import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { TokenTransfer, TokenTransfersApi, Wallet } from 'api';
import TransferTable from './transfer-table';
import { transferToUI } from 'core/api/transfer';
import { useWallets } from 'hooks/use-wallets';
import WithDataRecord from 'hoc/WithDataRecord';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';

const TransferList: FunctionComponent<{
  wallets: Wallet[];
  defaultOrdering: DataRecordOrdering;
}> = WithDataRecord<TokenTransfersApi, TokenTransfer>(TokenTransfersApi)(
  ({ data, ordering, onOrderBy, wallets, paginationProps }) => {
    const goTo = useGoTo(INVESTOR_ROUTES.transactionDetails);

    const transfers = (data || []).map((transfer: TokenTransfer) => transferToUI(transfer, wallets));

    return (
      <TransferTable
        ordering={ordering}
        onOrderBy={onOrderBy}
        transfers={transfers}
        goToDetails={(transferId: string) => goTo({ id: transferId })}
        paginationProps={paginationProps}
      />
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.tokenTransfersList({
      offset,
      limit,
      ordering,
    });
  },
);

const InvestorTransactionsPage = () => {
  const { wallets, loading: walletsLoading } = useWallets();

  if (walletsLoading || !wallets) {
    return <LoadingRing />;
  }

  // TODO(niklasb) handle error

  return (
    <WideContent title={<Translate name="dashboardInvestorTransactions.title" />}>
      <TransferList wallets={wallets} defaultOrdering={{ direction: 'desc', fieldName: 'created_at' }} />
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorTransactionsPage, AppType.INVESTOR);
