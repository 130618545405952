import { AdminInvestment, InvestmentTokenPermissionEnum } from 'api';
import { useProductsWithPermission } from 'apps/issuer/hooks';
import { BatchConfirmTypes } from 'apps/issuer/shared/confirm-investments-table-view/types';

export function useInvestmentsFilteredByConfirmPermissions(
  data: AdminInvestment[] | undefined,
  type: BatchConfirmTypes,
) {
  let permission;
  if (type === 'approveOffers') permission = InvestmentTokenPermissionEnum.MANAGE_APPROVALS;
  if (type === 'confirmPayments') permission = InvestmentTokenPermissionEnum.MANAGE_INCOMING_PAYMENTS;

  const tokens = useProductsWithPermission(permission);

  if (!tokens.length || !data?.length) return;

  return data.filter((investment) => tokens.some((token) => token.id === investment.token.id));
}
