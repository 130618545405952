import { useCallback, useContext, useMemo } from 'react';
import useApiCall from 'hooks/use-api-call';
import useInvestorMe from 'hooks/use-investor-me';
import { AdditionalInformationFields } from 'libraries/wizard/components/additional-information/additional-information-form';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import { CurrencyEnum, InvestmentsApi } from 'api';
import WizardContext from 'libraries/wizard/wizard-context';
import { MoneyValue } from 'ui/types/money-value';
import { makeBigNumber } from 'ui/helper/big-number';
import { AnyServerError } from 'hooks/use-api-error';

const useAdditionalInformation = (): {
  onSubmit: (values: AdditionalInformationFields) => void;
  bankAccountRequired: boolean;
  securitiesDepositAccountRequired: boolean;
  taxInformationRequired: boolean;
  showLoading: boolean;
  error?: AnyServerError;
} => {
  //hooks
  const { investmentId, resourceId: invitationId, loading, finalize } = useContext(WizardContext);

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  const { investor: me, error: meError } = useInvestorMe();

  const { token, error: invitationError, loading: loadingInvitation } = useInvestmentInvitation(invitationId);

  const showLoading = !token || loading || loadingInvitation || apiLoading;
  const error = invitationError || apiError || meError;

  const bankAccountRequired = useMemo(
    () => token?.bankAccountRequired && !me?.person.bankAccount,
    [me?.person.bankAccount, token?.bankAccountRequired],
  );
  const securitiesDepositAccountRequired = useMemo(
    () => token?.securitiesDepositAccountRequired && !me?.person.securitiesDepositAccount,
    [me?.person.securitiesDepositAccount, token?.id],
  );
  const taxInformationRequired = useMemo(() => token?.taxInformationRequired, [token?.id]);

  const investmentsApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);

  //functions
  const onSubmit = useCallback(
    (values: AdditionalInformationFields) => {
      withApi(async () => {
        await investmentsApi.investmentsAdditionalInformationCreate({
          id: investmentId,
          additionalInformationUpdateRequest: {
            ...(token?.taxInformationRequired
              ? {
                  exemptionOrderAmount: values?.exemptionOrderAmount
                    ? new MoneyValue(
                        makeBigNumber(values?.exemptionOrderAmount),
                        //  TODO(mara-cashlink): hardcoded currency EUR, replace if other currencies should be usable
                        CurrencyEnum.EUR,
                      )
                    : new MoneyValue(makeBigNumber(0), CurrencyEnum.EUR),
                }
              : {}),
            investorData: {
              bankAccount:
                bankAccountRequired && values.bankIban.length > 0 && values.bankAccountHolder.length > 0
                  ? {
                      iban: values.bankIban,
                      accountHolder: values.bankAccountHolder,
                    }
                  : undefined,
              securitiesDepositAccount: securitiesDepositAccountRequired
                ? {
                    bic: values.bic,
                    accountHolder: values.securitiesDepositAccountHolder,
                    accountNumber: values.securitiesDepositAccountHolder,
                  }
                : undefined,
              taxInformation:
                values?.taxIdentificationNumber && values?.nonAssessmentCertificate
                  ? {
                      taxIdentificationNumber: values?.taxIdentificationNumber,
                      nonAssessmentCertificate: values?.nonAssessmentCertificate === 'true',
                    }
                  : null,
            },
          },
        });

        finalize();
      });
    },
    [investmentsApi, investmentId, withApi, finalize, token?.taxInformationRequired],
  );

  return useMemo(
    () => ({
      bankAccountRequired: bankAccountRequired || false,
      securitiesDepositAccountRequired: securitiesDepositAccountRequired || false,
      taxInformationRequired: taxInformationRequired || false,
      showLoading,
      error,
      onSubmit,
    }),
    [
      bankAccountRequired,
      securitiesDepositAccountRequired,
      taxInformationRequired,
      onSubmit,
      investmentId,
      invitationId,
      showLoading,
      error,
    ],
  );
};

export default useAdditionalInformation;
