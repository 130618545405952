import { Colors, resolveColor } from 'ui/styles/utils/colors';
import styled from 'styled-components';
import { HorizontalAlign } from 'ui/types/align';

export interface StyledDropdownProps {
  $padding?: string;
  $color?: Colors;
}

export interface ItemsContainerProps {
  $openPosition?: HorizontalAlign;
}

export const Dropdown = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
`;

export const Title = styled.button<StyledDropdownProps>`
  display: flex;
  align-items: center;
  ${({ $padding }) => `padding: ${$padding}rem`};
  color: ${({ theme }) => theme.dropdown.labelColor};
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;

  &:focus {
    outline: none;
  }
`;

export const Arrow = styled.div`
  display: flex;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: ${({ theme }) => resolveColor(theme, 'primary')};
`;

export const ItemsContainer = styled.div<ItemsContainerProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: ${({ $openPosition }) => ($openPosition === 'left' ? '0' : 'auto')};
  left: auto;
  z-index: 1000;
  min-width: 10rem;
  padding: 1rem;
  color: ${({ theme }) => theme.dropdown.labelColor};
  background-color: ${({ theme }) => resolveColor(theme, 'white')};
  border-radius: 0.5rem;
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
`;
