import React, { FunctionComponent, ReactNode } from 'react';
import { InvestmentsApi } from 'api/apis';
import { AdminInvestment } from 'api/models';
import WithDataRecord from 'hoc/WithDataRecord';
import DashboardInvestmentsTable from 'src/apps/investor/shared/dashboard-investments-table';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import { InvestmentsListStatusEnum } from 'api';
import { DataRecordOrdering } from 'ui/types/data-ordering';

interface InvestorInvestmentsTableViewProps {
  status?: string;
  emptyView?: ReactNode;
  token?: string;
  title?: ReactNode;
  showSignedDateColumn?: boolean;
  defaultOrdering: DataRecordOrdering;
}

const InvestorInvestmentsTableView: FunctionComponent<InvestorInvestmentsTableViewProps> = WithDataRecord<
  InvestmentsApi,
  AdminInvestment
>(InvestmentsApi)(
  ({ data, ordering, onOrderBy, title, showSignedDateColumn, loading, emptyView, paginationProps }) => {
    const goToInvestment = useGoTo(INVESTOR_ROUTES.investment);

    return (
      <DashboardInvestmentsTable
        title={title}
        onOrderBy={onOrderBy}
        ordering={ordering}
        investments={data}
        emptyView={emptyView}
        loading={loading}
        showSignedDateColumn={showSignedDateColumn}
        goToDetails={(investmentId: string) => goToInvestment({ id: investmentId })}
        paginationProps={paginationProps}
      />
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.investmentsList({
      offset,
      limit,
      ordering,
      status: props.status as InvestmentsListStatusEnum,
      tokenId: props.token,
    });
  },
);

export default InvestorInvestmentsTableView;
