import styled from 'styled-components';

export const SIDEBAR_PADDING = 32; // px

export const SidebarHeader = styled.div`
  display: flex;
  justify-self: flex-start;
  flex-shrink: 1;
  flex-grow: 0;
  position: relative;
  top: 0;
  padding: ${SIDEBAR_PADDING}px;
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
`;

export const SidebarFooter = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-shrink: 1;
  flex-grow: 0;
  position: fixed;
  bottom: 0;
  padding: ${SIDEBAR_PADDING}px;
`;
