import { useEffect, useState } from 'react';

const useClearAfterTimeout = (what: any, timeout = 2000) => {
  const [timeoutWhat, setTimeoutWhat] = useState(what);
  useEffect(() => {
    setTimeoutWhat(what);
    const timeoutHandler = setTimeout(() => setTimeoutWhat(null), timeout);
    return () => clearTimeout(timeoutHandler);
  }, [what, timeout]);

  return timeoutWhat;
};

export default useClearAfterTimeout;
