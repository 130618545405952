import styled from 'styled-components';

export const FilterRange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Range = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
`;

export const RangeDivider = styled.div`
  padding: 0 8px;
`;
