import React, { FunctionComponent } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import { hideCentralCharacters } from 'ui/helper/hideCharacters';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Currency from 'ui/atoms/currency';
import { TaxInformation } from 'api';

export interface StudioInvestmentTaxDataProps {
  taxInformation: TaxInformation | null;
}

const StudioInvestmentTaxData: FunctionComponent<StudioInvestmentTaxDataProps> = (props) => {
  const { taxInformation } = props;

  return (
    <>
      <InfoGrid columns={1}>
        <InfoField title={<Translate name="studioInvestmentTaxData.taxNumber" />}>
          <PlaceholderFallback>
            {taxInformation?.taxIdentificationNumber &&
              hideCentralCharacters(taxInformation.taxIdentificationNumber, 2, -2)}
          </PlaceholderFallback>
        </InfoField>
        <InfoField title={<Translate name="studioInvestmentTaxData.redemptionAmount" />}>
          <PlaceholderFallback>
            {taxInformation?.exemptionOrderSum && <Currency>{taxInformation.exemptionOrderSum}</Currency>}
          </PlaceholderFallback>
        </InfoField>
      </InfoGrid>
    </>
  );
};

export default StudioInvestmentTaxData;
