import { Cmd } from 'redux-loop';
import { Investor, InvestorsApi } from 'api';
import { makeAuthenticatedApi } from 'core/api';
import { currentUserSelector } from 'core/auth/selectors';
import type { RootAppState } from 'store/types';

export async function getInvestorEffect(getState: Cmd.GetState): Promise<Investor> {
  const { currentUser } = currentUserSelector(getState<RootAppState>());

  if (!currentUser?.investor) {
    return Promise.reject(
      'Attempted to fetch investor data before user was logged in. This is a no-op. User has to be logged in for investor data to be shown.',
    );
  }

  const investorsApi: InvestorsApi = makeAuthenticatedApi(InvestorsApi, getState);

  return await investorsApi.investorsRetrieve({
    id: currentUser.investor,
  });
}
