import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import ServerError from 'ui/types/server-error';
import Section from 'ui/atoms/section';
import Hint from 'ui/atoms/hint';
import { camelCase } from 'change-case';
import { WalletType } from 'ui/types/wallet';
import makeForm from 'ui/forms';
import { toNumber } from 'ui/helper/money';
import * as Styled from './styled';
import Header from 'ui/atoms/header';
import Spacer from 'src/ui/atoms/spacer';
import { InvestorsApi } from 'src/api';
import useInvestorMe from 'src/hooks/use-investor-me';
import useApiCall from 'src/hooks/use-api-call';
import WizardContext from 'src/libraries/wizard/wizard-context';
export interface InvestmentWallet {
  type: WalletType;
  address?: string;
  id?: string;
}

export interface TFADevice {
  confirmed: boolean;
  walletNeeded: boolean;
}

export interface InvestmentSelectDepotProps {
  /** Additional classes. */
  className?: string;

  existingWallets?: InvestmentWallet[];

  loading?: boolean;

  error?: ServerError;

  onDepotSelected?: (selectedWallet: InvestmentWallet) => void;

  prioritizedAllowedWalletTypes: WalletType[];

  tfaDevice?: TFADevice;
}

const InvestmentSelectDepotForm = makeForm<{ walletOptionIndex: string }>();

const InvestmentSelectDepot: FunctionComponent<InvestmentSelectDepotProps> = ({
  className,
  loading,
  error,
  onDepotSelected = () => {},
  prioritizedAllowedWalletTypes,
  tfaDevice,
}) => {
  const { makeAuthenticatedApi, withApi } = useApiCall();

  const { investor } = useInvestorMe();

  const { finalize } = useContext(WizardContext);

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const needMobileAppWalletUpdate = prioritizedAllowedWalletTypes.includes(WalletType.MOBILE) && tfaDevice?.confirmed;

  const nonExistingGenericWalletOption: InvestmentWallet | undefined = prioritizedAllowedWalletTypes.includes(
    WalletType.GENERIC,
  )
    ? { type: WalletType.GENERIC }
    : undefined;

  const orderedNonExistingWalletOptions: InvestmentWallet[] = useMemo(() => {
    return prioritizedAllowedWalletTypes
      .filter((walletType) => {
        return (
          // handle non existing genericWallet separately
          walletType !== WalletType.GENERIC
        );
      })
      .map((walletType) => {
        return {
          type: walletType,
        };
      });
  }, [prioritizedAllowedWalletTypes]);

  const orderedWalletOptions: InvestmentWallet[] = [...orderedNonExistingWalletOptions];

  const skipWalletSelection = useCallback(() => {
    if (!investor?.id) return;

    withApi(async () => {
      await investorsApi.investorsSkipWalletSelectionCreate({
        id: investor.id,
      });

      finalize();
    });
  }, [withApi, investor?.id]);

  return (
    <div className={className}>
      <InvestmentSelectDepotForm
        initial={{ walletOptionIndex: '0' }}
        onSubmit={({ walletOptionIndex }) => {
          if (walletOptionIndex === '0') {
            skipWalletSelection();
          } else {
            onDepotSelected(orderedWalletOptions[toNumber(walletOptionIndex) - 1]);
          }
        }}
        error={error}
      >
        <p>
          <Translate name="investmentDepot.walletGuide.description" />
        </p>
        <Header size="small" spacing="medium">
          <Translate name="investmentDepot.walletGuide.explanationTitle" />
        </Header>
        <p>
          <Translate name="investmentDepot.walletGuide.explanation" />
        </p>
        {needMobileAppWalletUpdate && (
          <Hint variant="warning">
            <Translate name="investmentDepot.selection.needMobileAppWalletUpdate" />
          </Hint>
        )}
        <Spacer y={6} />
        <Header size="small" spacing="medium">
          <Translate name="investmentDepot.selection.title" />
        </Header>
        <Section spacing="small">
          <InvestmentSelectDepotForm.Group name="walletOptionIndex">
            <InvestmentSelectDepotForm.Radio key={0} value="0">
              <Translate name="wallet.skip" />
            </InvestmentSelectDepotForm.Radio>
          </InvestmentSelectDepotForm.Group>
          {orderedWalletOptions.map((walletOption, index) => (
            <InvestmentSelectDepotForm.Group name="walletOptionIndex">
              <InvestmentSelectDepotForm.Radio key={index + 1} value={(index + 1).toString()}>
                <Translate
                  name={`investmentDepot.selection.${walletOption.address ? 'existing' : 'new'}.${camelCase(
                    walletOption.type,
                  )}`}
                  args={
                    walletOption.address
                      ? [
                          (_, key) => (
                            <strong style={{ wordBreak: 'break-all' }} key={key}>
                              {walletOption.address}
                            </strong>
                          ),
                        ]
                      : []
                  }
                />
                {!walletOption.address && (
                  <Styled.WalletSelectDetails>
                    <Translate name={`investmentDepot.selection.details.${camelCase(walletOption.type)}`} />
                  </Styled.WalletSelectDetails>
                )}
              </InvestmentSelectDepotForm.Radio>
            </InvestmentSelectDepotForm.Group>
          ))}
        </Section>
        <Section spacing="small">
          {nonExistingGenericWalletOption && (
            <Button variant="link" onClick={() => onDepotSelected(nonExistingGenericWalletOption)}>
              <Translate name="investmentDepot.selection.new.generic" />
            </Button>
          )}
        </Section>
        <InvestmentSelectDepotForm.Submit loading={loading} size="large" fluid={true} variant="primary">
          <Translate name="common.continue" />
        </InvestmentSelectDepotForm.Submit>
      </InvestmentSelectDepotForm>
    </div>
  );
};

export default InvestmentSelectDepot;
