import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Icon from 'ui/atoms/icon';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import Spacer from 'ui/atoms/spacer';

export interface BenefitingPerson {
  forename: string;
  surname: string;
  street: string;
  zip: string;
  city: string;
}

export interface BenefitingPersonsSummaryProps {
  benefitingPersons: BenefitingPerson[];

  onEditClick?: () => void;

  className?: string;
}

export const BenefitingPersonsSummary: FunctionComponent<BenefitingPersonsSummaryProps> = (props) => {
  const { className, benefitingPersons, onEditClick, ...restProps } = props;

  return (
    <div className={cx('benefiting-persons-summary', className)} {...restProps}>
      {benefitingPersons.length ? (
        benefitingPersons.map(({ forename, surname, street, zip, city }, key) => {
          return (
            <p key={key}>
              <Icon size="large" name="check-circle" color="primary" />
              <span>{`${[forename.trim(), surname.trim()].join(' ')}, ${street}, ${zip} ${city}`}</span>
            </p>
          );
        })
      ) : (
        <p>
          <Translate name="benefitingPersons.emptySummary" />
        </p>
      )}
      {onEditClick && (
        <Spacer y={1}>
          <Button variant="link" onClick={onEditClick}>
            <Translate name="benefitingPersons.editOnSummary" />
          </Button>
        </Spacer>
      )}
    </div>
  );
};

export default BenefitingPersonsSummary;
