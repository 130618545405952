import Grid, { Col } from 'ui/atoms/grid';
import styled from 'styled-components';

export const QuestionnaireFormRow = styled(Grid)`
  margin: 18px 0;
`;

export const QuestionnaireDesktopCol = styled(Col)`
  padding: 0;
  && {
    display: flex;
    align-items: center;
  }
`;

export const QuestionnaireDesktopColEnd = styled(Col)`
  padding: 0;
  && {
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;
