import styled from 'styled-components';

export const DateContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.2rem;
  }
`;
