import React, { FunctionComponent } from 'react';

import Translate from 'ui/atoms/translate';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import LoadingRing from 'ui/atoms/loading-ring';

import useApiCall from 'hooks/use-api-call';
import useOnlyOnce from 'hooks/use-only-once';
import { UsersApi } from 'api';
import useGoTo from 'hooks/use-go-to';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';

interface ConfirmEmailCallbackPageProps {
  token: string;
}

const ConfirmEmailCallbackPage: FunctionComponent<ConfirmEmailCallbackPageProps> = ({ token }) => {
  const goToHome = useGoTo({ path: '/' });
  const goToInvitationContinue = useGoTo(INVESTMENT_ROUTES.invitationsContinue);

  const { error, loading, makeAuthenticatedApi, withApi } = useApiCall();

  const usersApi: UsersApi = makeAuthenticatedApi(UsersApi);

  useOnlyOnce(() => {
    withApi(async () => {
      await usersApi.usersEmailConfirmationCreate({
        emailConfirmationRequest: {
          token: token,
        },
      });
      // userId,  oldMail, newMail, tokenTime, tokenChallenge
      const newMail = decodeURIComponent(token).split(':')[2];

      if (newMail) {
        goToHome();
      } else {
        goToInvitationContinue();
      }
    });
  });

  return (
    <NarrowContent>
      <BoxedContent>
        {loading && <LoadingRing />}
        {error && <Translate name="errors.unknownError" />}
      </BoxedContent>
    </NarrowContent>
  );
};

export default ConfirmEmailCallbackPage;
