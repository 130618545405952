import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Section from 'ui/atoms/section';
import Link from 'ui/atoms/link';

export interface InvestmentFinalSummaryProps {
  /** Additional classes. */
  className?: string;
  isTransferInvitationView?: boolean;
}

const InvestmentFinalSummary: FunctionComponent<InvestmentFinalSummaryProps> = (props) => {
  const { className, children, isTransferInvitationView, ...restProps } = props;

  return (
    <div className={cx('investment-final-summary', className)} {...restProps}>
      <Hint variant="success">
        <Translate
          name={isTransferInvitationView ? 'accountCreationFinal.successHint' : 'investmentFinalSummary.success'}
        />
      </Hint>
      <Section>
        {isTransferInvitationView && (
          <Section>
            <Translate name="accountCreationFinal.findSecurities" />
          </Section>
        )}
        <strong>
          <Translate name="investmentFinalSummary.findSecurities1" />
        </strong>
        <p>
          <Translate
            name="investmentFinalSummary.findSecurities2"
            args={[
              (_, key) => (
                <Link key={key} variant="link" external iconPosition="right" href={`/dashboard/investorSettings`}>
                  <Translate as="span" name="investmentFinalSummary.productLink" />
                </Link>
              ),
            ]}
          />
        </p>
      </Section>
    </div>
  );
};

export default InvestmentFinalSummary;
