import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { compact } from 'lodash';
import LoadingRing from 'ui/atoms/loading-ring';
import { BenefitingPerson, Investor, NaturalPerson, RoleEnum } from 'api/models';
import RegisterInvestorBenefitingPersonsOverview from './register-investor-benefiting-overview';
import RegisterInvestorBenefitingPersonModal from 'libraries/wizard/components/beneficiary/register-investor-benefiting-modal';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import { PersonToNaturalPerson } from 'helper/cast';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

// TODO(geforcefan): refactor api calls and edit, add and delete logic
const BeneficiaryStep: FunctionComponent<{
  benefitingPersonConfirmationCreate: () => void;
  showLoading: boolean;
  investor?: Investor | null;
}> = ({ benefitingPersonConfirmationCreate, showLoading, investor }) => {
  const { finalize, loading } = useContext(WizardContext);

  const [currentPersonIndex, setCurrentPersonIndex] = useState<number | undefined>(undefined);

  const [benefitingPersons, setBenefitingPersons] = useState<
    (BenefitingPerson & { id?: string; action?: 'delete' | 'edit' | 'add' })[]
  >([]);

  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();

  useEffect(() => {
    if (investor?.id) {
      // TODO(geforcefan): Rethink this methology, casting now person to natural person, check function
      const naturalPersons: NaturalPerson[] = compact(
        (investor?.person?.persons || []).map(PersonToNaturalPerson),
      ).filter((naturalPerson) => {
        return naturalPerson.role === RoleEnum.CONTROLLING_PERSON;
      });

      const benefitingPersons: BenefitingPerson[] = naturalPersons as any as BenefitingPerson[];

      setBenefitingPersons(benefitingPersons);
    }
  }, [investor?.id]);

  const setPerson = useCallback(
    (person) => {
      if (currentPersonIndex === undefined) return;

      if (currentPersonIndex >= 0)
        setBenefitingPersons(
          benefitingPersons.map((existingPerson, index) =>
            index === currentPersonIndex
              ? {
                  ...existingPerson,
                  ...person,
                  action: existingPerson.action === 'add' ? 'add' : 'edit',
                }
              : existingPerson,
          ),
        );
      else setBenefitingPersons([...benefitingPersons, { ...person, action: 'add' }]);

      setCurrentPersonIndex(undefined);
    },
    [benefitingPersons, currentPersonIndex],
  );

  const deleteByPersonIndex = useCallback(
    (personIndex) => {
      setBenefitingPersons(
        benefitingPersons.map((existingPerson, index) =>
          index === personIndex ? { ...benefitingPersons[personIndex], action: 'delete' } : existingPerson,
        ),
      );
    },
    [benefitingPersons],
  );

  const onDataSubmit = useCallback(() => {
    const investorsApi: InvestorsApi = makeAuthenticatedApi(InvestorsApi);

    const personsToAdd = benefitingPersons.filter((person) => person.action === 'add');
    const personsToEdit = benefitingPersons.filter((person) => person.action === 'edit' && person.id);
    const personsToDelete = benefitingPersons.filter((person) => person.action === 'delete' && person.id);

    withApi(async () => {
      await Promise.all<any>([
        ...personsToAdd.map((person) =>
          investorsApi.investorsBenefitingPersonsCreate({
            id: investor?.id as string,
            benefitingPersonRequest: person,
          }),
        ),
        ...personsToDelete.map((person) =>
          investorsApi.investorsBenefitingPersonsDestroy({
            id: investor?.id as string,
            personId: person.id as string,
          }),
        ),
        ...personsToEdit.map((person) =>
          investorsApi.investorsBenefitingPersonsUpdate({
            id: investor?.id as string,
            personId: person.id as string,
            benefitingPersonRequest: person,
          }),
        ),
      ]);

      await benefitingPersonConfirmationCreate();

      finalize();
    });
  }, [finalize, investor, withApi, makeAuthenticatedApi, benefitingPersons]);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (apiError) return null;

  if (showLoading) return <LoadingRing />;

  const currentPerson = currentPersonIndex !== undefined && benefitingPersons[currentPersonIndex];

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate name="benefitingPersons.title" />
      </Header>
      <RegisterInvestorBenefitingPersonsOverview
        persons={benefitingPersons}
        onDeletePersonClicked={deleteByPersonIndex}
        onSetPersonClicked={setCurrentPersonIndex}
        onSubmit={onDataSubmit}
        error={apiError}
        loading={loading || apiLoading}
      />

      {currentPersonIndex !== undefined && (
        // TODO(geforcefan): initialState has a salutation as string, not compatible anymore, write api-conversion function, turned to any for now
        <RegisterInvestorBenefitingPersonModal
          onSubmit={setPerson}
          onCancelTriggered={() => setCurrentPersonIndex(undefined)}
          initialState={currentPerson || ({} as any)}
          isEditMode={currentPersonIndex >= 0}
        />
      )}
    </>
  );
};

export default BeneficiaryStep;
