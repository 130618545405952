import React, { FunctionComponent, useContext, useEffect } from 'react';
import AppRoutes from './app-routes';
import { useLoadServerConfig } from 'core/config/hooks';
import LoadingRing from 'ui/atoms/loading-ring';
import { TagManager } from 'helper/gtm';
import { I18NContext } from 'ui/i18n/provider';
import { InitialLocales } from 'ui/i18n/types';
import { mergeMessages } from 'ui/i18n/utils';

const Main: FunctionComponent = () => {
  const {
    loading,
    loaded,
    error,
    config: { googleTagManagerId, platformData },
  } = useLoadServerConfig();

  const { addLocaleMessages, isMessagesLoaded } = useContext(I18NContext);

  useEffect(() => {
    if (loaded && platformData && isMessagesLoaded) {
      const { address, companyName, platformName, supportEmail, supportPhone } = platformData;

      const platformDataConversion = {
        platform: {
          address: {
            street: address.street,
            zip: address.zip,
            city: address.city,
          },
          name: platformName,
          companyName: companyName,
          supportPhone: supportPhone,
          supportEmail: supportEmail,
        },
      };

      addLocaleMessages({ ...mergeMessages(platformDataConversion as unknown as InitialLocales) });
    }
  }, [loaded, isMessagesLoaded, platformData]);

  useEffect(() => {
    if (error) {
      console.error('Client config failed to load, app configuration may be incorrect', error);
    }
  }, [error]);

  useEffect(() => {
    if (googleTagManagerId) {
      TagManager.initialize(googleTagManagerId);
    }
  }, [googleTagManagerId]);

  // config needs to load before anything else, because app features are tied to the config setting
  if (loading) return <LoadingRing />;
  if (!loaded) return null;

  return (
    <>
      <AppRoutes />
    </>
  );
};

export default Main;
