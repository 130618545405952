import React, { FunctionComponent, useEffect } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import IssuerPages from './pages';
import withApp from 'core/with-app';
import { loadIssuerApp } from './actions';
import { useDispatch } from 'store/hooks';
import DefaultLayout from 'core/layout/default';
import PageNotFound from 'core/components/not-found';
import reducer from './reducer';
import { useIssuerSelector } from 'apps/issuer/hooks';
import { useIsLoggedInSelector } from 'core/auth/hooks';
import type { IssuerAppState } from 'store/types';

const IssuerApp: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();

  const { tokens, loading, error, isInitial } = useIssuerSelector();

  useEffect(() => {
    if (isLoggedIn) dispatch(loadIssuerApp());
  }, [isLoggedIn]);

  if (loading && isInitial) return <LoadingRing />;

  // todo: handle this better
  if (error)
    return (
      <DefaultLayout segment={false} pageTitle={false}>
        <PageNotFound />
      </DefaultLayout>
    );

  return <IssuerPages tokens={tokens || []} />;
};

export default withApp<IssuerAppState>('issuer', reducer)(IssuerApp);
