import Input from 'src/ui/atoms/input';
import styled from 'styled-components';

const defaults = {
  hrColor: '#D7D7D7',
};

export const Hr = styled.div`
  border-bottom: 1px solid ${defaults.hrColor};
  margin: 1rem 0;
`;

export const NetWorthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NetWorthInput = styled(Input)`
  margin-left: 1rem;
  text-align: right;
`;

export const FinancialItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
`;
