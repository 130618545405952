import React, { FunctionComponent, useCallback } from 'react';
import IssueManagedTokensModal from './managed-tokens-modal';
import { AdminInvestment } from 'api';

interface IssuerCreditSecuritiesProps {
  investment: AdminInvestment;
  onDone?: () => void;
}

const IssuerCreditSecurities: FunctionComponent<IssuerCreditSecuritiesProps> = (props) => {
  const { investment, onDone = () => {} } = props;

  const hideTokenIssueModal = useCallback(async () => {
    onDone();
  }, [onDone]);

  if (investment.token?.managed)
    return (
      <IssueManagedTokensModal
        investmentId={investment.id}
        amount={investment.remainingAmount}
        recipientName={investment.investor.companyName || investment.investor.name}
        onHide={hideTokenIssueModal}
      />
    );

  return <></>;
};

export default IssuerCreditSecurities;
