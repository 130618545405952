import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { camelCase } from 'change-case';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import List from 'ui/atoms/list';
import { WalletType } from 'ui/types/wallet';

export interface WalletStepSuccessProps {
  /** Additional classes. */
  className?: string;

  walletType: WalletType;

  walletAddress?: string | null;
}

const WalletStepSuccess: FunctionComponent<WalletStepSuccessProps> = (props) => {
  const { className, walletType, walletAddress, ...restProps } = props;

  return (
    <div className={cx('wallet-step-success', className)} {...restProps}>
      <Section>
        <Translate as="p" name="wallet.success.description" />
      </Section>
      <p>
        <Translate as="b" name="wallet.success.title" />
      </p>
      <List truncate={true}>
        <li>
          <Translate
            name="wallet.success.type"
            args={[<Translate key={0} name={`walletType.${camelCase(walletType)}`} />]}
          />
        </li>
        {walletAddress && (
          <li>
            <Translate name="wallet.success.depotAddress" args={[<span key={0}>{walletAddress}</span>]} />
          </li>
        )}
      </List>
    </div>
  );
};

export default WalletStepSuccess;
