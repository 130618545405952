export enum NetworkType {
  ETHEREUM = 'ETHEREUM',
  STELLAR = 'STELLAR',
}

export interface EthTokenContractInfo {
  address: string;
}
export interface StellarTokenContractInfo {
  issuingAccountId: string;
}
