import { useCallback } from 'react';
import { PasswordStrength } from 'ui/atoms/strength-indicator';
import useAsyncFunction from './use-async-function';
import { useServerConfigSelector } from 'core/config/hooks';
import applicationConfig from 'core/config/local';

const loadZxcvbn = async () => (await import('zxcvbn')).default;

const usePasswordStrength = (loadingCondition = true) => {
  const zxcvbn = useAsyncFunction(loadZxcvbn, loadingCondition);

  const { config } = useServerConfigSelector();

  const commonPasswords = config.commonPasswordStrings;

  return useCallback(
    (password: string, userInputs?: string[]) => {
      if (!zxcvbn) return undefined;
      const { guesses } = zxcvbn(password, [...(userInputs || []), ...(commonPasswords || [])]);

      if (guesses >= applicationConfig.passwordRequirements.zxcvbnStrong) {
        return PasswordStrength.STRONG;
      } else if (guesses >= applicationConfig.passwordRequirements.zxcvbnWeak) {
        return PasswordStrength.MEDIUM;
      } else {
        return PasswordStrength.WEAK;
      }
    },
    [zxcvbn, commonPasswords],
  );
};

export default usePasswordStrength;
