import Translate from 'ui/atoms/translate';
import React, { FunctionComponent } from 'react';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import type { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export interface DefaultFormSegmentProps {
  questions: QuestionnaireQuestions[];
  isEcsp: boolean;
}

const DefaultFormSegment: FunctionComponent<DefaultFormSegmentProps> = ({ questions, isEcsp }) => {
  const translate = useTranslateWithStringArgs();

  return (
    <>
      {questions.map((question) => {
        const questionI18nKey = question.segmentI18nKey ? question.segmentI18nKey : DEFAULT_SEGMENT_KEY;
        const optionI18nKey = question.segmentI18nKey ? question.id : DEFAULT_SEGMENT_KEY;

        return (
          <QuestionnaireForm.Group
            key={question.id}
            name={question.id}
            label={
              <Translate key={question.id} name={`${questionI18nKey}.${snakeToCamelCaseWithNumbers(question.id)}`} />
            }
            required={isEcsp}
          >
            <QuestionnaireForm.Select
              options={(question.options || []).map((option) => ({
                value: option,
                label: translate(`${optionI18nKey}.${snakeToCamelCaseWithNumbers(option)}`),
              }))}
              hasLabelOutside={true}
            ></QuestionnaireForm.Select>
          </QuestionnaireForm.Group>
        );
      })}
    </>
  );
};

export default DefaultFormSegment;
