import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { InvestmentTokenForInvestor, TokenTransferStatusEnum, WalletBalance, Wallet, Balance } from 'api/models';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import ReceiveAssetsModal from './receive-assets-modal';
import { useTransfers } from 'hooks/transfer';
import useGoTo from 'hooks/use-go-to';
import { getWalletAddressForNetworkAssertExistence } from 'helper/network';
import { assertDefined } from 'ui/helper/assert-defined';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import TransferWizard from '../transfer-wizard';
import FrozenWalletModal from './frozen-wallet-modal';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';

const WalletActions: FunctionComponent<{
  wallet: WalletBalance & Wallet;
  token: InvestmentTokenForInvestor;
  balance: Balance | undefined;
}> = ({ wallet, token, balance }) => {
  const [showReceiveAssets, setShowReceiveAssets] = useState(false);
  const [showTransferWizard, setShowTransferWizard] = useState(false);
  const [showFrozenModal, setShowFrozenModal] = useState(false);
  const [continueTransfer, setContinueTransfer] = useState<string | undefined>(undefined);

  const filter = useMemo(
    () => ({
      tokenId: token?.id,
      status: [TokenTransferStatusEnum.TFA_REQUESTED],
      walletIds: [wallet.id],
    }),
    [token, wallet],
  );

  const { transfers: pendingTransfers } = useTransfers(filter);

  const goToTransactionList = useGoTo(INVESTOR_ROUTES.transactions);

  const isPhone = useIsMedia(DEVICES.phone);

  useEffect(() => {
    if (pendingTransfers && pendingTransfers.length > 0) {
      setContinueTransfer(pendingTransfers[0].id);
    }
  }, [pendingTransfers]);

  const onClickTransfer = useCallback(() => {
    if (wallet.isFrozen) {
      setShowFrozenModal(true);
    } else {
      setShowTransferWizard(true);
    }
  }, []);

  const onCloseTransferWizard = () => {
    setShowTransferWizard(false);
  };

  return (
    <>
      <ActionButtons fullWidth={isPhone} verticalAlign={isPhone}>
        {!!wallet.balance && (
          <Button variant="secondary" fluid={isPhone} onClick={onClickTransfer}>
            <Translate name="common.transfer" />
          </Button>
        )}
        <Button variant="secondary" fluid={isPhone} onClick={() => setShowReceiveAssets(true)}>
          <Translate name="common.receive" />
        </Button>
      </ActionButtons>

      {showReceiveAssets && (
        <ReceiveAssetsModal
          open={showReceiveAssets}
          walletAddress={assertDefined(
            token.tokenizationInfo &&
              getWalletAddressForNetworkAssertExistence(token.tokenizationInfo.networkType, wallet),
          )}
          onCancel={() => setShowReceiveAssets(false)}
          onContinue={goToTransactionList}
        />
      )}
      {showTransferWizard && (
        <TransferWizard onClose={onCloseTransferWizard} wallet={wallet} token={token} balance={balance} />
      )}
      {!showTransferWizard && continueTransfer && (
        <TransferWizard
          onClose={onCloseTransferWizard}
          token={token}
          transferId={continueTransfer}
          wallet={wallet}
          balance={balance}
        />
      )}
      {showFrozenModal && <FrozenWalletModal onClose={() => setShowFrozenModal(false)} />}
    </>
  );
};

export default WalletActions;
