import React, { useState, FunctionComponent, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import useTranslate from 'ui/hooks/use-translate';
import * as Styled from './styled';
import Spacer from '../spacer';

export interface DateInputProps {
  /** Value */
  value?: Date | string;

  // this any is hacked around the react-hook-form nonsense that doesn't work with hidden inputs
  onChange?: (e: any) => void;

  name?: string;

  // this any is hacked around the react-hook-form nonsense that doesn't work with hidden inputs
  onBlur?: (e: any) => void;

  /** Minimum date */
  min?: Date;

  /** Maximum date */
  max?: Date;

  /** Required */
  required?: boolean;

  hasTime?: boolean;

  valid?: boolean;
}

const hasAllInputs = (year: number | undefined, month: number | undefined, day: number | undefined) =>
  year !== undefined && month !== undefined && day !== undefined;

const renderDate = (item: number | undefined, literals = 2) => {
  if (item === undefined) return '';
  if (item === 0) return Array(literals).fill('0').join('');
  if (String(item).length >= literals) return String(item);
  return (
    Array(literals - String(item).length)
      .fill('0')
      .join('') + item
  );
};

const getMinMaxedDate = (input: number, min: number, max: number, start?: number) => {
  if (start && input < start) return input;
  if (input < min) return min;
  if (input > max) return max;
  return input;
};

const buildDate = (year: number | undefined, month: number | undefined, day: number | undefined) => {
  if (year === undefined || month === undefined || day === undefined) return undefined;

  const result = new Date(Date.UTC(year, month - 1, day));
  // need to specifically set the year to get the actual value, in case year is two digits
  // because the default date constructor will default a two digit value to the 20th century, so 20 -> 1920
  result.setUTCFullYear(year);

  return result;
};

const getDateValidity = (
  year: number | undefined,
  month: number | undefined,
  day: number | undefined,
  min: Date | undefined,
  max: Date | undefined,
) => {
  let isDayValid = (day as number) > 0;
  let isMonthValid = (month as number) > 0;
  let isYearValid = (year as number) > 0;
  const inputDate = buildDate(year, month, day) as Date;

  if (min && inputDate < min) {
    isYearValid = false;
  }

  if (max && inputDate > max) {
    isYearValid = false;
  }

  return { isDayValid, isMonthValid, isYearValid };
};

export const DateInput: FunctionComponent<DateInputProps> = ({
  name,
  value,
  onChange,
  onBlur,
  valid,
  max = new Date('2100-01-01'),
  min = new Date('1900-01-01'),
  hasTime = false,
  ...props
}) => {
  const [canValidate, setCanValidate] = useState(false);
  const [dayValid, setDayValid] = useState<boolean | undefined>(undefined);
  const [monthValid, setMonthValid] = useState<boolean | undefined>(undefined);
  const [yearValid, setYearValid] = useState<boolean | undefined>(undefined);
  const [day, setDay] = useState<number | undefined>(value ? new Date(value).getDate() : undefined);
  const [month, setMonth] = useState<number | undefined>(value ? new Date(value).getMonth() + 1 : undefined);
  const [year, setYear] = useState<number | undefined>(value ? new Date(value).getFullYear() : undefined);
  const [date, setDate] = useState<Date | undefined>(value ? new Date(value) : undefined);
  const [usedMax, setUsedMax] = useState<number | false>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const translate = useTranslate();

  useEffect(() => {
    if (!canValidate) return;

    const { isDayValid, isMonthValid, isYearValid } = getDateValidity(year, month, day, min, max);

    setDayValid(isDayValid);
    setMonthValid(isMonthValid);
    setYearValid(isYearValid);
  }, [day, month, year, min, max]);

  useEffect(() => {
    if (value !== date) {
      setDate(value ? new Date(value) : undefined);
    }
  }, [value]);

  const doOnChange = useCallback(
    (event: any, type: 'day' | 'month' | 'year' | 'time') => {
      const input = Number(event.target.value);
      const minDays = 0;
      const minMonths = 0;
      const maxMonths = 12;
      const maxYear = max.getFullYear();
      const minYear = min.getFullYear();
      let valid = false;
      let actualDate = undefined;
      let maxDays = 31;
      let newDay = day;
      let newMonth = month;
      let newYear = year;
      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      switch (type) {
        case 'day':
          actualDate = buildDate(newYear, newMonth, 1);
          maxDays = actualDate ? moment(actualDate).daysInMonth() : 31;

          newDay = getMinMaxedDate(input, minDays, maxDays);
          setDay(newDay);
          setUsedMax(false);
          break;
        case 'month':
          newMonth = getMinMaxedDate(input, minMonths, maxMonths);

          actualDate = buildDate(newYear, newMonth, 1);
          maxDays = actualDate ? moment(actualDate).daysInMonth() : 31;

          setMonth(newMonth);
          if (usedMax || (newDay && newDay > maxDays)) {
            newDay = usedMax && usedMax < maxDays ? usedMax : maxDays;
            setDay(newDay);
            if (!usedMax) setUsedMax(newDay);
          }
          break;
        case 'year':
          newYear = getMinMaxedDate(input, minYear, maxYear, 999);

          actualDate = buildDate(newYear, newMonth, 1);
          maxDays = actualDate ? moment(actualDate).daysInMonth() : 31;

          setYear(newYear);
          if (usedMax || (newDay && newDay > maxDays)) {
            newDay = usedMax && usedMax < maxDays ? usedMax : maxDays;
            setDay(newDay);
            if (!usedMax) setUsedMax(newDay);
          }
          break;
        case 'time':
          if (event.target.value.split(':').length < 3) {
            break;
          }
          hours = event.target.value.split(':')[0];
          minutes = event.target.value.split(':')[1];
          seconds = event.target.value.split(':')[2];
          break;
        default:
          throw new Error('Invalid date field, something went wrong');
      }

      const { isYearValid, isDayValid, isMonthValid } = getDateValidity(newYear, newMonth, newDay, min, max);

      if (hasAllInputs(newYear, newMonth, newDay) && isYearValid && isMonthValid && isDayValid) {
        valid = true;
      }

      const newDate = valid ? buildDate(newYear, newMonth, newDay) : undefined;

      if (hasAllInputs(newYear, newMonth, newDay)) {
        if (hasTime) {
          newDate?.setHours(hours);
          newDate?.setMinutes(minutes);
          newDate?.setSeconds(seconds);
        }

        setDate(newDate);
        if (onChange) {
          onChange({ ...event, target: { value: newDate, validity: { valid } } });
        }
      }
    },
    [date, day, month, year, min, max, usedMax],
  );

  const getNewYear = useCallback(
    (event: any) => {
      let newYear = Number(event.target.value);
      if (newYear === 0) newYear = 0;
      // surface to the user that this is missing
      else if (newYear < new Date().getFullYear() % 100) newYear = 2000 + newYear;
      else if (newYear < 100) newYear = 1900 + newYear;

      doOnChange({ ...event, target: { ...event.target, value: newYear } }, 'year');
    },
    [doOnChange],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        getNewYear(event);
      }
    },
    [getNewYear],
  );

  const handleOnBlur = useCallback(() => {
    if (hasAllInputs(year, month, day) && onBlur && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.blur();
      setCanValidate(true);
    }
  }, [day, year, month, onBlur]);

  const onBlurDay = useCallback(
    (event) => {
      if (event.target.value === '') return;
      doOnChange(event, 'day');
      handleOnBlur();
    },
    [year, handleOnBlur, doOnChange],
  );

  const onBlurMonth = useCallback(
    (event) => {
      if (event.target.value === '') return;
      doOnChange(event, 'month');
      handleOnBlur();
    },
    [year, handleOnBlur, doOnChange],
  );

  const onBlurYear = useCallback(
    (event) => {
      if (event.target.value === '') return;
      getNewYear(event);
      handleOnBlur();
    },
    [year, handleOnBlur, doOnChange],
  );

  return (
    <Styled.DateInputWrapper>
      <Styled.ShadowInput
        {...props}
        name={name}
        value={date ? date.toISOString() : ''}
        onBlur={onBlur}
        type="text"
        ref={inputRef}
      />
      <Styled.DateInput
        value={renderDate(day)}
        $flex={1}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        min={0}
        max={31}
        label={translate('date.day') as string}
        onChange={(event) => doOnChange(event, 'day')}
        onBlur={onBlurDay}
        aria-label="Select day of month"
        valid={dayValid || valid}
        data-qa="day"
        isDate
      />
      <Styled.DateInput
        value={renderDate(month)}
        $flex={1}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        min={0}
        max={12}
        label={translate('date.month') as string}
        onBlur={onBlurMonth}
        onChange={(event) => doOnChange(event, 'month')}
        aria-label="Select month of year"
        valid={monthValid || valid}
        data-qa="month"
        isDate
      />
      <Styled.DateInput
        value={renderDate(year, 4)}
        $flex={1}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        min={0}
        max={9999}
        label={translate('date.year') as string}
        onChange={(event) => doOnChange(event, 'year')}
        onKeyDown={handleKeyDown}
        onBlur={onBlurYear}
        aria-label="Select year"
        valid={yearValid || valid}
        data-qa="year"
        isDate
      />
      {hasTime && (
        <>
          <Spacer x={4} />
          <Styled.Time type="time" step="1" required onChange={(event) => doOnChange(event, 'time')} />
        </>
      )}
    </Styled.DateInputWrapper>
  );
};

export default DateInput;
