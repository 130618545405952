enum IdentificationType {
  UNKNOWN = 'UNKNOWN',
  IDCARD = 'IDCARD',
  PASSPORT = 'PASSPORT',
  DRIVERSLICENSE = 'DRIVERS_LICENSE',
  RESIDENCETITLE = 'RESIDENCE_TITLE',
  TEMPORARYIDCARD = 'TEMPORARY_ID_CARD',
  TEMPORARYPASSPORT = 'TEMPORARY_PASSPORT',
}

export default IdentificationType;
