export interface NavigationTheme {
  navBarBorderColor: any;
  navBarHeight: any;
  linkColor: any;
  linkHoverColor: any;
}

export default {
  navBarBorderColor: '#dee2e6',
  navBarHeight: 'auto',
  linkColor: 'rgba(0,0,0,.5)',
  linkHoverColor: 'rgba(0,0,0,.7)',
} as NavigationTheme;
