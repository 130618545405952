import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Image from 'ui/atoms/image';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import { camelCase } from 'change-case';
import Grid from 'ui/atoms/grid';
import { StyledCol } from './styled';
import Link from 'ui/atoms/link';
import { useServerConfigSelector } from 'core/config/hooks';

export enum EmptyViewType {
  INVESTOR_DOCUMENTS = 'INVESTOR_DOCUMENTS',
  INVESTOR_OPEN_INVESTMENTS = 'INVESTOR_OPEN_INVESTMENTS',
  INVESTOR_PORTFOLIO = 'INVESTOR_PORTFOLIO',
  REGISTERED_PORTFOLIO = 'REGISTERED_PORTFOLIO',
  REGISTERED_DOCUMENTS = 'REGISTERED_DOCUMENTS',
  ISSUER_INVESTMENTS = 'ISSUER_INVESTMENTS',
  ISSUER_CAMPAIGN_DETAILS_INVESTMENTS = 'ISSUER_CAMPAIGN_DETAILS_INVESTMENTS',
  ISSUER_INVITATION_DETAILS_INVESTMENTS = 'ISSUER_INVITATION_DETAILS_INVESTMENTS',
  ISSUER_INVESTORS = 'ISSUER_INVESTORS',
  ISSUER_APPROVALS = 'ISSUER_APPROVALS',
  ISSUER_PAYMENTS = 'ISSUER_PAYMENTS',
  ISSUER_DIGITAL_SECURITIES = 'ISSUER_DIGITAL_SECURITIES',
  ISSUER_CAMPAIGNS = 'ISSUER_CAMPAIGNS',
  ISSUER_PERSONAL_INVITATIONS = 'ISSUER_PERSONAL_INVITATIONS',
  ISSUER_DOCUMENTS = 'ISSUER_DOCUMENTS',
  ISSUER_VOUCHERS = 'ISSUER_VOUCHERS',
  ISSUER_TRIGGER_SOLUTION = 'ISSUER_TRIGGER_SOLUTION',
  ISSUER_INVESTOR_EMAILS = 'ISSUER_INVESTOR_EMAILS',
  NO_FILTER_RESULTS = 'NO_FILTER_RESULTS',
}

export interface DashboardEmptyViewProps {
  children?: ReactNode;
  /** Additional classes. */
  className?: string;

  // when adding types here also add corresponding translations to dashboardEmptyView
  type: EmptyViewType;

  image: string;

  i18nKeyExtension?: string;
}

const DashboardEmptyView: FunctionComponent<DashboardEmptyViewProps> = (props) => {
  const { className, type, image, children, i18nKeyExtension, ...restProps } = props;

  const {
    config: { investNowUrl },
  } = useServerConfigSelector();

  return (
    <div className={cx('dashboard-empty-view', className)} {...restProps}>
      <Grid>
        <StyledCol phone={12} width={3}>
          <Image src={image} alt={`empty view ${camelCase(type)}`} size="large" />
        </StyledCol>
        <StyledCol phone={12} width={9}>
          <Segment basic={true}>
            <Header size="medium">
              <Translate name={`dashboardEmptyView.${camelCase(type)}${i18nKeyExtension || ''}.title`} />
            </Header>
            <Translate
              as="p"
              name={`dashboardEmptyView.${camelCase(type)}${i18nKeyExtension || ''}.description`}
              args={[
                (text, key) =>
                  investNowUrl && (
                    <Link href={investNowUrl} key={key} inline>
                      {text}
                    </Link>
                  ),
              ]}
            />
            {/*eg. add an action button as a child*/}
            {children}
          </Segment>
        </StyledCol>
      </Grid>
    </div>
  );
};

export default DashboardEmptyView;
