import React, { createContext } from 'react';
import type { FilterValues, FilterState } from '../types';

type FilterContextType = {
  userFilters: FilterState;
  activeFilters: FilterValues;
  setUserFilters: React.Dispatch<React.SetStateAction<FilterState>>;
  filterNumber: number | null;
  filterNames: string[];
  setFilterNames: React.Dispatch<React.SetStateAction<string[]>>;
  loading: boolean | undefined;
  entries: number | null | undefined;
  applyFilters: boolean;
  setApplyFilters: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterContext = createContext<FilterContextType>({
  userFilters: {},
  entries: null,
  setUserFilters: () => {},
  activeFilters: {},
  filterNumber: null,
  filterNames: [],
  setFilterNames: () => {},
  loading: false,
  applyFilters: false,
  setApplyFilters: () => {},
});

export default FilterContext;
