import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const WalletType = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${query.phone`
    font-size: 11px;
  `};
`;
