import type { InvestmentToken } from 'api';

export enum IssuerActions {
  LOAD_APP = 'ISSUER/LOAD_APP',
  TOKENS_FETCH = 'ISSUER/TOKENS_FETCH',
  TOKENS_FETCH_SUCCESS = 'ISSUER/TOKENS_FETCH_SUCCESS',
  TOKENS_FETCH_FAILURE = 'ISSUER/TOKENS_FETCH_FAILURE',
}

export type LoadIssuerApp = { type: IssuerActions.LOAD_APP };
export type TokensFetch = { type: IssuerActions.TOKENS_FETCH };
export type TokensFetchSuccess = {
  type: IssuerActions.TOKENS_FETCH_SUCCESS;
  data: InvestmentToken[];
};
export type TokensFetchFailure = {
  type: IssuerActions.TOKENS_FETCH_FAILURE;
  error: Error;
};

export type IssuerActionTypes = LoadIssuerApp | TokensFetch | TokensFetchSuccess | TokensFetchFailure;

export function loadIssuerApp(): LoadIssuerApp {
  return {
    type: IssuerActions.LOAD_APP,
  };
}

export function tokensFetch(): TokensFetch {
  return {
    type: IssuerActions.TOKENS_FETCH,
  };
}

export function tokensFetchSuccess(data: InvestmentToken[]): TokensFetchSuccess {
  return {
    type: IssuerActions.TOKENS_FETCH_SUCCESS,
    data,
  };
}

export function tokensFetchFailure(error: Error): TokensFetchFailure {
  return {
    type: IssuerActions.TOKENS_FETCH_FAILURE,
    error,
  };
}
