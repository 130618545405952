import React, { FunctionComponent } from 'react';
import RoutesRenderer from 'core/routing/routes-renderer';
import DefaultLayout from 'core/layout/default';
import { DEMO_COMPONENT_ROUTING, DEMO_ROUTES } from './routes.config';
import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';

export interface DemoPagesProps {}

const DemoPages: FunctionComponent<DemoPagesProps> = () => {
  const routes = useRoutesResolver(DEMO_ROUTES);

  return (
    <DefaultLayout>
      <RoutesRenderer routes={routes} componentRouting={DEMO_COMPONENT_ROUTING} />
    </DefaultLayout>
  );
};

export default DemoPages;
