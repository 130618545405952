import { AdminApi, CountryEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface EditInvestorRegisterAddressFields {
  id: string;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
}

export interface EditInvestorRegisterAddressModalProps {
  onHideEditRegisterAddress: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  values: EditInvestorRegisterAddressFields;
}

const EditInvestorRegisterAddressForm = makeModalForm<EditInvestorRegisterAddressFields>();

const EditRegistrationAddressModal: FunctionComponent<EditInvestorRegisterAddressModalProps> = (props) => {
  const { onHideEditRegisterAddress, fetchInvestorDetails, showSuccessModal = () => {}, values } = props;

  const [investorData, setInvestorData] = useState<EditInvestorRegisterAddressFields>(values);

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editRegistrationAddress = (fieldsValues: StateValues<EditInvestorRegisterAddressFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminInvestorUpdateRequest: {
          naturalPerson: {
            ...fieldsValues,
          },
        },
      };
      await investorsApi.adminInvestorsPartialUpdate(patchBody);
      showSuccessModal();
      fetchInvestorDetails();
      onHideEditRegisterAddress();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditRegisterAddress}>
        <EditInvestorRegisterAddressForm
          initial={{
            street: investorData.street,
            zip: investorData.zip,
            city: investorData.city,
            country: investorData.country,
          }}
          onChange={(values: StateValues<EditInvestorRegisterAddressFields>) => {
            setInvestorData(values);
          }}
          onSubmit={(values: StateValues<EditInvestorRegisterAddressFields>) => {
            return editRegistrationAddress(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editRegisterAddressForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editRegisterAddressForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditInvestorRegisterAddressForm.Group name="street" required={!!values.street}>
              <EditInvestorRegisterAddressForm.Input autoFocus={true} placeholder="-" />
            </EditInvestorRegisterAddressForm.Group>
            <Grid>
              <Col width={4} spacing="small">
                <EditInvestorRegisterAddressForm.Group name="zip" required={!!values.zip}>
                  <EditInvestorRegisterAddressForm.Input placeholder="-" />
                </EditInvestorRegisterAddressForm.Group>
              </Col>
              <Col width={8} spacing="small">
                <EditInvestorRegisterAddressForm.Group name="city" required={!!values.city}>
                  <EditInvestorRegisterAddressForm.Input value={investorData.city} placeholder="-" />
                </EditInvestorRegisterAddressForm.Group>
              </Col>
            </Grid>
            <EditInvestorRegisterAddressForm.Group name="country" required={!!values.country}>
              <EditInvestorRegisterAddressForm.Select
                options={useCountries()}
                value={values.country}
                onChange={(country: CountryEnum) => {
                  setInvestorData({ ...investorData, country });
                }}
              ></EditInvestorRegisterAddressForm.Select>
            </EditInvestorRegisterAddressForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditRegisterAddress,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editRegisterAddressForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                },
              ]}
            />
          </ModalFooter>
        </EditInvestorRegisterAddressForm>
      </Modal>
    </>
  );
};

export default EditRegistrationAddressModal;
