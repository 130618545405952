import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import Date from 'ui/atoms/date';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import Icon from 'ui/atoms/icon';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import { IssuerInvestmentStatusIndicator } from 'src/apps/issuer/shared/issuer-investment-status';
import { PaginationProps } from 'ui/molecules/pagination';

export interface StudioInvestmentsTableProps {
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  investments?: any;
  goToDetails: (productId: string, investmentId: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  noProduct?: boolean;
  paginationProps?: PaginationProps;
}

const StudioInvestmentsTable: FunctionComponent<StudioInvestmentsTableProps> = (props) => {
  const { ordering, noProduct, onOrderBy, investments, goToDetails, title, emptyView, loading, paginationProps } =
    props;

  return (
    <DataRecordTable
      ordering={ordering}
      onOrderBy={onOrderBy}
      data={investments}
      title={title}
      loading={loading}
      emptyView={emptyView}
      paginationProps={paginationProps}
      cells={[
        !noProduct && {
          title: <Translate name={`studioInvestmentsTable.product`} />,
          render: ({ productName }) => productName,
        },
        {
          // TODO(mara-cashlink): add maxColumnWidth: "medium"?
          title: <Translate name={`studioInvestmentsTable.investorName`} />,
          render: ({ investor }) => investor.companyName || investor.name,
        },
        {
          title: <Translate name={`studioInvestmentsTable.signedDate`} />,
          orderingFieldName: 'signed_date',
          render: ({ signedDate }) => <Date>{signedDate}</Date>,
        },
        {
          title: <Translate name={`studioInvestmentsTable.investmentTotal`} args={[<>&shy;</>]} />,
          alignContent: 'right',
          orderingFieldName: 'investment_total',
          render: ({ investmentTotal }) => <Currency>{investmentTotal}</Currency>,
        },
        {
          title: <Translate name={`studioInvestmentsTable.status`} />,
          render: ({ frontendStatus }) => (
            <PlaceholderFallback>
              {frontendStatus && <IssuerInvestmentStatusIndicator status={frontendStatus} />}
            </PlaceholderFallback>
          ),
        },
        {
          title: '',
          render: ({ token, id }) => (
            <Icon name="arrow-right" color="primary" onClick={() => goToDetails(token.id, id || '')} size="large" />
          ),
          alignContent: 'right',
        },
      ]}
    />
  );
};

export default StudioInvestmentsTable;
