import styled from 'styled-components';

export const ActionIconCell = styled.div`
  display: flex;
  align-items: center;

  .external-link {
    margin-left: 6px;
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .external-link {
      visibility: initial;
    }
  }
`;
