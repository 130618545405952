export const convertFileSize = (bytes: number): string => {
  // converts value from bytes to bytes/KB/MB/GB

  // var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  //   if (bytes == 0) return 'n/a';
  //   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  //   if (i == 0) return bytes + ' ' + sizes[i];
  //   return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];

  if (bytes >= 1000000000) {
    return (bytes / 1000000000).toFixed(2) + ' GB';
  }
  if (bytes >= 1000000) {
    return (bytes / 1000000).toFixed(2) + ' MB';
  }
  if (bytes >= 1000) {
    return (bytes / 1000).toFixed(2) + ' KB';
  }
  if (bytes > 1) {
    return bytes + ' bytes';
  }
  if (bytes === 1) {
    return bytes + ' byte';
  }
  return bytes + ' bytes';
};
