import React, { FunctionComponent } from 'react';
import { compact } from 'lodash';
import Questionnaire from 'libraries/wizard/components/questionnaire';
import { useQuestionnaire } from './use-questionnaire';
import Translate from 'ui/atoms/translate';
import ConfirmationNetWorthModal from 'libraries/wizard/components/questionnaire/confirmation-networth-modal';
import SavedNetworth from 'libraries/wizard/components/questionnaire/saved-networth';
import QuestionnaireLossabilityCalculator from 'libraries/wizard/components/questionnaire/questionnaire-lossability-calculator';
import QuestionnaireSummary from 'libraries/wizard/components/questionnaire/questionnaire-summary';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import QuestionnaireWaiver from 'libraries/wizard/components/questionnaire/questionnaire-waiver';

const QuestionnaireStep: FunctionComponent = () => {
  const props = useQuestionnaire();
  const {
    investor,
    loading,
    isEcsp,
    showLossabilityCalculator,
    setShowLossabilityCalculator,
    savedNetWorth,
    lossability,
    isSavingDisabled,
    setLosabilityAnswers,
    onSaveLossabilityEntries,
    onEditQuestions,
    onContinueWithoutSavingNetworth,
    onSkipNetworth,
    isConfirmModalOpen,
    setConfirmModalOpen,
    showSummary,
    scoreList,
    questionnairePagesSegments,
    initialEducationOccupation,
    prefilledQuestions,
    surveyAnswers,
    investmentTotal,
    onContinueSummary,
    onGoBackSummary,
    restartNetworthCalculation,
    noAnswers,
    questionnairePage,
    setQuestionnairePage,
    surveyWaiverType,
    isNetWorthSuitable,
    showWaiver,
    onContinueWaiver,
    editQuestions,
    error,
  } = props;

  if (!prefilledQuestions || !surveyAnswers || !investor || loading) return <LoadingRing />;

  const initialAnswers = prefilledQuestions.map((question) => ({
    ...question,
    selectedOption: surveyAnswers[question.id] ? String(surveyAnswers[question.id]) : undefined,
  }));

  const hasQuestionnaireExistingSelections = () => {
    const selections = initialAnswers.map((question) => question.selectedOption);
    return compact(selections).length === selections.length;
  };

  const riskNoticeTitle = <Translate name="questionnaire.riskNotice" />;
  const networthTitle = <Translate name="lossAbility.netWorth" />;
  const questionnaireTitle = <Translate name="questionnaire.title" />;

  const getQuestionnaireContent = () => {
    if (showSummary) {
      return (
        <>
          <Header size="large" spacing="xlarge">
            {questionnaireTitle}
          </Header>
          <QuestionnaireSummary
            initialAnswers={initialAnswers}
            questions={prefilledQuestions}
            initialEducationOccupation={initialEducationOccupation}
            questionnairePagesSegments={questionnairePagesSegments}
            onContinue={onContinueSummary}
            onGoBack={onGoBackSummary}
            scoreList={scoreList}
            lossability={lossability}
            netWorth={savedNetWorth}
            investmentTotal={investmentTotal}
            noAnswers={noAnswers}
          />
        </>
      );
    }

    if (showWaiver) {
      return (
        <>
          <Header size="large" spacing="xlarge">
            {riskNoticeTitle}
          </Header>
          <QuestionnaireWaiver
            loading={loading}
            error={error}
            noAnswers={noAnswers}
            onContinue={onContinueWaiver}
            onEditQuestions={onEditQuestions}
            onEditNetWorth={onGoBackSummary}
            isNetWorthSuitable={isNetWorthSuitable}
            isEcsp={isEcsp}
            surveyWaiverType={surveyWaiverType}
          />
        </>
      );
    }

    if (showLossabilityCalculator || questionnairePage === questionnairePagesSegments.length + 1) {
      return (
        <>
          <Header size="large" spacing="xlarge">
            {networthTitle}
          </Header>
          {savedNetWorth ? (
            <SavedNetworth
              savedNetWorth={savedNetWorth}
              restartCalculation={restartNetworthCalculation}
              onSkipNetworth={onSkipNetworth}
            />
          ) : (
            <QuestionnaireLossabilityCalculator
              setLosabilityAnswers={setLosabilityAnswers}
              setShowLossabilityCalculator={setShowLossabilityCalculator}
              onEditQuestions={onEditQuestions}
              setConfirmModalOpen={setConfirmModalOpen}
              onSaveEntries={onSaveLossabilityEntries}
              isSavingDisabled={isSavingDisabled}
            />
          )}
          {isConfirmModalOpen && (
            <ConfirmationNetWorthModal
              onConfirm={onContinueWithoutSavingNetworth}
              onModalClose={() => setConfirmModalOpen(false)}
            />
          )}
        </>
      );
    }

    return <Questionnaire initialAnswers={initialAnswers} {...props} />;
  };

  // don't show the steps in the WizardHeader if the risk notice or the QuestionnaireDisclaimer is displayed
  if (showWaiver || (hasQuestionnaireExistingSelections() && questionnairePage === 1 && !editQuestions)) {
    return getQuestionnaireContent();
  }

  return (
    <>
      <WizardHeader
        subStep={questionnairePage}
        subStepsNumber={isEcsp ? questionnairePagesSegments.length + 1 : questionnairePagesSegments.length}
        changeSubStep={setQuestionnairePage}
      />
      {getQuestionnaireContent()}
    </>
  );
};

export default QuestionnaireStep;
