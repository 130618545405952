import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Section from 'ui/atoms/section';
import { PersonType } from 'ui/types/person';
import { LegalPerson, NaturalPerson } from 'api';
import { UserDataSet } from 'ui/types/user-data-set';
import Hint from 'ui/atoms/hint';
import Grid, { Col } from 'ui/atoms/grid';

export interface IssuerUserInformationProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  userDataSet: UserDataSet;

  userType: 'investor' | 'issuer';

  naturalPerson?: NaturalPerson;

  legalPerson?: LegalPerson;

  personType: PersonType;
}

const IssuerUserInformation: FunctionComponent<IssuerUserInformationProps> = (props) => {
  const { className, userDataSet } = props;

  return (
    <div className={cx('user-information', className)}>
      <Section>
        {Object.entries(userDataSet.fields).map((value: any, key: number) => {
          return (
            <Grid spacing="medium" key={key}>
              <Col>{value[1].label}</Col>
              {value[1].hasOwnProperty('value') && (
                <Col>
                  <PlaceholderFallback>{value[1].value}</PlaceholderFallback>
                </Col>
              )}
            </Grid>
          );
        })}
      </Section>
      {userDataSet.key === 'personalData' && (
        <Hint variant="info">
          <Translate name={'dashboardInvestorSettings.personalData.hint'} />
        </Hint>
      )}
    </div>
  );
};

export default IssuerUserInformation;
