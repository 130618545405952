import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import useClipboard from 'ui/hooks/use-clipboard';
import { ButtonProps } from 'ui/atoms/button';
import Spacing from 'ui/types/spacing';
import * as Styled from './styled';
import InputIcon from 'ui/molecules/input-icon';

export interface CopyInputGroupProps extends ButtonProps {
  /** Primary content. */
  children?: ReactNode;

  valueToCopy?: string;

  /** Additional classes. */
  className?: string;

  /** Spacing can be added to the copy input group */
  spacing?: Spacing;

  disabled?: boolean;
}

const CopyInputGroup: FunctionComponent<CopyInputGroupProps> = (props) => {
  const { className, disabled, valueToCopy } = props;

  const { copy, copied } = useClipboard();

  const onCopyClick = async (textToCopy?: string) => {
    if (!textToCopy) return;
    await copy(textToCopy);
  };

  return (
    <Styled.CopyInput className={cx('copy-input-group', className)}>
      <InputIcon
        value={valueToCopy}
        readOnly={true}
        iconActive={!copied}
        icon={copied ? 'check' : 'copy'}
        iconSize="large"
        color="primary"
        disabled={disabled}
        onClickIcon={() => onCopyClick(valueToCopy)}
      />
    </Styled.CopyInput>
  );
};

export default CopyInputGroup;
