import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Date from 'ui/atoms/date';
import Header from 'ui/atoms/header';
import BenefitingPersonsSummary, { BenefitingPerson } from 'ui/molecules/benefiting-persons-summary';
import PersonName from 'ui/atoms/person-name';
import Section from 'ui/atoms/section';
import List from 'ui/atoms/list';

export interface IdentificationSuccessProps {
  /** Additional classes. */
  className?: string;

  naturalPerson?: {
    forename: string;
    surname: string;
    birthDate?: Date;
    birthPlace?: string;
    identificationDocumentNumber?: string;
  };

  benefitingPersons?: BenefitingPerson[];
}

const IdentificationSuccess: FunctionComponent<IdentificationSuccessProps> = (props) => {
  const { className, naturalPerson, benefitingPersons, ...restProps } = props;

  return (
    <div className={cx('identification-success', className)} {...restProps}>
      <Section>
        <Translate as="p" name="identificationSuccess.success" />
      </Section>

      {naturalPerson && (
        <Section spacing={benefitingPersons ? 'large' : 'none'}>
          <Header size="small" spacing="medium">
            <Translate name="identificationSuccess.personalData" />
          </Header>
          <List>
            <li>
              <PersonName person={naturalPerson} />
            </li>
            {naturalPerson.birthDate && naturalPerson.birthPlace && (
              <li>
                <Translate
                  name="identificationSuccess.born"
                  args={[<Date key={0}>{naturalPerson.birthDate}</Date>, naturalPerson.birthPlace]}
                />
              </li>
            )}
            {naturalPerson.identificationDocumentNumber && (
              <li>
                <Translate
                  name="identificationSuccess.identificationDocumentNumber"
                  args={[naturalPerson.identificationDocumentNumber]}
                />
              </li>
            )}
          </List>
        </Section>
      )}

      {benefitingPersons && (
        <Section>
          <Header size="small">
            <Translate name="identificationSuccess.benefitingPersons" />
          </Header>
          <BenefitingPersonsSummary benefitingPersons={benefitingPersons} />
        </Section>
      )}
    </div>
  );
};

export default IdentificationSuccess;
