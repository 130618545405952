import React, { FunctionComponent, ReactNode, useRef } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import { compact } from 'lodash';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import Input from 'ui/atoms/input';
import DocumentView from 'ui/molecules/document-view';
import FormGroup from 'ui/molecules/form-group';

import { Document } from 'ui/types/document';
import ServerError from 'ui/types/server-error';

import { handleError } from 'ui/helper/error-handling';

import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate, { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import useForm from 'ui/hooks/use-form';
import {
  hasErrorDependingOnTouched,
  validateCurrentDate,
  validateDate,
  validateEmailAddress,
} from 'ui/helper/validators';
import Container from 'ui/atoms/container';
import Header from 'ui/atoms/header';
import { PersonType } from 'ui/types/person';
import Section from 'ui/atoms/section';
import * as Styled from 'src/subapps/investment/pages/investment/wizard-steps/vib/vib-confirmation-form/styled';
import Grid, { Col } from 'ui/atoms/grid';

interface VibInfoNaturalPersonInputFields {
  forename: string;
  surname: string;
  email: string;
  street: string;
  zip: string;
  city: string;
  birthDate: string;
  birthPlace: string;
  documentId: string;
  documentIssuer: string;
}

interface VibInfoLegalPersonInputFields {
  legalCompanyName?: string | null;
  legalEstablishedAt?: string | null;
  legalCommercialRegister?: string | null;
  legalCommercialRegisterNumber?: string | null;
  legalZip?: string | null;
  legalStreet?: string | null;
  legalCity?: string | null;
  legalCountry?: string | null;
  legalEmail?: string | null;
}

export interface VibInfoInputFields extends VibInfoNaturalPersonInputFields, VibInfoLegalPersonInputFields {
  signaturePlace: string;
  signatureDate: string;
}

export type VibInfoInitial = Partial<VibInfoInputFields>;

export type VibInfoPrefill = Partial<Omit<VibInfoInputFields, 'signatureDate' | 'signaturePlace'>>;

interface VibField {
  i18nkey: string;
  name: keyof VibInfoPrefill;
  validator?: (value: string) => boolean;
  placeholder?: string;
  error?: ReactNode;
}

export interface InvestmentVibConfirmationFormProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  personType: PersonType;

  vibDoc: Document;

  loading?: boolean;

  error?: ServerError;

  onSubmit?: (values: VibInfoInputFields) => {};

  prefill?: VibInfoPrefill;

  allowCopyPrefillFields?: boolean;

  initial?: VibInfoInitial;
}

interface VibInputRowProps {
  vibField: VibField;
  setField: any;
  text: any;
  index: number;
  prefill?: VibInfoPrefill;
  allowCopyPrefillFields: boolean;
}

const VibInputRow: FunctionComponent<VibInputRowProps> = (props) => {
  const {
    vibField: { i18nkey, placeholder, error, name, validator },
    text,
    setField,
    prefill = {},
    allowCopyPrefillFields,
  } = props;

  return (
    <div key={i18nkey}>
      <Container padding={false} fluid={true} spacing="medium">
        <Grid>
          <Col width={7}>
            <FormGroup error={error} spacing="small">
              <Input
                label={<Translate name={i18nkey} />}
                {...text(name, { validate: validator })}
                placeholder={placeholder}
              />
            </FormGroup>
          </Col>
          <Col width={5} spacing="medium">
            {prefill[name] && (
              <Styled.PrefillGroup>
                <Styled.PrefillValue>
                  <span>{prefill[name]}</span>
                </Styled.PrefillValue>
                {allowCopyPrefillFields && (
                  <Styled.PrefillButton>
                    <Styled.CopyButton
                      variant="link"
                      icon="copy"
                      color="primary"
                      onClick={() => setField(name, prefill[name] || '')}
                    />
                  </Styled.PrefillButton>
                )}
              </Styled.PrefillGroup>
            )}
          </Col>
        </Grid>
      </Container>
    </div>
  );
};

const InvestmentVibConfirmationForm: FunctionComponent<InvestmentVibConfirmationFormProps> = (props) => {
  const {
    className,
    children,
    personType,
    vibDoc,
    loading = false,
    error,
    onSubmit = () => {},
    prefill = {},
    initial,
    allowCopyPrefillFields = true,
    ...restProps
  } = props;

  const legalPersonInputFields: (keyof VibInfoLegalPersonInputFields)[] = [
    'legalCompanyName',
    'legalEstablishedAt',
    'legalCommercialRegister',
    'legalCommercialRegisterNumber',
    'legalZip',
    'legalStreet',
    'legalCity',
    'legalCountry',
    'legalEmail',
  ];

  const [state, { text, submit }] = useForm<VibInfoInputFields>(
    {
      isEditMode: !!initial,
      validateFields: compact([
        // natural person fields
        'forename',
        'surname',
        'email',
        'street',
        'zip',
        'city',
        'birthDate',
        'birthPlace',
        'documentId',
        'documentIssuer',
        // legal person fields
        ...(personType === PersonType.Legal ? legalPersonInputFields : []),
        // signature fields
        'signaturePlace',
        'signatureDate',
      ]),
    },
    { ...initial },
  );

  const { isValid, setField, values, validity, touched } = state;
  const { onSubmit: onFormSubmit } = submit(onSubmit);

  const translate = useTranslateWithStringArgs();

  const dateFormatRef = useRef(translate('date.dateInputFormat'));
  dateFormatRef.current = translate('date.dateInputFormat');

  // TODO use proper error handling once defined
  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const naturalPersonFields: VibField[] = [
    {
      name: 'forename',
      i18nkey: 'personalInformation.forename',
    },
    {
      name: 'surname',
      i18nkey: 'personalInformation.surname',
    },
    {
      name: 'email',
      i18nkey: 'common.emailShort',
      validator: validateEmailAddress,
    },
    {
      name: 'street',
      i18nkey: 'personalInformation.street',
    },
    { name: 'zip', i18nkey: 'personalInformation.zip' },
    { name: 'city', i18nkey: 'personalInformation.city' },
    {
      name: 'birthDate',
      i18nkey: 'personalInformation.birthDate',
      validator: (value) => {
        return validateDate(value, dateFormatRef.current);
      },
      placeholder: translate('date.datePlaceholder'),
      error:
        hasErrorDependingOnTouched(!!validity.birthDate, touched.birthDate) &&
        (values.birthDate ? (
          <Translate name="date.errors.wrongFormat" />
        ) : (
          <Translate name="inputErrors.empty" args={[<Translate key={0} name="vib.errors.birthDate" />]} />
        )),
    },
    {
      name: 'birthPlace',
      i18nkey: 'personalInformation.birthPlace',
    },
    { name: 'documentId', i18nkey: 'vib.documentId' },
    { name: 'documentIssuer', i18nkey: 'vib.documentIssuer' },
  ];

  const legalPersonFields: VibField[] = [
    {
      name: 'legalCompanyName',
      i18nkey: 'personalInformation.companyName',
    },
    {
      name: 'legalEstablishedAt',
      i18nkey: 'vib.establishmentDate',
      validator: (value) => {
        return validateDate(value, dateFormatRef.current);
      },
      placeholder: translate('date.datePlaceholder'),
      error:
        hasErrorDependingOnTouched(!!validity.legalEstablishedAt, touched.legalEstablishedAt) &&
        (values.legalEstablishedAt ? (
          <Translate name="date.errors.wrongFormat" />
        ) : (
          <Translate name="inputErrors.empty" args={[<Translate key={0} name="vib.errors.establishmentDate" />]} />
        )),
    },
    {
      name: 'legalCommercialRegister',
      i18nkey: 'personalInformation.commercialRegister',
    },
    {
      name: 'legalCommercialRegisterNumber',
      i18nkey: 'personalInformation.commercialRegisterNumber',
    },
    {
      name: 'legalStreet',
      i18nkey: 'personalInformation.street',
    },
    {
      name: 'legalZip',
      i18nkey: 'personalInformation.zip',
    },
    {
      name: 'legalCity',
      i18nkey: 'personalInformation.city',
    },
    {
      name: 'legalCountry',
      i18nkey: 'personalInformation.country',
    },
    {
      name: 'legalEmail',
      i18nkey: 'vib.emailCompany',
      validator: validateEmailAddress,
    },
  ];

  return (
    <div className={cx('investment-vib-confirmation-form', className)} {...restProps}>
      <p>
        <Translate name="vib.text" />
      </p>
      <Section spacing="large">
        <DocumentView {...vibDoc} height="medium" bordered={true} />
      </Section>
      <form noValidate onSubmit={onFormSubmit}>
        <Section spacing={personType === PersonType.Legal ? 'large' : 'none'}>
          {personType === PersonType.Legal && (
            <>
              <Header size="small" spacing="medium">
                <Translate name="vib.titleLegalPerson" />
              </Header>
              <Grid>
                <Col width={7}></Col>
                <Col width={5}>
                  <Header size="xsmall" spacing="small">
                    <Translate name="vib.accountData" />
                  </Header>
                </Col>
              </Grid>
            </>
          )}
          {naturalPersonFields.map((field, index) => (
            <VibInputRow
              allowCopyPrefillFields={allowCopyPrefillFields}
              index={index}
              text={text}
              setField={setField}
              vibField={field}
              prefill={prefill}
            />
          ))}
        </Section>
        {personType === PersonType.Legal && (
          <>
            <Header size="small" spacing="medium">
              <Translate name="vib.titleCompanyInfo" />
            </Header>
            <div className="company-info">
              {legalPersonFields.map((field, index) => (
                <VibInputRow
                  allowCopyPrefillFields={allowCopyPrefillFields}
                  index={index}
                  text={text}
                  setField={setField}
                  vibField={field}
                  prefill={prefill}
                />
              ))}
            </div>
          </>
        )}
        <Grid className="signature" spacing="medium">
          <Col phone={12} width={6} spacing="medium">
            <FormGroup spacing="small">
              <Input label={<Translate name="vib.signaturePlace" />} {...text('signaturePlace')} />
            </FormGroup>
          </Col>
          <Col phone={12} width={6}>
            <FormGroup
              spacing="small"
              error={
                hasErrorDependingOnTouched(!!validity.signatureDate, touched.signatureDate) &&
                (values.signatureDate ? (
                  <Translate name="date.errors.notCurrent" />
                ) : (
                  <Translate name="inputErrors.empty" args={[<Translate key={0} name="vib.errors.signatureDate" />]} />
                ))
              }
            >
              <Input
                label={<Translate name="vib.signatureDate" />}
                {...text('signatureDate', {
                  validate: (value) => validateCurrentDate(value, dateFormatRef.current),
                })}
                placeholder={translate('date.datePlaceholder')}
              />
            </FormGroup>
          </Col>
        </Grid>

        <Button
          type="submit"
          size="large"
          variant="primary"
          error={getRemainingError()}
          fluid={true}
          disabled={!isValid}
          loading={loading}
        >
          <Translate name="common.continue" />
        </Button>
      </form>
    </div>
  );
};

export default InvestmentVibConfirmationForm;
