import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import Translate from 'ui/atoms/translate';
import * as Styled from '../styled';
import { clamp } from 'lodash';
import { MAX_VALUE } from '../index';
import Accordion from 'src/ui/atoms/accordion';

export interface LiquidAssetsProps {
  onChangeLiquidAssets: (savings: number, exchangeSecurities: number, fundAssets: number) => void;
  onChangeTotalSum: (sum: number) => void;
  shouldResetValues?: boolean;
}

const LiquidAssets: FunctionComponent<LiquidAssetsProps> = (props) => {
  const { onChangeLiquidAssets, onChangeTotalSum, shouldResetValues } = props;

  const [isExpanded, setExpanded] = useState(false);
  const [savings, setSavings] = useState(0);
  const [exchangeSecurities, setExchangeSecurities] = useState(0);
  const [fundAssets, setFundAssets] = useState(0);

  useEffect(() => {
    onChangeLiquidAssets(savings, exchangeSecurities, fundAssets);
  }, [savings, exchangeSecurities, fundAssets]);

  useEffect(() => {
    setSavings(0);
    setExchangeSecurities(0);
    setFundAssets(0);
  }, [shouldResetValues]);

  const getTotalIncome = useCallback(() => {
    const total: number = savings + exchangeSecurities + fundAssets;
    onChangeTotalSum(total);

    return total.toString();
  }, [savings, exchangeSecurities, fundAssets]);

  return (
    <Accordion
      label={<Translate name="lossAbility.liquidAssets" />}
      additionalInfo={
        <Styled.NetWorthInput
          type="number"
          textAlign="right"
          height="small"
          value={getTotalIncome()}
          isCurrency
          disabled
        />
      }
      expanded={isExpanded}
      onChange={() => setExpanded(!isExpanded)}
    >
      <>
        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.savings" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={savings.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setSavings(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.exchangeSecurities" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={exchangeSecurities.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setExchangeSecurities(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.fundAssets" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            isCurrency
            value={fundAssets.toString()}
            textAlign="right"
            height="small"
            onChange={(e: any) => setFundAssets(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>
      </>
    </Accordion>
  );
};

export default LiquidAssets;
