import React, { ReactNode, useState } from 'react';
import LoadingSpinner from 'ui/atoms/loading-spinner';
import * as Styled from './styled';
import type { Icons } from 'ui/assets/icons/types';
import type { ButtonIconPositions, ButtonSizes, ButtonVariants } from 'ui/styles/themes/buttons';
import type { PolymorphicComponentPropWithRef, PolymorphicRef, As } from 'ui/helper/get-element-type';
import type { Colors } from 'ui/styles/utils/colors';
import { IconSizes } from 'ui/styles/themes/icons';
import { getResponsiveButtonSize } from 'ui/helper/button-size';
import useIsMedia, { DEVICES } from 'src/ui/hooks/use-is-media';

export interface ActionProps {
  /** A button may have error messages */
  error?: ReactNode; // todo: deprecate this

  /**
   * A button may be one of a variety of visual variants.
   *
   * @default "primary"
   * @expandType true
   */
  variant?: ButtonVariants;

  /**
   * A button can have different sizes.
   *
   * @default "small"
   * @expandType true
   */
  size?: ButtonSizes;

  /**
   * Color of the button
   */
  color?: Colors;

  /** A button can show it is currently the active user selection. */
  active?: boolean;

  /** Whether to render inline with no padding, useful for links */
  inline?: boolean;

  /** Whether to render inline with an underline, useful for links */
  underline?: boolean;

  /** A button can take the width of its container. */
  fluid?: boolean;

  /**
   * Shows up as a dangerous action
   */
  danger?: boolean;

  /** A button can show a loading indicator. */
  loading?: boolean;

  /** A button can be a circle, useful for icon-buttons */
  circular?: boolean;

  /** icon */
  icon?: Icons;

  /** icon size */
  iconSize?: IconSizes;

  stickied?: 'top' | 'right' | 'bottom' | 'left' | 'all';

  /** whether to have fixed width */
  fixedWidth?: boolean;

  /**
   * Icon position.
   *
   * @default "left"
   */
  iconPosition?: ButtonIconPositions;
  /**
   * Icon color.
   *
   */
  iconColor?: Colors;

  /**
   * The wordBreak of Links can be set
   *
   * @default undefined
   *
   * */
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word' | 'initial' | 'inherit';

  /**
   * Classname
   */
  className?: string;
}

type ActionAsProps = ActionProps & As<'button' | 'a' | 'label'>;

type ActionComponentProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, ActionAsProps>;

type ActionComponent = <C extends React.ElementType = 'button'>(
  props: ActionComponentProps<C>,
) => React.ReactElement | null;

/**
 * Polymorphic component used for user actions, whether as a Button or a Link
 */
const Action: ActionComponent = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      children,
      error,
      icon,
      iconSize,
      iconColor,
      variant = 'secondary',
      size = 'small',
      color,
      iconPosition = 'left',
      circular = false,
      loading = false,
      active = false,
      fixedWidth = false,
      fluid = false,
      danger = false,
      inline = false,
      underline = false,
      stickied,
      wordBreak,
      as,
      ...rest
    }: ActionComponentProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const [hovering, setHovering] = useState<boolean>(false);

    const isPhone = useIsMedia(DEVICES.phone);

    return (
      <Styled.Action
        $variant={variant}
        $color={color}
        $size={getResponsiveButtonSize(size, isPhone)}
        $active={active}
        $hovering={hovering}
        $circular={circular}
        $danger={danger || !!error}
        $fixedWidth={fixedWidth}
        $fluid={fluid}
        $inline={inline}
        $underline={underline}
        $stickied={stickied}
        $wordBreak={wordBreak}
        $iconOnly={!children}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        ref={ref}
        as={as || 'button'}
        {...rest}
      >
        <LoadingSpinner loading={loading} backgroundColor={danger ? 'danger' : color} />
        <Styled.ActionContentWrapper
          $loading={loading}
          $variant={variant}
          $underline={underline}
          $active={active}
          $hovering={hovering}
          $inline={inline}
          $size={size}
        >
          {error ? (
            error
          ) : (
            <>
              {icon && iconPosition === 'left' && (
                <Styled.Icon
                  $variant={variant}
                  $circular={circular}
                  $iconOnly={!children}
                  $iconPosition={iconPosition}
                  $size={size}
                  name={icon}
                  size={iconSize}
                  color={iconColor || color || 'primary'}
                />
              )}
              {children && (
                <Styled.ActionText
                  $variant={variant}
                  $active={active}
                  $underline={underline}
                  $hovering={hovering}
                  $size={size}
                >
                  {children}
                </Styled.ActionText>
              )}
              {icon && iconPosition === 'right' && (
                <Styled.Icon
                  name={icon}
                  $variant={variant}
                  $circular={circular}
                  $iconOnly={!children}
                  $size={size}
                  $iconPosition={iconPosition}
                  size={iconSize}
                  color={iconColor || color || 'primary'}
                />
              )}
            </>
          )}
        </Styled.ActionContentWrapper>
      </Styled.Action>
    );
  },
);

export default Action;
