import { Platforms } from 'platforms';
import { CustomImages } from 'ui/assets/images/types';

export const importAssets = async (platform: Platforms): Promise<CustomImages> => {
  // todo, this doesn't work, and neither are platform overrides
  // await import(
  //   /* webpackChunkName: "[request]" */
  //   `platforms/${platform}/assets/icons`
  // );
  return (
    await import(
      /* webpackChunkName: "[request]" */
      `platforms/${platform}/assets/images`
    )
  ).default;
};
