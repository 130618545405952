import React, { FunctionComponent } from 'react';
import { useStyles } from 'ui/styles/hooks';
import { StylesProvider } from './prodiver';
import { useLoadPlatformCss } from 'ui/styles/async-loader';

import type { DeepPartial } from 'ui/helper/typescript-utils';
import type { BaseTheme } from 'ui/styles/theme.types';

export const Styles: FunctionComponent<{
  initialTheme: DeepPartial<BaseTheme>;
}> = ({ children, initialTheme }) => {
  const styles = useStyles({ initialTheme });
  useLoadPlatformCss(styles);

  return <StylesProvider theme={styles.platformTheme}>{children}</StylesProvider>;
};

export default Styles;
