import { query } from 'ui/styles/queries';
import styled from 'styled-components';
import InputIcon from 'ui/molecules/input-icon';
import { resolveColor } from 'ui/styles/utils/colors';

export const Search = styled(InputIcon)`
  min-width: 260px;
  flex-grow: 1;

  ${query.phone`
    min-width: 0;
  `};
`;

export const Dropdown = styled.div`
  display: flex;
  position: relative;
`;

export const ItemsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  background-color: white;
  border: 1px solid ${({ theme }) => resolveColor(theme, 'grayLight')};
  border-radius: 0.25rem;
`;
