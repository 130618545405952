import { ThemeColor } from 'ui/styles/types';

export interface ProgressStepsTheme {
  iconColor: ThemeColor;
  stepIndicatorColor: ThemeColor;
  stepInactiveColor: ThemeColor;
}

export default {
  iconColor: ({ theme }) => theme.colorVariants.primary,
  stepIndicatorColor: ({ theme }) => theme.colors.blueMain,
  stepInactiveColor: '#cccccc',
} as ProgressStepsTheme;
