import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Section from 'ui/atoms/section';
import { InvestorOverview } from 'api';
import { Col } from 'src/ui/atoms/grid';
import Button from 'ui/atoms/button';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import * as Styled from './styled';

export interface OpenTransactionsProps {
  investorOverview: InvestorOverview;
}

const OpenTransactions: FunctionComponent<OpenTransactionsProps> = (props) => {
  const { investorOverview } = props;

  const goToInvesment = useGoTo(INVESTOR_ROUTES.continueInvestments);

  const openTransactionsNumber = investorOverview.numberOpenTransactions;

  return (
    <Section spacing="large">
      <Styled.OpenInvestmentsGrid>
        <Col width={4} tablet={12} phone={12}>
          <Hint variant="info">
            <div>
              <Translate
                name={`dashboardInvestorOverview.${
                  openTransactionsNumber === 1 ? 'openInvestment' : 'openInvestments'
                }`}
                grammaticalNumber={openTransactionsNumber}
                args={[openTransactionsNumber]}
              />
            </div>
          </Hint>
        </Col>
        <Col width={4} tablet={12} phone={12}>
          <Button variant="primary" onClick={goToInvesment}>
            <Translate
              name={`dashboardInvestorOverview.${
                openTransactionsNumber === 1 ? 'continueInvestment' : 'continueInvestments'
              }`}
              grammaticalNumber={openTransactionsNumber}
              args={[openTransactionsNumber]}
            />
          </Button>
        </Col>
      </Styled.OpenInvestmentsGrid>
    </Section>
  );
};

export default OpenTransactions;
