export enum EducationalQualification {
  NotSpecified = 'Not specified',
  HighSchoolDiplomaALevels = 'High school diploma / A-levels',
  SecondarySchoolDiploma = 'Secondary school diploma',
  BachelorAndOrMasterDegree = 'Bachelor and/or Master degree',
  DoctoralDegree = 'Doctoral degree',
}

export enum Occupation {
  NotSpecified = 'Not specified',
  Employee = 'Employee',
  Apprentice = 'Apprentice',
  Pupil = 'Pupil',
  Student = 'Student',
  SelfEmployed = 'Self-employed',
  Retiree = 'Retiree',
  MarginalEmploymentMiniJob = 'Marginal employment (Mini job)',
  WithoutEmployment = 'Without employment',
}

export enum PersonType {
  Natural = 'natural',
  Legal = 'legal',
}

export enum Salutation {
  MR = 'MR',
  MS = 'MS',
  D = 'D',
}

export interface Person {
  pictureURI: string;
  fullName: string;
  eMail: string;
  phone: string;
  calendlyURI?: string;
  title?: string;
}
