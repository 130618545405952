import React, { FunctionComponent, ReactNode } from 'react';
import Spacing from 'ui/types/spacing';
import { ColType, StyledCol } from './styled';

export interface ColProps {
  /** Additional classes */
  className?: string;

  /** Primary content */
  children?: ReactNode;

  /** Width of col on all devices */
  width?: ColType;

  /** Width of col on phone devices */
  phone?: ColType;

  /** Width of col on tablet devices */
  tablet?: ColType;

  /** Width of col on large tablet devices */
  lTablet?: ColType;

  /** Width of col on small desktop devices */
  sDesktop?: ColType;

  /** Width of col on desktop devices */
  desktop?: ColType;

  /** Width of col on large desktop devices */
  lDesktop?: ColType;

  /** Spacing at the bottom */
  spacing?: Spacing;

  disablePadding?: boolean;
}

export const Col: FunctionComponent<ColProps> = ({
  children,
  width,
  phone,
  tablet,
  lTablet,
  sDesktop,
  desktop,
  lDesktop,
  spacing,
  disablePadding,
  className,
}) => {
  return (
    <StyledCol
      $width={width}
      $phone={phone}
      $tablet={tablet}
      $lTablet={lTablet}
      $sDesktop={sDesktop}
      $desktop={desktop}
      $lDesktop={lDesktop}
      $spacing={spacing}
      $disablePadding={disablePadding}
      className={className}
    >
      {children}
    </StyledCol>
  );
};

export default Col;
