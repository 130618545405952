import useApiCall from './use-api-call';
import { useEffect, useMemo, useState } from 'react';
import { Wallet } from '../api/models';
import { WalletsApi } from '../api/apis';

export const useWallets = () => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);
  const [wallets, setWallets] = useState<Wallet[]>();

  const walletsApi: WalletsApi = useMemo(() => makeAuthenticatedApi(WalletsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      setWallets(await walletsApi.walletsList());
    });
  }, [withApi, walletsApi]);

  return {
    wallets,
    error,
    loading,
  };
};
