import React, { FunctionComponent } from 'react';
import RouterLink from 'core/routing/router-link';
import Translate from 'ui/atoms/translate';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import ProfileAccess from 'ui/molecules/profile-access';
import Logo from 'ui/atoms/logo';
import ProgressSteps, { ProgressStep } from 'core/layout/wizard/progress-steps';
import * as Styled from './styled';
import LanguageSelectorNavigationItem from 'ui/molecules/language-selector-navigation-item';
import PlatformSelectorItem from 'core/nav/navbar/platform-selector-item';

export interface WizardNavBarMobileProps {
  showInvestorOverview?: boolean;
  progressSteps: ProgressStep[];
  registerOnlyFlow?: boolean;
  isUserConfirmed: boolean;
  isLoggedIn: boolean;
  activeLocale: string;
  switchLocale: (locale: string) => void;
  onLogout: () => void;
}

const WizardNavBarMobile: FunctionComponent<WizardNavBarMobileProps> = (props) => {
  const {
    showInvestorOverview,
    progressSteps,
    registerOnlyFlow,
    isUserConfirmed,
    isLoggedIn,
    activeLocale,
    switchLocale,
    onLogout,
  } = props;

  return (
    <Styled.NavBarMobile>
      <Styled.NavBarRow>
        <Logo logoType="inverted" variant="investment" spacing="none" />
        <Styled.ActionGroup>
          <PlatformSelectorItem />
          {(!isLoggedIn || !isUserConfirmed) && (
            <LanguageSelectorNavigationItem active={activeLocale} onLocaleChange={switchLocale} />
          )}
          {isLoggedIn && isUserConfirmed && (
            <ProfileAccess
              activeLocale={activeLocale}
              showInvestorOverview={showInvestorOverview}
              registerOnlyFlow={registerOnlyFlow}
              onLocaleChange={switchLocale}
              onLogout={() => onLogout()}
            />
          )}
          {!isLoggedIn && (
            <RouterLink route={AUTH_ROUTES.login} className="nav-link" fluid>
              <Translate name="common.login">Login</Translate>
            </RouterLink>
          )}
        </Styled.ActionGroup>
      </Styled.NavBarRow>
      {progressSteps.length > 0 && <ProgressSteps steps={progressSteps} />}
    </Styled.NavBarMobile>
  );
};

export default WizardNavBarMobile;
