import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi } from 'api';
import { Campaign } from 'api/models';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import BoxedIssuerCampaign from 'apps/issuer/pages/product-details/campaigns/campaign-details/boxed-campaign';
import { currentTokenHasManageDistributionPermission } from 'apps/issuer/helpers/permissions';
import Link from 'core/routing/link';
import CampaignInvestments from './campaign-investments';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';
import Button from 'src/ui/atoms/button';
import AddInvestmentModal from './add-investment-modal';
import Header from 'src/ui/atoms/header';
import { hasDistributionCreateDataPermission } from 'src/apps/issuer/helpers/distributionPlatformPermissions';

const CampaignDetailsPage = (props: { campaignId: string }) => {
  const { campaignId } = props;

  const { withApi, makeAuthenticatedApi } = useApiCall(true);

  const [campaignDetails, setCampaignDetails] = useState<Campaign>();
  const [isAddInvestmentModalOpen, setAddInvestmentModalOpen] = useState(false);
  const [forceDataLoad, setForceDataLoad] = useState(false);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!campaignId) {
        return;
      }
      withApi(async () => {
        const campaignDetailsData = await api.adminCampaignsRetrieve({
          id: campaignId,
        });

        setCampaignDetails(campaignDetailsData);
      });
    });
  }, [withApi, api, campaignId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const refreshTableData = useCallback(() => {
    setForceDataLoad(true);
    setAddInvestmentModalOpen(false);
    setForceDataLoad(false);
  }, []);

  if (!campaignDetails) return null;

  const canManage = currentTokenHasManageDistributionPermission(campaignDetails.token);

  return (
    <WideContent title={campaignDetails?.token?.name}>
      {campaignDetails.name && (
        <Section spacing="medium">
          <Translate name={'issuerProductDetails.campaigns.campaignDetails.subtitle'} args={[campaignDetails.name]} />
        </Section>
      )}
      <BoxedIssuerCampaign key={campaignDetails.id} issuersCanManage={canManage} {...campaignDetails} />
      <Section spacing="medium">
        <Header size="small">
          <Translate name={'issuerProductDetails.campaigns.campaignDetails.investments.title'} />
        </Header>
        <p>
          <Translate name={'issuerProductDetails.campaigns.campaignDetails.investments.subtitle'} />
        </p>
        {hasDistributionCreateDataPermission([campaignDetails.distributionPlatformDetails]) && (
          <Button variant="secondary" icon="plus" size="xsmall" onClick={() => setAddInvestmentModalOpen(true)}>
            <Translate name="common.addEntry" />
          </Button>
        )}
      </Section>
      <CampaignInvestments
        campaignId={campaignId}
        defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
        forceDataLoad={forceDataLoad}
        filters={<IssuerInvestmentsFilters />}
      />
      <Section spacing="medium">
        <Link icon="arrow-left" to={`/dashboard/product/${campaignDetails.token.id}`}>
          <Translate as="span" name="issuerProductDetails.campaigns.campaignDetails.backToProductDetails" />
        </Link>
      </Section>

      {isAddInvestmentModalOpen && (
        <AddInvestmentModal
          onClose={() => setAddInvestmentModalOpen(false)}
          onAddNewInvestment={refreshTableData}
          campaignId={campaignId}
        />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(CampaignDetailsPage, AppType.ISSUER);
