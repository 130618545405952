/*
  todo maik: this organism will be reused later to resemble:
  https://www.sketch.com/s/e86c8353-42d5-47b2-8019-0c74db324f6c/a/bg4bQMJ
  currently this is unused
*/

import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';

export interface WalletCreationSuccessProps {
  /** Additional classes. */
  className?: string;

  receiverEmail?: string;

  walletConfirmed?: boolean;

  onContinue?: () => void;
}

const WalletCreationSuccess: FunctionComponent<WalletCreationSuccessProps> = (props) => {
  const { receiverEmail, className, children, walletConfirmed, onContinue = () => {}, ...restProps } = props;

  return (
    <div className={cx('wallet-creation-success', className)} {...restProps}>
      <Button variant="primary" size="large" onClick={onContinue} fluid={true}>
        <Translate name="common.continue" />
      </Button>
    </div>
  );
};

export default WalletCreationSuccess;
