import React, { FunctionComponent, useEffect } from 'react';

import FormGroup from 'ui/molecules/form-group';
import Checkbox from 'ui/atoms/checkbox';
import Translate from 'ui/atoms/translate';
import useForm, { ChangeWithValidationState } from 'ui/hooks/use-form';
import { Money } from 'api';
import Currency from 'ui/atoms/currency';
import { nullMoney, toNumber } from 'ui/helper/money';
import { compact } from 'lodash';
import Header from 'ui/atoms/header';
import Hint from 'ui/atoms/hint';

export interface SelfDisclosureFields {
  sufficientDoubleIncome?: boolean;
  sufficientLiquidAssets?: boolean;
  confirmedProfessionalInvestor?: boolean;
}

export interface SelfDisclosureFieldValues {
  doubleIncome?: Money;
  liquidAssets?: Money;
  confirmedProfessionalInvestor?: boolean;
}

export interface SelfDisclosureRequirements {
  investmentTotal?: Money;
  minimumDoubleIncome?: Money;
  minimumLiquidAssets?: Money;
  confirmationProfessionalRequired?: boolean;
}

export interface SelfDisclosureProps extends SelfDisclosureRequirements {
  onRequirementChecked?: (state: ChangeWithValidationState<SelfDisclosureFieldValues>) => void;
  initial: {
    selfDisclosure?: SelfDisclosureFieldValues;
  };
  selfDisclosureInvestmentAmountExceeds25k?: boolean;
}
const SelfDisclosure: FunctionComponent<SelfDisclosureProps> = (props) => {
  const {
    investmentTotal,
    minimumDoubleIncome,
    minimumLiquidAssets,
    confirmationProfessionalRequired,
    selfDisclosureInvestmentAmountExceeds25k,
    onRequirementChecked = () => {},
    initial: { selfDisclosure: initialSelfDisclosureValues },
  } = props;

  const showMinimumDoubleIncomeCheckbox =
    !confirmationProfessionalRequired && !!minimumDoubleIncome && toNumber(minimumDoubleIncome) > 0;
  const showMinimumLiquidAssetsCheckbox =
    !confirmationProfessionalRequired && !!minimumLiquidAssets && toNumber(minimumLiquidAssets) > 0;
  const showConfirmationProfessionalCheckbox = confirmationProfessionalRequired;

  const checkboxCount = compact([
    showMinimumDoubleIncomeCheckbox,
    showMinimumLiquidAssetsCheckbox,
    showConfirmationProfessionalCheckbox,
  ]).length;

  const shouldCheckRequirements = !!checkboxCount;

  const initialInput: SelfDisclosureFields | undefined = {
    sufficientDoubleIncome:
      !!initialSelfDisclosureValues?.doubleIncome && toNumber(initialSelfDisclosureValues.doubleIncome) > 0,
    sufficientLiquidAssets:
      !!initialSelfDisclosureValues?.liquidAssets && toNumber(initialSelfDisclosureValues.liquidAssets) > 0,
    confirmedProfessionalInvestor: !!initialSelfDisclosureValues?.confirmedProfessionalInvestor,
  };

  const [state, { checkbox }] = useForm<SelfDisclosureFields>(
    {
      validateFields: ['sufficientDoubleIncome', 'sufficientLiquidAssets', 'confirmedProfessionalInvestor'],
    },
    { ...initialInput },
  );

  const { values } = state;

  useEffect(() => {
    if (shouldCheckRequirements) {
      onRequirementChecked({
        isValid:
          (showMinimumDoubleIncomeCheckbox && !!values?.sufficientDoubleIncome) ||
          (showMinimumLiquidAssetsCheckbox && !!values?.sufficientLiquidAssets) ||
          (showConfirmationProfessionalCheckbox && !!values?.confirmedProfessionalInvestor),
        values: {
          ...(minimumDoubleIncome &&
            showMinimumDoubleIncomeCheckbox && {
              doubleIncome: (values.sufficientDoubleIncome && minimumDoubleIncome) || nullMoney,
            }),
          ...(minimumLiquidAssets &&
            showMinimumLiquidAssetsCheckbox && {
              liquidAssets: (values.sufficientLiquidAssets && minimumLiquidAssets) || nullMoney,
            }),
          ...(showConfirmationProfessionalCheckbox && {
            confirmedProfessionalInvestor: values.confirmedProfessionalInvestor,
          }),
        },
      });
    } else {
      onRequirementChecked({ isValid: true, values: {} });
    }
  }, [
    shouldCheckRequirements,
    minimumDoubleIncome,
    minimumLiquidAssets,
    confirmationProfessionalRequired,
    values.sufficientDoubleIncome,
    values.sufficientLiquidAssets,
    values.confirmedProfessionalInvestor,
    onRequirementChecked,
    showMinimumLiquidAssetsCheckbox,
    showConfirmationProfessionalCheckbox,
    showMinimumDoubleIncomeCheckbox,
  ]);

  if (selfDisclosureInvestmentAmountExceeds25k) {
    return (
      <Hint variant="warning">
        <Translate name="investmentAmount.errors.selfDisclosureInvestmentAmountExceeds25k" />
      </Hint>
    );
  }

  if (!investmentTotal) return null;

  return (
    <>
      {checkboxCount === 2 && (
        <Header as="h3" size="small">
          <Translate name="investmentAmount.selfDisclosure.confirmOneOutOfTwo" />
        </Header>
      )}
      {checkboxCount === 1 && (
        <Header as="h3" size="small">
          <Translate name="investmentAmount.selfDisclosure.confirmOne" />
        </Header>
      )}
      {showMinimumDoubleIncomeCheckbox && (
        <FormGroup>
          <Checkbox {...checkbox('sufficientDoubleIncome')} required={false}>
            <Translate
              name="investmentAmount.selfDisclosure.sufficientDoubleIncome"
              args={[(_, key) => <Currency key={key}>{investmentTotal}</Currency>]}
            />
          </Checkbox>
        </FormGroup>
      )}
      {showMinimumLiquidAssetsCheckbox && minimumLiquidAssets && (
        <FormGroup>
          <Checkbox {...checkbox('sufficientLiquidAssets')} required={false}>
            <Translate
              name="investmentAmount.selfDisclosure.sufficientLiquidAssets"
              args={[(_, key) => <Currency key={key}>{minimumLiquidAssets}</Currency>]}
            />
          </Checkbox>
        </FormGroup>
      )}
      {showConfirmationProfessionalCheckbox && (
        <FormGroup>
          <Checkbox {...checkbox('confirmedProfessionalInvestor')} required={false}>
            <Translate name="investmentAmount.selfDisclosure.confirmedProfessionalInvestor" />
          </Checkbox>
        </FormGroup>
      )}
    </>
  );
};

export default SelfDisclosure;
