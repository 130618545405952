import React, { FunctionComponent, ReactNode } from 'react';
import Container from 'ui/atoms/container';
import PageTitle from 'core/components/page-title';
import Grid, { Col } from 'ui/atoms/grid';

export interface NarrowContentProps {
  children: ReactNode;
}

const NarrowContent: FunctionComponent<NarrowContentProps> = ({ children }) => {
  return (
    <Container>
      <Grid>
        <Col
          desktop={{ width: 6, offset: 3 }}
          sDesktop={{ width: 6, offset: 3 }}
          lDesktop={{ width: 6, offset: 3 }}
          phone={12}
        >
          {React.Children.map(children, (element, i) => {
            if (!React.isValidElement(element)) return;
            const { title } = element.props;

            return (
              <>
                {title && !i && <PageTitle renderInPlace={false}>{title}</PageTitle>}
                {element}
              </>
            );
          })}
        </Col>
      </Grid>
    </Container>
  );
};

export default NarrowContent;
