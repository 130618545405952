import { useContext, useEffect, useState } from 'react';
import { PlatformImages } from 'ui/assets/images/types';
import { AssetsContext } from 'ui/assets/provider';

export const usePlatformImage = (name: PlatformImages) => {
  const images = useContext(AssetsContext);

  const [url, setUrl] = useState<string>(images[name]);

  useEffect(() => {
    setUrl(images?.[name]);
  }, [name, images?.[name]]);

  return url;
};

export default usePlatformImage;
