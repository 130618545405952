export enum ProductType {
  DIGITAL_COMPANY_SHARE = 'DIGITAL_COMPANY_SHARE',
  BOND = 'BOND',
  SUBORDINATED_LOAN = 'SUBORDINATED_LOAN',
  PARTICIPATION_PAPER = 'PARTICIPATION_PAPER',
  PARTICIPATION_RIGHT = 'PARTICIPATION_RIGHT',
  AIF = 'AIF',
  TOKENIZEDLIMITEDPARTNERSHARES = 'TOKENIZED_LIMITED_PARTNER_SHARES',
  TOKENIZEDARTWORK = 'TOKENIZED_ARTWORK',
  DEBTSECURITY = 'DEBT_SECURITY',
  CONVERTIBLEBOND = 'CONVERTIBLE_BOND',
  FUNDSHARE = 'FUND_SHARE',
}
