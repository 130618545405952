import { InvestmentToken } from 'api';
import { useProducts } from 'apps/issuer/hooks';
import React, { FunctionComponent, useMemo } from 'react';
import Button from 'ui/atoms/button';
import Checkbox from 'ui/atoms/checkbox';
import Grid, { Col } from 'ui/atoms/grid';
import Header from 'ui/atoms/header';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import { VoucherFields } from '..';
import { useProductValidity } from './hooks';
import Spacer from 'ui/atoms/spacer';

interface VoucherUsesByInvestorProps {
  Form: any;
  values: VoucherFields;
  selectedValidityProducts: string[] | undefined;
  selectedDistributionPlatform: string;
  onChangeValidity: (products: InvestmentToken[] | string) => void;
}

export enum ProductsOptions {
  ALL_PRODUCTS = 'allProducts',
  SPECIFIC = 'specific',
}

const MAX_PRODUCTS_SHOWN = 10;

const ProductValidity: FunctionComponent<VoucherUsesByInvestorProps> = ({
  Form,
  values,
  selectedValidityProducts,
  selectedDistributionPlatform,
  onChangeValidity,
}) => {
  const { tokens: products } = useProducts();

  const productsOfSelectedDistributionPlatform = useMemo(
    () =>
      selectedDistributionPlatform && !!products?.length
        ? products?.filter((product) =>
            product.distributionPlatforms.some((platform) => platform.id === selectedDistributionPlatform),
          )
        : [],
    [selectedDistributionPlatform, products],
  );

  const {
    selectedProducts,
    firstColumnProducts,
    secondColumnProducts,
    showAllProducts,
    setShowAllProducts,
    onChangeProduct,
    onProductsValidityChange,
  } = useProductValidity(
    productsOfSelectedDistributionPlatform,
    MAX_PRODUCTS_SHOWN,
    values,
    selectedValidityProducts,
    onChangeValidity,
  );

  const renderColumn = (products: InvestmentToken[]) => (
    <Col width={6}>
      {products.length > 0 &&
        products.map((product: InvestmentToken) => (
          <Checkbox
            key={product.id}
            checked={selectedProducts.includes(product)}
            onChange={(evt) => onChangeProduct(product, (evt.target as HTMLInputElement).checked)}
          >
            {product.name}
          </Checkbox>
        ))}
    </Col>
  );

  return (
    <Section spacing="medium">
      <Header size="small" spacing="medium">
        <Translate name="createVoucherForm.validity.title" />
      </Header>
      <Form.Group name="validityProducts" required={true}>
        <Section spacing="none">
          <strong>
            <Translate name={`createVoucherForm.validity.label`} />
          </strong>
          <Spacer y={2} />
          <Form.GroupToggle>
            {Object.values(ProductsOptions).map((option) => (
              <Form.Radio value={option} key={option} onClick={onProductsValidityChange}>
                <Translate name={`createVoucherForm.validity.${option}`} />
              </Form.Radio>
            ))}
          </Form.GroupToggle>
          {values.validityProducts === 'specific' && (
            <>
              <Grid>
                {renderColumn(firstColumnProducts)}
                {renderColumn(secondColumnProducts)}
              </Grid>
              {productsOfSelectedDistributionPlatform &&
                productsOfSelectedDistributionPlatform.length > MAX_PRODUCTS_SHOWN &&
                !showAllProducts && (
                  <Button variant="link" onClick={() => setShowAllProducts(true)}>
                    <Translate name={`createVoucherForm.showAll`} />
                  </Button>
                )}
            </>
          )}
        </Section>
      </Form.Group>
    </Section>
  );
};

export default ProductValidity;
