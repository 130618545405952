import React, { FunctionComponent, ReactNode } from 'react';
import PublicPage from 'core/auth/components/public-page';
import LoginMask, { LoginForm } from './login-mask';
import { useLogin, useRedirectPathSelector } from 'core/auth/hooks';
import Translate from 'ui/atoms/translate';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import useGoTo from 'hooks/use-go-to';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import Section from 'ui/atoms/section';
import Header from 'ui/atoms/header';
import { AppType } from 'core/auth/types';
import applicationConfig from 'core/config/local';

export interface LoginHooks {
  top?: ReactNode;
  bottom?: ReactNode;
}

interface LoginPageProps {
  initial?: {
    email?: string;
  };
}

interface LoginCardProps {
  initial?: {
    email?: string;
  };
  hooks?: LoginHooks;
  onRegisterClick?: () => void;
  redirectToDashboard?: boolean;
}

export const LoginCard: FunctionComponent<LoginCardProps> = (props) => {
  const { hooks, initial, redirectToDashboard, onRegisterClick } = props;

  const { loading, error, login } = useLogin();

  const goToForgotPassword = useGoTo(AUTH_ROUTES.forgotPassword);

  const goToDashboard = useGoTo({ path: '/dashboard' });

  const handleLogin = (email: string, password: string, distributionPlatformName: string) => {
    login(email, password, distributionPlatformName);
    if (redirectToDashboard) {
      goToDashboard();
    }
  };

  return (
    <BoxedContent title={!hooks?.top && <Translate name="login.title" />}>
      <LoginForm
        error={error}
        onSubmit={({ email, password }) => handleLogin(email, password, applicationConfig.platform)}
        i18nKey="loginForm"
      >
        <LoginMask
          onForgotPassword={() => {
            goToForgotPassword();
          }}
          hooks={hooks}
          initial={initial}
        />
        <LoginForm.GenericErrorMessages />
        <ActionButtons fullWidth spacingTop="small">
          {onRegisterClick && (
            <Button type="submit" variant="link" size="large" fluid onClick={onRegisterClick}>
              <Translate name="common.register" />
            </Button>
          )}
          <Button type="submit" variant="primary" size="large" loading={loading} fluid>
            <Translate name="common.login" />
          </Button>
        </ActionButtons>
      </LoginForm>
    </BoxedContent>
  );
};

const LoginPage: FunctionComponent<LoginPageProps> = (props) => {
  const { initial } = props;

  const { origin: appType } = useRedirectPathSelector();

  const hooks =
    appType !== AppType.DEFAULT
      ? {
          top: (
            <Section>
              <Header>
                <Translate name={`login.header.${appType}`} />
              </Header>
              <Translate as="p" name="login.description" />
            </Section>
          ),
        }
      : {};

  return (
    <PublicPage>
      <NarrowContent>
        <LoginCard initial={initial} hooks={hooks} />
      </NarrowContent>
    </PublicPage>
  );
};

export default LoginPage;
