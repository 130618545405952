import { useCallback, useState } from 'react';
import { ApiServerError, UnknownServerError, createServerErrorFromException } from 'core/api/errors';

export type AnyServerError = ApiServerError | UnknownServerError;

interface Res {
  error: AnyServerError | undefined;
  setException: (exc: any) => void;
  setError: (err: AnyServerError | undefined) => void;
}

export const useApiError = (): Res => {
  const [error, setError] = useState<ApiServerError | UnknownServerError | undefined>(undefined);
  const setException = useCallback((exc: any) => setError(createServerErrorFromException(exc)), []);
  return { error, setException, setError };
};

export default useApiError;
