import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import { WalletType } from 'ui/types/wallet';
import { camelCase } from 'change-case';
import Number from 'ui/atoms/number';
import Hint from 'ui/atoms/hint';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import { Balance, NetworkTypeEnum } from 'api';
import { getNetworkTypeName } from 'ui/helper/networks';
import { makeBigInt } from 'ui/helper/big-number';
import StatusTag from 'src/ui/atoms/status-tag';
import Spacer from 'src/ui/atoms/spacer';

export interface DashboardWalletInfoProps {
  /** Additional classes. */
  className?: string;

  type: WalletType;

  address: string | null | undefined;

  balance: Balance | undefined;

  managed: boolean;

  isFrozen: boolean;

  networkType: NetworkTypeEnum | undefined;
}

const DashboardWalletInfo: FunctionComponent<DashboardWalletInfoProps> = (props) => {
  const { className, address, type, balance, managed, networkType, isFrozen, ...restProps } = props;

  const activeBalance = balance
    ? makeBigInt(0).plus(makeBigInt(balance.total)).minus(makeBigInt(balance.frozen))
    : makeBigInt(0);
  const pendingBalance = balance ? makeBigInt(balance.pendingOutgoing) : makeBigInt(0);

  return (
    <div className={cx('dashboard-wallet-info', className)} {...restProps}>
      <Section spacing={managed ? 'none' : 'medium'}>
        <InfoGrid columns={3} truncate={true}>
          <InfoField title={<Translate name="dashboardWalletInfo.wallet" />} truncate={true}>
            <Translate name={`walletTypeForDisplay.${camelCase(type)}`} />
          </InfoField>
          <InfoField
            title={
              <Translate
                name={
                  networkType
                    ? `dashboardWalletInfo.walletAddress${getNetworkTypeName(networkType)}`
                    : `dashboardWalletInfo.walletAddress`
                }
              />
            }
            truncate={true}
          >
            <PlaceholderFallback>{address}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="dashboardWalletInfo.balance" />} truncate={true}>
            <Translate
              name="dashboardWalletInfo.activeBalanceUnits"
              args={[(_, key) => <Number key={key}>{activeBalance}</Number>]}
            />
            {!pendingBalance.eq(0) && (
              <p>
                <small>
                  <Translate
                    name="dashboardWalletInfo.pendingBalanceUnits"
                    args={[(_, key) => <Number key={key}>{pendingBalance}</Number>]}
                  />
                </small>
              </p>
            )}
          </InfoField>
        </InfoGrid>
        <Spacer y={4} />
        <InfoField title={<Translate name="common.status" />} truncate={true}>
          {isFrozen ? (
            <StatusTag variant="warning">
              <Translate name="dashboardWalletInfo.temporaryFrozen" />
            </StatusTag>
          ) : (
            <StatusTag variant="success">
              <Translate name="dashboardWalletInfo.active" />
            </StatusTag>
          )}
        </InfoField>
      </Section>
      {!managed && (
        <Hint variant="info">
          <Translate name="dashboardWalletInfo.walletNotManagedInfo" />
        </Hint>
      )}
    </div>
  );
};

export default DashboardWalletInfo;
