import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Hint from 'ui/atoms/hint';
import makeForm from 'ui/forms';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';

export interface CommunicationSettingsFields {
  optinEmail?: boolean;
  optinMail?: boolean;
  optinPhone?: boolean;
}

type CommunicationOptions = 'optinMail' | 'optinEmail' | 'optinPhone';

export interface ChangeCommunicationSettingsProps {
  /** Additional classes. */
  className?: string;

  communicationChangedSuccessfully: boolean;

  communicationOptions: Array<string>;
}

export const ChangeCommunicationSettingsForm = makeForm<CommunicationSettingsFields>();

const ChangeCommunicationSettings: FunctionComponent<ChangeCommunicationSettingsProps> = (props) => {
  const { className, communicationChangedSuccessfully, communicationOptions, ...restProps } = props;

  return (
    <div className={cx('change-communication-settings', className)} {...restProps}>
      {communicationChangedSuccessfully && (
        <>
          <Hint variant="success">
            <Translate name="changeCommunicationSettings.success" />
          </Hint>
        </>
      )}
      {!communicationChangedSuccessfully && (
        <>
          <Section>
            <Translate as="p" name="changeCommunicationSettings.description" />
          </Section>
          <Section>
            {communicationOptions?.length > 0 &&
              communicationOptions.map((communicationOption: CommunicationOptions) => (
                <ChangeCommunicationSettingsForm.Group
                  // Enforced type casting due to conversion from snake case to camel case
                  name={snakeToCamelCaseWithNumbers(communicationOption) as CommunicationOptions}
                  key={snakeToCamelCaseWithNumbers(communicationOption)}
                >
                  <ChangeCommunicationSettingsForm.Checkbox>
                    <Translate
                      name={`changeCommunicationSettings.${snakeToCamelCaseWithNumbers(communicationOption)}`}
                    />
                  </ChangeCommunicationSettingsForm.Checkbox>
                </ChangeCommunicationSettingsForm.Group>
              ))}
          </Section>
        </>
      )}
    </div>
  );
};

export default ChangeCommunicationSettings;
