import React, { FunctionComponent, ReactNode } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';

export interface SuccessMessageModalProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  title?: ReactNode;

  description?: ReactNode;

  onHideSuccessEdit: () => void;
}

const SuccessMessageModal: FunctionComponent<SuccessMessageModalProps> = ({
  title,
  children,
  onHideSuccessEdit,
  description,
}) => {
  return (
    <Modal onClose={onHideSuccessEdit}>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <Hint variant="success">{children}</Hint>

        {description && <p>{description}</p>}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          align="start"
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              size: 'large',
              onClick: onHideSuccessEdit,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default SuccessMessageModal;
