import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { PasswordStrength } from 'ui/atoms/strength-indicator';
import Section from 'ui/atoms/section';
import { CreateAccountForm } from 'libraries/wizard/components/account-setup/create-account/helpers';
import Header from 'ui/atoms/header';

export interface AccountCredentialsProps {
  shouldValidateEmail?: boolean;
  passwordStrength?: PasswordStrength | undefined;
}

const AccountCredentials: FunctionComponent<AccountCredentialsProps> = ({ shouldValidateEmail, passwordStrength }) => {
  return (
    <Section>
      <Header size="small">
        <Translate name="createAccount.accountCredentials.headline" />
      </Header>
      <CreateAccountForm.Group name="email" required={true}>
        <CreateAccountForm.Input disabled={!shouldValidateEmail} />
        {shouldValidateEmail && CreateAccountForm.Validators.Email()}
      </CreateAccountForm.Group>
      <CreateAccountForm.PasswordGroup
        passwordField={{
          name: 'password',
        }}
        passwordRepeatField={{
          name: 'passwordRepeat',
        }}
        passwordStrength={passwordStrength}
      />
    </Section>
  );
};

export default AccountCredentials;
