import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import Translate from 'ui/atoms/translate';
import * as Styled from '../styled';
import { clamp } from 'lodash';
import { MAX_VALUE } from '../index';
import Accordion from 'src/ui/atoms/accordion';

export interface YearlyLiabilitiesProps {
  onChangeYearlyLiabilities: (
    housekeeping: number,
    rentAndMortgage: number,
    redemption: number,
    insurance: number,
    supplyService: number,
    subscriptions: number,
    taxes: number,
  ) => void;
  onChangeTotalSum: (sum: number) => void;
  shouldResetValues?: boolean;
}

const YearlyLiabilities: FunctionComponent<YearlyLiabilitiesProps> = (props) => {
  const { onChangeYearlyLiabilities, onChangeTotalSum, shouldResetValues } = props;

  const [isExpanded, setExpanded] = useState(false);
  const [housekeeping, setHousekeeping] = useState(0);
  const [rentAndMortgage, setRentAndMortgage] = useState(0);
  const [redemption, setRedemption] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [supplyService, setSupplyService] = useState(0);
  const [subscriptions, setSubscriptions] = useState(0);
  const [taxes, setTaxes] = useState(0);

  useEffect(() => {
    onChangeYearlyLiabilities(
      housekeeping,
      rentAndMortgage,
      redemption,
      insurance,
      supplyService,
      subscriptions,
      taxes,
    );
  }, [housekeeping, rentAndMortgage, redemption, insurance, supplyService, subscriptions, taxes]);

  useEffect(() => {
    setHousekeeping(0);
    setRentAndMortgage(0);
    setRedemption(0);
    setInsurance(0);
    setSupplyService(0);
    setSubscriptions(0);
    setTaxes(0);
  }, [shouldResetValues]);

  const getTotalIncome = useCallback(() => {
    const total: number =
      housekeeping + rentAndMortgage + redemption + insurance + supplyService + subscriptions + taxes;
    onChangeTotalSum(total);

    return total.toString();
  }, [housekeeping, rentAndMortgage, redemption, insurance, supplyService, subscriptions, taxes]);

  return (
    <Accordion
      label={<Translate name="lossAbility.yearlyLiabilities" />}
      additionalInfo={
        <Styled.NetWorthInput
          type="number"
          textAlign="right"
          height="small"
          value={getTotalIncome()}
          disabled
          isCurrency
        />
      }
      expanded={isExpanded}
      onChange={() => setExpanded(!isExpanded)}
    >
      <>
        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.housekeeping" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={housekeeping.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setHousekeeping(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.rentAndMortgage" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={rentAndMortgage.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setRentAndMortgage(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.redemption" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={redemption.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setRedemption(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.insurance" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={insurance.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setInsurance(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.supplyService" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={supplyService.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setSupplyService(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.subscriptions" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={subscriptions.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setSubscriptions(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.taxes" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={taxes.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setTaxes(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>
      </>
    </Accordion>
  );
};

export default YearlyLiabilities;
