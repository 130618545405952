import accents from 'ui/styles/themes/accents';
import buttons from 'ui/styles/themes/buttons';
import cards from 'ui/styles/themes/cards';
import colors from 'ui/styles/themes/colors';
import colorVariants from 'ui/styles/themes/color-variants';
import common from 'ui/styles/themes/common';
import forms from 'ui/styles/themes/forms';
import dropdown from './themes/dropdown';
import icons from 'ui/styles/themes/icons';
import images from 'ui/styles/themes/images';
import input from 'ui/styles/themes/input';
import lists from 'ui/styles/themes/lists';
import logo from 'ui/styles/themes/logo';
import margin from 'ui/styles/themes/margin';
import modal from 'ui/styles/themes/modal';
import navigation from 'ui/styles/themes/navigation';
import padding from 'ui/styles/themes/padding';
import predefinedInputOption from 'ui/styles/themes/predefined-input-option';
import progressSteps from 'ui/styles/themes/progress-steps';
import popups from 'ui/styles/themes/popups';
import segment from 'ui/styles/themes/segment';
import sideOverlay from 'ui/styles/themes/side-overlay';
import sidebar from 'ui/styles/themes/sidebar';
import sizes from 'ui/styles/themes/sizes';
import spacing from 'ui/styles/themes/spacing';
import sticky from 'ui/styles/themes/sticky';
import tables from 'ui/styles/themes/tables';
import tag from 'ui/styles/themes/tag';
import typography from './themes/typography';
import zIndices from 'ui/styles/themes/z-index';

import type { BaseTheme } from 'ui/styles/theme.types';

const defaultTheme: BaseTheme = {
  name: 'default',
  accents,
  buttons,
  colors,
  colorVariants,
  common,
  cards,
  dropdown,
  sizes,
  forms,
  icons,
  images,
  input,
  lists,
  logo,
  margin,
  modal,
  navigation,
  padding,
  predefinedInputOption,
  progressSteps,
  popups,
  segment,
  sideOverlay,
  sidebar,
  spacing,
  sticky,
  tables,
  tag,
  typography,
  zIndices,
};

export default defaultTheme;
