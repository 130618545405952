import Login from './login';
import ResetPassword from './reset-password';
import ForgotPassword from './forgot-password';
import EmailConfirmation from './confirm-email-callback';
import ExternalInfo from './external-info';
import type { ComponentRouting, RouteDefinitions } from 'core/routing/types';
import { string } from 'yup';

export const ROUTES = {
  login: 'login',
  resetPassword: 'resetPassword',
  forgotPassword: 'forgotPassword',
  emailConfirmation: 'emailConfirmation',
  externalInfo: 'externalInfo',
};

export const LOGIN = {
  path: '/login',
  exact: true,
  condition: () => true,
};

export const FORGOT_PASSWORD = {
  path: '/forgotPassword',
  exact: true,
  condition: () => true,
};

export const RESET_PASSWORD = {
  path: '/password/reset/confirmation/:userId/:token',
  exact: true,
  condition: () => true,
  requirements: {
    userId: string().required(),
    token: string().required(),
  },
};

export const EMAIL_CONFIRMATION = {
  path: '/users/email/confirmation/:token',
  exact: true,
  condition: () => true,
  requirements: {
    token: string().required(),
  },
};

export const EXTERNAL_INFO = {
  path: '/external-info',
  exact: true,
  condition: () => true,
  requirements: {
    investorId: string().required(),
  },
};

export const AUTH_ROUTES: RouteDefinitions = {
  [ROUTES.login]: LOGIN,
  [ROUTES.forgotPassword]: FORGOT_PASSWORD,
  [ROUTES.resetPassword]: RESET_PASSWORD,
  [ROUTES.emailConfirmation]: EMAIL_CONFIRMATION,
  [ROUTES.externalInfo]: EXTERNAL_INFO,
};

export const AUTH_PATH_LIST = Object.values(AUTH_ROUTES).map((route) => route.path);

export const AUTH_COMPONENT_ROUTING: ComponentRouting = {
  [ROUTES.login]: Login,
  [ROUTES.forgotPassword]: ForgotPassword,
  [ROUTES.resetPassword]: ResetPassword,
  [ROUTES.emailConfirmation]: EmailConfirmation,
  [ROUTES.externalInfo]: ExternalInfo,
};
