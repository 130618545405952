import { useContext, useEffect } from 'react';
import { PlatformContext } from 'ui/platform/provider';
import mergeAll from 'lodash/fp/mergeAll';
import defaultTheme from 'ui/styles/theme';
import { useStyles } from 'ui/styles/hooks';
import { Platforms } from 'platforms';
import { DeepPartial } from 'ui/helper/typescript-utils';
import { DefaultTheme } from 'styled-components';

export const importTheme = async (platform: Platforms): Promise<DeepPartial<DefaultTheme>> => {
  return (
    await import(
      /* webpackChunkName: "[request]" */
      `platforms/${platform}/theme`
    )
  ).default;
};

const platformThemeImports = async (platform: Platforms): Promise<Awaited<ReturnType<typeof importTheme>>> => {
  return await importTheme(platform);
};

export function useLoadPlatformCss({ setPlatformTheme }: ReturnType<typeof useStyles>) {
  const { activePlatform, initialPlatform } = useContext(PlatformContext);

  useEffect(() => {
    if (activePlatform === initialPlatform) return;

    platformThemeImports(activePlatform).then((theme) => {
      setPlatformTheme(mergeAll([defaultTheme, theme]));
    });
  }, [activePlatform, initialPlatform]);
}
