import { useState } from 'react';
import { useCurrentUserSelector } from 'src/core/auth/hooks';

const useInvestmentOverview = (
  invitationId: string,
): {
  onCloseProcessOverviewOverlay: () => void;
  showProcessOverview: boolean;
} => {
  const [showOverlay, setShowOverlay] = useState(true);

  const { currentUser } = useCurrentUserSelector();

  const getProcessOverviewVisited = () => {
    try {
      return localStorage.getItem(`processOverview.${invitationId}.visited`);
    } catch (e) {
      return null;
    }
  };

  const setProcessOverviewVisited = (value: string) => {
    try {
      return localStorage.setItem(`processOverview.${invitationId}.visited`, value);
    } catch (e) {
      return;
    }
  };

  const onCloseProcessOverviewOverlay = () => {
    setProcessOverviewVisited('1');
    setShowOverlay(false);
  };

  const showProcessOverview = !currentUser && !getProcessOverviewVisited() && showOverlay;

  return { onCloseProcessOverviewOverlay, showProcessOverview };
};

export default useInvestmentOverview;
