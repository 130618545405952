import React, { FunctionComponent, useMemo } from 'react';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import ServerError from 'ui/types/server-error';
import Section from 'ui/atoms/section';
import Hint from 'ui/atoms/hint';
import { camelCase } from 'change-case';
import { WalletType } from 'ui/types/wallet';
import makeForm from 'ui/forms';
import { toNumber } from 'ui/helper/money';
import * as Styled from 'subapps/investment/pages/investment/wizard-steps/wallet/select-wallet/select-depot/styled';
import Header from 'ui/atoms/header';
import Spacer from 'src/ui/atoms/spacer';
export interface InvestmentWallet {
  type: WalletType;
  address?: string;
  id?: string;
}

export interface TFADevice {
  confirmed: boolean;
  walletNeeded: boolean;
}

export interface InvestmentSelectDepotProps {
  /** Additional classes. */
  className?: string;

  existingWallets?: InvestmentWallet[];

  loading?: boolean;

  error?: ServerError;

  onDepotSelected?: (selectedWallet: InvestmentWallet) => void;

  prioritizedAllowedWalletTypes: WalletType[];

  tfaDevice?: TFADevice;
}

const InvestmentSelectDepotForm = makeForm<{ walletOptionIndex: string }>();

const InvestmentSelectDepot: FunctionComponent<InvestmentSelectDepotProps> = ({
  className,
  loading,
  error,
  existingWallets = [],
  onDepotSelected = () => {},
  prioritizedAllowedWalletTypes,
  tfaDevice,
}) => {
  const existingTanganyWallet = find(existingWallets, {
    type: WalletType.TANGANY,
  });
  const existingCashlinkWallet = find(existingWallets, {
    type: WalletType.CASHLINK,
  });
  const existingMobileWallet = find(existingWallets, {
    type: WalletType.MOBILE,
  });

  const orderedExistingWalletOptions: InvestmentWallet[] = useMemo(
    () => orderBy(existingWallets, [({ type }) => prioritizedAllowedWalletTypes.indexOf(type), 'address']),
    [existingWallets, prioritizedAllowedWalletTypes],
  );

  const needMobileAppWalletUpdate =
    prioritizedAllowedWalletTypes.includes(WalletType.MOBILE) && !existingMobileWallet && tfaDevice?.confirmed;

  const nonExistingGenericWalletOption: InvestmentWallet | undefined = prioritizedAllowedWalletTypes.includes(
    WalletType.GENERIC,
  )
    ? { type: WalletType.GENERIC }
    : undefined;

  const orderedNonExistingWalletOptions: InvestmentWallet[] = useMemo(() => {
    return prioritizedAllowedWalletTypes
      .filter((walletType) => {
        return (
          // investors with existing tangany depot should not have the option to create a new tangany depot
          !(walletType === WalletType.TANGANY && existingTanganyWallet) &&
          // investors with existing cashlink wallet should not have the option to create a new tangany depot
          !(walletType === WalletType.CASHLINK && existingCashlinkWallet) &&
          // users can only have 0-1 connected mobile devices, and 0-1 mobile safekeeping wallets
          !(walletType === WalletType.MOBILE && (existingMobileWallet || needMobileAppWalletUpdate)) &&
          // handle non existing genericWallet separately
          walletType !== WalletType.GENERIC
        );
      })
      .map((walletType) => {
        return {
          type: walletType,
        };
      });
  }, [
    prioritizedAllowedWalletTypes,
    existingTanganyWallet,
    existingCashlinkWallet,
    existingMobileWallet,
    needMobileAppWalletUpdate,
  ]);

  const orderedWalletOptions: InvestmentWallet[] = [
    ...orderedExistingWalletOptions,
    ...orderedNonExistingWalletOptions,
  ];

  return (
    <div className={className}>
      <InvestmentSelectDepotForm
        initial={{ walletOptionIndex: '0' }}
        onSubmit={({ walletOptionIndex }) => {
          onDepotSelected(orderedWalletOptions[toNumber(walletOptionIndex)]);
        }}
        error={error}
      >
        <p>
          <Translate name="investmentDepot.walletGuide.description" />
        </p>
        <Header size="small" spacing="medium">
          <Translate name="investmentDepot.walletGuide.explanationTitle" />
        </Header>
        <p>
          <Translate name="investmentDepot.walletGuide.explanation" />
        </p>
        {needMobileAppWalletUpdate && (
          <Hint variant="warning">
            <Translate name="investmentDepot.selection.needMobileAppWalletUpdate" />
          </Hint>
        )}
        <Spacer y={6} />
        <Header size="small" spacing="medium">
          <Translate name="investmentDepot.selection.title" />
        </Header>
        <Section spacing="small">
          {orderedWalletOptions.map((walletOption, index) => (
            <InvestmentSelectDepotForm.Group name="walletOptionIndex">
              <InvestmentSelectDepotForm.Radio key={index} value={index.toString()}>
                <Translate
                  name={`investmentDepot.selection.${walletOption.address ? 'existing' : 'new'}.${camelCase(
                    walletOption.type,
                  )}`}
                  args={
                    walletOption.address
                      ? [
                          (_, key) => (
                            <strong style={{ wordBreak: 'break-all' }} key={key}>
                              {walletOption.address}
                            </strong>
                          ),
                        ]
                      : []
                  }
                />
                {!walletOption.address && (
                  <Styled.WalletSelectDetails>
                    <Translate name={`investmentDepot.selection.details.${camelCase(walletOption.type)}`} />
                  </Styled.WalletSelectDetails>
                )}
              </InvestmentSelectDepotForm.Radio>
            </InvestmentSelectDepotForm.Group>
          ))}
        </Section>
        <Section spacing="small">
          {nonExistingGenericWalletOption && (
            <Button variant="link" onClick={() => onDepotSelected(nonExistingGenericWalletOption)}>
              <Translate name="investmentDepot.selection.new.generic" />
            </Button>
          )}
        </Section>
        <InvestmentSelectDepotForm.Submit loading={loading} size="large" fluid={true} variant="primary">
          <Translate name="common.continue" />
        </InvestmentSelectDepotForm.Submit>
      </InvestmentSelectDepotForm>
    </div>
  );
};

export default InvestmentSelectDepot;
