import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import IdentificationType from 'ui/types/identification-type';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import Grid, { Col } from 'ui/atoms/grid';
import { IdentificationDocumentTypeEnum } from 'src/api';

export interface AddIdentificationDocumentDataProps {
  AddIdentificationForm: any;
  documentValidFrom: Date | undefined;
  documentValidTo: Date | undefined;
  onChangeDocumentType: (documentType: IdentificationDocumentTypeEnum) => void;
}

const AddIdentificationDocumentData: FunctionComponent<AddIdentificationDocumentDataProps> = (props) => {
  const { AddIdentificationForm, documentValidFrom, documentValidTo, onChangeDocumentType } = props;

  const translate = useTranslateWithStringArgs();

  const documentTypes = Object.values(IdentificationType);

  return (
    <>
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitleIdentification" />
        </strong>
      </p>
      <AddIdentificationForm.Group name="documentType" required={true}>
        <AddIdentificationForm.Select
          onChange={(documentType: IdentificationDocumentTypeEnum) => onChangeDocumentType(documentType)}
          options={documentTypes.map((documentType) => ({
            value: documentType,
            label: translate(`identificationDocumentType.${snakeToCamelCaseWithNumbers(documentType.toLowerCase())}`),
          }))}
        ></AddIdentificationForm.Select>
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="documentId" required={true}>
        <AddIdentificationForm.Input />
      </AddIdentificationForm.Group>
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.fields.documentValidity.label" />
        </strong>
      </p>
      <Grid>
        <Col width={6} spacing="small">
          <AddIdentificationForm.Group name="documentValidFrom" required={true}>
            <AddIdentificationForm.DatePicker maxDate={documentValidTo} />
          </AddIdentificationForm.Group>
        </Col>
        <Col width={6} spacing="small">
          <AddIdentificationForm.Group name="documentValidTo" required={true}>
            <AddIdentificationForm.DatePicker minDate={documentValidFrom} />
          </AddIdentificationForm.Group>
        </Col>
      </Grid>
      <AddIdentificationForm.Group name="issuingOffice" required={true}>
        <AddIdentificationForm.Input />
      </AddIdentificationForm.Group>
    </>
  );
};

export default AddIdentificationDocumentData;
