import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, makeModalForm, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Section from 'ui/atoms/section';
import { Product } from 'apps/issuer/pages/investor-details/create-redemption';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Icon from 'ui/atoms/icon';
import { toNumber, toMoney } from 'ui/helper/money';
import Currency from 'ui/atoms/currency';
import ConfirmRedemption from './confirm-redemption-content';
import { AdminApi, BalanceApi, NetworkTypeEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import { getUniqueProductValues, getWalletsByProductId } from 'helper/redemption';
import Hint from 'ui/atoms/hint';
import NumberComponent from 'ui/atoms/number';
import { ButtonProps } from 'ui/atoms/button';
import Spacer from 'ui/atoms/spacer';

export interface CreateRedemptionFields {
  product: string;
  units: string;
  wallet: string | undefined;
}

export interface CreateRedemptionModalProps {
  onHideCreateRedemptionModal: () => void;
  onSuccessfulRedemption: () => void;
  products: Product[];
  investorId: string;
}
export interface ModalButton extends ButtonProps {
  name: string;
  content?: ReactNode;
}

const CreateRedemptionForm = makeModalForm<CreateRedemptionFields>();

const CreateRedemptionModal: FunctionComponent<CreateRedemptionModalProps> = (props) => {
  const [createRedemptionValues, setCreateRedemptionValues] = useState<CreateRedemptionFields>({
    product: '',
    units: '',
    wallet: '',
  });

  const [isRedemptionCreatedSuccesfully, setRedemptionCreatedSuccesfully] = useState(false);

  const [isConfirmRedemptionOpen, setConfirmRedemptionOpen] = useState(false);

  const [balance, setBalance] = useState(0);

  const { onHideCreateRedemptionModal, onSuccessfulRedemption, products, investorId } = props;

  const translate = useTranslateWithStringArgs();

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const balanceId: BalanceApi = useMemo(() => makeAuthenticatedApi(BalanceApi), [makeAuthenticatedApi]);

  const uniqueProducts = getUniqueProductValues(products);

  const selectedProduct = products.find((prod) => prod.id === createRedemptionValues?.product);

  const prodWallets = getWalletsByProductId(selectedProduct?.id || '', products);

  const selectedWallet = prodWallets?.find((wallet) => wallet.id === createRedemptionValues?.wallet);

  const getBalance = () => {
    if (selectedWallet?.id) {
      withApi(async () => {
        const balance = await balanceId.balanceRetrieve({
          tokenId: selectedProduct?.id || '',
          walletId: selectedWallet?.id || '',
        });
        if (balance) {
          const activeBalance = parseInt(balance.total) - parseInt(balance.frozen);
          setBalance(activeBalance);
        }
      });
    } else if (!selectedProduct?.isTokenized) {
      setBalance(selectedProduct?.remainingAmountSum || 0);
    }
  };

  useEffect(() => {
    getBalance();
  }, [selectedWallet?.id, selectedProduct?.id]);

  const submitRedemptionValues = async () => {
    if (isConfirmRedemptionOpen) {
      await adminApi.adminRedemptionsCreate({
        redemptionRequest: {
          investorId: investorId,
          productId: selectedProduct?.id || '',
          walletId: selectedWallet?.id,
          units: parseInt(createRedemptionValues?.units || '1'),
        },
      });
      onSuccessfulRedemption();
      setRedemptionCreatedSuccesfully(true);
      if (isRedemptionCreatedSuccesfully) {
        onHideCreateRedemptionModal();
        setConfirmRedemptionOpen(false);
      }
    } else {
      setConfirmRedemptionOpen(true);
    }
  };

  const actionButtons = useMemo((): ModalButton[] => {
    if (isRedemptionCreatedSuccesfully) {
      return [
        {
          name: 'cancel',
          content: <Translate name="common.close" />,
          size: 'large',
          onClick: onHideCreateRedemptionModal,
        },
      ];
    }
    return [
      {
        name: 'cancel',
        content: <Translate name="common.close" />,
        size: 'large',
        onClick: onHideCreateRedemptionModal,
      },
      {
        name: 'send',
        content: (
          <Translate
            name={
              isConfirmRedemptionOpen ? 'dashboardIssuerInvestorDetails.createRedemptionForm.button' : 'common.continue'
            }
          />
        ),
        variant: 'primary',
        size: 'large',
        disabled: selectedProduct?.type === NetworkTypeEnum.STELLAR,
        type: 'submit',
      },
    ];
  }, [isRedemptionCreatedSuccesfully, isConfirmRedemptionOpen, selectedProduct?.type]);

  return (
    <>
      <Modal onClose={onHideCreateRedemptionModal}>
        <CreateRedemptionForm
          onChange={(values: StateValues<CreateRedemptionFields>) => {
            setCreateRedemptionValues(values);
          }}
          onSubmit={async () => submitRedemptionValues()}
          i18nKey={'dashboardIssuerInvestorDetails.createRedemptionForm'}
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.title" />
          </ModalHeader>
          <ModalContent>
            {!isConfirmRedemptionOpen && (
              <>
                <Section spacing="small">
                  <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.subtitle" />
                </Section>
                <CreateRedemptionForm.Group name="product" required={true}>
                  <CreateRedemptionForm.Select
                    onChange={(product: any) => {
                      setCreateRedemptionValues({ ...createRedemptionValues, product: product });
                    }}
                    options={
                      (products?.length &&
                        uniqueProducts.map((prod) => ({
                          value: prod.id,
                          label: prod.name,
                        }))) ||
                      []
                    }
                  ></CreateRedemptionForm.Select>
                </CreateRedemptionForm.Group>
                {selectedProduct?.type === NetworkTypeEnum.STELLAR && (
                  <Hint variant="danger">
                    <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.stellarMessage" />
                  </Hint>
                )}
                {selectedProduct?.type !== NetworkTypeEnum.STELLAR && (
                  <>
                    {selectedProduct?.isTokenized && !!prodWallets?.length && (
                      <CreateRedemptionForm.Group
                        name="wallet"
                        required={true}
                        info={
                          <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.wallet.helper" />
                        }
                      >
                        <CreateRedemptionForm.Select
                          onChange={(wallet: any) =>
                            setCreateRedemptionValues({ ...createRedemptionValues, wallet: wallet })
                          }
                          options={prodWallets.map((wallet) => ({
                            value: wallet.id,
                            label: `${translate(`walletType.${wallet.type.toLowerCase()}`)} (${wallet.address})`,
                          }))}
                        ></CreateRedemptionForm.Select>
                      </CreateRedemptionForm.Group>
                    )}
                    <CreateRedemptionForm.Group name="units" required={true}>
                      <CreateRedemptionForm.Input type="number" />
                      <Spacer y={1} />
                      {((selectedProduct?.id && !selectedProduct?.isTokenized) ||
                        (selectedProduct?.isTokenized && selectedWallet)) &&
                        CreateRedemptionForm.Validators.Range(
                          1,
                          balance,
                          <Translate
                            name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.requirements.max.error"
                            args={[(_, key) => <NumberComponent key={key}>{balance}</NumberComponent>]}
                          />,
                          balance && (
                            <>
                              <p>
                                <Translate
                                  name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.requirements.max.helper"
                                  args={[balance]}
                                />
                              </p>
                              {!!createRedemptionValues.units?.length && !!parseInt(createRedemptionValues.units) && (
                                <p>
                                  <Translate
                                    name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.requirements.max.helperInfo"
                                    args={[
                                      (_, key) => (
                                        <Currency key={key}>
                                          {toMoney(
                                            parseInt(createRedemptionValues.units) *
                                              toNumber(selectedProduct?.tokenPrice),
                                            selectedProduct?.tokenPrice.currency,
                                          )}
                                        </Currency>
                                      ),
                                    ]}
                                  />
                                </p>
                              )}
                            </>
                          ),
                        )}
                      {createRedemptionValues?.units &&
                        selectedProduct?.isTokenized &&
                        (!selectedProduct || !selectedWallet) && (
                          <div>
                            <Spacer inline x={1}>
                              <Icon name="exclamation-circle" color="danger" size="small" />
                            </Spacer>
                            <small className="text-error">
                              <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.requirements.noProductOrWallet.error" />
                            </small>
                          </div>
                        )}
                      {createRedemptionValues?.units && !selectedProduct?.isTokenized && !selectedProduct && (
                        <div>
                          <Spacer inline x={1}>
                            <Icon name="exclamation-circle" color="danger" size="small" />
                          </Spacer>
                          <small className="text-error">
                            <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.requirements.noProduct.error" />
                          </small>
                        </div>
                      )}
                    </CreateRedemptionForm.Group>
                  </>
                )}
              </>
            )}
            {isConfirmRedemptionOpen && createRedemptionValues && selectedProduct && (
              <ConfirmRedemption
                onHideConfirmRedemption={() => setConfirmRedemptionOpen(false)}
                isRedemptionCreatedSuccesfully={isRedemptionCreatedSuccesfully}
                product={selectedProduct}
                wallet={selectedWallet}
                units={parseInt(createRedemptionValues.units)}
              />
            )}
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              align={isRedemptionCreatedSuccesfully ? 'start' : 'end'}
              actionButtons={actionButtons}
            />
          </ModalFooter>
        </CreateRedemptionForm>
      </Modal>
    </>
  );
};

export default CreateRedemptionModal;
