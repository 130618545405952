import React from 'react';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';

const PendingIdentification = () => {
  return (
    <BoxedContent>
      <p>
        <strong>
          <Translate name="dashboardInvestorOverview.pendingIdentification.title" />
        </strong>
      </p>
      <p>
        <Translate name="dashboardInvestorOverview.pendingIdentification.content" />
      </p>
    </BoxedContent>
  );
};

export default PendingIdentification;
