import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Link from 'ui/atoms/link';

export interface IdentificationErrorPageProps {
  /** Additional classes. */
  className?: string;
}

const IdentificationErrorPage: FunctionComponent<IdentificationErrorPageProps> = (props) => {
  const { className, children, ...restProps } = props;

  const translate = useTranslateWithStringArgs();

  const hasSupportPhone = translate('platform.supportPhone');

  return (
    <div className={cx('identification-error-page', className)} {...restProps}>
      <p className="text">
        <Translate name="identification.errorText" />
      </p>
      <h4>
        <Translate name="identification.mailCaption" />
      </h4>
      <Link href={`mailto:${translate('platform.supportEmail')}`}>
        <Translate as="p" name="platform.supportEmail" />
      </Link>
      {hasSupportPhone && (
        <>
          <h4>
            <Translate name="identification.phoneCaption" />
          </h4>
          <p>
            <Translate name="platform.supportPhone" /> <Translate name="identification.supportWorkingHours" />
          </p>
        </>
      )}
    </div>
  );
};

export default IdentificationErrorPage;
