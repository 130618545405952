import styled from 'styled-components';
import StickyMessage from 'ui/atoms/sticky-message';

export const DemoStickyMessage = styled(StickyMessage)`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;

  p {
    margin: 0;
  }
`;
