import React, { useCallback, useState } from 'react';
import * as Styled from './styled';

import type { FunctionComponent } from 'react';

export interface SearchProps {
  id: string;
  selectedItem: string | null;
  onChange: (investorName: string) => void;
  onRemoveSelection?: () => void;
}

export const Search: FunctionComponent<SearchProps> = (props) => {
  const { children, id, selectedItem, onChange, onRemoveSelection } = props;

  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onChangeText = useCallback(
    (value: string) => {
      if (selectedItem) return;

      setValue(value);
      setIsOpen(true);
      onChange(value);
    },
    [selectedItem],
  );

  const onClickIcon = useCallback(() => {
    if (onRemoveSelection && selectedItem) {
      onRemoveSelection();
      setValue('');
    }
  }, [selectedItem]);

  return (
    <Styled.Dropdown>
      <Styled.Search
        id={id}
        aria-haspopup="true"
        aria-expanded={isOpen}
        icon={selectedItem ? 'close' : 'search'}
        value={selectedItem || value}
        alignIcon="left"
        onChange={(e: any) => onChangeText(e.target.value)}
        onBlur={() => setIsOpen(false)}
        onClickIcon={onClickIcon}
      />
      {isOpen && (
        <Styled.ItemsContainer aria-labelledby={id} onClick={() => setIsOpen(false)}>
          {children}
        </Styled.ItemsContainer>
      )}
    </Styled.Dropdown>
  );
};

export default Search;
