import React, { FunctionComponent, useContext } from 'react';
import { ModalContext } from 'ui/molecules/modal/provider';
import ActionButtons, { ActionButtonsProps } from 'ui/molecules/action-buttons';
import * as Styled from './styled';
import { ModalButtonsAlign } from './styled';

export interface ModalFooterButtonsProps extends ActionButtonsProps {
  align?: ModalButtonsAlign;
  className?: string;
}

export const ModalFooterButtons: FunctionComponent<ModalFooterButtonsProps> = ({
  children,
  actionButtons,
  align,
  className,
  ...rest
}) => {
  const { disableClose } = useContext(ModalContext);

  // todo: we can automate the close
  return (
    <Styled.ModalFooterButtons $disableClose={disableClose} $align={align} className={className}>
      <ActionButtons actionButtons={actionButtons} spacingTop="none" {...rest}>
        {children}
      </ActionButtons>
    </Styled.ModalFooterButtons>
  );
};

export default ModalFooterButtons;
