import React, { FunctionComponent, ReactNode } from 'react';
import HeaderSize from 'ui/types/header-size';
import * as Styled from './styled';
import { As } from 'ui/helper/get-element-type';
import Spacing from 'ui/types/spacing';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';
import { TextAlignOptions } from 'ui/polymorphs/text/styled';

export interface HeaderProps extends As {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /**
   * An header can have different sizes.
   *
   * @default "large"
   * @expandType true
   */
  size?: HeaderSize;

  /**
   * Color of the icon.
   *
   * @expandType true
   */
  color?: Colors;

  /**
   * A header may be uppercase.
   *
   * @default true
   */
  uppercase?: boolean;

  /** Inverts the color of the header for dark backgrounds */
  inverted?: boolean;

  /** Truncates large texts and adds '...' at the end of the truncated text */
  truncate?: boolean;

  breakWords?: boolean;

  /**
   * Header spacing.
   *
   * @default "large"
   */
  spacing?: Spacing;

  textAlign?: TextAlignOptions;
}

export const Header: FunctionComponent<HeaderProps> = (props) => {
  const {
    className,
    children,
    color,
    inverted,
    truncate,
    breakWords,
    uppercase = false,
    spacing = 'large',
    size,
    textAlign = 'left',
  } = props;

  const getHeaderType = (size?: HeaderSize) => {
    switch (size) {
      case 'large':
        return 'h1';
      case 'medium':
        return 'h2';
      case 'small':
        return 'h3';
      default:
        return 'h4';
    }
  };

  return (
    <Styled.Header
      $size={size}
      $color={color}
      $spacing={spacing}
      $breakWords={breakWords}
      $inverted={inverted}
      $uppercase={uppercase}
      $truncate={truncate}
      $textAlign={textAlign}
      as={getHeaderType(size)}
      className={cx('header', className)}
    >
      {children}
    </Styled.Header>
  );
};

export default Header;
