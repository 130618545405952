import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router';
import 'ui/styles/style.scss';

import Main from './core/main';

import store from './store';
import history from './core/routing/history';
import AuthLayer from 'core/auth/auth-layer';
import { setUpMessageBuffer } from 'helper/postmessage-buffer';
import RootLayout from './core/layout/root';
import GlobalStyle from 'ui/styles/global';
import { PageTitleProvider } from 'context/page-title';
import ErrorBoundary from 'core/components/error-boundary';

setUpMessageBuffer();

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <GlobalStyle />
        <PageTitleProvider>
          <RootLayout>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AuthLayer>
                <Main />
              </AuthLayer>
            </QueryParamProvider>
          </RootLayout>
        </PageTitleProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
