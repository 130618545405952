export interface ListsTheme {
  pointSize: any;
  pointDistanceFromText: any;
  pointPadding: any;
  pointPaddingSm: any;
  pointShadow: any;
}

export default {
  pointSize: '2.5',
  pointDistanceFromText: '1',
  pointPadding: '0.75em 0em',
  pointPaddingSm: '0.5em 0em',
  pointShadow: '0em 0em 0em 0.1em inset',
} as ListsTheme;
