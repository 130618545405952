import { ThemeColor } from '../types';
export interface ColorsTheme {
  white: ThemeColor;
  black: ThemeColor;
  disabled: ThemeColor;
  grayLight: ThemeColor;
  grayHover: ThemeColor;
  grayMain: ThemeColor;
  grayDark: ThemeColor;
  redLight: ThemeColor;
  redHover: ThemeColor;
  redMain: ThemeColor;
  redDark: ThemeColor;
  yellowLight: ThemeColor;
  yellowHover: ThemeColor;
  yellowMain: ThemeColor;
  yellowDark: ThemeColor;
  greenLight: ThemeColor;
  greenHover: ThemeColor;
  greenMain: ThemeColor;
  greenDark: ThemeColor;
  primaryLight: ThemeColor;
  primaryHover: ThemeColor;
  primaryMain: ThemeColor;
  primaryDark: ThemeColor;
  [k: string]: ThemeColor;
} // any color or key is valid here, no point in typing individual properties

export default {
  // common colors
  white: '#ffffff',
  black: '#262626',
  // disabled color
  disabled: '#565E64',
  // shades of gray
  grayLight: '#F4F4F5',
  grayHover: '#B0B5B9',
  grayMain: '#8E969B',
  grayDark: '#6C747A',
  // shades of red
  redLight: '#FFE2E2',
  redHover: '#FF5E5E',
  redMain: '#FF2D2D',
  redDark: '#EB1919',
  // shades of yellow
  yellowLight: '#FFF3C6',
  yellowHover: '#FDD440',
  yellowMain: '#F5C518',
  yellowDark: '#E3B613',
  // shades of green
  greenLight: '#EBF7DA',
  greenHover: '#78BF39',
  greenMain: '#52A31D',
  greenDark: '#3C7D0E',
  // for default Cashlink platform
  primaryLight: '#C9ECFF',
  primaryHover: '#6AC3F2',
  primaryMain: '#45ACE2',
  primaryDark: '#3499CE',
} as ColorsTheme;
