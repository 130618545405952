import {
  AdminApi,
  AdminVoucherCreationRequest,
  AdminVoucherDetail,
  AdminVoucherStepRequest,
  InvestmentToken,
  CurrencyEnum,
} from 'api';
import useApiCall from 'hooks/use-api-call';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toMoney } from 'ui/helper/money';
import { VoucherFields } from '.';
import { buildRequestParameters, validate } from './helper';
import { ProductsOptions } from './product-validity';
import { Step, ValidatedStep } from './voucher-steps';
import { VoucherUsesByInvestorEnum } from './voucher-uses-investor';
import { VoucherUsesTotalEnum } from './voucher-uses-total';

export const useCreateVoucher = (
  isOpen: boolean | undefined,
  isEditMode: boolean,
  voucher: AdminVoucherDetail | undefined,
  onVoucherChanged: () => void,
  onModalClose?: () => void | undefined,
) => {
  const [createVoucherValues, setCreateVoucherValues] = useState<VoucherFields>({
    distributionPlatformId: '',
    voucherName: '',
    voucherCode: '',
    numberOfUsesTotal: VoucherUsesTotalEnum.UNLIMITED,
    numberOfUsesByInvestor: VoucherUsesByInvestorEnum.LIMITED_TO,
    validityProducts: ProductsOptions.ALL_PRODUCTS,
  });

  const [noOfUsesTotalAmount, setNoOfUsesTotalAmount] = useState(1);
  const [noOfUsesByInvestorAmount, setNoOfUsesByInvestorAmount] = useState(1);
  const [steps, setSteps] = useState<Step[]>([]);
  const [productsValidity, setProductsValidity] = useState<InvestmentToken[] | string>(ProductsOptions.ALL_PRODUCTS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  useEffect(() => {
    if (isOpen) setIsModalOpen(true);
  }, [isOpen]);

  const getNoOfUsesByInvestor = useCallback(
    (voucher: AdminVoucherDetail) => {
      if (voucher.firstTimeOnly) {
        return VoucherUsesByInvestorEnum.FIRST_TIME;
      } else if (voucher.numberOfUsesInvestor === 0) {
        return VoucherUsesByInvestorEnum.UNLIMITED;
      } else {
        return VoucherUsesByInvestorEnum.LIMITED_TO;
      }
    },
    [voucher],
  );

  useEffect(() => {
    if (isEditMode && voucher) {
      setCreateVoucherValues({
        ...createVoucherValues,
        voucherName: voucher.name,
        voucherCode: voucher.code,
        numberOfUsesTotal:
          voucher.numberOfUses === 0 ? VoucherUsesTotalEnum.UNLIMITED : VoucherUsesTotalEnum.LIMITED_TO,
        numberOfUsesByInvestor: getNoOfUsesByInvestor(voucher),
        validityProducts: voucher.productNames.length > 0 ? ProductsOptions.SPECIFIC : ProductsOptions.ALL_PRODUCTS,
      });
    }
  }, [isEditMode, voucher]);

  const onSubmit = () => {
    submitVoucherValues();
  };

  const onClose = () => {
    setError('');
    setIsModalOpen(false);
    if (onModalClose) onModalClose();
  };

  const submitVoucherValues = () => {
    setError('');
    const params = buildRequestParameters(
      createVoucherValues,
      noOfUsesTotalAmount,
      noOfUsesByInvestorAmount,
      productsValidity,
    );
    const error = validate(params, steps);

    if (error) {
      setError(error);
      return;
    }

    withApi(async () => {
      try {
        let response;
        let voucherId;
        if (isEditMode) {
          response = await api.adminVouchersPartialUpdate({
            id: voucher!.id,
            patchedAdminVoucherUpdateRequest: params,
          });
        } else {
          response = await api.adminVouchersCreate({
            adminVoucherCreationRequest: params as AdminVoucherCreationRequest,
          });
          voucherId = response.id;
        }
        await submitVoucherSteps(voucherId || voucher!.id, steps as ValidatedStep[]); // we already check for this in the `validate` above
      } catch (e) {
        const errorCode = e.info.validation_errors.code[0].code;
        setError(`createVoucherForm.error.${errorCode}`);
      }
    });
  };

  const submitVoucherSteps = useCallback(
    async (voucherId: string, steps: ValidatedStep[]) => {
      const stepsRequest = steps.map((step) => {
        const reqParams: AdminVoucherStepRequest = {
          minimumInvestmentAmount:
            typeof step.minInvestment === 'string' ? toMoney(step.minInvestment, CurrencyEnum.EUR) : step.minInvestment,
        };

        step.discountSelection === CurrencyEnum.EUR
          ? (reqParams.disagio = step.discount)
          : (reqParams.disagioPercent = step.discount);

        return reqParams;
      });
      await api.adminVouchersVoucherStepsCreate({
        id: voucherId,
        adminVoucherStepBulkCreateRequest: { steps: stepsRequest },
      });

      setIsModalOpen(false);
      onVoucherChanged();
    },
    [api],
  );

  return {
    isModalOpen,
    error,
    createVoucherValues,
    onSubmit,
    onClose,
    setIsModalOpen,
    setCreateVoucherValues,
    setNoOfUsesTotalAmount,
    setNoOfUsesByInvestorAmount,
    setSteps,
    setProductsValidity,
  };
};
