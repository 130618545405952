import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import getElementType, { As } from 'ui/helper/get-element-type';

import LoadingRing from 'ui/atoms/loading-ring';
import Button from 'ui/atoms/button';
import Segment from 'ui/atoms/segment';

export interface LoadingWithMessageProps extends As {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Action button label */
  actionButtonLabel?: string;

  /** Called after user's click on action button. */
  onActionClick?: MouseEventHandler<HTMLButtonElement>;

  /* Custom action button */
  customActionButton?: ReactNode;
}

export const LoadingWithMessage: FunctionComponent<LoadingWithMessageProps> = (props) => {
  const { customActionButton, actionButtonLabel, onActionClick = () => {}, className, children, ...restProps } = props;

  const ElementType = getElementType(LoadingWithMessage, props);

  return (
    <ElementType className={cx('loading-with-message', className)} {...restProps}>
      <Segment basic={true} textAlign="center">
        <LoadingRing />
        <p>{children}</p>
        {customActionButton}
        {!customActionButton && actionButtonLabel && (
          <Button variant="link" onClick={onActionClick}>
            {actionButtonLabel}
          </Button>
        )}
      </Segment>
    </ElementType>
  );
};

export default LoadingWithMessage;
