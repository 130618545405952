import { ThemeColor } from '../types';

export type FontSizes = 'tiny' | 'xsmall' | 'small' | 'medium' | 'large';

export interface TypographyTheme {
  default: number;
  sizes: {
    [key in FontSizes]: number;
  };
  header: {
    [key in FontSizes]: number;
  };
  color: ThemeColor;
}

export default {
  default: 1,
  sizes: {
    large: 1.25,
    medium: 1,
    small: 0.875,
    tiny: 0.75,
  },
  header: {
    large: 2,
    medium: 1.5,
    small: 1.25,
    xsmall: 1,
    tiny: 0.875,
  },
  color: '#2A2F2F',
} as TypographyTheme;
