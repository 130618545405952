import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import Date from 'ui/atoms/date';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminVoucher } from 'api';
import StatusTag from 'ui/atoms/status-tag';
import HoverCopy from 'ui/molecules/hover-copy';
import { PaginationProps } from 'ui/molecules/pagination';

export interface StudioVouchersTableProps {
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  vouchers?: AdminVoucher[];
  goToDetails: (id: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
  defaultLimit?: number;
}

const StudioVouchersTable: FunctionComponent<StudioVouchersTableProps> = (props) => {
  const { ordering, onOrderBy, vouchers, goToDetails, title, emptyView, loading, paginationProps } = props;
  return (
    <DataRecordTable
      ordering={ordering}
      onOrderBy={onOrderBy}
      data={vouchers}
      title={title}
      loading={loading}
      emptyView={emptyView}
      paginationProps={paginationProps}
      cells={[
        {
          title: <Translate name={`studioVouchersTable.name`} />,
          render: ({ name }) => name,
        },
        {
          title: <Translate name={`studioVouchersTable.voucherCode`} />,
          render: ({ code }) => <HoverCopy name={code} />,
        },
        {
          title: <Translate name={`studioVouchersTable.alreadyUsed`} />,
          alignContent: 'right',
          render: ({ alreadyUsed }) => alreadyUsed,
        },
        {
          title: <Translate name={`studioVouchersTable.createdOn`} args={[<>&shy;</>]} />,
          orderingFieldName: 'created_at',
          render: ({ createdAt }) => <Date>{createdAt}</Date>,
        },
        {
          title: <Translate name={`studioVouchersTable.status`} />,
          render: ({ active }) => (
            <StatusTag variant={active ? 'success' : 'danger'} truncate={true}>
              <Translate name={`studioVouchersTable.${active ? 'active' : 'deactivated'}`} />
            </StatusTag>
          ),
        },
        {
          title: '',
          render: ({ id }) => <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />,
          alignContent: 'right',
        },
      ]}
    />
  );
};

export default StudioVouchersTable;
