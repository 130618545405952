import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import ServerError from 'ui/types/server-error';
import makeForm from 'ui/forms';
import TaxInformation from 'libraries/wizard/components/additional-information/additional-information-form/tax-information';
import BankAccount from 'libraries/wizard/components/additional-information/additional-information-form/bank-account';
import SecuritiesDepositAccount from 'libraries/wizard/components/additional-information/additional-information-form/securities-deposit-account';
import ActionButtons from 'ui/molecules/action-buttons';

export interface AdditionalInformationFields {
  taxIdentificationNumber?: string;
  exemptionOrderAmount: string;
  nonAssessmentCertificate?: 'true' | 'false';

  bankIban: string;
  bankAccountHolder: string;

  securitiesDepositAccountNumber: string;
  bic: string;
  securitiesDepositAccountHolder: string;
}

export interface AdditionalInformationProps {
  /** Additional classes. */
  className?: string;

  bankAccountRequired?: boolean;

  securitiesDepositAccountRequired?: boolean;

  taxInformationRequired?: boolean;

  hasTaxInformation?: boolean;

  initial?: {
    accountHolder?: string;
  };

  onSubmit?: (values: AdditionalInformationFields) => void;

  /** Indicates a loading state */
  loading?: boolean;

  /** Error message */
  error?: ServerError;

  isRegisterOnlyFlow?: boolean;
}

export const AdditionalInformationForm = makeForm<AdditionalInformationFields>();

const AdditionalInformation: FunctionComponent<AdditionalInformationProps> = (props) => {
  const {
    className,
    bankAccountRequired,
    taxInformationRequired,
    securitiesDepositAccountRequired,
    hasTaxInformation,
    initial,
    onSubmit = () => {},
    loading,
    error,
    isRegisterOnlyFlow,
    ...restProps
  } = props;

  return (
    <div className={cx('investment-additional-information', className)} {...restProps}>
      <AdditionalInformationForm
        onSubmit={onSubmit}
        error={error}
        initial={{
          nonAssessmentCertificate: 'false',
          bankAccountHolder: initial?.accountHolder,
          securitiesDepositAccountHolder: initial?.accountHolder,
        }}
        i18nKey="additionalInformationForm"
      >
        {taxInformationRequired && (
          <TaxInformation hasTaxInformation={hasTaxInformation} showExemptionOrder={!isRegisterOnlyFlow} />
        )}
        {bankAccountRequired && <BankAccount />}
        {securitiesDepositAccountRequired && <SecuritiesDepositAccount />}
        <ActionButtons>
          <Button variant="primary" type="submit" size="large" fluid={true} loading={loading}>
            <Translate
              name={
                isRegisterOnlyFlow ? 'registerAdditionalInformation.submit' : 'investmentAdditionalInformation.submit'
              }
            />
          </Button>
        </ActionButtons>
      </AdditionalInformationForm>
    </div>
  );
};

export default AdditionalInformation;
