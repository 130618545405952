import { ThemeColor } from 'ui/styles/types';

export interface SideOverlayTheme {
  padding: string;
  color: ThemeColor;
}

export default {
  padding: '1.5rem',
  color: ({ theme }: any) => theme.colorVariants.background,
} as SideOverlayTheme;
