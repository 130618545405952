import React from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import LoadingRing from 'ui/atoms/loading-ring';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import ActionButtons from 'ui/molecules/action-buttons';
import StudioInvestmentsTable from 'src/apps/issuer/shared/studio-investments-table';
import { makeBigNumber } from 'ui/helper/big-number';
import useGoTo from 'hooks/use-go-to';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import ProductsOverview from './products-overview';
import { useIssuerOverview } from 'apps/issuer/hooks';
import Link from 'core/routing/link';
import { AdminInvestment } from 'api';
import * as Styled from './styled';
import useTranslate from 'ui/hooks/use-translate';
import Header from 'src/ui/atoms/header';
import useIsMedia, { DEVICES } from 'src/ui/hooks/use-is-media';

const IssuerOverviewPage = () => {
  const goToInvestment = useGoTo(ISSUER_ROUTES.investmentDetails);

  const translate = useTranslate();

  const isPhone = useIsMedia(DEVICES.phone);

  const { requiredActions, tokenLoading, loading, productsOverview, investorsIncompleteList } = useIssuerOverview();

  if (tokenLoading || loading || !productsOverview || !investorsIncompleteList) {
    return <LoadingRing />;
  }

  return (
    <WideContent title={<Translate name="dashboard.issuerOverview" />}>
      <ProductsOverview data={productsOverview} />
      <Section spacing="large">
        <>
          {requiredActions &&
            requiredActions.map(({ type, number, link }) => (
              <Hint variant="info" key={type}>
                <div>
                  {!isPhone && (
                    <Translate
                      name={`dashboardIssuerOverview.requiredActions.${type}`}
                      grammaticalNumber={makeBigNumber(number)}
                      args={[
                        number,
                        (text, key) => (
                          <Link to={link} key={key} inline>
                            <Styled.RedirectButton variant="secondary" size="xsmall">
                              {text}
                            </Styled.RedirectButton>
                          </Link>
                        ),
                      ]}
                    />
                  )}
                  {isPhone && (
                    <>
                      <Translate
                        name={`dashboardIssuerOverview.requiredActionsMobile.${type}`}
                        grammaticalNumber={makeBigNumber(number)}
                        args={[number]}
                      />
                      <div>
                        <Link to={link} key={type}>
                          <Styled.RedirectButton variant="secondary" size="xsmall">
                            <Translate name={`dashboardIssuerOverview.requiredActionsButton.${type}`} />
                          </Styled.RedirectButton>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Hint>
            ))}
          {investorsIncompleteList &&
            investorsIncompleteList.map((investor) => (
              <Hint variant="info" key={investor.id}>
                <div>
                  {!isPhone && (
                    <Translate
                      name="dashboardIssuerOverview.requiredActions.incompleteInvestor"
                      args={[
                        investor.name,
                        investor.email,
                        (text, key) => (
                          <Link to={`issuerInvestor/${investor.id}`} key={key} inline underline>
                            {text}
                          </Link>
                        ),
                      ]}
                    />
                  )}
                  {isPhone && (
                    <>
                      <Translate
                        name="dashboardIssuerOverview.requiredActionsMobile.incompleteInvestor"
                        args={[investor.name, investor.email]}
                      />
                      <div>
                        <Link to={`issuerInvestor/${investor.id}`} underline>
                          <Translate name={`dashboardIssuerOverview.requiredActionsButton.incompleteInvestor`} />
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Hint>
            ))}
        </>
      </Section>
      {productsOverview.lastInvestments.length && (
        <>
          <Header size="small" spacing="medium">
            <Translate name="dashboardIssuerOverview.lastInvestments" />
          </Header>
          <Styled.NumberOfInvestments>
            {`${productsOverview.lastInvestments.length} ${translate('common.of')} ${
              productsOverview.numberActiveInvestments
            }`}
          </Styled.NumberOfInvestments>
          <StudioInvestmentsTable
            investments={productsOverview.lastInvestments as unknown as AdminInvestment[]}
            goToDetails={(productId: string, investmentId: string) => goToInvestment({ productId, investmentId })}
          />
          <ActionButtons spacingTop="small">
            <Link to="/dashboard/investments">
              <Translate name="dashboardIssuerOverview.viewAllInvestments" />
            </Link>
          </ActionButtons>
        </>
      )}
      {!productsOverview.lastInvestments.length && <IssuerEmptyView type={EmptyViewType.ISSUER_INVESTMENTS} />}
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerOverviewPage, AppType.ISSUER);
