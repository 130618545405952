import React, { FunctionComponent, ReactNode, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Radio from 'ui/atoms/radio';
import Button from 'ui/atoms/button';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Link from 'ui/atoms/link';
import * as Styled from './styled';

export interface AlternativeIdentificationOptionsProps {
  children?: ReactNode;
  identificationFormDocUrl?: string;
  identificationInPerson: boolean;
  className?: string;
}

const AlternativeIdentificationOptions: FunctionComponent<AlternativeIdentificationOptionsProps> = ({
  className,
  children,
  identificationFormDocUrl,
  identificationInPerson,
  ...restProps
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selection, setSelection] = useState<'thirdParty' | 'platform'>();

  const translate = useTranslateWithStringArgs();
  const hasAltIDOptions = !!identificationFormDocUrl || identificationInPerson;

  if (!hasAltIDOptions) return null;

  return (
    <div className={cx('alternative-identification-options', className)} {...restProps}>
      <Button variant="link" size="large" fluid={true} onClick={() => setShowOptions((prevState) => !prevState)}>
        <Translate name="alternativeIdentificationOptions.title" />
      </Button>
      {showOptions && (
        <>
          {identificationFormDocUrl && (
            <>
              <Radio key="thirdParty" checked={selection === 'thirdParty'} onChange={(_) => setSelection('thirdParty')}>
                <Translate name="alternativeIdentificationOptions.thirdParty.title" />
              </Radio>
              {selection === 'thirdParty' && (
                <Styled.IdentificationOption className="option-description">
                  <Translate
                    as="p"
                    name="alternativeIdentificationOptions.thirdParty.description1"
                    args={[
                      (txt, key) => (
                        <Link external href={identificationFormDocUrl}>
                          {txt}
                        </Link>
                      ),
                    ]}
                  />
                  <Translate as="p" name="alternativeIdentificationOptions.thirdParty.description2" />
                  <Translate as="p" name="alternativeIdentificationOptions.thirdParty.address" />
                  <Translate as="p" name="platform.address.name" />
                  <Translate as="p" name="platform.address.street" />
                  <Translate as="p" name="alternativeIdentificationOptions.thirdParty.zipAndCity" />
                </Styled.IdentificationOption>
              )}
            </>
          )}
          {identificationInPerson && (
            <>
              <Radio key="platform" checked={selection === 'platform'} onChange={(_) => setSelection('platform')}>
                <Translate name="alternativeIdentificationOptions.platform.title" />
              </Radio>
              {selection === 'platform' && (
                <Styled.IdentificationOption className="option-description">
                  <Translate
                    as="p"
                    name="alternativeIdentificationOptions.platform.description1"
                    args={[
                      <Link href={`mailto:${translate('platform.supportEmail')}`}>
                        <Translate name="platform.supportEmail" />
                      </Link>,
                    ]}
                  />
                  <Translate as="p" name="alternativeIdentificationOptions.platform.description2" />
                  <Translate as="p" name="alternativeIdentificationOptions.platform.description3" />
                </Styled.IdentificationOption>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AlternativeIdentificationOptions;
