import React, { useRef, useLayoutEffect, useState, useCallback, useEffect, forwardRef, cloneElement } from 'react';
import { PopoverPortal } from './portal';
import { DEFAULT_POPOVER_POSITIONS, EMPTY_CLIENT_RECT, rectsAreEqual } from './util';
import { usePopover, useMemoizedArray } from './hooks';
import { PopoverAlign, PopoverPositions } from 'ui/molecules/popover/types';

type PopoverProps = {
  children: React.ReactElement;
  isOpen?: boolean;
  content: React.ReactElement | ((popoverState: any) => React.ReactElement);
  positions?: Array<PopoverPositions>;
  parentElement?: any;
  contentLocation?: unknown;
  align?: PopoverAlign;
  padding?: number;
  reposition?: boolean;
  boundaryInset?: number;
  boundaryElement?: any;
  containerClassName?: string;
  containerStyle?: Record<any, any>;
  onClickOutside?: (e: any) => void;
};

// todo: styled components this one
export const PopoverInternal = forwardRef<any, PopoverProps>(
  (
    {
      isOpen,
      children,
      content,
      positions: externalPositions = DEFAULT_POPOVER_POSITIONS,
      align = 'center',
      padding = 0,
      reposition = true,
      parentElement = window.document.body,
      boundaryElement = parentElement,
      containerClassName,
      containerStyle,
      contentLocation,
      boundaryInset = 0,
      onClickOutside,
    },
    externalRef,
  ) => {
    const positions = useMemoizedArray(externalPositions);

    // TODO: factor prevs out into a custom prevs hook
    const prevIsOpen = useRef<any>(false);
    const prevPositions = useRef<any>();
    const prevContentLocation = useRef<any>();
    const prevReposition = useRef<any>(reposition);

    const childRef = useRef<any>();

    const [popoverState, setPopoverState] = useState({
      align,
      nudgedLeft: 0,
      nudgedTop: 0,
      position: positions[0],
      padding,
      childRect: EMPTY_CLIENT_RECT,
      popoverRect: EMPTY_CLIENT_RECT,
      parentRect: EMPTY_CLIENT_RECT,
      boundaryRect: EMPTY_CLIENT_RECT,
      boundaryInset,
      violations: EMPTY_CLIENT_RECT,
      hasViolations: false,
    });

    const onPositionPopover = useCallback((s) => setPopoverState(s), []);

    const { positionPopover, popoverRef, scoutRef } = usePopover({
      isOpen,
      childRef,
      containerClassName,
      parentElement,
      boundaryElement,
      contentLocation,
      positions,
      align,
      padding,
      boundaryInset,
      reposition,
      onPositionPopover,
    });

    useLayoutEffect(() => {
      let shouldUpdate = true;
      const updatePopover = () => {
        if (isOpen && shouldUpdate) {
          const childRect = childRef?.current?.getBoundingClientRect();
          const popoverRect = popoverRef?.current?.getBoundingClientRect();
          if (
            childRect != null &&
            popoverRect != null &&
            (!rectsAreEqual(childRect, {
              top: popoverState.childRect.top,
              left: popoverState.childRect.left,
              width: popoverState.childRect.width,
              height: popoverState.childRect.height,
              bottom: popoverState.childRect.top + popoverState.childRect.height,
              right: popoverState.childRect.left + popoverState.childRect.width,
            } as DOMRect) ||
              popoverRect.width !== popoverState.popoverRect.width ||
              popoverRect.height !== popoverState.popoverRect.height ||
              popoverState.padding !== padding ||
              popoverState.align !== align ||
              positions !== prevPositions.current ||
              contentLocation !== prevContentLocation.current ||
              reposition !== prevReposition.current)
          ) {
            positionPopover();
          }

          // TODO: factor prev checks out into the custom prevs hook
          if (positions !== prevPositions.current) {
            prevPositions.current = positions;
          }
          if (contentLocation !== prevContentLocation.current) {
            prevContentLocation.current = contentLocation;
          }
          if (reposition !== prevReposition.current) {
            prevReposition.current = reposition;
          }

          if (shouldUpdate) {
            window.requestAnimationFrame(updatePopover);
          }
        }

        prevIsOpen.current = isOpen;
      };

      window.requestAnimationFrame(updatePopover);

      return () => {
        shouldUpdate = false;
      };
    }, [
      align,
      contentLocation,
      isOpen,
      padding,
      popoverRef,
      popoverState.align,
      popoverState.childRect.height,
      popoverState.childRect.left,
      popoverState.childRect.top,
      popoverState.childRect.width,
      popoverState.padding,
      popoverState.popoverRect.height,
      popoverState.popoverRect.width,
      positionPopover,
      positions,
      reposition,
    ]);

    useEffect(() => {
      const popoverElement = popoverRef.current;

      Object.assign(popoverElement.style, containerStyle);

      return () => {
        Object.keys(containerStyle ?? {}).forEach((key) => delete popoverElement.style[key as unknown as number]);
      };
    }, [containerStyle, isOpen, popoverRef]);

    const handleOnClickOutside = useCallback(
      (e) => {
        if (isOpen && !popoverRef.current?.contains(e.target) && !childRef.current?.contains(e.target)) {
          onClickOutside?.(e);
        }
      },
      [isOpen, onClickOutside, popoverRef],
    );

    const handleWindowResize = useCallback(() => {
      if (childRef.current) {
        window.requestAnimationFrame(() => positionPopover());
      }
    }, [positionPopover]);

    useEffect(() => {
      window.addEventListener('click', handleOnClickOutside, true);
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('click', handleOnClickOutside, true);
        window.removeEventListener('resize', handleWindowResize);
      };
    }, [handleOnClickOutside, handleWindowResize]);

    const handleRef = useCallback(
      (node) => {
        childRef.current = node;
        if (externalRef != null) {
          if (typeof externalRef === 'object') {
            externalRef.current = node;
          } else if (typeof externalRef === 'function') {
            externalRef(node);
          }
        }
      },
      [externalRef],
    );

    const renderChild = () =>
      cloneElement(children, {
        ref: handleRef,
      });

    const renderPopover = () => {
      if (!isOpen) return null;

      return (
        <PopoverPortal element={popoverRef.current} scoutElement={scoutRef.current} container={parentElement}>
          {typeof content === 'function' ? content(popoverState) : content}
        </PopoverPortal>
      );
    };

    return (
      <>
        {renderChild()}
        {renderPopover()}
      </>
    );
  },
);

const Popover = forwardRef((props: PopoverProps, ref) => {
  if (typeof window === 'undefined') return props.children;
  return <PopoverInternal {...props} ref={ref} />;
});

export { ArrowContainer } from './arrow';
export { usePopover };

export default Popover;
