import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { AddIdentificationFields } from '../..';
import Section from 'ui/atoms/section';
import Date from 'ui/atoms/date';
import Grid, { Col } from 'ui/atoms/grid';

export interface ConfirmIdentificationPersonalDataProps {
  identification: AddIdentificationFields;
}

const ConfirmIdentificationPersonalData: FunctionComponent<ConfirmIdentificationPersonalDataProps> = (props) => {
  const { identification } = props;

  return (
    <Section spacing="medium">
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitlePersonalData" />
        </strong>
      </p>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.personalData.salutation" />
        </Col>
        <Col width={6} spacing="small">
          {identification.salutation}
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.personalData.name" />
        </Col>
        <Col width={6} spacing="small">
          {`${identification.forename} ${identification.surname}`}
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.personalData.birthDate" />
        </Col>
        <Col width={6} spacing="small">
          <Date>{identification.dateOfBirth}</Date>
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.personalData.nationality" />
        </Col>
        <Col width={6} spacing="small">
          <Translate name={`countries.${identification.nationality}`} />
        </Col>
      </Grid>
    </Section>
  );
};

export default ConfirmIdentificationPersonalData;
