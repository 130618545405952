import styled from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';
import Margin from 'ui/types/margin';

export const ModalContent = styled.div<{ $bgColor: Colors; $hasScrollbar: boolean; $margin?: Margin }>`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme, $bgColor }) => resolveColor(theme, $bgColor)};
  margin: ${({ theme, $margin }) => ($margin ? theme.margin[$margin] : '1.5rem 0px')};
  padding: 0.3rem;
  padding-right: ${({ $hasScrollbar }) => ($hasScrollbar ? '1.5rem' : '0.2rem')};
`;

export const ModalContentLoader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
