import BigNumber from 'bignumber.js';

const makeBigNumberCache: { [key: string]: BigNumber } = {};

export const makeBigNumber = (value: string | number): BigNumber => {
  const key = value.toString();
  if (key in makeBigNumberCache) {
    return makeBigNumberCache[key];
  }
  return (makeBigNumberCache[key] = new BigNumber(value));
};

const tryBigIntCache: { [key: string]: BigNumber } = {};

// tryBigInt returns a big integer or undefined if value cannot be converted to a big integer
export const tryBigInt = (value: string | number): BigNumber | undefined => {
  const key = value.toString();
  if (key in tryBigIntCache) {
    return tryBigIntCache[key];
  }
  const newBigInt = new BigNumber(value);
  if (!newBigInt.isInteger()) return undefined;
  return (tryBigIntCache[key] = newBigInt);
};

// makeBigInt returns a big integer or throws an error if value cannot be converted to a big integer
export const makeBigInt = (value: string | number): BigNumber => {
  const bigInt = tryBigInt(value);

  if (bigInt === undefined) {
    throw new Error('Value passed to makeBigInt is no integer');
  }
  return bigInt;
};
