import { AdminInvestor } from 'api';
import React, { FunctionComponent, useMemo } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Spacer from 'ui/atoms/spacer';
import { useReactivationModal } from './hooks';
import { ButtonSizes } from 'ui/styles/themes/buttons';

export interface ReactivationProps {
  investor: AdminInvestor;
  onModalClose: () => void;
}

const ReactivationModal: FunctionComponent<ReactivationProps> = (props) => {
  const { investor, onModalClose } = props;

  const { formatDate, onClose, onConfirm, isActivationSuccessful } = useReactivationModal(investor, onModalClose);

  const Buttons = {
    Cancel: {
      name: 'cancel',
      content: <Translate name="common.cancel" />,
      size: 'large' as ButtonSizes,
      onClick: onClose,
    },
    Close: {
      name: 'close',
      content: <Translate name="common.close" />,
      size: 'large' as ButtonSizes,
      onClick: onClose,
    },
    Confirm: {
      name: 'confirm',
      content: <Translate name="dashboardIssuerInvestorDetails.activateAccount.title" />,
      variant: 'primary',
      type: 'submit',
      size: 'large' as ButtonSizes,
      onClick: onConfirm,
    },
  };

  const actionButtons = useMemo(() => {
    if (!isActivationSuccessful) {
      return [Buttons.Cancel, Buttons.Confirm];
    } else {
      return [Buttons.Close];
    }
  }, [isActivationSuccessful]);

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <Translate name="dashboardIssuerInvestorDetails.activateAccount.title" />
      </ModalHeader>
      <ModalContent>
        {isActivationSuccessful && (
          <Hint variant="success">
            <Translate name="dashboardIssuerInvestorDetails.activateAccount.activated" args={[investor.name]} />
          </Hint>
        )}

        {!isActivationSuccessful && (
          <div>
            <Translate name="dashboardIssuerInvestorDetails.activateAccount.question" args={[investor.name]} as="b" />
            <Spacer y={4} />
            <p>
              <Translate
                name="dashboardIssuerInvestorDetails.activateAccount.reversed"
                args={[formatDate(investor.earliestDeletion)]}
              />
            </p>
            <Hint variant="warning">
              <Translate name="dashboardIssuerInvestorDetails.activateAccount.notification" />
            </Hint>
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons actionButtons={actionButtons} />
      </ModalFooter>
    </Modal>
  );
};

export default ReactivationModal;
