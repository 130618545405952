import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { PersonType } from 'ui/types/person';
import Section from 'ui/atoms/section';
import { SalutationEnum } from 'api';
import Grid from 'ui/atoms/grid';
import Header from 'ui/atoms/header';
import { CreateAccountForm } from 'libraries/wizard/components/account-setup/create-account/helpers';
import { useServerConfigSelector } from 'core/config/hooks';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import useCountries from 'src/hooks/use-countries';
import * as Styled from './styled';

export interface NaturalPersonProps {
  isExternalInvitation: boolean;
  personType: PersonType;
  naturalBirthCountry: string | undefined;
  nationality: string | undefined;
  naturalCountry: string | undefined;
}

const NaturalPerson: FunctionComponent<NaturalPersonProps> = ({
  isExternalInvitation,
  personType,
  naturalBirthCountry,
  nationality,
  naturalCountry,
}) => {
  const { config } = useServerConfigSelector();

  const isLargeScreen = useIsMedia([DEVICES.lTablet, DEVICES.desktops]);
  const countriesList = useCountries();

  return (
    <Section>
      {!isExternalInvitation && (
        <Section spacing="small">
          <Header size="small" spacing="medium">
            <Translate name="createAccount.headers.individual.personalInformation" />
          </Header>
          <CreateAccountForm.Group name="naturalSalutation" required={true}>
            <CreateAccountForm.GroupToggle>
              {Object.values(SalutationEnum).map((salutation, index) => (
                <CreateAccountForm.Radio value={salutation} key={index}>
                  <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
                </CreateAccountForm.Radio>
              ))}
            </CreateAccountForm.GroupToggle>
          </CreateAccountForm.Group>
          <Grid>
            <Styled.LeftColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalForename" required={true}>
                <CreateAccountForm.Input autoFocus={true} />
              </CreateAccountForm.Group>
            </Styled.LeftColumn>
            <Styled.RightColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalSurname" required={true}>
                <CreateAccountForm.Input />
              </CreateAccountForm.Group>
            </Styled.RightColumn>
          </Grid>
          <Grid>
            <Styled.LeftColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalBirthDate" required={true}>
                <CreateAccountForm.DateInput max={new Date()} />
                {CreateAccountForm.Validators.PastDate()}
                {CreateAccountForm.Validators.Over18()}
              </CreateAccountForm.Group>
            </Styled.LeftColumn>
            <Styled.RightColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalBirthPlace" required={true} occupyHiddenLabelSpace={isLargeScreen}>
                <CreateAccountForm.Input />
              </CreateAccountForm.Group>
            </Styled.RightColumn>
          </Grid>
          <Grid>
            <Styled.LeftColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalBirthCountry" required={true}>
                <CreateAccountForm.Select
                  options={countriesList}
                  value={naturalBirthCountry}
                ></CreateAccountForm.Select>
              </CreateAccountForm.Group>
            </Styled.LeftColumn>
            <Styled.RightColumn width={6} tablet={12} phone={12}>
              <CreateAccountForm.Group name="nationality" required={true}>
                <CreateAccountForm.Select options={countriesList} value={nationality}></CreateAccountForm.Select>
              </CreateAccountForm.Group>
            </Styled.RightColumn>
          </Grid>
        </Section>
      )}
      {isExternalInvitation && (
        <p>
          <Translate as="strong" name="createAccount.externalInvestor.verifyData" />
        </p>
      )}
      {personType === PersonType.Natural && (
        <>
          <Header size="xsmall" spacing="medium">
            <Translate name="createAccount.headers.individual.contactInformation" />
          </Header>
          <CreateAccountForm.Group name="naturalStreet" required={true}>
            <CreateAccountForm.Input />
          </CreateAccountForm.Group>
          <Grid>
            <Styled.LeftColumn width={4} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalZip" required={true}>
                <CreateAccountForm.Input />
              </CreateAccountForm.Group>
            </Styled.LeftColumn>
            <Styled.RightColumn width={8} tablet={12} phone={12}>
              <CreateAccountForm.Group name="naturalCity" required={true}>
                <CreateAccountForm.Input />
              </CreateAccountForm.Group>
            </Styled.RightColumn>
          </Grid>
          <CreateAccountForm.Group name="naturalCountry" required={true}>
            <CreateAccountForm.Select options={countriesList} value={naturalCountry}></CreateAccountForm.Select>
          </CreateAccountForm.Group>
        </>
      )}

      {isExternalInvitation && (
        <CreateAccountForm.Group name="naturalIban" required={true}>
          <CreateAccountForm.Input maxLength={100} />
          {CreateAccountForm.Validators.IBAN()}
        </CreateAccountForm.Group>
      )}

      <CreateAccountForm.Group name="naturalPhone" required={config.telephoneNumberRequired}>
        <CreateAccountForm.Input />
        {CreateAccountForm.Validators.PhoneNumber()}
      </CreateAccountForm.Group>
    </Section>
  );
};

export default NaturalPerson;
