import { useContext } from 'react';
import { I18NContext } from 'ui/i18n/provider';

// todo: move to i18n hooks
export const useTranslate = () => {
  const { translate } = useContext(I18NContext);
  return translate;
};

export const useTranslateWithStringArgs = () => {
  const { translateWithStringArgs } = useContext(I18NContext);
  return translateWithStringArgs;
};

export default useTranslate;
