export enum Platform {
  CASHLINK = 'cashlink',
  WIWIN = 'wiwin',
  THREE_SIXTY_X = '360x',
  TRADIAS = 'tradias',
  INVENTURE = 'inventure',
  TOKENSTREET = 'tokenstreet',
  WEBZEICHNUNG = 'webzeichnung',
  ARTTRADE = 'arttrade',
  LLOYD = 'lloyd',
  LAIQON = 'laiqon',
  REFUTURE = 'refuture',
  GERMANREALESTATE = 'germanrealestate',
  ETERNA = 'eterna',
  GRUENWELT = 'gruenwelt',
  KRYPTOFANTEN = 'kryptofanten',
  IQFAIRPROFIT = 'iqfairprofit',
  TOKEN4ENERGY = 'token4energy',
  IMMVESTIFY = 'immvestify',
  ART360X = 'art360x',
  SENSUS = 'sensus',
  RIDGE = 'ridge',
  ESTATEX = 'estatex',
  CROWDWERK = 'crowdwerk',
  FINYOZ = 'finyoz',
  ONECROWD = 'onecrowd',
  SIINVEST = 'siinvest',
  AGITAREX = 'agitarex',
  // <PLATFORM_ONBOARDING>{{PLATFORM|upper}}= "{{PLATFORM}}",</PLATFORM_ONBOARDING>
}

export type Platforms = (typeof Platform)[keyof typeof Platform];

export type PlatformType = `${Platform}`;

export const PlatformList = Object.values(Platform);

export interface PlatformConfig {}
