import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import moment from 'moment';
import { compact } from 'lodash';
import Translate from 'ui/atoms/translate';
import Date from 'ui/atoms/date';
import Currency from 'ui/atoms/currency';
import Button from 'ui/atoms/button';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import DataRecordTable from 'src/libraries/data-record-table';
import {
  currentTokenHasManageApprovalsPermission,
  currentTokenHasManageCreditDigitalSecuritiesPermission,
  currentTokenHasManageIncomingPaymentsPermission,
} from 'apps/issuer/helpers/permissions';
import { DataRecordOrdering } from 'ui/types/data-ordering';

import { AdminInvestment } from 'api/models';
import { useProducts } from 'apps/issuer/hooks';
import ActionButtons from 'ui/molecules/action-buttons';
import { PaginationProps } from 'ui/molecules/pagination';

type TableType = 'approveOffers' | 'confirmPayments' | 'creditSecurities';

export interface StudioConfirmInvestmentsTableProps {
  type: TableType;
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  investments?: AdminInvestment[];
  loadingConfirmationId?: string;
  onConfirm: (investmentId: string) => void;
  onOpenCancellationModal: (investmentId: string) => void;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
}

const StudioConfirmInvestmentsTable: FunctionComponent<StudioConfirmInvestmentsTableProps> = (props) => {
  const {
    type,
    ordering,
    onOrderBy,
    investments,
    loadingConfirmationId,
    onConfirm,
    onOpenCancellationModal,
    paginationProps,
    emptyView,
    loading,
  } = props;

  const { tokens } = useProducts();

  const hasPermissionsForAction = useCallback(
    (type: TableType, tokenId: string) => {
      const token = tokens?.find((t) => t.id === tokenId);

      switch (type) {
        case 'approveOffers':
          return currentTokenHasManageApprovalsPermission(token);
        case 'confirmPayments':
          return currentTokenHasManageIncomingPaymentsPermission(token);
        case 'creditSecurities':
          return currentTokenHasManageCreditDigitalSecuritiesPermission(token);
        default:
          return false;
      }
    },
    [tokens, type],
  );

  return (
    <DataRecordTable
      onOrderBy={onOrderBy}
      ordering={ordering}
      data={investments}
      emptyView={emptyView}
      loading={loading}
      paginationProps={paginationProps}
      cells={compact([
        {
          title: <Translate name={`studioConfirmInvestmentsTable.${type}.product`} />,
          render: ({ productName }) => productName,
        },
        {
          // TODO(mara-cashlink): add maxColumnWidth: "medium"?
          title: <Translate name={`studioConfirmInvestmentsTable.${type}.investorName`} />,
          render: ({ investor }) => investor.companyName || investor.name,
        },
        {
          title: <Translate name={`studioConfirmInvestmentsTable.${type}.signedDate`} />,
          orderingFieldName: 'signed_date',
          render: ({ signedDate }) => <Date>{signedDate}</Date>,
        },
        type === 'confirmPayments' && {
          title: <Translate name="studioConfirmInvestmentsTable.confirmPayments.paymentDeadline" />,
          orderingFieldName: 'payment_deadline',
          render: ({ paymentDeadline }) => (
            <PlaceholderFallback>{paymentDeadline && <Date>{paymentDeadline}</Date>}</PlaceholderFallback>
          ),
        },
        {
          title: <Translate name={`studioConfirmInvestmentsTable.${type}.amount`} args={[<>&shy;</>]} />,
          orderingFieldName: type === 'approveOffers' || type === 'creditSecurities' ? 'investment_total' : undefined,
          alignContent: 'right',
          render: ({ investmentTotal, paymentTotal }) => (
            <Currency>
              {
                {
                  approveOffers: investmentTotal,
                  creditSecurities: investmentTotal,
                  confirmPayments: paymentTotal,
                }[type]
              }
            </Currency>
          ),
        },
        type === 'creditSecurities' && {
          title: <Translate name="studioConfirmInvestmentsTable.creditSecurities.units" args={[<>&shy;</>]} />,
          alignContent: 'right',
          orderingFieldName: 'fulfilled_amount',
          render: ({ remainingAmount }) => remainingAmount,
        },
        type === 'confirmPayments' && {
          title: <Translate name="studioConfirmInvestmentsTable.confirmPayments.referenceText" args={[<>&shy;</>]} />,
          alignContent: 'right',
          orderingFieldName: 'payment_code',
          render: ({ paymentCode }) => paymentCode,
        },
        {
          title: <Translate name={`studioConfirmInvestmentsTable.${type}.actionHeader`} />,
          alignContent: 'right',
          render: (investment: AdminInvestment) => (
            <ActionButtons align="end" spacingTop="none">
              {((type === 'approveOffers' && investment.canCancel.length) || type === 'confirmPayments') && (
                <Button
                  variant="link"
                  color="primary"
                  disabled={
                    (type === 'confirmPayments' && moment().isSameOrBefore(investment.paymentDeadline, 'day')) ||
                    !hasPermissionsForAction(type, investment.token.id)
                  }
                  onClick={() => onOpenCancellationModal(investment.id)}
                  size="xsmall"
                >
                  <Translate name={`studioConfirmInvestmentsTable.${type}.cancel`} />
                </Button>
              )}
              <Button
                disabled={
                  (type === 'creditSecurities' && !investment.canIssue) ||
                  !hasPermissionsForAction(type, investment.token.id)
                }
                onClick={() => onConfirm(investment.id)}
                variant="secondary"
                loading={investment.id === loadingConfirmationId}
                size="xsmall"
              >
                <Translate name={`studioConfirmInvestmentsTable.${type}.action`} />
              </Button>
            </ActionButtons>
          ),
        },
      ])}
    />
  );
};

export default StudioConfirmInvestmentsTable;
