import { useMemo } from 'react';
import { useServerConfigSelector } from 'src/core/config/hooks';

const useProcessOverview = (): {
  overviewSteps: string[];
} => {
  const {
    config: { registerOnlyFlow },
  } = useServerConfigSelector();

  const registerOverviewSteps = {
    accountData: 'accountData',
    investorExperience: 'investorExperience',
    identityCheck: 'identityCheck',
    saveDepositAccountConnection: 'saveDepositAccountConnection',
  };

  const steps = useMemo(() => {
    return Object.values(registerOverviewSteps).filter(
      (x) => !!registerOnlyFlow.walletStepEnabled || x !== registerOverviewSteps.saveDepositAccountConnection,
    );
  }, [registerOnlyFlow.walletStepEnabled]);

  return { overviewSteps: steps };
};

export default useProcessOverview;
