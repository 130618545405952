export enum Step {
  ACCOUNTSETUP = 'ACCOUNT_SETUP',
  INVESTMENTAMOUNT = 'INVESTMENT_AMOUNT',
  VIB = 'VIB',
  OFFERCREATION = 'OFFER_CREATION',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  BENEFICIARY = 'BENEFICIARY',
  IDENTIFICATION = 'IDENTIFICATION',
  RESUMEIDENTIFICATION = 'RESUME_IDENTIFICATION',
  IDENTIFICATIONREJECTED = 'IDENTIFICATION_REJECTED',
  IDENTIFICATIONFAILED = 'IDENTIFICATION_FAILED',
  WAITINGKYC = 'WAITING_KYC',
  WAITINGACCEPTANCE = 'WAITING_ACCEPTANCE',
  PAYMENT = 'PAYMENT',
  SELECTWALLET = 'SELECT_WALLET',
  SETUPMOBILEWALLET = 'SETUP_MOBILE_WALLET',
  PREPAREASSET = 'PREPARE_ASSET',
  CONFIRMWALLET = 'CONFIRM_WALLET',
  FINAL = 'FINAL',
  UNCONFIRMED = 'UNCONFIRMED',
  ADDITIONALINFORMATION = 'ADDITIONAL_INFORMATION',
  WAITINGCOMPLIANCE = 'WAITING_COMPLIANCE',
  CANCELED = 'CANCELED',
}
