import { useContext, useEffect } from 'react';
import { PlatformContext } from 'ui/platform/provider';
import { mergeMessages } from 'ui/i18n/utils';
import assets from 'ui/i18n/assets';
import { useI18n } from 'ui/i18n/hooks';
import { Platforms } from 'platforms';
import { Locales } from './types';

export const importLocales = async (platform: Platforms): Promise<Locales> => {
  return (
    await import(
      /* webpackChunkName: "[request]" */
      `platforms/${platform}/locales/`
    )
  ).default;
};

export function useLoadPlatformI18n({
  activeLocale,
  initialLocales,
  setLocaleMessages,
  setIsMessagesLoaded,
  localeMessages,
}: ReturnType<typeof useI18n>) {
  const { activePlatform, initialPlatform } = useContext(PlatformContext);

  useEffect(() => {
    setIsMessagesLoaded(true);
  }, [localeMessages]);

  useEffect(() => {
    (async () => {
      if (activePlatform === initialPlatform) {
        setIsMessagesLoaded(false);
        setLocaleMessages({ ...assets[activeLocale], ...initialLocales[activeLocale] });
        return;
      }

      try {
        const platformLocaleMessages = (
          await import(
            /* webpackChunkName: "[request]" */
            `platforms/${activePlatform}/locales/files/${activeLocale}.json`
          )
        ).default;

        const messages = mergeMessages(assets[activeLocale], platformLocaleMessages);

        setIsMessagesLoaded(false);
        setLocaleMessages(messages);
      } catch (e) {
        console.error(
          `Error while loading locales for platform ${activePlatform} and language ${activeLocale}:`,
          e,
          e.stack,
        );
        setLocaleMessages({});
      }
    })();
  }, [activeLocale, activePlatform]);
}
