import styled from 'styled-components';
import ActionButtons from 'ui/molecules/action-buttons';

export const MobileMenu = styled.div`
  display: flex;
`;

export const NavbarButtons = styled(ActionButtons)`
  margin: 0px;
`;
