import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Section from 'ui/atoms/section';

export interface UpdateMobileSafekeepingProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;
}

const UpdateMobileSafekeeping: FunctionComponent<UpdateMobileSafekeepingProps> = (props) => {
  const { className, children, ...restProps } = props;

  return (
    <div className={cx('update-mobile-safekeeping', className)} {...restProps}>
      <Section>
        <p>
          <Translate as="b" name="updateMobileSafekeeping.info" />
        </p>
        <Translate as="p" name="updateMobileSafekeeping.description" />
      </Section>
      <Translate as="p" name="updateMobileSafekeeping.actionDescription" />
      <Hint variant="info">
        <Translate name="updateMobileSafekeeping.forwardHint" />
      </Hint>
    </div>
  );
};

export default UpdateMobileSafekeeping;
