import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';

export interface NoIdentificationProps {
  onClick?: () => void;
}

const NoIdentification: FunctionComponent<NoIdentificationProps> = (props) => {
  const { onClick } = props;

  return (
    <BoxedContent>
      <p>
        <strong>
          <Translate name="dashboardInvestorOverview.noIdentification.title" />
        </strong>
      </p>
      <p>
        <Translate name="dashboardInvestorOverview.noIdentification.content" />
      </p>
      <ActionButtons>
        <Button variant="primary" onClick={onClick}>
          <Translate name="dashboardInvestorOverview.noIdentification.button" />
        </Button>
      </ActionButtons>
    </BoxedContent>
  );
};

export default NoIdentification;
