import styled, { css } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';

export const NavLink = styled.a<{ $active?: boolean }>`
  display: block;
  padding: 0rem 0 0rem 32px;
  line-height: 34px;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 16px;
  color: ${({ theme }) => resolveColor(theme, 'white')};

  ${({ $active, theme }) =>
    $active &&
    css`
      position: relative;
      color: ${resolveColor(theme, 'marker')};
      &:before {
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        content: '';
        border: 3px solid ${resolveColor(theme, 'marker')};
      }
    `};

  &:hover {
    color: ${({ theme }) => resolveColor(theme, 'marker')};
    text-decoration: none;
  }
`;

export const SidebarNavGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
  flex-direction: column;
  color: white;
`;
