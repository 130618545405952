import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import * as Styled from './styled';
import Translate from '../translate';
import Tag from 'ui/atoms/tag';
import { Wallet } from 'ui/types/wallet';
import { camelCase } from 'change-case';

export interface TransferIdentifierProps {
  fromAddress: string;
  toAddress: string;
  isIssuance?: boolean;

  fromWallet?: Wallet;
  toWallet?: Wallet;

  short?: boolean;

  /** Additional classes. */
  className?: string;
}

export const TransferIdentifier: FunctionComponent<TransferIdentifierProps> = (props) => {
  const { className, children, short, isIssuance, fromAddress, toAddress, fromWallet, toWallet, ...restProps } = props;

  const shorten = (val: string) => (short ? `${val.slice(0, 10)}...` : val);

  return (
    <Styled.TransferIdentifier className={cx('transfer-identifier', className)} {...restProps}>
      <Tag color="dark">
        {isIssuance ? (
          <Translate name={`transferIdentifier.issuance`} />
        ) : fromWallet ? (
          <Translate
            name="transferIdentifier.yourWallet"
            args={[<Translate key={0} name={`walletType.${camelCase(fromWallet.type)}`} />]}
          />
        ) : (
          shorten(fromAddress)
        )}
      </Tag>
      →
      <Tag color="dark">
        {toWallet ? (
          <Translate
            name="transferIdentifier.yourWallet"
            args={[<Translate key={0} name={`walletType.${camelCase(toWallet.type)}`} />]}
          />
        ) : toAddress === '0x0000000000000000000000000000000000000000' ? (
          <Translate name={`transferIdentifier.issuance`} />
        ) : (
          shorten(toAddress)
        )}
      </Tag>
    </Styled.TransferIdentifier>
  );
};

export default TransferIdentifier;
