import { Money } from 'api';
import { ValuationHistory } from './valuation-history';
import { Step } from './step';
import { SurveyWaiverType } from './survey-waiver';
import { Document } from './document';
import { Wallet, WalletType } from './wallet';
import { InvestmentCancellation } from './investment-cancellation';
import { InvestmentAcquisitionTypeRecipientEnum, InvestmentTokenForInvestor } from 'api';
import { TokenizationOperationStatus } from './tokenization-api';

// add corresponding translations if adding new status cases here!
export enum InvestorInvestmentStatusEnum {
  PreInvestmentAmount = 'PreInvestmentAmount',
  InvestmentAmountMissing = 'InvestmentAmountMissing',
  QuestionnaireMissing = 'QuestionnaireMissing',
  VibMissing = 'VibMissing',
  OfferConfirmationMissing = 'OfferConfirmationMissing',
  BenefitingPersonsMissing = 'BenefitingPersonsMissing',
  IdentificationMissing = 'IdentificationMissing',
  IdentificationRejected = 'IdentificationRejected',
  IdentificationPending = 'IdentificationPending',
  IdentificationFailed = 'IdentificationFailed',
  WaitingAcceptance = 'WaitingAcceptance',
  PaymentOutstanding = 'PaymentOutstanding',
  WalletSetupMissing = 'WalletSetupMissing',
  WaitingSecurityTransfer = 'WaitingSecurityTransfer',
  WalletSetupPending = 'WalletSetupPending',
  AdditionalInformationMissing = 'AdditionalInformationMissing',
  SecuritiesTransferredToManagedWallet = 'SecuritiesTransferredToManagedWallet',
  SecuritiesTransferredToCustomWallet = 'SecuritiesTransferredToCustomWallet',
  SuccessFullyTransfered = 'SuccessFullyTransfered',
  SuccessPartiallyTransfered = 'SuccessPartiallyTransfered',
  SuccessRedeemed = 'SuccessRedeemed',
  Finished = 'Finished',
  Handover = 'Handover',
  Canceled = 'Canceled',
}

export enum RuleType {
  PrivatePlacement = 'private_placement',
  MinimumTicketSize = 'minimum_ticket_size',
  PublicOffering = 'public_offering',
}

export interface RuleSet {
  country: string;

  hasPrivatePlacement: boolean;

  hasPublicOffering: boolean;

  hasMinimumTicket: boolean;

  remainingInvitations: number;

  minimumTicketSize: Money;
}

export interface CurrencyRate {
  readonly exchangeRate: Money;
  readonly baseCurrency: string;
}

export interface MinimalInvestor {
  id: string;
  name: string;
  email: string;
  companyName: string | null;
}

export interface Investment {
  id: string;
  signedDate: Date;
  acceptedDate?: Date | null;
  maxAmount: number;
  fulfilledAmount: number;
  remainingAmount: number;
  paymentDate?: Date | null;
  investmentTotal: Money;
  investmentTotalFulfilled: Money;
  paymentDeadline: Date | null;
  paymentCode: string;
  tokenPrice: Money;
  tokenTransactionHash?: string;
  tokenDeliveryDate?: Date | null;
  token: InvestmentTokenForInvestor;
  wallet?: Wallet | null;
  investor: MinimalInvestor;
  initiatedIdentification?: boolean;
  valuationHistory: Array<ValuationHistory>;
  surveyValid?: boolean;
  surveyWaiverType?: SurveyWaiverType;
  canAccept: boolean;
  canReceivePayment: boolean;
  investmentDocuments: Array<Document>;
  productName: string;
  disagio: Money | null;
  agio: Money | null;
  paymentTotal: Money;
  sumSettledPayouts: Money;
  lastSettledPayout: Money | null;
  nextScheduledPayout: Date | null;
  addWalletLater: WalletType | null;
  accruedInterest: Money;
  step: Step;
  isTokenized: boolean;
  cancellationReason?: InvestmentCancellation | null;
  cancellationDate?: Date | null;
  issuanceStatus: TokenizationOperationStatus | null;
  acquisitionType: InvestmentAcquisitionTypeRecipientEnum;
}
