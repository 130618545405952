import React, { FunctionComponent } from 'react';
import groupBy from 'lodash/groupBy';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Translate from 'ui/atoms/translate';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import Grid from 'ui/atoms/grid';
import * as Styled from './styled';
import Section from 'src/ui/atoms/section';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export interface SplitFormSegmentMobileProps {
  questions: QuestionnaireQuestions[];
  segmentId?: string;
  isEcsp: boolean;
}

const SplitFormSegmentMobile: FunctionComponent<SplitFormSegmentMobileProps> = ({ questions, segmentId, isEcsp }) => {
  const questionRows = groupBy(questions, (question) => question.productId);

  const translate = useTranslateWithStringArgs();

  const getExperienceSubgroupMobile = (subgroup?: string) => {
    return Object.entries(questionRows).map(([productId, questionRow]) => {
      const [knowledge, experience] = questionRow;

      if ((subgroup && knowledge.subgroupId !== subgroup) || (!subgroup && knowledge.subgroupId)) return null;

      return (
        <Styled.QuestionnaireSplitFormDiv key={productId}>
          <Grid>
            <Styled.QuestionnaireCenteredText>
              <Translate as="h4" name={`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(productId)}`} />
            </Styled.QuestionnaireCenteredText>
          </Grid>
          <Section spacing="small">
            <Grid>
              <Styled.QuestionnaireCenteredText>
                <Translate name="questionnaire.investmentExperienceCategories.knowledge" />
              </Styled.QuestionnaireCenteredText>
              <Styled.QuestionnaireCenteredText>
                <QuestionnaireForm.Group spacing="none" key={knowledge.id} name={knowledge.id}>
                  <QuestionnaireForm.GroupToggle>
                    {[
                      { name: 'true', value: 'knowledge' },
                      { name: 'false', value: 'no_knowledge' },
                    ].map((option, index) => (
                      <QuestionnaireForm.Radio value={option.value} key={option.value} spacing="small">
                        <Translate name={`commonOptions.${option.name}`} />
                      </QuestionnaireForm.Radio>
                    ))}
                  </QuestionnaireForm.GroupToggle>
                </QuestionnaireForm.Group>
              </Styled.QuestionnaireCenteredText>
            </Grid>
          </Section>
          <Grid>
            <Styled.QuestionnaireCenteredText>
              <Translate name="questionnaire.investmentExperienceCategories.experience" />
            </Styled.QuestionnaireCenteredText>
            <Styled.QuestionnaireCenteredText>
              <QuestionnaireForm.Group spacing="none" key={experience.id} name={experience.id}>
                <QuestionnaireForm.Select
                  options={(experience.options || []).map((option) => ({
                    value: option,
                    label: translate(`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(option)}`),
                  }))}
                ></QuestionnaireForm.Select>
              </QuestionnaireForm.Group>
            </Styled.QuestionnaireCenteredText>
          </Grid>
        </Styled.QuestionnaireSplitFormDiv>
      );
    });
  };

  return (
    <>
      {getExperienceSubgroupMobile()}
      {isEcsp && getExperienceSubgroupMobile('crowdfunding')}
    </>
  );
};

export default SplitFormSegmentMobile;
