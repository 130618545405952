import { camelCase } from 'change-case';
import ServerError from 'ui/types/server-error';

export interface ErrorInterface {
  error_codes: any[];
  validation_errors: any;
}

export class ApiError {
  private readonly info?: ErrorInterface;
  private readonly raw?: Response;

  constructor(info?: ErrorInterface | undefined, raw?: Response) {
    this.info = info;
    this.raw = raw;
  }

  get response(): Response | undefined {
    return this.raw;
  }

  hasInfo(): boolean {
    return !!this.info;
  }

  getMessage(translate: any): string {
    if (!this.info) return translate('errors.unknownError');

    const errorCode = [...this.info.error_codes].shift();
    const errorCodeStr = typeof errorCode === 'string' ? camelCase(errorCode) : errorCode.code;

    return translate(`errors.${errorCodeStr}`) || translate(`errors.unknownError`);
  }

  get status(): number {
    return this.raw?.status || 500;
  }

  getErrorCode(code: string): any {
    if (!this.info) return undefined;
    const matches = this.info.error_codes.filter((x) => {
      return x === code || (x as any).code === code;
    });
    if (matches.length === 0) return undefined;
    return matches[0];
  }

  hasErrorCode(code: string): boolean {
    return !!this.getErrorCode(code);
  }

  getFieldError(field: string): any {
    if (!this.info) return undefined;
    return this.info.validation_errors[field];
  }

  hasFieldError(field: string): boolean {
    return !!this.getFieldError(field);
  }

  get errorFields(): string[] {
    const map = this.info && this.info.validation_errors;
    if (!map) return [];
    return Object.keys(map);
  }

  get errorCodes(): any[] {
    return (this.info && this.info.error_codes) || [];
  }
}

export class ApiServerError implements ServerError {
  private apiError: ApiError;
  errorFields: string[];
  errorCodes: any[];

  constructor(apiError: ApiError) {
    this.apiError = apiError;
    this.errorFields = apiError.errorFields;
    this.errorCodes = apiError.errorCodes;
  }

  hasInfo(): boolean {
    return this.apiError.hasInfo();
  }

  hasErrorCode(code: string): boolean {
    return this.apiError.hasErrorCode(code);
  }

  getErrorCode(code: string): any {
    return this.apiError.getErrorCode(code);
  }

  hasFieldError(field: string): boolean {
    return this.apiError.hasFieldError(field);
  }

  getFieldError(field: string): any {
    return this.apiError.getFieldError(field);
  }

  getMessage(translate: any): string {
    return this.apiError.getMessage(translate);
  }

  get status(): number {
    return this.apiError.status;
  }
}

export class UnknownServerError implements ServerError {
  exception: any;
  response: undefined;
  status: undefined;
  errorFields: string[];
  errorCodes: any[];

  constructor(exception: any) {
    this.exception = exception;
    this.errorFields = this.errorCodes = [];
  }

  hasInfo(): false {
    return false;
  }

  hasErrorCode(code: string): false {
    return false;
  }

  getErrorCode(code: string): undefined {
    return undefined;
  }

  hasFieldError(field: string): false {
    return false;
  }

  getFieldError(field: string): undefined {
    return undefined;
  }

  getMessage(translate: any): string {
    return translate('errors.unknownError');
  }
}

export const createServerErrorFromException = (exception: any) => {
  if (exception instanceof ApiError) return new ApiServerError(exception);
  return new UnknownServerError(exception);
};
