import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

export const GRID_COLUMNS = 12;

export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type ColType =
  | ColumnSize
  | 'auto'
  | {
      width?: ColumnSize | 'auto';
      offset?: ColumnSize;
    };
export interface StyledColProps {
  $width?: ColType;
  $phone?: ColType;
  $tablet?: ColType;
  $lTablet?: ColType;
  $sDesktop?: ColType;
  $desktop?: ColType;
  $lDesktop?: ColType;
  $spacing?: Spacing;
  $disablePadding?: boolean;
}

function getColWidthAllDevicesStyle(props: StyledColProps) {
  return css<StyledColProps>`
    ${props.$width &&
    css`
      ${getColWidthAndFlex(props.$width)}
    `}
    ${props.$phone && query.phone`${getColWidthAndFlex(props.$phone)}`}
    ${props.$tablet && query.tablet`${getColWidthAndFlex(props.$tablet)}`}
    ${props.$lTablet && query.lTablet`${getColWidthAndFlex(props.$lTablet)}`}
    ${props.$sDesktop && query.sDesktop`${getColWidthAndFlex(props.$sDesktop)}`}
    ${props.$desktop && query.desktop`${getColWidthAndFlex(props.$desktop)}`}
    ${props.$lDesktop && query.lDesktop`${getColWidthAndFlex(props.$lDesktop)}`}
  `;
}

function getColWidthAndFlex(colWidth: ColType) {
  if (colWidth === 'auto' || (typeof colWidth === 'object' && colWidth.width === 'auto')) {
    return `
      flex-basis: auto;
      flex-grow: 0;
      width: auto;
      ${getOffset(colWidth)}
    `;
  }

  if (typeof colWidth === 'object' && colWidth.width !== 'auto') {
    return css`
      ${!!colWidth.width && getWidth(colWidth.width)};
      ${getOffset(colWidth)};
    `;
  }

  if (typeof colWidth !== 'object') {
    return css`
      ${getWidth(colWidth)}
      ${getOffset(colWidth)}
    `;
  }
}

function getOffset(colWidth: ColType) {
  if (typeof colWidth !== 'object' || !colWidth.offset) return null;

  return css`
    margin-left: calc(100% * ${colWidth.offset / GRID_COLUMNS});
  `;
}

function getWidth(colWidth: ColumnSize) {
  return css`
    flex-basis: calc(100% * ${colWidth / GRID_COLUMNS});
    width: calc(100% * ${colWidth / GRID_COLUMNS});
    flex-grow: 0;
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<StyledColProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacing[$spacing]}rem;
    }
  `;
}

export const StyledCol = styled.div<StyledColProps>`
  flex: 0 0 100%;
  max-width: 100%;
  padding: ${({ $disablePadding }) => ($disablePadding ? '0' : '0 1rem')};
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis: 0;
  flex-grow: 1;
  align-self: initial;
  ${(props) => getColWidthAllDevicesStyle(props)};
  ${getSpacing}
`;
