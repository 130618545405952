import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { StateValues } from 'react-use-form-state';
import {
  AdminApi,
  AdminInvestmentCreationRequest,
  AdminInvestorList,
  AdminSignedDocumentCreationRequest,
  ComplianceSubjectEnum,
  DocumentsApi,
  KycStatusEnum,
} from 'api';
import useApiCall from 'hooks/use-api-call';
import { FileToUpload } from 'src/ui/atoms/file-upload/multiple-file-upload';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';
import { toMoney, toNumber } from 'src/ui/helper/money';
import { AddInvestmentFields } from '.';

export const useAddInvestment = (campaignId: string, onAddNewInvestment: () => void) => {
  const { makeAuthenticatedApi, withApi } = useApiCall();

  const [investors, setInvestors] = useState<AdminInvestorList[] | undefined>([]);
  const [selectedInvestor, setSelectedInvestor] = useState<AdminInvestorList | null>(null);
  const [error, setError] = useState('');

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const translate = useTranslateWithStringArgs();

  const searchInvestor = useCallback(
    (investorName: string) => {
      withApi(async () => {
        const { results } = await api.adminInvestorsList({ search: investorName });
        const onlyKycSuccessInvestors = results?.filter((investor) => investor.kycStatus === KycStatusEnum.SUCCESS);

        setInvestors(onlyKycSuccessInvestors);
      });
    },
    [withApi, api],
  );

  const debouncedSearch = debounce(searchInvestor, 500);

  const onTypeInvestor = (investorName: string) => {
    if (investorName.length < 2) return;

    debouncedSearch(investorName);
  };

  const getDocumentTypes = useCallback(() => {
    return Object.values(ComplianceSubjectEnum).map((document) => ({ label: document }));
  }, []);

  const uploadFiles = useCallback(async (documents: FileToUpload[]) => {
    const documentsRequest: Array<AdminSignedDocumentCreationRequest> = [];

    await Promise.all(
      documents.map(async (document: FileToUpload) => {
        const documentResponse = await documentsApi.adminDocumentsCreate({ file: document.file });
        documentsRequest.push({
          documentId: documentResponse.id,
          complianceSubject: document.documentType as ComplianceSubjectEnum,
        });
      }),
    );

    return documentsRequest;
  }, []);

  const validate = useCallback(
    (fieldsValues: StateValues<AddInvestmentFields>) => {
      let errorMessage = '';

      if (!selectedInvestor) {
        errorMessage = translate('addInvestment.errors.noInvestor');
      } else if (fieldsValues.acceptedAt < fieldsValues.signedAt) {
        errorMessage = translate('addInvestment.errors.acceptedDate');
      } else if (fieldsValues.paidAt < fieldsValues.acceptedAt) {
        errorMessage = translate('addInvestment.errors.paidDate');
      } else if (fieldsValues.signedAt > fieldsValues.paidAt) {
        errorMessage = translate('addInvestment.errors.signedDate');
      }

      return errorMessage;
    },
    [selectedInvestor],
  );

  const onSubmit = useCallback(
    async (fieldsValues: StateValues<AddInvestmentFields>) => {
      setError('');

      if (validate(fieldsValues).length > 0) {
        setError(validate(fieldsValues));
        return;
      }

      const params: AdminInvestmentCreationRequest = {
        investorId: selectedInvestor!.id,
        walletId: fieldsValues.wallet,
        units: toNumber(fieldsValues.units),
        signedAt: fieldsValues.signedAt,
        paidAt: fieldsValues.paidAt,
        acceptedAt: fieldsValues.acceptedAt,
      };

      if (fieldsValues.disagio) {
        params.disagio = toMoney(fieldsValues.disagio);
      }

      if (fieldsValues.exemptionOrderAmount) {
        params.exemptionOrderAmount = toMoney(fieldsValues.exemptionOrderAmount);
      }

      if (fieldsValues.documents) {
        let isDocumentTypeSet = true;
        fieldsValues.documents.forEach((document) => {
          if (!document.documentType) {
            isDocumentTypeSet = false;
            return;
          }
        });
        if (!isDocumentTypeSet) {
          setError(translate('addInvestment.errors.noDocumentType'));
          return;
        }

        const documents: AdminSignedDocumentCreationRequest[] = await uploadFiles(fieldsValues.documents);
        params.signedDocuments = documents;
      }
      withApi(async () => {
        await api.adminCampaignsInvestmentsCreate({ id: campaignId, adminInvestmentCreationRequest: params });
        onAddNewInvestment();
      });
    },
    [selectedInvestor],
  );

  return {
    investors,
    selectedInvestor,
    error,
    setSelectedInvestor,
    getDocumentTypes,
    onTypeInvestor,
    onSubmit,
  };
};
