import { TokenTransferStatusEnum } from 'api';
import { TransferStatus } from 'ui/types/transfer';
import type { ColorVariants } from '../styles/themes/color-variants';

export const transferStatusToVariant = (status: TransferStatus | TokenTransferStatusEnum) => {
  switch (status) {
    case TransferStatus.APPROVER_REJECTED:
    case TransferStatus.FAILED:
    case TransferStatus.TFA_REJECTED:
      return 'danger';
    case TransferStatus.PENDING:
    case TransferStatus.PROCESSING:
    case TransferStatus.TFA_REQUESTED:
    case TransferStatus.TFA_CONFIRMED:
      return 'warning';
    case TransferStatus.COMPLETED:
      return 'success';
    default:
      return 'info';
  }
};

export const variantToIconName = (variant: ColorVariants) => {
  switch (variant) {
    case 'success':
      return 'check';
    case 'warning':
      return 'exclamation';
    case 'danger':
      return 'cross';
    case 'info':
      return 'info';
    case 'primary':
      return 'info';
    default:
      return 'info';
  }
};

export const variantToNotificationColor = (variant: ColorVariants) => {
  switch (variant) {
    case 'success':
      return 'greenLight';
    case 'warning':
      return 'yellowLight';
    case 'danger':
      return 'redLight';
    case 'info':
      return 'grayLight';
    case 'primary':
      return 'grayLight';
    default:
      return 'grayLight';
  }
};

export const variantToIconColor = (variant: ColorVariants) => {
  switch (variant) {
    case 'success':
      return 'greenMain';
    case 'danger':
      return 'redMain';
    case 'warning':
    case 'info':
      return '#303539';
    case 'primary':
      return 'primaryDark';
    default:
      return 'primaryDark';
  }
};
