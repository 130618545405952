import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InvestmentTokenForInvestor, User, Wallet, WalletTypeEnum } from 'api';
import { useApiCall } from 'hooks/use-api-call';
import { TwoFactorAuthApi, UsersApi } from 'api';
import TfaDeviceSetupModal from 'src/libraries/tfa-device-setup-modal';

interface TfaDeviceSetupProps {
  token: InvestmentTokenForInvestor;
  wallet: Wallet;
  onClose?: () => void;
}

const WithTfaDevice = <P extends object>(Component: ComponentType<P>) => {
  const TfaDevice: FunctionComponent<P & TfaDeviceSetupProps> = (props) => {
    const { wallet, onClose = () => {} } = props;

    const [me, setMe] = useState<User>();

    const { error, makeAuthenticatedApi, withApi } = useApiCall();

    const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

    const sendTwoFactorAuthInitializationEmail = useCallback(() => {
      const api: TwoFactorAuthApi = makeAuthenticatedApi(TwoFactorAuthApi);
      withApi(async () => {
        await api.twoFactorAuthDeviceInitializationCreate({
          tFADeviceInitializationRequest: {
            walletNeeded: wallet.type === WalletTypeEnum.MOBILE,
          },
        });
      });
    }, [withApi, makeAuthenticatedApi, wallet]);

    useEffect(() => {
      sendTwoFactorAuthInitializationEmail();
    }, []);

    const load = useCallback(() => {
      withApi(async () => {
        const me = await usersApi.usersMeRetrieve();
        setMe(me);
      });
    }, [withApi, usersApi]);

    useEffect(() => {
      load();
    }, [load]);

    const tfaSetupComplete = me?.tfaDevice?.confirmed;

    const timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
      if (tfaSetupComplete) return;
      timeoutHandler.current = setInterval(load, 2000);
      return () => {
        if (timeoutHandler.current) {
          clearInterval(timeoutHandler.current);
        }
      };
    }, [tfaSetupComplete, load]);

    useEffect(() => {
      if (error && timeoutHandler.current) {
        clearInterval(timeoutHandler.current);
      }
    }, [error, timeoutHandler]);

    if (!tfaSetupComplete) {
      return (
        <TfaDeviceSetupModal
          user={me}
          onResendEmail={sendTwoFactorAuthInitializationEmail}
          onClose={onClose}
          error={error}
        />
      );
    }
    return <Component {...(props as P)} />;
  };
  return TfaDevice;
};

export default WithTfaDevice;
