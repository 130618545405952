import styled from 'styled-components';

export const TransInvestorInfo = styled.div`
  display: flex;
  align-items: center;

  .trans-icon {
    margin-left: 12px;
    margin-right: 12px;
  }

  .external-link {
    margin-left: 12px;
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .external-link {
      visibility: initial;
    }
  }
`;
