import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import StatusTag from 'ui/atoms/status-tag';

export const ProductCampaignsStatus: FunctionComponent<{
  active: true | false | undefined;
}> = ({ active }) => {
  return (
    <StatusTag variant={active ? 'success' : 'danger'} truncate={true}>
      <Translate name={`issuerProductDetails.campaigns.status.${active ? 'active' : 'deactivated'}`} />
    </StatusTag>
  );
};
