import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

const defaults = {
  strengthIndicatorBgColor: '#F4F4F5',
  strenghtBarBgColor: '#B0B5B9',
};
export interface StrengthBarProps {
  $color?: string;
}

function getStrengthIndicatorContainerDefaultStyle() {
  return css`
    background-color: ${defaults.strengthIndicatorBgColor};
    padding: 1rem;
    margin-top: 4px;
    border-radius: 8px;
  `;
}

function getStrengthIndicatorDefaultStyle() {
  return css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.3rem;
    height: 1.5rem;
    max-width: 700px;
    align-items: center;
  `;
}

function getStrengthBarDefaultStyle({ $color }: ThemedStyledProps<StrengthBarProps, DefaultTheme>) {
  return css`
    position: relative;
    height: 100%;
    :after {
      content: '';
      background-color: ${defaults.strenghtBarBgColor};
      height: 6px;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      border-radius: 10rem;
      top: 50%;
      min-width: 100%;
      ${$color &&
      `
        background-color: ${$color};
      `}
    }
  `;
}

function getStrengthDescriptionTitleDefaultStyle() {
  return css`
    p {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0.5rem;

      ${query.phone`
        font-size: 12px;
      `};
    }
  `;
}

function getStrengthDescriptionContentDefaultStyle() {
  return css`
    p {
      font-size: 14px;
      margin-bottom: 0;

      ${query.phone`
        font-size: 12px;
      `};
    }
  `;
}

export const StrengthIndicatorContainer = styled.div`
  ${getStrengthIndicatorContainerDefaultStyle};
`;

export const StrengthIndicator = styled.div`
  ${getStrengthIndicatorDefaultStyle};
`;

export const StrengthBar = styled.div<StrengthBarProps>`
  ${getStrengthBarDefaultStyle};
`;

export const StrengthDescriptionTitle = styled.div`
  ${getStrengthDescriptionTitleDefaultStyle};
`;

export const StrengthDescriptionContent = styled.div`
  ${getStrengthDescriptionContentDefaultStyle};
`;
