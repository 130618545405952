import { resolveColor } from 'ui/styles/utils/colors';
import styled from 'styled-components';
import Section from 'ui/atoms/section';

export const WizardHeader = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
`;

export const SubStep = styled.span`
  color: ${({ theme }) => resolveColor(theme, 'grayHover')};
`;
