import React from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';
import { useInvestorHasInvestmentsSelector } from 'apps/investor/resources/investments/hooks';
import DocumentTable from 'src/libraries/document-table';
import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import InvestorEmptyView from 'core/components/empty-views/investor';

const InvestorDocumentsPage = () => {
  const { investor: me, loading } = useInvestorSelector();
  const { hasInvestments } = useInvestorHasInvestmentsSelector();

  if (loading) {
    return <LoadingRing />;
  }

  return (
    <WideContent title={<Translate name="dashboard.investorDocuments" />}>
      {me?.documents && me.documents.length > 0 && <DocumentTable documents={me.documents} />}
      {me?.documents && me.documents.length === 0 && (
        <InvestorEmptyView
          type={hasInvestments ? EmptyViewType.INVESTOR_DOCUMENTS : EmptyViewType.REGISTERED_DOCUMENTS}
        />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorDocumentsPage, AppType.INVESTOR);
