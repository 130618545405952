import React, { FunctionComponent } from 'react';
import { useCurrentUserSelector } from 'core/auth/hooks';
import WalletCreationSuccess from 'subapps/investment/pages/investment/wizard-steps/wallet/confirm/wallet-creation-success';
import Translate from 'ui/atoms/translate';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const ConfirmWalletStep: FunctionComponent<{}> = () => {
  const { currentUser } = useCurrentUserSelector();

  const title = <Translate name="investmentDepot.title" />;

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (!currentUser?.email) return null;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <WalletCreationSuccess receiverEmail={currentUser.email} />
    </>
  );
};

export default ConfirmWalletStep;
