import { AdminApi, CountryEnum, DocumentsApi, LegalEntityTypeEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface EditInvestorCompanyDataFields {
  id: string;
  companyName: string;
  legalEntityType: LegalEntityTypeEnum | undefined;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
  commercialRegister: string;
  commercialRegisterNumber: string;
  commercialRegisterStatement: Blob | null;
  listOfShareholders: Blob | null;
  transparencyRegister: Blob | null;
  commercialRegisterStatementId?: string;
  listOfShareholdersId?: string;
  transparencyRegisterId?: string;
}

export interface EditCompanyDataModalProps {
  onHideEditCompanyData: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  values: EditInvestorCompanyDataFields;
}

const EditInvestorCompanyDataForm = makeModalForm<EditInvestorCompanyDataFields>();

const EditCompanyDataModal: FunctionComponent<EditCompanyDataModalProps> = (props) => {
  const { fetchInvestorDetails, onHideEditCompanyData, showSuccessModal = () => {}, values } = props;

  const [investorData, setInvestorData] = useState<EditInvestorCompanyDataFields>(values);

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const editCompanyData = (fieldsValues: StateValues<EditInvestorCompanyDataFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        adminLegalPersonUpdateRequest: {
          ...fieldsValues,
        },
      };

      if (fieldsValues.commercialRegisterStatement) {
        const commercialRegisterStatementResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.commercialRegisterStatement,
        });
        patchBody.adminLegalPersonUpdateRequest.commercialRegisterStatementId = commercialRegisterStatementResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.commercialRegisterStatementId = values.commercialRegisterStatementId;
      }
      if (fieldsValues.listOfShareholders) {
        const listOfShareholdersResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.listOfShareholders,
        });
        patchBody.adminLegalPersonUpdateRequest.listOfShareholdersId = listOfShareholdersResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.listOfShareholdersId = values.listOfShareholdersId;
      }
      if (fieldsValues.transparencyRegister) {
        const transparencyRegisterResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.transparencyRegister,
        });
        patchBody.adminLegalPersonUpdateRequest.transparencyRegisterId = transparencyRegisterResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.transparencyRegisterId = values.transparencyRegisterId;
      }
      await investorsApi.adminInvestorsLegalPersonCreate(patchBody);

      showSuccessModal();
      fetchInvestorDetails();
      onHideEditCompanyData();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditCompanyData}>
        <EditInvestorCompanyDataForm
          initial={{
            companyName: investorData.companyName,
            legalEntityType: investorData.legalEntityType,
            street: investorData.street,
            zip: investorData.zip,
            city: investorData.city,
            country: investorData.country,
            commercialRegister: investorData.commercialRegister,
            commercialRegisterNumber: investorData.commercialRegisterNumber,
            commercialRegisterStatement: null,
            listOfShareholders: null,
          }}
          onChange={(values: StateValues<EditInvestorCompanyDataFields>) => {
            setInvestorData(values);
          }}
          onSubmit={(values: StateValues<EditInvestorCompanyDataFields>) => {
            return editCompanyData(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editCompanyDataForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editCompanyDataForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditInvestorCompanyDataForm.Group name="companyName" required={!!values.companyName}>
              <EditInvestorCompanyDataForm.Input autoFocus={true} placeholder="-" />
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="legalEntityType" required={true}>
              <EditInvestorCompanyDataForm.Select
                value={values.legalEntityType}
                options={Object.values(LegalEntityTypeEnum).map((legalType) => ({
                  value: legalType,
                  label: legalType,
                }))}
              ></EditInvestorCompanyDataForm.Select>
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="street" required={!!values.street}>
              <EditInvestorCompanyDataForm.Input placeholder="-" />
            </EditInvestorCompanyDataForm.Group>
            <Grid>
              <Col width={4} spacing="small">
                <EditInvestorCompanyDataForm.Group name="zip" required={!!values.zip}>
                  <EditInvestorCompanyDataForm.Input placeholder="-" />
                </EditInvestorCompanyDataForm.Group>
              </Col>
              <Col width={8} spacing="small">
                <EditInvestorCompanyDataForm.Group name="city" required={!!values.city}>
                  <EditInvestorCompanyDataForm.Input value={investorData.city} placeholder="-" />
                </EditInvestorCompanyDataForm.Group>
              </Col>
            </Grid>
            <EditInvestorCompanyDataForm.Group name="country" required={!!values.country}>
              <EditInvestorCompanyDataForm.Select
                options={useCountries()}
                value={investorData.country}
                onChange={(country: CountryEnum) => {
                  setInvestorData({ ...investorData, country });
                }}
              ></EditInvestorCompanyDataForm.Select>
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="commercialRegister" required={!!values.commercialRegister}>
              <EditInvestorCompanyDataForm.Input />
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group
              name="commercialRegisterNumber"
              required={!!values.commercialRegisterNumber}
            >
              <EditInvestorCompanyDataForm.Input />
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="commercialRegisterStatement">
              <EditInvestorCompanyDataForm.FileUpload />
              {EditInvestorCompanyDataForm.Validators.FileSize(5000000)}
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="listOfShareholders">
              <EditInvestorCompanyDataForm.FileUpload />
              {EditInvestorCompanyDataForm.Validators.FileSize(5000000)}
            </EditInvestorCompanyDataForm.Group>
            <EditInvestorCompanyDataForm.Group name="transparencyRegister">
              <EditInvestorCompanyDataForm.FileUpload />
              {EditInvestorCompanyDataForm.Validators.FileSize(5000000)}
            </EditInvestorCompanyDataForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditCompanyData,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editCompanyDataForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                },
              ]}
            />
          </ModalFooter>
        </EditInvestorCompanyDataForm>
      </Modal>
    </>
  );
};

export default EditCompanyDataModal;
