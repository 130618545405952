import React from 'react';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import type { FunctionComponent } from 'react';
import useTranslate from 'ui/hooks/use-translate';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';

export interface FormSegmentHeaderProps {
  /** Additional classes. */
  className?: string;
  segmentIndex: number;
  segmentId: string;
  showDescription?: boolean;
}

const FormSegmentHeader: FunctionComponent<FormSegmentHeaderProps> = ({ segmentIndex, segmentId, showDescription }) => {
  const translate = useTranslate();

  const segment = snakeToCamelCaseWithNumbers(segmentId);

  return (
    <>
      <Header size="small" spacing="small">
        {segmentIndex ? `${segmentIndex}.` : null} {translate(`questionnaire.parts.${segment}.title`).toString()}
      </Header>
      {!!translate(`questionnaire.parts.${segment}.description`)?.length && !!showDescription && (
        <p>
          <Translate name={`questionnaire.parts.${segment}.description`} />
        </p>
      )}
    </>
  );
};

export default FormSegmentHeader;
