import { useEffect } from 'react';

import { useServerConfigSelector } from 'core/config/hooks';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import appConfig from 'core/config/local';

import './hubspot-chatbot.scss';

const useHubspotChatbot = () => {
  const { config } = useServerConfigSelector();
  const hubId = config.hubspotId;

  const isDesktop = useIsMedia(DEVICES.desktops);

  useEffect(() => {
    // Disable HS tracking and opt out of 3rd-party cookies
    document.cookie = '__hs_opt_out=yes';
    document.cookie = '__hs_do_not_track=yes';

    if (hubId && (isDesktop || appConfig.hubspotChatbotOnMobile)) {
      let script = document.getElementById('hs-script-loader');

      if (!script) {
        script = document.createElement('script');
        const attrs = {
          type: 'text/javascript',
          id: 'hs-script-loader',
          async: 'async',
          defer: 'defer',
          src: `https://js.hs-scripts.com/${hubId}.js`,
        };
        for (const [key, value] of Object.entries(attrs)) {
          script.setAttribute(key, value);
        }
        document.head.appendChild(script);
      }
    }

    // Activate CSS which shows the widget
    document.body.classList.add('hubspot-chatbot-active');

    return () => {
      document.body.classList.remove('hubspot-chatbot-active');
    };
  }, [hubId, isDesktop]);
};

export default useHubspotChatbot;
