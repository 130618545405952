import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Container from 'ui/atoms/container';
import { resolveColor } from 'ui/styles/utils/colors';

type StepNumberProps = {
  $lastItem?: boolean;
};

const stepListDefaults = {
  spaceBetweenSteps: '1rem',
};

function getDefaultStepListStyle() {
  return css`
    text-align: center;
  `;
}

function getDefaultStepItemStyle() {
  return css`
    display: flex;
    margin-bottom: ${stepListDefaults.spaceBetweenSteps};
    &:last-of-type {
      margin-bottom: 0;
    }
  `;
}

function getDefaultVericalItemColStyle() {
  return css`
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: inherit;
    margin-top: 5px;
  `;
}

function getDefaultStepDescriptionStyle() {
  return css`
    text-align: center;
  `;
}

function getDefaultStepNumberStyle({ theme, $lastItem }: ThemedStyledProps<StepNumberProps, DefaultTheme>) {
  return css`
    text-align: center;
    position: relative;
    vertical-align: center;
    margin-bottom: 2rem;
    // create horizontal line connecting the steps
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      top: 50%;
      height: 0.2rem;
      background: ${resolveColor(theme, 'dark')};
    }
    // do not show horizontal line after last step
    ${$lastItem &&
    css`
      :after {
        content: none;
      }
    `}
  `;
}

export const StepList = styled(Container)`
  ${getDefaultStepListStyle}
`;

export const StepItem = styled.div`
  ${getDefaultStepItemStyle}
`;

export const VerticalItemCol = styled.div`
  ${getDefaultVericalItemColStyle}
`;

export const StepDescription = styled.div`
  ${getDefaultStepDescriptionStyle}
`;
export const StepNumber = styled.div<StepNumberProps>`
  ${getDefaultStepNumberStyle}
`;
