import React, { FunctionComponent } from 'react';
import groupBy from 'lodash/groupBy';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Translate from 'ui/atoms/translate';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import * as Styled from './styled';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export interface SplitFormSegmentDesktopProps {
  questions: QuestionnaireQuestions[];
  segmentId?: string;
  isEcsp: boolean;
}

const SplitFormSegmentDesktop: FunctionComponent<SplitFormSegmentDesktopProps> = ({ questions, segmentId, isEcsp }) => {
  const questionRows = groupBy(questions, (question) => question.productId);

  const translate = useTranslateWithStringArgs();

  const getExperienceSubgroupDesktop = (subgroup?: string) => {
    const subgroupDescription = subgroup ? subgroup.charAt(0).toUpperCase() + subgroup.slice(1) : 'Product';

    return (
      <>
        <Translate
          name={`questionnaire.parts.${snakeToCamelCaseWithNumbers(segmentId || '')}.description${subgroupDescription}`}
        />
        <Styled.QuestionnaireSplitFormRow>
          <Styled.QuestionnaireDesktopCol width={5}>
            <Translate name={`questionnaire.investmentExperienceCategories.${subgroup || 'product'}`} as="b" />
          </Styled.QuestionnaireDesktopCol>
          <Styled.QuestionnaireDesktopCol width={3}>
            <Translate name="questionnaire.investmentExperienceCategories.knowledge" as="b" />
          </Styled.QuestionnaireDesktopCol>
          <Styled.QuestionnaireDesktopCol width={4}>
            <Translate name="questionnaire.investmentExperienceCategories.experience" as="b" />
          </Styled.QuestionnaireDesktopCol>
        </Styled.QuestionnaireSplitFormRow>
        {Object.entries(questionRows).map(([productId, questionRow]) => {
          const [knowledge, experience] = questionRow;

          if ((subgroup && knowledge.subgroupId !== subgroup) || (!subgroup && knowledge.subgroupId)) return null;

          return (
            <Styled.QuestionnaireSplitFormRow key={productId}>
              <Styled.QuestionnaireDesktopCol width={5}>
                <Translate as="h4" name={`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(productId)}`} />
              </Styled.QuestionnaireDesktopCol>
              <Styled.QuestionnaireDesktopCol width={3}>
                <QuestionnaireForm.Group key={knowledge.id} name={knowledge.id}>
                  <QuestionnaireForm.GroupToggle>
                    {[
                      { name: 'true', value: 'knowledge' },
                      { name: 'false', value: 'no_knowledge' },
                    ].map((option, index) => (
                      <QuestionnaireForm.Radio value={option.value} key={option.value} spacing="small">
                        <Translate name={`commonOptions.${option.name}`} />
                      </QuestionnaireForm.Radio>
                    ))}
                  </QuestionnaireForm.GroupToggle>
                </QuestionnaireForm.Group>
              </Styled.QuestionnaireDesktopCol>
              <Styled.QuestionnaireDesktopCol width={4}>
                <Styled.SelectGroupContainer key={experience.id} name={experience.id}>
                  <QuestionnaireForm.Select
                    options={(experience.options || []).map((option) => ({
                      value: option,
                      label: translate(`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(option)}`),
                    }))}
                  ></QuestionnaireForm.Select>
                </Styled.SelectGroupContainer>
              </Styled.QuestionnaireDesktopCol>
            </Styled.QuestionnaireSplitFormRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      {getExperienceSubgroupDesktop()}
      {isEcsp && getExperienceSubgroupDesktop('crowdfunding')}
    </>
  );
};

export default SplitFormSegmentDesktop;
