import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';
import type { Spacing } from 'ui/styles/themes/spacing';
import { query } from 'ui/styles/queries';

export type TextFormattingOptions =
  | 'b'
  | 'strong'
  | 'bold'
  | 'i'
  | 'italic'
  | 'em'
  | 'u'
  | 'underline'
  | 'small'
  | 'sub'
  | 'subscript'
  | 'sup'
  | 'superscript'
  | 'line-through'
  | 'strikethrough'
  | string;

export type TextAlignOptions = 'left' | 'right' | 'center' | 'justify';

export interface TextProps {
  $format?: Array<TextFormattingOptions>;
  $align?: TextAlignOptions;
  $color?: Colors;
  $spacing?: Spacing;
  $textTransform?: string;
  $fontSize?: number;
  $wordBreak?: string;
  $indent?: number;
  $lineClamp?: number;
}

function getFormatStyles(theme: DefaultTheme, item: TextFormattingOptions, items: TextFormattingOptions[]) {
  switch (item) {
    case 'b':
    case 'strong':
    case 'bold':
      return css`
        font-weight: bold;
      `;
    case 'i':
    case 'italic':
    case 'em':
      return css`
        font-style: italic;
      `;
    case 'u':
    case 'underline':
      if (items.some((i) => i === 'line-through' || i === 'strikethrough'))
        return css`
          text-decoration: underline line-through;
        `;
      return css`
        text-decoration: underline;
      `;
    case 'small':
      return css`
        font-size: 12px;

        ${query.phone`
          font-size: 11px;
        `};
      `;
    case 'sub':
    case 'subscript':
      return css`
        vertical-align: sub;
        font-size: smaller;
      `;
    case 'sup':
    case 'superscript':
      return css`
        vertical-align: super;
        font-size: smaller;
      `;
    case 'strikethrough':
    case 'line-through':
      if (items.some((i) => i === 'u' || i === 'underline')) return null;
      return css`
        text-decoration: line-through;
      `;
    default:
      return null;
  }
}

function getAlignOptions({ $align }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$align) return;

  switch ($align) {
    case 'center':
      return css`
        text-align: center;
        justify-content: center;
      `;
    case 'justify':
      return css`
        text-align: justify;
        justify-content: space-between;
      `;
    case 'left':
      return css`
        text-align: left;
        justify-content: flex-start;
      `;
    case 'right':
      return css`
        text-align: right;
        justify-content: flex-end;
      `;
    default:
      return null;
  }
}

function getIndent({ $indent }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$indent) return null;

  return css`
    padding-left: ${20 * $indent}px;
  `;
}

function getTextTransform({ $textTransform }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$textTransform) return null;

  return css`
    text-transform: ${$textTransform};
  `;
}

function getColor({ theme, $color }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$color) return null;

  return css`
    color: ${resolveColor(theme, $color)};
  `;
}

function getFontSize({ $fontSize }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$fontSize) return null;

  return css`
    font-size: ${$fontSize}px;
    line-height: 1.2em;
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css`
    margin-bottom: ${theme.spacing[$spacing]}rem;
  `;
}

function getWordBreak({ $wordBreak }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$wordBreak) return null;

  return css`
    word-break: ${$wordBreak};
  `;
}

export const Text = styled.span<TextProps>`
  text-wrap: pretty;
  ${({ $format, theme }) => $format?.length && $format.map((item) => getFormatStyles(theme, item, $format))};
  ${getAlignOptions};
  ${getIndent};
  ${getTextTransform};
  ${getFontSize};
  ${getColor};
  ${getWordBreak};
  ${getSpacing};
`;
