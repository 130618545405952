import React, { FunctionComponent, useContext } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import CreateAccount, { CreateAccountValues } from './create-account';
import ProcessOverview from './process-overview';
import { PersonType } from 'ui/types/person';
import Translate from 'ui/atoms/translate';
import { LoginCard } from 'core/auth/pages/login';
import Hint from 'ui/atoms/hint';
import { extractNaturalAndLegalFromPerson } from 'core/api/conversions';
import usePasswordStrength from 'hooks/use-password-strength';
import PublicPage from 'core/auth/components/public-page';
import useInvestorMe from 'hooks/use-investor-me';
import { Config, ExternalCampaignInvitation } from 'api';
import { AnyServerError } from 'hooks/use-api-error';
import WizardHeader from 'libraries/wizard/wizard-header';
import ActionButtons from 'ui/molecules/action-buttons';
import Header from 'ui/atoms/header';
import WizardContext from '../../wizard-context';
import useInvestmentInvitation from 'src/subapps/investment/pages/investment/hooks/use-investment-invitation';

export type PreSelectionMode = 'overview' | 'login' | undefined;

export interface AccountSetupStepProps {
  onCloseProcessOverviewOverlay: (id: string) => void;
  setPreSelectionMode: (mode: PreSelectionMode) => void;
  onSubmit: (values: CreateAccountValues) => void;
  showProcessOverview: boolean;
  preSelectionMode?: string;
  continueProcessURL: string;
  createAccountMode: boolean;
  email?: string;
  isLoggedIn: boolean;
  config: Config;
  resourceError?: AnyServerError;
  apiError?: AnyServerError;
  apiLoading: boolean;
  showLoading: boolean;
  isTransferInvitation: boolean;
  externalInvitation?: ExternalCampaignInvitation;
  allowedPersonTypes: PersonType[];
  overviewSteps: string[];
}

const AccountSetupStep: FunctionComponent<AccountSetupStepProps> = ({
  onCloseProcessOverviewOverlay,
  setPreSelectionMode,
  onSubmit,
  showProcessOverview,
  preSelectionMode,
  createAccountMode,
  config,
  email,
  isLoggedIn,
  continueProcessURL,
  isTransferInvitation,
  externalInvitation,
  showLoading,
  allowedPersonTypes,
  resourceError,
  apiError,
  apiLoading,
  overviewSteps,
}) => {
  const { investor: me } = useInvestorMe();

  const { resourceId: invitationId } = useContext(WizardContext);
  const { token, invitation } = useInvestmentInvitation(invitationId);

  const getPasswordStrength = usePasswordStrength(createAccountMode);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (resourceError) {
    return null;
  }

  if (showLoading) return <LoadingRing />;

  const isCampaign = !email; // TODO(geforcefan): think about proper casting
  const shouldShowPreSelectionMode = isCampaign && !isLoggedIn;

  // show pre login or create account screen for campaigns without credentials
  if (shouldShowPreSelectionMode) {
    if (preSelectionMode === 'overview') {
      return (
        <>
          <WizardHeader />
          {!showProcessOverview && (
            <Header size="large" spacing="xlarge">
              <Translate name="investmentAccountSelection.title" />
            </Header>
          )}
          {showProcessOverview && (
            <ProcessOverview
              onCloseProcessOverviewOverlay={onCloseProcessOverviewOverlay}
              continueURL={continueProcessURL}
              overviewSteps={overviewSteps}
              token={token}
              pricePerToken={invitation?.pricePerToken}
              minNumberOfTokens={invitation?.minNumberOfTokens}
              maxNumberOfTokens={invitation?.maxNumberOfTokens}
            />
          )}

          {!showProcessOverview && (
            <>
              <Translate name="investmentAccountSelection.information.1" as="p" />
              <Translate name="investmentAccountSelection.information.2" as="p" />
              <ActionButtons
                actionButtons={[
                  {
                    name: 'login',
                    variant: 'secondary',
                    content: <Translate name="common.login" />,
                    size: 'large',
                    onClick: () => setPreSelectionMode('login'),
                  },
                  {
                    name: 'create',
                    variant: 'primary',
                    content: <Translate name="investmentAccountSelection.create" />,
                    size: 'large',
                    onClick: () => setPreSelectionMode(undefined),
                  },
                ]}
              />
            </>
          )}
        </>
      );
    }
  }

  if (preSelectionMode === 'login' && !isLoggedIn) {
    // TODO(geforcefan): this is a dirty hack, best approach is to redirect to login page
    //  but we first need redirection information in the url which we don´t have for now
    return (
      <PublicPage>
        <LoginCard
          initial={{
            email,
          }}
          hooks={{
            top: email && (
              <Hint variant="warning">
                <Translate name="investmentAccountSelection.accountExists" />
              </Hint>
            ),
          }}
          onRegisterClick={() => setPreSelectionMode(undefined)}
          redirectToDashboard={isTransferInvitation}
        />
      </PublicPage>
    );
  }

  const { personType, legalPerson, naturalPerson } = extractNaturalAndLegalFromPerson(me?.person);

  return (
    <>
      <WizardHeader />
      {!externalInvitation && !isTransferInvitation && showProcessOverview && (
        <ProcessOverview
          onCloseProcessOverviewOverlay={onCloseProcessOverviewOverlay}
          continueURL={continueProcessURL}
          overviewSteps={overviewSteps}
          token={token}
          pricePerToken={invitation?.pricePerToken}
          minNumberOfTokens={invitation?.minNumberOfTokens}
          maxNumberOfTokens={invitation?.maxNumberOfTokens}
        />
      )}
      {!showProcessOverview && (
        <>
          <Header size="large" spacing="xlarge">
            <Translate name="createAccount.headers.title" />
          </Header>
          <CreateAccount
            createAccountMode={createAccountMode}
            externalInvitation={
              externalInvitation && {
                naturalPerson: {
                  ...externalInvitation.naturalPerson,
                  salutation: externalInvitation.naturalPerson.salutation
                    ? externalInvitation.naturalPerson.salutation
                    : undefined,
                },
              }
            }
            marketingOptsEnabled={config.marketingOptsEnabled}
            shouldValidateEmail={isCampaign || !!externalInvitation}
            onSubmit={onSubmit}
            allowedPersonTypes={allowedPersonTypes}
            getPasswordStrength={getPasswordStrength}
            loading={apiLoading}
            error={apiError}
            documents={{
              effectaAccountSetupDocument: config.effectaAccountSetupDocument,
              effectaTermsConditions: config.effectaTermsConditions,
            }}
            initial={
              externalInvitation
                ? {
                    personType: PersonType.Natural,
                    naturalPerson: {
                      country: externalInvitation.naturalPerson.country,
                      street: externalInvitation.naturalPerson.street,
                      city: externalInvitation.naturalPerson.city,
                      zip: externalInvitation.naturalPerson.zip,
                      phone: externalInvitation.naturalPerson.phone,
                      forename: externalInvitation.naturalPerson.forename,
                      surname: externalInvitation.naturalPerson.surname,
                      salutation: externalInvitation.naturalPerson.salutation,
                      // TODO(mara-cashlink): move iban outside naturalPerson?
                      iban: externalInvitation.iban || undefined,
                    },
                    email: externalInvitation.email || undefined,
                  }
                : {
                    personType,
                    legalPerson: legalPerson as any, // TODO(geforcefan): get rid of any
                    naturalPerson: naturalPerson as any, // TODO(geforcefan): get rid of any
                    email,
                  }
            }
          />
        </>
      )}
    </>
  );
};

export default AccountSetupStep;
