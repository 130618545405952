import {
  AdminApi,
  AdminInvestment,
  BenefitingPerson,
  ComplianceStatus,
  InvestmentCancellationEnum,
  KycStatusEnum,
  LegalPerson,
} from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Button from 'ui/atoms/button';
import Date from 'ui/atoms/date';
import Grid, { Col } from 'ui/atoms/grid';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import StatusTag from 'ui/atoms/status-tag';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import ActionButtons from 'ui/molecules/action-buttons';
import { InfoField } from 'ui/molecules/info-grid';
import InvestmentCancellationModal, {
  CancellationModalType,
} from 'src/apps/issuer/shared/investment-cancellation-modal';
import * as Styled from './styled';
import SuccessMessageModal from 'apps/issuer/pages/investor-details/edit-modals/success-message-modal';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import AddManualReview from './add-manual-review';
import { PersonType } from 'ui/types/person';
import Accordion from 'src/ui/atoms/accordion';

export enum KycAndComplianceType {
  INVESTOR = 'investor',
  INVESTMENT = 'investment',
}

const variantForKycStatus = {
  warning: [KycStatusEnum.PENDING, KycStatusEnum.STARTED, KycStatusEnum.WAITING_FOR_BACKOFFICE],
  danger: [KycStatusEnum.REJECTED],
  success: [KycStatusEnum.SUCCESS],
};

const variantForComplianceStatus = {
  warning: [
    ComplianceStatus.TBD,
    ComplianceStatus.UNKNOWN,
    ComplianceStatus.RESUBMISSION,
    ComplianceStatus.RESUBMISSIONINAPPROPIATENESS,
    ComplianceStatus.RESUBMISSIONMISSINGDOCUMENTS,
  ],
  danger: [ComplianceStatus.FAILURE],
  success: [
    ComplianceStatus.APPROVAL,
    ComplianceStatus.APPROVALCASEOFINASSESSABILITY,
    ComplianceStatus.APPROVALWITHINAPPROPIATENESSDECLARATION,
  ],
};

export interface KycAndComplianceProps {
  type: KycAndComplianceType;
  kycStatus: string | null;
  hasCompliance: boolean;
  comment: string | undefined;
  complianceStatus: string | undefined;
  complianceDate: Date | null | undefined;
  hasManageDataPermission: boolean;
  canCancelReasons?: InvestmentCancellationEnum[];
  investment?: AdminInvestment;
  investmentId?: string;
  investorId: string;
  personData?: LegalPerson;
  loadData?: () => void;
  benefitingPersons?: BenefitingPerson[];
  cancellationDate?: Date | null;
  complianceExportRequired?: boolean;
}

const KycAndCompliance: FunctionComponent<KycAndComplianceProps> = (props) => {
  const {
    type,
    kycStatus,
    hasCompliance,
    complianceExportRequired,
    comment,
    complianceStatus,
    complianceDate,
    hasManageDataPermission,
    canCancelReasons,
    investmentId,
    investorId,
    personData,
    benefitingPersons,
    loadData,
    cancellationDate,
  } = props;

  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [successRequestIdentificationModalOpen, setSuccessRequestIdentificationModalOpen] = useState(false);
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);
  const [errorCancellation, setErrorCancellation] = useState(false);
  const [successCancellation, setSuccessCancellation] = useState(false);

  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const translate = useTranslateWithStringArgs();

  const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onCancelInvestment = useCallback(() => {
    if (!canCancelReasons?.length) return;
    const cancelReason = InvestmentCancellationEnum.KYC_INSUFFICIENT;

    withApi(async () => {
      try {
        await investmentsApi.adminInvestmentsCancellationCreate({
          id: investmentId!,
          investmentCancellationRequest: {
            reason: cancelReason,
          },
        });
        setSuccessCancellation(true);
        if (loadData) loadData();
      } catch (e) {
        setErrorCancellation(true);
        console.error(e);
      }
    });
  }, [investmentId, JSON.stringify(canCancelReasons)]);

  const canCancelInvestment: boolean = useMemo(
    () =>
      !!canCancelReasons?.length &&
      canCancelReasons?.includes(InvestmentCancellationEnum.KYC_INSUFFICIENT) &&
      hasManageDataPermission,
    [JSON.stringify(canCancelReasons), hasManageDataPermission],
  );

  const requestIdentification = useCallback(() => {
    withApi(async () => {
      await investmentsApi.adminInvestmentsRequestIdentificationCreate({ id: investmentId! });
      setSuccessRequestIdentificationModalOpen(true);
    });
  }, [investmentId]);

  const getVariantForStatus = useCallback(
    (
      variantConfig: typeof variantForKycStatus | typeof variantForComplianceStatus,
      status: string | null | undefined,
    ) => {
      if (!status) return undefined;

      for (const [variant, statusOptions] of Object.entries(variantConfig)) {
        if (statusOptions.includes(status)) {
          return variant as ColorVariants;
        }
      }
    },
    [],
  );

  const wasExportedToEffecta: boolean =
    kycStatus === (KycStatusEnum.SUCCESS || kycStatus === KycStatusEnum.REJECTED) && hasCompliance;

  return (
    <>
      <Grid>
        <Col>
          <InfoField title={<Translate name="kycComplianceDetails.kycStatus" />}>
            {kycStatus && (
              <StatusTag variant={getVariantForStatus(variantForKycStatus, kycStatus)} truncate={true}>
                <Translate name={`kycStatus.${kycStatus}`} />
              </StatusTag>
            )}
          </InfoField>
        </Col>
        <Col>
          <InfoField
            title={
              <Translate
                name={
                  type === KycAndComplianceType.INVESTMENT
                    ? 'kycComplianceDetails.investmentCompliance'
                    : 'kycComplianceDetails.investorCompliance'
                }
              />
            }
          >
            {type === KycAndComplianceType.INVESTMENT && complianceExportRequired === false ? (
              <StatusTag variant={'warning'} truncate={true}>
                <Translate name={'complianceStatus.notrequired'} />
              </StatusTag>
            ) : wasExportedToEffecta && complianceStatus ? (
              <StatusTag variant={getVariantForStatus(variantForComplianceStatus, complianceStatus)} truncate={true}>
                <Translate name={`complianceStatus.${complianceStatus!.toLowerCase()}`} />
              </StatusTag>
            ) : (
              <StatusTag variant="info" truncate={true}>
                {complianceStatus ? (
                  <Translate name="complianceStatus.tbd" />
                ) : (
                  <Translate name="complianceStatus.nostatus" />
                )}
              </StatusTag>
            )}
          </InfoField>
        </Col>
        <Col>
          <InfoField title={<Translate name="kycComplianceDetails.complianceDate" />}>
            <PlaceholderFallback>
              {complianceDate && wasExportedToEffecta && <Date>{complianceDate}</Date>}
            </PlaceholderFallback>
          </InfoField>
        </Col>
      </Grid>
      {wasExportedToEffecta && comment && (
        <Accordion
          label={translate('kycComplianceDetails.viewComplianceInfo')}
          expanded={isAccordionExpanded}
          onChange={() => setAccordionExpanded(!isAccordionExpanded)}
        >
          <Styled.MultilineText>{comment}</Styled.MultilineText>
        </Accordion>
      )}
      {kycStatus === KycStatusEnum.WAITING_FOR_BACKOFFICE &&
        personData?.personType === PersonType.Legal &&
        hasManageDataPermission && (
          <AddManualReview
            investorId={investorId}
            personData={personData}
            benefitingPersons={benefitingPersons}
            fetchInvestorDetails={loadData}
          />
        )}
      {type === KycAndComplianceType.INVESTMENT &&
        hasManageDataPermission &&
        complianceStatus !== ComplianceStatus.APPROVAL && (
          <ActionButtons>
            {canCancelInvestment && (
              <Button variant="link" onClick={() => setCancellationModalOpen(true)}>
                <Translate name={'kycComplianceDetails.cancelInvestmentButton'} />
              </Button>
            )}
            {wasExportedToEffecta && cancellationDate === null && (
              <Button variant="primary" onClick={() => requestIdentification()}>
                <Translate name="kycComplianceDetails.addNewIdentification" />
              </Button>
            )}
          </ActionButtons>
        )}
      {cancellationModalOpen && (
        <InvestmentCancellationModal
          onCancelInvestment={onCancelInvestment}
          loading={loading}
          onClose={() => setCancellationModalOpen(false)}
          reason={<Translate name={'issuerInvestmentCancellationComplianceModal.failedIdentification'} />}
          type={CancellationModalType.COMPLIANCE_CANCELLATION}
          successCancellation={successCancellation}
          errorCancellation={errorCancellation}
        />
      )}
      {successRequestIdentificationModalOpen && (
        <SuccessMessageModal
          title={<Translate name="kycComplianceDetails.requestIdentificationModal.title" />}
          onHideSuccessEdit={() => setSuccessRequestIdentificationModalOpen(false)}
          description={<Translate name="kycComplianceDetails.requestIdentificationModal.description" />}
        >
          <Translate name="kycComplianceDetails.requestIdentificationModal.hint" />
        </SuccessMessageModal>
      )}
    </>
  );
};

export default KycAndCompliance;
