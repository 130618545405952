import React, { FunctionComponent, useCallback } from 'react';
import FormGroup from 'ui/molecules/form-group';
import { useFilterOption } from 'ui/molecules/filtering/hooks';
import type { FilterOptionSelectionProps } from 'ui/molecules/filtering/types';
import { StyledMultiSelect } from './styled';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

interface MultiSelectItem {
  value: string;
  label: string;
  name: string;
}

export const FilterMultiSelect: FunctionComponent<FilterOptionSelectionProps> = (props) => {
  const { value: selection, setValue: setSelection } = useFilterOption(props, []);

  const translate = useTranslateWithStringArgs();

  const onSelect = useCallback(
    (selectedList: MultiSelectItem[]) => {
      setSelection(selectedList.map((item) => item.value));
    },
    [selection],
  );

  const selectedValues = props.options.filter(({ value }) =>
    Array.isArray(selection) ? selection.includes(value) : selection === value,
  );

  return (
    <FormGroup label={props.label}>
      <StyledMultiSelect
        options={props.options}
        displayValue="label"
        selectedValues={selectedValues}
        placeholder={translate('filters.multiselect.placeholder')}
        showArrow={true}
        onSelect={(selectedList: MultiSelectItem[]) => onSelect(selectedList)}
        onRemove={(selectedList: MultiSelectItem[]) => onSelect(selectedList)}
      />
    </FormGroup>
  );
};

export default FilterMultiSelect;
