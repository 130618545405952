import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'ui/helper/prefixed-class-names';

import Link from 'ui/atoms/link';

export interface MarkdownProps {
  /** Markdown source. */
  source: string;

  /** Additional classes. */
  className?: string;

  /** Shift header count by the given number. E.g. if value is 1, h2-h5 will be
   * used instead of h1-h6. */
  headerShift?: number;
}

const linkRenderer = (props: any) => {
  return <Link external={/^http/.test(props.href)} {...props} inline />;
};

const tableRenderer = (props: any) => <table className="table" {...props} />;

const blockquoteRender = (props: any) => <blockquote className="blockquote" {...props} />;

export const Markdown: FunctionComponent<MarkdownProps> = (props) => {
  const { className, source, headerShift = 0 } = props;

  const renderers = {
    link: linkRenderer,
    linkReference: linkRenderer,
    blockquote: blockquoteRender,
    table: tableRenderer,
    heading: (props: any) => {
      const { level } = props;
      return React.createElement(`h${level + headerShift}`, props, props.children);
    },
  };

  return <ReactMarkdown className={cx('markdown', className)} source={source} renderers={renderers} />;
};

export default Markdown;
