import { investorAppRootStateSelector } from 'apps/investor/selectors';
import type { InvestorAppState } from 'store/types';

export function walletsRootSelector(state: InvestorAppState) {
  return investorAppRootStateSelector(state).wallets;
}

export function walletsSelector(state: InvestorAppState) {
  const { data, loading, error } = walletsRootSelector(state);

  return { wallets: data, loading, error };
}
