import { Cmd, loop, Loop, LoopReducer, reduceReducers, combineReducers } from 'redux-loop';
import { InvestorAppActions } from './actions';
import type { InvestorAppActionTypes, LoadInvestorApp } from './actions';
import { walletsFetch } from 'apps/investor/resources/wallets/actions';
import { investorFetch } from 'apps/investor/resources/investor/actions';
import {
  investorHasEcspInvestmentsFetch,
  investorHasInvestmentsFetch,
} from 'apps/investor/resources/investments/actions';
import walletsReducer, { WalletsStateShape } from './resources/wallets/reducer';
import investorReducer, { InvestorStateShape } from './resources/investor/reducer';
import investmentsReducer, { InvestmentsStateShape } from './resources/investments/reducer';

type InvestorAppLoop<A> = (state: undefined, action: A) => undefined | Loop<undefined>;

export interface InvestorAppShape {
  wallets: WalletsStateShape;
  investor: InvestorStateShape;
  investments: InvestmentsStateShape;
}

const handleLoadInvestorApp: InvestorAppLoop<LoadInvestorApp> = (state) => {
  return loop(
    state,
    Cmd.list([
      Cmd.action(walletsFetch()),
      Cmd.action(investorFetch()),
      Cmd.action(investorHasInvestmentsFetch()),
      Cmd.action(investorHasEcspInvestmentsFetch()),
    ]),
  );
};

const reducer: LoopReducer<undefined, InvestorAppActionTypes> = (state, action): undefined | Loop<undefined> => {
  switch (action.type) {
    case InvestorAppActions.LOAD_INVESTOR_APP:
      return handleLoadInvestorApp(state, action);
    default:
      return state;
  }
};

export default reduceReducers(
  reducer,
  combineReducers({
    investor: investorReducer,
    wallets: walletsReducer,
    investments: investmentsReducer,
  }),
);
