import React, { Context, createContext, FunctionComponent, useEffect, useState } from 'react';
import { Platforms, Platform } from 'platforms';
import applicationConfig from 'core/config/local';

interface PlatformContextProps {
  initialPlatform: Platforms;
  activePlatform: Platforms;
  changePlatform: (platform: Platforms) => void;
}

const initial: PlatformContextProps = {
  initialPlatform: Platform.CASHLINK,
  activePlatform: Platform.CASHLINK,
  changePlatform: (platform: Platforms) => {},
};

export const PlatformContext: Context<PlatformContextProps> = createContext(initial);

const getLocalPlatform = () => {
  try {
    return localStorage.getItem('platform') as Platforms;
  } catch (e) {
    return undefined;
  }
};

const setLocalPlatform = (value: Platforms) => {
  try {
    localStorage.setItem('platform', value);
  } catch (e) {
    return;
  }
};

export const PlatformProvider: FunctionComponent<{
  initialPlatform: Platforms;
  overwritePlatform?: Platforms;
}> = ({ children, initialPlatform, overwritePlatform }) => {
  const [selectedPlatform, setSelectedPlatform] = useState<Platforms | undefined>(
    applicationConfig.enablePlatformSwitch ? getLocalPlatform() : undefined,
  );
  const [activePlatform, setActivePlatform] = useState<Platforms>(
    overwritePlatform || selectedPlatform || initialPlatform,
  );

  const changePlatform = (platform: Platforms): void => {
    if (!applicationConfig.enablePlatformSwitch) {
      console.error('Tried to switch platforms in production, this is not allowed');
      return;
    }

    setSelectedPlatform(platform);
    setLocalPlatform(platform);
  };

  useEffect(() => {
    if (!applicationConfig.enablePlatformSwitch) {
      console.error('Tried to switch platforms in production, this is not allowed');
      return;
    }

    setActivePlatform(overwritePlatform || selectedPlatform || initialPlatform);
  }, [overwritePlatform, selectedPlatform, initialPlatform]);

  if (!activePlatform) return null;

  return (
    <PlatformContext.Provider
      value={{
        initialPlatform,
        activePlatform,
        changePlatform,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};

export default {
  PlatformProvider,
  PlatformContext,
};
