import React, { FunctionComponent } from 'react';
import Icon from 'src/ui/atoms/icon';
import { useFilterContext } from '../hooks';

export const CancelFilter: FunctionComponent<{
  inputNames: string[];
  filterName: string;
  className?: string;
}> = ({ inputNames, className }) => {
  const { resetFilter } = useFilterContext();

  return (
    <Icon name="close" size="large" onClick={() => resetFilter(inputNames)} color="grayMain" className={className} />
  );
};

export default CancelFilter;
