import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { Money } from 'api';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import FormGroup from 'ui/molecules/form-group';
import PaymentTotalListing from 'libraries/payment-total-listing';

export interface SummaryCardProps {
  /** Amount of securities */
  amount: number;

  /** tokenPrice per security */
  tokenPrice: Money | null;

  /** Investment amount */
  investmentTotal: Money | null;

  /** Payment amount */
  paymentTotal: Money | null;

  /** Disagio amount */
  disagio: Money | null;

  /** Agio amount */
  agio: Money | null;

  /** Accrued interest */
  accruedInterest: Money;

  /** payment information */
  paymentInformation?: {
    /** Account holder */
    accountHolder: string;

    /** Iban */
    iban: string;

    /** BIC */
    bic: string;

    /** Reference text */
    referenceText: string;
  };

  isStepDone?: boolean;

  /** Additional classes. */
  className?: string;

  useUnits: boolean;

  showAccruedInterest: boolean;

  /** As long as investment is not accepted, accrued interest might still change. */
  isAccruedInterestFixed: boolean;
}

const SummaryCard: FunctionComponent<SummaryCardProps> = (props) => {
  const { paymentInformation, className, isStepDone, ...paymentTotalListingProps } = props;

  return (
    <div className={cx('investment-summary-card', className)}>
      <PaymentTotalListing {...paymentTotalListingProps} showUnitsInfoIfConfigured={true} />
      {!isStepDone &&
        (paymentInformation ? (
          <>
            <p>
              <Translate name="investmentSummary.offerAcceptedHint" />
            </p>
            <FormGroup label={<Translate name="investmentSummary.accountHolder" />}>
              <CopyInputGroup valueToCopy={paymentInformation.accountHolder} disabled />
            </FormGroup>
            <FormGroup label={<Translate name="investmentSummary.iban" />}>
              <CopyInputGroup valueToCopy={paymentInformation.iban} disabled />
            </FormGroup>
            <FormGroup label={<Translate name="investmentSummary.bic" />}>
              <CopyInputGroup valueToCopy={paymentInformation.bic} disabled />
            </FormGroup>
            <FormGroup label={<Translate name="investmentSummary.referenceText" />}>
              <CopyInputGroup valueToCopy={paymentInformation.referenceText} disabled />
            </FormGroup>
            <Hint variant="warning">
              <Translate name="investmentSummary.continueHint" />
            </Hint>
          </>
        ) : (
          <Hint variant="warning">
            <Translate name="investmentSummary.offerNotAcceptedHint" />
          </Hint>
        ))}
      {isStepDone && (
        <Hint variant="success">
          <Translate name="investmentSummary.paymentConfirmed" />
        </Hint>
      )}
    </div>
  );
};

export default SummaryCard;
