import styled from 'styled-components';
import { Col } from 'ui/atoms/grid';

export const StyledCol = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  > * {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
