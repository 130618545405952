import React from 'react';
import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import BoxedContent from 'ui/molecules/boxed-content';

import { AppType } from 'core/auth/types';

import { useTransfer } from 'hooks/transfer';

import UITransferCard from 'ui/molecules/transfer-card';
import UITransferDetailsCard from './transfer-details-card';
import { transferToUI } from 'core/api/transfer';
import { useWallets } from 'hooks/use-wallets';

const InvestorTransactionDetailsPage = (props: { id: string }) => {
  const { id } = props;
  const { transfer, loading: transferLoading } = useTransfer(id);
  const { wallets, loading: walletsLoading } = useWallets();

  if (transferLoading || walletsLoading || !transfer || !wallets) {
    return <LoadingRing />;
  }

  const uiTransfer = transferToUI(transfer, wallets);

  // TODO(niklasb) handle error

  return (
    <WideContent title={<Translate name="dashboardInvestorTransactionDetails.title" />}>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <UITransferCard transfer={uiTransfer} />
      </BoxedContent>

      <BoxedContent title={<Translate name="dashboardInvestorTransactionDetails.subtitle" />}>
        <UITransferDetailsCard transfer={uiTransfer} />
      </BoxedContent>
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorTransactionDetailsPage, AppType.INVESTOR);
