import React, { FunctionComponent, useContext } from 'react';
import WizardContext from 'libraries/wizard/wizard-context';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Spacer from 'ui/atoms/spacer';
import * as Styled from './styled';
import Button from 'ui/atoms/button';
import Header from 'ui/atoms/header';
import Image from 'ui/atoms/image';
import Text from 'ui/polymorphs/text';
import { camelCase } from 'lodash';
import { InvestmentTokenForInvestor, Money } from 'src/api';
import { convertProductTypeFromApi } from 'src/core/api/conversions';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import Grid, { Col } from 'ui/atoms/grid';
import Currency from 'ui/atoms/currency';
import { toMoney, toNumber } from 'ui/helper/money';

export interface ProcessOverviewProps {
  className?: string;
  onCloseProcessOverviewOverlay: any;
  continueURL: string;
  overviewSteps: string[];
  pricePerToken?: Money;
  minNumberOfTokens?: number;
  maxNumberOfTokens?: number;
  token?: InvestmentTokenForInvestor;
}

const ProcessOverview: FunctionComponent<ProcessOverviewProps> = (props) => {
  const {
    className,
    onCloseProcessOverviewOverlay,
    overviewSteps,
    minNumberOfTokens,
    maxNumberOfTokens,
    pricePerToken,
    token,
  } = props;

  const { isRegisterOnlyFlow } = useContext(WizardContext);

  const isPhoneTablet = useIsMedia([DEVICES.phone, DEVICES.tablet]);
  const isPhone = useIsMedia(DEVICES.phone);

  return (
    <Styled.ProcessOverviewContainer className={cx('investment-process-overview', className)}>
      {!isPhone && <Spacer y={10} />}
      {!isPhone && (
        <>
          <Header size="large" textAlign="center">
            <Translate name={isRegisterOnlyFlow ? 'register.overview.title' : 'investmentProcessOverview.title'} />
          </Header>
          <Spacer y={4} />
        </>
      )}
      {token && (
        <Styled.InvestmentOverview spacing="xlarge">
          {token.productImage && (
            <Col width={4}>
              <Image
                src={token.productImage.file}
                alt={`${token.name} image`}
                size={isPhoneTablet ? 'medium' : 'large'}
                bordered
              />
            </Col>
          )}
          <Col width={token.productImage ? 8 : 12}>
            <Header size="xsmall" spacing="none" breakWords={true}>
              <Text wordBreak="break-word">{token.name}</Text>
            </Header>
            <Section spacing="medium">
              <Translate name={`productType.${camelCase(convertProductTypeFromApi(token.productType))}`} />
            </Section>
            <div>
              {pricePerToken && (
                <Grid>
                  <Col>
                    <Styled.InvestmentDescription fontSize={isPhoneTablet ? 12 : 14}>
                      <Translate name="investmentInvitation.pricePerToken" />
                    </Styled.InvestmentDescription>
                  </Col>
                  <Col>
                    <Styled.AmountContainer>
                      <strong>
                        <Text fontSize={isPhoneTablet ? 12 : 14} align="right">
                          <Currency decimals="*">{pricePerToken}</Currency>
                        </Text>
                      </strong>
                    </Styled.AmountContainer>
                  </Col>
                </Grid>
              )}
              {minNumberOfTokens && pricePerToken && (
                <Grid>
                  <Col>
                    <Styled.InvestmentDescription fontSize={isPhoneTablet ? 12 : 14}>
                      <Translate name="investmentInvitation.minAmount" />
                    </Styled.InvestmentDescription>
                  </Col>
                  <Col>
                    <Styled.AmountContainer>
                      <Text fontSize={isPhoneTablet ? 12 : 14} align="right">
                        <strong>
                          <Currency decimals="*">{toMoney(minNumberOfTokens * toNumber(pricePerToken))}</Currency>
                        </strong>{' '}
                        {minNumberOfTokens} <Translate name="studioConfirmInvestmentsTable.creditSecurities.units" />
                      </Text>
                    </Styled.AmountContainer>
                  </Col>
                </Grid>
              )}
              <Spacer y={1} />
              {maxNumberOfTokens && pricePerToken && (
                <Grid>
                  <Col>
                    <Styled.InvestmentDescription fontSize={isPhoneTablet ? 12 : 14}>
                      <Translate name="investmentInvitation.maxAmount" />
                    </Styled.InvestmentDescription>
                  </Col>
                  <Col>
                    <Styled.AmountContainer>
                      <Text fontSize={isPhoneTablet ? 12 : 14} align="right">
                        <strong>
                          <Currency decimals="*">{toMoney(maxNumberOfTokens * toNumber(pricePerToken))}</Currency>
                        </strong>{' '}
                        {maxNumberOfTokens} <Translate name="studioConfirmInvestmentsTable.creditSecurities.units" />
                      </Text>
                    </Styled.AmountContainer>
                  </Col>
                </Grid>
              )}
            </div>
          </Col>
        </Styled.InvestmentOverview>
      )}
      {isPhone && (
        <Header size="large" textAlign="center">
          <Translate name={isRegisterOnlyFlow ? 'register.overview.title' : 'investmentProcessOverview.title'} />
        </Header>
      )}
      <Section spacing={isPhone ? 'small' : 'medium'}>
        <Styled.ButtonsContainer align="center">
          <Button variant="primary" color="marker" size="large" onClick={onCloseProcessOverviewOverlay}>
            <Translate
              name={
                isRegisterOnlyFlow
                  ? 'register.overview.startRegisterProcess'
                  : 'investmentProcessOverview.startInvestmentProcess'
              }
            />
          </Button>
        </Styled.ButtonsContainer>
      </Section>
      <Spacer y={2} />
      <Styled.InvestmentSteps>
        <Styled.InvestmentHeader size="small" textAlign="center">
          <Translate name="investmentProcessOverview.stepsTitle" />
        </Styled.InvestmentHeader>
        <div>
          {overviewSteps.map((step, index) => (
            <div key={step}>
              <strong>{index + 1}</strong>
              <Spacer x={4} inline />
              <strong>
                <Translate name={`investmentProcessOverview.${step}.title`} />:{' '}
              </strong>
              <Translate name={`investmentProcessOverview.${step}.description`} />
              <Spacer y={2} />
            </div>
          ))}
        </div>
      </Styled.InvestmentSteps>
      <Text fontSize={14} align="center" as="p">
        <Translate name="investmentProcessOverview.note" />
      </Text>
    </Styled.ProcessOverviewContainer>
  );
};

export default ProcessOverview;
