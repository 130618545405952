import { query } from 'ui/styles/queries';
import styled from 'styled-components';
import Section from 'ui/atoms/section';

export const DataNavigationContainer = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Controls = styled.div`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;

  & > * {
    margin-left: 12px;
  }

  ${query.phone`
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 8px;

    & > * {
      margin-left: 0;
    }

    & > * + * {
      margin-left: 8px;
    }
  `}
`;

export const Entries = styled.div`
  align-items: flex-end;

  ${query.phone`
    display: none;
    align-items: center;
  `};
`;

export const AddNewEntry = styled.div`
  display: inline-block;
  margin-left: 1rem;
`;
