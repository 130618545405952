import React, { FunctionComponent, ReactNode, useContext } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Header from 'ui/atoms/header';
import Size from 'ui/types/size';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';
import type { Icons } from 'ui/assets/icons/types';
import type { HorizontalAlign, VerticalAlign } from 'ui/types/align';
import * as Styled from './styled';
import { TableContext, TableProvider } from './provider';

export interface TableRowProps {
  className?: string;
  onClick?: () => void;
}

export interface TableSubComponents {
  Row: FunctionComponent<TableRowProps>;
  Head: FunctionComponent<TableHeadProps>;
  Body: FunctionComponent<TableBodyProps>;
  Cell: FunctionComponent<TableCellProps>;
  HeadCell: FunctionComponent<TableHeadCellProps>;
  Foot: FunctionComponent<TableFootProps>;
}

export const TableRow: FunctionComponent<TableRowProps> = (props) => {
  const { className, children, onClick, ...restProps } = props;

  const { borderless } = useContext(TableContext);

  return (
    <Styled.TableRow
      className={cx('table-row', className)}
      onClick={onClick}
      $borderless={borderless}
      $clickable={!!onClick}
      {...restProps}
    >
      {children}
    </Styled.TableRow>
  );
};

export interface TableHeadProps {
  className?: string;
}

export const TableHead: FunctionComponent<TableHeadProps> = (props) => {
  const { className, children, ...restProps } = props;

  return (
    <Styled.TableHead className={cx('table-head', className)} {...restProps}>
      {children}
    </Styled.TableHead>
  );
};

export interface TableBodyProps {
  className?: string;
}

export const TableBody: FunctionComponent<TableBodyProps> = (props) => {
  const { className, children, ...restProps } = props;

  return (
    <tbody className={cx('table-body', className)} {...restProps}>
      {children}
    </tbody>
  );
};

export interface TableCellProps {
  className?: string;
  alignContent?: HorizontalAlign;
  verticalAlignContent?: VerticalAlign;
  colSpan?: number;
}

export const TableCell: FunctionComponent<TableCellProps> = (props) => {
  const { className, children, alignContent, verticalAlignContent, colSpan, ...restProps } = props;

  const { compact } = useContext(TableContext);

  return (
    <Styled.TableCell
      className={cx('table-cell', className)}
      colSpan={colSpan}
      $alignContent={alignContent}
      $verticalAlignContent={verticalAlignContent}
      $compact={compact}
      {...restProps}
    >
      {children}
    </Styled.TableCell>
  );
};

export interface TableHeadCellProps {
  className?: string;
  alignContent?: HorizontalAlign;
  orderingDirection?: 'asc' | 'desc';
  onOrderBy?: () => void;
}

export const TableHeadCell: FunctionComponent<TableHeadCellProps> = (props) => {
  const { className, children, alignContent, orderingDirection, onOrderBy, ...restProps } = props;

  const canOrderCell = !!onOrderBy;

  return (
    <Styled.TableHeadCell
      className={cx('table-head-cell', className)}
      onClick={onOrderBy}
      $alignContent={alignContent}
      $pointer={canOrderCell}
      {...restProps}
    >
      <Styled.TableHeadCellContent className="head-cell-content" $alignContent={alignContent}>
        <div>
          <div>{children}</div>
        </div>
        {canOrderCell && (
          <>
            <Spacer x={1} inline />
            <Icon
              name={(orderingDirection ? { asc: 'sort-up', desc: 'sort-down' }[orderingDirection] : 'sort') as Icons}
              color={!orderingDirection ? 'grayMain' : 'primary'}
            />
          </>
        )}
      </Styled.TableHeadCellContent>
    </Styled.TableHeadCell>
  );
};

export interface TableFootProps {
  className?: string;
}

export const TableFoot: FunctionComponent<TableFootProps> = (props) => {
  const { className, children, ...restProps } = props;

  const { borderless } = useContext(TableContext);

  return (
    <Styled.TableFoot className={cx('table-foot', className)} $borderless={borderless} {...restProps}>
      {children}
    </Styled.TableFoot>
  );
};

export interface TableProps {
  className?: string;
  title?: ReactNode;
  borderless?: boolean;
  compact?: boolean;
  size?: Size;
}

const Table: FunctionComponent<TableProps> & TableSubComponents = (props) => {
  const { className, children, title, borderless = false, compact = false, size, ...restProps } = props;

  return (
    <>
      {title && (
        <Header size="small" spacing="medium">
          {title}
        </Header>
      )}
      <TableProvider borderless={borderless} compact={compact}>
        <Styled.Table
          className={cx('table-molecule', className)}
          $borderless={borderless}
          $compact={compact}
          $size={size}
          {...restProps}
        >
          {children}
        </Styled.Table>
      </TableProvider>
    </>
  );
};

Table.Body = TableBody;
Table.Row = TableRow;
Table.Head = TableHead;
Table.Cell = TableCell;
Table.HeadCell = TableHeadCell;
Table.Foot = TableFoot;

export default Table;
