import { useCallback } from 'react';
import { AdminBundesbankSettlementInstructionStatusEnum } from 'src/api';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';

export const useTriggerSolutionStatus = () => {
  const translate = useTranslateWithStringArgs();

  const getVariant = useCallback((status: AdminBundesbankSettlementInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankSettlementInstructionStatusEnum.ASSET_LOCKED:
      case AdminBundesbankSettlementInstructionStatusEnum.PAYMENT_INSTRUCTION_PREPARED:
        return 'warning';
      case AdminBundesbankSettlementInstructionStatusEnum.CREATED:
      case AdminBundesbankSettlementInstructionStatusEnum.SUCCESS:
        return 'success';
      case AdminBundesbankSettlementInstructionStatusEnum.FAILED:
        return 'danger';
    }
  }, []);

  const getStatusName = useCallback((status: AdminBundesbankSettlementInstructionStatusEnum) => {
    switch (status) {
      case AdminBundesbankSettlementInstructionStatusEnum.ASSET_LOCKED:
        return translate('studioSettlementsTable.assetLocked');
      case AdminBundesbankSettlementInstructionStatusEnum.CREATED:
        return translate('studioSettlementsTable.created');
      case AdminBundesbankSettlementInstructionStatusEnum.FAILED:
        return translate('studioSettlementsTable.failed');
      case AdminBundesbankSettlementInstructionStatusEnum.PAYMENT_INSTRUCTION_PREPARED:
        return translate('studioSettlementsTable.paymentInstructionPrepared');
      case AdminBundesbankSettlementInstructionStatusEnum.SUCCESS:
        return translate('studioSettlementsTable.success');
    }
  }, []);

  return {
    getStatusName,
    getVariant,
  };
};
