import Logo from 'ui/atoms/logo';
import styled from 'styled-components';
import RouterLink from 'core/routing/router-link';

export const NavBarDesktop = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlatformLogo = styled(Logo)`
  margin-right: 8px;
`;

export const ActionMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginRouterLink = styled(RouterLink)`
  min-width: fit-content;
  width: max-content;
`;
