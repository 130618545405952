import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Button from 'ui/atoms/button';
import Segment from 'ui/atoms/segment';
import Translate from 'ui/atoms/translate';
import ServerError from 'ui/types/server-error';
import Section from 'ui/atoms/section';
import makeForm from 'ui/forms';
import Grid, { Col } from 'ui/atoms/grid';
import Icon from 'ui/atoms/icon';
import * as Styled from './styled';
import ActionButtons from 'src/ui/molecules/action-buttons';

// TODO(geforcefan): add dummy validation to forms and get rid of this workaround
export interface BenefitingPersonsOverviewFields {
  acceptPep: boolean;
  dummyPersonsValid: string;
}

export interface RegisterInvestorBenefitingPersonsOverviewProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: () => void;

  /** On edit person callback */
  onSetPersonClicked?: (index: number) => void;

  /** On delete person callback */
  onDeletePersonClicked?: (index: number) => void;

  /** Indicates a loading state */
  loading?: boolean;

  /** Error message */
  error?: ServerError;

  /**
   * Persons list
   *
   * @default []
   * */
  persons?: {
    action?: 'delete' | 'edit' | 'add';
    forename: string;
    surname: string;
    //[propType: string]: string | undefined;
  }[];
}

const RegisterInvestorBenefitingPersonsOverviewForm = makeForm<BenefitingPersonsOverviewFields>();

const RegisterInvestorBenefitingPersonsOverview: FunctionComponent<RegisterInvestorBenefitingPersonsOverviewProps> = (
  props,
) => {
  const {
    className,
    loading,
    error,
    onSubmit = () => {},
    onSetPersonClicked = () => {},
    onDeletePersonClicked = () => {},
    persons = [],
    ...restProps
  } = props;

  return (
    <div className={cx('register-investor-benefiting-persons-overview', className)} {...restProps}>
      <Section>
        <p>
          <strong>
            <Translate name="benefitingPersons.overview.title" />
          </strong>
        </p>
        <p>
          <Translate name="benefitingPersons.overview.description" />
        </p>
        <p>
          <Translate name="benefitingPersons.overview.addPersonDescription" />
        </p>

        <Segment basic={true} padded="none">
          {!!persons.length && (
            <>
              <p>
                <strong>
                  <Translate name="benefitingPersons.overview.addPersonTitle" />
                </strong>
              </p>

              {persons.map(({ action, forename, surname }, index) =>
                action !== 'delete' ? (
                  <Grid key={index}>
                    <Col>{`${forename} ${surname}`.trim()}</Col>
                    <Col>
                      <Styled.IconContainer>
                        <Icon name="edit" color="primary" onClick={() => onSetPersonClicked(index)} />
                        <Translate name="common.edit">Bearbeiten</Translate>
                      </Styled.IconContainer>
                    </Col>
                    <Col>
                      <Styled.IconContainer>
                        <Icon name="trash" color="primary" onClick={() => onDeletePersonClicked(index)} />
                        <Translate name="common.delete" />
                      </Styled.IconContainer>
                    </Col>
                  </Grid>
                ) : null,
              )}
            </>
          )}

          <Button variant="link" icon="plus" onClick={() => onSetPersonClicked(-1)}>
            <Translate name="benefitingPersons.addPerson" />
          </Button>
        </Segment>

        <RegisterInvestorBenefitingPersonsOverviewForm
          onSubmit={onSubmit}
          initial={{ dummyPersonsValid: 'dummy' }}
          error={error}
        >
          {/* TODO(geforcefan): get rid of this workaround */}
          <div style={{ display: 'none' }}>
            <RegisterInvestorBenefitingPersonsOverviewForm.Group name="dummyPersonsValid">
              <RegisterInvestorBenefitingPersonsOverviewForm.Input />
              <RegisterInvestorBenefitingPersonsOverviewForm.Validation
                validate={() => !!persons.filter((person) => person.action !== 'delete').length}
              />
            </RegisterInvestorBenefitingPersonsOverviewForm.Group>
          </div>
          <RegisterInvestorBenefitingPersonsOverviewForm.Group name="acceptPep" required={true}>
            <RegisterInvestorBenefitingPersonsOverviewForm.Checkbox>
              <Translate name="benefitingPersons.overview.acceptPep" />
            </RegisterInvestorBenefitingPersonsOverviewForm.Checkbox>
          </RegisterInvestorBenefitingPersonsOverviewForm.Group>

          <ActionButtons>
            <RegisterInvestorBenefitingPersonsOverviewForm.Submit variant="primary" size="large" loading={loading}>
              <Translate name="benefitingPersons.overview.send" />
            </RegisterInvestorBenefitingPersonsOverviewForm.Submit>
          </ActionButtons>
        </RegisterInvestorBenefitingPersonsOverviewForm>
      </Section>
      <Translate
        as="small"
        name="pepDefinition.description"
        args={[<Translate key={0} as="strong" name="pepDefinition.title" />]}
      />
    </div>
  );
};

export default RegisterInvestorBenefitingPersonsOverview;
