import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import Icon from 'ui/atoms/icon';
import Date from 'ui/atoms/date';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import DataRecordTable from 'src/libraries/data-record-table';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { Money } from 'api';
import { HorizontalAlign } from 'ui/types/align';
import { toNumber } from 'ui/helper/money';
import { PaginationProps } from 'ui/molecules/pagination';

interface Investment {
  id: string;
  investmentTotal: Money;
  investmentTotalFulfilled: Money;
  productName: string;
  sumSettledPayouts: Money;
  lastSettledPayout: Money | null;
  nextScheduledPayout: Date | null;
  signedDate: Date;
}

export interface DashboardInvestmentsTableProps {
  /** Primary content. */
  children?: ReactNode;
  /** Additional classes. */
  className?: string;
  title?: ReactNode;
  showSignedDateColumn?: boolean;
  showProductColumn?: boolean;
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  investments?: Investment[];
  goToDetails: (investmentId: string) => void;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
}

const DashboardInvestmentsTable: FunctionComponent<DashboardInvestmentsTableProps> = (props) => {
  const {
    className,
    children,
    title,
    onOrderBy,
    ordering,
    goToDetails,
    investments,
    emptyView,
    loading,
    showSignedDateColumn,
    showProductColumn,
    paginationProps,
    ...restProps
  } = props;

  const hasInvestmentAmountChanged = (investment: Investment): boolean => {
    return toNumber(investment?.investmentTotal) !== toNumber(investment?.investmentTotalFulfilled);
  };

  return (
    <div className={cx('dashboard-investments-table', className)} {...restProps}>
      <DataRecordTable
        title={title}
        onOrderBy={onOrderBy}
        ordering={ordering}
        data={investments}
        emptyView={emptyView}
        loading={loading}
        paginationProps={paginationProps}
        cells={[
          ...(showProductColumn
            ? [
                {
                  title: <Translate name="dashboardInvestorInvestments.investment" />,
                  render: (investment: Investment) => investment.productName,
                },
              ]
            : []),
          ...(showSignedDateColumn
            ? [
                {
                  title: <Translate name="dashboardInvestorInvestments.signedDate" />,
                  orderingFieldName: 'signed_date',
                  render: (investment: Investment) => (
                    <PlaceholderFallback>
                      {investment.signedDate && <Date>{investment.signedDate}</Date>}
                    </PlaceholderFallback>
                  ),
                  alignContent: 'right' as HorizontalAlign,
                },
              ]
            : []),
          {
            title: <Translate name="dashboardInvestorInvestments.investmentAmount" args={[<>&shy;</>]} />,
            orderingFieldName: 'investment_total',
            render: (investment) => (
              <PlaceholderFallback>
                {investment?.investmentTotal && <Currency>{investment?.investmentTotal}</Currency>}
                {investment?.investmentTotalFulfilled && hasInvestmentAmountChanged(investment) && (
                  <>
                    {' ('}
                    <Currency>{investment?.investmentTotalFulfilled}</Currency>
                    {')'}
                  </>
                )}
              </PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardInvestorInvestments.sumSettledPayouts" args={[<>&shy;</>]} />,
            render: (investment) => <Currency>{investment.sumSettledPayouts}</Currency>,
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardInvestorInvestments.lastSettledPayout" />,
            render: (investment) => (
              <PlaceholderFallback>
                {investment.lastSettledPayout && toNumber(investment.lastSettledPayout) > 0 && (
                  <Currency>{investment.lastSettledPayout}</Currency>
                )}
              </PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardInvestorInvestments.nextScheduledPayout" />,
            render: (investment) => (
              <PlaceholderFallback>
                {investment.nextScheduledPayout && <Date>{investment.nextScheduledPayout}</Date>}
              </PlaceholderFallback>
            ),
            alignContent: 'right',
          },

          {
            title: '',
            render: ({ id }) => (
              <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
            ),
            alignContent: 'right',
          },
        ]}
      />
    </div>
  );
};

export default DashboardInvestmentsTable;
