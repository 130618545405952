import styled from 'styled-components';
import Input from 'ui/atoms/input';

export const ShortSummaryInput = styled(Input)`
  justify-content: center;

  input {
    width: 3.8rem;
    text-align: center;
  }
`;
