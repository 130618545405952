import { ThemeColor } from 'ui/styles/types';
export interface StickyTheme {
  paddingHorizontal: number;
  paddingVertical: number;
  arrowSize: number;
  bgColor: ThemeColor;
}

export default {
  paddingHorizontal: 1, // in rem
  paddingVertical: 0.5, // in rem
  arrowSize: 1, // in rem
  bgColor: ({ theme }) => theme.colors.grayLight,
} as StickyTheme;
