import React, { FunctionComponent, MouseEventHandler } from 'react';
import Action, { ActionProps } from 'ui/polymorphs/action';
import { PolymorphicChildComponentProps } from 'ui/helper/get-element-type';

export interface ButtonProps extends ActionProps {
  /**
   * Button HTML type
   */
  type?: 'button' | 'reset' | 'submit';

  /**
   * Disabled
   */
  disabled?: boolean;

  buttonRef?: any;

  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button: FunctionComponent<PolymorphicChildComponentProps<'button', ButtonProps>> = ({
  type = 'button',
  disabled = false,
  buttonRef,
  ...props
}) => {
  return <Action as="button" disabled={disabled} type={type} ref={buttonRef} {...props} />;
};

Button.displayName = 'Button';

export default Button;
