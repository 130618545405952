import { ThemeColor } from 'ui/styles/types';

export interface SidebarTheme {
  width: number; // px
  small: number; // px
  color: ThemeColor;
}

export default {
  width: 230,
  small: 160,
  color: ({ theme }: any) => theme.colorVariants.background,
} as SidebarTheme;
