import styled from 'styled-components';

export interface StyledNavbarProps {
  $progressBar?: boolean;
}

export const Navbar = styled.div<StyledNavbarProps>`
  background-color: white;
  position: relative;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.navigation.navBarBorderColor};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.navigation.navBarHeight};
  ${({ $progressBar }) => $progressBar && 'flex-wrap: wrap'};

  .nav-item {
    color: ${({ theme }) => theme.navigation.linkColor};

    &:hover {
      color: ${({ theme }) => theme.navigation.linkHoverColor};
    }
  }
`;

export const NavContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
