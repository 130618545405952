import React, { FunctionComponent } from 'react';
import Link from 'src/ui/atoms/link';
import useTranslate from 'src/ui/hooks/use-translate';

import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';

export interface FrozenWalletModalProps {
  onClose: () => void;
}

const FrozenWalletModal: FunctionComponent<FrozenWalletModalProps> = ({ onClose }) => {
  const translate = useTranslate();

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <Translate name="dashboardWalletInfo.walletFrozenModal.title" />
      </ModalHeader>
      <ModalContent>
        <Translate name="dashboardWalletInfo.walletFrozenModal.content1" as="p" />
        <Translate
          as="p"
          name="dashboardWalletInfo.walletFrozenModal.content2"
          args={[
            (_, key) => (
              <Link href={`mailto:${translate('platform.supportEmail')}`} key={key} inline>
                <Translate name="platform.supportEmail" />
              </Link>
            ),
          ]}
        />
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              onClick: onClose,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default FrozenWalletModal;
