import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import usePlatformImage from 'ui/hooks/use-platform-image';
import LoadingRing from 'ui/atoms/loading-ring';
import Image from 'ui/atoms/image';
import { ApiServerError, UnknownServerError } from 'core/api/errors';
import { handleError } from 'ui/helper/error-handling';
import useTranslate from 'ui/hooks/use-translate';
import Hint from 'ui/atoms/hint';

export interface TfaDeviceSetupModalProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  loading?: boolean;

  user?: { email: string };

  onResendEmail?: () => void;

  onClose?: () => void;

  error?: ApiServerError | UnknownServerError;
}

const TfaDeviceSetupModal: FunctionComponent<TfaDeviceSetupModalProps> = ({
  className,
  user,
  onResendEmail = () => {},
  onClose = () => {},
  error,
}) => {
  const imgTfaSetup = usePlatformImage('tfaSetup');

  const title = <Translate name="portfolio.transferWizard.setupTitle" />;

  const { getRemainingError } = handleError({
    error,
    translate: useTranslate(),
  });

  const remainingError = getRemainingError();

  return (
    <Modal onClose={onClose} className={cx('tfa-device-setup-modal', className)}>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        {!user && !error && <LoadingRing />}
        {user && (
          <>
            <p>
              <Image alt="" src={imgTfaSetup} size="medium" />
            </p>

            <p>
              <Translate name="portfolio.transferWizard.setupDescUpper" />
            </p>

            <p>
              <Translate name="portfolio.transferWizard.setupDescLower" args={[<b key={0}>{user.email}</b>]} />
            </p>
            <Button variant="link" onClick={onResendEmail}>
              <Translate name="portfolio.transferWizard.setupResendEmail" />
            </Button>
            <Hint variant="warning">
              <Translate name="portfolio.transferWizard.forwardWhenSetupCompleteHint" />
            </Hint>
          </>
        )}
        {error && <Hint variant="danger">{remainingError}</Hint>}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'close',
              size: 'large',
              content: <Translate name="common.close" />,
              onClick: onClose,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default TfaDeviceSetupModal;
