import React, { FunctionComponent, useMemo } from 'react';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import usePlatformImage from 'ui/hooks/use-platform-image';
import { useServerConfigSelector } from 'core/config/hooks';
import useGoTo from 'hooks/use-go-to';
import EmptyView, { EmptyViewAssetMap, EmptyViewActionMap } from 'core/components/empty-views/empty-view';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';

const mapTypeAssetName: EmptyViewAssetMap = {
  [EmptyViewType.NO_FILTER_RESULTS]: 'emptyFilterResults',
  [EmptyViewType.INVESTOR_PORTFOLIO]: 'emptyViewInvestorPortfolio',
  [EmptyViewType.INVESTOR_DOCUMENTS]: 'emptyViewDocuments',
  [EmptyViewType.INVESTOR_OPEN_INVESTMENTS]: 'emptyViewInvestorOpenInvestments',
  [EmptyViewType.REGISTERED_PORTFOLIO]: 'emptyViewInvestorPortfolio',
  [EmptyViewType.REGISTERED_DOCUMENTS]: 'emptyViewDocuments',
};

export interface InvestorEmptyViewProps {
  type: EmptyViewType;
}

// todo: refactor this further, split it for each type
const InvestorEmptyView: FunctionComponent<InvestorEmptyViewProps> = (props) => {
  const { type, children } = props;

  const emptyViewImage = usePlatformImage(mapTypeAssetName[type] || 'emptyViewInvestorOpenInvestments');

  const {
    config: { investNowUrl },
  } = useServerConfigSelector();

  const goToContinueInvestments = useGoTo(INVESTOR_ROUTES.continueInvestments);

  const goToInvestNowUrl = (investNowUrl: string) => {
    window.open(investNowUrl, '_blank', 'noopener noreferrer');
  };

  const mapEmptyViewActions: EmptyViewActionMap = useMemo(() => {
    return {
      [EmptyViewType.INVESTOR_PORTFOLIO]: [
        {
          i18nKey: 'viewOpenInvestments',
          onAction: goToContinueInvestments,
        },
      ],
      [EmptyViewType.INVESTOR_DOCUMENTS]: [
        {
          i18nKey: 'viewOpenInvestments',
          onAction: goToContinueInvestments,
        },
      ],
      [EmptyViewType.INVESTOR_OPEN_INVESTMENTS]: investNowUrl
        ? [{ i18nKey: 'investNow', onAction: () => goToInvestNowUrl(investNowUrl) }]
        : undefined,
    };
  }, [investNowUrl, goToContinueInvestments]);

  return (
    <EmptyView type={type} image={emptyViewImage} emptyViewActions={mapEmptyViewActions[type]}>
      {children}
    </EmptyView>
  );
};

export default InvestorEmptyView;
