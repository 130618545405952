import React, { FunctionComponent } from 'react';
import { camelCase } from 'change-case';
import Translate from 'ui/atoms/translate';
import useTranslate from 'ui/hooks/use-translate';
import { QuestionnaireForm } from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { EducationalQualification, Occupation } from 'ui/types/person';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';

export interface LegacyQuestionnaireProps {
  questions: QuestionnaireQuestions[];
}

const LegacyQuestionnaire: FunctionComponent<LegacyQuestionnaireProps> = ({ questions }) => {
  const translate = useTranslate();

  return (
    <>
      <QuestionnaireForm.Group name="educationalQualification">
        <QuestionnaireForm.Select
          options={Object.values(EducationalQualification).map((educationalQualification) => ({
            value: educationalQualification,
            label: translate(`educationalQualification.${camelCase(educationalQualification)}`),
          }))}
        ></QuestionnaireForm.Select>
      </QuestionnaireForm.Group>
      <QuestionnaireForm.Group name="occupation">
        <QuestionnaireForm.Select
          options={Object.values(Occupation).map((occupation) => ({
            value: occupation,
            label: translate(`occupation.${camelCase(occupation)}`),
          }))}
        ></QuestionnaireForm.Select>
      </QuestionnaireForm.Group>
      {questions.map((question) => (
        <QuestionnaireForm.Group
          key={question.id}
          name={question.id}
          label={<Translate name={`questionnaire.${snakeToCamelCaseWithNumbers(question.id)}`} />}
        >
          <QuestionnaireForm.Select
            options={(question.options || []).map((option) => ({
              value: option,
              label: translate(`questionnaire.${snakeToCamelCaseWithNumbers(option)}`),
            }))}
          ></QuestionnaireForm.Select>
        </QuestionnaireForm.Group>
      ))}
    </>
  );
};

export default LegacyQuestionnaire;
