import React, { FunctionComponent } from 'react';
import Icon from 'ui/atoms/icon';
import Dropdown, { DropdownItem } from 'ui/atoms/dropdown';
import { Icons } from 'src/ui/assets/icons/types';
import Translate from 'src/ui/atoms/translate';
import Spacer from 'src/ui/atoms/spacer';
import { HorizontalAlign } from 'src/ui/types/align';

export interface LanguageSelectorNavigationItemProps {
  active: string;
  iconColor?: string;
  openPosition?: HorizontalAlign;
  onLocaleChange: (locale: string) => void;
}

const languages = [
  { locale: 'de', name: 'deutsch' },
  { locale: 'en', name: 'english' },
];

export const LanguageSelectorNavigationItem: FunctionComponent<LanguageSelectorNavigationItemProps> = (props) => {
  const { active, iconColor = 'white', openPosition = 'left', onLocaleChange = () => {} } = props;

  return (
    <>
      <Dropdown
        id="language-selector"
        title={<Icon name={active as Icons} size="large" />}
        openPosition={openPosition}
        hasDynamicArrow={true}
        color={iconColor}
        className="nav-item"
      >
        {languages.map(({ locale, name }) => (
          <DropdownItem onClick={() => onLocaleChange(locale)} key={locale}>
            <Translate name={`languages.${locale}`} />
            <Spacer x={4} />
            <Icon name={locale as Icons} size="large" />
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

export default LanguageSelectorNavigationItem;
