import React from 'react';
import FormGroup from 'ui/molecules/form-group';
import DatePicker from 'ui/atoms/date-picker';
import { useFilterOption } from 'ui/molecules/filtering/hooks';
import type { FunctionComponent } from 'react';
import type { FilterDatePickerInput } from 'ui/molecules/filtering/types';

export const FilterDateOption: FunctionComponent<FilterDatePickerInput> = (props) => {
  const { input, name, pairedInput, defaultField, label, minDate, maxDate, ...restProps } = props;
  const { value, setValue } = useFilterOption(props, null);

  const changeDate = (value: Date) => {
    setValue(value);
  };

  if (!label) {
    return (
      <DatePicker
        value={value}
        onChange={changeDate}
        minDate={minDate as Date}
        maxDate={maxDate as Date}
        {...restProps}
      />
    );
  }

  return (
    <FormGroup label={props.label}>
      <DatePicker value={value} onChange={changeDate} />
    </FormGroup>
  );
};

export default FilterDateOption;
