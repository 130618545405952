import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Translate from 'ui/atoms/translate';
import StepList, { StepItem } from 'ui/molecules/step-list';
import Segment from 'ui/atoms/segment';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import Markdown from 'ui/atoms/markdown';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Text from 'src/ui/polymorphs/text';

export interface FriendVoucherInfoProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  voucherCode: string;
}

const steps = ['share', 'invest', 'claimVoucher'];

const FriendVoucherInfo: FunctionComponent<FriendVoucherInfoProps> = (props) => {
  const { className, children, voucherCode, ...restProps } = props;

  const translate = useTranslateWithStringArgs();

  return (
    <div className={cx('friend-voucher-info', className)} {...restProps}>
      <Translate as="p" name="friendVoucherInfo.description" args={[<strong key={0}>{voucherCode}</strong>]} />
      <StepList spacing="large" listPointVariant="secondary">
        {steps.map((step, index) => (
          <StepItem
            key={index}
            isLastItem={index === steps.length - 1}
            title={<Translate name={`friendVoucherInfo.${step}.title`} />}
          >
            <Text align="left">
              <Translate name={`friendVoucherInfo.${step}.description`} />
            </Text>
          </StepItem>
        ))}
      </StepList>

      <Segment compact={true} basic={true} padded="none">
        <p>
          <Translate as="strong" name="friendVoucherInfo.voucherCode" />
        </p>
        <CopyInputGroup valueToCopy={voucherCode} spacing="large" />
      </Segment>
      <Markdown source={translate('friendVoucherInfo.termsAndConditions')} />
    </div>
  );
};

export default FriendVoucherInfo;
