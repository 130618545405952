import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';

export type LoaderSizes = 'small' | 'regular';

export interface LoadingRingProps {
  $size?: LoaderSizes;
}

const LOADER_SIZE_MAP = {
  small: {
    width: '24px',
    height: '24px',
  },
  regular: {
    width: '80px',
    height: '80px',
  },
};

function getLoaderSizes({ $size }: ThemedStyledProps<LoadingRingProps, DefaultTheme>) {
  const { width, height } = LOADER_SIZE_MAP[$size || 'regular'];

  return css`
    width: ${width};
    height: ${height};

    svg {
      width: ${width};
      height: ${height};
    }
  `;
}

export const LoaderRing = styled.div`
  color: ${({ theme }) => resolveColor(theme, 'primary')};
  animation: spin 2s infinite linear;
  margin: auto;

  ${getLoaderSizes};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
