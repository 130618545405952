import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi, TokenOverview } from 'api';
import LoadingRing from 'ui/atoms/loading-ring';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import ProductOverview from 'apps/issuer/shared/product-overview';
import ProductCampaign from 'apps/issuer/pages/product-details/campaigns';
import ProductInvitation from 'apps/issuer/pages/product-details/invitations';
import { useActiveProduct } from 'apps/issuer/hooks';
import Link from 'core/routing/link';

const ProductDetailsPage = (props: { productId: string }) => {
  const { productId } = props;

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);

  const [productOverview, setProductOverview] = useState<TokenOverview>();

  const { token } = useActiveProduct(productId);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!productId) {
        return;
      }
      withApi(async () => {
        const productOverview = await api.adminTokensOverviewRetrieve({
          id: productId,
        });

        setProductOverview(productOverview);
      });
    });
  }, [withApi, api, productId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading || !productOverview || !token) {
    return <LoadingRing />;
  }

  return (
    <>
      <Section spacing="large">
        <WideContent title={<Translate name="issuerProductDetails.title" args={[productOverview.productName]} />}>
          <ProductOverview data={productOverview} />
        </WideContent>
      </Section>
      <Section spacing="large">
        <ProductCampaign productId={productId} product={token} />
      </Section>
      <Section spacing="medium">
        <ProductInvitation productId={productId} product={token} />
      </Section>
      <Section spacing="medium">
        <WideContent>
          <Link icon="arrow-left" to="/dashboard/products">
            <Translate as="span" name="issuerProductDetails.backToProducts" />
          </Link>
        </WideContent>
      </Section>
    </>
  );
};

export default WithAuthenticatedPage(ProductDetailsPage, AppType.ISSUER);
