import { matchPath } from 'react-router';
import type { RouteDefinition } from 'core/routing/types';
import type { SubNavigationItem } from 'core/nav/types';

const isOneOfSubRoutesActive = (subRoutes: SubNavigationItem[] | undefined, currentPath: string): boolean => {
  if (!subRoutes?.length) return false;
  for (let i = 0; i < subRoutes.length; i++) {
    if (
      matchPath(currentPath, {
        path: subRoutes[i].route.path,
        exact: subRoutes[i].route.exact,
      })
    ) {
      return true;
    } else if (isOneOfSubRoutesActive(subRoutes[i].subRoutes, currentPath)) {
      return true;
    }
  }
  return false;
};

export const isMainRouteActive = (
  route: RouteDefinition,
  subRoutes: SubNavigationItem[] | undefined,
  currentPath: string,
): boolean => {
  if (
    route &&
    matchPath(currentPath, {
      path: route.path,
      exact: route.exact,
    })
  ) {
    return true;
  } else {
    return isOneOfSubRoutesActive(subRoutes, currentPath);
  }
};
