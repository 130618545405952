import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';
import { ISSUER_ROUTES } from './routes.config';
import type { DistributionPlatform, Feature, InvestmentToken } from 'api';

export function useIssuerNavigationItems({
  tokens,
  configFeatures,
  distributionPlatforms,
}: {
  tokens: InvestmentToken[];
  configFeatures: Feature;
  distributionPlatforms: DistributionPlatform[];
}) {
  return useRoutesResolver(ISSUER_ROUTES, {
    tokens,
    displayAllRoutes: true,
    configFeatures,
    distributionPlatforms,
  });
}
