import React, { FunctionComponent, useState } from 'react';

import { compact } from 'lodash';
import cx from 'ui/helper/prefixed-class-names';

import { ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';

import Translate from 'ui/atoms/translate';
import { Document } from 'ui/types/document';
import DocumentView from 'ui/molecules/document-view';
import LoadingWithMessage from 'ui/molecules/loading-with-message';
import Link, { LinkProps } from 'ui/atoms/link';
import * as Styled from './styled';

export interface DocumentModalProps extends Partial<Document> {
  /** Additional classes. */
  className?: string;

  /**
   * @expandType true
   */
  linkProps?: Pick<LinkProps, Exclude<keyof LinkProps, 'onClick' | 'href' | 'platformUrl'>>;

  file?: string;
}

const DocumentModal: FunctionComponent<DocumentModalProps> = (props) => {
  const { children, linkProps, file } = props;

  const [open, setOpen] = useState(false);

  const hasFile = !!file;

  return (
    <>
      <Link
        href={file || ''}
        size="large"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        inline
        {...linkProps}
      >
        {children}
      </Link>
      {open && (
        <Styled.DocumentModal className={cx('document-modal')} onClose={() => setOpen(false)} size="large">
          <ModalHeader>{children}</ModalHeader>
          <ModalContent>
            {!hasFile && (
              <LoadingWithMessage>
                <Translate name="documentModal.loadingDocument" />
              </LoadingWithMessage>
            )}
            {hasFile && <DocumentView file={file} />}
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={compact([
                hasFile && {
                  name: 'open',
                  content: <Translate name="common.showFullDocument" />,
                  size: 'large',
                  onClick: () =>
                    // CP-658: this is required on iOS where embedded PDFs are not scrollable
                    window.open(file, '_blank'),
                },
                {
                  name: 'close',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: () => setOpen(false),
                },
              ])}
            />
          </ModalFooter>
        </Styled.DocumentModal>
      )}
    </>
  );
};

export default DocumentModal;
