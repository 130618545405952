import React, { FunctionComponent, useCallback } from 'react';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import { useServerConfigSelector } from 'src/core/config/hooks';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import Link from 'src/ui/atoms/link';
import { removeRegistrationLocalStorage } from 'subapps/investment/helpers';
import Header from 'ui/atoms/header';

const RegistrationDone: FunctionComponent<{}> = () => {
  const {
    config: { investNowUrl },
  } = useServerConfigSelector();

  const title = <Translate name="register.registrationDone.title" />;

  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);

  const finishUp = useCallback(() => {
    removeRegistrationLocalStorage();
    goToOverview();
  }, []);

  return (
    <>
      <Header size="large">{title}</Header>
      <Hint variant="success">
        <Translate name="register.registrationDone.hint" />
      </Hint>
      <p>
        <b>
          <Translate name="register.registrationDone.subtitle" />
        </b>
      </p>
      <p>
        {investNowUrl ? (
          <Translate
            name="register.registrationDone.descriptionWithLink"
            args={[
              (txt, key) => (
                <Link href={investNowUrl} inline>
                  {txt}
                </Link>
              ),
            ]}
          />
        ) : (
          <Translate name="register.registrationDone.descriptionWithoutLink" />
        )}
      </p>
      <ActionButtons>
        <Button fluid={true} size="large" variant="secondary" onClick={finishUp}>
          <Translate name="register.registrationDone.button" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default RegistrationDone;
