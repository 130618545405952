import { compact } from 'lodash';

import React, { Context, createContext, Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import useTranslate from '../ui/hooks/use-translate';

export const PageTitleContext: Context<{
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
}> = createContext({ title: '', setTitle: (title: string) => {} });

export const PageTitleProvider: FunctionComponent<{}> = ({ children }) => {
  const [title, setTitle] = useState('');
  const translate = useTranslate();

  useEffect(() => {
    document.title = compact([title, translate('platform.name')]).join(' | ');
  }, [title, translate]);

  return <PageTitleContext.Provider value={{ title, setTitle }}>{children}</PageTitleContext.Provider>;
};

export default {
  PageTitleProvider,
  PageTitleContext,
};
