import { AdminApi, AdminMail } from 'api';
import WithDataRecord from 'hoc/WithDataRecord';
import React, { FunctionComponent, ReactNode } from 'react';
import Button from 'ui/atoms/button';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';
import DateAtom from 'ui/atoms/date';
import Translate from 'ui/atoms/translate';
import { FiltersWrapper } from 'ui/molecules/filtering';
import DataRecordTable from 'src/libraries/data-record-table';
import DataRecordOrdering from 'ui/types/data-ordering';
import * as Styled from './styled';

interface IssuerInvestmentsTableProps {
  filters?: ReactNode;
  forceDataLoad?: boolean;
  resendEmail: (id: string) => void;
  isEmailResent: (id: string) => boolean;
  defaultOrdering?: DataRecordOrdering;
  defaultLimit?: number;
  investorId: string;
  emptyView?: ReactNode;
}

const EmailHistoryTableView: FunctionComponent<IssuerInvestmentsTableProps> = WithDataRecord<AdminApi, AdminMail>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, loading, filters, emptyView, resendEmail, isEmailResent, paginationProps }) => {
    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <DataRecordTable
          ordering={ordering}
          onOrderBy={onOrderBy}
          data={data}
          loading={loading}
          emptyView={emptyView}
          paginationProps={paginationProps}
          cells={[
            {
              title: <Translate name={`studioEmailHistoryTable.product`} />,
              render: ({ product }) => product || '-',
            },
            {
              title: <Translate name={`studioEmailHistoryTable.mailType`} />,
              render: ({ type }) => <Translate name={`emailType.${type}`} />,
            },
            {
              title: <Translate name={`studioEmailHistoryTable.mailSubject`} />,
              render: ({ subject }) => subject,
            },
            {
              title: <Translate name={`studioEmailHistoryTable.deliveredDate`} args={[<>&shy;</>]} />,
              render: ({ deliveredAt }) => deliveredAt && <DateAtom showTime>{new Date(deliveredAt)}</DateAtom>,
            },
            {
              title: null,
              alignContent: 'right',
              render: ({ id }) => {
                const emailResent = isEmailResent(id);
                return (
                  <Button type="submit" variant="link" disabled={emailResent} onClick={() => resendEmail(id)}>
                    {emailResent ? (
                      <Styled.EmailResentMessage>
                        <Translate name="studioEmailHistoryTable.resent" />
                        <Spacer x={2} />
                        <Icon color="link" name="check" />
                      </Styled.EmailResentMessage>
                    ) : (
                      <Translate name="studioEmailHistoryTable.resend" />
                    )}
                  </Button>
                );
              },
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit) => {
    return api.adminInvestorsMailsList({
      id: props.investorId,
      offset,
      limit,
      ...props,
    });
  },
);

export default EmailHistoryTableView;
