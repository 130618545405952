import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import { WalletsActions, walletsFetchSuccess, walletsFetchFailure } from './actions';
import { getWalletsEffect } from './effects';
import type { WalletsActionTypes, WalletsFetch, WalletsFetchFailure, WalletsFetchSuccess } from './actions';
import type { Wallet } from 'api';
import { RootActions, WithRootActionTypes } from 'store/actions';

export const WALLETS_STORE_NAME = 'wallets';

export type WalletsStateShape = {
  data: Wallet[] | null;
  loading: boolean;
  error: Error | null;
};

export const initialState: WalletsStateShape = {
  data: null,
  loading: false,
  error: null,
};

type WalletsLoop<A> = (state: WalletsStateShape, action: A) => WalletsStateShape | Loop<WalletsStateShape>;

const handleWalletsFetch: WalletsLoop<WalletsFetch> = (state) => {
  return loop(
    mergeAll([state, { loading: true, error: false }]),
    Cmd.run(getWalletsEffect, {
      args: [Cmd.getState],
      successActionCreator: walletsFetchSuccess,
      failActionCreator: walletsFetchFailure,
    }),
  );
};

const handleWalletsFetchSuccess: WalletsLoop<WalletsFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      data,
      loading: false,
      error: null,
    },
  ]);
};

const handleWalletsFetchFailure: WalletsLoop<WalletsFetchFailure> = (state, { error }) => {
  return mergeAll([
    state,
    {
      data: null,
      loading: false,
      error: error,
    },
  ]);
};

const reducer: LoopReducerWithDefinedState<WalletsStateShape, WithRootActionTypes<WalletsActionTypes>> = (
  state = initialState,
  action,
): WalletsStateShape | Loop<WalletsStateShape> => {
  switch (action.type) {
    case RootActions.RESET_STATE:
      return initialState;
    case WalletsActions.WALLETS_FETCH:
      return handleWalletsFetch(state, action);
    case WalletsActions.WALLETS_FETCH_SUCCESS:
      return handleWalletsFetchSuccess(state, action);
    case WalletsActions.WALLETS_FETCH_FAILURE:
      return handleWalletsFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
