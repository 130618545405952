import { DistributionPlatform, DistributionPlatformPermissionEnum } from 'src/api';

export const userHasPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
  permission: DistributionPlatformPermissionEnum,
) =>
  !!distributionPlatforms &&
  distributionPlatforms.some(
    (distributionPlatform) => !!distributionPlatform?.permissions.find((perm) => perm === permission),
  );

export const hasDistributionViewDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.VIEW_PLATFORM_DATA);

export const hasDistributionManageDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.MANAGE_INVESTOR_AND_INVESTMENT_DATA);

export const hasDistributionCreateDataPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.CREATE_INVESTOR_AND_INVESTMENT);

export const hasDistributionManageVouchersPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.MANAGE_VOUCHERS);

export const hasDistributionViewFriendReferralPermission = (distributionPlatforms: DistributionPlatform[] | null) =>
  userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.VIEW_FRIEND_REFERRALS);

export const hasDistributionManageIdentificationDataPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
) => userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.MANAGE_IDENTIFICATION_DATA);

export const hasDistributionDownloadPlatformStatisticsDataPermission = (
  distributionPlatforms: DistributionPlatform[] | null,
) => userHasPermission(distributionPlatforms, DistributionPlatformPermissionEnum.DOWNLOAD_PLATFORM_STATISTICS);
