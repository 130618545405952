import React, { Fragment, FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import getElementType, { As } from 'ui/helper/get-element-type';

import moment from 'moment';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

export interface DateProps extends As {
  /** Primary content */
  children?: Date;

  /** Additional classes */
  className?: string;

  /** Show date time */
  showTime?: boolean;
}

export const Date: FunctionComponent<DateProps> = (props) => {
  const { className, children, showTime, ...restProps } = props;

  const translate = useTranslateWithStringArgs();

  const formattedDate =
    children &&
    moment(children).format(`${translate('date.dateFormat')} ${showTime ? translate('date.timeFormat') : ''}`);

  const ElementType = getElementType(Date, props, className ? 'span' : Fragment);

  if (ElementType === Fragment) return <>{formattedDate}</>;

  return (
    <ElementType className={cx('date', className)} {...restProps}>
      {formattedDate}
    </ElementType>
  );
};

export default Date;
