import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';

export interface PrepareAssetHintProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;
}

const PrepareAssetHint: FunctionComponent<PrepareAssetHintProps> = (props) => {
  const { className, children, ...restProps } = props;

  return (
    <div className={cx('investment-prepare-asset', className)} {...restProps}>
      <Hint variant="success">
        <Translate name="investmentPrepareAsset.walletSuccess" />
      </Hint>
      <Translate as="p" name="investmentPrepareAsset.description" />
      <ol>
        <li>
          <Translate name="investmentPrepareAsset.clickReceive" />
        </li>
        <li>
          <Translate name="investmentPrepareAsset.appConfirmation" />
        </li>
      </ol>
    </div>
  );
};

export default PrepareAssetHint;
