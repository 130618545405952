import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import useApiCall from 'hooks/use-api-call';
import { WalletsApi, Wallet, NetworkTypeEnum, WalletTypeEnum, TwoFactorAuthApi } from 'api';
import { getWalletAddressForNetwork } from 'helper/network';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import Translate from 'ui/atoms/translate';
import { getNetworkTypeName } from 'ui/helper/networks';
import Section from 'ui/atoms/section';
import Hint from 'ui/atoms/hint';
import Icon from 'ui/atoms/icon';
import { camelCase } from 'change-case';
import Button from 'ui/atoms/button';
import ResetWalletModal from './reset-wallet-modal';
import ReceivingTransfers from './receiving-transfers';
import * as Styled from './styled';

const BoxedWallets: FunctionComponent = () => {
  const { withApi, makeAuthenticatedApi } = useApiCall();
  const [wallets, setWallets] = useState<Wallet[]>();
  const [isUnpaired, setIsUnpaired] = useState(false);
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const walletsApi: WalletsApi = useMemo(() => makeAuthenticatedApi(WalletsApi), [makeAuthenticatedApi]);
  const adminApi: TwoFactorAuthApi = useMemo(() => makeAuthenticatedApi(TwoFactorAuthApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      const wallets = await walletsApi.walletsList();
      setWallets(wallets);

      const mobileWallet = wallets.find(({ type }) => type === WalletTypeEnum.MOBILE);
      setIsUnpaired(mobileWallet?.unpaired || false);
    });
  }, [withApi, walletsApi]);

  const onResendResetEmail = useCallback(() => {
    withApi(async () => {
      try {
        await adminApi.twoFactorAuthDeviceResendResetMailCreate();
        setIsResetEmailSent(true);
      } catch (e) {
        console.error(e);
      }
    });
  }, [withApi, adminApi]);

  const onSavingReceiveAssets = useCallback(async () => {
    const wallets = await walletsApi.walletsList();
    setWallets(wallets);
  }, [walletsApi]);

  const onResetWalleModalClose = useCallback(() => {
    setIsResetModalOpen(false);
  }, []);

  if (!wallets?.length) {
    return null;
  }

  return (
    <>
      <BoxedContent
        title={<Translate name="dashboardInvestorSettings.wallets.title" />}
        releaseSpaceWhenTitleIsEmpty={true}
      >
        {wallets.map((wallet) => {
          const ethAddress = getWalletAddressForNetwork(NetworkTypeEnum.ETHEREUM, wallet);
          const stellarAddress = getWalletAddressForNetwork(NetworkTypeEnum.STELLAR, wallet);
          return (
            <Section key={wallet.id}>
              <Styled.WalletType>
                <Translate name={`walletType.${camelCase(wallet.type)}`} />
              </Styled.WalletType>
              {ethAddress && (
                <Section spacing="small">
                  <strong>
                    <Translate
                      key={0}
                      name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.ETHEREUM)}`}
                    />
                  </strong>
                  <CopyInputGroup valueToCopy={ethAddress} disabled />
                  <ReceivingTransfers
                    ethAddress={ethAddress}
                    receivingEnabled={wallet.receivingEnabled}
                    stellarAddress={stellarAddress}
                    walleId={wallet.id}
                    onSavingReceiveAssets={onSavingReceiveAssets}
                  />
                </Section>
              )}
              {stellarAddress && (
                <Section spacing="medium">
                  <strong>
                    <Translate
                      key={0}
                      name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.STELLAR)}`}
                    />
                  </strong>
                  <CopyInputGroup valueToCopy={stellarAddress} disabled />
                  <ReceivingTransfers
                    ethAddress={ethAddress}
                    receivingEnabled={wallet.receivingEnabled}
                    stellarAddress={stellarAddress}
                    walleId={wallet.id}
                    onSavingReceiveAssets={onSavingReceiveAssets}
                  />
                </Section>
              )}
            </Section>
          );
        })}
        {!isUnpaired && (
          <>
            <div>
              <Translate name={`resetWallet.resetHint`} />
            </div>
            <Button variant="link" onClick={() => setIsResetModalOpen(true)}>
              <Translate name={`resetWallet.resetTitle`} />
            </Button>
          </>
        )}
        {isUnpaired && (
          <>
            <Hint variant="warning">
              <Translate name="resetWallet.unpaired" />
            </Hint>
            {!isResetEmailSent && (
              <Button variant="link" onClick={() => onResendResetEmail()}>
                <Translate name={`resetWallet.resendEmail`} />
              </Button>
            )}

            {isResetEmailSent && (
              <Button variant="link">
                <Translate name={`resetWallet.resendEmailSent`} /> <Icon name="check" />
              </Button>
            )}
          </>
        )}
      </BoxedContent>

      {isResetModalOpen && <ResetWalletModal onModalClose={() => onResetWalleModalClose()} />}
    </>
  );
};

export default BoxedWallets;
