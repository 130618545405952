import React, { ComponentType } from 'react';
import { RouteChildrenProps } from 'react-router';
import type { FunctionComponent } from 'react';
import PageTitle from 'core/components/page-title';
import LoadingRing from 'ui/atoms/loading-ring';
import { Route } from 'core/routing/types';
import ErrorBoundary from 'src/core/components/error-boundary';

export interface RouteRendererProps extends RouteChildrenProps {
  route?: Route;
  Component: ComponentType<any>;
}

// todo: this abstraction is potentially uncessecary, and may be better solutions to it, do investigate
const RenderRoute: FunctionComponent<RouteRendererProps> = ({ route, match, Component }) => {
  if (!match) return <LoadingRing key="content" />;

  return (
    <ErrorBoundary>
      <PageTitle key="title">{''}</PageTitle>
      {<Component key="content" condition={route?.condition} {...match?.params} />}
    </ErrorBoundary>
  );
};

export default RenderRoute;
