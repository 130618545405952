import React, { FunctionComponent, useMemo } from 'react';
import RoutesRenderer from 'core/routing/routes-renderer';
import DashboardLayout from 'core/layout/dashboard';
import { INVESTOR_COMPONENT_ROUTING, INVESTOR_NAVIGATION } from 'apps/investor/pages/routes.config';
import { useInvestorNavigationItems } from 'apps/investor/pages/hooks';
import { Config, Investor } from 'api';
import useHubspotChatbot from 'hooks/use-hubspot-chatbot';
import InvestorTasks from 'apps/investor/shared/tasks';

export interface InvestorPagesProps {
  investor: Investor | null;
  investorHasManagedWallet: boolean;
  hasInvestments: boolean | null;
  hasEcspInvestments: boolean | null;
  config: Config;
}

const InvestorPages: FunctionComponent<InvestorPagesProps> = ({
  investor,
  investorHasManagedWallet,
  hasInvestments,
  hasEcspInvestments,
  config,
}) => {
  const routes = useInvestorNavigationItems({
    investor,
    investorHasManagedWallet,
    hasInvestments,
    hasEcspInvestments,
    config,
  });

  const navItems = useMemo(
    () => (routes ? INVESTOR_NAVIGATION.filter((nav) => !!routes[nav.key]) : []),
    [JSON.stringify(routes)],
  );

  useHubspotChatbot();

  return (
    <>
      <InvestorTasks />
      <DashboardLayout navigationItems={navItems} showInviteFriends={!!investor?.voucherCode} showInvestNowButton>
        <RoutesRenderer routes={routes} componentRouting={INVESTOR_COMPONENT_ROUTING} />
      </DashboardLayout>
    </>
  );
};

export default InvestorPages;
