import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import ServerError from 'ui/types/server-error';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import { WalletType } from 'ui/types/wallet';
import { camelCase } from 'change-case';
import makeForm from 'ui/forms';

export interface CreateWalletFields {
  address: string;
}

export interface CreateWalletProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (values: CreateWalletFields) => void;

  onCancel?: () => void;

  loading?: boolean;

  error?: ServerError;

  /**
   * @default WalletType.MOBILE
   */
  type?: WalletType.MOBILE | WalletType.GENERIC;
}

const CreateWalletForm = makeForm<CreateWalletFields>();

const CreateWallet: FunctionComponent<CreateWalletProps> = (props) => {
  const {
    className,
    children,
    type = WalletType.MOBILE,
    loading,
    error,
    onCancel,
    onSubmit = () => {},
    ...restProps
  } = props;

  return (
    <div className={cx('investment-create-wallet', className)} {...restProps}>
      <p>
        <Translate name="investmentDepot.walletGuide.description" />
      </p>
      <p>
        <strong>
          <Translate name="investmentDepot.walletGuide.explanationTitle" />
        </strong>
      </p>
      <p>
        <Translate name="investmentDepot.walletGuide.explanation" />
      </p>
      <CreateWalletForm
        i18nKey={`investmentDepot.selection.create.${camelCase(type)}`}
        error={error}
        onSubmit={onSubmit}
      >
        <CreateWalletForm.Group name="address" required={true}>
          <CreateWalletForm.Input />
          {CreateWalletForm.Validators.TokenAddressChecksum()}
        </CreateWalletForm.Group>

        <p>
          <Button variant="link" onClick={onCancel}>
            <Translate name="wallet.otherOptions" />
          </Button>
        </p>

        <CreateWalletForm.Submit variant="primary" size="large" fluid={true} loading={loading}>
          <Translate name="common.continue" />
        </CreateWalletForm.Submit>
      </CreateWalletForm>
    </div>
  );
};

export default CreateWallet;
