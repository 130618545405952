import { useContext, useEffect, useState } from 'react';
import { PlatformContext } from 'ui/platform/provider';
import { DefaultTheme } from 'styled-components';
import mergeAll from 'lodash/fp/mergeAll';
import defaultTheme from 'ui/styles/theme';
import { DeepPartial } from 'ui/helper/typescript-utils';
import { BaseTheme } from 'ui/styles/theme.types';

export type UseStylesProps = {
  initialTheme: DeepPartial<BaseTheme>;
};

export function useStyles({ initialTheme }: UseStylesProps) {
  const { activePlatform, initialPlatform } = useContext(PlatformContext);
  const [platformTheme, setPlatformTheme] = useState<DefaultTheme>(mergeAll([defaultTheme, initialTheme]));

  useEffect(() => {
    if (activePlatform !== initialPlatform) return;

    setPlatformTheme(mergeAll([defaultTheme, initialTheme]));
  }, [activePlatform]);

  return {
    platformTheme,
    setPlatformTheme,
    initialTheme,
  };
}
