import React, { FunctionComponent, useState } from 'react';
import { BenefitingPerson, CountryEnum, LegalPerson } from 'api';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import BenefitingPersonsModal from './add-manual-review-modals/benefiting-persons-modal';
import ManualReviewCompanyFormModal from './add-manual-review-modals/company-data-modal';

export interface AddManualReviewProps {
  investorId: string;
  personData: LegalPerson;
  fetchInvestorDetails?: () => void;
  benefitingPersons?: BenefitingPerson[];
}

const AddManualReview: FunctionComponent<AddManualReviewProps> = (props) => {
  const { investorId, personData, benefitingPersons, fetchInvestorDetails } = props;

  const [manualReviewModalOpen, setManualReviewModalOpen] = useState(false);
  const [benefitingPersonsModalOpen, setBenefitingPersonsModalOpen] = useState(false);

  return (
    <>
      <ActionButtons>
        <Button variant="primary" onClick={() => setManualReviewModalOpen(true)}>
          <Translate name="kycComplianceDetails.addManualReview.addReviewButton" />
        </Button>
      </ActionButtons>
      {manualReviewModalOpen && (
        <ManualReviewCompanyFormModal
          onHide={() => setManualReviewModalOpen(false)}
          investorId={investorId}
          values={{
            companyName: personData.companyName || '',
            street: personData.street || '',
            zip: personData.zip || '',
            city: personData.city || '',
            country: personData.country || CountryEnum.DEU,
            commercialRegisterStatement: null,
            listOfShareholders: null,
            transparencyRegister: null,
            commercialRegisterStatementId: personData.commercialRegisterStatement?.id,
            listOfShareholdersId: personData.listOfShareholders?.id,
            transparencyRegisterId: personData.transparencyRegister?.id,
          }}
          continueReview={() => setBenefitingPersonsModalOpen(true)}
          fetchInvestorDetails={fetchInvestorDetails}
        />
      )}
      {benefitingPersonsModalOpen && (
        <BenefitingPersonsModal
          onHide={() => setBenefitingPersonsModalOpen(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          investorId={investorId}
          benefitingOwners={benefitingPersons}
          completeManualReview={true}
        />
      )}
    </>
  );
};

export default AddManualReview;
