import React, { FunctionComponent, MouseEventHandler } from 'react';
import Action, { ActionProps } from 'ui/polymorphs/action';
import { PolymorphicChildComponentProps } from 'ui/helper/get-element-type';
import { camelCase } from 'change-case';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { PlatformUrl } from 'ui/types/urls';
import { Colors } from 'ui/styles/utils/colors';
import { IconSizes } from 'ui/styles/themes/icons';
import { convertPlatformUrl } from 'core/api/conversions';
import { useServerConfigSelector } from 'core/config/hooks';

export interface BaseLinkProps extends ActionProps {
  disabled?: boolean;

  linkRef?: any;

  external?: boolean;

  params?: object;

  onClick?: MouseEventHandler<HTMLAnchorElement>;

  iconColor?: Colors;

  iconSize?: IconSizes;

  inline?: boolean;
}

interface HrefLinkProps extends BaseLinkProps {
  platformUrl?: never;
  href: string;
}

interface NamedLinkProps extends BaseLinkProps {
  platformUrl: PlatformUrl;
  href?: never;
}

export type LinkProps = HrefLinkProps | NamedLinkProps;

export const Link: FunctionComponent<PolymorphicChildComponentProps<'a', LinkProps>> = ({
  variant = 'link',
  size = 'small',
  href,
  platformUrl,
  external,
  linkRef,
  iconColor,
  iconSize = 'regular',
  inline = false,
  ...props
}) => {
  const translateWithStringArgs = useTranslateWithStringArgs();
  const {
    config: { platformData },
  } = useServerConfigSelector();

  if (platformUrl) {
    const platformUrlName = convertPlatformUrl(platformUrl);
    const urlFromConfig = platformData && platformData[platformUrlName];
    //fallback if the config object is not loaded or has an empty string
    href = urlFromConfig?.length ? urlFromConfig : translateWithStringArgs(`urls.${camelCase(platformUrl)}`);
  }

  return (
    <Action
      as="a"
      href={href}
      ref={linkRef}
      variant={variant}
      size={size}
      icon={props.icon || (external && variant === 'link') ? 'external-link-alt' : undefined}
      iconColor={iconColor}
      iconSize={iconSize}
      iconPosition={props.iconPosition || external ? 'right' : undefined}
      inline={inline}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
      {...props}
    />
  );
};

export default Link;
