import React, { FunctionComponent } from 'react';
import { PlatformProvider } from 'ui/platform/provider';
import I18N from 'ui/i18n';
import Styles from 'ui/styles';
import AssetsProvider from 'ui/assets';
import { PlatformContextProps } from 'ui/platform/types';

const UIContextProvider: FunctionComponent<PlatformContextProps> = ({
  children,
  initialPlatform,
  initialTheme,
  initialLocales,
  initialImages,
}) => {
  return (
    <PlatformProvider initialPlatform={initialPlatform}>
      <Styles initialTheme={initialTheme}>
        <AssetsProvider initialImages={initialImages}>
          <I18N initialLocales={initialLocales}>{children}</I18N>
        </AssetsProvider>
      </Styles>
    </PlatformProvider>
  );
};

export default UIContextProvider;
