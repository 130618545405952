import React, { FunctionComponent } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import CopyButton from 'ui/molecules/copy-button';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { AdminBundesbankSettlementInstruction } from 'src/api';
import Header from 'src/ui/atoms/header';
import BoxedContent from 'src/ui/molecules/boxed-content';
import Spacer from 'src/ui/atoms/spacer';
import Currency from 'ui/atoms/currency';
import StatusTag from 'src/ui/atoms/status-tag';
import { useTriggerSolutionStatus } from '../../trigger-solution-settlements/hooks';
import PlaceholderFallback from 'src/ui/atoms/placeholder-fallback';
import Date from 'src/ui/atoms/date';
import Grid, { Col } from 'src/ui/atoms/grid';

export interface GeneralInformationProps {
  settlement: AdminBundesbankSettlementInstruction;
}

const GeneralInformation: FunctionComponent<GeneralInformationProps> = (props) => {
  const { settlement } = props;

  const isPhone = useIsMedia(DEVICES.phone);

  const { getStatusName, getVariant } = useTriggerSolutionStatus();

  return (
    <>
      <Spacer y={12} />
      <Header size="medium">
        <Translate name="triggerSolutionsGeneral.title" />
      </Header>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <InfoGrid columns={isPhone ? 1 : 3} truncate={true}>
          <InfoField title={<Translate name="triggerSolutionsGeneral.instructionId" />}>
            <span>{settlement.id} </span>
            <CopyButton valueToCopy={settlement.id} />
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsGeneral.product" />}>
            <span>{settlement.productName}</span>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsGeneral.paymentAmount" />}>
            <PlaceholderFallback>
              {settlement.paymentAmount && <Currency>{settlement.paymentAmount}</Currency>}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsGeneral.assetAmount" />}>
            <PlaceholderFallback>
              {settlement.productAmount && <span>{settlement.productAmount}</span>}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsGeneral.htlcHash" />} wordBreak>
            <PlaceholderFallback>{settlement.htlcHash && <span>{settlement.htlcHash}</span>}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsGeneral.assetTimeout" />}>
            <PlaceholderFallback>
              {settlement.calculatedTimeout && <Date showTime={true}>{settlement.calculatedTimeout}</Date>}
            </PlaceholderFallback>
          </InfoField>
        </InfoGrid>

        <Spacer y={8} />
        <Grid>
          <Col width={isPhone ? 12 : 8} spacing="large">
            <InfoField title={<Translate name="triggerSolutionsGeneral.htlcAddress" />}>
              <PlaceholderFallback>
                {settlement.htlcAddress && (
                  <>
                    <span>{settlement.htlcAddress}</span>
                    <CopyButton valueToCopy={settlement.htlcAddress} />
                  </>
                )}
              </PlaceholderFallback>
            </InfoField>
          </Col>
          <Col width={4} disablePadding={isPhone ? false : true}>
            <InfoField title={<Translate name="common.status" />}>
              <PlaceholderFallback>
                {settlement.status && (
                  <StatusTag variant={getVariant(settlement.status)} truncate={true}>
                    {getStatusName(settlement.status)}
                  </StatusTag>
                )}
              </PlaceholderFallback>
            </InfoField>
          </Col>
        </Grid>
      </BoxedContent>
    </>
  );
};

export default GeneralInformation;
