import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useServerConfigSelector } from 'core/config/hooks';
import LoadingRing from 'ui/atoms/loading-ring';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import { Investor } from 'api/models';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import ChangeCommunicationSettings, { ChangeCommunicationSettingsForm } from './change-communication-settings-form';
import { CommunicationSettingsFields } from 'libraries/wizard/components/account-setup/create-account/communication-settings';

const BoxedChangeCommunicationSettings: FunctionComponent<{
  investor: Investor;
}> = (props) => {
  const { investor } = props;
  const { error, loading, withApi, makeAuthenticatedApi } = useApiCall();
  const { config } = useServerConfigSelector();
  const [success, setSuccess] = useState(false);

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const onSubmitCommunicationSettings = useCallback(
    (communicationSettings: CommunicationSettingsFields) => {
      withApi(async () => {
        await investorsApi.investorsPartialUpdate({
          id: investor.id,
          patchedInvestorUpdateRequest: {
            communicationSettings: {
              ...communicationSettings,
            },
          },
        });
        setSuccess(true);
      });
    },
    [investorsApi, withApi, investor.id],
  );

  if (!config.marketingOptsEnabled?.length) return null;

  return (
    <BoxedContent title={<Translate name="changeCommunicationSettings.title" />} releaseSpaceWhenTitleIsEmpty={true}>
      {!investor.person.communicationSettings && <LoadingRing />}
      {investor.person.communicationSettings && (
        <ChangeCommunicationSettingsForm
          onSubmit={onSubmitCommunicationSettings}
          error={error}
          initial={investor.person.communicationSettings}
        >
          <ChangeCommunicationSettings
            communicationChangedSuccessfully={success}
            communicationOptions={config.marketingOptsEnabled}
          />
          <ChangeCommunicationSettingsForm.GenericErrorMessages />
          <ActionButtons>
            {success && (
              <Button variant="link" onClick={() => setSuccess(false)}>
                <Translate name="changeCommunicationSettings.goBack" />
              </Button>
            )}
            {!success && (
              <Button type="submit" variant="primary" loading={loading}>
                <Translate name="changeCommunicationSettings.saveChanges" />
              </Button>
            )}
          </ActionButtons>
        </ChangeCommunicationSettingsForm>
      )}
    </BoxedContent>
  );
};

export default BoxedChangeCommunicationSettings;
