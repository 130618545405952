import { query } from 'ui/styles/queries';
import styled from 'styled-components';
import InputIcon from '../../input-icon';

export const Search = styled(InputIcon)`
  min-width: 260px;
  flex-grow: 1;
  margin-top: 2px;

  ${query.phone`
    min-width: 0;
  `};
`;
