import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import * as Styled from './styled';
import Spacer from '../spacer';

export interface AccordionProps {
  additionalInfo?: ReactNode;
  className?: string;
  expanded: boolean;
  label?: ReactNode;
  labelComponent?: ReactNode;
  onChange: () => void;
}

export const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const { children, additionalInfo, label, labelComponent, onChange, expanded, className } = props;

  return (
    <Styled.Container className={cx('accordion', className)}>
      <Styled.Row onClick={onChange}>
        {labelComponent ? (
          labelComponent
        ) : (
          <Styled.Title variant="link" size="small">
            {label}
          </Styled.Title>
        )}
        <Styled.ExpandIcon name={expanded ? 'angle-up' : 'angle-down'} color="link" />
        {additionalInfo && additionalInfo}
      </Styled.Row>

      <Spacer y={4} />

      {expanded && children}
    </Styled.Container>
  );
};

export default Accordion;
