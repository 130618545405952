import styled from 'styled-components';

export const NavBarMobile = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0rem 1rem;
`;

export const NavBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
`;
