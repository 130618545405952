export interface PaddingTheme {
  none: any;
  tiny: any;
  slightly: any;
  very: any;
}

export default {
  none: 0,
  tiny: 0.5,
  slightly: 1.5,
  very: 2,
} as PaddingTheme;
