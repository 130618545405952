import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { getColorYig, resolveColor } from 'ui/styles/utils/colors';

interface BoxedContentProps {
  $fullHeight?: boolean;
}

const boxedContentDefaults = {
  marginBottom: '2rem',
};

const actionPanelDefaults = {
  spacingNormal: '1.25rem',
  spacingBig: '1.5rem',
};

export const BoxedContent = styled.div<BoxedContentProps>`
  ${({ $fullHeight }) => $fullHeight && `height: 100%`}
  margin-bottom: ${boxedContentDefaults.marginBottom};
  &:last-child {
    margin-bottom: 0;
  }
`;

function getTextColor({ theme }: ThemedStyledProps<BoxedContentProps, DefaultTheme>) {
  const colorYig = getColorYig(theme, resolveColor(theme, theme.colors.black));

  return css`
    color: ${colorYig};
  `;
}

export const ActionPanel = styled.div`
  background: ${({ theme }) => theme.colors.black};
  margin: ${actionPanelDefaults.spacingNormal} -${actionPanelDefaults.spacingNormal} -${actionPanelDefaults.spacingNormal};
  padding: ${actionPanelDefaults.spacingNormal} ${actionPanelDefaults.spacingNormal} ${actionPanelDefaults.spacingBig}
    ${actionPanelDefaults.spacingNormal};
  border-radius: 0 0 ${({ theme }) => theme.common.borderRadius} ${({ theme }) => theme.common.borderRadius};

  small {
    ${getTextColor};
  }
`;
