import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import postIdentButton from './postidentbutton.png';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import ServerError from 'ui/types/server-error';
import Icon from 'ui/atoms/icon';
import Section from 'ui/atoms/section';
import Table from 'ui/molecules/table';
import * as Styled from './styled';

export interface PostIdentVerificationComponentProps {
  /** Is the verification process already started? */
  alreadyStarted?: boolean;

  /** Click handler for post ident button click. */
  onClick?: () => void;

  /** Button loading */
  loading?: boolean;

  /** Error message */
  error?: ServerError;

  /** Additional classes. */
  className?: string;
}

const PostIdentVerificationComponent: FunctionComponent<PostIdentVerificationComponentProps> = (props) => {
  const { className, alreadyStarted = false, onClick = () => {}, loading = false, error, ...restProps } = props;

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const verificationOptions = ['videoIdent', 'eId', 'postOffice'];

  const errors = getRemainingError();

  return (
    <div className={cx('post-ident-verification-component', className)} {...restProps}>
      <Section>
        <Translate
          as="p"
          name="postIdent.startText"
          args={[<Translate key={0} as="b" name="postIdent.identification" />]}
        />
      </Section>
      <p>
        <Translate as="b" name="postIdent.differentOptions" />
      </p>
      <Section>
        <Table borderless={true} compact={true}>
          <Table.Body>
            {verificationOptions.map((verificationOption) => (
              <Table.Row>
                <Table.Cell />
                <Table.Cell verticalAlignContent="top">
                  <Icon name="check" />
                </Table.Cell>
                <Table.Cell>
                  <Translate
                    name={`postIdent.${verificationOption}.description`}
                    args={[<Translate key={1} as="b" name={`postIdent.${verificationOption}.name`} />]}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Section>

      <p>
        <Translate as="b" name="postIdent.clickGraphic" />
      </p>

      {alreadyStarted && (
        <Hint variant="warning">
          <Translate name="identification.alreadyStartedHint" />
        </Hint>
      )}
      {loading ? (
        <LoadingRing size="small" />
      ) : (
        <Button size="large" variant="link" onClick={onClick}>
          <Styled.PostIndentImage src={postIdentButton} alt="" />
        </Button>
      )}
      <p>
        <Styled.ErrorText className="text-error">{errors}</Styled.ErrorText>
      </p>
    </div>
  );
};

export default PostIdentVerificationComponent;
