import React, { FunctionComponent } from 'react';
import Hint from 'ui/atoms/hint';
import Input from 'ui/atoms/input';
import Link from 'ui/atoms/link';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

interface WordsConfirmationProps {
  onChangeWordConfirmation: (isCorrect: boolean) => void;
}

const WordsConfirmation: FunctionComponent<WordsConfirmationProps> = (props) => {
  const { onChangeWordConfirmation } = props;

  const translate = useTranslateWithStringArgs();

  const onChangeText = (value: string) => {
    if (value === translate('resetWallet.wordsConfirmText')) {
      onChangeWordConfirmation(true);
    } else {
      onChangeWordConfirmation(false);
    }
  };

  return (
    <>
      <Hint variant="warning">
        <Translate name="resetWallet.wordsHint" />
      </Hint>
      <p>
        <Translate name="resetWallet.wordsDescription" />
      </p>
      <ul>
        <li key="first">
          <Translate name="resetWallet.wordsConsequence1" />
        </li>
        <li key="second">
          <Translate name="resetWallet.wordsConsequence2" />
        </li>
      </ul>
      <Translate name="resetWallet.wordsConfirmation" as="b" />
      <Input onChange={(evt) => onChangeText((evt.target as HTMLInputElement).value)} />
      <Translate
        name="resetWallet.trouble"
        as="small"
        args={[
          <Link href={`mailto:${translate('platform.supportEmail')}`} inline>
            <Translate name="platform.supportEmail" />
          </Link>,
        ]}
      />
    </>
  );
};

export default WordsConfirmation;
