import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';
import type { Colors } from 'ui/styles/utils/colors';

function getColor({ theme, $color }: ThemedStyledProps<PopupProps, DefaultTheme>) {
  return css`
    background-color: ${({ theme }) => theme.colorVariants.background};
    color: ${resolveColor(theme, 'white')};
  `;
}

interface PopupProps {
  $color?: Colors;
}

export const Popup = styled.div<PopupProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  max-width: 450px;
  top: 24px;
  right: 24px;
  opacity: ${({ theme }) => theme.popups.opacity};
  padding: ${({ theme }) => theme.popups.padding}px;
  border-radius: ${({ theme }) => theme.popups.borderRadius}px;
  ${getColor};

  ${query.phone`
    top: auto;
    right: auto;
    max-width: 250px;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  h4 {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 8px;
`;
