import React, { useCallback, useContext } from 'react';
import FormGroup from 'ui/molecules/form-group';
import Input from 'ui/atoms/input';
import { useFilterOption } from 'ui/molecules/filtering/hooks';

import type { FunctionComponent } from 'react';
import type { FilterOptionInput } from 'ui/molecules/filtering/types';
import FilterContext from '../../filter-context';

export const FilterInputOption: FunctionComponent<FilterOptionInput> = (props) => {
  const { input, name, pairedInput, defaultField, label, ...restProps } = props;
  const { value, setValue } = useFilterOption(props, '');
  const { activeFilters } = useContext(FilterContext);

  const onChange = useCallback(
    (value: string) => {
      setValue(value);
    },
    [activeFilters, name],
  );

  if (!label) {
    return <Input value={value} onChange={(e: any) => onChange(e.target.value)} {...restProps} />;
  }

  return (
    <FormGroup>
      <Input label={props.label} value={value} onChange={(e: any) => onChange(e.target.value)} {...restProps} />
    </FormGroup>
  );
};

export default FilterInputOption;
