import styled from 'styled-components';

export interface ContentProps {
  $maxWidth?: number;
}

export const Content = styled.div<ContentProps>`
  position: absolute;
  top: 100%;
  margin-top: 8px;
  left: 50%;
  transform: translate(-50%, 0px);
  color: ${({ theme }) => theme.colors.grayLight};
  background-color: ${({ theme }) => theme.colors.black};
  width: max-content;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '400px')};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    z-index: 1000;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.black}; transparent;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  text-align: center;

  &:hover {
    ${Content} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
