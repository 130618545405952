import React, { FunctionComponent, useContext } from 'react';

import PageTitle from 'core/components/page-title';
import DemoInvestorRegistrationForm, { InvestorRegistrationFields } from './demo-investor-registration-form';
import Translate from 'ui/atoms/translate';

import { DemoApi } from 'api';
import { useApiCall } from 'hooks/use-api-call';
import { makeApi } from 'core/api';
import useGoTo from 'hooks/use-go-to';
import { DEMO_ROUTES } from 'subapps/demo/pages/routes.config';
import { I18NContext } from 'ui/i18n/provider';

const InvestorRegistration: FunctionComponent<{}> = (props) => {
  const goToDemoRegistrationSuccess = useGoTo(DEMO_ROUTES.investorRegistrationSuccess);

  const { activeLocale } = useContext(I18NContext);
  const { loading, error, withApi } = useApiCall();

  const onSubmit = async (fields: InvestorRegistrationFields) => {
    const { companyName, firstName, lastName, email, consentPrivacy } = fields;
    const api: DemoApi = await makeApi(DemoApi);

    if (
      !(await withApi(async () => {
        await api.demoInvestorRegistrationCreate({
          demoInvestorRegistrationRequest: {
            companyName: companyName || undefined,
            forename: firstName,
            surname: lastName,
            email,
            acceptTos: consentPrivacy,
            preferredLanguage: activeLocale,
          },
        });
        return true;
      }))
    ) {
      // an error occurred
      return;
    }
    goToDemoRegistrationSuccess();
  };

  return (
    <>
      <PageTitle>
        <Translate name="demo.registration.title" />
      </PageTitle>
      <DemoInvestorRegistrationForm error={error} loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default InvestorRegistration;
