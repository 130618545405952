import type { IssuerAppState } from 'store/types';
import { rootStateSelector } from 'store/selectors';

export function issuerAppRootSelector(state: IssuerAppState) {
  return rootStateSelector(state).issuer;
}

export function issuerSelector(state: IssuerAppState) {
  const { data, loading, error, isInitial } = issuerAppRootSelector(state);

  return { tokens: data, loading, error, isInitial };
}

export function productSelector(state: IssuerAppState, productId: string | undefined) {
  const { tokens, loading, error } = issuerSelector(state);

  if (!productId || !tokens?.length) return { token: null, loading, error };

  return {
    token: tokens.find((token) => token.id === productId),
    loading,
    error,
  };
}
