import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import {
  InvestmentsActions,
  investorHasInvestmentsFetchSuccess,
  investorHasInvestmentsFetchFailure,
  InvestorHasInvestmentsFetchSuccess,
  InvestorHasInvestmentsFetch,
  InvestorHasInvestmentsFetchFailure,
  investorHasEcspInvestmentsFetchSuccess,
  investorHasEcspInvestmentsFetchFailure,
  InvestorHasEcspInvestmentsFetchSuccess,
  InvestorHasEcspInvestmentsFetch,
  InvestorHasEcspInvestmentsFetchFailure,
} from './actions';
import { getHasInvestmentsEffect, getHasEcspInvitationsEffect } from './effects';
import type { InvestmentsActionTypes } from './actions';
import { RootActions, WithRootActionTypes } from 'store/actions';

export type InvestmentsStateShape = {
  data: { hasInvestments?: boolean | null; hasEcspInvestments?: boolean | null } | null;
  hasInvestmentsLoading: boolean;
  hasEcspInvestmentsLoading: boolean;
  hasInvestmentsError: Error | null;
  hasEcspInvestmentsError: Error | null;
};

export const initialState: InvestmentsStateShape = {
  data: null,
  hasInvestmentsLoading: false,
  hasEcspInvestmentsLoading: false,
  hasInvestmentsError: null,
  hasEcspInvestmentsError: null,
};

type InvestmentsLoop<A> = (
  state: InvestmentsStateShape,
  action: A,
) => InvestmentsStateShape | Loop<InvestmentsStateShape>;

const handleInvestorHasInvestmentsFetch: InvestmentsLoop<InvestorHasInvestmentsFetch> = (state) => {
  return loop(
    mergeAll([state, { hasInvestmentsLoading: true, hasInvestmentsError: false }]),
    Cmd.run(getHasInvestmentsEffect, {
      args: [Cmd.getState],
      successActionCreator: investorHasInvestmentsFetchSuccess,
      failActionCreator: investorHasInvestmentsFetchFailure,
    }),
  );
};

const handleInvestorHasInvestmentsFetchSuccess: InvestmentsLoop<InvestorHasInvestmentsFetchSuccess> = (
  state,
  { data },
) => {
  return mergeAll([
    state,
    {
      data: { hasInvestments: data },
      hasInvestmentsLoading: false,
      hasInvestmentsError: null,
    },
  ]);
};

const handleInvestorHasInvestmentsFetchFailure: InvestmentsLoop<InvestorHasInvestmentsFetchFailure> = (
  state,
  { error },
) => {
  return mergeAll([
    state,
    {
      data: { hasInvestments: null },
      hasInvestmentsLoading: false,
      hasInvestmentsError: error,
    },
  ]);
};

const handleInvestorHasEcspInvestmentsFetch: InvestmentsLoop<InvestorHasEcspInvestmentsFetch> = (state) => {
  return loop(
    mergeAll([state, { hasEcspInvestmentsLoading: true, hasEcspInvestmentsError: false }]),
    Cmd.run(getHasEcspInvitationsEffect, {
      args: [Cmd.getState],
      successActionCreator: investorHasEcspInvestmentsFetchSuccess,
      failActionCreator: investorHasEcspInvestmentsFetchFailure,
    }),
  );
};

const handleInvestorHasEcspInvestmentsFetchSuccess: InvestmentsLoop<InvestorHasEcspInvestmentsFetchSuccess> = (
  state,
  { data },
) => {
  return mergeAll([
    state,
    {
      data: { hasEcspInvestments: data },
      hasEcspInvestmentsLoading: false,
      hasEcspInvestmentsError: null,
    },
  ]);
};

const handleInvestorHasEcspInvestmentsFetchFailure: InvestmentsLoop<InvestorHasEcspInvestmentsFetchFailure> = (
  state,
  { error },
) => {
  return mergeAll([
    state,
    {
      data: { hasEcspInvestments: null },
      hasEcspInvestmentsLoading: false,
      hasEcspInvestmentsError: error,
    },
  ]);
};

const reducer: LoopReducerWithDefinedState<InvestmentsStateShape, WithRootActionTypes<InvestmentsActionTypes>> = (
  state = initialState,
  action,
): InvestmentsStateShape | Loop<InvestmentsStateShape> => {
  switch (action.type) {
    case RootActions.RESET_STATE:
      return initialState;
    case InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH:
      return handleInvestorHasInvestmentsFetch(state, action);
    case InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_SUCCESS:
      return handleInvestorHasInvestmentsFetchSuccess(state, action);
    case InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_FAILURE:
      return handleInvestorHasInvestmentsFetchFailure(state, action);
    case InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH:
      return handleInvestorHasEcspInvestmentsFetch(state, action);
    case InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_SUCCESS:
      return handleInvestorHasEcspInvestmentsFetchSuccess(state, action);
    case InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_FAILURE:
      return handleInvestorHasEcspInvestmentsFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
