import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

export interface SpacerProps {
  /** Adds space on the horizontal. */
  x?: number;

  /** Adds space on the vertical. */
  y?: number;

  inline?: boolean;

  /** Additional classes. */
  className?: string;
}

export const Spacer: FunctionComponent<SpacerProps> = ({ children, x, y, inline = false, className }) => {
  return (
    <Styled.Spacer $x={x} $y={y} $inline={inline} className={className}>
      {children}
    </Styled.Spacer>
  );
};

export default Spacer;
