import React, { FunctionComponent } from 'react';
import config from 'core/config/local';
import DemoStickyMessage from 'ui/molecules/demo-sticky-message';
import * as Styled from 'src/core/layout/root/styled';

interface EmptyLayoutProps {}

const RootLayout: FunctionComponent<EmptyLayoutProps> = ({ children }) => {
  return (
    <Styled.RootLayout>
      {config.production || <DemoStickyMessage />}
      {children}
    </Styled.RootLayout>
  );
};

export default RootLayout;
