import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import * as Styled from './styled';
import Header from 'ui/atoms/header';

export interface PageNotFoundProps {
  /** Additional classes. */
  className?: string;

  /** Click handler for visit login button. */
  onClick?: () => void;
}

const PageNotFound: FunctionComponent<PageNotFoundProps> = (props) => {
  const { className, onClick, ...restProps } = props;

  const translate = useTranslateWithStringArgs();

  return (
    <Styled.PageNotFound className={cx(className)} {...restProps}>
      <Styled.SubHeadline>
        <Translate name={'pageNotFound.subheadline'} />
      </Styled.SubHeadline>
      <Header size="large">
        <Translate name={'pageNotFound.headline'} />
      </Header>
      <p>
        <Translate name={'pageNotFound.description'} />
      </p>
      <Button variant="primary" onClick={onClick}>
        <Translate name={'pageNotFound.redirectButtonText'} />
      </Button>
      <Styled.ContactLink href={`mailto:${translate('platform.supportEmail')}`}>
        <Translate name={'pageNotFound.supportButtonText'} />
      </Styled.ContactLink>
    </Styled.PageNotFound>
  );
};

export default PageNotFound;
