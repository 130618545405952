import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import Date from 'ui/atoms/date';
import Link from 'ui/atoms/link';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import { InvestorInvestmentStatusEnum } from 'ui/types/investment';
import InvestorInvestmentStatusDescription from './investor-investment-status-description';
import Section from 'ui/atoms/section';
import { camelCase } from 'change-case';
import { getInvestorInvestmentStatusVariant } from 'ui/../helper/investment-status';
import StatusTag from 'ui/atoms/status-tag';
import { convertProductTypeFromApi } from 'core/api/conversions';
import { InvestmentAcquisitionTypeRecipientEnum, Money, NetworkTypeEnum, ProductTypeEnum, Wallet } from 'api';
import { toNumber } from 'ui/helper/money';
import moment from 'moment';

export interface DashboardInvestmentDetailsProps {
  /** Primary content. */
  children?: ReactNode;

  /** Investments can be finished or open (investment process not finished yet). */
  openInvestment?: boolean;

  /** Additional classes. */
  className?: string;
  investment: {
    id: string;
    productName?: string;
    investmentTotal?: Money | null;
    investmentTotalFulfilled?: Money;
    signedDate?: Date;
    sumSettledPayouts?: Money;
    lastSettledPayout?: Money | null;
    nextScheduledPayout?: Date | null;
    wallet?: Wallet | null;
    canAbort?: boolean;
    isTokenized?: boolean;
    tokenTransactionHash?: string;
    token?: { tokenizationInfo: { networkType: NetworkTypeEnum } | null; productType: ProductTypeEnum };
    remainingAmount?: number;
    tokenPrice?: Money;
    acquisitionType?: InvestmentAcquisitionTypeRecipientEnum;
    considerationStartedAt?: Date;
  };
  website?: string | null;
  investorStatus: InvestorInvestmentStatusEnum;
  walletAddress?: string | null;
  areAllTokensUnavailable?: boolean;
  areTokensPartiallyAvailable?: boolean;
  isEcsp?: boolean;
}

const CONSIDERATION_HOURS = 96;

const DashboardInvestmentDetails: FunctionComponent<DashboardInvestmentDetailsProps> = (props) => {
  const {
    className,
    children,
    investment,
    openInvestment,
    investorStatus,
    walletAddress,
    website,
    areAllTokensUnavailable,
    areTokensPartiallyAvailable,
    isEcsp,
    ...restProps
  } = props;

  const [hoursLeft, setHoursLeft] = useState(0);

  useEffect(() => {
    var duration = moment.duration(moment().diff(investment.considerationStartedAt));
    var hours = Math.floor(CONSIDERATION_HOURS - duration.asHours());
    setHoursLeft(hours);
  }, [investment.considerationStartedAt]);

  const hasInvestmentAmountChanged = (): boolean => {
    return toNumber(investment?.investmentTotal || 0) !== toNumber(investment?.investmentTotalFulfilled);
  };

  return (
    <div className={cx('dashboard-investment-details', className)} {...restProps}>
      <Section>
        <InfoGrid columns={4}>
          <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.amount" />}>
            <PlaceholderFallback>
              {investment?.investmentTotal && <Currency>{investment?.investmentTotal}</Currency>}
              {investment?.investmentTotalFulfilled && hasInvestmentAmountChanged() && (
                <>
                  {' ('}
                  <Currency>{investment?.investmentTotalFulfilled}</Currency>
                  {')'}
                </>
              )}
            </PlaceholderFallback>
          </InfoField>
          {openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.productName" />} wordBreak={true}>
              {investment?.productName}
            </InfoField>
          )}
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.sumSettledPayouts" />}>
              <PlaceholderFallback>
                {investment?.sumSettledPayouts && <Currency>{investment.sumSettledPayouts}</Currency>}
              </PlaceholderFallback>
            </InfoField>
          )}
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.unitPrice" />}>
              <PlaceholderFallback>
                {investment?.tokenPrice && <Currency decimals={'*'}>{investment.tokenPrice}</Currency>}
              </PlaceholderFallback>
            </InfoField>
          )}
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.lastSettledPayout" />}>
              <PlaceholderFallback>
                {investment?.lastSettledPayout && <Currency>{investment.lastSettledPayout}</Currency>}
              </PlaceholderFallback>
            </InfoField>
          )}
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.unitNumber" />}>
              {investment?.remainingAmount}
            </InfoField>
          )}
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.nextScheduledPayout" />}>
              <PlaceholderFallback>
                {investment?.nextScheduledPayout && <Date>{investment.nextScheduledPayout}</Date>}
              </PlaceholderFallback>
            </InfoField>
          )}
          {openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.date" />}>
              <PlaceholderFallback>
                {investment?.signedDate && <Date>{investment.signedDate}</Date>}
              </PlaceholderFallback>
            </InfoField>
          )}
          <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.status" />}>
            <StatusTag variant={getInvestorInvestmentStatusVariant(investorStatus)} truncate={true}>
              {isEcsp && hoursLeft >= 0 && investorStatus === InvestorInvestmentStatusEnum.WaitingAcceptance ? (
                hoursLeft === 0 || hoursLeft === 1 ? (
                  <Translate name="dashboardInvestorInvestmentDetails.considerationTimeOneHour" />
                ) : (
                  <Translate
                    name="dashboardInvestorInvestmentDetails.considerationTime"
                    args={[hoursLeft.toString()]}
                  />
                )
              ) : (
                <Translate name={`investorInvestmentStatus.${camelCase(investorStatus)}.status`} />
              )}
            </StatusTag>
          </InfoField>
          {!openInvestment && (
            <InfoField title={<Translate name="dashboardInvestorInvestmentDetails.productType" />}>
              <PlaceholderFallback>
                {investment?.token?.productType && (
                  <Translate
                    name={`productType.${camelCase(convertProductTypeFromApi(investment?.token?.productType))}`}
                  />
                )}
              </PlaceholderFallback>
            </InfoField>
          )}
        </InfoGrid>
      </Section>
      {website && (
        <Section>
          <p>
            <Link href={website} external>
              <Translate name="dashboardInvestorInvestmentDetails.website" />
            </Link>
          </p>
        </Section>
      )}
      <InvestorInvestmentStatusDescription
        status={investorStatus}
        walletAddress={walletAddress}
        signedDate={
          investment?.acquisitionType === InvestmentAcquisitionTypeRecipientEnum.HANDOVER ? investment.signedDate : null
        }
        investmentTotalFulfilled={
          investorStatus === InvestorInvestmentStatusEnum.SuccessPartiallyTransfered
            ? investment?.investmentTotalFulfilled
            : null
        }
        areAllTokensUnavailable={areAllTokensUnavailable}
        areTokensPartiallyAvailable={areTokensPartiallyAvailable}
        productName={investment?.productName}
        isEcsp={isEcsp}
        hoursLeft={hoursLeft}
      />
    </div>
  );
};

export default DashboardInvestmentDetails;
