import React, { FunctionComponent, useCallback, useMemo } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { compact } from 'lodash';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import { useQuestionnaireFormSubmit, useInvestmentQuestionnaireFormMemo } from './hooks';
import { QuestionnaireForm } from './common';
import QuestionnaireDisclaimer from './questionnaire-disclaimer';
import LegacyQuestionnaire from './legacy-questionnaire';
import QuestionnaireFormSegment from './questionnaire-form-segment';
import type { EducationOccupationFields, QuestionnaireAnswers, QuestionnaireFormProps } from './types';
import Section from 'ui/atoms/section';
import FormSegmentHeader from './questionnaire-form-segment/form-segment-header';
import QuestionnaireButtons from './questionnaire-buttons';
import ActionButtons from 'ui/molecules/action-buttons';
import Spacer from 'ui/atoms/spacer';
import * as Styled from './styled';

const InvestmentQuestionnaire: FunctionComponent<QuestionnaireFormProps> = (props) => {
  const {
    className,
    questions = [],
    onSubmit = () => {},
    error,
    editQuestions,
    onEditQuestions,
    loading,
    initialAnswers = [],
    initialEducationOccupation,
    pageNumber,
    questionnairePagesSegments,
    investorId,
    setPageNumber,
    isEcsp,
    goBack,
    ...restProps
  } = props;

  const hasQuestionnaireExistingSelections = useMemo(() => {
    const selections = initialAnswers.map((question) => question.selectedOption);
    return compact(selections).length === selections.length;
  }, [JSON.stringify(initialAnswers)]);

  const initialQuestionnaireValues = mapValues(
    keyBy(initialAnswers, 'id'),
    (answers) => answers.selectedOption || answers.options![0],
  );

  const isLastPage = useMemo(() => {
    return pageNumber === questionnairePagesSegments.length;
  }, [pageNumber, questionnairePagesSegments.length]);

  const isFirstPage = useMemo(() => {
    return pageNumber === 1;
  }, [pageNumber, questionnairePagesSegments.length]);

  const handleOnSubmit = (
    values: { answers: QuestionnaireAnswers[]; educationOccupation: EducationOccupationFields },
    giveNoAnswers?: boolean,
  ) => {
    onSubmit(values, isLastPage || giveNoAnswers);
    if (!isLastPage && !giveNoAnswers) {
      setPageNumber(pageNumber + 1);
    }

    if (giveNoAnswers) {
      setPageNumber(questionnairePagesSegments.length);
    }
  };

  // TODO(geforcefan): this logic belongs to step
  const onFormSubmit = useQuestionnaireFormSubmit({ questions, onSubmit: handleOnSubmit });

  const onSubmitExistingSelections = useCallback(() => {
    onSubmit(
      {
        answers: questions,
        educationOccupation: initialEducationOccupation,
      },
      true,
    );
  }, [onSubmit, questions, initialEducationOccupation]);

  const questionsSegments = useInvestmentQuestionnaireFormMemo(questions);
  const formPage =
    questionnairePagesSegments.filter(({ page }) => page === pageNumber)[0] || questionnairePagesSegments[0];

  if (hasQuestionnaireExistingSelections && pageNumber === 1 && !editQuestions) {
    return (
      <>
        <QuestionnaireDisclaimer hasQuestionnaireExistingSelections={true} isEcsp={isEcsp} noAnswers={true} />
        <Styled.EditQuestionsButton size="large" onClick={onEditQuestions} variant="link">
          <Translate name="questionnaire.reevaluateSelections" />
        </Styled.EditQuestionsButton>
        <ActionButtons>
          <Button
            variant="primary"
            size="large"
            onClick={(e) => {
              onSubmitExistingSelections();
            }}
          >
            <Translate name="questionnaire.selectionsAccurate" />
          </Button>
        </ActionButtons>
      </>
    );
  }

  return (
    <div className={cx('investment-questionnaire-form', className)} {...restProps}>
      <QuestionnaireForm
        initial={{
          ...initialQuestionnaireValues,
          ...initialEducationOccupation,
        }}
        onSubmit={onFormSubmit}
        error={error}
        i18nKey="questionnaire"
      >
        <QuestionnaireForm.ValueProvider>
          {(values) => {
            return (
              <>
                {pageNumber === 1 && (
                  <QuestionnaireDisclaimer
                    hasQuestionnaireExistingSelections={false}
                    isEcsp={isEcsp}
                    noAnswers={!!values.giveNoAnswers}
                  />
                )}
                {!isEcsp && pageNumber === 1 && (
                  <QuestionnaireForm.Group name="giveNoAnswers">
                    <QuestionnaireForm.Checkbox>
                      <Translate name="questionnaire.giveNoAnswers" />
                    </QuestionnaireForm.Checkbox>
                  </QuestionnaireForm.Group>
                )}
                {!values.giveNoAnswers &&
                  (Array.isArray(questionsSegments) ? (
                    <LegacyQuestionnaire questions={questionsSegments} />
                  ) : (
                    !!formPage &&
                    formPage.segments.map(({ index, values: segmentValues, title }) => (
                      <>
                        <Section spacing="medium" key={index}>
                          <FormSegmentHeader segmentIndex={index} segmentId={title} showDescription />
                          {segmentValues.map((questionSegment) => {
                            return (
                              <QuestionnaireFormSegment
                                key={questionSegment}
                                segmentIndex={index}
                                segmentId={questionSegment}
                                questionsSegment={questionsSegments[questionSegment]}
                                isEcsp={isEcsp}
                              />
                            );
                          })}
                        </Section>
                        <Spacer y={2} />
                      </>
                    ))
                  ))}
                <QuestionnaireButtons
                  isLastPage={isLastPage}
                  isFirstPage={isFirstPage}
                  goBack={goBack}
                  error={error}
                  isEcsp={isEcsp}
                  loading={loading}
                />
              </>
            );
          }}
        </QuestionnaireForm.ValueProvider>
      </QuestionnaireForm>
    </div>
  );
};

export default InvestmentQuestionnaire;
