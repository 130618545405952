import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import TransferIdentifier from 'ui/atoms/transfer-identifier';
import InfoGrid, { InfoField } from '../info-grid';

import { UITransfer, TransferStatus, FINAL_TRANSFER_STATUSES } from 'ui/types/transfer';
import Number from 'ui/atoms/number';

export interface TransferCardProps {
  /** Transfer details */
  transfer: UITransfer;

  /** Additional classes. */
  className?: string;
}

const TransferStatusWarning: FunctionComponent<{ status: TransferStatus }> = ({ status }) => {
  if (FINAL_TRANSFER_STATUSES.includes(status)) return <></>;
  return (
    <Hint variant="warning">
      <Translate name="portfolio.transfer.pendingWarning" />
    </Hint>
  );
};

export const TransferCard: FunctionComponent<TransferCardProps> = (props) => {
  const { transfer, className, ...restProps } = props;
  const { status, isIssuance, fromAddress, toAddress, fromWallet, toWallet } = transfer;

  return (
    <div className={cx('transfer-card', className)} {...restProps}>
      <InfoGrid columns={1}>
        <InfoField title={<Translate name="portfolio.transaction" />}>
          <TransferIdentifier
            isIssuance={isIssuance}
            fromAddress={fromAddress}
            fromWallet={fromWallet}
            toAddress={toAddress}
            toWallet={toWallet}
          />
        </InfoField>

        <InfoField title={<Translate name="portfolio.amount" />}>
          <Number>{transfer.amount}</Number>
        </InfoField>

        <InfoField title={<Translate name="portfolio.asset" />} wordBreak={true}>
          {transfer.productName}
        </InfoField>

        <InfoField title={<Translate name="common.status" />}>
          <Translate name={`portfolio.verboseTransferStatus.${transfer.status}`} />
        </InfoField>
      </InfoGrid>

      <TransferStatusWarning status={status} />
    </div>
  );
};

export default TransferCard;
