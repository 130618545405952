export type ButtonSizes = 'xsmall' | 'small' | 'large';

export enum ButtonSizesEnum {
  XSMALL = 'xsmall',
  SMALL = 'small',
  LARGE = 'large',
}

export type ButtonVariants = 'primary' | 'secondary' | 'link';

export type ButtonIconPositions = 'right' | 'left';

interface ButtonSize {
  fontSize: number; // in rem
  lineHeight: number; // in rem
  radius: number; // in px
  paddingX: number; // in rem
  paddingY: number; // in rem
}

export interface ButtonsTheme {
  size: {
    xsmall: ButtonSize;
    small: ButtonSize;
    large: ButtonSize;
  };
  radius: number; // px
  fontWeight: number; // font weight
  fixedWidth: number; // rem
}

export default {
  size: {
    xsmall: {
      fontSize: 0.75,
      lineHeight: 1.33,
      radius: 4,
      paddingX: 0.75,
      paddingY: 0.25,
    },
    small: {
      fontSize: 0.875,
      lineHeight: 1.4,
      radius: 8,
      paddingX: 1,
      paddingY: 0.5,
    },
    large: {
      fontSize: 1,
      lineHeight: 1.4,
      radius: 8,
      paddingX: 1.5,
      paddingY: 1,
    },
  },
  fontSize: 1,
  radius: 8,
  fontWeight: 700,
  fixedWidth: 13,
} as ButtonsTheme;
