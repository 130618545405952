import { AdminInvestor } from 'api';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Checkbox from 'ui/atoms/checkbox';
import Hint from 'ui/atoms/hint';
import Spacer from 'ui/atoms/spacer';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

export interface FutureDeletionProps {
  activePlatform: string;
  investor: AdminInvestor;
  onChangeConfirm: (isConfirmed: boolean) => void;
}

const FutureDeletion: FunctionComponent<FutureDeletionProps> = (props) => {
  const { activePlatform, investor, onChangeConfirm } = props;

  const [isConfirmed, setIsConfirmed] = useState(false);

  const translate = useTranslateWithStringArgs();

  const formatDate = (date: Date) => moment(date).format(`${translate('date.dateFormat')}`);

  useEffect(() => {
    onChangeConfirm(isConfirmed);
  }, [isConfirmed]);

  return (
    <div>
      <Hint variant="warning">
        <div>
          <p>
            <Translate
              name="dashboardIssuerInvestorDetails.futureDeletion.warningTitle"
              as="strong"
              args={[formatDate(investor.earliestDeletion)]}
            />
          </p>
          <div>
            <Translate name="dashboardIssuerInvestorDetails.futureDeletion.warningContent" />
          </div>
        </div>
      </Hint>

      <Spacer y={4} />

      <Translate name="dashboardIssuerInvestorDetails.futureDeletion.whatToDo" as="b" />

      <p>
        <Translate name="dashboardIssuerInvestorDetails.futureDeletion.canDeactivate" />
      </p>

      <Checkbox checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)}>
        <Translate
          name="dashboardIssuerInvestorDetails.futureDeletion.deactivateAndDelete"
          args={[investor!.name, `(${investor!.email})`]}
        />
      </Checkbox>

      <small>
        <Translate name="dashboardIssuerInvestorDetails.futureDeletion.accessDisabled" />
      </small>

      <Spacer y={8} />

      <div>
        <div>
          <Translate name="dashboardIssuerInvestorDetails.futureDeletion.consequenceTitle" as="b" />
        </div>
        <div>
          <Translate name="dashboardIssuerInvestorDetails.futureDeletion.consequenceContent1" />
        </div>
        <div>
          <Translate
            name="dashboardIssuerInvestorDetails.futureDeletion.consequenceContent2"
            args={[activePlatform.toUpperCase()]}
          />
        </div>
      </div>
    </div>
  );
};

export default FutureDeletion;
