import React, { FunctionComponent, ReactNode } from 'react';
import Container from 'ui/atoms/container';
import Header from 'ui/atoms/header';
import Segment from 'ui/atoms/segment';
import usePageTitle from 'hooks/use-page-title';
import Imprint from 'core/components/imprint';
import * as Styled from './styles';
import Grid, { Col } from 'ui/atoms/grid';
import NavBar from 'src/core/nav/navbar/studio-navbar';

interface DefaultLayoutProps {
  children?: ReactNode;
  segment?: boolean;
  pageTitle?: boolean;
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = (props) => {
  const { children, segment = true, pageTitle = true } = props;
  const currentPageTitle = usePageTitle();

  return (
    <Styled.DefaultLayout>
      <Styled.Header>
        <NavBar />
      </Styled.Header>
      <Styled.Main>
        <Container>
          <Grid>
            {segment && (
              <Col desktop={{ width: 6, offset: 3 }} phone={12}>
                {pageTitle && (
                  <Header as="h1" size="small">
                    {currentPageTitle || <>&nbsp;</>}
                  </Header>
                )}
                <Segment raised={true}>{children}</Segment>
              </Col>
            )}

            {!segment && (
              <Col>
                {pageTitle && (
                  <Header as="h1" size="small">
                    {currentPageTitle || <>&nbsp;</>}
                  </Header>
                )}
                {children}
              </Col>
            )}
          </Grid>
        </Container>
      </Styled.Main>
      <Styled.Footer>
        <Imprint color="primary" />
      </Styled.Footer>
    </Styled.DefaultLayout>
  );
};

export default DefaultLayout;
