import { useCallback, useEffect, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import useInvestorMe from 'hooks/use-investor-me';
import { AnyServerError } from 'hooks/use-api-error';
import { useServerConfigSelector } from 'core/config/hooks';
import { BenefitingPerson, Document, InvestorStepEnum, KycInformation } from 'api';
import { PersonToLegalPerson } from 'helper/cast';
import useRegister from 'subapps/investment/pages/register/hooks/use-register';

const useRegisterIdentification = (): {
  showLoading: boolean;
  step?: InvestorStepEnum;
  apiError?: AnyServerError;
  information?: KycInformation;
  apiLoading: boolean;
  redirectLoading: boolean;
  startIdentification: () => void;
  identificationFormDoc: Document | null;
  identificationInPerson: boolean;
  benefitingPersons?: Array<BenefitingPerson>;
} => {
  //state
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const [information, setInformation] = useState<KycInformation | undefined>();
  const [benefitingPersons, setBenefitingPersons] = useState<Array<BenefitingPerson>>();

  //hooks
  const { error: apiError, loading: apiLoading, withApi, makeAuthenticatedApi } = useApiCall();
  const {
    config: { identificationFormDoc, identificationInPerson },
  } = useServerConfigSelector();
  const { step } = useRegister();
  const { investor } = useInvestorMe();

  //apis
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  //variables
  const showLoading = !investor?.id || !step;

  //effects
  useEffect(() => {
    withApi(async () => {
      if (!investor?.id) return null;
      let info = await investorsApi.investorsIdentificationRetrieve({
        id: investor.id,
      });
      setInformation(info);

      if (PersonToLegalPerson(investor?.person) && investor?.id) {
        const results = await investorsApi.investorsBenefitingPersonsList({
          id: investor.id,
        });
        setBenefitingPersons(results);
      }
    });
  }, [investorsApi, investor?.id, withApi]);

  //functions
  const startIdentification = useCallback(() => {
    if (!information || !investor?.id || information?.url === null) return;

    withApi(async () => {
      if (step === InvestorStepEnum.IDENTIFICATION) {
        await investorsApi.investorsIdentificationStartNotificationCreate({
          id: investor.id,
        });
      }

      setRedirectLoading(true);
      document.location.href = information.url as string;
    });
  }, [investorsApi, investor?.id, step, withApi, information]);

  return useMemo(
    () => ({
      showLoading,
      apiLoading,
      redirectLoading,
      apiError,
      step,
      information,
      startIdentification,
      identificationFormDoc,
      identificationInPerson,
      benefitingPersons,
    }),
    [
      showLoading,
      apiLoading,
      redirectLoading,
      apiError,
      step,
      information,
      startIdentification,
      identificationFormDoc,
      identificationInPerson,
      benefitingPersons,
    ],
  );
};

export default useRegisterIdentification;
