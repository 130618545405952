import type { Colors } from 'ui/styles/utils/colors';
import { resolveColor, getColorsMap } from 'ui/styles/utils/colors';
import Padding from 'ui/types/padding';
import Size from 'ui/types/size';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

export interface TagProps {
  $color?: Colors;
  $compact?: boolean;
  $truncate?: boolean;
  $padding?: Padding;
  $raised?: boolean;
  $size: Size;
}

export interface TagContentProps {
  $color?: Colors;
  $textColor?: Colors;
}

function getDefaultStyle({ theme }: ThemedStyledProps<TagProps, DefaultTheme>) {
  return css<TagProps>`
    display: block;
    padding-left: ${theme.tag.paddingX}rem;
    padding-right: ${theme.tag.paddingX}rem;
    border-radius: 4px;
    line-height: 140%;
    white-space: nowrap;
  `;
}

function getRaised({ theme, $raised }: ThemedStyledProps<TagProps, DefaultTheme>) {
  if (!$raised) return null;

  return css<TagProps>`
    box-shadow: ${theme.tag.raisedBoxShadow};
  `;
}

function getCompact() {
  return css`
    display: inline-block;
  `;
}

function getFontSize({ theme, $size }: ThemedStyledProps<TagProps, DefaultTheme>) {
  if (!$size) return null;

  return css<TagProps>`
    font-size: ${theme.typography.sizes[$size]}rem;

    ${query.phone`
      font-size: ${theme.typography.sizes.small}rem;
    `};
  `;
}

function getPadding({ theme, $padding }: ThemedStyledProps<TagProps, DefaultTheme>) {
  if (!$padding) return null;

  const padding = theme.tag.paddingY * theme.padding[$padding];

  return css<TagProps>`
    padding-top: ${padding}rem;
    padding-bottom: ${padding}rem;
  `;
}

function getTruncation({ $truncate }: ThemedStyledProps<TagProps, DefaultTheme>) {
  if (!$truncate) return null;

  return css`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
  `;
}

function getBackgroundColor({ theme, $color }: ThemedStyledProps<TagProps, DefaultTheme>) {
  const themeColor = resolveColor(theme, $color);

  return css`
    background-color: ${themeColor};
  `;
}

function getTextColor({ theme, $textColor, $color }: ThemedStyledProps<TagContentProps, DefaultTheme>) {
  const { minor } = getColorsMap(theme, $color);
  const textColor = $textColor || minor;

  return css`
    color: ${textColor};
  `;
}

export const Tag = styled.span<TagProps>`
  ${getDefaultStyle};
  ${getRaised};
  ${getCompact};
  ${getFontSize};
  ${getPadding};
  ${getTruncation};
  ${getBackgroundColor};
`;

export const Content = styled.span<TagContentProps>`
  ${getTextColor};
`;
