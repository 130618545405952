import React from 'react';
import { PopoverPositions } from 'ui/molecules/popover/types';
import * as Styled from './styled';
import type { Colors } from 'ui/styles/utils/colors';

type ArrowContainerProps = {
  childRect: DOMRect;
  popoverRect: DOMRect;
  position: PopoverPositions;
  color?: Colors;
  size?: number;
  className?: string;
  containerClassName?: string;
};

export const ArrowContainer: React.FunctionComponent<ArrowContainerProps> = ({
  childRect,
  popoverRect,
  position,
  color = 'secondary',
  size = 10,
  containerClassName,
  className,
  children,
}) => {
  return (
    <Styled.ArrowContainer $position={position} $size={size} className={containerClassName}>
      <Styled.Arrow
        $color={color}
        $size={size}
        $childRect={childRect}
        $popoverRect={popoverRect}
        $position={position}
        className={className}
      />
      {children}
    </Styled.ArrowContainer>
  );
};
