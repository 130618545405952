import React from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Markdown from 'ui/atoms/markdown';
import BoxedContent from 'ui/molecules/boxed-content';
import LoadingRing from 'ui/atoms/loading-ring';
import { useServerConfigSelector } from 'core/config/hooks';

const InvestorFaqPage = () => {
  const {
    config: { faq },
    loading,
    error,
  } = useServerConfigSelector();

  if (loading) {
    return <LoadingRing />;
  }

  if (error) return null;

  return (
    <WideContent>
      {faq.map(({ title, info }, index) => (
        <BoxedContent title={title} headerSize="large" key={index}>
          <Markdown headerShift={2} source={info} />
        </BoxedContent>
      ))}
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorFaqPage, AppType.INVESTOR);
