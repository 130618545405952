import React, { FunctionComponent, useContext, useMemo } from 'react';

import { Document } from 'ui/types/document';
import Translate from 'ui/atoms/translate';
import DocumentModal from 'libraries/document-modal';
import { PlatformUrl } from 'ui/types/urls';
import Section from 'ui/atoms/section';
import { CreateAccountForm } from 'libraries/wizard/components/account-setup/create-account/helpers';
import { PlatformContext } from 'ui/platform/provider';
import { Platform, Platforms } from 'platforms';
import Link from 'ui/atoms/link';
import Header from 'ui/atoms/header';
import {
  CreateAccountDocument,
  CreateAccountDocumentGroupType,
} from 'libraries/wizard/components/account-setup/create-account/types';

export interface AcceptCreateAccountDocumentsProps {
  documents: {
    effectaAccountSetupDocument: Document;
    effectaTermsConditions: Document;
  };
}

const AcceptCreateAccountDocuments: FunctionComponent<AcceptCreateAccountDocumentsProps> = ({ documents }) => {
  // TODO(geforcefan): remove this asap, need better solution than checking here for platform
  const { activePlatform } = useContext(PlatformContext);

  // todo: what is this? no platform specific nonsense here
  const isPlatform360 = ([Platform.THREE_SIXTY_X] as Platforms[]).includes(activePlatform);

  const acceptCheckboxes: {
    group: CreateAccountDocumentGroupType;
    checkboxes: CreateAccountDocument[];
  }[] = useMemo(
    () => [
      {
        group: 'liabilityUmbrella',
        checkboxes: [
          {
            name: 'effectaAccountSetupDocument',
            documentList: [isPlatform360 ? PlatformUrl.TERMS_AND_CONDITIONS : documents.effectaAccountSetupDocument],
            display: isPlatform360 || (!!documents.effectaAccountSetupDocument && !!documents.effectaTermsConditions),
          },
          {
            name: 'effectaTermsConditions',
            documentList: [isPlatform360 ? PlatformUrl.TERMS_AND_CONDITIONS : documents.effectaTermsConditions],
            display: isPlatform360 || (!!documents.effectaAccountSetupDocument && !!documents.effectaTermsConditions),
          },
        ],
      },
      {
        group: 'platformTerms',
        checkboxes: [
          {
            name: 'termsOfService',
            documentList: [PlatformUrl.TERMS_AND_CONDITIONS, PlatformUrl.PRIVACY_POLICY],
            display: true,
          },
        ],
      },
      {
        group: 'pepAndOwnAccount',
        checkboxes: [
          { name: 'benefitingPerson', documentList: [], display: true },
          { name: 'pep', documentList: [], display: true },
        ],
      },
    ],
    [documents],
  );

  return (
    <>
      {acceptCheckboxes.map(({ group, checkboxes }) => (
        <Section key={group} spacing="small">
          <Header spacing="small" size="xsmall">
            <Translate name={`createAccount.acceptGroupType.${group}`} />
          </Header>
          {checkboxes.map(({ name, documentList, display }) => {
            if (!display) return null;
            return (
              <CreateAccountForm.Group name={name} key={name} required={true} spacing="small">
                <CreateAccountForm.Checkbox key={name}>
                  <Translate
                    name={`createAccount.accept.${name}`}
                    args={documentList.map((document, i) => {
                      if (typeof document === 'string') {
                        return (text) => (
                          <Link key={i} platformUrl={document} inline external>
                            {text}
                          </Link>
                        );
                      }

                      return (text) => (
                        <DocumentModal key={i} {...document}>
                          {text}
                        </DocumentModal>
                      );
                    })}
                  />
                </CreateAccountForm.Checkbox>
              </CreateAccountForm.Group>
            );
          })}
        </Section>
      ))}
    </>
  );
};

export default AcceptCreateAccountDocuments;
