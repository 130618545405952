import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

const useObservedDelta = (value: any, callback: () => void) => {
  const valueRef = useRef(value);
  const hasValueChanged = !isEqual(value, valueRef.current);
  useEffect(() => {
    if (hasValueChanged) {
      valueRef.current = value;
      callback();
    }
  }, [callback, value, hasValueChanged]);
};
export default useObservedDelta;
