import React, { FunctionComponent, ReactNode } from 'react';
import Spacing from 'ui/types/spacing';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';
import { variantToNotificationColor } from 'src/ui/helper/variant';

export interface HintProps {
  /** Primary content */
  children?: ReactNode;

  /** Additional classes */
  className?: string;

  /**
   * A hint has one of a variety of visual variants
   *
   * @expandType true
   */
  variant: ColorVariants;

  /** Spacing at the bottom */
  spacing?: Spacing;

  withoutIcon?: boolean;
}

export const Hint: FunctionComponent<HintProps> = (props) => {
  const { className, children, variant, spacing, withoutIcon, ...restProps } = props;

  const variantToIconName = (variant: ColorVariants) => {
    switch (variant) {
      case 'success':
        return 'check';
      case 'warning':
        return 'exclamation-circle';
      case 'danger':
        return 'cross';
      case 'info':
        return 'info-circle';
      case 'primary':
        return 'info';
      default:
        return 'info';
    }
  };

  return (
    <Styled.Hint
      $spacing={spacing}
      $color={variantToNotificationColor(variant)}
      inverted={true}
      padded={'tiny'}
      className={cx('hint', className)}
      {...restProps}
    >
      {!withoutIcon && <Styled.HintIcon name={variantToIconName(variant)} size="large" $variant={variant} />}
      {children}
    </Styled.Hint>
  );
};

export default Hint;
