import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { AddIdentificationFields } from '../..';
import Section from 'ui/atoms/section';
import Grid, { Col } from 'ui/atoms/grid';

export interface ConfirmIdentificationRegisteredAddressProps {
  identification: AddIdentificationFields;
}

const ConfirmIdentificationRegisteredAddress: FunctionComponent<ConfirmIdentificationRegisteredAddressProps> = (
  props,
) => {
  const { identification } = props;

  return (
    <Section spacing="medium">
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitleRegisteredAddress" />
        </strong>
      </p>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.registrationAddress.street" />
        </Col>
        <Col width={6} spacing="small">
          {identification.street}
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.registrationAddress.city" />
        </Col>
        <Col width={6} spacing="small">
          {identification.city}
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.registrationAddress.country" />
        </Col>
        <Col width={6} spacing="small">
          <Translate name={`countries.${identification.country}`} />
        </Col>
      </Grid>
    </Section>
  );
};

export default ConfirmIdentificationRegisteredAddress;
