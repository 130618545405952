import styled from 'styled-components';
import { query } from 'ui/styles/queries';

const navItemDefaults = {
  color: 'rgba(0,0,0,.5)',
  hoverColor: 'rgba(0,0,0,.7)',
};

export const NavItem = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: ${navItemDefaults.color};
  text-decoration: none;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colorVariants.primary};
  }

  ${query.phoneTablet`
    padding: 0;
    color: ${({ theme }) => theme.colorVariants.primary};

    &:hover {
      color: ${navItemDefaults.hoverColor};
    }

  `}
`;
