import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import useGoTo from 'hooks/use-go-to';
import React, { FunctionComponent, ReactNode } from 'react';
import WizardHeader from 'libraries/wizard/wizard-header';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import Header from 'ui/atoms/header';

export interface IdentificationPendingProps {
  title?: ReactNode;
}

const IdentificationPending: FunctionComponent<IdentificationPendingProps> = (props) => {
  const { title } = props;

  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <Hint variant="success">
        <Translate name="kycPending.hint" />
      </Hint>
      <p>
        <strong>
          <Translate name="kycPending.subtitle" />
        </strong>
      </p>
      <p>
        <Translate name="kycPending.content" />
      </p>
      <Button size="large" variant="link" fluid={true} onClick={() => goToOverview()}>
        <Translate name="kycPending.button" />
      </Button>
    </>
  );
};

export default IdentificationPending;
