import React, { useContext } from 'react';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { useFilterContext, useFilterOption } from 'ui/molecules/filtering/hooks';
import * as Styled from './styled';
import type { FunctionComponent } from 'react';
import type { FilterSearch } from '../types';
import type { InputProps } from 'ui/atoms/input';
import FilterContext from '../filter-context';
import debounce from 'lodash/debounce';

export const Search: FunctionComponent<FilterSearch> = (props) => {
  const { input, name, label, defaultField, fieldNames, ...restProps } = props;
  const { loading } = useFilterContext();
  const { value, setValue } = useFilterOption(props, '');
  const translate = useTranslateWithStringArgs();
  const { setApplyFilters } = useContext(FilterContext);

  const onChange = (value: string) => {
    setValue(value);
    debouncedSearch();
  };

  const applySearchFilter = () => {
    setApplyFilters(true);
  };

  const debouncedSearch = debounce(applySearchFilter, 500);

  return (
    <Styled.Search
      icon={loading ? 'spinner' : 'search'}
      loading={loading}
      value={value}
      placeholder={translate('filters.search.placeholder', {
        args: [typeof label === 'string' ? label : ''],
      })}
      onChange={(e: any) => onChange(e.target.value)}
      alignIcon="left"
      height="small"
      iconSize="regular"
      {...(restProps as InputProps)}
    />
  );
};

export default Search;
