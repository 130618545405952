import { useContext, useEffect, useState } from 'react';
import { PlatformContext } from 'ui/platform/provider';
import { CustomImages } from 'ui/assets/images/types';

export type UseAssetsType = {
  defaultImages: CustomImages;
  initialImages: CustomImages;
};

export function useAssets({ defaultImages, initialImages }: UseAssetsType) {
  const { activePlatform, initialPlatform } = useContext(PlatformContext);

  const [images, setImages] = useState<CustomImages>({ ...defaultImages, ...initialImages });
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    if (activePlatform !== initialPlatform) return;

    setImages({ ...defaultImages, ...initialImages });
    setImagesLoaded(true);
    return;
  }, [activePlatform]);

  return {
    images,
    setImages,
    imagesLoaded,
    setImagesLoaded,
    defaultImages,
    initialImages,
  };
}
