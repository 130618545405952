import { ButtonSizes, ButtonSizesEnum } from 'ui/styles/themes/buttons';

export const getResponsiveButtonSize = (size: ButtonSizes, isPhone: boolean = false) => {
  switch (size) {
    case ButtonSizesEnum.XSMALL:
      return ButtonSizesEnum.XSMALL;
    case ButtonSizesEnum.SMALL:
      return isPhone ? ButtonSizesEnum.XSMALL : ButtonSizesEnum.SMALL;
    case ButtonSizesEnum.LARGE:
      return isPhone ? ButtonSizesEnum.SMALL : ButtonSizesEnum.LARGE;
    default:
      return isPhone ? ButtonSizesEnum.XSMALL : ButtonSizesEnum.SMALL;
  }
};
