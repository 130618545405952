import React, { FunctionComponent } from 'react';
import usePlatformImage from 'ui/hooks/use-platform-image';
import Spacing from 'ui/types/spacing';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';

export type LogoVariant = 'header' | 'studio' | 'investment';

export interface LogoProps {
  /**
   * Logo type ("normal", i.e. dark, or "inverted", i.e. light version of the logo).
   *
   * @default "normal"
   * */
  logoType?: 'normal' | 'inverted';

  /** Spacing at the bottom. */
  spacing?: Spacing;

  borderRight?: boolean;

  /** Logo variant differ in height. */
  variant: LogoVariant;

  /** Logo can be hidden. */
  hide?: boolean;

  /** Additional classes. */
  className?: string;
}

export const Logo: FunctionComponent<LogoProps> = (props) => {
  const { logoType = 'normal', borderRight, spacing, variant, hide, className, ...restProps } = props;

  const logoImage = usePlatformImage(logoType === 'normal' ? 'logo' : 'logoWhite');

  return (
    <Styled.Logo
      $variant={variant}
      $borderRight={borderRight}
      $spacing={spacing}
      $hide={hide}
      src={logoImage}
      alt="Brand"
      className={cx('logo', className)}
      {...restProps}
    />
  );
};

export default Logo;
