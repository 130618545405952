import React, { FunctionComponent, useCallback } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WideContent from 'core/layout/content/wide-content';
import IssuerInvestmentsTable from 'apps/issuer/shared/investments-table';
import { hasDownloadStatisticsPermission } from 'apps/issuer/helpers/permissions';
import DownloadInvestmentData from './download-investment-data';
import { useIssuerSelector } from 'apps/issuer/hooks';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';
import DownloadPlatformInvestmentData from './download-platform-investment-data';
import Spacer from 'src/ui/atoms/spacer';
import Box from 'src/ui/polymorphs/box';
import { hasDistributionDownloadPlatformStatisticsDataPermission } from 'apps/issuer/helpers/distributionPlatformPermissions';
import { useCurrentUserSelector } from 'src/core/auth/hooks';

const IssuerInvestmentsPage: FunctionComponent = () => {
  const { tokens } = useIssuerSelector();
  const { currentUser } = useCurrentUserSelector();

  const hasDownloadPermission = hasDownloadStatisticsPermission(tokens);
  const hasDownloadPlatformStatisticsPermission = hasDistributionDownloadPlatformStatisticsDataPermission(
    currentUser?.associatedDistributionPlatforms || [],
  );

  const renderDownloadButtons = useCallback(() => {
    if (!hasDownloadPermission && !hasDownloadPlatformStatisticsPermission) return null;

    return (
      <Box>
        {hasDownloadPermission && <DownloadInvestmentData />}
        {hasDownloadPlatformStatisticsPermission && (
          <>
            <Spacer x={2} />
            <DownloadPlatformInvestmentData />
          </>
        )}
      </Box>
    );
  }, []);

  return (
    <WideContent title={<Translate name="dashboardInvestorInvestments.title" />} headerPanel={renderDownloadButtons()}>
      <IssuerInvestmentsTable
        filters={<IssuerInvestmentsFilters />}
        defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
        defaultLimit={50}
      />
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerInvestmentsPage, AppType.ISSUER);
