export interface LogoTheme {
  width: {
    phone: {
      header: any;
      studio: any;
      investment: any;
    };
    desktop: {
      header: any;
      studio: any;
      investment: any;
    };
  };
}

export default {
  width: {
    phone: {
      header: '100px',
      studio: '100px',
      investment: '100px',
    },
    desktop: {
      header: '200px',
      studio: '200px',
      investment: '200px',
    },
  },
} as LogoTheme;
