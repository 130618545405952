import { resolveColor } from 'ui/styles/utils/colors';
import styled, { css } from 'styled-components';
import Icon from 'ui/atoms/icon';
import { query } from 'ui/styles/queries';

export const ModalHeader = styled.div<{ $overlap?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.modal.headerBackgroundColor};
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  flex-shrink: 0;
  z-index: 1;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  ${query.phone`
    font-size: 1rem;
    line-height: 22.4px;
  `};

  ${({ $overlap }) =>
    $overlap &&
    css`
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      background-color: transparent;
      color: ${({ theme }) => theme.colorVariants.dark};
    `}
`;

export const CloseIcon = styled(Icon)`
  &:hover {
    color: ${({ theme }) => resolveColor(theme, 'grayHover')};
    fill: ${({ theme }) => resolveColor(theme, 'grayHover')};
  }
`;
