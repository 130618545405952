import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import * as Styled from './styled';

export interface CheckboxProps {
  id?: string;

  /** Primary content. */
  children?: ReactNode;

  /** Primary content when children is not set */
  content?: ReactNode;

  /** Additional classes */
  className?: string;

  /** Checked */
  checked?: boolean;

  /** Required */
  required?: boolean;

  onChange?: React.FormEventHandler<this>;

  onBlur?: React.FormEventHandler<this>;

  /** Read only */
  readOnly?: boolean;

  /** Disabled */
  disabled?: boolean;

  /** Valid */
  valid?: boolean;
}

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { className, children, id, content, valid, disabled, checked, ...restProps } = props;

  const inputID = useMemo(() => id || uuid(), [id]);

  return (
    <Styled.CheckboxContainer className="checkbox-container">
      <Styled.Label htmlFor={inputID} $disabled={disabled}>
        <Styled.Checkbox $checked={checked} $disabled={disabled} $isInvalid={valid === false} className="checkbox" />
        <Styled.CheckboxCheckmark $checked={checked} $disabled={disabled} />
        <Styled.CheckboxInput
          type="checkbox"
          id={inputID}
          className={className}
          disabled={disabled}
          checked={checked}
          {...restProps}
        />
      </Styled.Label>
      <Styled.Content>{children || content}</Styled.Content>
    </Styled.CheckboxContainer>
  );
};

export default Checkbox;
