import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import { AdminApi } from 'api';
import useApiCall from 'src/hooks/use-api-call';
import { StateValues } from 'react-use-form-state';
import Hint from 'ui/atoms/hint';

export interface AddWalletFiedls {
  address: string;
}

export interface AddWalletProps {
  investorId: string;
  onAddNewWallet: () => void;
  onClose: () => void;
}

export const AddWalletForm = makeModalForm<AddWalletFiedls>();

const AddWalletModal: FunctionComponent<AddWalletProps> = (props) => {
  const { investorId, onAddNewWallet, onClose } = props;

  const [doesWalletExists, setWalletExits] = useState(false);

  const { withApi, makeAuthenticatedApi } = useApiCall();
  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onSubmit = useCallback(
    (fieldsValues: StateValues<AddWalletFiedls>) => {
      setWalletExits(false);

      withApi(async () => {
        try {
          await adminApi.adminInvestorsWalletsEthGenericCreate({
            id: investorId,
            adminGenericWalletCreationRequest: { address: fieldsValues.address },
          });
          onAddNewWallet();
        } catch (e) {
          if (e.info?.error_codes?.includes('unique')) {
            setWalletExits(true);
          }
        }
      });
    },
    [withApi, adminApi],
  );

  return (
    <Modal onClose={onClose}>
      <AddWalletForm
        i18nKey="dashboardWalletInfo"
        onSubmit={(values: StateValues<AddWalletFiedls>) => {
          onSubmit(values);
        }}
      >
        <ModalHeader>
          <Translate name="dashboardWalletInfo.header" />
        </ModalHeader>
        <ModalContent>
          <AddWalletForm.Group name="address" required={true}>
            {AddWalletForm.Validators.EthereumAddress()}
            {AddWalletForm.Validators.TokenAddressChecksum()}
            <AddWalletForm.Input />
          </AddWalletForm.Group>

          {doesWalletExists && (
            <Hint variant="danger">
              <Translate name="dashboardWalletInfo.walletExists" />
            </Hint>
          )}
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'close',
                content: <Translate name="common.close" />,
                size: 'large',
                onClick: onClose,
              },
              {
                name: 'confirm',
                content: <Translate name="common.confirm" />,
                variant: 'primary',
                size: 'large',
                type: 'submit',
              },
            ]}
          />
        </ModalFooter>
      </AddWalletForm>
    </Modal>
  );
};

export default AddWalletModal;
