import React, { createContext, FunctionComponent } from 'react';
import type { Colors } from 'ui/styles/utils/colors';

interface ModalContextValues {
  disableClose: boolean;
  onClose: () => void;
  bgColor: Colors;
}

export const ModalContext = createContext<ModalContextValues>({
  disableClose: false,
  onClose: () => {},
  bgColor: 'white',
});

export const ModalProvider: FunctionComponent<{
  disableClose: boolean;
  onClose: () => void;
  bgColor: Colors;
}> = ({ children, bgColor, disableClose, onClose }) => {
  return <ModalContext.Provider value={{ disableClose, onClose, bgColor }}>{children}</ModalContext.Provider>;
};

export default {
  ModalContext,
  ModalProvider,
};
