import Grid, { Col } from 'ui/atoms/grid';
import styled from 'styled-components';
import { QuestionnaireForm } from '../../../common';

const questionnaireSplitFormSegment = `
  margin: 18px 0;
`;

const centerText = `
  display: flex;
  align-items: center;
`;

export const QuestionnaireSplitFormRow = styled(Grid)`
  ${questionnaireSplitFormSegment}
`;

export const QuestionnaireDesktopCol = styled(Col)`
  padding: 0;
  && {
    ${centerText}
  }
`;

export const SelectGroupContainer = styled(QuestionnaireForm.Group)`
  width: 100%;
`;
