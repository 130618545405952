import useTranslate from 'src/ui/hooks/use-translate';
import { handleError } from 'src/ui/helper/error-handling';
import { AnyServerError } from 'src/hooks/use-api-error';

export const useServerErrorsHandling = (error: AnyServerError | undefined) => {
  const translate = useTranslate();

  const sourceWalletErrorConfig = {
    wallet_receiving_disabled: {
      field: 'source_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_not_confirmed: {
      field: 'source_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_target2_bic_missing: {
      field: 'source_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_not_found: {
      field: 'source_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    invalid: {
      field: 'source_wallet_address',
      translateKey: 'errors.walletSourceInsufficient',
    },
  };

  const targetWalletErrorConfig = {
    wallet_receiving_disabled: {
      field: 'target_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_not_confirmed: {
      field: 'target_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_target2_bic_missing: {
      field: 'target_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
    wallet_not_found: {
      field: 'target_wallet_address',
      translateKey: 'errors.walletTarget2Bic',
    },
  };

  const { getErrorForField: getSourceWalletError } = handleError({
    error,
    translate,
    specialCodes: sourceWalletErrorConfig,
  });

  const { getErrorForField: getTargetWalletError } = handleError({
    error,
    translate,
    specialCodes: targetWalletErrorConfig,
  });

  const { getErrorForField: getErrorForFieldCorrelationId } = handleError({
    error,
    translate,
    specialCodes: {
      unique: {
        field: 'correlation_id',
        translateKey: 'errors.correlationId',
      },
    },
  });

  return {
    getSourceWalletError,
    getTargetWalletError,
    getErrorForFieldCorrelationId,
  };
};
