import React, { FunctionComponent, useMemo } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { lookup } from 'mime-types';
import { Document } from 'ui/types/document';
import * as Styled from './styled';
import Spacing from 'ui/types/spacing';

export interface DocumentViewProps extends Partial<Document> {
  /** Additional classes. */
  className?: string;

  /** Add black border */
  bordered?: boolean;

  /**
   * Add spacing (margin bottom), if not last child
   *
   * @default medium
   */
  spacing?: Spacing;

  height?: 'medium';
}

// Make sure to only add non-active file types here, or types which don't cross origin
// boundaries
const allowedTypes = ['application/pdf'];

export const DocumentView: FunctionComponent<DocumentViewProps> = (props) => {
  const { className, file, height, spacing = 'medium', bordered = false, ...restProps } = props;

  const type = useMemo(() => lookup(file || ''), [file]) || 'application/pdf';

  if (!allowedTypes.includes(type)) {
    throw new Error('Security check failed: MIME type not allowed: ' + file);
  }

  return (
    <Styled.DocumentView
      className={cx('document-view', className)}
      $borderd={bordered}
      $height={height}
      $spacing={spacing}
      {...restProps}
    >
      <embed src={file} type={type} />
    </Styled.DocumentView>
  );
};

export default DocumentView;
