import React, { FunctionComponent } from 'react';
import useTranslate from 'ui/hooks/use-translate';
import Section from 'ui/atoms/section';
import { CreateAccountForm } from 'libraries/wizard/components/account-setup/create-account/helpers';
import Translate from 'ui/atoms/translate';
import Grid, { Col } from 'ui/atoms/grid';
import { LegalEntityTypeEnum } from 'api';
import Header from 'ui/atoms/header';
import useCountries from 'src/hooks/use-countries';

export interface LegalPersonProps {
  country: string | undefined;
}

const LegalPerson: FunctionComponent<LegalPersonProps> = ({ country }) => {
  const translate = useTranslate();

  return (
    <Section>
      <Header size="small">
        <Translate name="createAccount.headers.company.companyInformation" />
      </Header>
      <Grid>
        <Col width={8} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalCompanyName" required={true}>
            <CreateAccountForm.Input autoFocus={true} />
          </CreateAccountForm.Group>
        </Col>
        <Col width={4} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalEntityType" required={true}>
            <CreateAccountForm.Select
              options={Object.values(LegalEntityTypeEnum).map((legalType) => ({
                value: legalType,
                label: legalType,
              }))}
            ></CreateAccountForm.Select>
          </CreateAccountForm.Group>
        </Col>
      </Grid>
      <Grid>
        <Col width={6} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalCommercialRegister" required={true}>
            <CreateAccountForm.Input
              placeholder={translate('personalInformation.commercialRegisterPlaceholder') as string}
            />
          </CreateAccountForm.Group>
        </Col>

        <Col width={6} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalCommercialRegisterNumber" required={true}>
            <CreateAccountForm.Input />
          </CreateAccountForm.Group>
        </Col>
      </Grid>
      <CreateAccountForm.Group name="legalStreet" required={true}>
        <CreateAccountForm.Input />
      </CreateAccountForm.Group>
      <Grid>
        <Col width={4} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalZip" required={true}>
            <CreateAccountForm.Input />
          </CreateAccountForm.Group>
        </Col>

        <Col width={8} tablet={12} phone={12}>
          <CreateAccountForm.Group name="legalCity" required={true}>
            <CreateAccountForm.Input />
          </CreateAccountForm.Group>
        </Col>
      </Grid>
      <CreateAccountForm.Group name="legalCountry" required={true}>
        <CreateAccountForm.Select options={useCountries()} value={country}></CreateAccountForm.Select>
      </CreateAccountForm.Group>
    </Section>
  );
};

export default LegalPerson;
