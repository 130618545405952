import React, { FunctionComponent, useEffect, useState } from 'react';

import { useIsLoggedInSelector } from 'core/auth/hooks';
import { redirectLoggedIn } from 'core/auth/actions';

import { useDispatch } from 'store/hooks';

interface ShouldNotAuthenticatedPageProps {}

const PublicPage: FunctionComponent<ShouldNotAuthenticatedPageProps> = ({ children }) => {
  const [hasRedirected, setHasRedirected] = useState(false);
  const isLoggedIn = useIsLoggedInSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasRedirected) return;

    if (isLoggedIn) {
      setHasRedirected(true);

      dispatch(redirectLoggedIn());
    }
  }, [isLoggedIn, dispatch, hasRedirected]);

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  return null;
};

export default PublicPage;
