import { string } from 'yup';
import Campaign from './campaign';
import Investment from './investment';
import Register from './register';
import InvitationsContinue from './invitations-continue';
import { ComponentRouting, RouteDefinitions } from 'core/routing/types';

export const ROUTES = {
  campaign: 'campaign',
  register: 'register',
  investment: 'investment',
  invitationsContinue: 'invitationsContinue',
};

export const CAMPAIGN = {
  path: '/campaign/:campaignId',
  exact: true,
  condition: () => true,
  requirements: {
    campaignId: string().required(),
  },
};

export const INVESTMENT = {
  path: '/investment/:invitationId',
  exact: true,
  condition: () => true,
  requirements: {
    invitationId: string().required(),
  },
};

export const INVITATIONS_CONTINUE = {
  path: '/invitation/continue',
  exact: true,
  condition: () => true,
};

export const REGISTER = {
  path: '/register',
  exact: true,
  condition: () => true,
};

export const INVESTMENT_ROUTES: RouteDefinitions = {
  [ROUTES.campaign]: CAMPAIGN,
  [ROUTES.investment]: INVESTMENT,
  [ROUTES.register]: REGISTER,
  [ROUTES.invitationsContinue]: INVITATIONS_CONTINUE,
};

export const INVESTMENT_PATHS = Object.values(INVESTMENT_ROUTES).map((route) => route.path);

export const INVESTMENT_COMPONENT_ROUTES: ComponentRouting = {
  [ROUTES.campaign]: Campaign,
  [ROUTES.investment]: Investment,
  [ROUTES.invitationsContinue]: InvitationsContinue,
  [ROUTES.register]: Register,
};
