import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export interface SectionProps {
  $spacing?: Spacing;
}

function getDefaultSectionStyle({ theme, $spacing }: ThemedStyledProps<SectionProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css<SectionProps>`
    position: relative;
    margin-bottom: ${2 * theme.spacing[$spacing]}rem;
  `;
}

export const Section = styled.div<SectionProps>`
  ${getDefaultSectionStyle};
`;
