import React, { useCallback } from 'react';
import * as Styled from './styled';
import type { PolymorphicComponentPropWithRef, PolymorphicRef, As } from 'ui/helper/get-element-type';
import type { TextAlignOptions, TextFormattingOptions } from './styled';
import type { Colors } from 'ui/styles/utils/colors';
import type { Spacing } from 'ui/styles/themes/spacing';

export type TextTypes =
  | 'span'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'a'
  | 'blockquote'
  | 'b'
  | 'strong'
  | 'i'
  | 'em'
  | 'u'
  | 'mark'
  | 'small'
  | 'del'
  | 'ins'
  | 'sub'
  | 'sup'
  | 'div';

export type TextAsProps = {
  children: React.ReactNode;
  format?: Array<TextFormattingOptions>;
  align?: TextAlignOptions;
  indent?: number;
  color?: Colors;
  textTransform?: string;
  spacing?: Spacing;
  fontSize?: number;
  className?: string;
  charLimit?: number;
  wordBreak?: string;
  lineClamp?: number;
} & As<TextTypes>;

export type TextComponentProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, TextAsProps>;

export type TextComponent = <C extends React.ElementType = 'span'>(
  props: TextComponentProps<C>,
) => React.ReactElement | null;

const Text: TextComponent = React.forwardRef(
  <C extends React.ElementType = 'span'>(
    {
      as,
      children,
      format,
      align,
      indent,
      spacing,
      charLimit,
      textTransform,
      color,
      fontSize,
      wordBreak,
      lineClamp,
      className,
      ...rest
    }: TextComponentProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const getCharLimit = useCallback(() => {
      if (typeof children !== 'string' || !charLimit) return children;

      let text = children;
      if (children.length > charLimit) {
        text = text.substring(0, charLimit - 3);
        text += '...';
      }

      return text;
    }, [children, charLimit]);

    return (
      <Styled.Text
        as={as || 'span'}
        ref={ref}
        $format={format}
        $indent={indent}
        $align={align}
        $color={color}
        $spacing={spacing}
        $textTransform={textTransform}
        $fontSize={fontSize}
        $wordBreak={wordBreak}
        className={className}
        {...rest}
      >
        {getCharLimit()}
      </Styled.Text>
    );
  },
);

export default Text;
