export type IconSizes = 'xsmall' | 'small' | 'regular' | 'large' | 'xlarge';

export interface IconsTheme {
  sizes: {
    xsmall: number;
    small: number;
    regular: number;
    large: number;
    xlarge: number;
  };
}

export default {
  sizes: {
    xsmall: 0.6,
    small: 0.75,
    regular: 1,
    large: 1.5,
    xlarge: 2,
  },
} as IconsTheme;
