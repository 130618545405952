import React, { useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import { useApiCall } from 'hooks/use-api-call';
import { InvestorsApi, WizardApi } from 'api/apis';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';
import { InvestorOverview, StepEnum } from 'api/models';
import Currency from 'ui/atoms/currency';
import LoadingRing from 'ui/atoms/loading-ring';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Col from 'ui/atoms/grid/col';
import DashboardInvestmentsTable from 'src/apps/investor/shared/dashboard-investments-table';
import ActionButtons from 'ui/molecules/action-buttons';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import Link from 'core/routing/link';
import Grid from 'ui/atoms/grid';
import OpenTransactions from './open-transactions';
import PendingWalletSetup from './pending-wallet-setup';
import NoIdentification from './no-identification';
import PendingIdentification from './pending-identification';
import FailedIdentification from './failed-identification';
import ContinueRegistration from './continue-registration';
import { useInvestorHasInvestmentsSelector } from 'apps/investor/resources/investments/hooks';
import * as Styled from './styled';
import useTranslate from 'src/ui/hooks/use-translate';

const InvestorOverviewPage = () => {
  const { investor: me, loading: investorLoading } = useInvestorSelector();
  const { hasInvestments } = useInvestorHasInvestmentsSelector();

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);

  const [investorOverview, setInvestorOverview] = useState<InvestorOverview>();

  const [wizardStepUnfinished, setWizardStepUnfinished] = useState<StepEnum>();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const wizardApi: WizardApi = useMemo(() => makeAuthenticatedApi(WizardApi), [makeAuthenticatedApi]);

  const goToInvestorInvestment = useGoTo(INVESTOR_ROUTES.investment);
  const goToInvesment = useGoTo(INVESTMENT_ROUTES.investment);

  const translate = useTranslate();

  useEffect(() => {
    if (!me?.id) {
      return;
    }
    withApi(async () => {
      const investorOverview = await investorsApi.investorsOverviewRetrieve({
        id: me.id,
      });
      setInvestorOverview(investorOverview);

      if (investorOverview.transferInvitation) {
        const wizard = await wizardApi.wizardRetrieve({ code: investorOverview.transferInvitation });
        setWizardStepUnfinished(wizard.step);
      }
    });
  }, [withApi, investorsApi, wizardApi, me?.id]);

  if (investorLoading || loading || !investorOverview) {
    return <LoadingRing />;
  }

  return (
    <WideContent title={<Translate name="dashboard.investorOverview" />}>
      <div>
        <Grid spacing="large">
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true}>
                <Translate name="dashboardInvestorOverview.investments" />
              </Header>
              <Styled.HeaderRow>{investorOverview?.numberOfInvestments}</Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true}>
                <Translate name="dashboardInvestorOverview.investmentSum" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{investorOverview?.totalInvestedSum}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true}>
                <Translate name="dashboardInvestorOverview.totalEarnings" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{investorOverview?.totalPayouts}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
        </Grid>
      </div>

      {!hasInvestments && <ContinueRegistration />}
      {wizardStepUnfinished === StepEnum.SELECT_WALLET && (
        <PendingWalletSetup onClick={() => goToInvesment({ invitationId: investorOverview.transferInvitation })} />
      )}
      {wizardStepUnfinished &&
        [StepEnum.IDENTIFICATION, StepEnum.RESUME_IDENTIFICATION].includes(wizardStepUnfinished) && (
          <NoIdentification onClick={() => goToInvesment({ invitationId: investorOverview.transferInvitation })} />
        )}
      {wizardStepUnfinished === StepEnum.IDENTIFICATION_REJECTED && (
        <FailedIdentification onClick={() => goToInvesment({ invitationId: investorOverview.transferInvitation })} />
      )}
      {wizardStepUnfinished === StepEnum.WAITING_KYC && <PendingIdentification />}
      {investorOverview?.numberOpenTransactions && <OpenTransactions investorOverview={investorOverview} />}

      {investorOverview?.lastInvestments.length && (
        <>
          <Header size="small" spacing="medium">
            <Translate name="dashboardInvestorOverview.lastInvestments" />
          </Header>
          <Styled.NumberOfInvestments>
            {`${investorOverview.lastInvestments.length} ${translate('common.of')} ${
              investorOverview.numberOfInvestments
            }`}
          </Styled.NumberOfInvestments>
          <DashboardInvestmentsTable
            investments={investorOverview?.lastInvestments}
            goToDetails={(investmentId: string) => goToInvestorInvestment({ id: investmentId })}
            showProductColumn={true}
          />
          <ActionButtons spacingTop="small">
            <Link to="/dashboard/investorPortfolio">
              <Translate name="dashboardInvestorOverview.viewPortfolio" />
            </Link>
          </ActionButtons>
        </>
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorOverviewPage, AppType.INVESTOR);
