import React, { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { PageTitleContext } from 'context/page-title';

const PageTitle: FunctionComponent<{
  children: ReactNode;
  renderInPlace?: boolean;
}> = ({ children, renderInPlace }) => {
  const { setTitle } = useContext(PageTitleContext);
  const [titleElement] = useState(document.createElement('div'));

  useEffect(() => {
    titleElement.style.display = 'none';
    document.body.appendChild(titleElement);
    setTitle(titleElement.innerText);

    return () => {
      document.body.removeChild(titleElement);
    };
  }, [children, setTitle, titleElement]);

  return (
    <>
      {createPortal(children, titleElement)}
      {renderInPlace && children}
    </>
  );
};

export default PageTitle;
