import React, { FunctionComponent, ReactNode } from 'react';
import Icon from 'src/ui/atoms/icon';
import useClipboard from 'ui/hooks/use-clipboard';

export interface CopyButtonProps {
  /** Primary content. */
  children?: ReactNode;

  valueToCopy: string;

  /** Additional classes. */
  className?: string;

  disabled?: boolean;
}

export const CopyButton: FunctionComponent<CopyButtonProps> = (props) => {
  const { className, valueToCopy, disabled, ...restProps } = props;

  const { copy, copied } = useClipboard();

  const copyValue = (e: React.MouseEvent<HTMLElement>, textToCopy: string) => {
    e.stopPropagation();
    copy(textToCopy);
  };
  return (
    <Icon onClick={(e) => copyValue(e, valueToCopy)} name={copied ? 'check' : 'copy'} color="primary" {...restProps} />
  );
};

export default CopyButton;
