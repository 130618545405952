import React, { FunctionComponent, ReactNode } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi, AdminInvestmentsListRequest, InvestmentAcquisitionTypeRecipientEnum, AdminInvestment } from 'api';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import StudioInvestmentsTable from 'src/apps/issuer/shared/studio-investments-table';

interface IssuerInvestmentsTableProps extends AdminInvestmentsListRequest {
  filters?: ReactNode;
  forceDataLoad?: boolean;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
}

const IssuerInvestmentsTable: FunctionComponent<IssuerInvestmentsTableProps> = WithDataRecord<
  AdminApi,
  AdminInvestment
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.investmentDetails);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <StudioInvestmentsTable
          loading={loading}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_INVESTMENTS} />}
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(productId: string, investmentId: string) => goTo({ productId, investmentId })}
          paginationProps={paginationProps}
          investments={(data || []).map((investment) => ({
            ...investment,
            acquisitionType: investment.acquisitionType || InvestmentAcquisitionTypeRecipientEnum.ISSUANCE,
          }))}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminInvestmentsList({
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default IssuerInvestmentsTable;
