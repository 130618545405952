import { AdminInvestor } from 'api';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Checkbox from 'ui/atoms/checkbox';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';

export interface DeleteConfirmProps {
  activePlatform: string;
  investor: AdminInvestor;
  onChangeConfirm: (isConfirmed: boolean) => void;
}

const DeleteConfirm: FunctionComponent<DeleteConfirmProps> = (props) => {
  const { activePlatform, investor, onChangeConfirm } = props;

  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    onChangeConfirm(isConfirmed);
  }, [isConfirmed]);

  return (
    <div>
      <Hint variant="warning">
        <div>
          <div>
            <Translate name="dashboardIssuerInvestorDetails.deleteAccount.content1" />
          </div>
          <div>
            <Translate name="dashboardIssuerInvestorDetails.deleteAccount.content2" />
          </div>
          <div>
            <Translate
              name="dashboardIssuerInvestorDetails.deleteAccount.content3"
              args={[activePlatform.toUpperCase()]}
            />
          </div>
        </div>
      </Hint>

      <Checkbox checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)}>
        <Translate
          name="dashboardIssuerInvestorDetails.deleteAccount.confirmDeletion"
          args={[investor!.name, `(${investor!.email})`]}
        />
      </Checkbox>
    </div>
  );
};

export default DeleteConfirm;
