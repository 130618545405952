import React, { FunctionComponent } from 'react';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import SummarySplitSegmentDesktop from './summary-split-segment-desktop';
import SummarySplitSegmentMobile from './summary-split-segment-mobile';

export interface SummarySplitSegmentProps {
  questions: QuestionnaireQuestions[];
  segmentId: string;
}

const SummarySplitSegment: FunctionComponent<SummarySplitSegmentProps> = ({ questions, segmentId }) => {
  const isDesktop = useIsMedia(DEVICES.desktops);

  if (isDesktop) {
    return <SummarySplitSegmentDesktop questions={questions} segmentId={segmentId} />;
  }

  return <SummarySplitSegmentMobile questions={questions} segmentId={segmentId} />;
};

export default SummarySplitSegment;
