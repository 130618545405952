import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import InvestmentAmountInput, {
  InvestmentAmountNumbers,
  TokenAmountInput,
} from 'subapps/investment/pages/investment/wizard-steps/investment-amount/investment-amount-form/investment-amount-input';
import { PersonType } from 'ui/types/person';
import SelfDisclosure, {
  SelfDisclosureFieldValues,
  SelfDisclosureRequirements,
} from 'subapps/investment/pages/investment/wizard-steps/investment-amount/investment-amount-form/self-disclosure';
import VoucherCode, {
  Voucher,
  VoucherCodeFields,
} from 'subapps/investment/pages/investment/wizard-steps/investment-amount/investment-amount-form/voucher-code';
import ServerError from 'ui/types/server-error';
import BigNumber from 'bignumber.js';
import { Document } from 'ui/types/document';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import { ChangeWithValidationState } from 'ui/hooks/use-form';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import ActionButtons from 'src/ui/molecules/action-buttons';

export interface InvestmentAmountFields {
  tokenAmountInput?: TokenAmountInput;
  selfDisclosure?: SelfDisclosureFieldValues;
  voucherCode?: string;
}

export interface InvestmentAmountProps {
  /** Additional classes. */
  className?: string;

  /** Indicates a loading state */
  loading?: boolean;

  /** On submit callback */
  onSubmit?: (values: InvestmentAmountFields) => void;

  /** On change callback */
  onChange?: (values: InvestmentAmountFields) => void;

  shareNumbers: InvestmentAmountNumbers;

  personType: PersonType;

  selfDisclosureRequirements?: SelfDisclosureRequirements;

  showShareInfo: boolean;

  /** Voucher, not entered or invalid if undefined */

  voucher?: Voucher;

  /** Error message */
  error?: ServerError;

  voucherError?: ServerError;

  selfDisclosureInvestmentAmountExceeds25k?: boolean;

  /** Investors can enter units (true) or investment amount (false) in the investment amount step */
  useUnits: boolean;

  unitSteps?: BigNumber[];

  customCommitmentText?: string | null;

  customCommitmentDocument?: Document | null;

  companyName?: ReactNode;

  initial: {
    selfDisclosure?: SelfDisclosureFieldValues;
    voucherCode?: string;
    numberOfTokens?: BigNumber;
  };
}

const InvestmentAmountForm: FunctionComponent<InvestmentAmountProps> = (props) => {
  const {
    className,
    voucher,
    useUnits,
    unitSteps,
    showShareInfo,
    selfDisclosureRequirements,
    personType,
    shareNumbers,
    selfDisclosureInvestmentAmountExceeds25k,
    customCommitmentText,
    customCommitmentDocument,
    companyName,
    loading,
    error,
    voucherError,
    initial,
    onSubmit = () => {},
    onChange = () => {},
    ...restProps
  } = props;

  const [tokenAmountInput, setTokenAmountInput] = useState<ChangeWithValidationState<TokenAmountInput>>();
  const [selfDisclosure, setSelfDisclosure] = useState<ChangeWithValidationState<SelfDisclosureFieldValues>>();
  const [voucherCodeInput, setVoucherCodeInput] = useState<ChangeWithValidationState<VoucherCodeFields>>();

  const isTokenAmountInputValid = tokenAmountInput?.isValid;
  const isSelfDisclosureValid = selfDisclosure?.isValid;

  const isInvestmentAmountFormValid = isSelfDisclosureValid && isTokenAmountInputValid;

  useEffect(() => {
    onChange({
      tokenAmountInput: tokenAmountInput?.values,
      selfDisclosure: selfDisclosure?.values,
      voucherCode: voucherCodeInput?.values?.voucherCode,
    });
  }, [tokenAmountInput, selfDisclosure, onChange, voucherCodeInput]);

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const errors = getRemainingError();

  return (
    <div className={cx('investment-amount', className)} {...restProps}>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();

          onSubmit({
            tokenAmountInput: tokenAmountInput?.values,
            selfDisclosure: selfDisclosure?.values,
          });
        }}
      >
        <InvestmentAmountInput
          {...shareNumbers}
          useUnits={useUnits}
          unitSteps={unitSteps}
          customCommitmentText={customCommitmentText}
          customCommitmentDocument={customCommitmentDocument}
          companyName={companyName}
          showShareInfo={showShareInfo}
          onChangeWithValidation={setTokenAmountInput}
          initial={initial}
        />

        <VoucherCode
          voucher={voucher}
          onChangeWithValidation={setVoucherCodeInput}
          initial={initial}
          voucherError={voucherError}
        />

        <SelfDisclosure
          {...selfDisclosureRequirements}
          onRequirementChecked={setSelfDisclosure}
          selfDisclosureInvestmentAmountExceeds25k={selfDisclosureInvestmentAmountExceeds25k}
          initial={initial}
        />
        <ActionButtons>
          <Button
            variant="primary"
            type="submit"
            size="large"
            disabled={!isInvestmentAmountFormValid || selfDisclosureInvestmentAmountExceeds25k}
            loading={loading}
            error={errors}
          >
            <Translate name="common.continue" />
          </Button>
        </ActionButtons>
      </form>
    </div>
  );
};

export default InvestmentAmountForm;
