import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { compact } from 'lodash';
import { RegisteredAddress } from 'apps/issuer/pages/investor-details/edit-modals/add-identification-modal';
import ChangeIdentificationAddress from 'ui/types/change-identification-address';
import Spacer from 'ui/atoms/spacer';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface AddIdentificationRegisteredAddressProps {
  AddIdentificationForm: any;
  registeredAddress: RegisteredAddress;
  changeAddress: boolean;
  country: string | undefined;
  onChangeCountry: (country: string) => void;
}

const AddIdentificationRegisteredAddress: FunctionComponent<AddIdentificationRegisteredAddressProps> = (props) => {
  const { AddIdentificationForm, registeredAddress, changeAddress, country, onChangeCountry } = props;

  const changeAddressOptions = compact([
    ChangeIdentificationAddress.CORRECTADDRESS,
    ChangeIdentificationAddress.INCORRECTADDRESS,
  ]);

  const changeAddressText = {
    [ChangeIdentificationAddress.CORRECTADDRESS]: {
      name: <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.correctAddress" />,
    },
    [ChangeIdentificationAddress.INCORRECTADDRESS]: {
      name: <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.incorrectAddress" />,
    },
  };

  const countries = useCountries();

  return (
    <>
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitleRegisteredAddress" />
        </strong>
      </p>
      <p>{registeredAddress.street && registeredAddress.street}</p>
      <p>
        {registeredAddress.zip && registeredAddress.zip} {registeredAddress.city && registeredAddress.city}
      </p>
      <p>{registeredAddress.country && <Translate name={`countries.${registeredAddress.country}`} />}</p>
      {registeredAddress.country && (
        <>
          <p>
            <strong>
              <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.changeRegisteredAddress" />
            </strong>
          </p>
          <AddIdentificationForm.Group name="changeAddress" required={true}>
            <AddIdentificationForm.GroupToggle>
              {changeAddressOptions.map((option, index) => (
                <AddIdentificationForm.Radio value={option} key={index}>
                  {changeAddressText[option].name}
                </AddIdentificationForm.Radio>
              ))}
            </AddIdentificationForm.GroupToggle>
          </AddIdentificationForm.Group>
        </>
      )}
      {(changeAddress || !registeredAddress.country) && (
        <>
          <Spacer y={2} />
          <AddIdentificationForm.Group name="street" required={true}>
            <AddIdentificationForm.Input />
          </AddIdentificationForm.Group>
          <Grid>
            <Col width={4} spacing="small">
              <AddIdentificationForm.Group name="zip" required={true}>
                <AddIdentificationForm.Input />
              </AddIdentificationForm.Group>
            </Col>
            <Col width={8} spacing="small">
              <AddIdentificationForm.Group name="city" required={true}>
                <AddIdentificationForm.Input />
              </AddIdentificationForm.Group>
            </Col>
          </Grid>
          <AddIdentificationForm.Group name="country" required={true}>
            <AddIdentificationForm.Select
              options={countries}
              value={country}
              onChange={(country: string) => {
                onChangeCountry(country);
              }}
            ></AddIdentificationForm.Select>
          </AddIdentificationForm.Group>
        </>
      )}
    </>
  );
};

export default AddIdentificationRegisteredAddress;
