import type { User } from 'api';
import { EnhancedAction } from 'redux-action-enhancer';
import { ENHANCE_WITH_LOCATION, LocationEnhancer } from 'store/enhancers';
import { AppType } from 'core/auth/types';

export enum AuthActions {
  VERIFY_AUTH = 'AUTH/VERIFY',
  AUTH_FETCH = 'AUTH/FETCH',
  AUTH_FETCH_SUCCESS = 'AUTH/FETCH_SUCCESS',
  AUTH_FETCH_FAILURE = 'AUTH/FETCH_FAILURE',
  LOGIN = 'AUTH/LOGIN',
  LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE',
  SET_AUTH_TOKEN = 'AUTH/SET_AUTH_TOKEN',
  INVALIDATE_AUTH = 'AUTH/INVALIDATE_AUTH',
  RE_LOGIN = 'AUTH/RE_LOGIN',
  REDIRECT_WITH_LOOPBACK = 'AUTH/REDIRECT_WITH_LOOPBACK',
  REDIRECT_LOGGED_IN = 'AUTH/REDIRECT_LOGGED_IN',
  SET_USER_LANGUAGE = 'AUTH/SET_USER_LANGUAGE',
}

export type VerifyAuth = { type: AuthActions.VERIFY_AUTH };
export type AuthFetch = { type: AuthActions.AUTH_FETCH };
export type AuthFetchSuccess = {
  type: AuthActions.AUTH_FETCH_SUCCESS;
  data: User;
};
export type AuthFetchFailure = {
  type: AuthActions.AUTH_FETCH_FAILURE;
  error: Error;
};
export type Login = {
  type: AuthActions.LOGIN;
  email: string;
  password: string;
  distributionPlatformName: string;
};
export type LoginSuccess = {
  type: AuthActions.LOGIN_SUCCESS;
  token: string;
};
export type LoginFailure = {
  type: AuthActions.LOGIN_FAILURE;
  error: Error;
};
export type SetAuthToken = { type: AuthActions.SET_AUTH_TOKEN; token: string };
export type InvalidateAuth = { type: AuthActions.INVALIDATE_AUTH };
type BaseReLogin = {
  type: AuthActions.RE_LOGIN;
  appType?: AppType;
};
type EnhancedReLogin = BaseReLogin & {
  [ENHANCE_WITH_LOCATION]: true;
};
export type ReLogin = EnhancedAction<BaseReLogin, LocationEnhancer>;

type BaseRedirectWithLoopback = {
  type: AuthActions.REDIRECT_WITH_LOOPBACK;
  path: string;
  appType?: AppType;
  nextPath?: string;
};
type EnhancedRedirectWithLoopback = BaseRedirectWithLoopback & {
  [ENHANCE_WITH_LOCATION]: true;
};
export type RedirectWithLoopback = EnhancedAction<BaseRedirectWithLoopback, LocationEnhancer>;
type BaseRedirectLoggedIn = {
  type: AuthActions.REDIRECT_LOGGED_IN;
};
export type EnhancedRedirectLoggedIn = BaseRedirectLoggedIn & {
  [ENHANCE_WITH_LOCATION]: true;
};
export type RedirectLoggedIn = EnhancedAction<BaseRedirectLoggedIn, LocationEnhancer>;

export type SetUserLanguage = {
  type: AuthActions.SET_USER_LANGUAGE;
  language: string;
};

export type AuthActionTypes =
  | VerifyAuth
  | AuthFetch
  | AuthFetchSuccess
  | AuthFetchFailure
  | Login
  | LoginSuccess
  | LoginFailure
  | SetAuthToken
  | InvalidateAuth
  | ReLogin
  | RedirectWithLoopback
  | RedirectLoggedIn
  | SetUserLanguage;

export function verifyAuth(): VerifyAuth {
  return {
    type: AuthActions.VERIFY_AUTH,
  };
}

export function authFetch(): AuthFetch {
  return {
    type: AuthActions.AUTH_FETCH,
  };
}

export function authFetchSuccess(data: User): AuthFetchSuccess {
  return {
    type: AuthActions.AUTH_FETCH_SUCCESS,
    data,
  };
}

export function authFetchFailure(error: Error): AuthFetchFailure {
  return {
    type: AuthActions.AUTH_FETCH_FAILURE,
    error,
  };
}

export function login(email: string, password: string, distributionPlatformName: string): Login {
  return {
    type: AuthActions.LOGIN,
    email,
    password,
    distributionPlatformName,
  };
}

export function loginSuccess(token: string): LoginSuccess {
  return {
    type: AuthActions.LOGIN_SUCCESS,
    token,
  };
}

export function loginFailure(error: Error): LoginFailure {
  return {
    type: AuthActions.LOGIN_FAILURE,
    error,
  };
}

export function setAuthToken(token: string): SetAuthToken {
  return {
    type: AuthActions.SET_AUTH_TOKEN,
    token,
  };
}

export function invalidateAuth(): InvalidateAuth {
  return {
    type: AuthActions.INVALIDATE_AUTH,
  };
}

export function reLogin(appType?: AppType): EnhancedReLogin {
  return {
    type: AuthActions.RE_LOGIN,
    appType,
    [ENHANCE_WITH_LOCATION]: true,
  };
}

export function redirectPathWithLoopback(path: string, appType?: AppType): EnhancedRedirectWithLoopback {
  return {
    type: AuthActions.REDIRECT_WITH_LOOPBACK,
    path,
    appType,
    [ENHANCE_WITH_LOCATION]: true,
  };
}

export function redirectLoggedIn(): EnhancedRedirectLoggedIn {
  return {
    type: AuthActions.REDIRECT_LOGGED_IN,
    [ENHANCE_WITH_LOCATION]: true,
  };
}

export function setUserLanguage(language: string): SetUserLanguage {
  return {
    type: AuthActions.SET_USER_LANGUAGE,
    language,
  };
}
