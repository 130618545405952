import React, { FunctionComponent, useState, useEffect } from 'react';
import Checkbox from 'src/ui/atoms/checkbox';
import Header from 'src/ui/atoms/header';
import Hint from 'src/ui/atoms/hint';
import Spacer from 'src/ui/atoms/spacer';
import Translate from 'src/ui/atoms/translate';

export interface ConsiderationPeriodProps {
  onChangeConfirmation: (isChecked: boolean) => void;
}

const ConsiderationPeriodConfirmation: FunctionComponent<ConsiderationPeriodProps> = (props) => {
  const { onChangeConfirmation } = props;

  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    onChangeConfirmation(isChecked);
  }, [isChecked]);

  return (
    <div>
      <Hint variant="info">
        <Translate name="considerationPeriodConfirmation.info" />
      </Hint>
      <Spacer y={2} />
      <Header>
        <Translate name="considerationPeriodConfirmation.timeToThink" />
      </Header>
      <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)}>
        <Translate name="considerationPeriodConfirmation.confirm" />
      </Checkbox>
      <Spacer y={2} />
    </div>
  );
};

export default ConsiderationPeriodConfirmation;
