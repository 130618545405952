import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Date from 'ui/atoms/date';
import Icon from 'ui/atoms/icon';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { InvestorPortfolio } from 'ui/types/portfolio';
import { PaginationProps } from 'ui/molecules/pagination';

export interface DashboardPortfolioTableProps {
  /** Primary content. */
  children?: ReactNode;
  /** Additional classes. */
  className?: string;
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  investorPortfolio?: InvestorPortfolio[];
  goToDetails: (productId: string) => void;
  emptyView?: ReactNode;
  loading: boolean;
  paginationProps?: PaginationProps;
}

const DashboardPortfolioTable: FunctionComponent<DashboardPortfolioTableProps> = (props) => {
  const {
    className,
    children,
    onOrderBy,
    ordering,
    goToDetails,
    investorPortfolio,
    emptyView,
    loading,
    paginationProps,
    ...restProps
  } = props;

  return (
    <div className={cx('dashboard-portfolio-table', className)} {...restProps}>
      <DataRecordTable
        onOrderBy={onOrderBy}
        ordering={ordering}
        data={investorPortfolio}
        emptyView={emptyView}
        loading={loading}
        paginationProps={paginationProps}
        cells={[
          {
            title: <Translate name="dashboardPortfolioTable.productName" />,
            orderingFieldName: 'product_name',
            render: (product) => product.productName,
          },
          {
            title: <Translate name="dashboardPortfolioTable.totalInvestmentAmount" args={[<>&shy;</>]} />,
            orderingFieldName: 'total_investment_amount',
            render: ({ totalInvestmentAmount }) => (
              <PlaceholderFallback>
                {totalInvestmentAmount && <Currency>{totalInvestmentAmount}</Currency>}
              </PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardPortfolioTable.totalEarnings" />,
            orderingFieldName: 'total_earnings',
            render: ({ totalEarnings }) => (
              <PlaceholderFallback>{totalEarnings && <Currency>{totalEarnings}</Currency>}</PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardPortfolioTable.lastProfit" />,
            orderingFieldName: 'last_profit',
            render: ({ lastProfit }) => (
              <PlaceholderFallback>{lastProfit && <Currency>{lastProfit}</Currency>}</PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: <Translate name="dashboardPortfolioTable.nextProfit" />,
            orderingFieldName: 'next_profit',
            render: ({ nextProfit }) => (
              <PlaceholderFallback>{nextProfit && <Date>{nextProfit}</Date>}</PlaceholderFallback>
            ),
            alignContent: 'right',
          },
          {
            title: '',
            render: ({ productId }) => (
              <Icon name="arrow-right" color="primary" onClick={() => goToDetails(productId)} size="large" />
            ),
            alignContent: 'right',
          },
        ]}
      />
    </div>
  );
};

export default DashboardPortfolioTable;
