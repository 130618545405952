import { DefaultTheme } from 'styled-components';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import { resolveThemeFn } from 'ui/styles/utils/common';
import { ThemeColor } from 'ui/styles/types';

export type Colors = ColorVariants | ThemeColor;

export const resolveColor = (
  theme: DefaultTheme,
  color?: Colors,
  fallback: 'primary' | 'grayMain' | 'white' | 'link' = 'primary',
): string => {
  const allColors = {
    ...theme.colors,
    ...theme.colorVariants,
  };

  if (!color) return resolveThemeFn(allColors[fallback], theme);
  if (typeof color === 'function') return color({ theme });
  if (allColors[color as ColorVariants]) return resolveThemeFn(allColors[color as ColorVariants], theme);

  return color;
};

export const getColorYig = (theme: DefaultTheme, color: string) =>
  isDark(theme, color) ? resolveColor(theme, 'light') : resolveColor(theme, 'dark');

export function getColorsMap(theme: DefaultTheme, color?: Colors, danger?: boolean) {
  let resolved: Colors = 'primary';
  if (danger) resolved = 'danger';
  if (color) resolved = color;

  const resolvedColor = resolveColor(theme, resolved, 'primary');

  return {
    main: resolvedColor,
    mainDark: darken(theme, resolvedColor, theme.common.colorDarkenValue),
    mainLight: lighten(theme, resolvedColor, theme.common.colorDarkenValue),
    minor: getColorYig(theme, resolvedColor),
  };
}

export const isDark = (theme: DefaultTheme, color: string) => {
  // Variables for red, green, blue values
  let r, g, b, hsp: number;

  if (!color) return true;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const rgbColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    if (!rgbColor) return true;

    r = parseInt(rgbColor[1]);
    g = parseInt(rgbColor[2]);
    b = parseInt(rgbColor[3]);
  } else {
    // If HEX --> Convert it to RGB
    let hexColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

    if (!hexColor) return true;

    r = parseInt(hexColor[1], 16);
    g = parseInt(hexColor[2], 16);
    b = parseInt(hexColor[2], 16);
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > theme.common.colorYigThreshold) {
    return false;
  }
  return true;
};

export const lighten = (theme: DefaultTheme, color: Colors, amount = theme.common.colorDarkenValue) => {
  return lightenDarkenColor(resolveColor(theme, color), amount);
};

export const darken = (theme: DefaultTheme, color: Colors, amount = theme.common.colorDarkenValue) => {
  return lightenDarkenColor(resolveColor(theme, color), -amount);
};

export const lightenDarkenColor = (color: string, amount: number) => {
  let usePound = false;

  if (!color) return;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);

  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let res = '000000' + (g | (b << 8) | (r << 16)).toString(16);
  return (usePound ? '#' : '') + res.substring(res.length - 6);
};
