import React, { FunctionComponent, useCallback, useContext, useState } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import { ButtonSizes } from 'ui/styles/themes/buttons';
import * as Styled from './styled';
import Icon from 'src/ui/atoms/icon';
import Spacer from 'src/ui/atoms/spacer';
import { I18NContext } from 'src/ui/i18n/provider';

export interface ChooseLanguageProps {
  onLocaleChange: (locale: string) => void;
  onModalClose: () => void;
}

const ChooseLanguageModal: FunctionComponent<ChooseLanguageProps> = (props) => {
  const { onLocaleChange, onModalClose } = props;

  const { activeLocale } = useContext(I18NContext);
  const [selectedLanguage, setSelectedLanguage] = useState(activeLocale);

  const onConfirm = useCallback(() => {
    onLocaleChange(selectedLanguage);
    onModalClose();
  }, [selectedLanguage]);

  return (
    <Modal onClose={onModalClose}>
      <ModalHeader>
        <Translate name="profileAccess.chooseLanguage" />
      </ModalHeader>
      <ModalContent>
        <div>
          <Styled.LanguagesContainer $active={selectedLanguage === 'de'} onClick={() => setSelectedLanguage('de')}>
            <Translate name="languages.de" />
            <Icon name="de" size="large" />
          </Styled.LanguagesContainer>
          <Spacer y={6} />
          <Styled.LanguagesContainer $active={selectedLanguage === 'en'} onClick={() => setSelectedLanguage('en')}>
            <Translate name="languages.en" />
            <Icon name="en" size="large" />
          </Styled.LanguagesContainer>
        </div>
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'confirm',
              content: <Translate name="common.save" />,
              variant: 'primary',
              type: 'submit',
              size: 'large' as ButtonSizes,
              onClick: onConfirm,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ChooseLanguageModal;
