import { RootAppState } from 'store/types';
import { Enhancement, ActionEnhancerById } from 'redux-action-enhancer';

export const ENHANCE_WITH_TIME = Symbol('ENHANCE WITH TIME');
export const ENHANCE_WITH_LOCATION = Symbol('ENHANCE WITH LOCATION');

export interface TimeEnhancer extends Enhancement {
  now: Date;
}

export const timeEnhancer: ActionEnhancerById<TimeEnhancer> = {
  id: ENHANCE_WITH_TIME,
  mapState: () => {
    return {
      now: new Date(),
    };
  },
};

export interface LocationEnhancer extends Enhancement {
  pathname: string;
  search: string;
  hash: string;
}

export const locationEnhancer: ActionEnhancerById<LocationEnhancer> = {
  id: ENHANCE_WITH_LOCATION,
  mapState: (state: RootAppState) => {
    return {
      ...state.router.location,
    };
  },
};

export default [timeEnhancer, locationEnhancer];
