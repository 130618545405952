import React, { FunctionComponent } from 'react';

import clamp from 'lodash/clamp';

import { As } from 'ui/helper/get-element-type';
import { Money } from 'api';
import { MoneyValue } from 'ui/types/money-value';
import { toNumber } from 'ui/helper/money';

import Number from 'ui/atoms/number';

export interface CurrencyProps extends As {
  /** Number to be formatted. */
  children: Money | MoneyValue;

  /** Additional classes. */
  className?: string;

  decimals?: number | '*';

  truncateDecimals?: number;

  showSign?: boolean;
}

export const Currency: FunctionComponent<CurrencyProps> = (props) => {
  const { className, children, showSign, ...restProps } = props;

  const decimals = clamp(
    typeof props.decimals === 'number' ? props.decimals : props.decimals === '*' ? children.decimals : 2,
    0,
    20,
  );
  const currency = children.currency || 'EUR';

  const bigNumber = children instanceof MoneyValue && children.bigNumber;

  return (
    <Number
      type="currency"
      minimumFractionDigits={2}
      maximumFractionDigits={decimals >= 2 ? decimals : 2}
      currency={currency}
      showSign={showSign}
      className={className}
      {...restProps}
    >
      {bigNumber || toNumber(children)}
    </Number>
  );
};

export default Currency;
