import React from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import LoadingRing from 'ui/atoms/loading-ring';
import FriendVoucherInfo from './friend-voucher-info';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';

const InvestorInviteFriendsPage = () => {
  const { investor, loading, error } = useInvestorSelector();

  if (loading) {
    return <LoadingRing />;
  }

  if (error || !investor?.voucherCode) return null;

  return (
    <WideContent title={<Translate name="friendVoucherInfo.title" />}>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <FriendVoucherInfo voucherCode={investor.voucherCode} />
      </BoxedContent>
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorInviteFriendsPage, AppType.INVESTOR);
