import React, { FunctionComponent, useMemo } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi } from 'api';
import { handleError } from 'ui/helper/error-handling';
import useTranslate from 'ui/hooks/use-translate';
import Hint from 'ui/atoms/hint';

export interface IssueDemoTokensModalProps {
  investmentId: string;
  amount: number;
  recipientName: string;
  onHide: () => void;
}

const IssueManagedTokensModal: FunctionComponent<IssueDemoTokensModalProps> = (props) => {
  const { investmentId, amount, recipientName, onHide } = props;

  const translate = useTranslate();

  const { loading, withApi, makeAuthenticatedApi, error } = useApiCall();
  const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onSubmit = async () => {
    await withApi(async () => {
      await investmentsApi.adminInvestmentsIssueCreate({
        id: investmentId,
      });
      onHide();
    });
  };

  const { getRemainingError } = handleError({
    error,
    translate,
  });

  const errors = getRemainingError();

  return (
    <Modal onClose={onHide}>
      <ModalHeader>
        <Translate name="issueManagedTokens.title" />
      </ModalHeader>
      <ModalContent>
        <p>
          <Translate name="issueManagedTokens.information" args={[amount, recipientName]} />
        </p>
        {errors && <Hint variant="danger">{errors}</Hint>}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              size: 'large',
              onClick: onHide,
            },
            {
              name: 'send',
              content: <Translate name="issueManagedTokens.send" />,
              variant: 'primary',
              size: 'large',
              loading: loading,
              onClick: onSubmit,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default IssueManagedTokensModal;
