import { AdminApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';

export interface EditDepositAccountFields {
  id: string;
  accountHolder: string;
  accountNumber: string;
  bic: string;
}

export interface EditDepositAccountModalProps {
  onHideEditDepositAccount: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  values: EditDepositAccountFields;
}

const EditDepositAccountForm = makeModalForm<EditDepositAccountFields>();

const EditDepositAccountModal: FunctionComponent<EditDepositAccountModalProps> = (props) => {
  const { onHideEditDepositAccount, fetchInvestorDetails, showSuccessModal = () => {}, values } = props;

  const [investorData, setInvestorData] = useState<EditDepositAccountFields>(values || {});

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editDepositAccount = (fieldsValues: StateValues<EditDepositAccountFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminInvestorUpdateRequest: {
          securitiesDepositAccount: fieldsValues,
        },
      };
      await investorsApi.adminInvestorsPartialUpdate(patchBody);
      showSuccessModal();
      fetchInvestorDetails();
      onHideEditDepositAccount();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditDepositAccount}>
        <EditDepositAccountForm
          initial={{
            accountHolder: investorData.accountHolder,
            accountNumber: investorData.accountNumber,
            bic: investorData.bic,
          }}
          onChange={(values: StateValues<EditDepositAccountFields>) => {
            setInvestorData(values);
          }}
          onSubmit={(values: StateValues<EditDepositAccountFields>) => {
            return editDepositAccount(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editDepositAccountForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editDepositAccountForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditDepositAccountForm.Group name="accountHolder" required={true}>
              <EditDepositAccountForm.Input placeholder="-" />
            </EditDepositAccountForm.Group>
            <EditDepositAccountForm.Group name="accountNumber" required={true}>
              <EditDepositAccountForm.Input placeholder="-" />
            </EditDepositAccountForm.Group>
            <EditDepositAccountForm.Group name="bic" required={true}>
              <EditDepositAccountForm.Input placeholder="-" />
              {EditDepositAccountForm.Validators.Bic()}
            </EditDepositAccountForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditDepositAccount,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editDepositAccountForm.button" />,
                  variant: 'primary',
                  type: 'submit',
                  size: 'large',
                },
              ]}
            />
          </ModalFooter>
        </EditDepositAccountForm>
      </Modal>
    </>
  );
};

export default EditDepositAccountModal;
