import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const DashboardLayout = styled.div`
  width: 100%;
  display: flex;
`;

export const DashboardContent = styled.div`
  flex-grow: 1;
  max-width: 100%;
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndices.header};
`;

export const Main = styled.main`
  padding: 5rem 0 1rem 0;
  position: relative;
  ${query.phoneTablet`
    padding-top: 1rem !important;
  `}
`;
