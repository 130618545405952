import styled, { css } from 'styled-components';
import Link from 'core/routing/link';
import { getColorsMap } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';

export interface StyledImprintProps {
  $color?: Colors;
}

export const Imprint = styled(Link)<StyledImprintProps>`
  align-self: flex-start;
  padding: 0px;

  ${({ theme, $color }) => {
    if (!$color) return;

    const { main, mainDark } = getColorsMap(theme, $color);

    return css`
      color: ${main};

      &:hover {
        color: ${mainDark};
      }
    `;
  }}
`;
