import { NetworkTypeEnum } from 'api/models';
import { useServerConfigSelector } from 'core/config/hooks';
import { useMemo } from 'react';
import { assertUnreachable } from 'ui/helper/unreachable';

const useTransactionLink = (
  hash: string | undefined,
  network: string | undefined,
  networkType: NetworkTypeEnum | undefined,
  networkAdress?: string,
) => {
  const { config, error, loading } = useServerConfigSelector();

  const networkInfo = useMemo(() => {
    if (!networkType) return undefined;
    switch (networkType) {
      case NetworkTypeEnum.ETHEREUM:
        return 'ethInfo';
      case NetworkTypeEnum.STELLAR:
        return 'stellarInfo';
    }
    assertUnreachable(networkType);
  }, [networkType]);

  const transactionLink = useMemo(() => {
    if (!hash || !network || !networkInfo) return undefined;
    return config.networks[network][networkInfo]?.explorerTxUrl?.replace('{hash}', hash);
  }, [network, hash, networkInfo]);

  return {
    error,
    loading,
    transactionLink,
  };
};

export default useTransactionLink;
