import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvitationsApi } from 'api/apis';
import makeLink from 'helper/make-link';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { PersonType } from 'ui/types/person';
import { CreateAccountValues } from 'libraries/wizard/components/account-setup/create-account';
import { I18NContext } from 'ui/i18n/provider';
import { InvitationAll, InvitationToExternalCampaignInvitation } from 'helper/cast';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import { InvitationTypeEnum } from 'ui/types/invitation-type';
import { useDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { getLocation } from 'connected-react-router';
import { redirectPathWithLoopback } from 'core/auth/actions';
import WizardContext from 'libraries/wizard/wizard-context';
import useInvestorMe from 'hooks/use-investor-me';
import { AnyServerError } from 'hooks/use-api-error';
import { useServerConfigSelector } from 'core/config/hooks';
import { Config, ExternalCampaignInvitation } from 'api';
import type { PreSelectionMode } from 'libraries/wizard/components/account-setup';

const useInvestmentAccountCreation = (): {
  setPreSelectionMode: (mode: PreSelectionMode) => void;
  onSubmit: (values: CreateAccountValues) => void;
  preSelectionMode: PreSelectionMode;
  continueProcessURL: string;
  createAccountMode: boolean;
  email?: string;
  isLoggedIn: boolean;
  config: Config;
  resourceError?: AnyServerError;
  apiError?: AnyServerError;
  apiLoading: boolean;
  showLoading: boolean;
  isTransferInvitation: boolean;
  externalInvitation?: ExternalCampaignInvitation;
  allowedPersonTypes: PersonType[];
} => {
  //contexts
  const { resourceId: invitationId, finalize, loading, nextStep } = useContext(WizardContext);
  const { activeLocale } = useContext(I18NContext);

  //state
  const [preSelectionMode, setPreSelectionMode] = useState<PreSelectionMode>('overview');
  const [loginEmail, setLoginEmail] = useState<string>();

  //hooks
  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi, reset: resetApi } = useApiCall();
  const { error: investmentError, invitation } = useInvestmentInvitation(invitationId);
  const { isLoggedIn, loading: meLoading } = useInvestorMe();
  const routerState = useSelector(getLocation);
  const dispatch = useDispatch();
  const { config, loading: configLoading } = useServerConfigSelector();

  //apis
  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  //variables
  const isTransferInvitation = invitation?.invitationType === InvitationTypeEnum.TRANSFER_INVITATION;
  const hasUniqueEmailError = apiError?.hasErrorCode('unique');
  const externalInvitation = InvitationToExternalCampaignInvitation(invitation) || undefined;
  const createAccountMode = !isLoggedIn;
  const showLoading = !invitation || meLoading || configLoading || loading;
  const investmentURL = makeLink(INVESTMENT_ROUTES.investment, { invitationId: invitationId }, true);
  const email = (invitation as InvitationAll)?.email;
  const allowedPersonTypes = [
    ...(invitation?.naturalPersonsAllowed ? [PersonType.Natural] : []),
    ...(invitation?.legalPersonsAllowed ? [PersonType.Legal] : []),
  ];

  //effects
  useEffect(() => {
    setLoginEmail(email);
  }, [email]);

  useEffect(() => {
    if ((hasUniqueEmailError && !externalInvitation) || isTransferInvitation) {
      setPreSelectionMode('login');
      resetApi();
    }
  }, [resetApi, hasUniqueEmailError, externalInvitation, isTransferInvitation]);

  useEffect(() => {
    if (externalInvitation) {
      setPreSelectionMode(undefined);
    }
  }, [externalInvitation]);

  useEffect(() => {
    if (preSelectionMode === 'login' && !isTransferInvitation) {
      dispatch(redirectPathWithLoopback('/login'));
    }
  }, [dispatch, routerState, preSelectionMode, isTransferInvitation]);

  //functions
  const onSubmit = useCallback(
    (values: CreateAccountValues) => {
      withApi(async () => {
        if (!values.naturalPerson)
          // TODO(niklasb) dropping errors silently is bad!
          return null; // todo: potential

        const naturalPerson = {
          ...values.naturalPerson,
          birthDate: new Date(values.naturalPerson.birthDate),
          country: values.naturalPerson.country as any, // TODO(geforcefan): write api converter instead of using any
          salutation: values.naturalPerson.salutation,
          // for legal persons: reset natural person address fields for data to submit
          // user might have filled these fields before selecting the "legal" option
          ...(values.personType === PersonType.Legal
            ? {
                street: undefined,
                zip: undefined,
                city: undefined,
                country: undefined,
              }
            : {}),
        };

        const commonCreateAccountValues = values.accountCredentials &&
          values.acceptDocuments && {
            password: values.accountCredentials.password,
            preferredLanguage: activeLocale,
            // TODO(niklasb) this field is misnamed
            acceptEffecta:
              values.acceptDocuments.effectaAccountSetupDocument || values.acceptDocuments.accountSetupDocument,
            acceptTos: values.acceptDocuments.effectaTermsConditions || values.acceptDocuments.termsOfService,
            communicationSettings: {
              optinEmail: values.communicationSettings?.optinEmail,
              optinMail: values.communicationSettings?.optinMail,
              optinPhone: values.communicationSettings?.optinPhone,
            },
          };

        const legalPerson =
          values.personType === PersonType.Legal && values.legalPerson
            ? {
                ...values.legalPerson,
                country: values.legalPerson.country as any, // TODO(mara-cashlink): write api converter instead of using any
              }
            : undefined;

        if (externalInvitation) {
          if (!commonCreateAccountValues || !values.accountCredentials)
            // TODO(niklasb) dropping errors silently is bad!
            return null;
          const { access } = await invitationsApi.invitationsRegistrationCompletionCreate({
            id: invitationId,
            investorCompletionRequest: {
              naturalPerson: {
                ...naturalPerson,
              },
              email: values.accountCredentials.email,
              iban: values.naturalPerson.iban,
              ...commonCreateAccountValues,
            },
          });
          finalize(access);
          nextStep();
        } else if (createAccountMode) {
          if (
            !values.accountCredentials ||
            !commonCreateAccountValues ||
            !naturalPerson.forename ||
            !naturalPerson.surname
          )
            return null;

          setLoginEmail(values.accountCredentials.email);

          const { access } = await invitationsApi.invitationsRegistrationCreate({
            investorCreationRequest: {
              naturalPerson: {
                ...naturalPerson,
                forename: naturalPerson.forename,
                surname: naturalPerson.surname,
                email: values.accountCredentials.email,
              },
              legalPerson,
              ...commonCreateAccountValues,
            },
            id: invitationId,
          });
          finalize(access);
          nextStep();
        } else {
          if (!naturalPerson.forename || !naturalPerson.surname)
            // TODO(niklasb) dropping errors silently is bad!
            return;
          await invitationsApi.invitationsRegistrationUpdate({
            investorUpdateRequest: {
              naturalPerson: {
                ...naturalPerson,
              },
              legalPerson,
            },
            id: invitationId,
          });
          nextStep();
        }
      });
    },
    [invitationsApi, invitationId, withApi, activeLocale, createAccountMode, externalInvitation, finalize, nextStep],
  );

  return useMemo(
    () => ({
      setPreSelectionMode,
      onSubmit,
      preSelectionMode,
      continueProcessURL: investmentURL,
      createAccountMode,
      email: loginEmail,
      isLoggedIn,
      showLoading,
      isTransferInvitation,
      externalInvitation,
      config,
      resourceError: investmentError,
      apiError,
      apiLoading,
      allowedPersonTypes,
    }),
    [
      setPreSelectionMode,
      onSubmit,
      preSelectionMode,
      investmentURL,
      createAccountMode,
      loginEmail,
      isLoggedIn,
      showLoading,
      config,
      isTransferInvitation,
      externalInvitation,
      invitationId,
      investmentError,
      apiError,
      apiLoading,
    ],
  );
};

export default useInvestmentAccountCreation;
