export interface SegmentTheme {
  paddingY: any;
  paddingX: any;
  marginY: any;
  marginX: any;
  raisedBoxShadow: any;
}

export default {
  paddingY: 1.5,
  paddingX: 1.5,
  marginY: 1,
  marginX: 0,
  // todo: use actual floating shadow
  raisedBoxShadow: ({ theme }: any) => theme.accents.floatingShadow,
} as SegmentTheme;
