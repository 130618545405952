import { investorAppRootStateSelector } from 'apps/investor/selectors';
import type { InvestorAppState } from 'store/types';

export function investmentsRootSelector(state: InvestorAppState) {
  return investorAppRootStateSelector(state)?.investments || {};
}

export function investmentsSelector(state: InvestorAppState) {
  const { data, hasInvestmentsLoading, hasInvestmentsError, hasEcspInvestmentsLoading, hasEcspInvestmentsError } =
    investmentsRootSelector(state);

  return {
    hasInvestments: data?.hasInvestments || null,
    hasEcspInvestments: data?.hasEcspInvestments || null,
    hasInvestmentsLoading,
    hasInvestmentsError,
    hasEcspInvestmentsLoading,
    hasEcspInvestmentsError,
  };
}
