import React from 'react';
import * as Styled from './styled';
import type { PolymorphicComponentPropWithRef, PolymorphicRef, As } from 'ui/helper/get-element-type';
import type { BoxAlignOptions, BoxDirectionOptions, BoxJustifyOptions } from './styled';
import type { Colors } from 'ui/styles/utils/colors';
import type { Spacing } from 'ui/styles/themes/spacing';

export type BoxTypes =
  | 'article'
  | 'aside'
  | 'section'
  | 'blockquote'
  | 'dialog'
  | 'div'
  | 'nav'
  | 'main'
  | 'footer'
  | 'header'
  | 'figure';

export interface BoxProps {
  children: React.ReactNode;
  justify?: BoxJustifyOptions;
  align?: BoxAlignOptions;
  direction?: BoxDirectionOptions;
  bgColor?: Colors;
  spacing?: Spacing;
  flex?: string;
  wrap?: boolean;
  className?: string;
}

export type BoxAsProps = BoxProps & As<BoxTypes>;

export type BoxComponentProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, BoxAsProps>;

export type BoxComponent = <C extends React.ElementType = 'div'>(
  props: BoxComponentProps<C>,
) => React.ReactElement | null;

const Box: BoxComponent = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, children, justify, align, direction, spacing, bgColor, flex, wrap, className, ...rest }: BoxComponentProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    return (
      <Styled.Box
        as={as || 'div'}
        ref={ref}
        $justify={justify}
        $direction={direction}
        $align={align}
        $bgColor={bgColor}
        $spacing={spacing}
        $flex={flex}
        $wrap={wrap}
        className={className}
        {...rest}
      >
        {children}
      </Styled.Box>
    );
  },
);

export default Box;
