import { Modal } from 'src/ui/molecules/modal';
import styled from 'styled-components';

export const DocumentModal = styled(Modal)`
  width: 90%;
  height: 98%;
  margin: auto;
  embed {
    width: 100%;
    min-height: calc(100vh - 15rem);
  }
`;
