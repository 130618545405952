import React, { ElementType, FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import * as Styled from './styled';

import Segment from 'ui/atoms/segment';
import Header from 'ui/atoms/header';
import ActionButtons from '../action-buttons';
import type { ActionButton } from '../action-buttons';
import HeaderSize from 'src/ui/types/header-size';

export interface BoxedContentProps {
  title?: ReactNode;

  actionPanel?: ReactNode;

  headerPanel?: ReactNode;

  actionButtons?: ActionButton[];

  releaseSpaceWhenTitleIsEmpty?: boolean;

  headerSize?: HeaderSize;

  headerAs?: ElementType;

  className?: string;

  breakWords?: boolean;

  fullHeight?: boolean;
}

export const BoxedContent: FunctionComponent<BoxedContentProps> = (props) => {
  const {
    className,
    children,
    releaseSpaceWhenTitleIsEmpty,
    headerSize = 'small',
    headerAs = 'h1',
    title,
    actionPanel,
    actionButtons = [],
    breakWords,
    fullHeight,
  } = props;

  return (
    <Styled.BoxedContent $fullHeight={fullHeight} className={cx('boxed-content', className)}>
      {(title || (!title && !releaseSpaceWhenTitleIsEmpty)) && (
        <Header as={headerAs} size={headerSize} spacing="medium">
          {title || <>&nbsp;</>}
        </Header>
      )}
      <Segment raised={true} breakWords={breakWords} fullHeight>
        {children}
        {actionPanel && <Styled.ActionPanel>{actionPanel}</Styled.ActionPanel>}
        {!!actionButtons.length && <ActionButtons actionButtons={actionButtons} />}
      </Segment>
    </Styled.BoxedContent>
  );
};

export default BoxedContent;
