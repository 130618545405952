import styled from 'styled-components';

export const NumberOfInvestments = styled.p`
  text-transform: lowercase;
`;

export const HeaderRow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  line-height: 140%;
`;
