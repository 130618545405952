import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import useForm from 'ui/hooks/use-form';
import { validateEmailAddress } from 'ui/helper/validators';

import Translate from 'ui/atoms/translate';
import Input from 'ui/atoms/input';
import Button from 'ui/atoms/button';
import Checkbox from 'ui/atoms/checkbox';
import Link from 'ui/atoms/link';
import FormGroup from 'ui/molecules/form-group';

import ServerError from 'ui/types/server-error';
import { PlatformUrl } from 'ui/types/urls';
import useTranslate from 'ui/hooks/use-translate';
import { handleError } from 'ui/helper/error-handling';
import * as Styled from './styled';

export interface IssuerRegistrationFields {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  consentPrivacy: boolean;
}

export interface DemoIssuerRegistrationFormProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (values: IssuerRegistrationFields) => void;

  /** Is the form being submitted? */
  loading?: boolean;

  /** error */
  error?: ServerError;
}

const DemoIssuerRegistrationForm: FunctionComponent<DemoIssuerRegistrationFormProps> = (props) => {
  const { className, error, onSubmit = () => {}, loading = false, ...restProps } = props;

  const translate = useTranslate();

  const [formState, { checkbox, password, text, submit }] = useForm<IssuerRegistrationFields>({
    validateFields: [
      'companyName',
      'firstName',
      'lastName',
      'email',
      'password',
      'passwordConfirmation',
      'consentPrivacy',
    ],
  });

  const { getErrorForField, getRemainingError } = handleError({
    error,
    translate,
    specialCodes: {
      user_already_registered: {
        field: 'email',
        translateKey: 'demo.issuerReg.error.userAlreadyRegistered',
      },
      company_already_registered: {
        field: 'company_name',
        translateKey: 'demo.issuerReg.error.companyAlreadyRegistered',
      },
    },
  });

  return (
    <div className={cx('demo-issuer-registration-form', className)} {...restProps}>
      <p>
        <Translate name="demo.issuerReg.text" args={[(txt: string, key) => <strong key={key}>{txt}</strong>]} />
      </p>

      <form noValidate autoComplete="off" {...submit(onSubmit)}>
        <FormGroup error={getErrorForField('company_name')}>
          <Input
            label={<Translate name="demo.issuerReg.labelCompanyName" />}
            required={true}
            {...text('companyName')}
          />
        </FormGroup>

        <FormGroup error={getErrorForField('forename')}>
          <Input label={<Translate name="demo.registration.labelFirstName" />} required={true} {...text('firstName')} />
        </FormGroup>

        <FormGroup error={getErrorForField('surname')}>
          <Input label={<Translate name="demo.registration.labelLastName" />} required={true} {...text('lastName')} />
        </FormGroup>

        <FormGroup error={getErrorForField('email')}>
          <Input
            label={<Translate name="demo.issuerReg.labelEmail" />}
            required={true}
            {...text('email', { validate: validateEmailAddress })}
          />
        </FormGroup>

        <FormGroup error={getErrorForField('password')}>
          <Input
            label={<Translate name="demo.issuerReg.labelPassword" />}
            required={true}
            minLength={8}
            {...password('password')}
          />
        </FormGroup>

        <FormGroup>
          <Input
            label={<Translate name="demo.issuerReg.labelPasswordConfirm" />}
            required={true}
            minLength={8}
            {...password('passwordConfirmation', {
              validate: (value, values) => value && values.password === value,
            })}
          />
        </FormGroup>

        <FormGroup>
          <Checkbox {...checkbox('consentPrivacy')}>
            <Translate
              name="demo.registration.labelConsentPrivacy"
              args={[
                (txt: string, key) => (
                  <Link key={key} platformUrl={PlatformUrl.PRIVACY_POLICY} external>
                    {txt}
                  </Link>
                ),
              ]}
            />
          </Checkbox>
        </FormGroup>

        {((msg) => msg && <Styled.Error>{msg}</Styled.Error>)(getRemainingError())}

        <Button
          type="submit"
          size="large"
          variant="primary"
          fluid={true}
          disabled={!formState.isValid}
          loading={loading}
        >
          <Translate name="demo.registration.button" />
        </Button>
      </form>
    </div>
  );
};

export default DemoIssuerRegistrationForm;
