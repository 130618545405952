import React from 'react';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';

const SuccessIdentification = () => {
  return (
    <>
      <Hint variant="success">
        <Translate name="dashboardIssuerInvestorDetails.successIdentification.hint" />
      </Hint>
    </>
  );
};

export default SuccessIdentification;
