import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Link from 'core/routing/link';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import useApiCall from 'src/hooks/use-api-call';
import { AdminApi, AdminBundesbankSettlementInstruction } from 'src/api';
import GeneralInformation from './general-information';
import PaymentInstruction from './payment-instruction';
import Customers from './customers';

interface IssuerTriggerSolutionDetailsPageProps {
  settlementId: string;
}

const IssuerTriggerSolutionDetailsPage: FunctionComponent<IssuerTriggerSolutionDetailsPageProps> = ({
  settlementId,
}) => {
  const [settlement, setSettlement] = useState<AdminBundesbankSettlementInstruction>();

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      const settlement = await api.adminBundesbankSettlementInstructionsRetrieve({ id: settlementId });
      setSettlement(settlement);
    });
  }, [withApi, settlementId]);

  useEffect(() => {
    loadData();
  }, [loadData, settlementId]);

  if (!settlement)
    return (
      <WideContent title={<Translate name="dashboardIssuerTriggerSolutionDetails.title" />}>
        <LoadingRing />
      </WideContent>
    );

  return (
    <>
      <Section spacing="large">
        <WideContent
          title={
            <div>
              {settlement.productName} - <Translate name="dashboardIssuerTriggerSolutionDetails.title" />
            </div>
          }
          titleSpacing="tiny"
        >
          <GeneralInformation settlement={settlement} />
          <PaymentInstruction settlement={settlement} />
          <Customers settlement={settlement} />
        </WideContent>
      </Section>

      <WideContent>
        <Link icon="arrow-left" to="/dashboard/triggerSolutionSettlements">
          <Translate as="span" name="dashboardIssuerTriggerSolutionDetails.goToSettlements" />
        </Link>
      </WideContent>
    </>
  );
};

export default WithAuthenticatedPage(IssuerTriggerSolutionDetailsPage, AppType.ISSUER);
