import React, { FunctionComponent } from 'react';
import Section from 'ui/atoms/section';
import FormSegmentHeader from 'libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment/form-segment-header';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { Money } from 'api';
import Currency from 'ui/atoms/currency';
import { toMoney } from 'ui/helper/money';
import { useIsNetWorthSuitable } from 'libraries/wizard/components/questionnaire/shared/risk-factors';

export interface QuestionnaireSummaryNetworthProps {
  investmentTotal?: Money | null;
  index: number;
  lossability?: Money | null;
  netWorth?: Money | null;
}

const QuestionnaireSummaryNetworth: FunctionComponent<QuestionnaireSummaryNetworthProps> = (props) => {
  const { investmentTotal, lossability, netWorth, index } = props;

  const isNetworthSuitable = useIsNetWorthSuitable(lossability, investmentTotal);

  if (!investmentTotal) return null;

  return (
    <Section spacing="large">
      <FormSegmentHeader segmentIndex={index} segmentId="networth" />
      {netWorth ? (
        <Hint variant={isNetworthSuitable ? 'success' : 'warning'}>
          <span>
            <Translate
              name={`questionnaireSummary.hints.${isNetworthSuitable ? 'networthSuitable' : 'networthUnsuitable'}`}
              args={[
                (_, key) => (
                  <strong>
                    <Currency key={key}>{netWorth || toMoney(0)}</Currency>
                  </strong>
                ),
                (_, key) =>
                  investmentTotal && (
                    <strong>
                      <Currency key={key}>{investmentTotal}</Currency>
                    </strong>
                  ),
              ]}
            />
          </span>
        </Hint>
      ) : (
        <Hint variant="info">
          <span>
            <Translate name="questionnaireSummary.hints.noAnswersNetworth" />
          </span>
        </Hint>
      )}
    </Section>
  );
};

export default QuestionnaireSummaryNetworth;
