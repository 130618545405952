import { AdminApi, CountryEnum, DocumentsApi, IdentificationDocumentTypeEnum, SalutationEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import AddIdentificationFormData from './form-data';
import ConfirmIdentification from './confirm-identification';
import SuccessIdentification from './success-identification';
import ChangeIdentificationAddress from 'ui/types/change-identification-address';
import type { ActionButton } from 'ui/molecules/action-buttons';

export interface AddIdentificationFields {
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  dateOfBirth: Date;
  placeOfBirth: string;
  nationality: CountryEnum;
  verifiedAt: Date;
  legitimationProtocol: Blob | null;
  documentId: string;
  documentType: IdentificationDocumentTypeEnum;
  documentValidFrom: Date;
  documentValidTo: Date;
  issuingOffice: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  changeAddress?: string;
}

export interface RegisteredAddress {
  street: string;
  zip: string;
  city: string;
  country: string;
}

export interface PersonalData {
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  dateOfBirth: Date | undefined;
  placeOfBirth: string;
  nationality: CountryEnum;
}

export interface AddIdentificationModalProps {
  onHideAddIdentification: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  investorId: string;
  registeredAddress: RegisteredAddress;
  personalData: PersonalData;
}

const AddIdentificationForm = makeModalForm<AddIdentificationFields>();

const AddIdentificationModal: FunctionComponent<AddIdentificationModalProps> = (props) => {
  const { onHideAddIdentification, fetchInvestorDetails, investorId, registeredAddress, personalData } = props;

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const [identification, setIdentification] = useState<AddIdentificationFields>();

  const [confirmIdentification, setConfirmIdentification] = useState(false);

  const [successIdentification, setSuccessIdentification] = useState(false);

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const uploadFile = async (legitimationProtocolDoc: Blob) => {
    const legitimationProtocolDocResponse = await documentsApi.adminDocumentsCreate({ file: legitimationProtocolDoc });
    return legitimationProtocolDocResponse.id;
  };

  const addIdentification = (values: StateValues<AddIdentificationFields>) => {
    withApi(async () => {
      const naturaPersonBody = {
        street: values.street,
        zip: values.zip,
        city: values.city,
        country: values.nationality,
        salutation: values.salutation,
        forename: values.forename,
        surname: values.surname,
        birthDate: new Date(values.dateOfBirth),
        birthPlace: values.placeOfBirth,
        nationality: values.nationality,
      };
      const patchBodyPersonalData = {
        id: investorId,
        patchedAdminInvestorUpdateRequest: {
          naturalPerson: naturaPersonBody,
        },
      };
      await adminApi.adminInvestorsPartialUpdate(patchBodyPersonalData);

      if (values.legitimationProtocol) {
        const documentId = await uploadFile(values.legitimationProtocol);
        const documentBody = {
          legitimationProtocolId: documentId,
          documentId: values.documentId,
          documentValidFrom: values.documentValidFrom,
          verifiedAt: values.verifiedAt,
          documentValidTo: values.documentValidTo,
          documentType: values.documentType,
          documentIssuer: values.issuingOffice,
        };
        const patchBodyDocument = {
          id: investorId,
          identificationRequest: documentBody,
        };
        await adminApi.adminInvestorsIdentificationsCreate(patchBodyDocument);
      }
      setSuccessIdentification(true);
      fetchInvestorDetails();
    });
  };

  const continueAddIdentification = (values: AddIdentificationFields) => {
    if (successIdentification) {
      onHideAddIdentification();
    } else if (confirmIdentification) {
      addIdentification(values);
    } else {
      setConfirmIdentification(true);
    }
  };

  const renderActionButtons = () => {
    let buttonsArr: ActionButton[] =
      !successIdentification && !confirmIdentification
        ? [
            {
              name: 'cancel',
              content: <Translate name="common.cancel" />,
              size: 'large',
              onClick: onHideAddIdentification,
            },
          ]
        : [];

    buttonsArr.push({
      name: 'send',
      content: (
        <Translate
          name={
            successIdentification
              ? 'dashboardIssuerInvestorDetails.successIdentification.button'
              : confirmIdentification
              ? 'dashboardIssuerInvestorDetails.confirmIdentificationForm.primaryButton'
              : 'dashboardIssuerInvestorDetails.addIdentificationForm.button'
          }
        />
      ),
      size: 'large',
      variant: 'primary',
      type: 'submit',
    });
    return buttonsArr;
  };

  const renderModalContent = () => {
    if (successIdentification) {
      return <SuccessIdentification />;
    }
    if (confirmIdentification && identification) {
      return (
        <ConfirmIdentification identification={identification} goToEditData={() => setConfirmIdentification(false)} />
      );
    }
    return (
      <AddIdentificationFormData
        changeAddress={identification?.changeAddress === ChangeIdentificationAddress.INCORRECTADDRESS}
        AddIdentificationForm={AddIdentificationForm}
        registeredAddress={registeredAddress}
        documentValidFrom={identification?.documentValidFrom}
        documentValidTo={identification?.documentValidTo}
        nationality={identification?.nationality}
        onChangeNationality={(nationality: CountryEnum) => {
          if (identification) {
            setIdentification({ ...identification, nationality });
          }
        }}
        country={identification?.country}
        onChangeCountry={(country: string) => {
          if (identification) {
            setIdentification({ ...identification, country });
          }
        }}
        onChangeDocumentType={(documentType: IdentificationDocumentTypeEnum) => {
          if (identification) {
            setIdentification({ ...identification, documentType });
          }
        }}
      />
    );
  };

  return (
    <>
      <Modal onClose={onHideAddIdentification}>
        <AddIdentificationForm
          initial={{
            salutation: personalData.salutation,
            forename: personalData.forename,
            surname: personalData.surname,
            dateOfBirth: personalData.dateOfBirth,
            placeOfBirth: personalData.placeOfBirth,
            nationality: personalData.nationality,
            documentType: IdentificationDocumentTypeEnum.UNKNOWN,
            documentValidFrom: undefined,
            documentValidTo: undefined,
            verifiedAt: new Date(),
            street: registeredAddress.street,
            zip: registeredAddress.zip,
            city: registeredAddress.city,
            country: registeredAddress.country,
            changeAddress: ChangeIdentificationAddress.CORRECTADDRESS,
            legitimationProtocol: null,
          }}
          onChange={(values: StateValues<AddIdentificationFields>) => {
            setIdentification(values);
          }}
          onSubmit={(values: StateValues<AddIdentificationFields>) => {
            continueAddIdentification(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.addIdentificationForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.title" />
          </ModalHeader>
          <ModalContent>{renderModalContent()}</ModalContent>
          <ModalFooter>
            <ModalFooterButtons actionButtons={renderActionButtons()} />
          </ModalFooter>
        </AddIdentificationForm>
      </Modal>
    </>
  );
};

export default AddIdentificationModal;
