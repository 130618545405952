import { RootAppState } from 'store/types';

export function authRootSelector(state: RootAppState) {
  return state.auth;
}

export function currentUserSelector(state: RootAppState) {
  const { data, loading, error } = authRootSelector(state).me;

  return { currentUser: data, loading, error };
}

export function isLoggedInSelector(state: RootAppState) {
  return !!currentUserSelector(state)?.currentUser && !!authTokenSelector(state).authToken;
}

export function authTokenSelector(state: RootAppState) {
  const { data, loading, error } = authRootSelector(state).authToken;

  return { authToken: data, loading, error };
}

export function authIsVerifiedSelector(state: RootAppState) {
  return authRootSelector(state).authVerified;
}

export function redirectPathSelector(state: RootAppState) {
  return {
    path: authRootSelector(state).redirectPath,
    origin: authRootSelector(state).redirectOrigin,
  };
}
