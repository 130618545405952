export enum UserType {
  Issuer,
  Investor,
  Default,
}

export enum AppType {
  ISSUER = 'issuer',
  INVESTOR = 'investor',
  INVESTMENT = 'investment',
  DEFAULT = 'default',
}
