import React from 'react';
import { InvestmentTokenForInvestor, InvestorStepEnum, InvestorWizardStep, StepEnum, WizardStep } from 'api/models';
import some from 'lodash/some';
import find from 'lodash/find';
import get from 'lodash/get';
import Translate from 'ui/atoms/translate';
import { WizardSidebarStep } from 'subapps/investment/pages/investment/wizard-steps/sidebar-mapping';
import { InvestorSidebarStep } from 'subapps/investment/pages/register/helpers/sidebar-mapping';

export interface sidebarMappingInterface<
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
> {
  sidebarStep: SS;
  wizardSteps: SE[];
}

function getFirstVisitableWizardStepOfGroup<
  WS extends WizardStep | InvestorWizardStep,
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
>(sidebarStep: SS, wizardSteps: WS[], sidebarMapping: sidebarMappingInterface<SS, SE>[]) {
  const revisitableOrNotDoneWizardSteps = wizardSteps
    .filter(({ done, revisitable }) => revisitable)
    .map(({ step }) => step);

  const wizardStepsOfGroup = get(find(sidebarMapping, { sidebarStep }), 'wizardSteps');

  return find(wizardStepsOfGroup, (wizardStep) => revisitableOrNotDoneWizardSteps.includes(wizardStep as SE));
}

function getActiveSidebarStep<
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
>(sidebarMappingData: sidebarMappingInterface<SS, SE>[], wizardStep: StepEnum | InvestorStepEnum) {
  return get(
    find(sidebarMappingData, ({ wizardSteps }) => wizardStep && wizardSteps?.includes(wizardStep as SE)),
    'sidebarStep',
  );
}

function isSidebarStepDone<
  WS extends WizardStep | InvestorWizardStep,
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
>(sidebarMappingData: sidebarMappingInterface<SS, SE>[], sidebarStep: SS, wizardSteps: WS[]) {
  // get all wizard steps from the mapping but filter out all wizard steps not present in backend
  const wizardStepsOfGroup = get(find(sidebarMappingData, { sidebarStep }), 'wizardSteps', []).filter((wizardStep) =>
    find(wizardSteps, { step: wizardStep }),
  );

  // get all done wizard steps from backend
  const doneWizardSteps = wizardSteps.filter((wizardStep) => wizardStep.done).map((wizardStep) => wizardStep.step);

  const doneWizardStepsOfGroup = wizardStepsOfGroup.filter((wizardStep) => doneWizardSteps.includes(wizardStep));

  if (!wizardStepsOfGroup) return false;

  return doneWizardStepsOfGroup.length === wizardStepsOfGroup.length;
}

function isCurrentSidebarStep<
  WS extends WizardStep | InvestorWizardStep,
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
>(sidebarMappingData: sidebarMappingInterface<SS, SE>[], sidebarStep: SS, wizardSteps: WS[]) {
  const wizardStepsOfGroup: string[] = get(find(sidebarMappingData, { sidebarStep }), 'wizardSteps', []).filter(
    (wizardStep) => find(wizardSteps, { step: wizardStep }),
  );

  //get first not-done step
  const currentStep: StepEnum | InvestorStepEnum =
    wizardSteps.find((wizardStep) => !wizardStep.done)?.step || wizardSteps.pop()?.step || StepEnum.ACCOUNT_SETUP;

  return wizardStepsOfGroup.includes(currentStep);
}

export function makeSidebarSteps<
  WS extends WizardStep | InvestorWizardStep,
  SS extends WizardSidebarStep | InvestorSidebarStep,
  SE extends StepEnum | InvestorStepEnum,
>(
  wizardStep: SE,
  availableWizardSteps: WS[] = [],
  onShouldChangeWizardStep: (step?: SE) => void = () => {},
  sidebarMapping: sidebarMappingInterface<SS, SE>[],
  token?: InvestmentTokenForInvestor,
) {
  if (!availableWizardSteps || !wizardStep) return undefined;

  const sidebarMappingData = sidebarMapping;

  const activeSidebarStep = getActiveSidebarStep(sidebarMappingData, wizardStep);

  // get all sidebar steps where its "wizardSteps" are included in "availableWizardSteps"
  const sidebarSteps = sidebarMappingData.filter(({ wizardSteps, sidebarStep }) => {
    return some(wizardSteps, (wizardStep) => find(availableWizardSteps, { step: wizardStep }));
  });

  return sidebarSteps.map(({ sidebarStep }, index) => {
    const firstVisitableWizardStep = getFirstVisitableWizardStepOfGroup(
      sidebarStep,
      availableWizardSteps,
      sidebarMappingData,
    );
    const done = isSidebarStepDone(sidebarMappingData, sidebarStep, availableWizardSteps);
    const active = activeSidebarStep === sidebarStep;
    const current = isCurrentSidebarStep(sidebarMappingData, sidebarStep, availableWizardSteps);

    const getTooltip = () => {
      if (!token) return sidebarStep;
      if (
        [WizardSidebarStep.ADDITIONAL_INFORMATION, InvestorSidebarStep.ADDITIONAL_INFORMATION].includes(sidebarStep)
      ) {
        if ((token.bankAccountRequired || token.securitiesDepositAccountRequired) && token.taxInformationRequired)
          return 'additionalInformationBankAccountAndTaxInformationRequired';
        if (token.bankAccountRequired || token.securitiesDepositAccountRequired)
          return 'additionalInformationBankAccountRequired';
        if (token.taxInformationRequired) return 'additionalInformationTaxInformationRequired';
      }

      return sidebarStep;
    };

    return {
      id: index,
      active: active,
      current: current,
      done: done,
      title: <Translate name={`investmentProgress.${sidebarStep}.title`} />,
      tooltip: <Translate name={`investmentProgress.${getTooltip()}.tooltip`} />,
      onClick: firstVisitableWizardStep
        ? () => {
            onShouldChangeWizardStep(firstVisitableWizardStep as SE);
          }
        : undefined,
    };
  });
}

export default makeSidebarSteps;
