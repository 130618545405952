export enum InvestorAppActions {
  LOAD_INVESTOR_APP = 'APP/LOAD_INVESTOR_APP',
}

export type LoadInvestorApp = { type: InvestorAppActions.LOAD_INVESTOR_APP };

export type InvestorAppActionTypes = LoadInvestorApp;

export function loadInvestorApp(): LoadInvestorApp {
  return {
    type: InvestorAppActions.LOAD_INVESTOR_APP,
  };
}
