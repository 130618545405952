import styled from 'styled-components';

export const PostIndentImage = styled.img`
  margin-left: -14px;
  width: 100%;
`;

export const ErrorText = styled.small`
  color: red;
`;
