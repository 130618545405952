import React, { FunctionComponent } from 'react';
import AdditionalInformation from 'libraries/wizard/components/additional-information';
import useAdditionalInformation from './use-additional-information';

const AdditionalInformationStep: FunctionComponent<{}> = () => {
  const props = useAdditionalInformation();

  return <AdditionalInformation {...props} />;
};

export default AdditionalInformationStep;
