import styled, { css, Keyframes, keyframes } from 'styled-components';
import { query } from 'ui/styles/queries';
import { resolveColor } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';
import Padding from 'src/ui/types/padding';

export type ModalSizes = 'auto' | 'fullWidth' | 'fullHeight' | 'small' | 'regular' | 'large';

export type ModalPositions = 'none' | 'centered' | 'centeredTop' | 'left' | 'right' | 'top' | 'bottom';

export const ModalSizesMap = {
  auto: 'auto',
  fullWidth: 'fullWidth',
  fullHeight: 'fullHeight',
  small: 'small',
  regular: 'regular',
  large: 'large',
};

export const ModalPositionsMap = {
  none: 'none',
  centered: 'centered',
  centeredTop: 'centeredTop',
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

export const keyframeSlideX = (from: number, to: number) => keyframes`
    from {
      transform: translateX(${from}vw);
    }

    to {
      transform: translateX(${to}vw);
    }
`;

export const keyframeSlideY = (from: number, to: number) => keyframes`
    from {
      transform: translateY(${from}vh);
    }

    to {
      transform: translateY(${to}vh);
    }
`;

export const slideInTop = keyframeSlideY(-100, 0);
export const slideInBottom = keyframeSlideY(100, 0);
export const slideInLeft = keyframeSlideX(-100, 0);
export const slideInRight = keyframeSlideX(100, 0);

export const keyframeFadeInModalBG = (from: number, to: number) => keyframes`
  from {
    opacity: ${from};
  }

  to {
    opacity: ${to};
  }
`;

const animate = (anim: Keyframes) => css`
  animation: ${anim} 400ms cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const largePhoneStyle = css`
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
  margin: 0;
`;

export const ModalBase = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.modal};
`;

export const ModalBackground = styled.div<{ $canAnimate?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #333333;
  opacity: ${({ theme }) => theme.modal.backdropOpacity};

  ${({ theme, $canAnimate }) => $canAnimate && animate(keyframeFadeInModalBG(0, theme.modal.backdropOpacity))}
`;

export const ModalPosition = styled.div<{ $position: ModalPositions; $canAnimate?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;

  ${({ $position, $canAnimate }) => {
    switch ($position) {
      case ModalPositionsMap.centered:
        return css`
          justify-content: center;
          align-items: center;

          ${$canAnimate && animate(slideInBottom)};
        `;
      case ModalPositionsMap.centeredTop:
        return css`
          justify-content: center;
          align-items: flex-start;
          margin-top: 100px;

          ${$canAnimate && animate(slideInBottom)};
        `;
      case ModalPositionsMap.top:
        return css`
          align-items: flex-start;

          ${$canAnimate && animate(slideInBottom)};
        `;
      case ModalPositionsMap.right:
        return css`
          justify-content: flex-end;

          ${$canAnimate && animate(slideInRight)};
        `;
      case ModalPositionsMap.bottom:
        return css`
          align-items: flex-end;

          ${$canAnimate && animate(slideInBottom)};
        `;
      case ModalPositionsMap.left:
        return css`
          justify-content: flex-start;

          ${$canAnimate && animate(slideInLeft)};
        `;
      default:
        return css`
          justify-content: center;
          align-items: center;

          ${$canAnimate && animate(slideInBottom)};
        `;
    }
  }}
`;

export const ModalBody = styled.div<{
  $canAnimate?: boolean;
  $hasBorderRadius?: boolean;
  $padding?: Padding;
  $size: ModalSizes;
  $maxWidth?: string;
  $maxHeight?: string;
  $bgColor?: Colors;
}>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
  max-height: ${({ $maxHeight }) => $maxHeight || 'auto'};
  padding: ${({ theme, $padding }) => ($padding ? theme.padding[$padding] : '40px')};
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${({ theme, $bgColor }) => resolveColor(theme, $bgColor)};
  box-shadow: ${({ theme }) => theme.modal.shadow};
  border-radius: ${({ $hasBorderRadius }) => ($hasBorderRadius ? '20px' : '0')};

  ${({ $size }) => {
    switch ($size) {
      case 'auto':
        return css`
          width: auto;
          min-height: 10vh;
          margin-top: 64px;
          margin-bottom: 64px;

          ${query.phoneTablet`${largePhoneStyle}`}
        `;
      case 'small':
        return css`
          max-width: 460px;
        `;
      case 'regular':
        return css`
          flex-basis: 500px;
          max-width: 500px;
          max-height: calc(100vh - 3.5rem);
          min-height: 10vh;
          margin: 1.75rem auto;

          ${query.phoneTablet`${largePhoneStyle}`}
        `;
      case 'large':
        return css`
          flex-basis: 700px;
          max-width: 700px;
          max-height: calc(100vh - 3.5rem);
          min-height: 10vh;
          margin: 1.75rem auto;

          ${query.phoneTablet`${largePhoneStyle}`}
        `;
      case 'fullWidth':
        return css`
          width: 100%;
          height: auto;
        `;
      case 'fullHeight':
        return css`
          width: auto;
          height: 100%;
          max-height: 100%;
        `;

      default:
        return css`
          width: auto;
          height: auto;
        `;
    }
  }}
`;
