import {
  useSelector as reactReduxUseSelector,
  useStore as reactReduxUseStore,
  useDispatch as reactReduxUseDispatch,
} from 'react-redux';
import { Action, AnyAction, Dispatch } from 'redux';
import { CashlinkStore, RootAppState } from 'store/types';

export function useStore<S extends RootAppState = RootAppState, A extends Action = AnyAction>() {
  return reactReduxUseStore<S, A>() as CashlinkStore<S, A>;
}

export function useDispatch<TDispatch = Dispatch<any>>() {
  return reactReduxUseDispatch<TDispatch>();
}

export function useSelector<TState extends RootAppState = RootAppState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) {
  return reactReduxUseSelector<TState, TSelected>(selector, equalityFn);
}
