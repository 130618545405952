import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import type { BaseTheme } from 'ui/styles/theme.types';

export const StylesProvider: FunctionComponent<{
  theme: BaseTheme;
}> = ({ children, theme }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default {
  StylesProvider,
};
