export enum WalletType {
  TANGANY = 'TANGANY',
  GENERIC = 'GENERIC',
  MOBILE = 'MOBILE',
  CASHLINK = 'CASHLINK',
}

export interface EthWalletInfo {
  address: string;
}

export interface StellarWalletInfo {
  accountId: string;
}

export interface WalletNetworkInfo {
  ethInfo?: EthWalletInfo | null;
  stellarInfo?: StellarWalletInfo | null;
}

export interface Wallet extends WalletNetworkInfo {
  id: string;
  confirmed: boolean;
  type: WalletType;
  managed: boolean;
}
