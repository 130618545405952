import React, { FunctionComponent, useContext } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import ServerError from 'ui/types/server-error';
import { Document } from 'ui/types/document';
import { PersonType } from 'ui/types/person';
import { PasswordStrength } from 'ui/atoms/strength-indicator';
import ExternalInvestorHint from 'libraries/wizard/components/account-setup/create-account/external-investor-hint';
import Section from 'ui/atoms/section';
import ExternalCampaignInfo from 'src/libraries/wizard/components/account-setup/create-account/external-campaign-info';
import { getNormalisedUserDataForPasswordEvaluation } from 'ui/helper/password';
import { SalutationEnum } from 'api';
import {
  LegalPersonFields,
  NaturalPersonFields,
  InvestmentCreateAccountValues as CreateAccountValuesCompatibility,
} from 'libraries/wizard/components/account-setup/create-account/types';
import { CreateAccountForm, fromUI, toUI } from 'src/libraries/wizard/components/account-setup/create-account/helpers';
import NaturalPerson from 'libraries/wizard/components/account-setup/create-account/natural-person';
import LegalPerson from 'libraries/wizard/components/account-setup/create-account/legal-person';
import AccountCredentials from 'src/libraries/wizard/components/account-setup/create-account/account-credentials';
import AcceptCreateAccountDocuments from 'libraries/wizard/components/account-setup/create-account/create-account-documents';
import CommunicationSettings from 'libraries/wizard/components/account-setup/create-account/communication-settings';
import WizardContext from 'libraries/wizard/wizard-context';
import Header from 'ui/atoms/header';

export type CreateAccountValues = CreateAccountValuesCompatibility;

export interface CreateAccountDocuments {
  effectaAccountSetupDocument: Document;
  effectaTermsConditions: Document;
}
export interface CreateAccountProps {
  /** Additional classes. */
  className?: string;

  /** Indicates a loading state */
  loading?: boolean;

  /** On submit callback */
  onSubmit?: (values: CreateAccountValuesCompatibility) => void;

  /** Get password strength */
  getPasswordStrength?: (password: string, values?: string[]) => PasswordStrength | undefined;

  /** Error message */
  error?: ServerError;

  createAccountMode?: boolean;

  allowedPersonTypes?: PersonType[];

  externalInvitation?: {
    naturalPerson: {
      salutation?: SalutationEnum;
      forename: string;
      surname: string;
      birthDate?: Date | null;
      birthPlace?: string;
    };
  };

  marketingOptsEnabled?: Array<string>;

  // TODO: alternative: do the check whether email input should be disabled or not via
  //  initial.person.email -> if defined disable email input field
  shouldValidateEmail: boolean;

  documents: CreateAccountDocuments;

  initial?: {
    personType?: PersonType;
    naturalPerson?: Partial<NaturalPersonFields>;
    legalPerson?: Partial<LegalPersonFields>;
    email?: string;
  };
}

// TODO(geforcefan): evaluate form connector usage, maybe add onChange to form connector? make more typesafe,
//  or more convenient to aggregate sub forms (e.g. name typesafe, onSubmit typesafe)
const CreateAccount: FunctionComponent<CreateAccountProps> = (props) => {
  const {
    className,
    onSubmit = () => {},
    getPasswordStrength = () => {
      return undefined;
    },
    documents,
    error,
    loading,
    shouldValidateEmail,
    createAccountMode,
    externalInvitation,
    marketingOptsEnabled,
    initial,
    allowedPersonTypes = [PersonType.Legal, PersonType.Natural],
    ...restProps
  } = props;

  const { isRegisterOnlyFlow } = useContext(WizardContext);

  return (
    <div className={cx('investment-offer', className)} {...restProps}>
      {externalInvitation && <ExternalCampaignInfo externalNaturalPerson={externalInvitation.naturalPerson} />}
      <Section spacing={createAccountMode ? 'medium' : 'none'}>
        <CreateAccountForm
          i18nKey="createAccount"
          onSubmit={(values) => {
            // TODO(geforcefan): refactor asap, remove unnecessary mapping
            onSubmit(fromUI(values));
          }}
          initial={{
            ...toUI(initial || {}),
            personType:
              initial?.personType || (allowedPersonTypes.length === 1 ? allowedPersonTypes[0] : PersonType.Natural),
          }}
          error={error}
        >
          {createAccountMode && !externalInvitation && allowedPersonTypes.length > 1 && (
            <Section spacing="medium">
              <Header size="small">
                <Translate
                  as="p"
                  name={
                    isRegisterOnlyFlow
                      ? 'createAccount.fields.personTypeForRegistration.label'
                      : 'createAccount.fields.personTypeForInvestment.label'
                  }
                />
              </Header>
              <CreateAccountForm.Group name="personType" required={true}>
                <CreateAccountForm.GroupToggle>
                  {allowedPersonTypes.map((personType, index) => (
                    <CreateAccountForm.Radio value={personType} key={index}>
                      <Translate name={`personTypes.${personType}`} />
                    </CreateAccountForm.Radio>
                  ))}
                </CreateAccountForm.GroupToggle>
              </CreateAccountForm.Group>
            </Section>
          )}

          {externalInvitation && <ExternalInvestorHint externalNaturalPerson={externalInvitation.naturalPerson} />}

          <CreateAccountForm.ValueProvider>
            {(values) => (
              <>
                {values.personType === PersonType.Legal && <LegalPerson country={values.legalCountry} />}

                {values.personType && (
                  <NaturalPerson
                    isExternalInvitation={!!externalInvitation}
                    personType={values.personType}
                    naturalBirthCountry={values.naturalBirthCountry}
                    nationality={values.nationality}
                    naturalCountry={values.naturalCountry}
                  />
                )}

                {createAccountMode && (
                  <AccountCredentials
                    passwordStrength={
                      values.password
                        ? getPasswordStrength(
                            values.password,
                            // normalized user inputs will be considered when evaluating password strength
                            getNormalisedUserDataForPasswordEvaluation({
                              email: values.email,
                              forename: values.naturalSurname,
                              surname: values.naturalForename,
                            }),
                          )
                        : undefined
                    }
                    shouldValidateEmail={shouldValidateEmail}
                  />
                )}
              </>
            )}
          </CreateAccountForm.ValueProvider>
          {createAccountMode && <AcceptCreateAccountDocuments documents={documents} />}

          {createAccountMode && !!marketingOptsEnabled?.length && (
            <CommunicationSettings marketingOptsEnabled={marketingOptsEnabled} />
          )}
          <CreateAccountForm.Submit variant="primary" size="large" loading={loading}>
            <Translate name={'common.continue'} />
          </CreateAccountForm.Submit>
        </CreateAccountForm>
      </Section>
      {createAccountMode && (
        <Translate
          as="small"
          name="pepDefinition.description"
          args={[<Translate key={0} as="strong" name="pepDefinition.title" />]}
        />
      )}
    </div>
  );
};

export default CreateAccount;
