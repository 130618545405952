import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import FormGroup from 'ui/molecules/form-group';
import Input from 'ui/atoms/input';
import useForm, { ChangeWithValidationState } from 'ui/hooks/use-form';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Currency from 'ui/atoms/currency';
import { Money } from 'api';
import Section from 'ui/atoms/section';
import Header from 'ui/atoms/header';
import ServerError from 'ui/types/server-error';
import { handleError } from 'ui/helper/error-handling';
import useTranslate from 'ui/hooks/use-translate';
import useApiCall from 'hooks/use-api-call';

export interface VoucherCodeFields {
  voucherCode?: string;
}

export type Voucher = {
  name: string;
  disagio: Money;
};

export interface VoucherCodeProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  voucher?: Voucher;

  onChangeWithValidation?: (state: ChangeWithValidationState<VoucherCodeFields>) => void;

  initial: {
    voucherCode?: string;
  };

  voucherError?: ServerError;
}

const VoucherCode: FunctionComponent<VoucherCodeProps> = (props) => {
  const {
    className,
    children,
    onChangeWithValidation = () => {},
    voucher,
    initial,
    voucherError,
    ...restProps
  } = props;

  const [{ values, touched, clearField, setField }] = useForm<VoucherCodeFields>(
    {
      validateFields: ['voucherCode'],
    },
    { ...initial },
  );

  const [redeemVoucher, setRedeemVoucher] = useState(false);

  const [voucherCode, setVoucherCode] = useState(values.voucherCode || '');

  const [voucherCodeWithoutDelay, setVoucherCodeWithDelay] = useState(values.voucherCode || '');

  const [isValidating, setIsValidating] = useState(false);

  const voucherCanBeRedeemed = voucher && values.voucherCode;

  const { withApi } = useApiCall();

  useEffect(() => {
    if (initial.voucherCode) {
      setRedeemVoucher(true);
    }
  }, [initial]);

  useEffect(() => {
    voucherCodeWithoutDelay.length > 0 ? setIsValidating(true) : setIsValidating(false);
    const timeout = setTimeout(() => {
      setVoucherCode(voucherCodeWithoutDelay);
      setField('voucherCode', voucherCodeWithoutDelay);
    }, 500);
    return () => clearTimeout(timeout);
  }, [voucherCodeWithoutDelay]);

  useEffect(() => {
    withApi(async () => {
      if (onChangeWithValidation) {
        await onChangeWithValidation({
          isValid: !!voucherCanBeRedeemed,
          values: {
            voucherCode,
          },
        });
        setIsValidating(false);
      }
    });
  }, [voucherCode, onChangeWithValidation, voucherCanBeRedeemed]);

  // TODO(geforcefan): this is a hack! resetting validation state when input field is empty
  useEffect(() => {
    if (!touched.voucherCode) return;
    if (!voucherCode) {
      clearField('voucherCode');
    }
  }, [voucherCode, clearField, touched.voucherCode]);

  const translate = useTranslate();

  const { getRemainingError } = handleError({
    error: voucherError,
    translate: translate,
  });

  const errorTranslation = getRemainingError();

  return (
    <div className={cx('voucher-code', className)} {...restProps}>
      {!redeemVoucher && (
        <Section>
          <Button variant="link" onClick={() => setRedeemVoucher(true)}>
            <Translate name="voucherCode.redeemVoucher" />
          </Button>
        </Section>
      )}
      {redeemVoucher && !voucherCanBeRedeemed && (
        <Section>
          <FormGroup
            error={
              voucherCode &&
              // handle voucherInvalid case specifically:
              // translation has to be different than voucherInvalid translation in investment commitment step
              ((voucherError?.getErrorCode('voucher_invalid') && translate('voucherCode.errors.voucherInvalid')) ||
                (voucherError?.getErrorCode('query_parameter_not_lower_than') &&
                  translate('voucherCode.errors.voucherInvalid')) ||
                (errorTranslation && <>{errorTranslation}</>))
            }
          >
            {/*TODO(mara-cashlink): add specific behaviour & error message if voucher code exists but investment amount is too low*/}
            <Input
              label={<Translate name="voucherCode.voucherCode" />}
              valid={!voucherError && !errorTranslation}
              value={voucherCodeWithoutDelay}
              onChange={(e: any) => setVoucherCodeWithDelay(e.target.value)}
              required={false}
              autoComplete="off"
              validating={isValidating}
            />
          </FormGroup>
        </Section>
      )}
      {redeemVoucher && voucherCanBeRedeemed && voucher && (
        <Hint variant="success" spacing="small">
          <div>
            <Header size="xsmall" spacing="small">
              <Translate
                name="voucherCode.success.title"
                args={[voucher.name, (_, key) => <Currency key={key}>{voucher.disagio}</Currency>]}
              />
            </Header>
            <Translate
              name="voucherCode.success.description"
              args={[(_, key) => <Currency key={key}>{voucher.disagio}</Currency>]}
            />
          </div>
        </Hint>
      )}
      {redeemVoucher && voucherCanBeRedeemed && voucher && (
        <Button variant="link" size="small" onClick={() => clearField('voucherCode')}>
          <Translate name="voucherCode.removeVoucher" />
        </Button>
      )}
    </div>
  );
};

export default VoucherCode;
