import styled from 'styled-components';
import Segment from 'ui/atoms/segment';
import Tag from 'ui/atoms/tag';
import { query } from 'ui/styles/queries';

export const Filters = styled.div`
  display: inline-flex;
  position: relative;

  ${query.phone`
    display: flex;
  `}
`;

export const FilterBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PopupTag = styled(Tag)`
  margin-left: 4px;
`;

export const FiltersPopover = styled(Segment)`
  position: absolute;
  border-radius: 8px;
  width: 380px;
  right: 0;
  top: 30px;
  z-index: 5000;
  box-shadow: 0px 5px 5px -3px #00000033, 0px 3px 14px 2px #0000001f, 0px 8px 10px 1px #00000024;

  ${query.phone`
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  `};
`;
