import { compact } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { StateValues } from 'react-use-form-state';
import { AdminApi } from 'api';
import { useServerConfigSelector } from 'core/config/hooks';
import useApiCall from 'hooks/use-api-call';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';

interface CreateRegistryStatementProps {
  investmentId: string;
  onRegistryStatementCreated: () => void;
}
interface RegistryStatementFields {
  sendEmail: string;
}

export const Form = makeModalForm<RegistryStatementFields>();

const CreateRegistryStatement: FunctionComponent<CreateRegistryStatementProps> = ({
  investmentId,
  onRegistryStatementCreated,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSuccessfulCreated, setSuccessfulCreated] = useState(false);

  const { config } = useServerConfigSelector();

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onSubmit = useCallback((fieldsValues: StateValues<RegistryStatementFields>) => {
    withApi(async () => {
      await api.adminInvestmentsRegistryStatementsCreate({
        id: investmentId,
        createRegistryStatementRequest: {
          sendEmail: fieldsValues.sendEmail === 'true',
        },
      });
      setSuccessfulCreated(true);
      onRegistryStatementCreated();
    });
  }, []);

  useEffect(() => {
    setSuccessfulCreated(false);
  }, [isModalOpen]);

  return (
    <>
      <Button variant="primary" onClick={() => setModalOpen(true)}>
        <Translate name="registryStatements.createDocument" />
      </Button>
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Form
            initial={{
              sendEmail: config.ewpgSendEmailsToInvestorsEnabled.toString(),
            }}
            onSubmit={(values: StateValues<RegistryStatementFields>) => {
              onSubmit(values);
            }}
          >
            <ModalHeader>
              {isSuccessfulCreated ? (
                <Translate name="registryStatements.createdSuccessfulTitle" />
              ) : (
                <Translate name="registryStatements.createDocument" />
              )}
            </ModalHeader>
            <ModalContent>
              {isSuccessfulCreated ? (
                <Hint variant="success">
                  <Translate name="registryStatements.createdSuccessfulMessage" />
                </Hint>
              ) : (
                <>
                  <Form.Group name="sendEmail" required={true}>
                    <div>
                      <Translate name="registryStatements.statementByEmail" />
                      <Form.GroupToggle>
                        {['true', 'false'].map((option, key) => (
                          <Form.Radio value={option} key={key} disabled={!config.ewpgSendEmailsToInvestorsEnabled}>
                            <Translate name={`commonOptions.${option}`} />
                          </Form.Radio>
                        ))}
                      </Form.GroupToggle>
                    </div>
                  </Form.Group>
                  {!config.ewpgSendEmailsToInvestorsEnabled && (
                    <Hint variant="warning">
                      <Translate name="registryStatements.noEmail" />
                    </Hint>
                  )}
                </>
              )}
            </ModalContent>
            <ModalFooter>
              <ModalFooterButtons
                actionButtons={compact([
                  !isSuccessfulCreated && {
                    name: 'cancel',
                    content: <Translate name="common.close" />,
                    size: 'large',
                    onClick: () => setModalOpen(false),
                  },
                  !isSuccessfulCreated && {
                    name: 'send',
                    content: <Translate name="registryStatements.createBtn" />,
                    variant: 'primary',
                    type: 'submit',
                    size: 'large',
                  },
                  isSuccessfulCreated && {
                    name: 'back',
                    content: <Translate name="registryStatements.backButton" />,
                    variant: 'primary',
                    size: 'large',
                    onClick: () => setModalOpen(false),
                  },
                ])}
              />
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default CreateRegistryStatement;
