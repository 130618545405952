import React, { FunctionComponent, ReactNode } from 'react';
import Spacing from 'ui/types/spacing';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';

export interface ContainerProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Use fluid for a full width container, spanning the entire width of the viewport */
  fluid?: boolean;

  /** Spacing to the bottom */
  spacing?: Spacing;

  /**
   * Padding to left and right
   *
   * @default true
   */
  padding?: boolean;
}

export const Container: FunctionComponent<ContainerProps> = ({
  fluid,
  className,
  children,
  spacing = 'none',
  padding = true,
  ...restProps
}) => (
  <Styled.Container
    $spacing={spacing}
    $padding={padding}
    $fluid={fluid}
    className={cx('ui-container', className)}
    {...restProps}
  >
    {children}
  </Styled.Container>
);

export default Container;
