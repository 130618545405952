import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminApi, AdminTokensListRequest, InvestmentToken } from 'api';
import { camelCase } from 'change-case';
import Currency from 'ui/atoms/currency';
import { FiltersWrapper } from 'ui/molecules/filtering';
import WithDataNoPagination from 'hoc/WithDataNoPagination';

export interface ProductsTableProps extends AdminTokensListRequest {
  goToDetails: (productId: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  filters?: ReactNode;
}

const ProductsTable: FunctionComponent<ProductsTableProps> = WithDataNoPagination<AdminApi, InvestmentToken>(AdminApi)(
  ({ data, goToDetails, title, emptyView, loading, filters }) => {
    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}

        <DataRecordTable
          data={data}
          title={title}
          loading={loading}
          emptyView={emptyView}
          cells={[
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.productName`} />,
              orderingFieldName: 'name',
              render: (product) => (
                <div>
                  <b>{product.name}</b>
                  <div>
                    <Translate name={`productType.${camelCase(product.productType)}`} />
                  </div>
                </div>
              ),
            },
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.issuerName`} />,
              orderingFieldName: 'issuer_name',
              render: (product) => product.issuer?.person?.companyName,
            },
            {
              title: <Translate name={`issuerProducts.issuerProductsTable.totalVolume`} />,
              orderingFieldName: 'total_volume',
              alignContent: 'right',
              render: (product) => <Currency>{product.totalVolume}</Currency>,
            },
            {
              title: '',
              render: ({ id }) => (
                <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
              ),
              alignContent: 'right',
            },
          ]}
        />
      </>
    );
  },
  (api, props) => {
    return api.adminTokensList({ ...props });
  },
);

export default ProductsTable;
