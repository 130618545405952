import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import makeForm from 'ui/forms';

export interface LoginMaskProps {
  children?: ReactNode;

  className?: string;

  /** On forgot password callback */
  onForgotPassword?: () => void;

  initial?: {
    email?: string;
  };

  hooks?: { top?: ReactNode; bottom?: ReactNode };
}

export interface LoginFormFields {
  email: string;
  password: string;
}

export const LoginForm = makeForm<LoginFormFields>();

const LoginMask: FunctionComponent<LoginMaskProps> = (props) => {
  const { className, children, hooks, initial, onForgotPassword = () => {}, ...restProps } = props;

  return (
    <div className={cx('login-mask', className)} {...restProps}>
      {hooks?.top}
      <LoginForm.Group required={true} name="email" simple={true}>
        <LoginForm.Input />
        {LoginForm.Validators.Email()}
      </LoginForm.Group>
      <LoginForm.Group name="password" required={true} simple={true}>
        <LoginForm.PasswordInput />
      </LoginForm.Group>
      <Segment textAlign="right" basic={true} padded="none">
        <Button variant="link" onClick={() => onForgotPassword()}>
          <Translate name="login.forgotPassword" />
        </Button>
      </Segment>
      {hooks?.bottom}
    </div>
  );
};

export default LoginMask;
