import React, { FunctionComponent, useRef, useState } from 'react';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import * as Styled from './styled';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';
import { convertFileSize } from 'helper/convert-file-size';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Select from 'ui/atoms/select';
import FormGroup from 'src/ui/molecules/form-group';

export interface FileToUpload {
  file: File | null;
  documentType?: string;
}

export const FileUpload: FunctionComponent<{
  handleFileInput: (event: any) => void;
  uploadedFiles: FileToUpload[];
  onRemoveDocument: (fileName?: string) => void;
  documentTypes?: Array<any>;
  onSelectDocumentType?: (documentType: string, fileName?: string) => void;
  showRequiredError?: boolean;
  showValidatedForm?: boolean;
}> = ({
  uploadedFiles,
  handleFileInput,
  onRemoveDocument,
  documentTypes,
  onSelectDocumentType,
  showRequiredError,
  showValidatedForm,
}) => {
  const fileInput = useRef<any>(null);
  const translate = useTranslateWithStringArgs();

  const [isFileDragged, setIsFileDragged] = useState(false);

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    setIsFileDragged(false);
    handleFileInput && handleFileInput(event);
  };

  return (
    <>
      <form method="post" action="" encType="multipart/form-data">
        <Styled.FileUploadContainer
          $dragOver={isFileDragged}
          $error={showRequiredError}
          onDragLeave={() => setIsFileDragged(false)}
          onDragOver={(event) => {
            event.preventDefault();
            setIsFileDragged(true);
          }}
          onDrop={handleFileDrop}
        >
          <input
            className="box__file"
            type="file"
            onChange={handleFileInput}
            onClick={(event: any) => {
              event.target.value = null;
            }}
            accept=".pdf"
            id="file"
            hidden={true}
            ref={fileInput}
          />
          <Styled.TextContent>
            <Icon size="large" name="upload" color="primary" />
            <Spacer y={4} />
            <Translate
              name="uploadPdf.dragDropButton"
              as="div"
              args={[
                (text, key) => (
                  <Button
                    variant="link"
                    onClick={() => fileInput?.current && fileInput.current.click()}
                    key={key}
                    inline
                  >
                    {text}
                  </Button>
                ),
              ]}
            />
            <Spacer y={2} />
            <Styled.SideTextContent>
              <Translate
                name="uploadPdf.fileSize"
                as="span"
                args={[
                  (text) => (
                    <label htmlFor="file">
                      {' '}
                      <b>{text}</b>
                    </label>
                  ),
                ]}
              />
            </Styled.SideTextContent>
          </Styled.TextContent>
        </Styled.FileUploadContainer>
      </form>
      <Spacer y={2} />
      {!!uploadedFiles?.length &&
        uploadedFiles.map((uploadedFile) => {
          if (!uploadedFile?.file?.name) return null;

          return (
            <>
              <Styled.DocumentContainer>
                <Styled.DocumentContent>
                  <Icon size="large" name="document" color="primary" />
                  <Spacer x={4} />
                  <Styled.DocumentTextContainer>
                    <Styled.DocumentNameRemove>
                      <span>{uploadedFile.file.name}</span>
                      <Spacer x={4} />
                      <Icon name="close-thin" onClick={() => onRemoveDocument(uploadedFile?.file?.name)} />
                    </Styled.DocumentNameRemove>
                    <Spacer y={2} />
                    <Styled.SideTextContent>{convertFileSize(uploadedFile.file.size)}</Styled.SideTextContent>
                  </Styled.DocumentTextContainer>
                </Styled.DocumentContent>
              </Styled.DocumentContainer>
              {!!documentTypes?.length && onSelectDocumentType && (
                <>
                  <Spacer y={2} />
                  <FormGroup
                    error={
                      !uploadedFile?.documentType && showValidatedForm ? (
                        <Translate name="form.errors.required.documentType" />
                      ) : null
                    }
                  >
                    <Select
                      required={true}
                      valid={!!uploadedFile?.documentType || !showValidatedForm}
                      label={translate('addInvestment.documentType')}
                      options={documentTypes.map((documentType) => ({
                        value: documentType.label,
                        label: documentType.label,
                      }))}
                      value={uploadedFile.documentType}
                      onChange={(evt) => {
                        onSelectDocumentType(evt, uploadedFile?.file?.name);
                      }}
                    />
                  </FormGroup>
                </>
              )}
              <Spacer y={2} />
            </>
          );
        })}
    </>
  );
};

export default FileUpload;
