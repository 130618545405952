import { ReactNode, useCallback, useEffect, useState } from 'react';
import { I18N, Languages, Locales } from 'ui/i18n/types';
import {
  getBrowserLanguage,
  getLocalLanguage,
  removeLocalLanguage,
  setBrowserLanguage,
  setLocalLanguage,
} from 'ui/i18n/utils';
import assets from 'ui/i18n/assets';
import get from 'lodash/get';
import { TranslateOptions } from 'ui/types/translate';
import considerGrammaticalNumber from 'ui/helper/consider-grammatical-number';
import replaceWithArgs, { replaceWithStringArgs } from 'ui/helper/replace-with-args';

export type UseI18NTypes = {
  defaultLocale: Languages;
  initialLocales: Locales;
  overwriteLocale?: Languages;
};

export function useI18n({ initialLocales, defaultLocale, overwriteLocale }: UseI18NTypes) {
  const [selectedLocale, setSelectedLocale] = useState<Languages | undefined>(getLocalLanguage());
  const [userLocale, setUserLocale] = useState<Languages | undefined>(undefined);

  const activeLocale: Languages =
    overwriteLocale || userLocale || selectedLocale || getBrowserLanguage() || defaultLocale;

  const [localeMessages, setLocaleMessages] = useState<I18N>(
    { ...assets[activeLocale], ...initialLocales[activeLocale] } || {},
  );

  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);

  const changeLocale = useCallback(async (locale: Languages, via: { user?: boolean; selection?: boolean }) => {
    if (via.user) {
      setUserLocale(locale);
    }
    if (via.selection) {
      setSelectedLocale(locale);
    }
    setBrowserLanguage(locale);
  }, []);

  useEffect(() => {
    if (selectedLocale) {
      setLocalLanguage(selectedLocale);
    } else {
      removeLocalLanguage();
    }
  }, [selectedLocale]);

  const addLocaleMessages = (messages: I18N) => {
    setLocaleMessages({ ...localeMessages, ...messages });
  };

  return {
    selectedLocale,
    userLocale,
    activeLocale,
    initialLocales,
    isMessagesLoaded,
    localeMessages,
    changeLocale,
    setIsMessagesLoaded,
    setLocaleMessages,
    addLocaleMessages,
  };
}

export function useTranslateDefinitions({ localeMessages }: ReturnType<typeof useI18n>) {
  const getLocaleMessage = useCallback(
    (stringName: string): string => {
      return get(localeMessages, stringName, '').replace(
        /{([^\d][^}]*.*?)}/g,
        (fullMatch, variableName) => getLocaleMessage(variableName) as string,
      );
    },
    [localeMessages],
  );

  const translate = useCallback(
    (stringName: string, options?: TranslateOptions<ReactNode>) => {
      const message =
        options?.grammaticalNumber !== undefined
          ? considerGrammaticalNumber(options?.grammaticalNumber, getLocaleMessage(stringName))
          : getLocaleMessage(stringName);
      return replaceWithArgs(message, ...(options?.args || []));
    },
    [getLocaleMessage],
  );

  const translateWithStringArgs = useCallback(
    (stringName: string, options?: TranslateOptions<string>): string =>
      replaceWithStringArgs(getLocaleMessage(stringName), ...(options?.args || [])),
    [getLocaleMessage],
  );

  return {
    translate,
    translateWithStringArgs,
  };
}
