import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import ServerError from 'ui/types/server-error';
import makeForm from 'ui/forms';
import Button from 'ui/atoms/button';
import { SurveyWaiverTypeEnum, Money } from 'api';
import ActionButtons from 'src/ui/molecules/action-buttons';
import Spacer from 'src/ui/atoms/spacer';

export interface QuestionnaireWaiverFields {
  itsOkayNotBeingSuitable: boolean;
  itsOkayNetWorthUnsuitable: boolean;
}

export interface QuestionnaireWaiverProps {
  className?: string;
  onContinue?: (values: QuestionnaireWaiverFields) => void;
  editable?: boolean;
  onEditQuestions?: () => void;
  onEditNetWorth?: () => void;
  loading?: boolean;
  noAnswers?: boolean;
  error?: ServerError;
  isEcsp?: boolean;
  lossability?: Money | null;
  investmentTotal?: Money | null;
  surveyWaiverType?: SurveyWaiverTypeEnum;
  isNetWorthSuitable?: boolean;
}

const QuestionnaireWaiverForm = makeForm<QuestionnaireWaiverFields>();

const QuestionnaireWaiver: FunctionComponent<QuestionnaireWaiverProps> = (props) => {
  const {
    className,
    onContinue = () => {},
    onEditQuestions = () => {},
    onEditNetWorth = () => {},
    editable = true,
    loading,
    error,
    noAnswers = false,
    isEcsp,
    lossability,
    investmentTotal,
    surveyWaiverType,
    isNetWorthSuitable,
    ...restProps
  } = props;

  const isQuestionnaireSuitable = surveyWaiverType !== SurveyWaiverTypeEnum.UNSUITABLE;

  return (
    <div className={cx('investment-questionnaire-unsuitable', className)} {...restProps}>
      {noAnswers && (
        <>
          <p>
            <Translate
              name={`questionnaire.waiver.${isEcsp ? 'ecsp.noAnswers.text1' : 'noAnswers.text1'}`}
              args={[(text, key) => <b key={key}>{text}</b>]}
            />
          </p>
          <p>
            <Translate name="questionnaire.waiver.noAnswers.text2" />
          </p>
          <p>
            <Translate name="questionnaire.waiver.noAnswers.text3" />
          </p>
        </>
      )}
      {!isQuestionnaireSuitable && (
        <p>
          <Translate name={`questionnaire.waiver.${isEcsp ? 'ecsp.unsuitable.text' : 'unsuitable.text'}`} />
        </p>
      )}
      {isEcsp && !isNetWorthSuitable && (
        <p>
          <Translate
            name={`questionnaire.waiver.ecsp.netWorthUnsuitable.${
              isQuestionnaireSuitable && !noAnswers ? 'information' : 'additionInformation'
            }`}
          />
        </p>
      )}
      <Hint variant="warning">
        <Translate name={`questionnaire.waiver.${isEcsp ? 'ecsp.information' : 'unsuitable.information'}`} />
      </Hint>
      <p>
        <Translate name="questionnaire.waiver.unsuitable.actionInformation" />
      </p>
      <QuestionnaireWaiverForm onSubmit={onContinue} error={error}>
        {noAnswers && (
          <>
            <QuestionnaireWaiverForm.Group name="itsOkayNotBeingSuitable" required>
              <QuestionnaireWaiverForm.Checkbox>
                <Translate
                  name={`questionnaire.waiver.${isEcsp ? 'ecsp.noAnswers.checkbox' : 'unsuitable.checkbox'}`}
                />
              </QuestionnaireWaiverForm.Checkbox>
            </QuestionnaireWaiverForm.Group>
          </>
        )}
        {!noAnswers && !isQuestionnaireSuitable && (
          <>
            <QuestionnaireWaiverForm.Group name="itsOkayNotBeingSuitable" required>
              <QuestionnaireWaiverForm.Checkbox>
                <Translate
                  name={`questionnaire.waiver.${isEcsp ? 'ecsp.unsuitable.checkbox' : 'unsuitable.checkbox'}`}
                />
              </QuestionnaireWaiverForm.Checkbox>
            </QuestionnaireWaiverForm.Group>
          </>
        )}
        {isEcsp && !isNetWorthSuitable && (
          <>
            <QuestionnaireWaiverForm.Group name="itsOkayNetWorthUnsuitable" required>
              <QuestionnaireWaiverForm.Checkbox>
                <Translate name="questionnaire.waiver.ecsp.netWorthUnsuitable.checkbox" />
              </QuestionnaireWaiverForm.Checkbox>
            </QuestionnaireWaiverForm.Group>
          </>
        )}
        <ActionButtons>
          {editable && (
            <>
              {isEcsp && (
                <>
                  <Button variant="link" size="large" onClick={onEditQuestions}>
                    <Translate name="questionnaire.editQuestionnaire" />
                  </Button>
                  <Spacer x={4} />
                  <Button variant="link" size="large" onClick={onEditNetWorth}>
                    <Translate name="questionnaire.editNetWorth" />
                  </Button>
                </>
              )}
            </>
          )}
          <QuestionnaireWaiverForm.Submit size="large" variant="primary" loading={loading}>
            {noAnswers ? <Translate name="common.confirm" /> : <Translate name="questionnaire.continue" />}
          </QuestionnaireWaiverForm.Submit>
        </ActionButtons>
      </QuestionnaireWaiverForm>
    </div>
  );
};

export default QuestionnaireWaiver;
