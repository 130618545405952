import { useContext, useEffect } from 'react';
import { PlatformContext } from 'ui/platform/provider';
import { importAssets } from 'ui/assets/utils';
import { useAssets } from 'ui/assets/hooks';

export function useLoadPlatformAssets({
  defaultImages,
  initialImages,
  setImages,
  setImagesLoaded,
}: ReturnType<typeof useAssets>) {
  const { activePlatform, initialPlatform } = useContext(PlatformContext);

  useEffect(() => {
    if (activePlatform === initialPlatform) return;

    (async () => {
      try {
        const platformImages = await importAssets(activePlatform);

        setImages({ ...defaultImages, ...platformImages });
        setImagesLoaded(true);
      } catch (e) {
        console.error(`Error while loading images for platform ${activePlatform}:`, e, e.stack);
        setImages({ ...defaultImages, ...initialImages });
      }
    })();
  }, [activePlatform]);
}
