import { AdminInvestor } from 'api';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

export interface DeleteSuccessProps {
  investor: AdminInvestor;
  requestFutureDeletion: boolean;
}

const DeleteSuccess: FunctionComponent<DeleteSuccessProps> = (props) => {
  const { investor, requestFutureDeletion } = props;

  const translate = useTranslateWithStringArgs();

  const formatDate = (date: Date) => moment(date).format(`${translate('date.dateFormat')}`);

  return (
    <div>
      <Hint variant="success">
        {requestFutureDeletion ? (
          <Translate
            name="dashboardIssuerInvestorDetails.futureDeletion.accountDeactivated"
            args={[investor.name, formatDate(investor.earliestDeletion)]}
          />
        ) : (
          <Translate name="dashboardIssuerInvestorDetails.deleteAccount.success" />
        )}
      </Hint>
      <small>
        {requestFutureDeletion ? (
          <Translate
            name="dashboardIssuerInvestorDetails.futureDeletion.emailDeactivation"
            args={[formatDate(investor.earliestDeletion)]}
          />
        ) : (
          <Translate
            name="dashboardIssuerInvestorDetails.deleteAccount.successEmail"
            args={[`${investor.name} (${investor.email})`]}
          />
        )}
      </small>
    </div>
  );
};

export default DeleteSuccess;
