import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminApi, AdminFriendVouchersListRequest, AdminVoucher } from 'api';
import { FiltersWrapper } from 'ui/molecules/filtering';
import StatusTag from 'ui/atoms/status-tag';
import Link from 'ui/atoms/link';
import * as Styled from './styled';
import DataRecordOrdering from 'ui/types/data-ordering';
import WithDataRecord from 'hoc/WithDataRecord';
import HoverCopy from 'ui/molecules/hover-copy';

export interface FriendReferralsTableProps extends AdminFriendVouchersListRequest {
  goToDetails: (referrerId: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  filters?: ReactNode;
  defaultLimit?: number;
  defaultOrdering?: DataRecordOrdering;
}

const FriendReferralsTable: FunctionComponent<FriendReferralsTableProps> = WithDataRecord<AdminApi, AdminVoucher>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, goToDetails, title, emptyView, loading, filters, paginationProps }) => {
    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}

        <DataRecordTable
          data={data}
          ordering={ordering}
          onOrderBy={onOrderBy}
          title={title}
          loading={loading}
          emptyView={emptyView}
          paginationProps={paginationProps}
          cells={[
            {
              title: <Translate name={`issuerFriendReferrals.table.referrer`} />,
              orderingFieldName: 'name',
              render: (referral) => (
                <Styled.ActionIconCell>
                  {referral.owningInvestor.name}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    href={`/dashboard/investor/${referral.owningInvestor.id}`}
                    className="external-link"
                    external
                  />
                </Styled.ActionIconCell>
              ),
            },
            {
              title: <Translate name={`issuerFriendReferrals.table.voucherCode`} />,
              orderingFieldName: 'code',
              render: (referral) => (
                <Styled.ActionIconCell>
                  <HoverCopy name={referral.code} className="external-link" />
                </Styled.ActionIconCell>
              ),
            },
            {
              title: <Translate name={`issuerFriendReferrals.table.useNumber`} />,
              orderingFieldName: 'already_used',
              render: (referral) => referral.alreadyUsed,
            },
            {
              title: <Translate name={`issuerFriendReferrals.table.status`} />,
              render: (referral) => (
                <StatusTag variant={referral.active ? 'success' : 'danger'} truncate={true}>
                  <Translate name={`issuerFriendReferrals.status.${referral.active ? 'active' : 'deactivated'}`} />
                </StatusTag>
              ),
            },
            {
              title: '',
              render: ({ id }) => (
                <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
              ),
              alignContent: 'right',
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminFriendVouchersList({ offset, limit, ordering, ...props });
  },
);

export default FriendReferralsTable;
