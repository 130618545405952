import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';

export interface LabelProps {
  $disabled?: boolean;
}

export interface CheckboxProps {
  $disabled?: boolean;
  $checked?: boolean;
  $isInvalid?: boolean;
}

export interface CheckboxCheckmarkProps {
  $disabled?: boolean;
  $checked?: boolean;
}

function getCheckedCheckbox({ theme, $checked, $disabled }: ThemedStyledProps<null, DefaultTheme>) {
  if (!$checked) return null;

  return css`
    animation-name: none;
    border-color: ${resolveColor(theme, 'primary')};
    background: ${resolveColor(theme, 'primary')};

    ${!$disabled &&
    `
      &:hover {
        border-color: ${resolveColor(theme, 'primaryHover')};
        background: ${resolveColor(theme, 'primaryHover')};
      }
      `}
  `;
}

function getCheckboxStyle({ theme, $disabled, $isInvalid }: ThemedStyledProps<null, DefaultTheme>) {
  return css`
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    border: 1px solid ${resolveColor(theme, $isInvalid ? 'danger' : 'grayHover')};
    border-radius: 4px;
    cursor: pointer;

    ${!$disabled &&
    `
      &:hover {
        border: 1px solid ${resolveColor(theme, 'primary')};
        background-color: ${resolveColor(theme, 'primaryLight')};
      }
      `}
  `;
}

function getDisabledCheckbox({ theme, $disabled }: ThemedStyledProps<null, DefaultTheme>) {
  if (!$disabled) return null;

  return css`
    background-color: ${resolveColor(theme, 'grayDark')};
    border: none;
    cursor: not-allowed;
  `;
}

function getCheckboxCheckmark({ theme, $checked }: ThemedStyledProps<null, DefaultTheme>) {
  if (!$checked) return null;

  return css`
    position: absolute;
    cursor: pointer;
    content: '';
    top: 4px;
    left: 8px;
    box-sizing: border-box;
    width: 0.5rem;
    height: 1rem;
    transform: rotate(40deg);
    border-width: 2px;
    border-style: solid;
    border-color: ${resolveColor(theme, 'white')};
    border-top: 0;
    border-left: 0;
  `;
}

function getDisabledCheckmark({ theme, $disabled }: ThemedStyledProps<null, DefaultTheme>) {
  if (!$disabled) return null;

  return css`
    border-color: ${resolveColor(theme, 'grayHover')};
    cursor: not-allowed;
  `;
}

export const Label = styled.label<LabelProps>`
  width: 1.5rem;
  margin-right: 0.5rem;
`;

export const Checkbox = styled.span<CheckboxProps>`
  ${getCheckboxStyle};
  ${getCheckedCheckbox};
  ${getDisabledCheckbox};
`;

export const CheckboxCheckmark = styled.span<CheckboxCheckmarkProps>`
  ${getCheckboxCheckmark};
  ${getDisabledCheckmark};
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const CheckboxContainer = styled.span`
  position: relative;
  display: block;
  margin-bottom: 0.5rem;

  ${query.phone`
    font-size: 12px;
  `};
`;

export const Content = styled.span`
  display: block;
  position: relative;
  margin-top: -1.3rem;
  margin-left: 2.6rem;
`;
