import React, { FunctionComponent } from 'react';

import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Container from 'ui/atoms/container';
import Header from 'ui/atoms/header';
import { AdditionalInformationForm } from 'libraries/wizard/components/additional-information/additional-information-form';
import Grid, { Col } from 'ui/atoms/grid';

const SecuritiesDepositAccount: FunctionComponent<{}> = () => {
  return (
    <Section spacing="large">
      <Header as="h3" size="small">
        <Translate name="investmentAdditionalInformation.securitiesDepositAccount.title" />
      </Header>
      <AdditionalInformationForm.Group
        name="securitiesDepositAccountHolder"
        required={true}
        info={<Translate name="additionalInformationForm.fields.securitiesDepositAccountHolder.info" />}
      >
        <AdditionalInformationForm.Input maxLength={100} />
      </AdditionalInformationForm.Group>
      <Container fluid={true} padding={false}>
        <Grid>
          <Col phone={12} width={7}>
            <AdditionalInformationForm.Group
              name="securitiesDepositAccountNumber"
              required={true}
              info={<Translate name="additionalInformationForm.fields.securitiesDepositAccountNumber.info" />}
            >
              <AdditionalInformationForm.Input maxLength={100} />
            </AdditionalInformationForm.Group>
          </Col>
          <Col phone={12} width={5}>
            <AdditionalInformationForm.Group name="bic" required={true}>
              <AdditionalInformationForm.Input maxLength={11} />
              {AdditionalInformationForm.Validators.Bic()}
            </AdditionalInformationForm.Group>
          </Col>
        </Grid>
      </Container>
    </Section>
  );
};

export default SecuritiesDepositAccount;
