import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import Header from 'ui/atoms/header';

const WaitingKyc: FunctionComponent<{}> = () => {
  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);

  return (
    <>
      <Header size="large">
        <Translate name="register.waitingKyc.title" />
      </Header>
      <Hint variant="warning">
        <Translate name="register.waitingKyc.hint" />
      </Hint>
      <ActionButtons>
        <Button variant="secondary" size="large" fluid={true} onClick={() => goToOverview()}>
          <Translate name="register.waitingKyc.button" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default WaitingKyc;
