import { useCallback, useState } from 'react';
import { NetworthAnswerRequest } from 'api';
import { toMoney } from 'ui/helper/money';

export enum LosabilityQuestions {
  YearlyNetLaborIncome = 'yearly-net-labor-income',
  YearlyInterestIncome = 'yearly-interest-income',
  YearlyDividendsAndCapitalIncome = 'yearly-dividends-and-capital-income',
  YearlyRentAndLeaseIncome = 'yearly-rent-and-lease-income',
  GiroAndSavings = 'giro-and-savings',
  ExchangeTradedSecurities = 'exchange-traded-securities',
  FundAssets = 'fund-assets',
  YearlyHousekeepingCosts = 'yearly-housekeeping-costs',
  YearlyRentAndMortgagePayments = 'yearly-rent-and-mortgage-payments',
  YearlyRedemptionCostsForCredits = 'yearly-redemption-costs-for-credits',
  YearlyInsuranceContributions = 'yearly-insurance-contributions',
  YearlyCostsForSupplyServices = 'yearly-costs-for-supply-services',
  YearlySubscriptionPayments = 'yearly-subscription-payments',
  Taxes = 'taxes',
}

export const useLossAbilityCalculator = (onUpdateAnswers: (netWorthAnswers: NetworthAnswerRequest[]) => void) => {
  const [yearlyIncome, setYearlyIncome] = useState(0);
  const [liquidAssets, setLiquidAssets] = useState(0);
  const [yearlyLiabilities, setYearlyLiabilities] = useState(0);
  const [lossabilityAnswers, setLosabilityAnswers] = useState<NetworthAnswerRequest[]>([]);

  const getCalculatedNetWorth = useCallback(() => {
    const total: number = yearlyIncome + liquidAssets - yearlyLiabilities;
    return total.toString();
  }, [yearlyIncome, liquidAssets, yearlyLiabilities]);

  const updateAnswer = (questionId: string, amount: number) => {
    const lossabilityAnswer = lossabilityAnswers.find((item) => item.questionId === questionId);

    if (lossabilityAnswer) {
      lossabilityAnswer.answer = toMoney(amount);
    } else {
      lossabilityAnswers.push({
        answer: toMoney(amount),
        questionId: questionId,
      });
    }

    onUpdateAnswers(lossabilityAnswers);
  };

  return {
    lossabilityAnswers,
    setLosabilityAnswers,
    setYearlyIncome,
    setLiquidAssets,
    setYearlyLiabilities,
    updateAnswer,
    getCalculatedNetWorth,
  };
};
