import { ColorVariants } from 'ui/styles/themes/color-variants';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Padding from 'ui/types/padding';
import { getColorYig, resolveColor } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';

export interface StickyMessageProps {
  $padded?: Padding;
  $absolute?: boolean;
  $color?: Colors;
  $variant?: ColorVariants;
}

function getDefaultStyle({ theme }: ThemedStyledProps<StickyMessageProps, DefaultTheme>) {
  return css<StickyMessageProps>`
    padding: ${theme.sticky.paddingVertical}rem ${theme.sticky.paddingHorizontal}rem;
    background: ${theme.sticky.bgColor};
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    position: relative;
    &:after {
      background: ${theme.sticky.bgColor};
      width: ${theme.sticky.arrowSize}rem;
      height: ${theme.sticky.arrowSize}rem;
      transform: rotate(45deg);
      position: absolute;
      content: '';
      right: 50%;
      bottom: -${theme.sticky.arrowSize / 2}rem;
      margin-right: ${theme.sticky.arrowSize / 2}rem;
      z-index: 1001;
    }
  `;
}

function getAbsolute({ $absolute }: ThemedStyledProps<StickyMessageProps, DefaultTheme>) {
  if (!$absolute) return null;

  return css`
    position: absolute;
    width: 100%;
  `;
}

function getPadding({ theme, $padded }: ThemedStyledProps<StickyMessageProps, DefaultTheme>) {
  if (!$padded) return null;

  const paddingVertical = theme.sticky.paddingVertical * theme.padding[$padded];
  const paddingHorizontal = theme.sticky.paddingHorizontal * theme.padding[$padded];

  return css<StickyMessageProps>`
    padding: ${paddingVertical}rem ${paddingHorizontal}rem;
  `;
}

function getColor({ theme, $color, $variant }: ThemedStyledProps<StickyMessageProps, DefaultTheme>) {
  const color = $color || $variant;

  if (!color) {
    return css<StickyMessageProps>`
      &,
      & * {
        color: ${getColorYig(theme, resolveColor(theme, theme.sticky.bgColor as Colors))};
      }
    `;
  }

  const themeColor = resolveColor(theme, color);
  const colorYig = getColorYig(theme, themeColor);

  return css<StickyMessageProps>`
    &,
    & *,
    &:after {
      fill: ${colorYig};
      color: ${colorYig};
      background-color: ${themeColor};
    }
  `;
}

export const StickyMessage = styled.div<StickyMessageProps>`
  ${getDefaultStyle};
  ${getAbsolute};
  ${getPadding};
  ${getColor};
`;
