import React, { FunctionComponent, useContext } from 'react';
import { getAccountHolderInitial } from 'helper/person';
import useInvestorMe from 'hooks/use-investor-me';
import useTranslate from 'ui/hooks/use-translate';
import WizardContext from 'libraries/wizard/wizard-context';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import AdditionalInformation, { AdditionalInformationFields } from './additional-information-form';
import { AnyServerError } from 'hooks/use-api-error';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const AdditionalInformationStep: FunctionComponent<{
  onSubmit: (values: AdditionalInformationFields) => void;
  bankAccountRequired: boolean;
  securitiesDepositAccountRequired: boolean;
  taxInformationRequired: boolean;
  showLoading: boolean;
  error?: AnyServerError;
}> = ({
  onSubmit,
  bankAccountRequired,
  securitiesDepositAccountRequired,
  taxInformationRequired,
  showLoading,
  error,
}) => {
  const { loading, finalize, isRegisterOnlyFlow } = useContext(WizardContext);
  const { investor: me, error: meError } = useInvestorMe();
  const translate = useTranslate();

  const accountHolderInitial = getAccountHolderInitial(translate, me?.person);

  const onSubmitValues = async (values: AdditionalInformationFields) => {
    await onSubmit(values);
    finalize();
  };

  // TODO: we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (error || meError) return null;

  if (showLoading) return <LoadingRing />;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate
          name={isRegisterOnlyFlow ? 'registerAdditionalInformation.title' : 'investmentAdditionalInformation.title'}
        />
      </Header>
      <AdditionalInformation
        onSubmit={onSubmitValues}
        initial={{ accountHolder: accountHolderInitial }}
        bankAccountRequired={bankAccountRequired}
        securitiesDepositAccountRequired={securitiesDepositAccountRequired}
        taxInformationRequired={taxInformationRequired}
        hasTaxInformation={!!me?.person.taxInformation}
        isRegisterOnlyFlow={isRegisterOnlyFlow}
        loading={showLoading || loading}
        error={error || meError}
      />
    </>
  );
};

export default AdditionalInformationStep;
