import React, { FunctionComponent } from 'react';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';

export interface QuestionnaireFormSegmentProps {
  hasQuestionnaireExistingSelections: boolean;
  isEcsp: boolean;
  noAnswers: boolean;
}

const QuestionnaireDisclaimer: FunctionComponent<QuestionnaireFormSegmentProps> = ({
  hasQuestionnaireExistingSelections,
  isEcsp,
  noAnswers,
}) => {
  return (
    <>
      {isEcsp ? (
        <Section spacing="large">
          <div>
            <Translate name="questionnaire.legalInformationEcsp" />
          </div>
          {!noAnswers && (
            <div>
              <Translate name="questionnaire.voluntaryInfo" />
            </div>
          )}
        </Section>
      ) : (
        <Section spacing="small">
          <div>
            <Translate name="questionnaire.legalInformation" />
          </div>
          {!noAnswers && (
            <div>
              <Translate name="questionnaire.voluntaryInfo" />
            </div>
          )}
        </Section>
      )}
      {hasQuestionnaireExistingSelections && (
        <p>
          <Translate name="questionnaire.selectionsExisting" />
        </p>
      )}
    </>
  );
};

export default QuestionnaireDisclaimer;
