import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import CopyInputGroup from 'src/ui/molecules/copy-input-group';
import Section from 'src/ui/atoms/section';
import { AdminApi, NetworkTypeEnum } from 'src/api';
import { getNetworkTypeName } from 'src/ui/helper/networks';
import Checkbox from 'src/ui/atoms/checkbox';
import useApiCall from 'src/hooks/use-api-call';

export interface ReceiveAssetsModalProps {
  investorId: string;
  ethAddress: string | null | undefined;
  stellarAddress: string | null | undefined;
  walletId: string;
  onSavingSuccessfully: () => void;
  onModalClose: () => void;
}

const ReceiveAssetsModal: FunctionComponent<ReceiveAssetsModalProps> = ({
  investorId,
  ethAddress,
  stellarAddress,
  walletId,
  onSavingSuccessfully,
  onModalClose,
}) => {
  const [isChecked, setChecked] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const { withApi, makeAuthenticatedApi } = useApiCall(true);

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onSave = useCallback(async () => {
    await withApi(async () => {
      setSaving(true);

      try {
        await adminApi.adminInvestorsWalletsPartialUpdate({
          id: investorId,
          walletId: walletId,
          patchedWalletUpdateRequest: {
            receivingEnabled: true,
          },
        });
        onSavingSuccessfully();
        setSaving(false);
      } catch (e) {
        setSaving(false);
        console.error(e);
      }
    });
  }, [withApi, investorId, walletId]);

  return (
    <Modal onClose={onModalClose}>
      <ModalHeader>
        <Translate name="dashboardInvestorSettings.wallets.receiveAssets" />
      </ModalHeader>
      <ModalContent>
        {ethAddress && (
          <Section spacing="small">
            <strong>
              <Translate
                key={0}
                name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.ETHEREUM)}`}
              />
            </strong>
            <CopyInputGroup valueToCopy={ethAddress} disabled />
          </Section>
        )}
        {stellarAddress && (
          <Section spacing="medium">
            <strong>
              <Translate
                key={0}
                name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.STELLAR)}`}
              />
            </strong>
            <CopyInputGroup valueToCopy={stellarAddress} disabled />
          </Section>
        )}
        <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)}>
          <Translate name="dashboardInvestorSettings.wallets.confirmReceiveAssets" />
        </Checkbox>
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.cancel" />,
              size: 'large',
              onClick: () => onModalClose(),
            },
            {
              name: 'confirm',
              content: <Translate name="common.save" />,
              variant: 'primary',
              type: 'submit',
              size: 'large',
              onClick: () => onSave(),
              disabled: !isChecked,
              loading: isSaving,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ReceiveAssetsModal;
