import { AdminVoucherStep, CurrencyEnum } from 'api';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Step } from '.';

export const useVoucherSteps = (
  createdSteps: AdminVoucherStep[] | undefined,
  onChangeSteps: (steps: Step[]) => void,
  maxStepsAllowed: number,
) => {
  const [steps, setSteps] = useState<Step[]>([
    {
      id: 0,
      minInvestment: null,
      discount: null,
      discountSelection: CurrencyEnum.EUR,
    },
  ]);

  useEffect(() => {
    onChangeSteps(steps);
  }, [steps]);

  useEffect(() => {
    if (!createdSteps?.length) return;

    const stepsToEdit: Step[] = [];
    createdSteps.forEach((step, key) => {
      const disagioPercent = step.disagioPercent ? Number(step.disagioPercent).toString() : '';
      const discount = step.disagio ? Number(step.disagio).toString() : disagioPercent;

      stepsToEdit.push({
        id: key,
        minInvestment: step.minimumInvestmentAmount,
        discount,
        discountSelection: step.disagio ? CurrencyEnum.EUR : '%',
      });
    });
    setSteps(stepsToEdit);
  }, [createdSteps]);

  const onClickAddStep = useCallback(() => {
    if (steps.length === maxStepsAllowed) return;

    setSteps([
      ...steps,
      {
        id: steps.length,
        minInvestment: null,
        discount: null,
        discountSelection: steps[steps.length - 1].discountSelection,
      },
    ]);
  }, [steps]);

  const onClickRemoveStep = useCallback(
    (id) => {
      const newSteps = steps.filter((step) => step.id !== id);
      setSteps([...newSteps]);
    },
    [steps],
  );

  const onChangeInvestmentValue = useCallback(
    (value: string, id: number) => {
      steps.filter((step) => step.id === id)[0].minInvestment = value;
      setSteps([...steps]);
    },
    [steps],
  );

  const onChangeDiscountValue = useCallback(
    (value: string, id: number) => {
      steps.filter((step) => step.id === id)[0].discount = value;
      setSteps([...steps]);
    },
    [steps],
  );

  const changeDiscountSelection = useCallback(
    (id: number, discountSelection: typeof CurrencyEnum.EUR | '%') => {
      steps.filter((step) => step.id === id)[0].discountSelection = discountSelection;
      setSteps([...steps]);
    },
    [steps],
  );

  const getActiveDiscountSelection = useCallback(
    (id: number) => {
      return steps.filter((step) => step.id === id)[0].discountSelection;
    },
    [steps],
  );

  const getMaxDiscountValue = useCallback(
    (id: number) => {
      const { minInvestment } = steps.filter((step) => step.id === id)[0];
      const minInvestmentAmount = toNumber(minInvestment);
      if (getActiveDiscountSelection(id) === '%') {
        return 100;
      } else {
        if (!minInvestmentAmount) {
          return Number.MAX_VALUE;
        } else {
          return minInvestmentAmount!;
        }
      }
    },
    [steps],
  );

  return {
    steps,
    onClickAddStep,
    onClickRemoveStep,
    onChangeInvestmentValue,
    onChangeDiscountValue,
    changeDiscountSelection,
    getActiveDiscountSelection,
    getMaxDiscountValue,
  };
};
