import { useCallback, useContext, useMemo } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestmentsApi } from 'api/apis';
import WizardContext from 'libraries/wizard/wizard-context';
import useInvestment from 'src/hooks/use-investment';

const useBeneficiary = (): {
  benefitingPersonConfirmationCreate: () => void;
  showLoading: boolean;
} => {
  //contexts
  const { investmentId } = useContext(WizardContext);

  //hooks
  const { makeAuthenticatedApi, withApi } = useApiCall();
  const { investor } = useInvestment(investmentId);

  //apis
  const investmentsApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);

  //functions
  const benefitingPersonConfirmationCreate = useCallback(() => {
    return withApi(async () => {
      if (!investmentId) return null;
      await investmentsApi.investmentsBenefitingPersonConfirmationCreate({ id: investmentId });
    });
  }, [investmentsApi, investmentId, withApi]);

  return useMemo(
    () => ({
      benefitingPersonConfirmationCreate,
      investor,
      showLoading: !investmentId,
    }),
    [benefitingPersonConfirmationCreate, investor?.id, investmentId],
  );
};

export default useBeneficiary;
