import React, { FunctionComponent } from 'react';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';

const SuccessReset: FunctionComponent = () => {
  return (
    <div>
      <Hint variant="success">
        <Translate name="resetWallet.success" />
      </Hint>
      <p>
        <Translate name="resetWallet.email" />
      </p>
    </div>
  );
};

export default SuccessReset;
