import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import makeForm from 'ui/forms';
import Section from 'ui/atoms/section';
import { useServerConfigSelector } from 'core/config/hooks';
import { InvestorsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import { TaskEnum } from 'api/models/TaskEnum';
import { InvestorTask } from 'api/models/InvestorTask';
import DocumentModal from 'src/libraries/document-modal';
import { snakeToCamelCaseWithNumbers } from 'src/ui/helper/case-transforms';

export interface SignDocumentsModalProps {
  onModalClose: () => void;
  taskObject: InvestorTask;
  investorId: string;
}

export interface SignDocumentsFormFields {
  acceptDoc: boolean;
}

const SignDocumentsForm = makeForm<SignDocumentsFormFields>();

const SignDocumentsModal: FunctionComponent<SignDocumentsModalProps> = (props) => {
  const { onModalClose, taskObject, investorId } = props;

  const [acceptDoc, setAcceptDoc] = useState(false);
  const { withApi, makeAuthenticatedApi } = useApiCall();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const { config } = useServerConfigSelector();

  const getDocument = () => {
    switch (taskObject.task) {
      case TaskEnum.TOS_AND_PRIVACY_POLICY:
        return config.cashlinkTosDocument;
      case TaskEnum.EFFECTA_CUSTOMER_INFO:
        return config.effectaAccountSetupDocument;
      default:
        return null;
    }
  };

  const onConfirmDocument = useCallback(() => {
    withApi(async () => {
      const body = {
        id: investorId,
        taskId: taskObject.id,
        investorTaskRequest: {
          task: taskObject.task,
        },
      };

      await investorsApi.investorsTasksCreate(body);
      onModalClose();
    });
  }, [withApi, investorsApi, taskObject.task]);

  const document = getDocument();

  if (!document) return null;

  return (
    <SignDocumentsForm onSubmit={onModalClose} initial={{ acceptDoc: acceptDoc }}>
      <Modal>
        <ModalHeader>
          <Translate name={`dashboardSignMissingDocuments.${snakeToCamelCaseWithNumbers(taskObject.task)}.title`} />
        </ModalHeader>
        <ModalContent>
          <Section spacing="small">
            <Translate
              name={`dashboardSignMissingDocuments.${snakeToCamelCaseWithNumbers(taskObject.task)}.description`}
            />
          </Section>
          <SignDocumentsForm.Group name="acceptDoc">
            <SignDocumentsForm.Checkbox checked={acceptDoc} onChange={() => setAcceptDoc(!acceptDoc)}>
              <Translate
                name={`dashboardSignMissingDocuments.${snakeToCamelCaseWithNumbers(taskObject.task)}.checkbox`}
                args={[
                  (text: string, key) => (
                    <DocumentModal key={key} {...document}>
                      {text}
                    </DocumentModal>
                  ),
                ]}
              />
            </SignDocumentsForm.Checkbox>
          </SignDocumentsForm.Group>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            align="center"
            actionButtons={[
              {
                name: 'confirm',
                content: (
                  <Translate
                    name={`dashboardSignMissingDocuments.${snakeToCamelCaseWithNumbers(taskObject.task)}.button`}
                  />
                ),
                variant: 'primary',
                size: 'large',
                disabled: !acceptDoc,
                type: 'submit',
                onClick: onConfirmDocument,
              },
            ]}
          />
        </ModalFooter>
      </Modal>
    </SignDocumentsForm>
  );
};

export default SignDocumentsModal;
