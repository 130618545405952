import merge from 'lodash/merge';
import flat from 'flat';
import type { I18N, InitialLocales, Languages } from 'ui/i18n/types';

export const getLocalLanguage = () => {
  try {
    return localStorage.getItem('language') as Languages;
  } catch (e) {
    return undefined;
  }
};

export const setLocalLanguage = (value: string) => {
  try {
    localStorage.setItem('language', value);
  } catch (e) {
    return;
  }
};

export const removeLocalLanguage = () => {
  try {
    localStorage.removeItem('language');
  } catch (e) {
    return;
  }
};

export const LANGUAGES = { en: true, de: true };

export const getBrowserLanguage = (): Languages | undefined => {
  if (!navigator.language) {
    return undefined;
  }
  const short = navigator.language.split('-')[0].split('_')[0].toLowerCase();
  return LANGUAGES.hasOwnProperty(short) ? (short as Languages) : undefined;
};

export const setBrowserLanguage = (lang: Languages) => {
  document.documentElement.lang = lang;
};

export const reportMissingTranslation = (key: string) => {
  const msg = `Missing translation for key ${key}`;
  console.warn(msg);
  /*Sentry.captureMessage(msg);*/
};

export const mergeMessages = (layer: InitialLocales, ...layers: InitialLocales[]): I18N => {
  return merge(flat(layer), ...layers.map((layer) => flat(layer))) as I18N;
};
