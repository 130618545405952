import { useEffect, useState } from 'react';
import type { Routes, RouteDefinitions } from 'core/routing/types';

export default function useRoutesResolver(routeDefinitions: RouteDefinitions, props?: any) {
  const [routes, setRoutes] = useState<Routes | null>(null);

  useEffect(() => {
    let resolvedRoutes: Routes = {};

    Object.entries(routeDefinitions).forEach(([key, route]) => {
      const displayRouteCondition = route.condition && route.condition(props);

      if (displayRouteCondition || props.displayAllRoutes) {
        resolvedRoutes[key] = {
          path: route.path,
          exact: route.exact,
          condition: displayRouteCondition,
        };
      }
    });

    setRoutes(resolvedRoutes);
  }, []);

  return routes;
}
