import React, { FunctionComponent, useCallback, useState } from 'react';
import Icon from 'ui/atoms/icon';
import useClipboard from 'ui/hooks/use-clipboard';
import { StyledButton } from './styled';

export interface HoverCopyProps {
  name: string;
  className?: string;
}

export const HoverCopy: FunctionComponent<HoverCopyProps> = ({ name, className }) => {
  const [isHover, setIsHover] = useState(false);

  const { copied, copy } = useClipboard();

  const onClickCopy = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, name: string) => {
    event.stopPropagation();
    copy(name);
  }, []);

  return (
    <div
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}
      style={{ width: 'fit-content' }}
    >
      <span>{name} </span>
      <StyledButton
        variant="link"
        onClick={(event) => onClickCopy(event, name)}
        disabled={copied}
        $isHover={isHover}
        className={className}
      >
        {copied ? <Icon color="primary" name="check" /> : <Icon name="copy" color="primary" />}
      </StyledButton>
    </div>
  );
};

export default HoverCopy;
