import { captureException } from '@sentry/browser';
import { mergeAll } from 'lodash/fp';
import { FetchParams, Middleware, RequestContext, ResponseContext } from 'api';
import applicationConfig from 'core/config/local';
import { ApiError, ErrorInterface } from 'core/api/errors';
import { authTokenSelector } from 'core/auth/selectors';
import type { RootAppState } from 'store/types';

export function accessMiddleware(access: string): Middleware {
  return {
    pre: async (context: RequestContext): Promise<FetchParams | void> => {
      return access ? mergeAll([context, { init: { headers: { Authorization: 'Bearer ' + access } } }]) : context;
    },
  };
}

export function authMiddleware<S extends RootAppState = RootAppState>(getState: () => S): Middleware {
  return {
    pre: async (context: RequestContext): Promise<FetchParams | void> => {
      const state = getState();
      const { authToken } = authTokenSelector(state);

      return authToken ? mergeAll([context, { init: { headers: { Authorization: 'Bearer ' + authToken } } }]) : context;
    },
  };
}

export function errorMiddleware(): Middleware {
  return {
    post: async (context: ResponseContext): Promise<Response | void> => {
      const { response } = context;

      if (response.status >= 200 && response.status < 300) return response;

      let info: ErrorInterface | undefined;
      try {
        const data: any = await response.json();
        if (data.hasOwnProperty('error_codes')) info = data as ErrorInterface;
      } catch (e) {
        // Might not be a JSON response, such as 500 or 503
      }

      if (!info && applicationConfig.sentry) {
        captureException(new ApiError(info, response));
      }

      throw new ApiError(info, response);
    },
  };
}
