import { AdminInvestment, Money, NetworkTypeEnum, WalletTypeEnum } from 'api';
import useTokensForRedemption from 'hooks/use-tokens-for-redemption';
import React, { FunctionComponent, useState } from 'react';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import CreateRedemptionModal from './create-redemption-modal';

export interface Wallet {
  id: string;
  address: string | null | undefined;
  type: WalletTypeEnum;
  balance: number;
}

export interface Product {
  id: string;
  name: string;
  isTokenized: boolean;
  tokenPrice: Money;
  wallet?: Wallet;
  type: NetworkTypeEnum;
  remainingAmountSum: number;
}

export interface CreateRedemptionProps {
  investments: AdminInvestment[];
  investorId: string;
  onSuccessfulRedemption: () => void;
}

const CreateRedemptionView: FunctionComponent<CreateRedemptionProps> = (props) => {
  const [isCreateRedemptionModalOpen, setCreateRedemptionModalOpen] = useState(false);

  const { onSuccessfulRedemption, investments, investorId } = props;

  const products = useTokensForRedemption(investments, isCreateRedemptionModalOpen);

  return (
    <>
      <Button variant="primary" onClick={() => setCreateRedemptionModalOpen(true)}>
        <Translate name="dashboardIssuerInvestorDetails.createRedemptionForm.title" />
      </Button>
      {isCreateRedemptionModalOpen && !!products.length && (
        <CreateRedemptionModal
          onHideCreateRedemptionModal={() => setCreateRedemptionModalOpen(false)}
          onSuccessfulRedemption={onSuccessfulRedemption}
          products={products}
          investorId={investorId}
        />
      )}
    </>
  );
};

export default CreateRedemptionView;
