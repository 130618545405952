import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export interface ListProps {
  $truncate?: boolean;
  $displayMarkers?: boolean;
}

function getDefaultStyle() {
  return css`
    li {
      padding-bottom: 0.5rem;
    }
  `;
}

function getTruncation({ $truncate }: ThemedStyledProps<ListProps, DefaultTheme>) {
  if (!$truncate) return null;

  return css`
    li {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;
}

function getDisplayMarkers({ $displayMarkers }: ThemedStyledProps<ListProps, DefaultTheme>) {
  if ($displayMarkers) return null;

  return css`
    list-style-type: none;
    padding-left: 0;
  `;
}

export const OrderedList = styled.ol<ListProps>`
  ${getDefaultStyle};
  ${getTruncation};
  ${getDisplayMarkers};
`;

export const UnorderedList = styled.ul<ListProps>`
  ${getDefaultStyle};
  ${getTruncation};
  ${getDisplayMarkers};
`;
