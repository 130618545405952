import React, { FunctionComponent } from 'react';
import Beneficiary from 'libraries/wizard/components/beneficiary';
import useBeneficiary from './use-beneficiary';

const BeneficiaryStep: FunctionComponent = () => {
  const props = useBeneficiary();

  return <Beneficiary {...props} />;
};

export default BeneficiaryStep;
