import React, { FunctionComponent, ReactNode } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi, AdminVouchersListRequest, AdminVoucher } from 'api';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import StudioVouchersTable from './StudioVouchersTable';

interface IssuerVouchersTableProps extends AdminVouchersListRequest {
  filters?: ReactNode;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
  forceDataLoad?: boolean;
}

const IssuerVouchersTable: FunctionComponent<IssuerVouchersTableProps> = WithDataRecord<AdminApi, AdminVoucher>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.voucherDetails);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <StudioVouchersTable
          loading={loading}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_VOUCHERS} />}
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(id: string) => goTo({ voucherId: id })}
          vouchers={data}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminVouchersList({
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default IssuerVouchersTable;
