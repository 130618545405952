import React, { FunctionComponent } from 'react';
import { ErrorBoundary as ErrorBoundaryView } from 'react-error-boundary';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import { useTranslateWithStringArgs } from 'src/ui/hooks/use-translate';
import * as Styled from './styled';

const ErrorBoundary: FunctionComponent = (props) => {
  const { children } = props;

  const translate = useTranslateWithStringArgs();

  return (
    <ErrorBoundaryView
      fallback={
        <Styled.ErrorFoundLayout>
          <Styled.SubHeadline>
            <Translate name={'pageErrorFound.subheadline'} />
          </Styled.SubHeadline>
          <h1>
            <Translate name={'pageErrorFound.headline'} />
          </h1>
          <p>
            <Translate name={'pageErrorFound.description'} />
          </p>
          <Button variant="primary">
            <Styled.SupportLink href={`mailto:${translate('platform.supportEmail')}`} variant="primary">
              <Translate name={'pageErrorFound.supportButtonText'} />
            </Styled.SupportLink>
          </Button>
        </Styled.ErrorFoundLayout>
      }
    >
      {children}
    </ErrorBoundaryView>
  );
};

export default ErrorBoundary;
