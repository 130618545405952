import React, { FunctionComponent } from 'react';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';

export interface CampaignAccessDeniedProps {
  email?: string;

  /** Additional classes. */
  className?: string;

  logout: () => void;
}

const CampaignAccessDenied: FunctionComponent<CampaignAccessDeniedProps> = (props) => {
  const { logout = () => {}, email } = props;

  return (
    <NarrowContent>
      <BoxedContent>
        <div>
          <p>
            <Translate
              name="campaignInvitation.accessDeniedForUser"
              args={[(fallbackUser, key) => <strong key={key}>{email}</strong>]}
            />
          </p>
          <Button fluid={true} size="large" onClick={logout} variant="primary">
            <Translate name="common.logoutAndContinue" />
          </Button>
        </div>
      </BoxedContent>
    </NarrowContent>
  );
};

export default CampaignAccessDenied;
