import React, { FunctionComponent } from 'react';
import groupBy from 'lodash/groupBy';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Translate from 'ui/atoms/translate';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import Grid from 'ui/atoms/grid';
import * as Styled from './styled';
import Section from 'ui/atoms/section';
import useTranslate from 'ui/hooks/use-translate';
import Input from 'ui/atoms/input';

export interface SummarySplitSegmentMobileProps {
  questions: QuestionnaireQuestions[];
  segmentId?: string;
}

const SummarySplitSegmentMobile: FunctionComponent<SummarySplitSegmentMobileProps> = ({ questions }) => {
  const questionRows = groupBy(questions, (question) => question.productId);
  const translate = useTranslate();

  const getExperienceSubgroupMobile = (subgroup?: string) => {
    return (
      <QuestionnaireForm.ValueProvider>
        {(values) => {
          return Object.entries(questionRows).map(([productId, questionRow]) => {
            const [knowledge, experience] = questionRow;
            const knowledgeValue = values[knowledge.id];
            const experienceValue = values[experience.id];

            if ((subgroup && knowledge.subgroupId !== subgroup) || (!subgroup && knowledge.subgroupId)) return null;

            return (
              <Styled.QuestionnaireSplitFormDiv key={productId}>
                <Grid>
                  <Styled.QuestionnaireCenteredText>
                    <Translate as="h4" name={`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(productId)}`} />
                  </Styled.QuestionnaireCenteredText>
                </Grid>
                <Section spacing="small">
                  <Grid>
                    <Styled.QuestionnaireCenteredText>
                      <Translate name="questionnaire.investmentExperienceCategories.knowledge" />
                    </Styled.QuestionnaireCenteredText>
                    <Styled.QuestionnaireCenteredText>
                      <Styled.ShortSummaryInput
                        key={knowledge.id}
                        height="small"
                        textAlign="center"
                        value={
                          translate(`${DEFAULT_SEGMENT_KEY}.${knowledgeValue === 'knowledge' ? 'yes' : 'no'}`) as string
                        }
                        disabled
                      />
                    </Styled.QuestionnaireCenteredText>
                  </Grid>
                </Section>
                <Grid>
                  <Styled.QuestionnaireCenteredText>
                    <Translate name="questionnaire.investmentExperienceCategories.experience" />
                  </Styled.QuestionnaireCenteredText>
                  <Styled.QuestionnaireCenteredText>
                    <Input
                      key={experience.id}
                      height="small"
                      textAlign="center"
                      value={
                        translate(`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(experienceValue)}`) as string
                      }
                      disabled
                    />
                  </Styled.QuestionnaireCenteredText>
                </Grid>
              </Styled.QuestionnaireSplitFormDiv>
            );
          });
        }}
      </QuestionnaireForm.ValueProvider>
    );
  };

  return (
    <>
      {getExperienceSubgroupMobile()}
      {getExperienceSubgroupMobile('crowdfunding')}
    </>
  );
};

export default SummarySplitSegmentMobile;
