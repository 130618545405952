import React, { FunctionComponent } from 'react';
import FormGroup from 'ui/molecules/form-group';
import CancelFilter from 'ui/molecules/filtering/cancel-filter';
import * as Styled from './styled';

export const FilterRange: FunctionComponent<{
  inputNames: string[];
  filterName: string;
  label: string;
}> = ({ children, inputNames, filterName, label }) => {
  return (
    <FormGroup label={label}>
      <Styled.FilterRange>
        <Styled.Range>
          {React.Children.map(children, (child, index) => (
            <>
              {!!index && <Styled.RangeDivider>-</Styled.RangeDivider>}
              {child}
            </>
          ))}
        </Styled.Range>
        <CancelFilter inputNames={inputNames} filterName={filterName} />
      </Styled.FilterRange>
    </FormGroup>
  );
};

export default FilterRange;
