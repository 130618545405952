import Size from 'ui/types/size';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export type ImageSize = Size | 'xlarge';

export interface ImageContainerProps {
  $size?: ImageSize;
  $horizontalCenter?: boolean;
  $verticalCenter?: boolean;
  $fluid?: boolean;
  $bordered?: boolean;
  $circular?: boolean;
}

export interface ImageProps {
  $centerCover?: boolean;
  $limitToContainerWidth?: boolean;
  $limitToContainerHeight?: boolean;
  $fluid?: boolean;
  $bordered?: boolean;
  $circular?: boolean;
}

function getSize({ theme, $size }: ThemedStyledProps<ImageContainerProps, DefaultTheme>) {
  if (!$size) return null;

  const size = theme.images.size[$size];

  return css<ImageContainerProps>`
    max-width: ${size}px;
    max-height: ${size}px;
  `;
}

function getBorderedImage({ theme, $bordered }: ThemedStyledProps<ImageProps, DefaultTheme>) {
  if (!$bordered) return null;

  return css<ImageProps>`
    border-radius: 8px;
    box-shadow: 0px 5px 5px -3px #00000033, 0px 3px 14px 2px #0000001f, 0px 8px 10px 1px #00000024;
  `;
}

function getCircularImage({ $circular, $bordered }: ThemedStyledProps<ImageProps, DefaultTheme>) {
  if (!$circular) return null;

  if (!$bordered) {
    return css`
      border-radius: 500em;
    `;
  }
}

export const Image = styled.img<ImageProps>`
  ${getBorderedImage};
  ${getCircularImage};
  ${getSize};
`;
