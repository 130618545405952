import { resolveColor } from 'ui/styles/utils/colors';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export interface IconContainerProps {
  $border?: boolean;
  $inputHover?: boolean;
  $disabled?: boolean;
}

export interface StepperProps {
  $inputHover: boolean;
}

function getStepperDefaultStyle({ theme, $inputHover }: ThemedStyledProps<StepperProps, DefaultTheme>) {
  return css<StepperProps>`
    position: absolute;
    top: 0rem;
    right: 0rem;
    color: ${resolveColor(theme, 'grayHover')};
    height: 100%;
    border-left: 1px solid ${$inputHover ? theme.input.borderFocusedColor : resolveColor(theme, 'grayHover')};
    width: 2rem;
  }`;
}

function getIconContainerDefaultStyle({
  theme,
  $border,
  $disabled,
}: ThemedStyledProps<IconContainerProps, DefaultTheme>) {
  return css`
    display: flex;
    justify-content: center;
    height: 50%;
    ${!$disabled &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${resolveColor(theme, 'primaryLight')};
        border-top-right-radius: ${$border ? '0.5rem' : 'none'};
        border-bottom-right-radius: ${$border ? 'none' : '0.5rem'};
        svg {
          fill: ${resolveColor(theme, 'primaryMain')};
        }
      }
    `}
  `;
}

function getIconBorder({ theme, $border, $inputHover }: ThemedStyledProps<IconContainerProps, DefaultTheme>) {
  if (!$border) return null;

  return css<IconContainerProps>`
    border-bottom: 1px solid ${$inputHover ? theme.input.borderFocusedColor : resolveColor(theme, 'grayHover')};
  `;
}

export const Stepper = styled.div<StepperProps>`
  ${getStepperDefaultStyle};
`;
export const StepperIconContainer = styled.div<IconContainerProps>`
  ${getIconContainerDefaultStyle};
  ${getIconBorder};
`;
