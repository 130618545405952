import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import ServerError from 'ui/types/server-error';
import { QuestionnaireForm } from '../common';

interface QuestionnaireButtonsProps {
  goBack: () => void;
  isEcsp: boolean;
  isLastPage: boolean;
  isFirstPage: boolean;
  error?: ServerError;
  loading?: boolean;
}

const QuestionnaireButtons: FunctionComponent<QuestionnaireButtonsProps> = (props) => {
  const { isLastPage, isFirstPage, isEcsp, loading } = props;

  return (
    <QuestionnaireForm.ActionButtons align={isFirstPage && isEcsp ? 'spaceBetween' : 'end'}>
      {isFirstPage && isEcsp && (
        <QuestionnaireForm.Group name="giveNoAnswers" spacing="none">
          <QuestionnaireForm.Checkbox>
            <Translate name="questionnaire.giveNoAnswers" />
          </QuestionnaireForm.Checkbox>
        </QuestionnaireForm.Group>
      )}
      {!isLastPage && (
        <Button type="submit" variant="primary" size="large" fluid={true} loading={loading}>
          <Translate name={'common.continue'} />
        </Button>
      )}
      {isLastPage && (
        <Button type="submit" variant="primary" size="large" fluid={true} loading={loading}>
          <Translate name={'questionnaire.send'} />
        </Button>
      )}
    </QuestionnaireForm.ActionButtons>
  );
};

export default QuestionnaireButtons;
