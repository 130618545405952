import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router';

import DefaultLayout from 'core/layout/default';
import AuthSubapp from 'core/auth';
import InvestorApp from 'apps/investor';
import IssuerApp from 'apps/issuer';
import InvestmentApp from 'subapps/investment';
import DemoSubapp from 'subapps/demo';
import PageNotFound from 'core/components/not-found';
import AppRoot from 'core/main/app-root';
import DashboardRoot from 'core/main/dashboard-root';
import { AUTH_PATH_LIST } from 'core/auth/pages/routes.config';
import { INVESTOR_PATHS } from 'apps/investor/pages/routes.config';
import { INVESTMENT_PATHS } from 'subapps/investment/pages/routes.config';
import { ISSUER_PATHS } from 'apps/issuer/pages/routes.config';
import { DEMO_PATH_LIST } from 'subapps/demo/pages/routes.config';

const AppRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/" exact component={AppRoot} />
      <Route path={AUTH_PATH_LIST}>
        <AuthSubapp />
      </Route>
      <Route path={DEMO_PATH_LIST}>
        <DemoSubapp />
      </Route>
      <Route path={INVESTMENT_PATHS}>
        <InvestmentApp />
      </Route>
      <Route path="/dashboard" exact component={DashboardRoot} />
      {/*todo: proper subapp architecture should be set up here*/}
      <Route path={INVESTOR_PATHS}>
        <InvestorApp />
      </Route>
      <Route path={ISSUER_PATHS}>
        <IssuerApp />
      </Route>
      <Route>
        <DefaultLayout segment={false} pageTitle={false}>
          <PageNotFound />
        </DefaultLayout>
      </Route>
    </Switch>
  );
};

export default AppRoutes;
