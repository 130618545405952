import React, { FunctionComponent, useContext } from 'react';
import Button from 'ui/atoms/button';
import { convertWalletTypeFromApi } from 'core/api/conversions';
import { WalletType } from 'ui/types/wallet';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import LoadingRing from 'ui/atoms/loading-ring';
import WalletStepSuccess from './wallet-step-success';
import WizardContext from 'libraries/wizard/wizard-context';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';
import { useWallets } from 'hooks/use-wallets';

const WalletStepDone: FunctionComponent<{}> = () => {
  const { wallets, loading } = useWallets();

  const { finalize } = useContext(WizardContext);

  const title = <Translate name="investmentDepot.title" />;

  if (loading) {
    return <LoadingRing />;
  }

  if (!wallets?.length) {
    return null;
  }

  const walletType: WalletType | undefined = convertWalletTypeFromApi(wallets[0].type);

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <WalletStepSuccess
        walletType={walletType}
        walletAddress={wallets[0].ethInfo?.address || wallets[0].stellarInfo?.accountId}
      />
      <ActionButtons>
        <Button
          fluid={true}
          size="large"
          variant="primary"
          onClick={() => {
            finalize();
          }}
        >
          <Translate name="common.continue" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default WalletStepDone;
