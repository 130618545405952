import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import useApiCall from 'hooks/use-api-call';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import { useDispatch } from 'store/hooks';
import { handleError } from 'ui/helper/error-handling';
import useTranslate from 'ui/hooks/use-translate';
import BoxedContent from 'ui/molecules/boxed-content';
import { AppType } from 'core/auth/types';
import { CampaignsApi, Invitation, InvitationsApi } from 'api';
import { useCurrentUserSelector } from 'core/auth/hooks';
import { reLogin } from 'core/auth/actions';
import BoxedInvestmentError from 'components/Investment/BoxedInvestmentError';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import Button from 'ui/atoms/button';
import useGoTo from 'hooks/use-go-to';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import type { LocalStorageInvitation } from 'subapps/investment/types';
import {
  getInvitationIdsLocal,
  removeRegistrationLocalStorage,
  setInvitationIdLocal,
} from 'subapps/investment/helpers';
import TransferInvitationUserLogged from 'subapps/investment/pages/investment/error-messages/TransferInvitationUserLogged';
import CampaignAccessDenied from './campaign-access-denied';
import ActionButtons from 'ui/molecules/action-buttons';
import Grid, { Col } from 'src/ui/atoms/grid';

const CampaignEnterInvestmentProcess: FunctionComponent<{
  campaignId: string;
  isTransferInvitation?: boolean;
}> = ({ campaignId, isTransferInvitation }) => {
  const dispatch = useDispatch();
  const [previousProcess, setPreviousProcess] = useState<LocalStorageInvitation | null>();
  const [showTansferInvitationUserLoggedError, setShowTansferInvitationUserLoggedError] = useState(false);
  const { error, makeAuthenticatedApi, withApi } = useApiCall(true);

  const {
    error: invitationsApiError,
    makeAuthenticatedApi: makeAuthenticatedInvitationsApi,
    withApi: withInvitationsApi,
  } = useApiCall(true);

  const invitationsApi: InvitationsApi = useMemo(
    () => makeAuthenticatedInvitationsApi(InvitationsApi),
    [makeAuthenticatedInvitationsApi],
  );

  const { currentUser } = useCurrentUserSelector();
  const isLoggedIn = !!currentUser;
  const investorId = currentUser?.investor;

  const goTo = useGoTo(INVESTMENT_ROUTES.investment);

  const startInvestment = async (
    goTo: (params: object) => void,
    makeAuthenticatedApi: (Api: any) => any,
    campaignId: string,
    investorId: string | undefined,
  ) => {
    const campaignsApi = await makeAuthenticatedApi(CampaignsApi);
    const invitationsApi = await makeAuthenticatedApi(InvitationsApi);

    let invitationId = '';
    try {
      const response = await campaignsApi.campaignsInvitationCreate({
        id: campaignId,
      });
      invitationId = response.id;
    } catch (e) {
      if (e.hasErrorCode('transfer_invitation_already_logged_in')) {
        setShowTansferInvitationUserLoggedError(true);
        return;
      }
    }

    const invitation: Invitation = await invitationsApi.invitationsRetrieve({
      id: invitationId,
    });
    // TODO(mara-cashlink): hack because generated client is broken (see also CP-501),
    //  remove when CampaignInvitationPreviewFromJSONTyped is fixed
    type HackedInvitation = Invitation & { product_name: string };
    const invitationHack: HackedInvitation = invitation as HackedInvitation;

    // TODO(mara-cashlink): hack because generated client is broken (see also CP-501),
    //   remove when CampaignInvitationPreviewFromJSONTyped is fixed
    const productName: string = invitation.productName || invitationHack.product_name;

    const forLocalStorage: LocalStorageInvitation = {
      campaignId,
      investorId,
      invitationId,
      productName,
      startedAt: moment().toString(),
    };

    setInvitationIdLocal(invitationId, JSON.stringify(forLocalStorage));
    removeRegistrationLocalStorage();
    goTo({ invitationId });
  };

  useEffect(() => {
    if (!invitationsApiError) return;
    setPreviousProcess(null);
  }, [invitationsApiError]);

  useEffect(() => {
    if (previousProcess === null) {
      withApi(() => startInvestment(goTo, makeAuthenticatedApi, campaignId, investorId));
    }
  }, [campaignId, withApi, makeAuthenticatedApi, goTo, previousProcess]);

  useEffect(() => {
    const localStorageInvitations = getInvitationIdsLocal();

    const sortedLocalStorageInvitations = orderBy(
      localStorageInvitations,
      (localStorageInvitation) => {
        return moment(localStorageInvitation.startedAt);
      },
      ['desc'],
    ) as LocalStorageInvitation[];

    const matchingStoredInvitation = find(
      sortedLocalStorageInvitations,
      isLoggedIn
        ? {
            campaignId,
            investorId,
          }
        : { campaignId },
    );

    if (isLoggedIn) {
      withInvitationsApi(async () => {
        const { results: openCampaignInvitations } = await invitationsApi.invitationsList({
          status: 'open',
          campaignId: campaignId,
          ordering: '-created_at',
          limit: 1,
        });

        const invitationForRedirection = openCampaignInvitations && openCampaignInvitations[0];
        if (invitationForRedirection) {
          goTo({ invitationId: invitationForRedirection.id });
        } else {
          setPreviousProcess(null);
        }
      });
    } else if (!isTransferInvitation) {
      setPreviousProcess(matchingStoredInvitation || null);
    } else {
      setPreviousProcess(null);
    }
  }, [isLoggedIn]);

  const translate = useTranslate();

  const { getErrorForField } = handleError({
    error,
    translate,
  });

  if (showTansferInvitationUserLoggedError) {
    return <TransferInvitationUserLogged />;
  }

  if (getErrorForField('user')) {
    return (
      <CampaignAccessDenied
        email={currentUser?.email}
        logout={() => {
          dispatch(reLogin(AppType.INVESTMENT));
        }}
      />
    );
  }

  // TODO(geforcefan): we need an error component, a good looking one!
  if (error) return <BoxedInvestmentError error={error} />;

  if (previousProcess === undefined) {
    return <LoadingRing />;
  }
  if (previousProcess && !isLoggedIn) {
    return (
      <Grid>
        <Col
          desktop={{ width: 8, offset: 2 }}
          sDesktop={{ width: 8, offset: 2 }}
          lDesktop={{ width: 8, offset: 2 }}
          phone={12}
        >
          <BoxedContent
            title={<Translate name="continuePreviousProcessPrompt.title" args={[previousProcess.productName]} />}
          >
            <Translate as="p" name="continuePreviousProcessPrompt.description" args={[previousProcess.productName]} />
            <ActionButtons>
              <Button
                variant="secondary"
                size="large"
                fluid={true}
                onClick={() => {
                  setPreviousProcess(null);
                }}
              >
                <Translate name="continuePreviousProcessPrompt.startNewProcess" />
              </Button>
              <Button
                variant="primary"
                size="large"
                fluid={true}
                onClick={() => goTo({ invitationId: previousProcess.invitationId })}
              >
                <Translate name="continuePreviousProcessPrompt.continueProcess" />
              </Button>
            </ActionButtons>
          </BoxedContent>
        </Col>
      </Grid>
    );
  }

  return <LoadingRing />;
};

export default CampaignEnterInvestmentProcess;
