import { Person } from '../api/models';
import { PersonToLegalPerson, PersonToNaturalPerson } from './cast';

export const getAccountHolderInitial = (translate: any, person?: Person) => {
  if (!person) return undefined;

  const legalPerson = PersonToLegalPerson(person);
  const naturalPerson = PersonToNaturalPerson(person);

  return legalPerson ? legalPerson.companyName : `${naturalPerson?.forename} ${naturalPerson?.surname}`;
};
