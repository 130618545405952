import React, { FunctionComponent } from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import DocumentTable from 'src/libraries/document-table';
import Translate from 'ui/atoms/translate';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi } from 'api';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import IssuerEmptyView from 'core/components/empty-views/issuer';

import { Document as UIDocument } from 'ui/types/document';

import { take, drop, orderBy } from 'lodash';

const IssuerDocumentsTable: FunctionComponent = WithDataRecord<AdminApi, UIDocument>(AdminApi)(
  ({ data, paginationProps }) => {
    if (!data?.length) {
      return <IssuerEmptyView type={EmptyViewType.ISSUER_DOCUMENTS} />;
    }

    return <DocumentTable documents={data} withProductName paginationProps={paginationProps} />;
  },
  (api, props, offset, limit, ordering) => {
    return (async () => {
      const tokens = await api.adminTokensList({});
      if (tokens) {
        // todo, this transformation is a bit odd, should probably get a straightforward answer from the api
        // todo, since this is already in redux, look into ways of making tables work with redux
        const data = tokens.reduce((previous, { name, issuerDocuments }) => {
          return [...previous, ...issuerDocuments.map((doc) => ({ ...doc, productName: name }))];
        }, [] as UIDocument[]);

        const desc = ordering?.substr(0, 1) === '-';
        const orderField = ordering?.substr(desc ? 1 : 0);
        const results = orderBy(take(drop(data, offset), limit), orderField, desc ? 'desc' : 'asc');

        return {
          results: results,
          count: data.length,
        };
      } else
        return {
          results: [],
          count: 0,
        };
    })();
  },
);

const IssuerDocumentsPage: FunctionComponent = () => {
  return (
    <WideContent title={<Translate name="dashboard.issuerDocuments" />}>
      <IssuerDocumentsTable />
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerDocumentsPage, AppType.ISSUER);
