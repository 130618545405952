import React, { FunctionComponent, ReactNode, useState } from 'react';
import Icon from 'ui/atoms/icon';
import Popover from 'ui/molecules/popover';
import * as Styled from './styled';
import type { Colors } from 'ui/styles/utils/colors';
import type { PopoverPositions } from 'ui/molecules/popover/types';

export interface InfoProps {
  children?: ReactNode;
  color?: Colors;
  parentElement?: any; // typing refs is a pain
  positions?: PopoverPositions[];
  className?: string;
}

export const Info: FunctionComponent<InfoProps> = ({
  children,
  positions,
  parentElement,
  color = 'secondary',
  className,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);

  return (
    <>
      <Popover
        isOpen={forceOpen || isOpen}
        positions={positions}
        parentElement={parentElement?.current || window.document.body}
        onClickOutside={() => setOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <Styled.Arrow position={position} childRect={childRect} popoverRect={popoverRect} color={color} size={10}>
            <Styled.Info $color={color}>{children}</Styled.Info>
          </Styled.Arrow>
        )}
        containerClassName={className}
      >
        <Styled.InfoIcon>
          <Icon
            name="info-circle"
            color="secondary"
            size="large"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => setForceOpen(!forceOpen)}
          />
        </Styled.InfoIcon>
      </Popover>
    </>
  );
};

export default Info;
