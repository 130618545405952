import { css, CSSObject, DefaultTheme, ThemedCssFunction } from 'styled-components';

export enum DEVICES {
  desktop = 'desktop',
  lDesktop = 'lDesktop',
  sDesktop = 'sDesktop',
  tablet = 'tablet',
  lTablet = 'lTablet',
  phone = 'phone',
  desktops = 'desktops',
  largeDesktops = 'largeDesktops',
  tabletDesktop = 'tabletDesktop',
  phoneTablet = 'phoneTablet',
  phoneTabletSDesktop = 'phoneTabletSDesktop',
}

export const SIZES = {
  [DEVICES.lDesktop]: 1440,
  [DEVICES.desktop]: 1280,
  [DEVICES.sDesktop]: 1024,
  [DEVICES.lTablet]: 768,
  [DEVICES.tablet]: 600,
  [DEVICES.phone]: 0,
};

type Queries = {
  [key in DEVICES]: string;
};

export const QUERIES: Queries = {
  [DEVICES.lDesktop]: `screen and (min-width: ${SIZES[DEVICES.lDesktop]}px)`,
  [DEVICES.desktop]: `screen and (min-width: ${SIZES[DEVICES.desktop]}px) and (max-width: ${
    SIZES[DEVICES.lDesktop] - 1
  }px)`,
  [DEVICES.sDesktop]: `screen and (min-width: ${SIZES[DEVICES.sDesktop]}px) and (max-width: ${
    SIZES[DEVICES.desktop] - 1
  }px)`,
  [DEVICES.lTablet]: `screen and (min-width: ${SIZES[DEVICES.lTablet]}px) and (max-width: ${
    SIZES[DEVICES.sDesktop] - 1
  }px)`,
  [DEVICES.tablet]: `screen and (min-width: ${SIZES[DEVICES.tablet]}px) and (max-width: ${
    SIZES[DEVICES.lTablet] - 1
  }px)`,
  [DEVICES.phone]: `screen and (min-width: ${SIZES[DEVICES.phone]}px) and (max-width: ${SIZES[DEVICES.tablet] - 1}px)`,
  [DEVICES.desktops]: `screen and (min-width: ${SIZES[DEVICES.sDesktop]}px)`,
  [DEVICES.largeDesktops]: `screen and (min-width: ${SIZES[DEVICES.desktop]}px)`,
  [DEVICES.tabletDesktop]: `screen and (min-width: ${SIZES[DEVICES.tablet]}px)`,
  [DEVICES.phoneTablet]: `screen and (min-width: ${SIZES[DEVICES.phone]}px) and (max-width: ${
    SIZES[DEVICES.sDesktop] - 1
  }px)`,
  [DEVICES.phoneTabletSDesktop]: `screen and (min-width: ${SIZES[DEVICES.phone]}px) and (max-width: ${
    SIZES[DEVICES.desktop] - 1
  }px)`,
};

type Query<P extends object = DefaultTheme> = {
  [key in DEVICES]: ThemedCssFunction<P>;
};

/**
 * creates a reusable media query for each device with template literals, usage: media.phone`padding: 20px;`
 */
export const query: Query = Object.keys(QUERIES).reduce<Query>((obj: Query, device: DEVICES) => {
  obj[device] = (first: CSSObject | TemplateStringsArray, ...args: Array<CSSObject | TemplateStringsArray>) => css`
    @media ${QUERIES[device]} {
      ${css(first, ...args)}
    }
  `;

  return obj;
}, {} as Query);
