import { useEffect, useState } from 'react';
import { AdminInvestment, NetworkTypeEnum, WalletTypeEnum } from 'api';
import { Product } from 'apps/issuer/pages/investor-details/create-redemption';
import { currentTokenHasManageRedemptionsPermission } from 'apps/issuer/helpers/permissions';
import { getWalletAddressForNetwork } from 'ui/helper/networks';

const useTokensForRedemption = (investments: AdminInvestment[], isCreateRedemptionModalOpen: boolean): Product[] => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    filterInvestments();
  }, [investments, isCreateRedemptionModalOpen]);

  const filterInvestments = () => {
    if (!isCreateRedemptionModalOpen) return;

    let filteredInvestments = investments?.filter(
      (investment) => investment.remainingAmount >= 1 && currentTokenHasManageRedemptionsPermission(investment.token),
    );

    if (!filteredInvestments?.length) return;

    let products: Product[] = [];
    const remainingAmountSum = filteredInvestments
      .map((investment) => investment.remainingAmount)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    products = filteredInvestments.map((investment) => ({
      id: investment.token.id,
      name: investment.token.name,
      isTokenized: investment.isTokenized,
      tokenPrice: investment.tokenPrice,
      type: investment.token.tokenizationInfo?.networkType || NetworkTypeEnum.ETHEREUM,
      wallet: {
        id: investment.wallet?.id || '',
        address:
          investment.wallet && investment.token.tokenizationInfo
            ? getWalletAddressForNetwork(
                investment.token.tokenizationInfo.networkType || NetworkTypeEnum.ETHEREUM,
                investment.wallet,
              )
            : '',
        type: investment.wallet?.type || WalletTypeEnum.TANGANY,
        balance: 0,
      },
      remainingAmountSum: remainingAmountSum,
    }));
    setProducts(products);
  };

  return products;
};

export default useTokensForRedemption;
