import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import BoxedContent from 'ui/molecules/boxed-content';
import DashboardInvestmentDetails from 'src/apps/investor/shared/dashboard-investment-details';
import useApiCall from 'hooks/use-api-call';
import { WizardApi, CampaignsApi, Invitation } from 'api';
import { InvestorInvestmentStatusEnum } from 'ui/types/investment';
import { getInvestorInvestmentStatus } from 'helper/investment-status';
import {
  NetworkTypeEnum,
  Wallet,
  ProductTypeEnum,
  InvestmentAcquisitionTypeRecipientEnum,
  Money,
  AdminInvestmentDetails,
} from 'api';
import { getWalletAddressForNetwork } from 'helper/network';
import { toNumber } from 'ui/helper/money';
import InvestorInvestmentActionButtons from 'src/apps/investor/shared/investment-action-buttons';

export interface InvestmentInvitation {
  id: string;
  isEcsp: boolean | undefined;
  productName?: string;
  investmentTotal?: Money;
  investmentTotalFulfilled?: Money;
  signedDate?: Date;
  sumSettledPayouts?: Money;
  lastSettledPayout?: Money | null;
  nextScheduledPayout?: Date | null;
  maxNumberOfTokens?: number;
  remainingAmount?: number;
  wallet?: Wallet | null;
  canAbort?: boolean;
  isTokenized?: boolean;
  tokenTransactionHash?: string;
  token?: {
    tokenizationInfo: { networkType: NetworkTypeEnum } | null;
    productType: ProductTypeEnum;
    isEcsp: boolean | undefined;
  };
  fulfilledAmount?: string | null;
  tokenPrice?: Money;
  acquisitionType?: InvestmentAcquisitionTypeRecipientEnum;
  campaignId?: string;
  redeemed?: boolean;
  considerationStartedAt?: Date;
}

const BoxedOpenInvestment: FunctionComponent<{
  onCancelInvestment: () => void;
  investment: AdminInvestmentDetails | InvestmentInvitation;
}> = (props) => {
  const { onCancelInvestment, investment } = props;

  const networkType: NetworkTypeEnum | undefined = investment.token?.tokenizationInfo?.networkType;

  const [investorStatus, setInvestorStatus] = useState<InvestorInvestmentStatusEnum>();
  const [remainingAmount, setRemainingAmount] = useState<number | undefined>();

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(false);

  const wizardApi: WizardApi = useMemo(() => makeAuthenticatedApi(WizardApi), [makeAuthenticatedApi]);
  const campaignsApi: CampaignsApi = useMemo(() => makeAuthenticatedApi(CampaignsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      const wizard = await wizardApi.wizardRetrieve({
        code: investment.id,
      });

      if (!wizard.step) return;

      const hasWalletAddress = !!(
        investment.wallet &&
        networkType &&
        getWalletAddressForNetwork(networkType, investment.wallet)
      );
      setInvestorStatus(
        getInvestorInvestmentStatus({
          step: wizard.step,
          hasTokenTransactionHash: !!investment.tokenTransactionHash,
          isWalletManaged: investment.wallet?.managed,
          hasWalletAddress: hasWalletAddress,
          isTokenized: investment.isTokenized,
          investmentTotal: toNumber(investment?.investmentTotal || 0),
          investmentTotalFulfilled: toNumber(investment?.investmentTotalFulfilled),
          acquisitionType: investment.acquisitionType,
          isRedeemed: investment.redeemed,
        }),
      );
    });
  }, [withApi, wizardApi, investment.id]);

  useEffect(() => {
    withApi(async () => {
      let remainingAmount;
      if ((investment as InvestmentInvitation).campaignId) {
        const campaign = await campaignsApi.campaignsRetrieve({
          id: (investment as InvestmentInvitation).campaignId || '',
        });
        remainingAmount = campaign.allocationAvailable;
      } else if ((investment as InvestmentInvitation).maxNumberOfTokens) {
        remainingAmount = (investment as InvestmentInvitation).maxNumberOfTokens;
      } else {
        remainingAmount = (investment as InvestmentInvitation).remainingAmount;
      }
      setRemainingAmount(remainingAmount);
    });
  }, [withApi, campaignsApi, investment.id]);

  if (!investment || !investorStatus || remainingAmount === undefined || loading) {
    return <LoadingRing />;
  }

  const fulfilledAmount = Number(investment.fulfilledAmount || '0');
  const isEcsp =
    (investment as Invitation)?.isEcsp !== undefined ? (investment as Invitation)?.isEcsp : investment.token?.isEcsp;

  return (
    investment &&
    investorStatus && (
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <DashboardInvestmentDetails
          investment={investment}
          openInvestment={true}
          investorStatus={investorStatus}
          walletAddress={
            (investment.wallet && networkType && getWalletAddressForNetwork(networkType, investment?.wallet)) ||
            undefined
          }
          areAllTokensUnavailable={remainingAmount === 0}
          areTokensPartiallyAvailable={remainingAmount !== 0 && fulfilledAmount > remainingAmount}
          isEcsp={isEcsp}
        />
        <InvestorInvestmentActionButtons
          status={investorStatus}
          canAbort={investment.canAbort}
          onCancelInvestment={onCancelInvestment}
          invitationId={investment.id}
          areAllTokensUnavailable={remainingAmount === 0}
          areTokensPartiallyAvailable={remainingAmount !== 0 && fulfilledAmount > remainingAmount}
        />
      </BoxedContent>
    )
  );
};

export default BoxedOpenInvestment;
