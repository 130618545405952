import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { lighten, resolveColor } from 'ui/styles/utils/colors';
import type { IconSizes } from 'ui/styles/themes/icons';
import type { Colors } from 'ui/styles/utils/colors';

interface IconWrapperProps {
  $size: IconSizes | number;
  $circular: boolean;
  $bordered: boolean;
  $color?: Colors;
  $hoverable: boolean;
}

interface IconProps {
  $size: IconSizes | number;
  $loading: boolean;
  disabled: boolean;
}

const getSize = (theme: DefaultTheme, size: IconSizes | number) =>
  typeof size !== 'number' ? theme.icons.sizes[size] : size;

function getIconSize({ theme, $size }: ThemedStyledProps<IconProps, DefaultTheme>) {
  const size = getSize(theme, $size);

  return css<IconProps>`
    width: ${size}rem;
    height: ${size}rem;
  `;
}

function getDisplay() {
  return css`
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
  `;
}

function getRadius({ theme, $circular, $size }: ThemedStyledProps<IconWrapperProps, DefaultTheme>) {
  const size = getSize(theme, $size);

  if ($circular) {
    return css<IconWrapperProps>`
      padding: ${0.5 * size}rem;
      border-radius: 100%;
    `;
  }
}

function getColor({ theme, $color }: ThemedStyledProps<IconWrapperProps, DefaultTheme>) {
  return css<IconWrapperProps>`
    color: ${$color ? resolveColor(theme, $color, 'grayMain') : resolveColor(theme, 'grayDark')};
    fill: ${$color ? resolveColor(theme, $color, 'grayMain') : resolveColor(theme, 'grayDark')};
  `;
}

function getBorder({ theme, $color, $bordered }: ThemedStyledProps<IconWrapperProps, DefaultTheme>) {
  if ($bordered) {
    return css<IconWrapperProps>`
      border: 1px solid ${$color ? resolveColor(theme, $color, 'grayMain') : 'currentColor'};
    `;
  }
}

function getHoverable({ theme, $hoverable, $color }: ThemedStyledProps<IconWrapperProps, DefaultTheme>) {
  if (!$hoverable) return null;
  if (!$color) return 'cursor: pointer;';

  const color = resolveColor(theme, $color, 'grayMain');
  const lightened = lighten(theme, color);

  return css`
    cursor: pointer;

    &:hover {
      color: ${lightened};
      border-color: ${lightened};
      fill: ${lightened};
    }
  `;
}

function getLoader({ $loading }: ThemedStyledProps<IconProps, DefaultTheme>) {
  if (!$loading) return null;

  return css`
    animation: spin 2s infinite linear;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  `;
}

function getDisabled({ theme, disabled }: ThemedStyledProps<IconProps, DefaultTheme>) {
  if (!disabled) return null;

  return css`
    color: ${resolveColor(theme, 'grayHover')};
    fill: ${resolveColor(theme, 'grayHover')};
  `;
}

export const IconContainer = styled.span<IconWrapperProps>`
  ${getDisplay};
  ${getRadius};
  ${getColor};
  ${getBorder};
  ${getHoverable};
`;

export const Icon = styled.svg<IconProps>`
  ${getIconSize};
  ${getLoader};
  ${getDisabled};
`;
