import renderApp from 'render.default';

import { Platform, PlatformType } from 'platforms';

import applicationConfig from './core/config/local';
import { importTheme } from './ui/styles/async-loader';
import { importAssets } from './ui/assets/utils';
import { importLocales } from './ui/i18n/async-loader';

const platform = applicationConfig.platform as PlatformType as Platform;

async function main() {
  // Erro handling? If we can't load the bundles we should fall back to an error page probably
  const platformTheme = await importTheme(platform);
  const platformImages = await importAssets(platform);
  const platformLocales = await importLocales(platform);
  renderApp({
    initialPlatform: platform,
    initialTheme: platformTheme,
    initialLocales: platformLocales,
    initialImages: platformImages,
  });
}

main();
