import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Translate from 'ui/atoms/translate';
import DashboardProductDetails from './dashboard-product-details';
import { InvestmentTokenForInvestor, InvestorPortfolio, WalletBalance } from 'api/models';
import { convertProductTypeFromApi } from 'core/api/conversions';
import InvestorInvestmentsTableView from './investments-table-view';
import { useApiCall } from 'hooks/use-api-call';
import { PortfolioApi, TokensApi, WalletsApi } from 'api/apis';
import LoadingRing from 'ui/atoms/loading-ring';
import Section from 'ui/atoms/section';
import Link from 'core/routing/link';
import WalletDetails from './wallet-details';

const InvestorProductDetailsPage: FunctionComponent<{ id: string }> = ({ id }) => {
  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const [portfolio, setPortfolio] = useState<InvestorPortfolio>();
  const [token, setToken] = useState<InvestmentTokenForInvestor>();
  const [walletBalances, setWalletBalances] = useState<WalletBalance[]>();

  const portfolioApi: PortfolioApi = useMemo(() => makeAuthenticatedApi(PortfolioApi), [makeAuthenticatedApi]);

  const tokensApi: TokensApi = useMemo(() => makeAuthenticatedApi(TokensApi), [makeAuthenticatedApi]);

  const walletsApi: WalletsApi = useMemo(() => makeAuthenticatedApi(WalletsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    withApi(async () => {
      setPortfolio(await portfolioApi.portfolioRetrieve({ id }));
      setToken(await tokensApi.tokensRetrieve({ id }));
      try {
        setWalletBalances(await walletsApi.walletsBalancesList({ token: id }));
      } catch {
        // no wallets associated
        setWalletBalances(undefined);
      }
    });
  }, [withApi, portfolioApi, tokensApi]);

  // TODO(mara-cashlink): error handling

  if (!portfolio || loading || !token) return <LoadingRing />;

  const {
    lastSignedDate,
    productType,
    productName,
    totalInvestmentAmount,
    website,
    totalEarnings,
    nextProfit,
    lastProfit,
    runtime,
  } = portfolio;

  return (
    <WideContent>
      <BoxedContent title={productName} headerSize="large">
        <DashboardProductDetails
          productName={productName}
          productType={convertProductTypeFromApi(productType)}
          totalInvestmentAmount={totalInvestmentAmount}
          lastSignedDate={lastSignedDate}
          totalEarnings={totalEarnings}
          lastProfit={lastProfit}
          nextProfit={nextProfit}
          runtime={runtime}
          website={website}
        />
      </BoxedContent>
      {(walletBalances || []).map((walletBalance) => {
        return <WalletDetails walletBalance={walletBalance} token={token} />;
      })}
      <Section>
        <InvestorInvestmentsTableView
          showSignedDateColumn={true}
          token={id}
          status="finished"
          title={<Translate name="dashboardInvestorProductDetails.investments" />}
          defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
        />
      </Section>
      <Link icon="arrow-left" to="/dashboard/investorPortfolio">
        <Translate name="dashboardInvestorProductDetails.goToPortfolio" />
      </Link>
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorProductDetailsPage, AppType.INVESTOR);
