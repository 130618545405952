import React from 'react';
import Button from 'ui/atoms/button';
import { ButtonProps } from 'ui/atoms/button';
import { FunctionComponent } from 'react';
import makeLink from 'helper/make-link';
import { useDispatch } from 'store/hooks';
import { push } from 'connected-react-router';

import getElementType, { As } from 'ui/helper/get-element-type';
import { RouteDefinition } from 'core/routing/types';

interface RouterLinkProps extends As, Pick<ButtonProps, Exclude<keyof ButtonProps, 'onClick' | 'href'>> {
  route: RouteDefinition;
  params?: object;
}

const RouterLink: FunctionComponent<RouterLinkProps> = (props) => {
  const { route, params, ...restProps } = props;

  const dispatch = useDispatch();
  const href = makeLink(route, params);

  const ElementType = getElementType(RouterLink, props, Button);

  return (
    <ElementType
      {...restProps}
      href={href}
      color="marker"
      onClick={(e: any) => {
        e.preventDefault();
        dispatch(push(href));
      }}
    />
  );
};

export default RouterLink;
