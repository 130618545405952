import { investorAppRootStateSelector } from 'apps/investor/selectors';
import type { InvestorAppState } from 'store/types';

export function investorRootSelector(state: InvestorAppState) {
  return investorAppRootStateSelector(state).investor;
}

export function investorSelector(state: InvestorAppState) {
  const { data, loading, error } = investorRootSelector(state);

  return { investor: data, loading, error };
}
