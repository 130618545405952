import React, { FunctionComponent } from 'react';
import { CustomImages } from 'ui/assets/images/types';

import './icons'; // icons are taken care of by the webpack loader, no need to do anything extra here
import defaultImages from './images';

import { useAssets } from 'ui/assets/hooks';
import { AssetsProvider } from 'ui/assets/provider';
import { useLoadPlatformAssets } from 'ui/assets/async-loader';

export const Assets: FunctionComponent<{
  initialImages: CustomImages;
}> = ({ children, initialImages }) => {
  const assets = useAssets({ defaultImages, initialImages });
  useLoadPlatformAssets(assets);

  const { images, imagesLoaded } = assets;

  return (
    <AssetsProvider images={images} imagesLoaded={imagesLoaded}>
      {children}
    </AssetsProvider>
  );
};

export default Assets;
