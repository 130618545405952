import React, { FunctionComponent, useState } from 'react';
import Icon from 'ui/atoms/icon';
import Dropdown, { DropdownItem } from 'ui/atoms/dropdown';
import Translate from 'src/ui/atoms/translate';
import * as Styled from './styled';
import { UserTypeEnum } from 'src/api';
import { INVESTOR_ROUTES } from 'src/apps/investor/pages/routes.config';
import useGoTo from 'hooks/use-go-to';
import ChooseLanguageModal from './choose-language-modal';
import { HorizontalAlign } from 'ui/types/align';
import { useCurrentUserSelector } from 'core/auth/hooks';

export interface ProfileAccessProps {
  activeLocale: string;
  iconColor?: string;
  openPosition?: HorizontalAlign;
  registerOnlyFlow?: boolean;
  showInvestorOverview?: boolean;
  onLocaleChange: (locale: string) => void;
  onLogout: () => void;
}

export const ProfileAccess: FunctionComponent<ProfileAccessProps> = (props) => {
  const {
    activeLocale,
    registerOnlyFlow,
    showInvestorOverview,
    iconColor = 'white',
    openPosition = 'left',
    onLocaleChange,
    onLogout,
  } = props;
  const [isLanguagesModalOpen, setLanguagesModalOpen] = useState(false);
  const { currentUser } = useCurrentUserSelector();

  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);
  const goToSettings = useGoTo(INVESTOR_ROUTES.settings);

  return (
    <>
      <Dropdown
        id="profile-access"
        color={iconColor}
        title={<Icon name="profile" size="large" />}
        hasDynamicArrow={true}
        openPosition={openPosition}
      >
        <Styled.DropdownContainer>
          <Styled.SignedAs>
            <Translate name="profileAccess.signedAs" />
            <Styled.Email>{currentUser?.email}</Styled.Email>
          </Styled.SignedAs>
          {showInvestorOverview && (
            <DropdownItem onClick={() => goToOverview()}>
              {registerOnlyFlow ? (
                <Translate name="profileAccess.overview" />
              ) : (
                <Translate name="profileAccess.investmentOverview" />
              )}
            </DropdownItem>
          )}
          <DropdownItem onClick={() => setLanguagesModalOpen(true)}>
            <Translate name="profileAccess.language" />
            <Icon name={activeLocale as 'de' | 'en'} size="large" />
          </DropdownItem>
          {currentUser?.userType === UserTypeEnum.INVESTOR && (
            <DropdownItem onClick={() => goToSettings()}>
              <Translate name="profileAccess.settings" />
            </DropdownItem>
          )}
          <Styled.Separator />
          <DropdownItem onClick={() => onLogout()}>
            <Translate name="common.logout" />
          </DropdownItem>
        </Styled.DropdownContainer>
      </Dropdown>

      {isLanguagesModalOpen && (
        <ChooseLanguageModal
          onLocaleChange={(locale: string) => onLocaleChange(locale)}
          onModalClose={() => setLanguagesModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProfileAccess;
