import React, { FunctionComponent, useCallback, useContext } from 'react';
import { NavigationItem as NavItem } from 'core/nav/types';
import { useDispatch } from 'store/hooks';
import useGoTo from 'hooks/use-go-to';
import { useIsLoggedInSelector, useLogout } from 'core/auth/hooks';
import RouterLink from 'core/routing/router-link';
import { setUserLanguage } from 'core/auth/actions';
import { useServerConfigSelector } from 'core/config/hooks';
import NavigationBar, { NavigationItem } from 'ui/molecules/navigation';
import Translate from 'ui/atoms/translate';
import LanguageSelectorNavigationItem from 'ui/molecules/language-selector-navigation-item';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import { I18NContext } from 'ui/i18n/provider';
import MenuCollapse from 'core/nav/sidebars/navbar-menu';
import PlatformSelectorItem from '../platform-selector-item';
import ProfileAccess from 'ui/molecules/profile-access';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import * as Styled from './styled';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import Link from 'ui/atoms/link';

const StudioNavBar: FunctionComponent<{
  showInvestNowButton?: boolean;
  showInviteFriends?: boolean;
  navigationItems?: NavItem[];
  showInvestorOverview?: boolean;
  registerOnlyFlow?: boolean;
  hideLogo?: boolean;
}> = ({
  showInvestNowButton,
  showInviteFriends,
  navigationItems,
  showInvestorOverview,
  registerOnlyFlow,
  hideLogo,
}) => {
  const { changeLocale, activeLocale } = useContext(I18NContext);

  const dispatch = useDispatch();
  const goToHome = useGoTo({ path: '/' });
  const isLoggedIn = useIsLoggedInSelector();
  const logout = useLogout();
  const isPhoneTablet = useIsMedia([DEVICES.phone, DEVICES.tablet, DEVICES.lTablet]);

  const switchLocale = useCallback(
    (locale: string) => {
      changeLocale(locale, { selection: true, user: isLoggedIn });
      if (isLoggedIn) {
        // TODO(niklasb) probably not the best place to do this
        dispatch(setUserLanguage(locale));
      }
    },
    [changeLocale, isLoggedIn, dispatch],
  );

  const {
    config: { investNowUrl },
  } = useServerConfigSelector();

  const onLogout = useCallback(() => {
    logout();
    goToHome();
  }, [logout, goToHome]);

  const renderMenuContent = () => (
    <>
      <Styled.NavbarButtons>
        {showInvestNowButton && investNowUrl && (
          <Link variant="link" href={investNowUrl}>
            <Translate name="navBar.investNow" />
          </Link>
        )}
        {showInviteFriends && (
          <Link variant="secondary" href={INVESTOR_ROUTES.inviteFriends.path}>
            <Translate name="navBar.inviteFriend" />
          </Link>
        )}
      </Styled.NavbarButtons>
      {!isLoggedIn && (
        <LanguageSelectorNavigationItem
          active={activeLocale}
          iconColor="grayMain"
          onLocaleChange={switchLocale}
          openPosition={isPhoneTablet ? 'right' : 'left'}
        />
      )}
      {!isPhoneTablet && <PlatformSelectorItem textColor="grayMain" />}
      {isLoggedIn && (
        <ProfileAccess
          activeLocale={activeLocale}
          showInvestorOverview={showInvestorOverview}
          registerOnlyFlow={registerOnlyFlow}
          iconColor="grayMain"
          openPosition={isPhoneTablet ? 'right' : 'left'}
          onLocaleChange={switchLocale}
          onLogout={() => onLogout()}
        />
      )}
      {isPhoneTablet && <PlatformSelectorItem textColor="grayMain" openPosition="right" />}
      {!isLoggedIn && (
        <RouterLink as={NavigationItem} route={AUTH_ROUTES.login} className="nav-link">
          <Translate name="common.login">Login</Translate>
        </RouterLink>
      )}
    </>
  );

  return (
    <NavigationBar fluid={true} logo={{ hide: hideLogo }}>
      <MenuCollapse navigationItems={navigationItems}>
        {isPhoneTablet && <Styled.MobileMenu>{renderMenuContent()}</Styled.MobileMenu>}
        {!isPhoneTablet && <>{renderMenuContent()}</>}
      </MenuCollapse>
    </NavigationBar>
  );
};

export default StudioNavBar;
