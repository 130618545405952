import { useEffect, useRef } from 'react';

interface UseInterval {
  onInterval: () => void;
  shouldStart?: boolean;
  shouldClear: boolean;
  intervalTime?: number;
}

const useInterval = ({ onInterval, shouldStart = true, shouldClear, intervalTime = 2000 }: UseInterval) => {
  const timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (
      shouldStart &&
      // only start interval if timer has not been started yet
      !timeoutHandler.current
    ) {
      timeoutHandler.current = setInterval(onInterval, intervalTime);
    }
    return () => {
      if (timeoutHandler.current) {
        clearInterval(timeoutHandler.current);
        timeoutHandler.current = undefined;
      }
    };
  }, [shouldStart, intervalTime, onInterval]);

  useEffect(() => {
    if (shouldClear && timeoutHandler.current) {
      clearInterval(timeoutHandler.current);
      timeoutHandler.current = undefined;
    }
  }, [shouldClear]);
};

export default useInterval;
