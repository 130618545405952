import makeForm from 'ui/forms';
import type { PersonType } from 'ui/types/person';
import type {
  CreateAccountFields,
  InvestmentCreateAccountValues,
  LegalPersonFields,
  NaturalPersonFields,
} from 'libraries/wizard/components/account-setup/create-account/types';

export const toUI = (values: {
  personType?: PersonType;
  naturalPerson?: Partial<NaturalPersonFields>;
  legalPerson?: Partial<LegalPersonFields>;
  email?: string;
}): Partial<CreateAccountFields> => {
  return {
    personType: values.personType,

    email: values.email,

    naturalSalutation: values.naturalPerson?.salutation,
    naturalForename: values.naturalPerson?.forename,
    naturalSurname: values.naturalPerson?.surname,
    naturalCountry: values.naturalPerson?.country,
    naturalPhone: values.naturalPerson?.phone,
    naturalStreet: values.naturalPerson?.street,
    naturalZip: values.naturalPerson?.zip,
    naturalCity: values.naturalPerson?.city,
    naturalIban: values.naturalPerson?.iban,
    naturalBirthDate: values.naturalPerson?.birthDate,
    naturalBirthPlace: values.naturalPerson?.birthPlace,
    naturalBirthCountry: values.naturalPerson?.birthCountry,
    nationality: values.naturalPerson?.nationality,
    legalCity: values.legalPerson?.city,
    legalZip: values.legalPerson?.zip,
    legalStreet: values.legalPerson?.street,
    legalCommercialRegisterNumber: values.legalPerson?.commercialRegisterNumber,
    legalCommercialRegister: values.legalPerson?.commercialRegister,
    legalCompanyName: values.legalPerson?.companyName,
    legalCountry: values.legalPerson?.country,
    legalEntityType: values.legalPerson?.legalEntityType,
  };
};

export const fromUI = (values: CreateAccountFields): InvestmentCreateAccountValues => {
  return {
    personType: values.personType,
    acceptDocuments: {
      accountSetupDocument: values.accountSetupDocument || false,
      effectaAccountSetupDocument: values.effectaAccountSetupDocument || false,
      effectaTermsConditions: values.effectaTermsConditions || false,
      benefitingPerson: values.benefitingPerson || false,
      pep: values.pep || false,
      termsOfService: values.termsOfService || false,
    },
    accountCredentials: {
      email: values.email,
      password: values.password,
      passwordRepeat: values.passwordRepeat,
    },
    communicationSettings: {
      optinEmail: values.optinEmail,
      optinMail: values.optinMail,
      optinPhone: values.optinPhone,
    },
    legalPerson: {
      companyName: values.legalCompanyName,
      commercialRegister: values.legalCommercialRegister,
      commercialRegisterNumber: values.legalCommercialRegisterNumber,
      legalEntityType: values.legalEntityType,
      street: values.legalStreet,
      city: values.legalCity,
      zip: values.legalZip,
      country: values.legalCountry,
    },
    naturalPerson: {
      salutation: values.naturalSalutation,
      forename: values.naturalForename,
      surname: values.naturalSurname,
      country: values.naturalCountry,
      phone: values.naturalPhone,
      street: values.naturalStreet,
      zip: values.naturalZip,
      city: values.naturalCity,
      iban: values.naturalIban,
      birthDate: values.naturalBirthDate,
      birthPlace: values.naturalBirthPlace,
      birthCountry: values.naturalBirthCountry,
      nationality: values.nationality,
    },
  };
};

export const CreateAccountForm = makeForm<CreateAccountFields>();
