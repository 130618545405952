import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import * as Styled from './styled';
import Container from 'ui/atoms/container';

export interface InfoFieldProps {
  /** Title component. */
  title: ReactNode;

  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  wordBreak?: boolean;

  truncate?: boolean;
}

export const InfoField: FunctionComponent<InfoFieldProps> = ({
  title,
  children,
  className,
  wordBreak,
  truncate,
  ...restProps
}) => {
  return (
    <Container fluid={true} className={cx('info-grid-field', className)} {...restProps}>
      <Styled.InfoGridFieldRow $wordBreak={wordBreak}>
        <Styled.TruncatedHeader $truncate={truncate} size="tiny" spacing="tiny" color="grayDark" uppercase={true}>
          {title}
        </Styled.TruncatedHeader>
      </Styled.InfoGridFieldRow>
      <Styled.TruncatedRow $truncate={truncate} $wordBreak={wordBreak}>
        {children}
      </Styled.TruncatedRow>
    </Container>
  );
};

export interface InfoGridProps {
  /** Children, all of which should be InfoFields. */
  children?: ReactNode;

  /** Number of columns. */
  columns?: number;

  /** Additional classes. */
  className?: string;

  truncate?: boolean;
}

const InfoGrid: FunctionComponent<InfoGridProps> = (props) => {
  const { children, className, columns: propColumns = 1, truncate, ...restProps } = props;

  const isPhone = useIsMedia(DEVICES.phone);
  const columns = (isPhone && 1) || propColumns;

  const arrayChildren = React.Children.toArray(children).filter(React.isValidElement);

  const cellCount = arrayChildren.length;
  const rowCount = Math.ceil(cellCount / columns);

  const rows: ReactNode[][] = new Array(rowCount).fill(null).map(() => new Array(columns).fill(''));

  arrayChildren.forEach((cell, i) => {
    rows[Math.floor(i / columns)][i % columns] = cell;
  });

  return (
    <Container fluid={true} className={cx('info-grid', className)} {...restProps}>
      {rows.map((row, i) => (
        <Styled.InfoGridRow key={i}>
          {row.map((cell, j) => (
            <Styled.InfoGridCell key={j} $truncate={truncate}>
              {cell}
            </Styled.InfoGridCell>
          ))}
        </Styled.InfoGridRow>
      ))}
    </Container>
  );
};

export default InfoGrid;
