import { AdminApi, AdminVoucherDetail } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useFriendReferralDetails = (id: string) => {
  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);
  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const [voucher, setVoucher] = useState<AdminVoucherDetail | null>(null);

  const retrieveFriendVoucher = useCallback(() => {
    withApi(async () => {
      const referral = await api.adminFriendVouchersRetrieve({ id });
      setVoucher(referral);
    });
  }, [id, api, withApi]);

  useEffect(() => retrieveFriendVoucher(), [retrieveFriendVoucher]);

  return { voucher, loading };
};
