import { InvestorStepEnum } from 'api';
import { assertUnreachable } from 'ui/helper/unreachable';
import { ColorVariants } from 'ui/styles/themes/color-variants';

export enum InvestorRegistrationStatusEnum {
  AccountSetup = 'AccountSetup',
  InvestmentAmountMissing = 'InvestmentAmountMissing',
  QuestionnaireMissing = 'QuestionnaireMissing',
  BenefitingPersonsMissing = 'BenefitingPersonsMissing',
  IdentificationMissing = 'IdentificationMissing',
  IdentificationRejected = 'IdentificationRejected',
  IdentificationPending = 'IdentificationPending',
  IdentificationFailed = 'IdentificationFailed',
  WalletSetupMissing = 'WalletSetupMissing',
  WalletSetupPending = 'WalletSetupPending',
  AdditionalInformationMissing = 'AdditionalInformationMissing',
  Finished = 'Finished',
}

export const getInvestorRegistrationStatusVariant = (status: InvestorRegistrationStatusEnum): ColorVariants => {
  switch (status) {
    case InvestorRegistrationStatusEnum.Finished:
      return 'success';
    case InvestorRegistrationStatusEnum.AccountSetup:
    case InvestorRegistrationStatusEnum.InvestmentAmountMissing:
    case InvestorRegistrationStatusEnum.QuestionnaireMissing:
    case InvestorRegistrationStatusEnum.BenefitingPersonsMissing:
    case InvestorRegistrationStatusEnum.IdentificationMissing:
    case InvestorRegistrationStatusEnum.IdentificationPending:
    case InvestorRegistrationStatusEnum.AdditionalInformationMissing:
    case InvestorRegistrationStatusEnum.WalletSetupMissing:
    case InvestorRegistrationStatusEnum.WalletSetupPending:
      return 'info';
    case InvestorRegistrationStatusEnum.IdentificationRejected:
    case InvestorRegistrationStatusEnum.IdentificationFailed:
      return 'warning';
  }
};

export const getInvestorRegistrationStatus = ({ step }: { step: InvestorStepEnum }): InvestorRegistrationStatusEnum => {
  switch (step) {
    case InvestorStepEnum.ACCOUNT_SETUP:
    case InvestorStepEnum.UNCONFIRMED:
      return InvestorRegistrationStatusEnum.AccountSetup;
    case InvestorStepEnum.QUESTIONNAIRE:
      return InvestorRegistrationStatusEnum.QuestionnaireMissing;
    case InvestorStepEnum.BENEFICIARY:
      return InvestorRegistrationStatusEnum.BenefitingPersonsMissing;
    case InvestorStepEnum.IDENTIFICATION:
    case InvestorStepEnum.RESUME_IDENTIFICATION:
      return InvestorRegistrationStatusEnum.IdentificationMissing;
    case InvestorStepEnum.IDENTIFICATION_REJECTED:
      return InvestorRegistrationStatusEnum.IdentificationRejected;
    case InvestorStepEnum.IDENTIFICATION_FAILED:
      return InvestorRegistrationStatusEnum.IdentificationFailed;
    case InvestorStepEnum.WAITING_KYC:
      return InvestorRegistrationStatusEnum.IdentificationPending;
    case InvestorStepEnum.SELECT_WALLET:
    case InvestorStepEnum.SETUP_MOBILE_WALLET:
      return InvestorRegistrationStatusEnum.WalletSetupMissing;
    case InvestorStepEnum.CONFIRM_WALLET:
      return InvestorRegistrationStatusEnum.WalletSetupPending;
    case InvestorStepEnum.ADDITIONAL_INFORMATION:
      return InvestorRegistrationStatusEnum.AdditionalInformationMissing;
    case InvestorStepEnum.REGISTRATION_DONE:
      return InvestorRegistrationStatusEnum.Finished;
  }

  // make sure we covered all cases
  return assertUnreachable(step);
};
