import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LoadingRing from 'ui/atoms/loading-ring';
import { useIsLoggedInSelector } from 'core/auth/hooks';
import useGoTo from 'hooks/use-go-to';
import { reLogin } from 'core/auth/actions';

interface DashboardPageProps {}

const AppRoot: FunctionComponent<DashboardPageProps> = () => {
  const isLoggedIn = useIsLoggedInSelector();

  const dispatch = useDispatch();
  const goToDashboard = useGoTo({ path: '/dashboard' });

  useEffect(() => {
    // Redirect to latest invitation process if email is unconfirmed
    if (!isLoggedIn) {
      dispatch(reLogin());
      return;
    }

    goToDashboard();
  }, [dispatch, isLoggedIn, reLogin]);

  return <LoadingRing />;
};

export default AppRoot;
