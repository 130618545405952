import React, { useContext, FunctionComponent, ReactNode } from 'react';
import Grid, { Col } from 'ui/atoms/grid';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import WizardContext from '../wizard-context';
import * as Styled from './styled';

export interface WizardContentProps {
  /** Primary content. */
  children?: ReactNode;

  sideContent?: ReactNode;

  title?: ReactNode;
}

const WizardContent: FunctionComponent<WizardContentProps> = (props) => {
  const { children, sideContent, title } = props;

  const isLargeDesktop = useIsMedia([DEVICES.desktop, DEVICES.lDesktop]);

  const { showProcessOverview } = useContext(WizardContext);

  return (
    <>
      <Grid>
        <Col desktop={{ width: 8, offset: 1 }} tablet={{ width: 9 }} phone={12}>
          {title}
        </Col>
      </Grid>
      <Styled.WizardGrid>
        {!isLargeDesktop && !showProcessOverview && (
          <Styled.HeaderContentCol width={12}>{sideContent}</Styled.HeaderContentCol>
        )}
        <Styled.WizardContentCol
          width={12}
          desktop={showProcessOverview ? 12 : 8}
          lDesktop={showProcessOverview ? 12 : 8}
        >
          <Styled.WizardContent>{children}</Styled.WizardContent>
        </Styled.WizardContentCol>
        {isLargeDesktop && !showProcessOverview && (
          <Styled.SideContentCol desktop={4} lDesktop={4}>
            {sideContent}
          </Styled.SideContentCol>
        )}
      </Styled.WizardGrid>
    </>
  );
};

export default WizardContent;
