import styled from 'styled-components';

export const VoucherCodeContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
`;

export const VoucherCode = styled.div`
  margin-right: 4px;
`;
