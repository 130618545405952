import { useCallback, useMemo } from 'react';
import {
  QuestionnaireQuestions,
  QuestionnaireLegacy,
  QuestionnaireSegmentsAnswers,
  QuestionnaireFormFields,
  QuestionnaireAnswers,
  EducationOccupationFields,
} from './types';
import { EducationalQualification, Occupation } from 'ui/types/person';
import groupBy from 'lodash/groupBy';

export function addDisplayNamesToQuestions(questions: Array<QuestionnaireQuestions>): Array<QuestionnaireQuestions> {
  return questions.map((question) => {
    let displayName = question.id;
    switch (question.id) {
      case 'investmentobjectives_general_capital_formation':
        displayName = 'generalCapitalInformation';
        break;
      case 'investmentobjectives_speculation_startup_expansion_financing':
        displayName = 'speculationStartupOrExpansionFinancing';
        break;
      case 'investmentobjectives_general_pension_provision':
        displayName = 'pensionProvision';
        break;
      case 'investmentobjectives_diversification':
        displayName = 'assetDiversification';
        break;
      case 'investmentsustainabilityobjective':
        displayName = 'environmental';
    }
    return { ...question, displayName };
  });
}

export function useInvestmentQuestionnaireFormMemo(
  questions: Array<QuestionnaireQuestions>,
): QuestionnaireSegmentsAnswers | QuestionnaireLegacy {
  return useMemo(() => {
    const isSegmented = questions.every((question) => !!question.groupId);
    const questionsForDisplay = addDisplayNamesToQuestions(questions);

    if (isSegmented) {
      const parts = groupBy(questionsForDisplay, (question) => question.groupId);

      // education questions need to be manually specified
      parts.education = [
        {
          id: 'educationalQualification',
          groupId: 'education',
          productId: null,
          options: Object.values(EducationalQualification),
          segmentI18nKey: 'personalInformation',
        },
        {
          id: 'occupation',
          groupId: 'education',
          productId: null,
          options: Object.values(Occupation),
          segmentI18nKey: 'personalInformation',
        },
      ];

      return parts;
    }

    return questionsForDisplay;
  }, [JSON.stringify(questions)]);
}

export function useQuestionnaireFormSubmit({
  questions,
  onSubmit,
}: {
  questions: QuestionnaireQuestions[];
  onSubmit: (
    values: { answers: QuestionnaireAnswers[]; educationOccupation?: EducationOccupationFields },
    giveNoAnswers: boolean,
  ) => void;
}) {
  return useCallback(
    (values: QuestionnaireFormFields) => {
      if (values.giveNoAnswers)
        onSubmit(
          {
            answers: [],
            educationOccupation: {
              educationalQualification: '' as any,
              occupation: '' as any,
            },
          },
          true,
        );
      else {
        onSubmit(
          {
            answers: questions.map((question) => ({
              id: question.id,
              selectedOption: values[question.id] || 'no_answer',
            })),
            educationOccupation: {
              educationalQualification: values.educationalQualification,
              occupation: values.occupation,
            },
          },
          false,
        );
      }
    },
    [onSubmit, JSON.stringify(questions)],
  );
}
