import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import usePlatformImage from 'ui/hooks/use-platform-image';
import Image from 'ui/atoms/image';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Header from 'ui/atoms/header';

export interface ExternalCampaignInfoProps {
  /** Additional classes. */
  className?: string;
  externalNaturalPerson: {
    forename: string;
    surname: string;
  };
}

const ExternalCampaignInfo: FunctionComponent<ExternalCampaignInfoProps> = (props) => {
  const { className, children, externalNaturalPerson, ...restProps } = props;

  // TODO(mara-cashlink): make external campaign image configurable for campaign
  const externalCampaignTomorrow = usePlatformImage('externalCampaignTomorrow');

  return (
    <div className={cx('external-campaign-info', className)} {...restProps}>
      <Section spacing="small">
        <Header>
          <Translate
            name="externalCampaignInfo.greeting"
            args={[externalNaturalPerson.forename, externalNaturalPerson.surname]}
          />
        </Header>
        {/*TODO(mara-cashlink): make external campaign description configurable*/}
        <Translate name="externalCampaignInfo.description" />
      </Section>
      <Section>
        <Image src={externalCampaignTomorrow} alt="campaign visual" size="xlarge" bordered />
      </Section>
    </div>
  );
};

export default ExternalCampaignInfo;
