import styled, { css } from 'styled-components';

export const Form = styled.form<{ $modal?: boolean }>`
  ${({ $modal }) =>
    $modal &&
    css`
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      flex-basis: 100%;
      margin: 0;
    `};
`;
