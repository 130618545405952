import { camelize } from 'humps';

export default function importAssets(r: any) {
  return r.keys().reduce((prev: any, item: any) => {
    const key = camelize(item.replace('./', '').replace(/\.[^/.]+$/, ''));

    prev[key] = r(item);
    return prev;
  }, {});
}
