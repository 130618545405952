import React, { FunctionComponent } from 'react';
import { getIssuerInvestmentStatusVariant } from 'helper/investment-status';
import Translate from 'ui/atoms/translate';
import { camelCase } from 'change-case';
import StatusTag from 'ui/atoms/status-tag';
import Currency from 'ui/atoms/currency';
import Date from 'ui/atoms/date';
import Link from 'ui/atoms/link';
import { FrontendStatusEnum, Money } from 'api';

export const IssuerInvestmentStatusIndicator: FunctionComponent<{
  status: FrontendStatusEnum;
  investmentTotalFulfilled?: Money | null;
}> = ({ status }) => {
  return (
    <StatusTag variant={getIssuerInvestmentStatusVariant(status)} truncate={true}>
      <Translate name={`issuerInvestmentStatus.${camelCase(status)}.status`} />
    </StatusTag>
  );
};

interface IssuerInvestmentStatusDescriptionProps {
  status: FrontendStatusEnum;
  transactionUrl: string | undefined;
  investmentTotalFulfilled?: Money | null;
  signedDate?: Date | null;
}

export const IssuerInvestmentStatusDescription: FunctionComponent<IssuerInvestmentStatusDescriptionProps> = ({
  status,
  transactionUrl,
  investmentTotalFulfilled,
  signedDate,
}) => {
  return (
    <>
      {status !== FrontendStatusEnum.HANDOVER && (
        <Translate
          as="p"
          name={`issuerInvestmentStatus.${camelCase(status)}.description`}
          args={[
            investmentTotalFulfilled ? (
              <Currency>{investmentTotalFulfilled}</Currency>
            ) : (
              transactionUrl &&
              ((text, key) => (
                <Link href={transactionUrl} key={key} external>
                  {text}
                </Link>
              ))
            ),
          ]}
        />
      )}
      {signedDate && (
        <Translate
          as="p"
          name={'issuerInvestmentStatus.handover.description'}
          args={[(_, key) => <Date key={key}>{signedDate}</Date>]}
        />
      )}
    </>
  );
};
