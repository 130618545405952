import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Grid, { Col } from 'ui/atoms/grid';
import Image from 'ui/atoms/image';
import Segment from 'ui/atoms/segment';
import Header from 'ui/atoms/header';
import Spacer from 'ui/atoms/spacer';
import Container from 'ui/atoms/container';
import { usePlatformImage } from 'ui/hooks/use-platform-image';

interface PageNotFoundProps {}

const ViewPermissionMissing: FunctionComponent<PageNotFoundProps> = () => {
  const noPermissionImage = usePlatformImage('artboard');

  return (
    <Container>
      <Spacer y={20} />
      <Grid>
        <Col width={2} phone={12}>
          <Image size="large" alt="" src={noPermissionImage} />
        </Col>
        <Col width={10} phone={12}>
          <Segment basic={true}>
            <Header size="medium">
              <Translate name="noPermissionView.title" />
            </Header>
            <Translate name="noPermissionView.description" />
          </Segment>
        </Col>
      </Grid>
    </Container>
  );
};

export default ViewPermissionMissing;
