import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Popup from 'ui/atoms/popup';
import Spacer from 'ui/atoms/spacer';
import { I18NContext } from 'ui/i18n/provider';

const EnglishDisclaimer: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const { activeLocale } = useContext(I18NContext);

  useEffect(() => {
    setOpen(activeLocale === 'en');

    return () => setOpen(false);
  }, [activeLocale]);

  return (
    <Popup header={<h4>Important information</h4>} open={open} setOpen={setOpen}>
      <div>
        <small>
          The investment product offered here is subject to German law and its offer is made exclusively in the Federal
          Republic of Germany. Only the German version of the contractual documents is binding.
        </small>
        <Spacer y={2} />
        <small>The English translation is to be used for information purposes only.</small>
      </div>
    </Popup>
  );
};

export default EnglishDisclaimer;
