import { useEffect, useState } from 'react';

export interface UseClipboard {
  copied?: boolean;
  copy: (text: string) => Promise<void>;
}

type Timeout = ReturnType<typeof setTimeout>;

const useClipboard: () => UseClipboard = () => {
  const [{ copied, timeoutId }, setCopyState] = useState<{
    copied: boolean;
    timeoutId?: Timeout;
  }>({ copied: false });
  useEffect(
    () => () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    },
    [timeoutId],
  );

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      const id = setTimeout(() => {
        setCopyState({ copied: false });
      }, 3000);
      setCopyState({ copied: true, timeoutId: id });
    } catch {
      console.error('Copy failed:', text);
    }
  };

  return { copied, copy };
};

export default useClipboard;
