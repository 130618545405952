import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminBundesbankSettlementInstruction } from 'api';
import StatusTag from 'ui/atoms/status-tag';
import { PaginationProps } from 'ui/molecules/pagination';
import Currency from 'src/ui/atoms/currency';
import { useTriggerSolutionStatus } from '../hooks';

export interface SettlementsTableProps {
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  settlements?: AdminBundesbankSettlementInstruction[];
  goToDetails: (id: string) => void;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
  defaultLimit?: number;
}

const StudioSettlementsTable: FunctionComponent<SettlementsTableProps> = (props) => {
  const { ordering, onOrderBy, settlements, goToDetails, title, emptyView, loading, paginationProps } = props;

  const { getStatusName, getVariant } = useTriggerSolutionStatus();

  return (
    <DataRecordTable
      ordering={ordering}
      onOrderBy={onOrderBy}
      data={settlements}
      title={title}
      loading={loading}
      emptyView={emptyView}
      paginationProps={paginationProps}
      cells={[
        {
          title: <Translate name={`studioSettlementsTable.correlationID`} />,
          orderingFieldName: 'correlation_id',
          render: ({ correlationId }) => correlationId,
        },
        {
          title: <Translate name={`studioSettlementsTable.assetAmount`} />,
          render: ({ productAmount }) => productAmount,
        },
        {
          title: <Translate name={`studioSettlementsTable.paymentAmount`} />,
          alignContent: 'right',
          render: ({ paymentAmount }) => <Currency>{paymentAmount}</Currency>,
        },
        {
          title: <Translate name={`studioSettlementsTable.status`} />,
          orderingFieldName: 'status',
          render: ({ status }) => (
            <StatusTag variant={getVariant(status)} truncate={true}>
              {getStatusName(status)}
            </StatusTag>
          ),
        },
        {
          title: '',
          render: ({ id }) => <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />,
          alignContent: 'right',
        },
      ]}
    />
  );
};

export default StudioSettlementsTable;
