import React, { FunctionComponent } from 'react';
import Number from 'ui/atoms/number';
import { useFilterContext } from '../hooks';
import * as Styled from './styled';

export interface FiltersWrapperProps {
  addNewEntryBtn?: React.ReactNode;
}

export const FiltersWrapper: FunctionComponent<FiltersWrapperProps> = ({ addNewEntryBtn, children }) => {
  const { loading, entries, activeFilters } = useFilterContext();

  if (!loading && !entries && Object.keys(activeFilters).length === 0) {
    return null;
  }

  return (
    <Styled.DataNavigationContainer spacing="small">
      <Styled.Entries>
        <small>{entries && entries > 0 ? <Number type="entries">{entries}</Number> : null}</small>
        {addNewEntryBtn && <Styled.AddNewEntry>{addNewEntryBtn}</Styled.AddNewEntry>}
      </Styled.Entries>
      <Styled.Controls>{children}</Styled.Controls>
    </Styled.DataNavigationContainer>
  );
};

export default FiltersWrapper;
