import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import SidebarNavGroup from 'ui/molecules/sidebar-group/sidebar-nav-group';
import { push } from 'connected-react-router';

const NavLink: FunctionComponent<{
  link: string;
  active?: boolean;
}> = ({ children, link, active }) => {
  const dispatch = useDispatch();
  return (
    <SidebarNavGroup.Link onClick={() => dispatch(push(link))} active={active}>
      {children}
    </SidebarNavGroup.Link>
  );
};

export default NavLink;
