import React, { FunctionComponent } from 'react';
import IdentificationStep from 'libraries/wizard/components/identification-step';
import useInvestmentIdentification from 'subapps/investment/pages/investment/hooks/use-investment-identification';

const Identification: FunctionComponent = () => {
  const props = useInvestmentIdentification();

  return <IdentificationStep {...props} />;
};

export default Identification;
