import { Wallet } from './wallet';

export enum TransferStatus {
  // non-final
  PENDING = 'pending',
  TFA_REQUESTED = 'tfa_requested',
  TFA_CONFIRMED = 'tfa_confirmed',
  PROCESSING = 'processing',

  // final
  TFA_REJECTED = 'tfa_rejected',
  APPROVER_REJECTED = 'approver_rejected',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const FINAL_TRANSFER_STATUSES: TransferStatus[] = [
  TransferStatus.TFA_REJECTED,
  TransferStatus.APPROVER_REJECTED,
  TransferStatus.COMPLETED,
  TransferStatus.FAILED,
];

export interface UITransferStatusHistory {
  createdAt: Date;

  tfaTime?: Date;
  tfaSuccess?: boolean;
  hideTfa?: boolean;

  approverTime?: Date;
  approverSuccess?: boolean;
  hideApproval?: boolean;

  completionTime?: Date;
  completionSuccess?: boolean;
}

export interface UITransfer {
  id: string;
  time: Date;
  productName: string;
  isIssuance: boolean;
  /** Sender address */
  fromAddress: string;
  /** Recipient address */
  toAddress: string;
  /** investor Wallet associated to fromAddress */
  fromWallet?: Wallet;
  /** investor Wallet associated to toAddress */
  toWallet?: Wallet;
  /** Amount of shares */
  amount: string;
  /** Status */
  status: TransferStatus;
  history: UITransferStatusHistory;
  networkName: string;
}
