import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Table from 'ui/molecules/table';
import Translate from 'ui/atoms/translate';
import Link from 'ui/atoms/link';
import Date from 'ui/atoms/date';
import { PaginationProps } from 'ui/molecules/pagination';
import { Document } from 'src/ui/types/document';

export interface DocumentTableProps {
  /** Additional classes. */
  className?: string;

  withProductName?: boolean;

  paginationProps?: PaginationProps;

  documents?: Document[];
}

const DocumentTable: FunctionComponent<DocumentTableProps> = (props) => {
  const { className, documents, withProductName, paginationProps, ...restProps } = props;

  return (
    <Table className={cx('document-table', className)} {...restProps}>
      <Table.Head>
        <Table.Row>
          {withProductName && (
            <Table.HeadCell>
              <Translate name="documentTable.product" />
            </Table.HeadCell>
          )}
          <Table.HeadCell>
            <Translate name="documentTable.document" />
          </Table.HeadCell>
          <Table.HeadCell>
            <Translate name="documentTable.updatedAt" />
          </Table.HeadCell>
          <Table.HeadCell alignContent="center">
            <Translate name="documentTable.download" />
          </Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {(documents || []).map((document, index) => (
          <Table.Row key={index}>
            {withProductName && !!document.productName && <Table.Cell>{document.productName}</Table.Cell>}
            <Table.Cell>{document.name}</Table.Cell>
            <Table.Cell>
              <Date>{document.updatedAt}</Date>
            </Table.Cell>
            <Table.Cell alignContent="center">
              {document.file && (
                <Link
                  href={document.file}
                  target="_blank"
                  icon="download"
                  download={true}
                  iconColor="primary"
                  iconSize="large"
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default DocumentTable;
