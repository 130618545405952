import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import NarrowContent from 'core/layout/content/narrow-content';
import { Campaign, CampaignsApi, InvestmentCalculation } from 'api';
import CampaignEnterInvestmentProcess from './campaign-enter-investment-process';

export interface CampaignPageProps {
  campaignId: string;
}
const CampaignPage: FunctionComponent<CampaignPageProps> = ({ campaignId }) => {
  const { error, makeAuthenticatedApi, withApi, loading } = useApiCall(true);

  const campaignsApi: CampaignsApi = useMemo(() => makeAuthenticatedApi(CampaignsApi), [makeAuthenticatedApi]);

  const [data, setData] = useState<{
    campaign: Campaign;
    calculation: InvestmentCalculation;
  }>();

  // Retrieve campaign information
  useEffect(() => {
    withApi(async () => {
      const campaign = await campaignsApi.campaignsRetrieve({ id: campaignId });
      const calculation = await campaignsApi.campaignsCalculationsRetrieve({
        id: campaignId,
      });
      setData({ campaign: campaign, calculation });
      return true;
    });
  }, [campaignsApi, campaignId, withApi]);

  if (error && error.status === 404) {
    // soft-deletion case
    return (
      <NarrowContent>
        <BoxedContent>
          <Translate name="campaign.inactiveError" />
        </BoxedContent>
      </NarrowContent>
    );
  }

  if (loading) return <LoadingRing />;

  return (
    <CampaignEnterInvestmentProcess
      campaignId={campaignId}
      isTransferInvitation={data?.campaign.isTransferInvitation}
    />
  );
};

export default CampaignPage;
