import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvitationsApi, InvestorsApi, InvestmentsApi, WizardApi } from 'api/apis';
import WizardContext from 'src/libraries/wizard/wizard-context';
import useInvestorMe from 'src/hooks/use-investor-me';
import { AnyServerError } from 'src/hooks/use-api-error';
import { useServerConfigSelector } from 'src/core/config/hooks';
import { BenefitingPerson, Document, KycInformation, StepEnum } from 'api';
import { PersonToLegalPerson } from 'src/helper/cast';

const useInvestmentIdentification = (): {
  showLoading: boolean;
  step?: StepEnum;
  apiError?: AnyServerError;
  information?: KycInformation;
  apiLoading: boolean;
  redirectLoading: boolean;
  startIdentification: () => void;
  identificationFormDoc: Document | null;
  identificationInPerson: boolean;
  benefitingPersons?: Array<BenefitingPerson>;
} => {
  //contexts
  const { resourceId: invitationId, investmentId, isTransferInvitation } = useContext(WizardContext);

  //state
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);
  const [information, setInformation] = useState<KycInformation | undefined>();
  const [step, setStep] = useState<StepEnum>();
  const [benefitingPersons, setBenefitingPersons] = useState<Array<BenefitingPerson>>();

  //hooks
  const { error: apiError, loading: apiLoading, withApi, makeAuthenticatedApi } = useApiCall();
  const {
    config: { identificationFormDoc, identificationInPerson },
  } = useServerConfigSelector();
  const { investor } = useInvestorMe();

  //apis
  const investmentApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);
  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const wizardApi: WizardApi = useMemo(() => makeAuthenticatedApi(WizardApi), [makeAuthenticatedApi]);

  //variables
  const showLoading = !invitationId || !investor || !information || !step;

  //effects
  useEffect(() => {
    withApi(async () => {
      const wizard = await wizardApi.wizardRetrieve({ code: invitationId });
      setStep(wizard.step);
    });
  }, [wizardApi, invitationId, investor, withApi]);

  useEffect(() => {
    withApi(async () => {
      let info: KycInformation;
      if (isTransferInvitation) {
        info = await invitationsApi.invitationsIdentificationRetrieve({
          id: invitationId,
        });
      } else {
        info = await investmentApi.investmentsIdentificationRetrieve({
          id: investmentId,
        });
      }
      setInformation(info);

      if (PersonToLegalPerson(investor?.person) && investor?.id) {
        // TODO(geforcefan): was investorsBenefitingPersonsRead before, check this call
        // TODO(mara-cashlink): cutting of paginated benefiting persons list without ui around it
        const results = await investorsApi.investorsBenefitingPersonsList({
          id: investor.id,
        });
        setBenefitingPersons(results);
      }
    });
  }, [invitationsApi, investorsApi, invitationId, investor, isTransferInvitation, withApi]);

  //functions
  const startIdentification = useCallback(() => {
    if (!information || information?.url === null) return;

    withApi(async () => {
      if (step === StepEnum.IDENTIFICATION) {
        if (isTransferInvitation) {
          await invitationsApi.invitationsIdentificationStartNotificationCreate({
            id: invitationId,
          });
        } else {
          await investmentApi.investmentsIdentificationStartNotificationCreate({
            id: investmentId,
          });
        }
      }

      setRedirectLoading(true);
      document.location.href = information.url as string;
    });
  }, [invitationsApi, invitationId, step, withApi, information]);

  return useMemo(
    () => ({
      showLoading,
      apiLoading,
      redirectLoading,
      apiError,
      step,
      information,
      startIdentification,
      identificationFormDoc,
      identificationInPerson,
      benefitingPersons,
    }),
    [
      showLoading,
      apiLoading,
      redirectLoading,
      apiError,
      step,
      information,
      startIdentification,
      identificationFormDoc,
      identificationInPerson,
      benefitingPersons,
    ],
  );
};

export default useInvestmentIdentification;
