import React, { FunctionComponent } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import CopyButton from 'ui/molecules/copy-button';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { AdminBundesbankSettlementInstruction } from 'src/api';
import Header from 'src/ui/atoms/header';
import BoxedContent from 'src/ui/molecules/boxed-content';
import PlaceholderFallback from 'src/ui/atoms/placeholder-fallback';
import StatusTag from 'src/ui/atoms/status-tag';
import { useTriggerSolutionPaymentStatus } from './hooks';
import Date from 'ui/atoms/date';

export interface PaymentInstructionProps {
  settlement: AdminBundesbankSettlementInstruction;
}

const PaymentInstruction: FunctionComponent<PaymentInstructionProps> = (props) => {
  const { settlement } = props;

  const isPhone = useIsMedia(DEVICES.phone);

  const { getStatusName, getVariant } = useTriggerSolutionPaymentStatus();

  return (
    <>
      <Header size="medium">
        <Translate name="triggerSolutionsPayment.title" />
      </Header>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <InfoGrid columns={isPhone ? 1 : 3} truncate={true}>
          <InfoField title={<Translate name="triggerSolutionsPayment.instructionId" />}>
            <PlaceholderFallback>
              {settlement.paymentInstruction?.serviceId && (
                <>
                  <span>{settlement.paymentInstruction.serviceId} </span>
                  <CopyButton valueToCopy={settlement.paymentInstruction.serviceId} />
                </>
              )}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsPayment.correlationId" />}>
            <PlaceholderFallback>
              <span>{settlement.correlationId} </span>
              <CopyButton valueToCopy={settlement.correlationId} />
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsPayment.timeoutDate" />}>
            <PlaceholderFallback>
              {settlement.paymentInstruction?.htlcTimeout && (
                <Date showTime={true}>{settlement.paymentInstruction.htlcTimeout}</Date>
              )}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsPayment.htlcHash" />} wordBreak>
            <PlaceholderFallback>
              {settlement.paymentInstruction?.htlcHash && <span>{settlement.paymentInstruction.htlcHash} </span>}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="triggerSolutionsPayment.htlcSecret" />}>
            <PlaceholderFallback>
              {settlement.paymentInstruction?.htlcSecret && <span>{settlement.paymentInstruction.htlcSecret} </span>}
            </PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="common.status" />}>
            <PlaceholderFallback>
              {settlement.paymentInstruction?.status && (
                <StatusTag variant={getVariant(settlement.paymentInstruction.status)} truncate={true}>
                  {getStatusName(settlement.paymentInstruction.status)}
                </StatusTag>
              )}
            </PlaceholderFallback>
          </InfoField>
        </InfoGrid>
      </BoxedContent>
    </>
  );
};

export default PaymentInstruction;
