import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import * as Styled from './styled';

export interface SidebarNavGroupProps {
  children?: ReactNode;
  className?: string;
}

export interface LinkProps {
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
  active?: boolean;
}

export const Link: FunctionComponent<LinkProps> = (props) => {
  const { children, className, onClick, active, ...restProps } = props;

  return (
    <Styled.NavLink
      onClick={onClick}
      className={className}
      $active={active}
      role="button"
      data-qa="nav-link"
      {...restProps}
    >
      {children}
    </Styled.NavLink>
  );
};

export const SidebarNavGroup: FunctionComponent<SidebarNavGroupProps> & {
  Link: FunctionComponent<LinkProps>;
} = (props) => {
  const { className, children, ...restProps } = props;

  return (
    <Styled.SidebarNavGroup className={cx('sidebar-nav-group', className)} {...restProps}>
      {children}
    </Styled.SidebarNavGroup>
  );
};

SidebarNavGroup.Link = Link;

export default SidebarNavGroup;
