import styled from 'styled-components';
import Button from 'ui/atoms/button';

interface StyledButtonProps {
  $isHover: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  ${({ $isHover }) => ($isHover ? 'visibility: visible' : 'visibility: hidden')};
`;
