export interface ZIndicesTheme {
  base: any;
  header: any;
  modal: any;
}

export default {
  base: 0,
  header: 1000,
  modal: 5000,
} as ZIndicesTheme;
