import { reduce } from 'lodash';
import { object } from 'yup';
import applicationConfig from 'core/config/local';
import type { RouteDefinition } from 'core/routing/types';

export const makeLink = (route: RouteDefinition, params: object = {}, absolute = false): string => {
  if (!route) return '';

  const prefix = (absolute && `${window.location.protocol}//${window.location.host}`) || '';

  const currentQueryParameter = window.location.search.includes('?')
    ? window.location.search
        .substr(1)
        .split('&')
        .map((query) => {
          const [key, ...rest] = query.split('=');
          return {
            key,
            value: rest.join('='),
          };
        })
    : [];

  const newQueryParameter = currentQueryParameter
    .filter(({ key }) => applicationConfig.keepGlobalUrlQueryKeys.includes(key))
    .map(({ key, value }) => `${key}=${value}`)
    .join('&');

  const suffix = (!!newQueryParameter.length && `?${newQueryParameter}`) || '';

  const requestSchema = object().shape(route.requirements || {});
  return (
    prefix +
    reduce(requestSchema.cast(params), (path, value, key) => path.replace(`:${key}`, value), route.path) +
    suffix
  );
};

export default makeLink;
