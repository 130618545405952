import React, { FunctionComponent } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import Link from 'core/routing/link';
import FriendReferralDetailsGeneralInfo from './general-information';
import LoadingRing from 'ui/atoms/loading-ring';
import ReferralInvestmentsTable from './referral-investments';
import { useFriendReferralDetails } from './hooks';

interface FriendReferralDetailsPageProps {
  referralId: string;
}

const FriendReferralDetailsPage: FunctionComponent<FriendReferralDetailsPageProps> = ({ referralId }) => {
  const { voucher, loading } = useFriendReferralDetails(referralId);

  if (!referralId || !voucher || loading)
    return (
      <WideContent title={<Translate name="issuerFriendReferrals.title" />}>
        <LoadingRing />
      </WideContent>
    );

  return (
    <Section spacing="large">
      <WideContent title={<Translate name="dashboardIssuerVoucherDetails.title" />} titleSpacing="small">
        <Section spacing="medium">
          <Section spacing="medium">{voucher.name}</Section>
          <FriendReferralDetailsGeneralInfo
            owningInvestorName={voucher.owningInvestor.name}
            voucherCode={voucher.code}
            useTimes={voucher.alreadyUsed}
            active={voucher.active!}
          />
        </Section>
      </WideContent>

      <WideContent
        title={<Translate name="issuerFriendReferrals.connectedInvestments" />}
        ignorePageTitle
        titleSpacing="small"
      >
        <Section spacing="medium">
          <Translate name="issuerFriendReferrals.connectedInvestmentsSubtitle" />
        </Section>
        <ReferralInvestmentsTable id={referralId} />
      </WideContent>

      <WideContent>
        <Link icon="arrow-left" to="/dashboard/friendReferrals">
          <Translate as="span" name="issuerFriendReferrals.back" />
        </Link>
      </WideContent>
    </Section>
  );
};

export default WithAuthenticatedPage(FriendReferralDetailsPage, AppType.ISSUER);
