import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import { Document } from 'api';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import ServerError from 'ui/types/server-error';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import Spacer from 'ui/atoms/spacer';
import ActionButtons from 'src/ui/molecules/action-buttons';
import AlternativeIdentificationOptions from '../alternative-identification-options';

export interface DummyKycLandingPageProps {
  /** Click handler for button. */
  onClick?: () => void;

  /** Additional classes. */
  className?: string;

  /** Indicates a loading state */
  loading?: boolean;

  /** Error message */
  error?: ServerError;

  /** Is the verification process already started? */
  alreadyStarted?: boolean;

  identificationFormDoc: Document | null;
  identificationInPerson: boolean;
}

const DummyKycLandingPage: FunctionComponent<DummyKycLandingPageProps> = (props) => {
  const {
    className,
    loading,
    error,
    alreadyStarted = false,
    identificationFormDoc,
    identificationInPerson,
    onClick = () => {},
    ...restProps
  } = props;

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const errors = getRemainingError();

  return (
    <div className={cx('dummy-kyc-landing-page', className)} {...restProps}>
      <p>
        <Translate name="demo.kyc.startText" />
      </p>

      {alreadyStarted && (
        <Hint variant={'warning'}>
          <Translate name="identification.alreadyStartedHint" />
        </Hint>
      )}

      <Hint variant="warning">
        <Translate name="demo.kyc.startWarning" />
      </Hint>
      <Spacer y={1} />
      <ActionButtons>
        <AlternativeIdentificationOptions
          identificationFormDocUrl={identificationFormDoc ? identificationFormDoc.file : undefined}
          identificationInPerson={identificationInPerson}
        />
        <Button
          variant="primary"
          size="large"
          className="button"
          fluid
          onClick={onClick}
          loading={loading}
          error={errors}
        >
          <Translate name="demo.kyc.startButton" />
        </Button>
      </ActionButtons>
      <Spacer y={1} />
    </div>
  );
};

export default DummyKycLandingPage;
