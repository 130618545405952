import React, { FunctionComponent, useMemo } from 'react';
import type { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import SummarySplitSegment from './summary-split-segment';
import SummaryDefaultSegment from './summary-default-segment';

export interface SummarySegmentProps {
  questions: QuestionnaireQuestions[];
  segmentId: string;
  isSplitQuestionGroup?: boolean;
}

const SummarySegment: FunctionComponent<SummarySegmentProps> = ({ questions, segmentId, isSplitQuestionGroup }) => {
  const SegmentFormType = useMemo(() => {
    if (isSplitQuestionGroup) {
      return <SummarySplitSegment questions={questions} segmentId={segmentId} />;
    }

    return <SummaryDefaultSegment questions={questions} />;
  }, [isSplitQuestionGroup]);

  return SegmentFormType;
};

export default SummarySegment;
