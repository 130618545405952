import React, { FunctionComponent } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import useGoTo from 'hooks/use-go-to';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterInputOption, FilterPopup, FilterRange, FilterSingleOption, Search } from 'ui/molecules/filtering';
import FriendReferralsTable from './friend-referrals-table';

const FriendReferralsPageFilters: FunctionComponent = () => {
  const translate = useTranslateWithStringArgs();

  const filterLabels = {
    alreadyUsed: 'alreadyUsed',
    alreadyUsedMin: 'alreadyUsedMin',
    alreadyUsedMax: 'alreadyUsedMax',
  };

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.nameOrReferral')} name="search" />
      <FilterPopup>
        <FilterRange
          label={translate('dashboardIssuerVoucherDetails.totalUsage')}
          inputNames={[filterLabels.alreadyUsedMin, filterLabels.alreadyUsedMax]}
          filterName={filterLabels.alreadyUsed}
        >
          <FilterInputOption
            input={filterLabels.alreadyUsedMin}
            pairedInput={filterLabels.alreadyUsedMax}
            type="number"
            label={null}
            defaultField={filterLabels.alreadyUsedMin}
            name={filterLabels.alreadyUsed}
          />
          <FilterInputOption
            input={filterLabels.alreadyUsedMax}
            pairedInput={filterLabels.alreadyUsedMin}
            type="number"
            label={null}
            defaultField={filterLabels.alreadyUsedMax}
            name={filterLabels.alreadyUsed}
          />
        </FilterRange>
        <FilterSingleOption
          input="active"
          defaultField="active"
          label={translate('studioVouchersTable.status')}
          options={[
            {
              value: 'true',
              label: translate('studioVouchersTable.active'),
              name: 'true',
            },
            {
              value: 'false',
              label: translate('studioVouchersTable.deactivated'),
              name: 'false',
            },
          ]}
        />
      </FilterPopup>
    </>
  );
};

const FriendReferralsPage = () => {
  const goToFriendReferralsDetails = useGoTo(ISSUER_ROUTES.friendReferralDetails);

  return (
    <WideContent title={<Translate name="issuerFriendReferrals.title" />}>
      <Section spacing="medium">
        <Translate name="issuerFriendReferrals.subtitle" />
      </Section>
      <Section spacing="medium">
        <FriendReferralsTable
          goToDetails={(referralId: string) => goToFriendReferralsDetails({ referralId })}
          filters={<FriendReferralsPageFilters />}
          defaultOrdering={{ direction: 'desc', fieldName: 'created_at' }}
          defaultLimit={50}
        />
      </Section>
    </WideContent>
  );
};

export default WithAuthenticatedPage(FriendReferralsPage, AppType.ISSUER);
