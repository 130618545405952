import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Tag from 'ui/atoms/tag';
import { InvestmentInvitation } from 'api';

export const ProductInvitationsStatus: FunctionComponent<{
  invitation: InvestmentInvitation;
}> = ({ invitation }) => {
  const mapInvestmentInvitationStatus = (hasInvestment?: boolean) => {
    if (hasInvestment) return 'committed';
    return 'new';
  };

  return (
    <Tag color="info" truncate={true}>
      <Translate name={`investmentInvitation.status.${mapInvestmentInvitationStatus(!!invitation.investment)}`} />
    </Tag>
  );
};
