import React, { FunctionComponent, ReactNode } from 'react';
import TextAlign from 'ui/types/text-align';
import getElementType, { As } from 'ui/helper/get-element-type';
import Padding from 'ui/types/padding';
import Spacing from 'ui/types/spacing';
import Size from 'ui/types/size';
import Margin from 'ui/types/margin';
import * as Styled from './styled';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';

export interface SegmentProps extends As {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /**
   * Formats content to be aligned as part of a vertical group.
   *
   * @expandType true
   **/
  textAlign?: TextAlign;

  /**
   * A segment can have different sizes.
   *
   * @expandType true
   **/
  size?: Size;

  /**
   * A segment can increase its padding.
   *
   * @expandType true
   * */
  padded?: Padding;

  /**
   * Color of the segment.
   *
   * @expandType true
   */
  color?: Colors;

  /** A segment may be formatted to raise above the page. */
  raised?: boolean;

  /** A segment may take up only as much space as is necessary. */
  compact?: boolean;

  /** A segment can have its colors inverted for contrast. */
  inverted?: boolean;

  /** A basic segment has no special formatting. */
  basic?: boolean;

  /** No rounded corners */
  unrounded?: boolean;

  /** Spacing at the bottom. */
  spacing?: Spacing;

  /** Margin at the top and bottom. */
  margin?: Margin;

  segmentRef?: any;

  breakWords?: boolean;

  fullHeight?: boolean;

  /** Variant of the segment. */
  variant?: ColorVariants;
}

export const Segment: FunctionComponent<SegmentProps> = (props) => {
  const {
    className,
    children,
    color,
    compact,
    inverted,
    basic,
    raised,
    textAlign,
    size,
    spacing,
    padded,
    margin,
    unrounded,
    breakWords,
    segmentRef,
    fullHeight,
    variant,
  } = props;

  const ElementType = getElementType(Segment, props);

  return (
    <Styled.Segment
      as={ElementType}
      ref={segmentRef}
      $color={color}
      $compact={compact}
      $inverted={inverted}
      $basic={basic}
      $raised={raised}
      $unrounded={unrounded}
      $fontSize={size}
      $padded={padded}
      $margin={margin}
      $spacing={spacing}
      $breakWords={breakWords}
      $fullHeight={fullHeight}
      $variant={variant}
      $textAlign={textAlign}
      className={cx('segment', raised && 'raised', inverted && 'inverted', className)}
    >
      {children}
    </Styled.Segment>
  );
};

export default Segment;
