import { query } from 'ui/styles/queries';
import styled from 'styled-components';

export const SideContent = styled.div`
  ${query.largeDesktops`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: auto;
    width: 100%;
    max-width: 292px;
    margin: auto;
  `}

  ${query.sDesktop`
    width: 100%;
    max-width: 620px;
    margin: auto;
  `};

  ${query.lTablet`
    width: 100%;
    max-width: 568px;
    margin: auto;
  `};

  ${query.phoneTabletSDesktop`
    display: flex;
  `}
`;

export const ProductPaymentContainer = styled.div`
  width: 100%;

  ${query.phone`
    display: flex;
    justify-content: space-between;
    align-items: start;
  `}

  ${query.tablet`
    display: flex;
    justify-content: space-between;
    align-items: start;
  `}
`;

export const ProductDetails = styled.div`
  ${query.phoneTabletSDesktop`
    width: 100%;
  `}
`;
