import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

export interface SpacerProps {
  $inline?: boolean;
  $x?: number;
  $y?: number;
}

function getDefaultStyle({ $inline, $x, $y }: ThemedStyledProps<SpacerProps, DefaultTheme>) {
  return css<SpacerProps>`
    display: ${$inline ? 'inline' : 'flex'};
    max-width: 100%;
    margin-right: ${$x ? `${4 * $x}px` : 0};
    margin-bottom: ${$y ? `${4 * $y}px` : 0};
  `;
}

export const Spacer = styled.div<SpacerProps>`
  ${getDefaultStyle}
`;
