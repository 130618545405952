import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import InputAtom from 'ui/atoms/input';

const inputIconPaddingVertical = '0.375rem';
const inputIconPaddingSide = '0.75rem';

export interface IconProps {
  $align?: string;
  $active?: boolean;
}

function getIconPosition({ $align }: ThemedStyledProps<IconProps, DefaultTheme>) {
  return css<IconProps>`
    position: absolute;
    top: 0;
    right: ${$align === 'right' ? '0' : 'auto'};
    left: ${$align === 'left' ? '0' : 'auto'};
    bottom: 0;
  }`;
}

function getIconActive({ $active }: ThemedStyledProps<IconProps, DefaultTheme>) {
  if ($active) return null;

  return css<IconProps>`
    pointer-events: none;
  }`;
}

export const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  input:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

export const Input = styled(InputAtom)`
  display: flex;
  flex-basis: 100%;
`;

export const Icon = styled.div<IconProps>`
  &:hover {
    text-decoration: underline;
  }
  ${getIconPosition}
  ${getIconActive}
  min-width: 24px;
  display: flex;
  align-items: center;
  padding: ${inputIconPaddingVertical} ${inputIconPaddingSide};
`;
