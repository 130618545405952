import React, { FunctionComponent, ReactNode } from 'react';

export interface PlaceholderFallbackProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /**
   * Custom placeholder ReactNode to be shown if children are not valid React elements or strings
   *
   * @default <React.Fragment>-</React.Fragment>
   */
  placeholder?: ReactNode;
}

export const PlaceholderFallback: FunctionComponent<PlaceholderFallbackProps> = (props) => {
  const { children, placeholder = <>-</> } = props;

  const hasValidChildren = !!React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) || (typeof child === 'string' && child.length),
  ).length;

  return <>{hasValidChildren ? children : placeholder}</>;
};

export default PlaceholderFallback;
