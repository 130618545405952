import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Button from 'ui/atoms/button';
import ServerError from 'ui/types/server-error';
import DepotTransferInputMask, {
  DepotTransferInputMaskForm,
  DepotTransferMaskFields,
} from '../depot-transfer-input-mask';
import usePlatformImage from 'ui/hooks/use-platform-image';
import Hint from 'ui/atoms/hint';
import { WalletType } from 'ui/types/wallet';
import { Money } from 'api';
import { Balance, NetworkTypeEnum } from 'api';
import * as Styled from './styled';
import { makeBigInt } from 'ui/helper/big-number';

export enum TransferWizardStep {
  LOADING,
  DATA_INPUT,
  WAITING_FOR_CONFIRMATION,
  REJECTED, // user rejected the transaction on the TFA device
  COMPLETED, // transfer successfully submitted (it can still fail later though)
}

export interface TransferWizardModalProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  open?: boolean;

  step?: TransferWizardStep;

  balance: Balance;

  loading?: boolean;

  error?: ServerError;

  twoFactorRequestKnown?: boolean;

  walletType: WalletType;

  networkType: NetworkTypeEnum;

  /** Handler for close/cancel button */
  onCancel?: () => void;

  /** Handler for form submit */
  onSubmit?: (values: DepotTransferMaskFields) => void;

  onChange?: (values: DepotTransferMaskFields) => void;

  /** Handler for "check status" button in final step */
  onGoToTransactions?: () => void;

  backToBackMinPrice?: Money;

  tokenId: string;

  amount?: string;

  isPlatformWallet: string;

  whiteListedError?: string;
}

const TransferWizardModal: FunctionComponent<TransferWizardModalProps> = (props) => {
  const {
    className,
    open = true,
    step = TransferWizardStep.DATA_INPUT,
    balance,
    loading = false,
    error,
    walletType,
    networkType,
    onCancel = () => {},
    onSubmit = () => {},
    onChange = () => {},
    onGoToTransactions = () => {},
    twoFactorRequestKnown = false,
    backToBackMinPrice,
    tokenId,
    amount,
    isPlatformWallet,
    whiteListedError,
  } = props;

  const imgTfaDone = usePlatformImage('tfaDone');
  const imgTfaPending = usePlatformImage('tfaPending');

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const activeBalance = balance
    ? makeBigInt(0)
        .plus(makeBigInt(balance.total))
        .minus(makeBigInt(balance.pendingOutgoing))
        .minus(makeBigInt(balance.frozen))
    : makeBigInt(0);

  useEffect(() => {
    setSubmitDisabled(isPlatformWallet === 'false');
  }, [isPlatformWallet]);

  const onChangeConfirmWallet = useCallback(
    (isConfirmWalletSelected: boolean) => {
      setSubmitDisabled(!isConfirmWalletSelected);
    },
    [isPlatformWallet],
  );

  if (!open) return null;

  switch (step) {
    case TransferWizardStep.LOADING: {
      return (
        <Modal className={cx('transfer-wizard-modal', className)} onClose={onCancel}>
          <ModalHeader>
            <Translate name="portfolio.transfer.modalTitle" />
          </ModalHeader>
          <ModalContent loading />
          <ModalFooter>
            <ModalFooterButtons actionButtons={[]} />
          </ModalFooter>
        </Modal>
      );
    }
    case TransferWizardStep.DATA_INPUT: {
      return (
        <Modal className={cx('transfer-wizard-modal', className)} onClose={onCancel}>
          <DepotTransferInputMaskForm
            error={error}
            onSubmit={onSubmit}
            onChange={onChange}
            initial={{
              isPlatformWallet: 'true',
            }}
          >
            <ModalHeader>
              <Translate name="portfolio.transfer.modalTitle" />
            </ModalHeader>
            <ModalContent>
              {activeBalance?.eq(0) ? (
                <Hint variant="danger">
                  <Translate
                    name={
                      makeBigInt(balance.pendingOutgoing).eq(0)
                        ? 'portfolio.securitiesLocked'
                        : 'portfolio.pendingTransaction'
                    }
                  />
                </Hint>
              ) : (
                <DepotTransferInputMask
                  walletType={walletType}
                  networkType={networkType}
                  maxAmount={activeBalance}
                  backToBackMinPrice={backToBackMinPrice}
                  tokenId={tokenId}
                  onSubmit={onSubmit}
                  amount={amount}
                  isPlatformWallet={isPlatformWallet}
                  onChangeConfirmWallet={onChangeConfirmWallet}
                  whiteListedError={whiteListedError}
                />
              )}
            </ModalContent>
            <ModalFooter>
              <ModalFooterButtons
                actionButtons={[
                  {
                    name: 'cancel',
                    content: <Translate name="common.cancel" />,
                    size: 'large',
                    onClick: onCancel,
                  },
                  !activeBalance?.eq(0) && {
                    name: 'submit',
                    content: <Translate name="portfolio.buttonTransfer" />,
                    variant: 'primary',
                    size: 'large',
                    loading: loading,
                    disabled: isSubmitDisabled,
                    type: 'submit',
                  },
                ]}
              />
            </ModalFooter>
          </DepotTransferInputMaskForm>
        </Modal>
      );
    }
    case TransferWizardStep.WAITING_FOR_CONFIRMATION: {
      return (
        <Modal className={cx('transfer-wizard-modal', className)} disableClose>
          <ModalHeader>
            <Translate name="portfolio.transferWizard.confirmTransactionTitle" />
          </ModalHeader>
          <ModalContent>
            <>
              <p>
                <Styled.Graphic alt="" src={imgTfaPending} />
              </p>
              <h3>
                <Translate name="portfolio.transferWizard.confirmTransaction" />
              </h3>
              <p>
                <Translate name="portfolio.transferWizard.confirmTransactionDesc" />
              </p>
              {twoFactorRequestKnown && (
                <Styled.ConfirmationCancelText className="cancel-transfer">
                  <Translate name="portfolio.transferWizard.cancelTransactionText" />
                  &nbsp;
                  <Button variant="link" onClick={onCancel} inline>
                    <Translate name="portfolio.transferWizard.cancelTransactionButton" />
                  </Button>
                </Styled.ConfirmationCancelText>
              )}
            </>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons actionButtons={[]} />
          </ModalFooter>
        </Modal>
      );
    }
    case TransferWizardStep.REJECTED: {
      return (
        <Modal className={cx('transfer-wizard-modal', className)} disableClose>
          <ModalHeader>
            <Translate name="portfolio.transfer.rejectedTitle" />
          </ModalHeader>
          <ModalContent>
            <p>
              <Translate name="portfolio.transfer.rejected" />
            </p>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  variant: 'primary',
                  size: 'large',
                  onClick: onCancel,
                },
              ]}
            />
          </ModalFooter>
        </Modal>
      );
    }
    case TransferWizardStep.COMPLETED: {
      return (
        <Modal className={cx('transfer-wizard-modal', className)} disableClose>
          <ModalHeader>
            <Translate name="portfolio.transferWizard.transactionConfirmedTitle" />
          </ModalHeader>
          <ModalContent>
            <>
              <p>
                <Styled.CompletedGraphic alt="" src={imgTfaDone} />
              </p>

              <h3>
                <Translate name="portfolio.transferWizard.transactionConfirmedHeadline" />
              </h3>

              <Hint variant="warning">
                <Translate name="portfolio.transferWizard.transferPendingWarningText" />
              </Hint>
            </>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'check',
                  content: <Translate name="portfolio.transferWizard.checkStatusButtonLabel" />,
                  variant: 'primary',
                  size: 'large',
                  onClick: onGoToTransactions,
                },
              ]}
            />
          </ModalFooter>
        </Modal>
      );
    }
    default:
      throw new Error('invalid step');
  }
};

export default TransferWizardModal;
