import { useState, useEffect } from 'react';
import { QUERIES, DEVICES } from 'ui/styles/queries';

/**
 * Hook to test media queries
 * @param devices: a single or an Array of media query strings to match
 * @param opts: object of options:
 *    @opt initial value returned when window is undefined, defaults to false
 */
export default function useIsMedia(devices: DEVICES | Array<DEVICES>, opts = { initial: false }): boolean {
  const medias: Array<any> = [];
  const [matches, setMatches] = useState<boolean>(opts.initial);

  useEffect(() => {
    if (Array.isArray(devices)) {
      devices.forEach((device) => {
        medias.push(window.matchMedia(QUERIES[device]));
      });
    } else {
      medias.push(window.matchMedia(QUERIES[devices]));
    }

    const handler = () => setMatches(medias.some((media) => media.matches));
    medias.forEach((media) => media.addListener(handler));

    handler();

    return () => medias.forEach((media) => media.removeListener(handler));
  }, [JSON.stringify(devices)]);

  return matches;
}

export { DEVICES };
