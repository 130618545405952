import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import type { Icons } from 'ui/assets/icons/types';
import type { IconSizes } from 'ui/styles/themes/icons';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';
import LoadingSpinner from 'ui/atoms/loading-spinner';

export interface IconProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'color'> {
  name: Icons;
  size?: IconSizes | number;
  color?: Colors;
  onClick?: (event?: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  loading?: boolean;
  circular?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  className?: string;
}

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size = 'regular',
  color,
  loading = false,
  circular = false,
  bordered = false,
  disabled = false,
  onClick,
  className,
  ...rest
}) => {
  const icon = `icon-cl-${loading ? 'spinner' : name}`;
  let symbol: SVGSymbolElement | undefined, viewBox: string | undefined;

  try {
    symbol = document.getElementById(icon) as unknown as SVGSymbolElement;
    viewBox = symbol.getAttribute('viewBox') || undefined;
  } catch (e) {
    console.error(`Icon "${name}" has no matching sprite`);
  }

  if (!symbol) {
    return null;
  }

  return (
    <Styled.IconContainer
      $size={size}
      $bordered={bordered}
      $circular={circular}
      $color={color}
      $hoverable={!disabled && !!onClick}
      onClick={disabled ? () => {} : onClick}
      className={cx('icon', className)}
      {...rest}
    >
      {loading ? (
        <LoadingSpinner loading={loading} color="primary" />
      ) : (
        <Styled.Icon $size={size} $loading={loading} viewBox={viewBox} disabled={disabled}>
          <use href={'#' + icon} />
        </Styled.Icon>
      )}
    </Styled.IconContainer>
  );
};

export default Icon;
