import React, { FunctionComponent, ReactNode } from 'react';
import * as Styled from './styled';

export interface TooltipProps {
  content: ReactNode;
  maxWidth?: number;
}

export const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { content, maxWidth, children } = props;

  return (
    <Styled.Tooltip>
      {children}
      <Styled.Content $maxWidth={maxWidth}>{content}</Styled.Content>
    </Styled.Tooltip>
  );
};

export default Tooltip;
