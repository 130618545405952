import Button from 'ui/atoms/button';
import styled from 'styled-components';

export const CopyButton = styled(Button)`
  color: ${({ theme }) => theme.colorVariants.dark};
`;

export const PrefillGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const PrefillButton = styled.div`
  width: 3rem;
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const PrefillValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
`;
