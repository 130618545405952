import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import makeForm from 'ui/forms';
import Grid, { Col } from 'ui/atoms/grid';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';

export interface ChangeEmailAddressFields {
  email: string;
  password: string;
}

export interface ChangeEmailAddressProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  emailChangedSuccessfully?: boolean;

  oldEmail?: string;

  newEmail?: string;
}

export interface ChangeEmailAddressFields {
  email: string;
  password: string;
}

export const ChangeEmailAddressForm = makeForm<ChangeEmailAddressFields>();

const ChangeEmailAddress: FunctionComponent<ChangeEmailAddressProps> = (props) => {
  const { className, children, emailChangedSuccessfully, oldEmail, newEmail, ...restProps } = props;

  const isPhone = useIsMedia(DEVICES.phone);

  const truncateEmail = useCallback(
    (email: string | undefined, maxLength: number) => {
      if (!isPhone || !email) return email;

      if (email.length > maxLength) {
        return email.substring(0, maxLength) + '...';
      }
      return email;
    },
    [isPhone],
  );

  return (
    <div className={cx('change-email-address', className)} {...restProps}>
      <Grid>
        <Col>
          <Translate name="changeEmailAddress.oldEmail" />
        </Col>
        <Col>{oldEmail}</Col>
      </Grid>
      {/*TODO(mara-cashlink): remove br and use spacing prop of Row instead when introduced*/}
      <br />

      {emailChangedSuccessfully && (
        <Hint variant="success">
          <Translate name="changeEmailAddress.success" args={[truncateEmail(newEmail, 20)]} />
        </Hint>
      )}
      {!emailChangedSuccessfully && (
        <>
          <ChangeEmailAddressForm.Group required={true} name="email">
            <ChangeEmailAddressForm.Input />
            {ChangeEmailAddressForm.Validators.Email()}
          </ChangeEmailAddressForm.Group>
          <ChangeEmailAddressForm.Group name="password" required={true} simple={true}>
            <ChangeEmailAddressForm.PasswordInput />
          </ChangeEmailAddressForm.Group>
        </>
      )}
    </div>
  );
};

export default ChangeEmailAddress;
