import React, { FunctionComponent } from 'react';
import { PortfolioApi } from 'api/apis';
import { InvestorPortfolio } from 'api/models';
import Translate from 'ui/atoms/translate';
import { push } from 'connected-react-router';
import makeLink from 'helper/make-link';
import { useDispatch } from 'store/hooks';
import { useInvestorHasInvestmentsSelector } from 'apps/investor/resources/investments/hooks';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WithDataRecord from 'hoc/WithDataRecord';
import WideContent from 'core/layout/content/wide-content';
import DashboardPortfolioTable from './dashboard-portfolio-table';
import { convertProductTypeFromApi } from 'core/api/conversions';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import InvestorEmptyView from 'core/components/empty-views/investor';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';

const InvestorPortfolioTable: FunctionComponent<{}> = WithDataRecord<PortfolioApi, InvestorPortfolio>(PortfolioApi)(
  ({ data, ordering, onOrderBy, loading, paginationProps }) => {
    const dispatch = useDispatch();

    const { hasInvestments } = useInvestorHasInvestmentsSelector();

    const goTo = (id: string) => {
      dispatch(push(makeLink(INVESTOR_ROUTES.product, { id })));
    };

    return (
      <>
        <DashboardPortfolioTable
          onOrderBy={onOrderBy}
          ordering={ordering}
          loading={loading}
          emptyView={
            <InvestorEmptyView
              type={hasInvestments ? EmptyViewType.INVESTOR_PORTFOLIO : EmptyViewType.REGISTERED_PORTFOLIO}
            />
          }
          investorPortfolio={(data || []).map((portfolio) => ({
            ...portfolio,
            productType: convertProductTypeFromApi(portfolio.productType),
          }))}
          goToDetails={(productId: string) => goTo(productId)}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.portfolioList({
      offset,
      limit,
      ordering,
    });
  },
);

const InvestorPortfolioPage: FunctionComponent<{}> = () => {
  return (
    <WideContent title={<Translate name="dashboardInvestorPortfolio.title" />}>
      <InvestorPortfolioTable />
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorPortfolioPage, AppType.INVESTOR);
