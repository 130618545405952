import styled, { css } from 'styled-components';

function getDefaultStyle() {
  return css`
    float: right;
    margin-top: -0.5em;
  `;
}

export const HeaderPanel = styled.div`
  ${getDefaultStyle};
`;
