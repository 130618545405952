import React, { FunctionComponent, useCallback } from 'react';
import FormGroup from 'ui/molecules/form-group';
import PredefinedInputOptions from 'ui/molecules/predefined-input-options';
import { compact } from 'lodash';
import { useFilterOption } from 'ui/molecules/filtering/hooks';
import type { FilterOptionSelectionProps } from 'ui/molecules/filtering/types';

export const FilterMultiGroupOption: FunctionComponent<FilterOptionSelectionProps> = (props) => {
  const { value: selection, setValue: setSelection } = useFilterOption(props, []);

  const selectedIndices = compact(
    props.options.map(({ value }, index) => (selection.includes(value[0]) ? index + 1 : undefined)),
  ).map((index) => index - 1);

  const onOptionSelected = useCallback(
    (group: String[]) => {
      let isSelected;
      const selectedArr = Array.isArray(selection) ? selection : [selection];
      const options: String[] = [];
      group.forEach((option: String) => {
        isSelected = selectedArr.includes(option);
        options.push(option);
      });
      setSelection(
        isSelected ? selectedArr.filter((item: string) => !options.includes(item)) : [...selectedArr, ...options],
      );
    },
    [selection],
  );

  return (
    <FormGroup label={props.label}>
      <PredefinedInputOptions
        options={props.options}
        activeOptionIndices={selectedIndices}
        optionLabel={({ label }) => label}
        onOptionSelected={({ value }) => onOptionSelected(value)}
      />
    </FormGroup>
  );
};

export default FilterMultiGroupOption;
