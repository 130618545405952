import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi, TwoFactorAuthApi } from 'api/apis';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import UpdateMobileSafekeeping from './update-mobile-safekeeping';
import { StepEnum } from 'api/models';
import useInvestorMe from 'hooks/use-investor-me';
import CreateMobileSafekeepingWallet from './create-mobile-safekeeping-wallet';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const SetupMobileWalletStep: FunctionComponent<{}> = () => {
  const { changeStep } = useContext(WizardContext);
  const { investor, error: investorError } = useInvestorMe();

  const { error, makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  let safekeepingAppUpdateNeeded = false;

  // TODO: This was needed for Stellar

  // const load = useCallback(() => {
  //   withApi(async () => {
  //     if (!investor?.id) return;

  //     const inv = await investorsApi.investorsRetrieve({
  //       id: investor.id,
  //     });

  //     Wizard step: SETUPMOBILEWALLET && investment has wallet -> app needs update
  //     safekeepingAppUpdateNeeded = !!inv?.wallet;

  //     const appUpdateSuccessfull = safekeepingAppUpdateNeeded && inv.step !== StepEnum.SETUPMOBILEWALLET;

  //     const walletSetupSuccessful =
  //       !safekeepingAppUpdateNeeded &&
  //       token?.tokenizationInfo?.networkType &&
  //       inv?.wallet &&
  //       getWalletAddressForNetwork(token?.tokenizationInfo.networkType, inv.wallet);

  //     if (appUpdateSuccessfull || walletSetupSuccessful) {
  //       finalize();
  //     }
  //   });
  // }, [withApi, investor?.id, investorsApi, finalize]);

  const twoFactorAuthApi: TwoFactorAuthApi = useMemo(
    () => makeAuthenticatedApi(TwoFactorAuthApi),
    [makeAuthenticatedApi],
  );

  const sendMobileSafeKeepingSetupEmail = useCallback(() => {
    withApi(async () => {
      await twoFactorAuthApi.twoFactorAuthDeviceInitializationCreate({
        tFADeviceInitializationRequest: {
          walletNeeded: true,
        },
      });
    });
  }, [withApi, twoFactorAuthApi]);

  const resetWalletOption = useCallback(() => {
    withApi(async () => {
      if (!investor?.id) return;

      try {
        await investorsApi.investorsResetWalletSelectionCreate({
          id: investor.id,
        });
        changeStep(StepEnum.SELECT_WALLET);
      } catch (e) {
        console.error(e);
      }
    });
  }, [investor?.id, withApi]);

  // const timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

  // useEffect(() => {
  //   timeoutHandler.current = setInterval(load, 2000);
  //   return () => {
  //     if (timeoutHandler.current) {
  //       clearInterval(timeoutHandler.current);
  //     }
  //   };
  // }, [load]);

  // useEffect(() => {
  //   if (error && timeoutHandler.current) {
  //     clearInterval(timeoutHandler.current);
  //   }
  // }, [error, timeoutHandler]);

  if (!investor) return <LoadingRing />;

  const title = safekeepingAppUpdateNeeded ? (
    <Translate name="updateMobileSafekeeping.title" />
  ) : (
    <Translate name="investmentDepot.title" />
  );

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      {safekeepingAppUpdateNeeded ? (
        <UpdateMobileSafekeeping />
      ) : (
        <CreateMobileSafekeepingWallet
          email={investor.email}
          onResendEmail={sendMobileSafeKeepingSetupEmail}
          error={error || investorError}
          resetWalletOption={resetWalletOption}
        />
      )}
    </>
  );
};

export default SetupMobileWalletStep;
