import React from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import { useInvestorSelector } from 'apps/investor/resources/investor/hooks';
import { extractNaturalAndLegalFromPerson } from 'core/api/conversions';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Translate from 'ui/atoms/translate';
import BoxedChangeEmailAddress from './change-email-address';
import BoxedChangePassword from './change-password';
import BoxedChangeCommunicationSettings from './change-communication-settings';
import BoxedWallets from './wallets';
import UserDataView from './user-data-view';

const InvestorSettingsPage = () => {
  const { investor, loading } = useInvestorSelector();

  const { naturalPerson } = extractNaturalAndLegalFromPerson(investor?.person);

  if (loading || !investor) {
    return <LoadingRing />;
  }

  return (
    <WideContent title={<Translate name="dashboardInvestorSettings.title" />}>
      <UserDataView investor={investor} />
      <BoxedWallets />
      <BoxedChangePassword naturalPerson={naturalPerson || undefined} />
      <BoxedChangeEmailAddress />
      <BoxedChangeCommunicationSettings investor={investor} />
    </WideContent>
  );
};

export default WithAuthenticatedPage(InvestorSettingsPage, AppType.INVESTOR);
