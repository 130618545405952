import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { ButtonProps } from 'ui/atoms/button';
import Button from 'ui/atoms/button';
import { Spacing } from 'ui/styles/themes/spacing';
import * as Styled from './styled';
import type { ActionButtonsAlign } from './styled';

export interface ActionButton extends Omit<ButtonProps, 'children'> {
  name?: string;
  content?: ReactNode;
}

export type ActionButtonsType = Array<ActionButton | boolean>;

export interface ActionButtonsProps {
  /** Primary content. */
  children?: ReactNode;

  actionButtons?: ActionButtonsType;

  /** Additional classes. */
  className?: string;

  /** Additional classes. */
  spacingTop?: Spacing;

  /** Alignment. */
  align?: ActionButtonsAlign;

  /** Reverse. */
  reverse?: boolean;

  /** full width or not. */
  fullWidth?: boolean;

  /** Vertical align or not. */
  verticalAlign?: boolean;
}

const renderActionButtons = (actionButtons: ActionButtonsType, fullWidth?: boolean, verticalAlign?: boolean) => {
  return (actionButtons.filter(Boolean) as Array<ActionButton>).map(({ name, content, ...button }, index) =>
    content ? (
      <Styled.ActionButtonWrapper key={name || index} $fullWidth={fullWidth} $verticalAlign={verticalAlign}>
        <Button {...button} variant={button.variant || 'link'} className={button.className}>
          {content}
        </Button>
      </Styled.ActionButtonWrapper>
    ) : null,
  );
};

const mapActionChildren = (children: ReactNode, fullWidth?: boolean, verticalAlign?: boolean) => {
  return React.Children.map(children, (child, index) =>
    child ? (
      <Styled.ActionButtonWrapper key={index} $fullWidth={fullWidth} $verticalAlign={verticalAlign}>
        {child}
      </Styled.ActionButtonWrapper>
    ) : null,
  );
};

export const ActionButtons: FunctionComponent<ActionButtonsProps> = (props) => {
  const {
    children,
    actionButtons = [],
    spacingTop = 'medium',
    align = 'end',
    reverse = false,
    fullWidth,
    verticalAlign,
    className,
    ...restProps
  } = props;

  if (!actionButtons?.length && !children) {
    return null;
  }

  return (
    <Styled.ActionButtons
      $align={align}
      $spacing={spacingTop}
      $reverse={reverse}
      $fullWidth={fullWidth}
      $verticalAlign={verticalAlign}
      className={cx('action-buttons', className)}
      {...restProps}
    >
      {children
        ? mapActionChildren(children, fullWidth, verticalAlign)
        : renderActionButtons(actionButtons, fullWidth, verticalAlign)}
    </Styled.ActionButtons>
  );
};

export default ActionButtons;
