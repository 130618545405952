import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'store/hooks';
import useTranslate from 'ui/hooks/use-translate';
import { invalidateAuth } from 'core/auth/actions';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import ServerError from 'ui/types/server-error';
import { first } from 'lodash';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';

// if adding to InvestmentErrorActions here, add corresponding locales (errors.actions) as well:
type InvestmentErrorActions = 'logoutAndContinue' | 'toInvestmentOverview';

const BoxedInvestmentError: FunctionComponent<{
  error: ServerError;
  extraErrorActions?: (action: InvestmentErrorActions) => void;
}> = ({ error, extraErrorActions = () => {} }) => {
  const dispatch = useDispatch();
  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);

  const translate = useTranslate();

  const errorCode = first(error.errorCodes);

  const errorAction: InvestmentErrorActions | undefined = useMemo(() => {
    if (errorCode && ['investment_for_legal_persons_only', 'investment_for_natural_persons_only'].includes(errorCode)) {
      return 'logoutAndContinue';
    } else if (
      errorCode &&
      [
        'investment_canceled_rejection',
        'investment_canceled_abortion',
        'investment_canceled_payment_missing',
        'investment_canceled_kyc_insufficient',
        'investment_canceled_cancellation',
      ].includes(errorCode)
    ) {
      return 'toInvestmentOverview';
    } else {
      return undefined;
    }
  }, [errorCode]);

  const onAction = useCallback(
    (action: InvestmentErrorActions) => {
      if (action === 'logoutAndContinue') {
        dispatch(invalidateAuth());
      } else if (action === 'toInvestmentOverview') {
        goToOverview();
      }

      extraErrorActions(action);
    },
    [dispatch, extraErrorActions],
  );

  return (
    <NarrowContent>
      <BoxedContent
        actionButtons={
          errorAction
            ? [
                {
                  name: errorAction,
                  fluid: true,
                  variant: 'primary',
                  content: <Translate name={`errors.actions.${errorAction}`} />,
                  onClick: () => onAction(errorAction),
                },
              ]
            : []
        }
      >
        {error.getMessage(translate)}
      </BoxedContent>
    </NarrowContent>
  );
};

export default BoxedInvestmentError;
