import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  InvestmentCalculation,
  InvestmentTokenForInvestor,
  Invitation,
  Issuer,
  StepEnum,
  WizardStep,
} from 'api/models';
import { AnyServerError } from 'src/hooks/use-api-error';
import ServerError from 'src/ui/types/server-error';
import useApiCall from 'src/hooks/use-api-call';
import { InvitationsApi, WizardApi } from 'src/api';
import { InvitationToNonPreviewInvitation } from 'src/helper/cast';
import { useHistory } from 'react-router';

const useInvestmentInvitation = (
  invitationId?: string,
): {
  invitation?: Invitation;
  issuer?: Issuer;
  token?: InvestmentTokenForInvestor;
  calculation?: InvestmentCalculation;
  step?: StepEnum;
  steps?: WizardStep[];
  investmentId?: string;
  loading: boolean;
  error?: AnyServerError;
  reload: () => void;
  reset: () => void;
  setStep: (step: StepEnum | undefined) => void;
  setSteps: (steps: WizardStep[] | undefined) => void;
  inactiveError?: ServerError;
} => {
  const { withApi, makeAuthenticatedApi, error, loading, reset: resetApi } = useApiCall(true);

  const [inactiveError, setInactiveError] = useState<ServerError>();

  const [invitation, setInvitation] = useState<Invitation>();

  const [investmentId, setInvestmentId] = useState<string>();

  const [token, setToken] = useState<InvestmentTokenForInvestor>();
  const [issuer, setIssuer] = useState<Issuer>();
  const [calculation, setCalculation] = useState<InvestmentCalculation>();
  const [step, setStep] = useState<StepEnum>();
  const [steps, setSteps] = useState<WizardStep[]>();

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  const wizardApi: WizardApi = useMemo(() => makeAuthenticatedApi(WizardApi), [makeAuthenticatedApi]);

  const history = useHistory();

  // TODO(geforcefan): we need to cancel loadInvestmentInvitationData when recalling it, this
  //  is causing serious problems
  const loadInvestmentInvitationData = useCallback(() => {
    if (!invitationId) return null;
    (async () => {
      await withApi(async () => {
        const wizard = await wizardApi.wizardRetrieve({
          code: invitationId,
        });

        if (!wizard.invitation) return;

        let invitation: Invitation;
        try {
          invitation = await invitationsApi.invitationsRetrieve({
            id: wizard.invitation,
          });
        } catch (e) {
          handleInvitationErrors(e);
          setInvitation(undefined);
          return;
        }
        setInvitation(invitation);

        setCalculation(
          await invitationsApi.invitationsCalculationsRetrieve({
            id: wizard.invitation,
          }),
        );

        const nonPreviewInvitation = InvitationToNonPreviewInvitation(invitation);

        if (nonPreviewInvitation) {
          setToken(nonPreviewInvitation.token);
          setIssuer(nonPreviewInvitation.issuer);
        }

        //handle redirects to a particular step on the wizard flow
        //hack for now, to be replaced by logic where frontend handles the wizard steps
        const searchParams = new URLSearchParams(window.location.search);
        const queryStep = searchParams.get('step') as StepEnum;
        searchParams.delete('step');
        history.replace({
          search: searchParams.toString(),
        });

        setStep(queryStep || wizard.step);
        setSteps(wizard.steps);
        setInvestmentId(wizard.investment);
      });
    })();
  }, [invitationsApi, wizardApi, invitationId, withApi]);

  const handleInvitationErrors = (e: ServerError) => {
    if (e.status !== 404) {
      throw e;
    }

    // 404 soft-deletion cases
    if (
      e.hasErrorCode('invitation_inactive') ||
      e.hasErrorCode('investment_canceled_rejection') ||
      e.hasErrorCode('investment_canceled_abortion') ||
      e.hasErrorCode('investment_canceled_payment_missing') ||
      e.hasErrorCode('investment_canceled_kyc_insufficient') ||
      e.hasErrorCode('investment_canceled_cancellation')
    ) {
      setInactiveError(e);
    } else {
      setInactiveError({
        ...e,
        errorCodes: ['investment_deletion_unknown_reason'],
      });
    }
  };

  useEffect(() => {
    loadInvestmentInvitationData();
  }, [loadInvestmentInvitationData]);

  const reset = useCallback(() => {
    resetApi();
    setInvitation(undefined);
    setToken(undefined);
    setIssuer(undefined);
    setStep(undefined);
    setInvestmentId(undefined);
  }, [resetApi, setInvitation, setToken, setIssuer, setStep, setInvestmentId]);

  return useMemo(
    () => ({
      invitation,
      token,
      issuer,
      calculation,
      step,
      steps,
      investmentId,
      loading,
      error,
      reload: loadInvestmentInvitationData,
      reset,
      setStep,
      setSteps,
      inactiveError,
    }),
    [
      invitation,
      token,
      issuer,
      calculation,
      step,
      steps,
      investmentId,
      loading,
      error,
      reset,
      loadInvestmentInvitationData,
      setStep,
      setSteps,
      inactiveError,
    ],
  );
};

export default useInvestmentInvitation;
