import React, { FunctionComponent } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi, AdminInvestmentsListRequest, InvestmentAcquisitionTypeRecipientEnum, InvestmentDetails } from 'api';
import useGoTo from 'hooks/use-go-to';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';
import StudioConnectedInvestmentsTable from 'apps/issuer/pages/voucher-details/connected-investments/StudioInvestmentsTable';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';

interface ReferralInvestmentsTableProps extends AdminInvestmentsListRequest {
  id: string;
  forceDataLoad?: boolean;
  defaultOrdering?: DataRecordOrdering;
  defaultLimit?: number;
}

const ReferralInvestmentsTable: FunctionComponent<ReferralInvestmentsTableProps> = WithDataRecord<
  AdminApi,
  InvestmentDetails
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.investmentDetails);

    if (data?.length === 0)
      return (
        <Section spacing="small">
          <small>
            <Translate name="dashboardIssuerVoucherDetails.noInvestments" />
          </small>
        </Section>
      );

    return (
      <>
        <FiltersWrapper>
          <IssuerInvestmentsFilters />
        </FiltersWrapper>
        <StudioConnectedInvestmentsTable
          loading={loading}
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(productId: string, investmentId: string) => goTo({ productId, investmentId })}
          investments={(data || []).map((investment) => ({
            ...investment,
            acquisitionType: investment.acquisitionType || InvestmentAcquisitionTypeRecipientEnum.ISSUANCE,
          }))}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminVouchersInvestmentsList({
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default ReferralInvestmentsTable;
