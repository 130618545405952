import React, { FunctionComponent, useContext } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import InvestmentFinalSummary from 'subapps/investment/pages/investment/wizard-steps/final/Investment-final-summary';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import { InvitationTypeEnum } from 'ui/types/invitation-type';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const FinalStep: FunctionComponent<{}> = () => {
  const { resourceId: invitationId } = useContext(WizardContext);
  const { token, invitation } = useInvestmentInvitation(invitationId);

  const goToDashboard = useGoTo(INVESTOR_ROUTES.overview);

  // TODO(mara-cashlink): error handling

  if (!invitation || !token) {
    return <LoadingRing />;
  }

  const isTransferInvitation = invitation.invitationType === InvitationTypeEnum.TRANSFER_INVITATION;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate name={isTransferInvitation ? 'accountCreationFinal.title' : 'accountCreationFinal.title'} />
      </Header>
      <InvestmentFinalSummary isTransferInvitationView={isTransferInvitation} />
      <ActionButtons>
        <Button variant="secondary" size="large" fluid={true} onClick={() => goToDashboard()}>
          <Translate name="investmentFinalSummary.toOverview" />
        </Button>
      </ActionButtons>
    </>
  );
};

export default FinalStep;
