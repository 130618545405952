import React, { FunctionComponent, useState, useEffect, useMemo, useCallback } from 'react';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import { camelCase } from 'change-case';
import { getWalletAddressForNetwork } from 'helper/network';
import { getNetworkTypeName } from 'ui/helper/networks';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, AdminInvestor, NetworkTypeEnum, Wallet } from 'api';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import Button from 'ui/atoms/button';
import ActionButtons from 'ui/molecules/action-buttons';
import AddWalletModal from '../add-wallet-modal';
import LoadingRing from 'ui/atoms/loading-ring';
import ReceivingTransfers from 'apps/issuer/shared/receiving-transfers';
import Spacer from 'src/ui/atoms/spacer';
import Text from 'src/ui/polymorphs/text';
import * as Styled from './styled';

export interface WalletsInformationProps {
  investorId: string;
  isKycSuccess?: boolean;
}

const WalletsInformation: FunctionComponent<WalletsInformationProps> = ({ investorId, isKycSuccess }) => {
  const { withApi, makeAuthenticatedApi } = useApiCall();
  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const [investor, setInvestor] = useState<AdminInvestor | null>(null);
  const [isAddWalletModalOpen, setAddWaletModalOpen] = useState(false);

  const refreshWallets = useCallback(() => {
    setAddWaletModalOpen(false);
    retrieveInvestor();
  }, []);

  const onSavingReceiveAssets = useCallback((wallet: Wallet) => {
    wallet.receivingEnabled = true;
    refreshWallets();
  }, []);

  useEffect(() => {
    retrieveInvestor();
  }, [withApi, adminApi]);

  const retrieveInvestor = useCallback(() => {
    withApi(async () => {
      const investor = await adminApi.adminInvestorsRetrieve({
        id: investorId,
      });
      setInvestor(investor);
    });
  }, []);

  if (!investor) {
    return <LoadingRing />;
  }

  return (
    <BoxedContent
      title={<Translate name="dashboardInvestorSettings.wallets.title" />}
      releaseSpaceWhenTitleIsEmpty={true}
      key="walletsData"
    >
      {!investor.wallets?.length && isKycSuccess && <Translate name="dashboardWalletInfo.noWallets" />}
      {!investor.wallets?.length && !isKycSuccess && <Translate name="dashboardWalletInfo.noWalletsNoButton" />}
      {!!investor.wallets?.length &&
        investor.wallets.map((wallet) => {
          const ethAddress = getWalletAddressForNetwork(NetworkTypeEnum.ETHEREUM, wallet);
          const stellarAddress = getWalletAddressForNetwork(NetworkTypeEnum.STELLAR, wallet);
          return (
            <Section key={wallet.id}>
              <Styled.WalletType>
                <Translate name={`walletType.${camelCase(wallet.type)}`} />
              </Styled.WalletType>
              {ethAddress && (
                <Section spacing="small">
                  <strong>
                    <Translate
                      key={0}
                      name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.ETHEREUM)}`}
                    />
                  </strong>
                  <CopyInputGroup valueToCopy={ethAddress} disabled />
                  <ReceivingTransfers
                    investorId={investorId}
                    ethAddress={ethAddress}
                    receivingEnabled={wallet.receivingEnabled}
                    stellarAddress={stellarAddress}
                    walleId={wallet.id}
                    onSavingReceiveAssets={() => onSavingReceiveAssets(wallet)}
                  />

                  {wallet.isFrozen && (
                    <>
                      <Spacer y={1} />
                      <Text as="small" color="red">
                        <Translate name="dashboardWalletInfo.frozenInfo" />
                      </Text>
                    </>
                  )}
                </Section>
              )}
              {stellarAddress && (
                <Section spacing="medium">
                  <strong>
                    <Translate
                      key={0}
                      name={`dashboardWalletInfo.walletAddress${getNetworkTypeName(NetworkTypeEnum.STELLAR)}`}
                    />
                  </strong>
                  <CopyInputGroup valueToCopy={stellarAddress} disabled />
                  <ReceivingTransfers
                    investorId={investorId}
                    ethAddress={ethAddress}
                    receivingEnabled={wallet.receivingEnabled}
                    stellarAddress={stellarAddress}
                    walleId={wallet.id}
                    onSavingReceiveAssets={() => onSavingReceiveAssets(wallet)}
                  />

                  {wallet.isFrozen && (
                    <>
                      <Spacer y={1} />
                      <Text as="small" color="red">
                        <Translate name="dashboardWalletInfo.frozenInfo" />
                      </Text>
                    </>
                  )}
                </Section>
              )}
            </Section>
          );
        })}
      {isKycSuccess && (
        <ActionButtons>
          <Button variant="secondary" onClick={() => setAddWaletModalOpen(true)}>
            <Translate name="dashboardWalletInfo.addNewWallet" />
          </Button>
        </ActionButtons>
      )}
      {isAddWalletModalOpen && (
        <AddWalletModal
          onAddNewWallet={refreshWallets}
          onClose={() => setAddWaletModalOpen(false)}
          investorId={investor.id}
        />
      )}
    </BoxedContent>
  );
};

export default WalletsInformation;
