import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import * as Styled from './styled';

export interface ListProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Lists can be unordered or ordered. */
  ordered?: boolean;

  /**
   * The marker (e.g. a circle) indicating a list item can be displayed or not
   * @default false
   * */
  displayMarkers?: boolean;

  /**
   * List items can be truncated if they exeed their parent
   * @default false
   * */
  truncate?: boolean;
}

export const List: FunctionComponent<ListProps> = (props) => {
  const { className, children, ordered, displayMarkers, truncate, ...restProps } = props;

  const listType = ordered ? 'ol' : 'ul';

  if (ordered) {
    return (
      <Styled.OrderedList
        as={listType}
        $truncate={truncate}
        $displayMarkers={displayMarkers}
        className={cx('list', !displayMarkers && 'hide-markers', className)}
        {...restProps}
      >
        {children}
      </Styled.OrderedList>
    );
  }

  return (
    <Styled.UnorderedList
      as={listType}
      $truncate={truncate}
      $displayMarkers={displayMarkers}
      className={cx('list', !displayMarkers && 'hide-markers', className)}
      {...restProps}
    >
      {children}
    </Styled.UnorderedList>
  );
};

export default List;
