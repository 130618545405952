import React, { FunctionComponent, useMemo } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import { useResetWallet } from './hooks';
import PresentSteps from './present-steps';
import WordsConfirmation from './words-confirmation';
import ResetConfirmation from './reset-confirmation';
import { useWallets } from 'hooks/use-wallets';
import { WalletTypeEnum } from 'api';
import SuccessReset from './success-reset';
import { ButtonSizes } from 'ui/styles/themes/buttons';

export interface ResetWalletProps {
  onModalClose: () => void;
}

export enum ResetSteps {
  PRESENT_STEPS = 'PRESENT_STEPS',
  WORDS_CONFIRMATION = 'WORDS_CONFIRMATION',
  RESET_CONFIRMATION = 'RESET_CONFIRMATION',
  SUCCESS = 'SUCCESS',
}

const ResetWalletModal: FunctionComponent<ResetWalletProps> = (props) => {
  const { onModalClose } = props;

  const { wallets } = useWallets();
  const existingTanganyWallet = wallets?.some((wallet) => wallet.type === WalletTypeEnum.TANGANY) || false;

  const {
    currentStep,
    onContinue,
    onClose,
    onConfirm,
    isConfirmBtnEnabled,
    onChangeWordConfirmation,
    onChangeUnpairConfirmation,
  } = useResetWallet(existingTanganyWallet, onModalClose);

  const buttons = {
    Cancel: {
      name: 'cancel',
      content: <Translate name="common.cancel" />,
      size: 'large' as ButtonSizes,
      onClick: onClose,
    },
    Close: {
      name: 'close',
      content: <Translate name="common.close" />,
      variant: 'primary',
      size: 'large' as ButtonSizes,
      onClick: onClose,
    },
    Continue: {
      name: 'continue',
      content: <Translate name="common.continue" />,
      variant: 'primary',
      size: 'large' as ButtonSizes,
      onClick: onContinue,
    },
    Confirm: {
      name: 'confirm',
      disabled: !isConfirmBtnEnabled,
      content: <Translate name="common.confirm" />,
      size: 'large' as ButtonSizes,
      onClick: onConfirm,
      variant: 'primary',
      type: 'submit',
    },
  };

  const actionButtons = useMemo(() => {
    if (currentStep === ResetSteps.PRESENT_STEPS) {
      return [buttons.Cancel, buttons.Continue];
    } else if (currentStep === ResetSteps.SUCCESS) {
      return [buttons.Close];
    } else {
      return [buttons.Cancel, buttons.Confirm];
    }
  }, [currentStep, isConfirmBtnEnabled]);

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <Translate name="resetWallet.resetTitle" />
      </ModalHeader>
      <ModalContent>
        {currentStep === ResetSteps.PRESENT_STEPS && <PresentSteps />}
        {currentStep === ResetSteps.WORDS_CONFIRMATION && (
          <WordsConfirmation onChangeWordConfirmation={(isCorrect) => onChangeWordConfirmation(isCorrect)} />
        )}
        {currentStep === ResetSteps.RESET_CONFIRMATION && (
          <ResetConfirmation
            onChangeUnpairConfirmation={(isUnpairChecked) => onChangeUnpairConfirmation(isUnpairChecked)}
          />
        )}
        {currentStep === ResetSteps.SUCCESS && <SuccessReset />}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons actionButtons={actionButtons} />
      </ModalFooter>
    </Modal>
  );
};

export default ResetWalletModal;
