// normalized user inputs to be considered when evaluating password strength
// same normalized user inputs are used by backend password validations

export const getNormalisedPasswordStrings = (value: string) => {
  return value.toLowerCase().split(/[,\s]+/);
};

interface UserDataForPasswordEvaluation {
  email?: string;
  forename?: string;
  surname?: string;
}

export const getNormalisedUserDataForPasswordEvaluation = (userData: UserDataForPasswordEvaluation) => {
  return [
    ...(userData.email ? getNormalisedPasswordStrings(userData.email) : []),
    ...(userData.surname ? getNormalisedPasswordStrings(userData.surname) : []),
    ...(userData.forename ? getNormalisedPasswordStrings(userData.forename) : []),
  ];
};
