import { AdminApi, AdminVoucherDetail, AdminVoucherStep, InvestmentDetails } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useVoucherDetails = (voucherId: string, refreshVoucherDetails: boolean) => {
  const [voucher, setVoucher] = useState<AdminVoucherDetail>();
  const [steps, setSteps] = useState<AdminVoucherStep[]>([]);
  const [connectedInvestments, setConnectedInvestments] = useState<InvestmentDetails[] | undefined>();

  const { withApi, makeAuthenticatedApi, loading } = useApiCall();
  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const fetchVoucherDetails = useCallback(() => {
    withApi(async () => {
      const voucherDetails = await api.adminVouchersRetrieve({ id: voucherId });
      setVoucher(voucherDetails);
    });
  }, [voucherId, api]);

  const fetchSteps = useCallback(() => {
    withApi(async () => {
      const stepsResponse = await api.adminVouchersVoucherStepsList({ id: voucherId });
      setSteps(stepsResponse);
    });
  }, [voucherId, api]);

  const fetchConnectedInvestments = useCallback(() => {
    withApi(async () => {
      const connectedInvestments = await api.adminVouchersInvestmentsList({ id: voucherId });
      setConnectedInvestments(connectedInvestments.results);
    });
  }, []);

  useEffect(() => {
    fetchVoucherDetails();
    fetchSteps();
    fetchConnectedInvestments();
  }, [withApi, api, voucherId, refreshVoucherDetails]);

  const changeVoucherActivation = useCallback(() => {
    withApi(async () => {
      await api.adminVouchersActivationCreate({
        id: voucherId,
        adminVoucherRequest: { code: voucher!.code, name: voucher!.name, active: !voucher!.active },
      });
      voucher!.active = !voucher!.active;
      setVoucher(voucher);
    });
  }, [voucherId, voucher?.active, withApi, api]);

  return { voucher, steps, connectedInvestments, changeVoucherActivation, loading };
};
