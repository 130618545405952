import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  margin-bottom: 1.5rem;

  ${query.phone`
    display: block;
  `}

  b {
    text-transform: uppercase;
  }
`;

export const SpaceBetweenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${query.phone`
    display: block;
  `}
`;

export const ActivateAccountContainer = styled.div`
  display: flex;
  align-items: center;
`;
