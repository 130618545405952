import { AdminApi, AdminInvestment, DocumentsApi, Money } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Container from 'ui/atoms/container';
import Icon from 'ui/atoms/icon';
import Grid, { Col } from 'ui/atoms/grid';
import Currency from 'ui/atoms/currency';
import Date from 'ui/atoms/date';
import Input from 'ui/atoms/input';
import { toMoney, toNumber } from 'ui/helper/money';
import * as Styled from './styled';

export interface EditTaxInformationFields {
  id: string;
  taxIdentificationNumber: string;
  nonAssessmentCertificate: string;
  nonAssessmentCertificateDocId: string;
  nonAssessmentCertificateDoc: Blob | null;
}
export interface EditTaxInformationModalProps {
  onHideEditTaxInformation: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  exemptionOrderSum?: Money | null;
  investments: AdminInvestment[];
  values: EditTaxInformationFields;
}

const EditTaxInformationForm = makeModalForm<EditTaxInformationFields>();

const EditTaxInformationModal: FunctionComponent<EditTaxInformationModalProps> = (props) => {
  const {
    exemptionOrderSum,
    investments,
    onHideEditTaxInformation,
    fetchInvestorDetails,
    showSuccessModal = () => {},
    values,
  } = props;

  const [investorData, setInvestorData] = useState<EditTaxInformationFields>(values || {});
  const [investmentsData, setInvestmentsData] = useState<AdminInvestment[]>(investments);
  const [exemptionOrderTotal, setExemptionOrderTotal] = useState(0);

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    const exemptionSum = investmentsData.reduce((previousSum, investment) => {
      if (!investment.exemptionOrderAmount) return previousSum;
      return previousSum + toNumber(investment.exemptionOrderAmount);
    }, 0);
    setExemptionOrderTotal(exemptionSum);
  }, [investmentsData]);

  const editTaxInformation = (fieldsValues: StateValues<EditTaxInformationFields>) => {
    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminInvestorUpdateRequest: {
          taxInformation: {
            ...fieldsValues,
            nonAssessmentCertificate: investorData.nonAssessmentCertificate === 'true',
          },
        },
      };

      if (fieldsValues.nonAssessmentCertificateDoc) {
        const nonAssessmentCertificateDocResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.nonAssessmentCertificateDoc,
        });
        patchBody.patchedAdminInvestorUpdateRequest.taxInformation.nonAssessmentCertificateDocId =
          nonAssessmentCertificateDocResponse.id;
      }

      await investorsApi.adminInvestorsPartialUpdate(patchBody);

      await updateExemptionOrdersAmount();
      fetchInvestorDetails();
      showSuccessModal();
      onHideEditTaxInformation();
    });
  };

  const updateExemptionOrdersAmount = async () => {
    const promises: any = [];

    investmentsData.forEach((investment) => {
      if (!investment.exemptionOrderAmount) return;

      promises.push(
        investorsApi.adminInvestmentsPartialUpdate({
          id: investment.id,
          patchedAdminInvestmentDetailsRequest: {
            exemptionOrderAmount: investment.exemptionOrderAmount,
          },
        }),
      );
    });

    return Promise.all(promises);
  };

  const handleExemptionChange = (investment: AdminInvestment, value: string) => {
    investment.exemptionOrderAmount = toMoney(value);
    setInvestmentsData([...investmentsData]);
  };

  return (
    <>
      <Modal onClose={onHideEditTaxInformation}>
        <EditTaxInformationForm
          initial={{
            taxIdentificationNumber: investorData.taxIdentificationNumber,
            nonAssessmentCertificate: investorData.nonAssessmentCertificate,
            nonAssessmentCertificateDoc: null,
          }}
          onChange={(values: StateValues<EditTaxInformationFields>) => {
            setInvestorData(values);
          }}
          onSubmit={(values: StateValues<EditTaxInformationFields>) => {
            return editTaxInformation(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editTaxInformationForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editTaxInformationForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditTaxInformationForm.Group name="taxIdentificationNumber" required={true}>
              <EditTaxInformationForm.Input placeholder="-" />
            </EditTaxInformationForm.Group>

            <EditTaxInformationForm.Group name="nonAssessmentCertificate" required={true}>
              <EditTaxInformationForm.GroupToggle>
                {['true', 'false'].map((option, key) => (
                  <EditTaxInformationForm.Radio value={option} key={key}>
                    <Translate name={`commonOptions.${option}`} />
                  </EditTaxInformationForm.Radio>
                ))}
              </EditTaxInformationForm.GroupToggle>
            </EditTaxInformationForm.Group>

            {investorData.nonAssessmentCertificate === 'true' && (
              <EditTaxInformationForm.Group name="nonAssessmentCertificateDoc">
                <EditTaxInformationForm.FileUpload />
                {EditTaxInformationForm.Validators.FileSize(5000000)}
              </EditTaxInformationForm.Group>
            )}

            {investmentsData.length > 0 && (
              <Grid>
                <Col width={8}>
                  <strong>
                    <Translate
                      name={`dashboardIssuerInvestorDetails.editTaxInformationForm.fields.exemptionOrder.orders`}
                    />
                  </strong>
                </Col>
                <Col width={4}>
                  <strong>
                    <Translate
                      name={`dashboardIssuerInvestorDetails.editTaxInformationForm.fields.exemptionOrder.lastlyChanged`}
                    />
                  </strong>
                </Col>
              </Grid>
            )}

            {investmentsData.length > 0 &&
              investmentsData.map((investment, key) => {
                return (
                  <Grid key={key}>
                    <Col width={8}>
                      <strong>
                        <small>{investment.productName}</small>
                      </strong>
                      <Input
                        type="number"
                        min={0}
                        onChange={(e: any) => {
                          handleExemptionChange(investment, e.target.value);
                        }}
                        value={
                          investment.exemptionOrderAmount ? toNumber(investment.exemptionOrderAmount).toString() : '0'
                        }
                        isCurrency
                      />
                      {EditTaxInformationForm.Validators.Range(0, undefined)}
                      {EditTaxInformationForm.Validators.MaxDecimals(2)}
                    </Col>
                    <Col width={4}>
                      {investment.exemptionOrderDate && (
                        <Styled.DateContainer>
                          <Icon name="history" size="regular" color="gray" />
                          <small>
                            <Date>{investment.exemptionOrderDate}</Date>
                          </small>
                        </Styled.DateContainer>
                      )}
                    </Col>
                  </Grid>
                );
              })}

            {exemptionOrderSum && (
              <Container>
                <Grid>
                  <small>
                    <Translate
                      name={`dashboardIssuerInvestorDetails.editTaxInformationForm.fields.exemptionOrder.total`}
                    />
                    <strong>
                      <Currency>{toMoney(exemptionOrderTotal)}</Currency>
                    </strong>
                  </small>
                </Grid>
              </Container>
            )}
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.cancel" />,
                  size: 'large',
                  onClick: onHideEditTaxInformation,
                },
                {
                  name: 'update',
                  content: <Translate name="common.save" />,
                  variant: 'primary',
                  type: 'submit',
                  size: 'large',
                },
              ]}
            />
          </ModalFooter>
        </EditTaxInformationForm>
      </Modal>
    </>
  );
};

export default EditTaxInformationModal;
