import { FunctionComponent } from 'react';
import { InvestorStepEnum } from 'api/models';
import AccountSetupStep from 'subapps/investment/pages/register/steps/account-setup';
import UserConfirmationStep from 'subapps/investment/pages/register/steps/user-confirmation';
import BeneficiaryStep from 'subapps/investment/pages/register/steps/beneficiary';
import QuestionnaireStep from 'subapps/investment/pages/register/steps/questionnaire';
import IdentificationStep from 'subapps/investment/pages/register/steps/identification-step';
import IdentificationStepDone from 'libraries/wizard/components/identification-step-done';
import RegistrationDone from 'subapps/investment/pages/register/steps/registration-done';
import WaitingKyc from './waiting-kyc';
import AdditionalInformationStep from './additional-information';
import SelectWalletStep from './wallet/select-wallet';
import WalletStepDone from './wallet/wallet-step-done';
import SetupMobileWalletStep from './wallet/setup-mobile-wallet';
import ConfirmWalletStep from './wallet/confirm';

const stepMapping = (
  step?: InvestorStepEnum,
): {
  Component: FunctionComponent<any>;
  DoneComponent?: FunctionComponent<any>;
} | null => {
  switch (step) {
    case InvestorStepEnum.ACCOUNT_SETUP:
      return {
        Component: AccountSetupStep,
      };
    case InvestorStepEnum.UNCONFIRMED:
      return {
        Component: UserConfirmationStep,
      };
    case InvestorStepEnum.QUESTIONNAIRE:
      return {
        Component: QuestionnaireStep,
      };
    case InvestorStepEnum.BENEFICIARY:
      return {
        Component: BeneficiaryStep,
      };
    case InvestorStepEnum.IDENTIFICATION:
    case InvestorStepEnum.RESUME_IDENTIFICATION:
    case InvestorStepEnum.IDENTIFICATION_FAILED:
    case InvestorStepEnum.IDENTIFICATION_REJECTED:
      return {
        Component: IdentificationStep,
        DoneComponent: IdentificationStepDone,
      };
    case InvestorStepEnum.WAITING_KYC:
      return {
        Component: WaitingKyc,
      };
    case InvestorStepEnum.SELECT_WALLET:
      return {
        Component: SelectWalletStep,
        DoneComponent: WalletStepDone,
      };
    case InvestorStepEnum.SETUP_MOBILE_WALLET:
      return {
        Component: SetupMobileWalletStep,
      };
    case InvestorStepEnum.CONFIRM_WALLET:
      return {
        Component: ConfirmWalletStep,
      };
    case InvestorStepEnum.ADDITIONAL_INFORMATION:
      return {
        Component: AdditionalInformationStep,
      };
    case InvestorStepEnum.REGISTRATION_DONE:
      return {
        Component: RegistrationDone,
      };
    default:
      return null;
  }
};

export default stepMapping;
