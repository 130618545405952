import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const DropdownContainer = styled.div`
  min-width: 224px;
`;

export const SignedAs = styled.div`
  pointer: none;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.black};
  user-select: none;

  ${query.phone`
    font-size: 11px;
  `};
`;

export const Email = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayDark};

  ${query.phone`
    font-size: 12px;
  `};
`;

export const Separator = styled.hr`
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
`;
