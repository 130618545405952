import styled from 'styled-components';
import { ModalContent, ModalHeader } from 'ui/molecules/modal';

export const SideOverlayHeader = styled(ModalHeader)`
  background-color: ${({ theme }) => theme.sidebar.color};
  padding: 1.5rem;
  justify-content: space-between;
`;

export const SideOverlayContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 0;
  color: white;
  background-color: ${({ theme }) => theme.sidebar.color};
`;

export const SideOverlayContainer = styled.div`
  margin-top: 1rem;
  padding: 0.3rem 0.3rem 0.3rem 1rem;
`;

export const NavWrapper = styled.div`
  height: 100%;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
`;
