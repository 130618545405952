import React, { FunctionComponent, useEffect, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Select from 'ui/atoms/select';
import FormGroup from 'ui/molecules/form-group';
import { toNumber } from 'ui/helper/money';
import * as Styled from './styled';
import Box from 'ui/polymorphs/box';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';

export interface PaginationProps {
  /** Additional classes. */
  className?: string;

  availableLimits?: number[];

  offset: number;
  limit: number;
  count: number;

  onLimitChanged: (limit: number) => void;
  onOffsetChanged: (offset: number) => void;
  limits?: number[];
}

const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const {
    className,
    limit = 10,
    offset = 0,
    count = 0,
    onLimitChanged,
    onOffsetChanged,
    availableLimits = [10, 50, 100],
    ...restProps
  } = props;

  const currentPage = Math.ceil((offset + 1) / limit);
  const [inputPage, setInputPage] = useState<string>(currentPage.toString());

  const numberOfPages = Math.ceil(count / limit);

  useEffect(() => {
    if (currentPage) setInputPage(currentPage.toString());
  }, [currentPage]);

  return (
    <Styled.Pagination className={cx(className)} {...restProps}>
      <Styled.PaginationContainer>
        <Styled.LimitContainer>
          <span>
            <Translate name="pagination.entriesPerPage" />
          </span>
          <Spacer x={2} />
          <Styled.LimitSelectionContainer>
            <FormGroup spacing="none">
              <Select
                value={limit}
                onChange={(val: any) => {
                  onLimitChanged(val);
                }}
                size="small"
                options={availableLimits.map((option) => ({
                  value: option,
                  label: option,
                }))}
                isSearchable={false}
                portalTarget={document.body}
                customComponents={{
                  InputContainer: Styled.InputContainer,
                  ValueContainer: Styled.ValueContainer,
                }}
              ></Select>
            </FormGroup>
          </Styled.LimitSelectionContainer>
        </Styled.LimitContainer>
        <Styled.PageSelectionContainer className="page-selection-container">
          <form
            noValidate
            onSubmit={(e) => {
              onOffsetChanged((toNumber(inputPage || '0') - 1) * limit);
              e.preventDefault();
            }}
          >
            <Box className={className} wrap={false}>
              <Icon
                name="angle-left"
                color="grayDark"
                onClick={() => onOffsetChanged(offset - limit)}
                disabled={offset + 1 - limit <= 0}
              />
              <Spacer x={1} />
              <Translate name="pagination.amountPages" args={[inputPage.toString(), numberOfPages]} />
              <Icon
                name="angle-right"
                color="grayDark"
                onClick={() => onOffsetChanged(offset + limit)}
                disabled={offset + limit >= count}
              />
            </Box>
          </form>
        </Styled.PageSelectionContainer>
      </Styled.PaginationContainer>
    </Styled.Pagination>
  );
};

export default Pagination;
