import { resolveColor } from 'src/ui/styles/utils/colors';
import styled from 'styled-components';
import Input from 'ui/atoms/input';

export const DateInputWrapper = styled.fieldset`
  display: flex;
  justify-content: flex-start;
`;

export const ShadowInput = styled.input`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const DateInput = styled(Input)<{ $flex: number }>`
  flex-grow: ${({ $flex }) => $flex};
  flex-shrink: 2;

  input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const Time = styled.input`
  border: ${({ theme }) => `1px solid ${resolveColor(theme, 'grayLight')}`};
  border-radius: 0.25rem;
  color: #495057;

  &:focus,
  &:active,
  &:focus-visible {
    border-color: #b3ddf3;
    box-shadow: 0 0 0 0.2rem rgb(69 172 226 / 25%);
    outline: 0;
  }
`;
