import type { Config } from 'api';

export enum ConfigActions {
  CONFIG_FETCH = 'CONFIG/FETCH',
  CONFIG_FETCH_SUCCESS = 'CONFIG/FETCH_SUCCESS',
  CONFIG_FETCH_FAILURE = 'CONFIG/FETCH_FAILURE',
}

export type ConfigFetch = { type: ConfigActions.CONFIG_FETCH };
export type ConfigFetchSuccess = {
  type: ConfigActions.CONFIG_FETCH_SUCCESS;
  data: Config;
};
export type ConfigFetchFailure = {
  type: ConfigActions.CONFIG_FETCH_FAILURE;
  error: Error;
};

export type ConfigActionTypes = ConfigFetch | ConfigFetchSuccess | ConfigFetchFailure;

export function configFetch(): ConfigFetch {
  return {
    type: ConfigActions.CONFIG_FETCH,
  };
}

export function configFetchSuccess(data: Config): ConfigFetchSuccess {
  return {
    type: ConfigActions.CONFIG_FETCH_SUCCESS,
    data,
  };
}

export function configFetchFailure(error: Error): ConfigFetchFailure {
  return {
    type: ConfigActions.CONFIG_FETCH_FAILURE,
    error,
  };
}
