import React, { FunctionComponent, ReactNode } from 'react';

import { Languages, Locales } from 'ui/i18n/types';
import { useI18n, useTranslateDefinitions } from 'ui/i18n/hooks';
import { I18NProvider } from 'ui/i18n/provider';
import { useLoadPlatformI18n } from 'ui/i18n/async-loader';

/*import * as Sentry from "@sentry/browser";*/

// todo: preload only deutsch language, have english be async loaded... maybe?

export const I18N: FunctionComponent<{
  defaultLocale?: Languages;
  children: ReactNode;
  initialLocales: Locales;
  overwriteLocale?: Languages;
}> = ({ defaultLocale = 'de', initialLocales = { en: {}, de: {} }, children, overwriteLocale }) => {
  const i18n = useI18n({ initialLocales, defaultLocale, overwriteLocale });
  const { translate, translateWithStringArgs } = useTranslateDefinitions(i18n);

  useLoadPlatformI18n(i18n);

  const { selectedLocale, userLocale, activeLocale, changeLocale, isMessagesLoaded, addLocaleMessages } = i18n;

  return (
    <I18NProvider
      selectedLocale={selectedLocale}
      userLocale={userLocale}
      activeLocale={activeLocale}
      translate={translate}
      translateWithStringArgs={translateWithStringArgs}
      changeLocale={changeLocale}
      isMessagesLoaded={isMessagesLoaded}
      addLocaleMessages={addLocaleMessages}
    >
      {children}
    </I18NProvider>
  );
};

export default I18N;
