import React, { ComponentType, FunctionComponent } from 'react';
import ConditionalPage, { ConditionFn } from './conditional-page';
import { AppType } from 'core/auth/types';

const WithConditionalPage =
  <P extends object>(Component: ComponentType<P>, condition: ConditionFn, appType?: AppType): FunctionComponent<any> =>
  (props) => {
    return (
      <ConditionalPage appType={appType} condition={condition}>
        <Component {...(props as P)} />
      </ConditionalPage>
    );
  };

export default WithConditionalPage;
