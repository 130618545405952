import React, { ComponentType, FunctionComponent, useEffect } from 'react';
import { useStore } from 'store/hooks';
import { useSelector } from 'react-redux';
import { LoopReducer } from 'redux-loop';
import { RootAppState } from 'store/types';

const withApp =
  <S extends RootAppState = RootAppState>(name: keyof S, reducer: LoopReducer<any>) =>
  <P extends object>(Component: ComponentType<P>) => {
    const App: FunctionComponent<P> = (props) => {
      const store = useStore();

      useEffect(() => {
        store.injectReducer(name, reducer);
      }, [name, reducer]);

      // todo, use module pattern, dispatch an action that sets a loading flag true to the module, this can cause too many rerenders
      const appStore = useSelector((state: S) => state[name]);

      if (!appStore) return null;

      return <Component {...props} />;
    };

    return App;
  };

export default withApp;
