import React, { FunctionComponent, ReactNode } from 'react';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import Padding from 'ui/types/padding';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';
export interface StickyMessageProps {
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /**
   * A sticky message may be one of a variety of visual variants.
   *
   * @expandType true
   */
  variant?: ColorVariants;

  /**
   * Color of the sticky message.
   *
   * @expandType true
   */
  color?: Colors;

  /**
   * A sticky message can increase its padding.
   *
   * @expandType true
   * */
  padded?: Padding;

  /**
   * A sticky message can be absolute positioned
   */
  absolute?: boolean;
}

export const StickyMessage: FunctionComponent<StickyMessageProps> = (props) => {
  const { className, padded, color, variant, absolute, children, ...restProps } = props;

  return (
    <Styled.StickyMessage
      $padded={padded}
      $absolute={absolute}
      $color={color}
      $variant={variant}
      className={cx('sticky-message', className)}
      {...restProps}
    >
      {children}
    </Styled.StickyMessage>
  );
};

export default StickyMessage;
