import React from 'react';

import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import DocumentTable from 'src/libraries/document-table';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import DashboardInvestmentDetails from 'src/apps/investor/shared/dashboard-investment-details';
import LoadingRing from 'ui/atoms/loading-ring';
import { AppType } from 'core/auth/types';
import useInvestment from 'hooks/use-investment';
import Section from 'ui/atoms/section';
import InvestorInvestmentActionButtons from 'src/apps/investor/shared/investment-action-buttons';
import { getWalletAddressForNetwork } from 'helper/network';
import BoxedInvestmentError from 'components/Investment/BoxedInvestmentError';
import Date from 'ui/atoms/date';
import Link from 'core/routing/link';

const InvestorInvestmentDetailsPage = (props: { id: string }) => {
  const { id } = props;
  const { investment, investorStatus, token, loading, error } = useInvestment(id);

  const website = token?.website;

  if (error) return <BoxedInvestmentError error={error} />;

  if (loading || !investment || !investorStatus || !token) {
    return <LoadingRing />;
  }

  return (
    <>
      <WideContent
        title={
          <Translate
            name={'dashboardInvestorInvestmentDetails.title'}
            args={[token?.name, (_, key) => <Date>{investment.signedDate}</Date>]}
          />
        }
      >
        <Section spacing="large">
          <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
            <DashboardInvestmentDetails
              investment={investment}
              website={website}
              investorStatus={investorStatus}
              walletAddress={
                investment.wallet &&
                token.tokenizationInfo &&
                getWalletAddressForNetwork(token.tokenizationInfo.networkType, investment.wallet)
              }
            />
            <InvestorInvestmentActionButtons status={investorStatus} invitationId={investment.id} />
          </BoxedContent>
        </Section>
        {investment && investment.investmentDocuments.length && (
          <Section>
            <Header size="small">
              <Translate name="dashboardInvestorInvestmentDetails.newsAndDocuments" />
            </Header>
            <DocumentTable documents={investment?.investmentDocuments} />
          </Section>
        )}
        <Link to={`/dashboard/investorProduct/${token.id}`} icon="arrow-left">
          <Translate as="span" name="dashboardInvestorInvestmentDetails.backToProductDetails" />
        </Link>
      </WideContent>
    </>
  );
};

export default WithAuthenticatedPage(InvestorInvestmentDetailsPage, AppType.INVESTOR);
