import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { LogoVariant } from '.';
import { query } from 'ui/styles/queries';

export interface LogoProps {
  $variant: LogoVariant;
  $borderRight?: boolean;
  $spacing?: Spacing;
  $hide?: boolean;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<LogoProps, DefaultTheme>) {
  if (!$spacing) return null;

  const spacing = 0.625 * theme.spacing[$spacing];

  return css<LogoProps>`
    padding-right: ${spacing}rem;
  `;
}

function getBorder({ $borderRight }: ThemedStyledProps<LogoProps, DefaultTheme>) {
  if (!$borderRight) return null;

  return css<LogoProps>`
    border-right: 1px solid white;
  `;
}

function getVariant({ theme, $variant }: ThemedStyledProps<LogoProps, DefaultTheme>) {
  if (!$variant) return null;

  return css<LogoProps>`
    ${query.phoneTablet`
      width: ${theme.logo.width.phone[$variant]};
    `};
    ${query.desktops`
      width: ${theme.logo.width.desktop[$variant]};
    `};
  `;
}

function getDisplay({ $hide }: ThemedStyledProps<LogoProps, DefaultTheme>) {
  if ($hide) {
    return css`
      display: none;
    `;
  }

  return css`
    display: initial;
  `;
}

export const Logo = styled.img<LogoProps>`
  ${getSpacing};
  ${getBorder};
  ${getVariant};
  ${getDisplay};
`;
