import React, { FunctionComponent, ReactNode, useRef, useState } from 'react';
import Size from 'ui/types/size';
import cx from 'ui/helper/prefixed-class-names';
import Icon from '../icon';
import Spacer from '../spacer';
import Select, {
  components,
  MenuListProps,
  ControlProps,
  ValueContainerProps,
  OptionProps,
  IndicatorSeparatorProps,
  DropdownIndicatorProps,
} from 'react-select';
import * as Styled from './styled';

export interface SelectProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Select is valid. */
  valid?: boolean;

  /** Select is required. */
  required?: boolean;

  /** Select is disabled. */
  disabled?: boolean;

  size?: Size;

  onChange?: (value: string) => void;

  onBlur?: React.FormEventHandler<any>;

  onFocus?: React.FormEventHandler<any>;

  isSearchable?: boolean;

  value?: any;

  hasLabelOutside?: boolean;

  options: Array<any>;

  label?: string | ReactNode;

  name?: string;

  dark?: boolean;

  portalTarget?: HTMLElement | null;

  radius?: {
    control?: string;
    menu?: string;
  };

  styles?: {
    control?: any;
    placeholder?: any;
    input?: any;
    menu?: any;
    singleValue?: any;
    valueContainer?: any;
  };

  customComponents?: {
    DropdownIndicator?: any;
    Control?: any;
    ValueContainer?: any;
    InputContainer?: any;
    MenuList?: any;
    Option?: any;
    IndicatorSeparator?: any;
  };
}

export const SelectAtom: FunctionComponent<SelectProps> = (props) => {
  const {
    className,
    options,
    valid,
    label,
    hasLabelOutside = false,
    isSearchable = true,
    portalTarget = null,
    value,
    name,
    onChange,
    styles,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef<any>(null);

  if (!options) return null;

  const DropdownIndicator = (props: DropdownIndicatorProps & { selectProps: SelectProps }) => {
    if (props.selectProps.customComponents?.DropdownIndicator) {
      return props.selectProps.customComponents.DropdownIndicator;
    }

    const iconColor = props.selectProps.dark ? 'marker' : 'primary';

    return (
      <>
        {isOpen ? <Icon name="angle-up" color={iconColor} /> : <Icon name="angle-down" color={iconColor} />}
        <Spacer x={4} />
      </>
    );
  };

  const Control = ({ ...props }: ControlProps & { selectProps: SelectProps }) => {
    const ControlComponent = props.selectProps.customComponents?.Control || Styled.Control;

    setIsOpen(props.menuIsOpen);

    if (props.menuIsOpen && selectRef.current) {
      setTimeout(() => {
        selectRef.current.focus();
      }, 0);
    }

    return (
      <>
        {label && hasLabelOutside && (
          <Styled.Label
            $isOpen={isOpen}
            $hasValue={value?.length > 0}
            $hasError={valid === false}
            $hasLabelOutside={hasLabelOutside}
          >
            {label}
          </Styled.Label>
        )}
        <ControlComponent
          $isOpen={props.menuIsOpen}
          $hasError={valid === false}
          $dark={props.selectProps.dark}
          $radiusClosed={props.selectProps.radius?.control}
          $radiusOpen={props.selectProps.radius?.menu}
        >
          <components.Control {...props}>{props.children}</components.Control>
        </ControlComponent>
      </>
    );
  };

  const ValueContainer = ({ ...props }: ValueContainerProps & { selectProps: SelectProps }) => {
    const InputContainerComponent = props.selectProps.customComponents?.InputContainer || Styled.InputContainer;
    const ValueContainerComponent = props.selectProps.customComponents?.ValueContainer || Styled.ValueContainer;

    return (
      <InputContainerComponent>
        {!!label && !hasLabelOutside && (
          <Styled.Label
            $isOpen={isOpen}
            $hasValue={value?.length > 0}
            $hasError={valid === false}
            $hasLabelOutside={hasLabelOutside}
          >
            {label}
          </Styled.Label>
        )}
        <ValueContainerComponent $hasLabel={!!label && !hasLabelOutside}>
          <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
        </ValueContainerComponent>
      </InputContainerComponent>
    );
  };

  const IndicatorSeparator = (props: IndicatorSeparatorProps & { selectProps: SelectProps }) => {
    if (props.selectProps.customComponents?.IndicatorSeparator) {
      return props.selectProps.customComponents.IndicatorSeparator;
    }
    return <></>;
  };

  const MenuList = (props: MenuListProps & { selectProps: SelectProps }) => {
    const MenuListComponent = props.selectProps.customComponents?.MenuList || Styled.MenuList;

    return (
      <MenuListComponent $dark={props.selectProps.dark} $radius={props.selectProps.radius?.menu}>
        <components.MenuList {...props}>
          <Styled.Separator $dark={props.selectProps.dark} />
          {props.children}
        </components.MenuList>
      </MenuListComponent>
    );
  };

  const Option = (props: OptionProps & { selectProps: SelectProps }) => {
    const OptionComponent = props.selectProps.customComponents?.Option || Styled.Option;

    return (
      <OptionComponent>
        <components.Option {...props} />
      </OptionComponent>
    );
  };

  const onChangeValue = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      className={cx('select', name, className)}
      ref={selectRef}
      options={options}
      isOptionDisabled={(option: any) => option.disabled}
      value={options.filter((option) => option.value === value)}
      onChange={(option: any) => onChangeValue(option.value)}
      placeholder=""
      isSearchable={isSearchable}
      menuPortalTarget={portalTarget}
      components={{
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: IndicatorSeparator,
        MenuList: MenuList,
        Control: Control,
        ValueContainer: ValueContainer,
        Option: Option,
      }}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          boxShadow: 'none',
          borderRadius: '8px',
          backgroundColor: 'inherit',
          ...styles?.control,
        }),
        placeholder: (base) => ({
          ...base,
          color: '#8E969B',
          marginTop: '-8px',
        }),
        input: (base) => ({
          ...base,
          marginTop: '-8px',
        }),
        singleValue: (base) => ({
          ...base,
          marginTop: '-6px',
          ...styles?.singleValue,
        }),
        valueContainer: (base) => ({
          ...base,
          overflow: 'initial',
          ...styles?.valueContainer,
        }),
        menu: (base) => ({
          ...base,
          ...styles?.menu,
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
      {...restProps}
    />
  );
};

export default SelectAtom;
