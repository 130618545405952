import { useCallback, useContext } from 'react';
import FilterContext from './filter-context';
import type { FilterOption } from './types';
import { FilterFieldNames } from './types';

export const useFilterContext = () => {
  const { userFilters, setUserFilters, loading, entries, activeFilters, filterNumber, applyFilters, setApplyFilters } =
    useContext(FilterContext);

  const resetFilters = useCallback(() => {
    setUserFilters({});
    setApplyFilters(true);
  }, [setUserFilters]);

  const resetFilter = useCallback(
    (inputs: string[]) => {
      setUserFilters((currentValues) => {
        let newValues = { ...currentValues };
        inputs.forEach((input) => {
          newValues[input] && delete newValues[input];
        });
        return newValues;
      });
    },
    [setUserFilters],
  );

  return {
    userFilters,
    setUserFilters,
    resetFilters,
    resetFilter,
    activeFilters,
    filterNumber,
    entries,
    loading,
    applyFilters,
    setApplyFilters,
  };
};

const getDynamicFieldName: (values: any, defaultField: string, fieldNames?: FilterFieldNames) => string = (
  values,
  defaultField,
  fieldNames,
) => {
  if (fieldNames && fieldNames.length) {
    const firstConditionHit = fieldNames.find(({ name, condition }) => condition(values));

    if (firstConditionHit) {
      return firstConditionHit.name;
    }
  }

  return defaultField;
};

export const useFilterOption: (
  filterOption: FilterOption,
  defaultValue: any,
) => {
  value: any;
  setValue: (value: any) => void;
} = (filterOption, defaultValue = []) => {
  const { userFilters, setUserFilters } = useFilterContext();

  const setValue = (value: any) => {
    const fieldName = getDynamicFieldName(value, filterOption.defaultField, filterOption.fieldNames);
    setUserFilters((currentValues) => ({
      ...currentValues,
      [filterOption.input]: { fieldName, value },
    }));
  };

  return {
    value: userFilters[filterOption.input]?.value === undefined ? defaultValue : userFilters[filterOption.input]?.value,
    setValue,
  };
};

export const useFilterValue = (inputName: string) => {
  const { userFilters } = useFilterContext();
  return userFilters[inputName] ? userFilters[inputName].value : undefined;
};
