import { TranslateArg, TranslateArgFunc, TranslateResult } from '../types/translate';

// todo: what on Earth is this nonsense? this can be done with a simple string replace
export function replaceWithArgs<T>(str: string, ...args: TranslateArg<T>[]): TranslateResult<T> {
  let complex = false;
  const chunks = [];

  // TODO(niklasb) this regex prevents stuff like {0:Foo {platform.name} bar}
  const r = /\{(\d+)(?::([^}]*))?\}/g;

  const addStringChunk = (start: number, end: number) => {
    if (end > start) {
      chunks.push(str.slice(start, end));
    }
  };

  let start = 0;
  let m;
  let key = 0;
  while ((m = r.exec(str))) {
    addStringChunk(start, m.index);
    const [all, num, param] = m;
    const i: number = parseInt(num);
    if (i < 0 || i >= args.length) {
      throw Error(`Invalid argument ID in translation string: ${i} in ${str}`);
    }

    const isFuncArg = typeof args[i] === 'function';
    if (param !== undefined && !isFuncArg) {
      throw Error(
        `Parameter for translation argument is ignored in translation string: ${i} in ${str} (param: ${param})`,
      );
    }

    const arg: TranslateArgFunc<T> | string | T = args[i];

    let argFunc: TranslateArgFunc<T>;
    if (isFuncArg) {
      // We know that arg is of type TranslateArgFunc<T> here
      // (assuming T is not a function type)
      argFunc = arg as TranslateArgFunc<T>;
    } else {
      // If that's not the case, it has to be string | T
      argFunc = () => arg as string | T;
    }

    const chunk: T | string = argFunc(param, key++);

    if (typeof chunk !== 'string') {
      complex = true;
    }
    chunks.push(chunk);
    start = m.index + all.length;
  }
  addStringChunk(start, str.length);
  return complex ? chunks : chunks.join('');
}

export const replaceWithStringArgs = (str: string, ...args: TranslateArg<string>[]): string => {
  const res = replaceWithArgs(str, ...args);

  if (typeof res !== 'string') {
    // complex should be false in replaceWithArgs, hence this should be impossible
    throw Error('this should not never happen.');
  }
  return res;
};

export default replaceWithArgs;
