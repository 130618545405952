import { DefaultTheme } from 'styled-components';
import { ThemeDependentThemeFn } from 'ui/styles/types';

export function resolveThemeFn<V extends string | number | boolean | null | undefined | Array<unknown> | Object>(
  value: ThemeDependentThemeFn<V> | V,
  theme: DefaultTheme,
): V {
  if (typeof value === 'function') {
    return value({ theme });
  }
  return value;
}
