import React, { FunctionComponent, useEffect } from 'react';
import LoadingRing from 'ui/atoms/loading-ring';
import { useInvestorSelector } from './resources/investor/hooks';
import { useWallets } from './resources/wallets/hooks';
import { useInvestorHasInvestmentsSelector } from './resources/investments/hooks';
import { includeManagedWallet } from 'helper/wallet';
import InvestorPages from './pages';
import { useDispatch } from 'store/hooks';
import { loadInvestorApp } from 'apps/investor/actions';
import { useCurrentUserSelector, useIsLoggedInSelector } from 'core/auth/hooks';
import reducer from './reducer';
import withApp from 'core/with-app';
import { InvestorAppState } from 'store/types';
import { useServerConfigSelector } from 'src/core/config/hooks';

const InvestorApp: FunctionComponent = () => {
  const isLoggedIn = useIsLoggedInSelector();
  const { currentUser } = useCurrentUserSelector();
  const { investor, loading: investorLoading } = useInvestorSelector();
  const { wallets, loading: walletsLoading } = useWallets(); // todo, get rid of this, we can just show a not-available view on the transactions page
  const { hasInvestments, hasEcspInvestments, hasInvestmentsLoading, hasEcspInvestmentsLoading } =
    useInvestorHasInvestmentsSelector();

  const investorHasManagedWallet = includeManagedWallet(wallets);
  const { config } = useServerConfigSelector();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && currentUser?.investor) {
      dispatch(loadInvestorApp());
    }
  }, [currentUser?.investor, isLoggedIn]);

  if (investorLoading || walletsLoading || hasInvestmentsLoading || hasEcspInvestmentsLoading) {
    return <LoadingRing />;
  }

  return (
    <InvestorPages
      investor={investor}
      investorHasManagedWallet={investorHasManagedWallet}
      hasInvestments={hasInvestments}
      hasEcspInvestments={hasEcspInvestments}
      config={config}
    />
  );
};

export default withApp<InvestorAppState>('investor', reducer)(InvestorApp);
