import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import type { Colors } from 'ui/styles/utils/colors';
import type { Spacing } from 'ui/styles/themes/spacing';

export type BoxAlignOptions = 'top' | 'bottom' | 'center' | 'justify';
export type BoxJustifyOptions = 'left' | 'right' | 'center' | 'justify';
export type BoxDirectionOptions = 'row' | 'row-reverse' | 'column';

export interface TextProps {
  $justify?: BoxJustifyOptions;
  $align?: BoxAlignOptions;
  $bgColor?: Colors;
  $spacing?: Spacing;
  $direction?: BoxDirectionOptions;
  $flex?: string;
  $wrap?: boolean;
}
function getAlignOptions({ $align }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$align) return;

  switch ($align) {
    case 'center':
      return css`
        align-content: center;
        vertical-align: center;
      `;
    case 'justify':
      return css`
        align-content: space-between;
      `;
    case 'top':
      return css`
        align-content: flex-start;
        vertical-align: top;
      `;
    case 'bottom':
      return css`
        align-content: flex-end;
        vertical-align: bottom;
      `;
    default:
      return null;
  }
}

function getJustify({ $justify }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$justify) return;

  switch ($justify) {
    case 'center':
      return css`
        justify-content: center;
      `;
    case 'justify':
      return css`
        justify-content: space-between;
      `;
    case 'left':
      return css`
        justify-content: flex-start;
      `;
    case 'right':
      return css`
        justify-content: flex-end;
      `;
    default:
      return null;
  }
}

function getDirection({ $direction }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$direction) return null;

  return css`
    flex-direction: ${$direction};
  `;
}

function getFlex({ $flex }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$flex) return null;

  return css`
    flex: ${$flex};
  `;
}

function getWrap({ $wrap }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$wrap) return null;

  return css`
    flex-wrap: ${$wrap ? 'wrap' : 'inherit'};
  `;
}

function getColor({ theme, $bgColor }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$bgColor) return null;

  return css`
    background-color: ${resolveColor(theme, $bgColor)};
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<TextProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css`
    margin-bottom: ${theme.spacing[$spacing]}rem;
  `;
}

export const Box = styled.div<TextProps>`
  display: flex;
  ${getAlignOptions};
  ${getJustify};
  ${getFlex};
  ${getWrap};
  ${getColor};
  ${getDirection};
  ${getSpacing};
`;
