import React, { FunctionComponent, useEffect, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import { InvestmentTokenForInvestor, WalletBalance, Balance } from 'api/models';
import DashboardWalletInfo from './dashboard-wallet-info';
import { convertWalletTypeFromApi } from 'core/api/conversions';
import { useApiCall } from 'hooks/use-api-call';
import { BalanceApi } from 'api/apis';
import { getWalletAddressForNetwork } from 'helper/network';
import WalletActions from '../wallet-actions';

const WalletDetails: FunctionComponent<{ walletBalance: WalletBalance; token: InvestmentTokenForInvestor }> = ({
  walletBalance,
  token,
}) => {
  const { walletId, managed, isFrozen, type } = walletBalance;

  const networkAddress =
    token?.tokenizationInfo && getWalletAddressForNetwork(token?.tokenizationInfo.networkType, walletBalance);

  const [balance, setBalance] = useState<Balance>();

  const { withApi, makeAuthenticatedApi } = useApiCall(false);

  useEffect(() => {
    withApi(async () => {
      const api: BalanceApi = makeAuthenticatedApi(BalanceApi);
      const balance = await api.balanceRetrieve({
        tokenId: token.id,
        walletId: walletId || '',
      });
      if (balance) {
        setBalance(balance);
      }
    });
  }, [makeAuthenticatedApi]);

  return (
    <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
      <DashboardWalletInfo
        type={convertWalletTypeFromApi(type)}
        address={networkAddress}
        balance={balance}
        managed={managed}
        isFrozen={isFrozen}
        networkType={token?.tokenizationInfo?.networkType}
      />
      {managed && walletId && networkAddress && (
        <WalletActions
          wallet={{
            ...walletBalance,
            id: walletId,
          }}
          token={token}
          balance={balance}
        />
      )}
    </BoxedContent>
  );
};

export default WalletDetails;
