import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'store/hooks';

import { AppType } from 'core/auth/types';
import { useCurrentUserSelector, useIsLoggedInSelector } from 'core/auth/hooks';
import { reLogin } from 'core/auth/actions';
import { isInvestor, isIssuer } from 'core/auth/helper';
import { push } from 'connected-react-router';
import ViewPermissionMissing from 'core/components/view-permission-missing';

interface AuthenticatedPageProps {
  appType?: AppType;
  condition?: boolean;
}

const AuthenticatedPage: FunctionComponent<AuthenticatedPageProps> = ({ children, condition, appType }) => {
  const { currentUser } = useCurrentUserSelector();
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();

  useEffect(() => {
    if (!isLoggedIn) dispatch(reLogin(appType || AppType.DEFAULT));

    const isIssuerInWrongApp =
      isIssuer(currentUser) && (appType === AppType.INVESTMENT || appType === AppType.INVESTOR);

    const isInvestorInWrongApp = isInvestor(currentUser) && appType === AppType.ISSUER;

    if (isIssuerInWrongApp || isInvestorInWrongApp) {
      dispatch(push('/dashboard'));
    }
  }, [isLoggedIn]);

  if (condition === false) return <ViewPermissionMissing />;

  if (isLoggedIn) {
    return <>{children}</>;
  }

  return null;
};

export default AuthenticatedPage;
