import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Button from 'ui/atoms/button';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Hint from 'ui/atoms/hint';
import { useCurrentUserSelector } from 'core/auth/hooks';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, AdminStatisticsReportRetrieveReportTypeEnum } from 'api';

const DownloadInvestmentData: FunctionComponent = () => {
  const [isDownloadStatisticsModalOpen, setDownloadStatisticsModalOpen] = useState(false);

  const [successGenerate, setSuccessGenerate] = useState<boolean | undefined>(undefined);

  const { currentUser } = useCurrentUserSelector();

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const generateInvestmentsData = () => {
    withApi(async () => {
      let statistics = await adminApi.adminStatisticsReportRetrieve({
        reportType: AdminStatisticsReportRetrieveReportTypeEnum.ISSUER_EXPORT,
      });
      setSuccessGenerate(statistics?.success);
    });
    setDownloadStatisticsModalOpen(true);
  };

  return (
    <>
      <Button variant="primary" onClick={generateInvestmentsData}>
        <Translate name="downloadInvestmentsData.button" />
      </Button>
      {successGenerate !== undefined && isDownloadStatisticsModalOpen && (
        <Modal onClose={() => setDownloadStatisticsModalOpen(false)}>
          <ModalHeader>
            <Translate name="downloadInvestmentsData.title" />
          </ModalHeader>
          <ModalContent>
            {successGenerate ? (
              <>
                <Hint variant="success">
                  <Translate name="downloadInvestmentsData.success.hint" args={[currentUser?.email]} />
                </Hint>
                <p>
                  <Translate name="downloadInvestmentsData.success.info" />
                </p>
                <Translate name="downloadInvestmentsData.success.infoSecondary" />
              </>
            ) : (
              <>
                <Hint variant="danger">
                  <Translate name="downloadInvestmentsData.error.hint" />
                </Hint>
                <p>
                  <Translate name="downloadInvestmentsData.error.info" args={[currentUser?.email]} />
                </p>
                <Translate name="downloadInvestmentsData.error.infoSecondary" args={[currentUser?.email]} />
              </>
            )}
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              align={successGenerate ? 'start' : 'end'}
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  variant: successGenerate ? 'primary' : 'link',
                  size: 'large',
                  onClick: () => setDownloadStatisticsModalOpen(false),
                },
              ]}
            />
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default WithAuthenticatedPage(DownloadInvestmentData, AppType.ISSUER);
