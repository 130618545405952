import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import { AddIdentificationFields } from '..';
import PersonalData from './personal-data';
import RegisteredAddress from './registered-address';
import DocumentData from './document-data';
import Button from 'ui/atoms/button';

export interface ConfirmIdentificationModalProps {
  identification: AddIdentificationFields;
  goToEditData: () => void;
}

const ConfirmIdentification: FunctionComponent<ConfirmIdentificationModalProps> = (props) => {
  const { identification, goToEditData } = props;

  return (
    <>
      <Hint variant="info">
        <Translate name="dashboardIssuerInvestorDetails.confirmIdentificationForm.hint" />
      </Hint>
      <PersonalData identification={identification} />
      <RegisteredAddress identification={identification} />
      <DocumentData identification={identification} />
      <Button variant="secondary" onClick={goToEditData}>
        <Translate name={'dashboardIssuerInvestorDetails.confirmIdentificationForm.secondaryButton'} />
      </Button>
    </>
  );
};

export default ConfirmIdentification;
