export interface PredefinedInputOptionTheme {
  activeBackgroundColor: any;
  activeBorderColor: any;
  activeColor: any;
  borderColor: any;
  color: any;
}

export default {
  activeBackgroundColor: ({ theme }: any) => theme.colors.primaryLight,
  activeBorderColor: ({ theme }: any) => theme.colors.primaryMain,
  activeColor: ({ theme }: any) => theme.colors.primaryMain,
  borderColor: ({ theme }: any) => theme.colors.grayHover,
  color: ({ theme }: any) => theme.colors.black,
} as PredefinedInputOptionTheme;
