import React, { FunctionComponent, useContext } from 'react';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Header from 'ui/atoms/header';
import { AdditionalInformationForm } from 'libraries/wizard/components/additional-information/additional-information-form';
import WizardContext from 'libraries/wizard/wizard-context';

const BankAccount: FunctionComponent = () => {
  const { isRegisterOnlyFlow } = useContext(WizardContext);

  return (
    <Section spacing="large">
      <Header as="h3" size="small">
        <Translate
          name={
            isRegisterOnlyFlow
              ? 'registerAdditionalInformation.bankAccount.title'
              : 'investmentAdditionalInformation.bankAccount.title'
          }
        />
      </Header>
      <AdditionalInformationForm.Group
        name="bankAccountHolder"
        required={true}
        info={<Translate name="additionalInformationForm.fields.bankAccountHolder.info" />}
      >
        <AdditionalInformationForm.Input maxLength={100} />
      </AdditionalInformationForm.Group>
      <AdditionalInformationForm.Group
        name="bankIban"
        required={true}
        info={<Translate name="additionalInformationForm.fields.bankIban.info" />}
      >
        <AdditionalInformationForm.Input maxLength={100} />
        {AdditionalInformationForm.Validators.IBAN()}
        {/*TODO(mara-cashlink): handle spaces, for validation and value of "iban"*/}
        {/*TODO(mara-cashlink): use masked Input field here to handle spaces*/}
        {/*  between country code, check digits and the following characters*/}
      </AdditionalInformationForm.Group>
    </Section>
  );
};

export default BankAccount;
