import React, { ComponentType, FunctionComponent } from 'react';
import AuthenticatedPage from './authenticated-page';
import { AppType } from 'core/auth/types';

const WithAuthenticatedPage =
  <P extends object>(Component: ComponentType<P>, appType?: AppType): FunctionComponent<any> =>
  (props) => {
    return (
      <AuthenticatedPage appType={appType} condition={props.condition}>
        <Component {...(props as P)} />
      </AuthenticatedPage>
    );
  };

export default WithAuthenticatedPage;
