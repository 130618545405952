import React, { FunctionComponent } from 'react';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import IssuerConfirmInvestmentsTableView from 'apps/issuer/shared/confirm-investments-table-view';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';

const IssuerApprovalsPage: FunctionComponent<{}> = () => {
  return (
    <IssuerConfirmInvestmentsTableView type="approveOffers" filters={<IssuerInvestmentsFilters />} defaultLimit={50} />
  );
};

export default WithAuthenticatedPage(IssuerApprovalsPage, AppType.ISSUER);
