import React, { FunctionComponent, useCallback, useState, useEffect } from 'react';
import Translate from 'ui/atoms/translate';
import * as Styled from '../styled';
import { clamp } from 'lodash';
import { MAX_VALUE } from '../index';
import Accordion from 'src/ui/atoms/accordion';

export interface YearlyIncomeProps {
  onChangeYearlyIncome: (
    netLaborIncome: number,
    interestIncome: number,
    dividentsIncome: number,
    rentIcome: number,
  ) => void;
  onChangeTotalSum: (sum: number) => void;
  shouldResetValues?: boolean;
}

const YearlyIncome: FunctionComponent<YearlyIncomeProps> = (props) => {
  const { onChangeYearlyIncome, onChangeTotalSum, shouldResetValues } = props;

  const [isExpanded, setExpanded] = useState(false);
  const [netLaborIncome, setNetLaborIncome] = useState(0);
  const [interestIncome, setInterestIncome] = useState(0);
  const [dividentsIncome, setDividentsIncome] = useState(0);
  const [rentIcome, setRentIncome] = useState(0);

  useEffect(() => {
    onChangeYearlyIncome(netLaborIncome, interestIncome, dividentsIncome, rentIcome);
  }, [netLaborIncome, interestIncome, dividentsIncome, rentIcome]);

  useEffect(() => {
    setNetLaborIncome(0);
    setInterestIncome(0);
    setDividentsIncome(0);
    setRentIncome(0);
  }, [shouldResetValues]);

  const getTotalIncome = useCallback(() => {
    const total: number = netLaborIncome + interestIncome + dividentsIncome + rentIcome;
    onChangeTotalSum(total);

    return total.toString();
  }, [netLaborIncome, interestIncome, dividentsIncome, rentIcome]);

  return (
    <Accordion
      label={<Translate name="lossAbility.yearlyIncome" />}
      additionalInfo={
        <Styled.NetWorthInput
          type="number"
          textAlign="right"
          height="small"
          value={getTotalIncome()}
          disabled
          isCurrency
        />
      }
      expanded={isExpanded}
      onChange={() => setExpanded(!isExpanded)}
    >
      <>
        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.netLaborIncome" />
            <br />
            <Translate name="lossAbility.netLaborIncomeDescription" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={netLaborIncome.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setNetLaborIncome(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.interestIncome" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={interestIncome.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setInterestIncome(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.dividentsIncome" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={dividentsIncome.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setDividentsIncome(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>

        <Styled.FinancialItem>
          <div>
            <Translate name="lossAbility.rentIncome" />
          </div>
          <Styled.NetWorthInput
            type="number"
            min={0}
            max={MAX_VALUE}
            value={rentIcome.toString()}
            isCurrency
            textAlign="right"
            height="small"
            onChange={(e: any) => setRentIncome(clamp(Number(e.target.value), 0, MAX_VALUE))}
          />
        </Styled.FinancialItem>
      </>
    </Accordion>
  );
};

export default YearlyIncome;
