import { first, find, findIndex } from 'lodash';

function getNextWithOverlap<T>(current: T, a: T[], b: T[]) {
  // If there is no items in b, don´t look for anything, there is no possible next of "current"
  if (!b.length) return undefined;

  const currentIndexInB = b.indexOf(current);
  const currentIndexInA = a.indexOf(current);

  // if "current" is available in "b", its next sibling is our next item, easy!
  // current = C, a = [A, B, C, D, E],  b = [C, D, E]
  //            current  <---^    found in b ^  ^------> next
  if (currentIndexInB !== -1) {
    return b[currentIndexInB + 1];
  } else if (currentIndexInA !== -1) {
    // "current" is not available in "b", so we are looking for the first sibling of "a" which is also in "b"
    // current = C, a = [A, B, C, D, E],              b = [A, E]
    //            current  <---^  ^--^ -> siblings            ^---> found a sibling -> next

    const nextItems = a.slice(currentIndexInA + 1);
    const nextCurrent = find(nextItems, (nextItem) => b.includes(nextItem));

    // found a sibling in "b", take that!
    if (nextCurrent) return nextCurrent;

    // okay, everything went wrong, now look in the opposite direction, the previous siblings
    // current = C, a = [A, B, C, D, E],                    b = [A, Z]
    //  prev siblings <- ^--^  ^---> current   found a sibling --^  ^---> next
    const previousItems = a.slice(0, currentIndexInA).reverse();
    const prevCurrentIndex = findIndex(previousItems, (prevItem) => b.includes(prevItem));

    // okay we found a previous sibling of "current" in "a", which is also in "b", so take its next item
    if (prevCurrentIndex === -1) return b[prevCurrentIndex + 1];
  }

  // so, nothing found, no overlap determinable, just take the first entry
  return first(b);
}
export default getNextWithOverlap;
