import { useState } from 'react';

// todo: this belongs in redux, but investment app migration to redux is a tad bit tricky
const useEditInvestorData = () => {
  const [isEditRegistrationAddressOpen, setEditRegistrationAddressOpen] = useState(false);

  const [isEditCompanyDataOpen, setEditCompanyDataOpen] = useState(false);

  const [isEditBankAccountOpen, setEditBankAccountOpen] = useState(false);

  const [isEditDepositAccountOpen, setEditDepositAccountOpen] = useState(false);

  const [isEditTaxInformationOpen, setEditTaxInformationOpen] = useState(false);

  const [isAddIdentificationOpen, setAddIdentificationOpen] = useState(false);

  const [isEditBenefitingPersonsOpen, setEditBenefitingPersonsOpen] = useState(false);

  const [isSuccessEditOpen, setSuccessEditOpen] = useState(false);

  const setEditRegistrationAddressModal = (value: boolean) => {
    setEditRegistrationAddressOpen(value);
  };

  const setEditCompanyDataModal = (value: boolean) => {
    setEditCompanyDataOpen(value);
  };

  const setEditBankAccountModal = (value: boolean) => {
    setEditBankAccountOpen(value);
  };

  const setEditDepositAccountModal = (value: boolean) => {
    setEditDepositAccountOpen(value);
  };

  const setEditTaxInformationModal = (value: boolean) => {
    setEditTaxInformationOpen(value);
  };

  const setAddIdentificationModal = (value: boolean) => {
    setAddIdentificationOpen(value);
  };

  const setEditBenefitingPersonsModal = (value: boolean) => {
    setEditBenefitingPersonsOpen(value);
  };

  const setSuccessModal = (value: boolean) => {
    setSuccessEditOpen(value);
  };

  return {
    isEditRegistrationAddressOpen,
    isEditCompanyDataOpen,
    isEditBankAccountOpen,
    isEditDepositAccountOpen,
    isEditTaxInformationOpen,
    isAddIdentificationOpen,
    isSuccessEditOpen,
    isEditBenefitingPersonsOpen,
    setEditRegistrationAddressModal,
    setEditCompanyDataModal,
    setEditBankAccountModal,
    setEditDepositAccountModal,
    setEditTaxInformationModal,
    setAddIdentificationModal,
    setEditBenefitingPersonsModal,
    setSuccessModal,
  };
};

export default useEditInvestorData;
