import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import config from 'core/config/local';
import reportWebVitals from './reportWebVitals';
import 'react-datepicker/dist/react-datepicker.css';

export default function renderApp({ App }: { App: React.ReactElement }) {
  if (config.sentry) {
    console.info('Initializing sentry with config', config.sentry);
    Sentry.init(config.sentry);
  } else {
    console.info('Skipping sentry setup');
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  return ReactDOM.render(App, document.getElementById('root'));
}
