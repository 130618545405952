import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import ChangePassword, { ChangePasswordFields, ChangePasswordForm } from './change-password-form';
import Translate from 'ui/atoms/translate';
import { UsersApi } from 'api/apis';
import useApiCall from 'hooks/use-api-call';
import usePasswordStrength from 'hooks/use-password-strength';
import { NaturalPerson } from 'api/models';
import { getNormalisedUserDataForPasswordEvaluation } from 'ui/helper/password';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import { useCurrentUserSelector } from 'core/auth/hooks';

export interface BoxedChangePasswordProps {
  /** Natural person - user data needed for password strength evaluation. */
  naturalPerson?: NaturalPerson;
}
const BoxedChangePassword: FunctionComponent<BoxedChangePasswordProps> = (props) => {
  const { naturalPerson } = props;

  const { error, loading, withApi, makeAuthenticatedApi } = useApiCall();

  const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState<boolean>();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  const { currentUser } = useCurrentUserSelector();

  const getPasswordStrength = usePasswordStrength();

  const onSubmit = useCallback(
    ({ oldPassword, newPassword }: ChangePasswordFields) => {
      withApi(async () => {
        await usersApi.usersPasswordCreate({
          setPasswordRequest: {
            oldPassword,
            newPassword,
          },
        });
        setPasswordChangedSuccessfully(true);
      });
    },
    [usersApi, withApi],
  );

  return (
    <BoxedContent title={<Translate name="changePassword.password" />} releaseSpaceWhenTitleIsEmpty={true}>
      <ChangePasswordForm onSubmit={onSubmit} error={error} i18nKey="changePassword">
        <ChangePasswordForm.ValueProvider>
          {({ newPassword }) => (
            <ChangePassword
              passwordChangedSuccessfully={passwordChangedSuccessfully}
              passwordStrength={
                newPassword
                  ? getPasswordStrength(
                      newPassword,
                      // normalized user inputs will be considered when evaluating password strength
                      getNormalisedUserDataForPasswordEvaluation({
                        email: currentUser?.email,
                        forename: naturalPerson?.forename,
                        surname: naturalPerson?.surname,
                      }),
                    )
                  : undefined
              }
            />
          )}
        </ChangePasswordForm.ValueProvider>
        <ChangePasswordForm.GenericErrorMessages />
        <ActionButtons>
          {passwordChangedSuccessfully && (
            <Button variant="link" onClick={() => setPasswordChangedSuccessfully(false)}>
              <Translate name="changePassword.goBack" />
            </Button>
          )}
          {!passwordChangedSuccessfully && (
            <Button variant="primary" type="submit" loading={loading}>
              <Translate name="changePassword.changePassword" />
            </Button>
          )}
        </ActionButtons>
      </ChangePasswordForm>
    </BoxedContent>
  );
};

export default BoxedChangePassword;
