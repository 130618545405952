import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import Logo from 'ui/atoms/logo';
import Spacer from 'ui/atoms/spacer';
import SidebarNavList from 'core/nav/sidebars/nav-list';
import Sidebar from 'ui/molecules/sidebar-group/sidebar';
import Imprint from 'core/components/imprint';
import type { NavigationItem } from 'core/nav/types';

const DashboardSidebar: FunctionComponent<{ navigationItems: NavigationItem[]; className?: string }> = ({
  navigationItems,
  className,
}) => {
  return (
    <Sidebar wide showSidebar={!!navigationItems.length} className={className}>
      <Styled.SidebarHeader>
        <Logo logoType="inverted" variant="studio" spacing="none" borderRight={false} />
      </Styled.SidebarHeader>
      <Spacer y={6} />
      <Styled.SidebarContent>
        <SidebarNavList navigationItems={navigationItems} />
      </Styled.SidebarContent>
      <Styled.SidebarFooter>
        <Imprint color="white" />
      </Styled.SidebarFooter>
    </Sidebar>
  );
};

export default DashboardSidebar;
