import type { Investor } from 'api';

export enum InvestorActions {
  INVESTOR_FETCH = 'INVESTOR/FETCH',
  INVESTOR_FETCH_SUCCESS = 'INVESTOR/FETCH_SUCCESS',
  INVESTOR_FETCH_FAILURE = 'INVESTOR/FETCH_FAILURE',
}

export type InvestorFetch = { type: InvestorActions.INVESTOR_FETCH };
export type InvestorFetchSuccess = {
  type: InvestorActions.INVESTOR_FETCH_SUCCESS;
  data: Investor;
};
export type InvestorFetchFailure = {
  type: InvestorActions.INVESTOR_FETCH_FAILURE;
  error: Error;
};

export type InvestorActionTypes = InvestorFetch | InvestorFetchSuccess | InvestorFetchFailure;

export function investorFetch(): InvestorFetch {
  return {
    type: InvestorActions.INVESTOR_FETCH,
  };
}

export function investorFetchSuccess(data: Investor): InvestorFetchSuccess {
  return {
    type: InvestorActions.INVESTOR_FETCH_SUCCESS,
    data,
  };
}

export function investorFetchFailure(error: Error): InvestorFetchFailure {
  return {
    type: InvestorActions.INVESTOR_FETCH_FAILURE,
    error,
  };
}
