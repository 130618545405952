import { useCallback, useMemo } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import useInvestorMe from 'hooks/use-investor-me';
import { AdditionalInformationFields } from 'libraries/wizard/components/additional-information/additional-information-form';
import { useServerConfigSelector } from 'core/config/hooks';
import { AnyServerError } from 'hooks/use-api-error';

const useAdditionalInformation = (): {
  onSubmit: (values: AdditionalInformationFields) => void;
  bankAccountRequired: boolean;
  securitiesDepositAccountRequired: boolean;
  taxInformationRequired: boolean;
  showLoading: boolean;
  error?: AnyServerError;
} => {
  //hooks
  const { error: apiError, loading: apiLoading, makeAuthenticatedApi, withApi } = useApiCall();
  const { investor, loading: investorLoading, error: investorError } = useInvestorMe();
  const { config } = useServerConfigSelector();

  const showLoading = !investor?.id || investorLoading || apiLoading;
  const error = investorError || apiError;

  const bankAccountRequired = useMemo(
    () => config?.bankAccountRequired && !investor?.person.bankAccount,
    [investor?.person?.bankAccount?.id, config?.bankAccountRequired],
  );

  const securitiesDepositAccountRequired = useMemo(
    () => config?.securitiesDepositAccountRequired && !investor?.person.securitiesDepositAccount,
    [investor?.person?.securitiesDepositAccount?.id, config?.securitiesDepositAccountRequired],
  );

  const taxInformationRequired = useMemo(() => config?.taxInformationRequired, [config?.taxInformationRequired]);

  //apis
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  //functions
  const onSubmit = useCallback(
    (values: AdditionalInformationFields) => {
      return withApi(async () => {
        if (!investor?.id) return false;
        await investorsApi.investorsPartialUpdate({
          id: investor.id,
          patchedInvestorUpdateRequest: {
            bankAccount:
              bankAccountRequired && values.bankIban.length > 0 && values.bankAccountHolder.length > 0
                ? {
                    iban: values.bankIban,
                    accountHolder: values.bankAccountHolder,
                  }
                : undefined,
            securitiesDepositAccount: securitiesDepositAccountRequired
              ? {
                  bic: values.bic,
                  accountHolder: values.securitiesDepositAccountHolder,
                  accountNumber: values.securitiesDepositAccountHolder,
                }
              : undefined,
            taxInformation: taxInformationRequired
              ? {
                  taxIdentificationNumber: values?.taxIdentificationNumber || '',
                  nonAssessmentCertificate: values?.nonAssessmentCertificate === 'true',
                }
              : undefined,
          },
        });
      });
    },
    [investorsApi, investor?.id, withApi],
  );

  return useMemo(
    () => ({
      bankAccountRequired,
      securitiesDepositAccountRequired,
      taxInformationRequired,
      showLoading,
      error,
      onSubmit,
    }),
    [
      bankAccountRequired,
      securitiesDepositAccountRequired,
      taxInformationRequired,
      onSubmit,
      investor?.id,
      showLoading,
      error,
    ],
  );
};

export default useAdditionalInformation;
