import React, { FunctionComponent } from 'react';

import { As } from 'ui/helper/get-element-type';

import Number from '../number';

export interface PercentProps extends As {
  /** Number to be formatted. */
  children: number | string;

  /** Additional classes. */
  className?: string;

  decimals?: number;
}

export const Percent: FunctionComponent<PercentProps> = (props) => {
  const { className, children, decimals = 2, ...restProps } = props;

  return (
    <Number
      type="percent"
      minimumFractionDigits={decimals}
      maximumFractionDigits={decimals}
      className={className}
      {...restProps}
    >
      {children}
    </Number>
  );
};

export default Percent;
