import React, { FunctionComponent, useState } from 'react';
import FileUpload from 'ui/atoms/file-upload';

export const SingleFileUpload: FunctionComponent<{
  onChange: (arg: Blob | null) => void;
  onFocus?: (arg: any) => void;
  required?: boolean;
  showRequiredError?: boolean;
  valid?: boolean;
  value?: any;
}> = ({ onChange = () => {}, value, required, showRequiredError }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(value);

  const handleFileInput = (event: any) => {
    event.preventDefault();

    const file = event.target?.files?.length ? event.target?.files[0] : event.dataTransfer.files[0];

    if (file && file.type === 'application/pdf') {
      setUploadedFile(file);
      onChange(file);
    }
  };

  const onRemoveDocument = () => {
    setUploadedFile(null);
    onChange(null);
  };

  return (
    <FileUpload
      handleFileInput={handleFileInput}
      uploadedFiles={[{ file: uploadedFile, documentType: '' }]}
      onRemoveDocument={onRemoveDocument}
      showRequiredError={showRequiredError}
    />
  );
};

export default SingleFileUpload;
