import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import useTranslate from 'ui/hooks/use-translate';
import replaceWithArgs from 'ui/helper/replace-with-args';
import { TranslateArg } from 'ui/types/translate';
import getElementType, { As } from 'ui/helper/get-element-type';
import BigNumber from 'bignumber.js';
import { makeBigNumber } from 'ui/helper/big-number';
import { reportMissingTranslation } from 'ui/i18n/utils';

export interface TranslateProps extends As {
  /** Fallback translation. */
  children?: string;

  name: string;

  args?: TranslateArg<ReactNode>[];

  [key: string]: any;

  preventRenderingOnNoTranslation?: boolean;

  grammaticalNumber?: BigNumber | number;
}

export const Translate: FunctionComponent<TranslateProps> = (props) => {
  const {
    children,
    name,
    args = [],
    preventRenderingOnNoTranslation = false,
    as,
    grammaticalNumber,
    ...restProps
  } = props;
  const translate = useTranslate();

  const ElementType = getElementType(Translate, props, Fragment);

  const bigGrammaticalNumber =
    grammaticalNumber !== undefined
      ? BigNumber.isBigNumber(grammaticalNumber)
        ? grammaticalNumber
        : makeBigNumber(grammaticalNumber)
      : undefined;

  const translatedString = translate(name, {
    args,
    grammaticalNumber: bigGrammaticalNumber,
  });
  let fallbackString;
  if (children) {
    fallbackString = replaceWithArgs(children as string, args);
  } else {
    fallbackString = name;
  }

  if (!translatedString) {
    if (preventRenderingOnNoTranslation) return null;
    reportMissingTranslation(name);
  }

  if (ElementType === Fragment) return <>{translatedString || fallbackString}</>;

  return <ElementType {...restProps}>{translatedString || fallbackString}</ElementType>;
};

export default Translate;
