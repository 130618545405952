import React, { FunctionComponent, MouseEventHandler } from 'react';
import * as Styled from './styled';

interface NavigationItemProps {
  onClick?: MouseEventHandler<HTMLElement>;
}

export const NavigationItem: FunctionComponent<NavigationItemProps> = (props) => {
  const { onClick, children } = props;

  return (
    <Styled.NavItem className="nav-item" onClick={onClick}>
      {children}
    </Styled.NavItem>
  );
};

export default NavigationItem;
