import React, { ReactNode } from 'react';

import { InputProps } from 'ui/atoms/input';
import Icon from 'ui/atoms/icon';

import * as Styled from './styled';

import type { FunctionComponent } from 'react';
import type { Icons } from 'ui/assets/icons/types';
import { IconSizes } from 'src/ui/styles/themes/icons';
import Size from 'src/ui/types/size';

export interface InputIconProps extends InputProps {
  icon: Icons;
  iconActive?: boolean;
  inputRef?: any;
  loading?: boolean;
  alignIcon?: string;
  height?: Size;
  iconSize?: IconSizes | number;
  onClickIcon?: () => void;
  label?: string | ReactNode;
  value?: string;
}

export const InputIcon: FunctionComponent<InputIconProps> = (props) => {
  const {
    icon,
    loading,
    inputRef,
    onClickIcon,
    iconActive,
    onClick,
    alignIcon = 'right',
    height = 'large',
    iconSize,
    label,
    value,
    className,
    ...restProps
  } = props;

  return (
    <Styled.InputContainer className={className} ref={inputRef} onClick={(iconActive ? onClick : () => {}) as any}>
      <Styled.Input alignIcon={alignIcon} height={height} value={value} label={label} {...restProps} />
      <Styled.Icon $align={alignIcon} $active={iconActive}>
        <Icon size={iconSize} name={icon} loading={loading} color="primary" onClick={onClickIcon} />
      </Styled.Icon>
    </Styled.InputContainer>
  );
};

export default InputIcon;
