import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import castArray from 'lodash/castArray';
import first from 'lodash/first';
import { reLogin, setAuthToken } from 'core/auth/actions';
import { AppType } from 'core/auth/types';
import Translate from 'ui/atoms/translate';
import { useDispatch } from 'react-redux';
import useInvestmentInvitation from 'src/subapps/investment/pages/investment/hooks/use-investment-invitation';
import useApiCall from 'hooks/use-api-call';
import { InvestmentAcquisitionTypeRecipientEnum, StepEnum } from 'api/models';
import { InvitationAll } from 'helper/cast';
import useResetScrollOnChange from 'hooks/use-reset-scroll-on-change';
import { InvitationsApi, WizardApi } from 'api/apis';
import BoxedInvestmentError from 'components/Investment/BoxedInvestmentError';
import ServerError from 'ui/types/server-error';
import { WizardLayoutContext } from 'core/layout/wizard';
import makeSidebarSteps from 'libraries/wizard/helpers/make-sidebar-steps';
import getNextWithOverlap from 'helper/get-next-with-overlap';
import { StringParam, useQueryParam } from 'use-query-params';
import type { LocalStorageInvitation } from 'subapps/investment/types';
import { useCurrentUserSelector } from 'core/auth/hooks';
import { isIssuer } from 'core/auth/helper';
import useOnlyOnce from 'hooks/use-only-once';
import { getInvitationIdLocal, removeRegistrationLocalStorage, setInvitationIdLocal } from 'subapps/investment/helpers';
import { InvitationTypeEnum } from 'ui/types/invitation-type';
import InvestmentAccessDenied from './error-messages/InvestmentAccessDenied';
import WizardContext from 'libraries/wizard/wizard-context';
import stepMapping from 'subapps/investment/pages/investment/wizard-steps';
import InvestmentSummary from './side-content';
import sidebarMapping, { transferSidebarMapping } from './wizard-steps/sidebar-mapping';
import useInvestmentOverview from './use-investment-overview';
import { useHistory } from 'react-router';
import { useQuestionnaire } from './wizard-steps/questionnaire/use-questionnaire';
import useInvestment from 'src/hooks/use-investment';

export interface InvestmentProps {
  invitationId: string;
}

const Investment: FunctionComponent<InvestmentProps> = ({ invitationId }) => {
  const [isUserAssociationChecking, setUserAssociationChecking] = useState(true);

  const dispatch = useDispatch();

  const [investmentError, setInvestmentError] = useState<ServerError>();

  const { setWizardSteps } = useContext(WizardLayoutContext);

  const {
    step,
    steps,
    setStep,
    setSteps,
    invitation,
    investmentId,
    loading,
    error,
    reload,
    reset,
    token,
    inactiveError,
  } = useInvestmentInvitation(invitationId);

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const [trackingId] = useQueryParam('tracking_id', StringParam);

  const { currentUser } = useCurrentUserSelector();

  const isLoggedIn = !!currentUser;

  const hasInvestment = !!investmentId;

  const hasUser = invitation?.hasUser;

  const investorId = currentUser?.investor;

  const isTransferInvitation = invitation?.invitationType === InvitationTypeEnum.TRANSFER_INVITATION;

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  const wizardApi: WizardApi = useMemo(() => makeAuthenticatedApi(WizardApi), [makeAuthenticatedApi]);

  const { onCloseProcessOverviewOverlay, showProcessOverview } = useInvestmentOverview(invitationId);

  const history = useHistory();

  const { setQuestionnairePage } = useQuestionnaire();

  // TODO(geforcefan): casting to investment invitation, we should be generic here, not supporting others for now
  const investmentInvitation = invitation as InvitationAll;

  const { investment } = useInvestment(investmentId || '');

  // TODO(mara-cashlink): access logic needs refactoring and comments
  // access logic

  const isAccessDenied =
    !loading &&
    !inactiveError &&
    ((!investmentInvitation && investment?.acquisitionType !== InvestmentAcquisitionTypeRecipientEnum.HANDOVER) ||
      (hasInvestment && isIssuer(currentUser)) ||
      (investmentInvitation &&
        isLoggedIn &&
        // hasInvestment is false even if there is an investment in cases where permission is denied
        !hasInvestment &&
        // if invitation has a user, and we can read it, it's definitely ours,
        // even if email was changed in the meantime (CP-464)
        !investmentInvitation?.hasUser &&
        investmentInvitation?.email &&
        currentUser?.email !== investmentInvitation?.email));

  // build default / fallback page title
  const DefaultPageTitle = useMemo(
    () => () => token ? <Translate name="investmentAmount.title" args={[token.name]} /> : <p></p>,
    [token],
  );

  // handle errors
  useEffect(() => {
    if (!error) return;

    if (!isLoggedIn) {
      dispatch(reLogin());
    }
  }, [dispatch, isLoggedIn, error]);

  // handle sidebar step changes
  useEffect(() => {
    if (step) {
      setWizardSteps(
        makeSidebarSteps(step, steps, setStep, isTransferInvitation ? transferSidebarMapping : sidebarMapping, token),
      );
    }
  }, [setWizardSteps, step, steps, setStep]);

  // handle tracking
  useEffect(() => {
    (async () => {
      if (trackingId) {
        try {
          await invitationsApi.invitationsTrackingInformationCreate({
            id: invitationId,
            invitationTrackingRequest: {
              trackingId,
            },
          });
        } catch (e) {
          // do nothing
        }
      }
    })();
  }, [step, invitationId, trackingId]);

  useEffect(() => {
    if (inactiveError) {
      setInvestmentError(inactiveError);
    }
  }, [inactiveError]);

  useEffect(() => {
    if (!step) return;

    const searchParams = new URLSearchParams(window.location.search);
    const currentStep = searchParams.get('page');

    if (currentStep === step) return;

    searchParams.set('page', step);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  }, [step]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        const searchParams = new URLSearchParams(location.search);
        const queryStep = searchParams.get('page');

        if (queryStep) {
          if (queryStep === StepEnum.QUESTIONNAIRE) {
            setQuestionnairePage(1, invitationId);
          }
          setStep(queryStep as StepEnum);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  const changeStep = useCallback(
    async (step: StepEnum | StepEnum[] | undefined) => {
      if (!step) setStep(undefined);
      const wizard = await wizardApi.wizardRetrieve({ code: invitationId });
      setSteps(wizard.steps);
      const stepsToChange = castArray(step);
      setStep(first(stepsToChange));
    },
    [setStep, invitationId],
  );

  // todo: investigate if necessary
  // when a step finishes
  const finalize = useCallback(
    (accessToken?: string) => {
      // maybe an access token has been returned from a step for auth
      if (accessToken) {
        dispatch(setAuthToken(accessToken));
      } else reload();
    },
    [dispatch, reload],
  );

  // remove finalize, remove param shouldCheckInBackend in changeStep, this is the proper method to call
  const nextStep = useCallback(() => {
    (async () => {
      const { steps: newSteps } = await wizardApi.wizardRetrieve({
        code: invitationId,
      });
      if (steps && newSteps) {
        const nextStep = getNextWithOverlap(
          step,
          steps.map(({ step }) => step),
          filter(newSteps, { revisitable: true }).map(({ step }) => step),
        );

        if (nextStep) setStep(nextStep);
        setSteps(newSteps);
      }
    })();
  }, [step, steps, wizardApi, invitationId, setStep, setSteps]);

  const lastRevisitableStep = useCallback(() => {
    if (steps) {
      const revisitableSteps = steps.filter((step) => !!step.revisitable);
      const currentStepIndex = revisitableSteps.findIndex((stepObj) => stepObj.step === step);
      const previousStep = revisitableSteps.slice(0, currentStepIndex).pop()?.step;

      return previousStep;
    }
    return undefined;
  }, [step, steps]);

  useOnlyOnce(() => {
    removeRegistrationLocalStorage();
  });

  // assign user to invitation when logged in, in case of missing user for invitation
  useEffect(() => {
    if (!step) return;

    if (!hasUser && isLoggedIn && !isAccessDenied && !loading && !inactiveError && !currentUser?.issuer) {
      setUserAssociationChecking(true);

      (async () => {
        await withApi(async () => {
          try {
            await invitationsApi.invitationsUserAssociationCreate({
              id: invitationId,
            });
            const invitationStorage = JSON.parse(getInvitationIdLocal(invitationId)) as LocalStorageInvitation;
            const newInvitationStorage: LocalStorageInvitation = {
              ...invitationStorage,
              investorId,
            };
            setInvitationIdLocal(invitationId, JSON.stringify(newInvitationStorage));

            reload();
          } catch (e) {
            if (e.status !== 403) {
              throw e;
            }

            // 403 investment allowed for specific person type only
            // TODO(mara-cashlink): do we need the the if block here or should BoxedInvestmentError decide everything?
            if (
              e.hasErrorCode('investment_for_natural_persons_only') ||
              e.hasErrorCode('investment_for_legal_persons_only')
            ) {
              setInvestmentError(e);
            }
          }
        });
        setUserAssociationChecking(false);
      })();
    } else setUserAssociationChecking(false);
  }, [
    hasUser,
    isLoggedIn,
    invitationId,
    withApi,
    invitationsApi,
    reload,
    step,
    isAccessDenied,
    currentUser,
    loading,
    inactiveError,
  ]);

  const extraErrorActions = useCallback(
    (action) => {
      if (action === 'logoutAndContinue') {
        reload();
        setInvestmentError(undefined);
      }
    },
    [reload],
  );

  // get component and title of current step
  const stepComponent = useMemo(() => stepMapping(step, isTransferInvitation), [step, isTransferInvitation]);

  useResetScrollOnChange(stepComponent);

  if (investmentError) {
    return <BoxedInvestmentError error={investmentError} extraErrorActions={extraErrorActions} />;
  }

  if (isAccessDenied)
    return (
      <InvestmentAccessDenied
        investmentInvitationEmail={investmentInvitation?.email}
        title={DefaultPageTitle}
        logout={() => {
          reset();
          dispatch(reLogin(AppType.INVESTMENT));
        }}
      />
    );

  let { Component, DoneComponent, investmentRequired = true } = stepComponent;

  if (
    !investmentInvitation ||
    (investmentRequired && !isTransferInvitation && !investmentId) ||
    isUserAssociationChecking
  )
    return null;

  const isCurrentStepDone = !!find(steps, { step, done: true });
  const isCurrentStepRevisitable = !!find(steps, { step, revisitable: true });

  return (
    <>
      <WizardContext.Provider
        value={{
          DefaultPageTitle,
          loading,
          resourceId: invitation?.id || invitationId,
          finalize,
          nextStep,
          lastRevisitableStep,
          isStepDone: isCurrentStepDone,
          isStepRevisitable: isCurrentStepRevisitable,
          investmentId: investmentId || '',
          changeStep: changeStep,
          isTransferInvitation,
          isRegisterOnlyFlow: false,
          onCloseProcessOverviewOverlay,
          showProcessOverview,
        }}
      >
        <InvestmentSummary token={token} initialData={investmentInvitation} step={step}>
          {(isCurrentStepDone && DoneComponent && <DoneComponent />) || <Component />}
        </InvestmentSummary>
      </WizardContext.Provider>
    </>
  );
};

export default Investment;
