import React, { FunctionComponent } from 'react';

interface ProductsListProps {
  products: string[];
}

const ProductsList: FunctionComponent<ProductsListProps> = ({ products }) => (
  <p>
    {products.map((product, i, arr) => (
      <span key={i}>
        {`${product}`}
        {arr.length - 1 === i ? '' : ', '}
      </span>
    ))}
  </p>
);

export default ProductsList;
