import React, { useContext, FunctionComponent } from 'react';
import useRegisterAccountCreation from 'subapps/investment/pages/register/steps/account-setup/use-register-account-creation';
import AccountSetup from 'libraries/wizard/components/account-setup';
import useProcessOverview from 'subapps/investment/pages/register/steps/account-setup/use-process-overview';
import WizardContext from 'src/libraries/wizard/wizard-context';

const AccountSetupStep: FunctionComponent<{}> = () => {
  const accountCreationProps = useRegisterAccountCreation();
  const overviewSteps = useProcessOverview();

  const { onCloseProcessOverviewOverlay, showProcessOverview } = useContext(WizardContext);

  return (
    <AccountSetup
      {...accountCreationProps}
      {...overviewSteps}
      onCloseProcessOverviewOverlay={onCloseProcessOverviewOverlay}
      showProcessOverview={showProcessOverview}
    />
  );
};

export default AccountSetupStep;
