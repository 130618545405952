import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import makeLink from 'helper/make-link';
import { isMainRouteActive } from 'core/nav/helpers';

import SidebarNavGroup from 'ui/molecules/sidebar-group/sidebar-nav-group';

import SidebarNavLink from 'core/nav/sidebars/nav-list/nav-link';
import { NavigationItem } from 'core/nav/types';

const SidebarNavList: FunctionComponent<{
  navigationItems: NavigationItem[];
  className?: string;
}> = ({ navigationItems, className }) => {
  const location = useLocation();

  return (
    <SidebarNavGroup className={className}>
      {navigationItems.map(({ route, subRoutes, label, params }) => (
        <SidebarNavLink
          key={makeLink(route, params)}
          link={makeLink(route, params)}
          active={isMainRouteActive(route, subRoutes, location.pathname)}
        >
          {label}
        </SidebarNavLink>
      ))}
    </SidebarNavGroup>
  );
};

export default SidebarNavList;
