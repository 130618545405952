import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import { RegisteredAddress } from 'apps/issuer/pages/investor-details/edit-modals/add-identification-modal';
import AddIdentificationPersonalData from './personal-data';
import AddIdentificationDocumentData from './document-data';
import AddIdentificationRegisteredAddress from './registered-address';
import AddIdentificationUploadLegitimation from './upload-legitimation';
import { CountryEnum, IdentificationDocumentTypeEnum } from 'src/api';

export interface AddIdentificationFormDataProps {
  AddIdentificationForm: any;
  changeAddress: boolean;
  registeredAddress: RegisteredAddress;
  documentValidFrom: Date | undefined;
  documentValidTo: Date | undefined;
  nationality: CountryEnum | undefined;
  onChangeNationality: (nationality: CountryEnum) => void;
  country: string | undefined;
  onChangeCountry: (country: string) => void;
  onChangeDocumentType: (documentType: IdentificationDocumentTypeEnum) => void;
}

const AddIdentificationFormData: FunctionComponent<AddIdentificationFormDataProps> = (props) => {
  const {
    AddIdentificationForm,
    changeAddress,
    registeredAddress,
    documentValidFrom,
    documentValidTo,
    nationality,
    onChangeNationality,
    country,
    onChangeCountry,
    onChangeDocumentType,
  } = props;

  return (
    <>
      <Hint variant="info">
        <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.hint" />
      </Hint>
      <AddIdentificationPersonalData
        AddIdentificationForm={AddIdentificationForm}
        nationality={nationality}
        onChangeNationality={(nationality: CountryEnum) => onChangeNationality(nationality)}
      />
      <AddIdentificationDocumentData
        AddIdentificationForm={AddIdentificationForm}
        documentValidFrom={documentValidFrom}
        documentValidTo={documentValidTo}
        onChangeDocumentType={(documentType: IdentificationDocumentTypeEnum) => onChangeDocumentType(documentType)}
      />
      <AddIdentificationRegisteredAddress
        AddIdentificationForm={AddIdentificationForm}
        registeredAddress={registeredAddress}
        changeAddress={changeAddress}
        country={country}
        onChangeCountry={(country: string) => onChangeCountry(country)}
      />
      <AddIdentificationUploadLegitimation AddIdentificationForm={AddIdentificationForm} />
    </>
  );
};

export default AddIdentificationFormData;
