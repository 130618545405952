import moment from 'moment';
import { FilterValues, InputValuesType } from './types';

export const filterOutEmptyAndFormat: (obj: { [key: string]: { fieldName: string; value: any } }) => {
  [key: string]: any;
} = (obj) =>
  Object.values(obj).reduce((previous, fieldState) => {
    const hasValue = (() => {
      if (fieldState.value !== undefined) {
        if (Array.isArray(fieldState.value) && fieldState.value.length) {
          return fieldState.value.some((v: any) => v !== undefined && v !== '');
        } else if (Array.isArray(fieldState.value) && !fieldState.value.length) {
          return false;
        } else {
          return fieldState.value !== undefined && fieldState.value !== '';
        }
      }

      return false;
    })();

    const fieldValue =
      Array.isArray(fieldState.value) && fieldState.value.length === 1 ? fieldState.value[0] : fieldState.value;

    return {
      ...previous,
      ...(hasValue ? { [fieldState.fieldName]: fieldValue } : {}),
    };
  }, {});

export const filterPossiblyNullStringValues: (
  props: { [key: string]: any },
  possiblyNullFilterName: string,
) => {
  formattedFilter: FilterValues;
  restOfFilters: FilterValues;
} = (props, possiblyNullFilterName) => {
  const { [possiblyNullFilterName]: filterVal, ...restOfFilters } = props;

  const isValidValuesArray = Array.isArray(filterVal) && filterVal?.length > 0;
  const areNullVals = isValidValuesArray ? filterVal.some((val) => val === null) : filterVal === null;
  const notNullVals = isValidValuesArray ? filterVal.filter((val) => val !== null) : filterVal;
  const formattedFilter: FilterValues = {};

  if (areNullVals) {
    formattedFilter[possiblyNullFilterName + 'Isnull'] = true;
  }
  if (
    (isValidValuesArray && Array.isArray(notNullVals) && notNullVals.length > 0) ||
    (!Array.isArray(filterVal) && notNullVals)
  ) {
    formattedFilter[possiblyNullFilterName] = notNullVals;
  }

  return { formattedFilter, restOfFilters };
};

export const convertStringToDate: (val: string) => Date = (val) => {
  const momentDateFormat = moment(val, 'DD-MM-YYYY', true);
  return momentDateFormat.toDate();
};

export const checkIfStringIsDate: (val: string) => boolean = (val) => {
  const momentDateFormat = moment(val, 'DD-MM-YYYY', true);
  return momentDateFormat.isValid();
};

export const fromStringToValue: (val: string) => any = (val) => {
  return val === 'null' ? null : val;
};

export const fromValueToString: (val: InputValuesType) => string = (val) => {
  return val === null ? 'null' : val.toString();
};
