import {
  BaseInputProps,
  CheckableInputProps,
  FormOptions,
  FormState,
  InputOptions,
  Omit,
  StateShape,
  StateValues,
  useFormState,
} from 'react-use-form-state';

import get from 'lodash/get';
import every from 'lodash/every';
import { useEffect } from 'react';

export interface FormCallbacks<T> {
  submit(onSubmit: (values: StateValues<T>) => void): { onSubmit: (event?: any) => void };
}

export interface ChangeWithValidationState<T> {
  values?: StateValues<T>;
  isValid?: boolean;
}

export interface ValidationProps {
  valid?: boolean;
}

export interface ValidatedInputs<T, Name extends keyof T> {
  password(name: Name, options?: Omit<InputOptions<T, Name>, 'name'>): BaseInputProps<T> & ValidationProps;

  text(name: Name, options?: Omit<InputOptions<T, Name>, 'name'>): BaseInputProps<T> & ValidationProps;

  select(name: Name, options?: Omit<InputOptions<T, Name>, 'name'>): Omit<BaseInputProps<T>, 'type'> & ValidationProps;

  number(name: Name, options?: Omit<InputOptions<T, Name>, 'name'>): BaseInputProps<T> & ValidationProps;

  radio(name: Name, options?: any): BaseInputProps<T> & ValidationProps;

  email(name: Name, options?: Omit<InputOptions<T, Name>, 'name'>): BaseInputProps<T> & ValidationProps;

  checkbox(name: Name): CheckableInputProps<T>;
}

const isFieldValid = <T extends {}>(formState: FormState<T>, name: keyof T) =>
  formState.validity[name] === true ? true : formState.touched[name] && !formState.validity[name] ? false : undefined;

const shouldValidate = <T extends {}>(fieldName: keyof T, validateFields: (keyof T)[]) =>
  validateFields.includes(fieldName) || false;

const isValid = <T extends {}>(
  isEditMode: boolean,
  validateFields: (keyof T)[],
  state: FormState<T>,
  initialState?: Partial<T> | null,
) =>
  every(
    validateFields.map((field) => {
      const hasChanged = get(initialState, field, '') !== get(state.values, field, '');

      if (isEditMode) {
        if (hasChanged) {
          return state.validity[field];
        }

        return true;
      }

      return state.validity[field];
    }),
  );

export const useForm = <T extends StateShape<T>>(
  formOptions?: Partial<
    FormOptions<T> & {
      validateFields?: (keyof T)[];
      isEditMode?: boolean;
      onChangeWithValidation?: (state: ChangeWithValidationState<T>) => void;
    }
  >,
  initialState?: Partial<T> | null,
): [FormState<T> & { isValid: boolean }, ValidatedInputs<T, keyof T> & FormCallbacks<T>] => {
  const [state, inputs] = useFormState<T>(initialState, formOptions);

  const validateFields = get<(keyof T)[]>(formOptions as any, 'validateFields', []);
  const isEditMode = get(formOptions, 'isEditMode', false);

  const valid = isValid(isEditMode, validateFields, state, initialState || {});

  const onChangeWithValidation = formOptions?.onChangeWithValidation;

  useEffect(() => {
    if (onChangeWithValidation) {
      onChangeWithValidation({
        values: state.values,
        isValid: valid,
      });
    }
  }, [state.values, valid, onChangeWithValidation]);

  return [
    {
      ...state,
      isValid: valid,
    },
    {
      password: (name: keyof T, options?: InputOptions<T, keyof T>) => ({
        ...inputs.password({ ...(options || {}), name }),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      text: (name: keyof T, options?: InputOptions<T, keyof T>) => ({
        ...inputs.text({ ...(options || {}), name }),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      select: (name: keyof T, options?: InputOptions<T, keyof T>) => ({
        ...inputs.select({ ...(options || {}), name }),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      number: (name: keyof T, options?: InputOptions<T, keyof T>) => ({
        ...inputs.number({ ...(options || {}), name }),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      radio: (name: keyof T, options?: any) => ({
        // @ts-ignore
        ...inputs.radio(name, options),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      email: (name: keyof T, options?: InputOptions<T, keyof T>) => ({
        ...inputs.email({ ...(options || {}), name }),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      checkbox: (name: keyof T) => ({
        ...inputs.checkbox(name),
        required: shouldValidate(name, validateFields),
        valid: shouldValidate(name, validateFields) ? isFieldValid(state, name) : undefined,
      }),
      submit: (onSubmit: (values: StateValues<T>) => void) => ({
        onSubmit: (e: Event) => {
          if (e) e.preventDefault();

          if (valid) {
            onSubmit(state.values);
          }
        },
      }),
    },
  ];
};

export default useForm;
