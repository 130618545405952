import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import VoucherSteps, { Step } from './voucher-steps';
import VoucherUsesTotal from './voucher-uses-total';
import VoucherUsesByInvestor from './voucher-uses-investor';
import ProductValidity from './product-validity';
import { AdminVoucherDetail, AdminVoucherStep, DistributionPlatformPermissionEnum } from 'api';
import Hint from 'ui/atoms/hint';
import Section from 'ui/atoms/section';
import { useCreateVoucher } from './hooks';
import { useCurrentUserSelector } from 'core/auth/hooks';

export interface VoucherFields {
  distributionPlatformId: string;
  voucherName: string;
  voucherCode: string;
  numberOfUsesTotal: string;
  numberOfUsesByInvestor: string;
  validityProducts: string;
}

export interface VoucherModalProps {
  voucher?: AdminVoucherDetail;
  isOpen?: boolean;
  isEditMode?: boolean;
  createdSteps?: AdminVoucherStep[];
  onModalClose?: () => void;
  onVoucherChanged: () => void;
}

const VoucherForm = makeModalForm<VoucherFields>();

const VoucherModal: FunctionComponent<VoucherModalProps> = (props) => {
  const { voucher, isOpen, isEditMode = false, createdSteps, onModalClose, onVoucherChanged } = props;

  const {
    isModalOpen,
    error,
    createVoucherValues,
    onSubmit,
    onClose,
    setIsModalOpen,
    setCreateVoucherValues,
    setNoOfUsesTotalAmount,
    setNoOfUsesByInvestorAmount,
    setSteps,
    setProductsValidity,
  } = useCreateVoucher(isOpen, isEditMode, voucher, onVoucherChanged, onModalClose);

  const { currentUser } = useCurrentUserSelector();

  const distributionPlatformsWithPermission = currentUser?.associatedDistributionPlatforms?.filter(
    (platform) => !!platform.permissions.find((perm) => perm === DistributionPlatformPermissionEnum.MANAGE_VOUCHERS),
  );

  if (!distributionPlatformsWithPermission?.length) return null;

  return (
    <>
      {!isEditMode && (
        <Button variant="primary" onClick={() => setIsModalOpen(true)}>
          <Translate name="dashboardIssuerVouchers.create" />
        </Button>
      )}
      {isModalOpen && (
        <Modal
          size="large"
          onClose={() => {
            if (onModalClose) onModalClose();
            onClose();
          }}
        >
          <VoucherForm
            onChange={(values: StateValues<VoucherFields>) => {
              setCreateVoucherValues({ ...createVoucherValues, ...values });
            }}
            i18nKey="createVoucherForm"
            initial={{
              distributionPlatformId: createVoucherValues.distributionPlatformId,
              voucherName: createVoucherValues.voucherName,
              voucherCode: createVoucherValues.voucherCode,
              numberOfUsesTotal: createVoucherValues.numberOfUsesTotal,
              numberOfUsesByInvestor: createVoucherValues.numberOfUsesByInvestor,
              validityProducts: createVoucherValues.validityProducts,
            }}
          >
            <ModalHeader>
              {isEditMode ? (
                <Translate name="dashboardIssuerVoucherDetails.editVoucher" />
              ) : (
                <Translate name="dashboardIssuerVouchers.create" />
              )}
            </ModalHeader>
            <ModalContent>
              <Section spacing="medium">
                {!!distributionPlatformsWithPermission?.length && (
                  <VoucherForm.Group name="distributionPlatformId" required={true}>
                    <VoucherForm.Select
                      options={distributionPlatformsWithPermission.map(({ id, platformName }) => ({
                        value: id,
                        label: platformName,
                      }))}
                      portalTarget={document.body}
                    ></VoucherForm.Select>
                  </VoucherForm.Group>
                )}
                <VoucherForm.Group name="voucherName" required={true}>
                  <VoucherForm.Input autoFocus={true} />
                </VoucherForm.Group>

                <VoucherForm.Group name="voucherCode" required={true}>
                  <VoucherForm.Input />
                </VoucherForm.Group>
              </Section>

              <VoucherSteps onChangeSteps={(steps: Step[]) => setSteps(steps)} createdSteps={createdSteps} />
              <VoucherUsesTotal
                Form={VoucherForm}
                values={createVoucherValues}
                numberOfUsesTotalAmount={voucher?.numberOfUses}
                onChangeUsesTotal={(noOfUses, amount) => {
                  setCreateVoucherValues({
                    ...createVoucherValues,
                    numberOfUsesTotal: noOfUses,
                  });
                  setNoOfUsesTotalAmount(amount);
                }}
              />
              <VoucherUsesByInvestor
                Form={VoucherForm}
                values={createVoucherValues}
                numberOfUsesByInvestorAmount={voucher?.numberOfUsesInvestor}
                onChangeUsesByInvestor={(noOfUses, amount) => {
                  setCreateVoucherValues({
                    ...createVoucherValues,
                    numberOfUsesByInvestor: noOfUses,
                  });
                  setNoOfUsesByInvestorAmount(amount);
                }}
              />
              <ProductValidity
                Form={VoucherForm}
                values={createVoucherValues}
                selectedValidityProducts={voucher?.productNames}
                selectedDistributionPlatform={createVoucherValues.distributionPlatformId}
                onChangeValidity={(products) => setProductsValidity(products)}
              />
              {error && (
                <Hint variant="danger">
                  <Translate name={error} />
                </Hint>
              )}
            </ModalContent>
            <ModalFooter>
              <ModalFooterButtons
                actionButtons={[
                  {
                    name: 'cancel',
                    content: <Translate name="common.cancel" />,
                    size: 'large',
                    onClick: onClose,
                  },
                  {
                    name: 'add',
                    content: isEditMode ? (
                      <Translate name="dashboardIssuerVoucherDetails.editVoucher" />
                    ) : (
                      <Translate name="dashboardIssuerVouchers.create" />
                    ),
                    variant: 'primary',
                    type: 'submit',
                    size: 'large',
                    onClick: onSubmit,
                  },
                ]}
              />
            </ModalFooter>
          </VoucherForm>
        </Modal>
      )}
    </>
  );
};

export default VoucherModal;
