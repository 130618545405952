import { FrontendStatusEnum, InvestmentAcquisitionTypeRecipientEnum, StepEnum } from 'api/models';
import { InvestorInvestmentStatusEnum } from '../ui/types/investment';
import { assertUnreachable } from '../ui/helper/unreachable';
import { toNumber } from 'ui/helper/money';
import { ColorVariants } from 'ui/styles/themes/color-variants';

export const getIssuerInvestmentStatusVariant = (status: FrontendStatusEnum): ColorVariants => {
  switch (status) {
    case FrontendStatusEnum.FINISHED:
    case FrontendStatusEnum.SECURITIES_TRANSFERRED:
    case FrontendStatusEnum.TRANSACTION_SUCCEEDED:
    case FrontendStatusEnum.SUCCESS_FULLY_TRANSFERED:
    case FrontendStatusEnum.SUCCESS_REDEEMED:
    case FrontendStatusEnum.HANDOVER:
      return 'success';
    case FrontendStatusEnum.ABORTED:
    case FrontendStatusEnum.REJECTED:
    case FrontendStatusEnum.CANCELED_KYC_INSUFFICIENT:
    case FrontendStatusEnum.CANCELED_PAYMENT_MISSING:
    case FrontendStatusEnum.CANCELLATION:
    case FrontendStatusEnum.TRANSACTION_FAILED:
      return 'danger';
    case FrontendStatusEnum.IDENTIFICATION_PENDING:
    case FrontendStatusEnum.IDENTIFICATION_COMPLIANCE_PENDING:
    case FrontendStatusEnum.ACCEPTANCE_MISSING:
    case FrontendStatusEnum.PAYMENT_PENDING:
    case FrontendStatusEnum.WALLET_SETUP_PENDING:
    case FrontendStatusEnum.SECURITY_TRANSFER_MISSING:
    case FrontendStatusEnum.ADDITIONAL_INFORMATION_PENDING:
    case FrontendStatusEnum.TRANSACTION_PENDING:
    case FrontendStatusEnum.SUCCESS_PARTIALLY_TRANSFERED:
      return 'warning';
  }
};

export const getInvestorInvestmentStatusVariant = (status: InvestorInvestmentStatusEnum): ColorVariants => {
  switch (status) {
    case InvestorInvestmentStatusEnum.Finished:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToManagedWallet:
    case InvestorInvestmentStatusEnum.SecuritiesTransferredToCustomWallet:
    case InvestorInvestmentStatusEnum.SuccessFullyTransfered:
    case InvestorInvestmentStatusEnum.SuccessRedeemed:
    case InvestorInvestmentStatusEnum.Handover:
      return 'success';
    case InvestorInvestmentStatusEnum.PreInvestmentAmount:
    case InvestorInvestmentStatusEnum.InvestmentAmountMissing:
    case InvestorInvestmentStatusEnum.QuestionnaireMissing:
    case InvestorInvestmentStatusEnum.VibMissing:
    case InvestorInvestmentStatusEnum.OfferConfirmationMissing:
    case InvestorInvestmentStatusEnum.BenefitingPersonsMissing:
    case InvestorInvestmentStatusEnum.IdentificationMissing:
    case InvestorInvestmentStatusEnum.IdentificationPending:
    case InvestorInvestmentStatusEnum.WaitingAcceptance:
    case InvestorInvestmentStatusEnum.PaymentOutstanding:
    case InvestorInvestmentStatusEnum.WalletSetupMissing:
    case InvestorInvestmentStatusEnum.WaitingSecurityTransfer:
    case InvestorInvestmentStatusEnum.WalletSetupPending:
    case InvestorInvestmentStatusEnum.AdditionalInformationMissing:
    case InvestorInvestmentStatusEnum.SuccessPartiallyTransfered:
      return 'info';
    case InvestorInvestmentStatusEnum.IdentificationRejected:
    case InvestorInvestmentStatusEnum.IdentificationFailed:
    case InvestorInvestmentStatusEnum.Canceled:
      return 'warning';
  }
};

interface GetInvestorInvestmentStatusInterface {
  hasTokenTransactionHash: boolean | undefined;
  isWalletManaged: boolean | undefined;
  hasWalletAddress: boolean | undefined;
  isTokenized: boolean | undefined;
  step: StepEnum;
  investmentTotal: number;
  investmentTotalFulfilled: number;
  acquisitionType: InvestmentAcquisitionTypeRecipientEnum | undefined;
  isRedeemed: boolean | undefined;
}

export const getInvestorInvestmentStatus = ({
  hasTokenTransactionHash,
  isWalletManaged,
  hasWalletAddress,
  isTokenized,
  step,
  investmentTotal,
  investmentTotalFulfilled,
  acquisitionType,
  isRedeemed,
}: GetInvestorInvestmentStatusInterface): InvestorInvestmentStatusEnum => {
  switch (step) {
    case StepEnum.ACCOUNT_SETUP:
    case StepEnum.UNCONFIRMED:
      return InvestorInvestmentStatusEnum.PreInvestmentAmount;
    case StepEnum.INVESTMENT_AMOUNT:
      return InvestorInvestmentStatusEnum.InvestmentAmountMissing;
    case StepEnum.QUESTIONNAIRE:
      return InvestorInvestmentStatusEnum.QuestionnaireMissing;
    case StepEnum.VIB:
      return InvestorInvestmentStatusEnum.VibMissing;
    case StepEnum.OFFER_CREATION:
      return InvestorInvestmentStatusEnum.OfferConfirmationMissing;
    case StepEnum.BENEFICIARY:
      return InvestorInvestmentStatusEnum.BenefitingPersonsMissing;
    case StepEnum.IDENTIFICATION:
    case StepEnum.RESUME_IDENTIFICATION:
      return InvestorInvestmentStatusEnum.IdentificationMissing;
    case StepEnum.IDENTIFICATION_REJECTED:
      return InvestorInvestmentStatusEnum.IdentificationRejected;
    case StepEnum.IDENTIFICATION_FAILED:
      return InvestorInvestmentStatusEnum.IdentificationFailed;
    case StepEnum.WAITING_KYC:
      return InvestorInvestmentStatusEnum.IdentificationPending;
    case StepEnum.WAITING_ACCEPTANCE:
    case StepEnum.WAITING_COMPLIANCE:
      return InvestorInvestmentStatusEnum.WaitingAcceptance;
    case StepEnum.PAYMENT:
      return InvestorInvestmentStatusEnum.PaymentOutstanding;
    case StepEnum.SELECT_WALLET:
    case StepEnum.SETUP_MOBILE_WALLET:
    case StepEnum.PREPARE_ASSET:
    case StepEnum.CONFIRM_WALLET:
      return InvestorInvestmentStatusEnum.WalletSetupMissing;
    case StepEnum.ADDITIONAL_INFORMATION:
      return InvestorInvestmentStatusEnum.AdditionalInformationMissing;
    case StepEnum.CANCELED:
      return InvestorInvestmentStatusEnum.Canceled;

    case StepEnum.FINAL:
      if (isRedeemed) {
        return InvestorInvestmentStatusEnum.SuccessRedeemed;
      }
      if (isTokenized) {
        if (!hasTokenTransactionHash) {
          if (!hasWalletAddress) {
            return InvestorInvestmentStatusEnum.WalletSetupPending;
          }
          return InvestorInvestmentStatusEnum.WaitingSecurityTransfer;
        }
        if (toNumber(investmentTotal) !== toNumber(investmentTotalFulfilled)) {
          if (toNumber(investmentTotal) === 0) {
            return InvestorInvestmentStatusEnum.SuccessFullyTransfered;
          }
          return InvestorInvestmentStatusEnum.SuccessPartiallyTransfered;
        }
        if (acquisitionType === InvestmentAcquisitionTypeRecipientEnum.HANDOVER) {
          return InvestorInvestmentStatusEnum.Handover;
        }

        if (isWalletManaged) {
          return InvestorInvestmentStatusEnum.SecuritiesTransferredToManagedWallet;
        }
        return InvestorInvestmentStatusEnum.SecuritiesTransferredToCustomWallet;
      }
      return InvestorInvestmentStatusEnum.Finished;
  }

  // make sure we covered all cases
  return assertUnreachable(step);
};
