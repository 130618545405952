import { Icons } from 'ui/assets/icons/types';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import Padding from 'ui/types/padding';
import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Icon from '../icon';
import Segment from '../segment';
import { Colors, resolveColor } from 'src/ui/styles/utils/colors';

export interface HintProps {
  $spacing?: Spacing;
  variant?: ColorVariants;
  inverted?: boolean;
  padded?: Padding;
  $color?: Colors;
}

export interface HintIconProps {
  name: Icons;
  $variant: ColorVariants;
}

function getDefaultStyle({ theme, $color }: ThemedStyledProps<HintProps, DefaultTheme>) {
  const themeColor = resolveColor(theme, $color);

  return css`
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${themeColor};
    border-color: ${themeColor};
    padding: 1.5rem 1rem;
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<HintProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css<HintProps>`
    margin-bottom: ${theme.spacing[$spacing]}rem;
  `;
}

function getHintIconStyle() {
  return css`
    flex-shrink: 0;
    margin-right: 1rem;
    align-self: flex-start;
  `;
}

function getColor({ $variant }: ThemedStyledProps<HintIconProps, DefaultTheme>) {
  if (!$variant) return null;

  switch ($variant) {
    case 'success':
      return css`
        fill: ${({ theme }) => theme.colors.greenMain};
      `;
    case 'danger':
      return css`
        fill: ${({ theme }) => theme.colors.redDark};
      `;
    case 'warning':
    case 'info':
      return css`
        fill: #303539;
      `;
  }
}

export const Hint = styled(Segment)<HintProps>`
  ${getDefaultStyle};
  ${getSpacing};
`;

export const HintIcon = styled(Icon)<HintIconProps>`
  ${getHintIconStyle};
  ${getColor};
`;
