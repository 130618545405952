import React, { FunctionComponent } from 'react';
import getElementType, { As } from 'ui/helper/get-element-type';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';

export interface ListPointProps extends As {
  /** List point size limits the child string to a length max 3 characters */
  children?: string;

  /** Additional classes. */
  className?: string;

  /**
   * Color of the list point.
   *
   * @expandType true
   */
  color?: Colors;

  /**
   * A list point may be one of a variety of visual variants.
   *
   * @expandType true
   */
  variant?: ColorVariants;

  /** List point can formatted to appear circular. */
  circular?: boolean;

  /** Formatted to have its colors inverted for contrast. */
  inverted?: boolean;

  /** Fitted, without space to left or right of list point. */
  fitted?: boolean;

  /** Providing a `href` will render an `<a>` element, styled as a list point. */
  href?: string;
}

export const ListPoint: FunctionComponent<ListPointProps> = (props) => {
  const { children, circular, className, fitted, color = 'grayHover', inverted, variant, ...restProps } = props;

  const ListPointType = getElementType(ListPoint, props);

  return (
    <Styled.ListPoint
      $color={color}
      $variant={variant}
      $inverted={inverted}
      $fitted={fitted}
      $circular={circular}
      as={ListPointType}
      className={cx('list-point', className)}
      {...restProps}
    >
      {children}
    </Styled.ListPoint>
  );
};

export default ListPoint;
