import React, { FunctionComponent } from 'react';
import Spacing from 'ui/types/spacing';
import { DEVICES } from 'ui/styles/queries';
import { StyledGrid } from './styled';

export interface GridProps {
  /** Spacing at the bottom */
  spacing?: Spacing;

  /** Additional classes */
  className?: string;

  reverse?: boolean | Array<DEVICES>;
}

export const Grid: FunctionComponent<GridProps> = ({ children, reverse, spacing, className }) => {
  return (
    <StyledGrid $reverse={reverse} $spacing={spacing} className={className}>
      {children}
    </StyledGrid>
  );
};

export { DEVICES };

export default Grid;
