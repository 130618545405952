import { useEffect } from 'react';
import { serverConfigSelector } from './selectors';
import { useDispatch, useSelector } from 'store/hooks';
import { configFetch } from 'core/config/actions';
import localConfig from './local';

export function useServerConfigSelector() {
  return useSelector(serverConfigSelector);
}

export function useLoadServerConfig() {
  const dispatch = useDispatch();
  const { config, loading, loaded, error } = useServerConfigSelector();

  useEffect(() => {
    dispatch(configFetch());
  }, []);

  return { config, loading, loaded, error };
}

export function useLocalConfig() {
  return localConfig;
}
