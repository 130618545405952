import React from 'react';
import Documents from './documents';
import Faq from './faq';
import InvestmentDetails from './investment-details';
import InviteFriends from './invite-friends';
import Overview from './overview';
import Portofolio from './portofolio';
import ProductDetails from './product-details';
import Settings from './settings';
import Transactions from './transactions';
import TransactionDetails from './transactions-details';
import ContinueInvestments from './continue-investments';
import LossAbility from './loss-ability';
import Translate from 'ui/atoms/translate';
import type { NavigationItem } from 'core/nav/types';
import { ComponentRouting, RouteDefinitions } from 'core/routing/types';

export const ROUTES = {
  root: 'root',
  overview: 'overview',
  portofolio: 'portofolio',
  product: 'product',
  investment: 'investment',
  continueInvestments: 'continueInvestments',
  transactions: 'transactions',
  transactionDetails: 'transactionDetails',
  lossAbility: 'lossAbility',
  documents: 'documents',
  inviteFriends: 'inviteFriends',
  settings: 'settings',
  faq: 'faq',
};

export const OVERVIEW = {
  path: '/dashboard/investorOverview',
  exact: true,
  condition: () => true,
};

export const PORTOFOLIO = {
  path: '/dashboard/investorPortfolio',
  exact: true,
  condition: () => true,
};

export const PRODUCT = {
  path: '/dashboard/investorProduct/:id',
  exact: true,
  condition: () => true,
};

export const INVESTMENT = {
  path: '/dashboard/investorInvestment/:id',
  exact: true,
  condition: () => true,
};

export const CONTINUE_INVESTMENTS = {
  path: '/dashboard/investorContinueInvestments',
  exact: true,
  condition: (props: any) => !!props?.hasInvestments,
};

export const LOSS_ABILITY = {
  path: '/dashboard/investorLossAbility',
  exact: true,
  condition: (props: any) => !!props?.config.features.ecsp || !!props?.hasEcspInvestments,
};

export const TRANSACTIONS = {
  path: '/dashboard/investorTransactions',
  exact: true,
  condition: (props: any) => !!props?.investorHasManagedWallet && !!props?.hasInvestments,
};

export const TRANSACTION_DETAILS = {
  path: '/dashboard/investorTransactionDetails/:id',
  exact: true,
  condition: (props: any) => !!props?.investorHasManagedWallet && !!props?.hasInvestments,
};

export const DOCUMENTS = {
  path: '/dashboard/investorDocuments',
  exact: true,
  condition: () => true,
};

export const INVITE_FRIENDS = {
  path: '/dashboard/investorInviteFriends',
  exact: true,
  condition: (props: any) => !!props?.investor?.voucherCode,
};

export const SETTINGS = {
  path: '/dashboard/investorSettings',
  exact: true,
  condition: () => true,
};

export const FAQ = {
  path: '/dashboard/investorFaq',
  exact: true,
  condition: () => true,
};

export const INVESTOR_ROUTES: RouteDefinitions = {
  [ROUTES.overview]: OVERVIEW,
  [ROUTES.portofolio]: PORTOFOLIO,
  [ROUTES.product]: PRODUCT,
  [ROUTES.investment]: INVESTMENT,
  [ROUTES.continueInvestments]: CONTINUE_INVESTMENTS,
  [ROUTES.transactions]: TRANSACTIONS,
  [ROUTES.transactionDetails]: TRANSACTION_DETAILS,
  [ROUTES.lossAbility]: LOSS_ABILITY,
  [ROUTES.documents]: DOCUMENTS,
  [ROUTES.inviteFriends]: INVITE_FRIENDS,
  [ROUTES.settings]: SETTINGS,
  [ROUTES.faq]: FAQ,
};

export const INVESTOR_PATHS = Object.values(INVESTOR_ROUTES).map((route) => route.path);

export const INVESTOR_NAVIGATION: NavigationItem[] = [
  {
    key: ROUTES.overview,
    route: OVERVIEW,
    label: <Translate name="dashboard.investorOverview" />,
  },
  {
    key: ROUTES.portofolio,
    route: PORTOFOLIO,
    label: <Translate name="dashboard.investorPortfolio" />,
    subRoutes: [
      {
        key: ROUTES.product,
        route: PRODUCT,
      },
      {
        key: ROUTES.investment,
        route: INVESTMENT,
      },
    ],
  },
  {
    key: ROUTES.continueInvestments,
    route: CONTINUE_INVESTMENTS,
    label: <Translate name="dashboard.investorContinueInvestments" />,
  },
  {
    key: ROUTES.lossAbility,
    route: LOSS_ABILITY,
    label: <Translate name="dashboard.investorLossAbility" />,
  },
  {
    key: ROUTES.transactions,
    route: TRANSACTIONS,
    label: <Translate name="dashboard.investorTransactions" />,
    subRoutes: [
      {
        key: ROUTES.transactionDetails,
        route: TRANSACTION_DETAILS,
      },
    ],
  },
  {
    key: ROUTES.documents,
    route: DOCUMENTS,
    label: <Translate name="dashboard.investorDocuments" />,
  },
  {
    key: ROUTES.inviteFriends,
    route: INVITE_FRIENDS,
    label: <Translate name="dashboard.investorInviteFriends" />,
  },
  {
    key: ROUTES.settings,
    route: SETTINGS,
    label: <Translate name="dashboard.investorSettings" />,
  },
  {
    key: ROUTES.faq,
    route: FAQ,
    label: <Translate name="dashboard.investorFaq" />,
  },
];

export const INVESTOR_COMPONENT_ROUTING: ComponentRouting = {
  [ROUTES.overview]: Overview,
  [ROUTES.portofolio]: Portofolio,
  [ROUTES.product]: ProductDetails,
  [ROUTES.investment]: InvestmentDetails,
  [ROUTES.continueInvestments]: ContinueInvestments,
  [ROUTES.transactions]: Transactions,
  [ROUTES.transactionDetails]: TransactionDetails,
  [ROUTES.lossAbility]: LossAbility,
  [ROUTES.documents]: Documents,
  [ROUTES.inviteFriends]: InviteFriends,
  [ROUTES.settings]: Settings,
  [ROUTES.faq]: Faq,
};
