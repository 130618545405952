import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';
import ProfileAccess from 'ui/molecules/profile-access';
import ProgressSteps, { ProgressStep } from 'core/layout/wizard/progress-steps';
import * as Styled from './styled';
import LanguageSelectorNavigationItem from 'ui/molecules/language-selector-navigation-item';
import PlatformSelectorItem from 'core/nav/navbar/platform-selector-item';

export interface WizardNavBarDesktopProps {
  showInvestorOverview?: boolean;
  progressSteps: ProgressStep[];
  registerOnlyFlow?: boolean;
  isUserConfirmed: boolean;
  isLoggedIn: boolean;
  activeLocale: string;
  switchLocale: (locale: string) => void;
  onLogout: () => void;
}

const WizardNavBarDesktop: FunctionComponent<WizardNavBarDesktopProps> = (props) => {
  const {
    showInvestorOverview,
    progressSteps,
    registerOnlyFlow,
    isUserConfirmed,
    isLoggedIn,
    activeLocale,
    switchLocale,
    onLogout,
  } = props;

  return (
    <Styled.NavBarDesktop>
      <Styled.PlatformLogo logoType="inverted" variant="investment" spacing="none" />
      {progressSteps.length > 0 && <ProgressSteps steps={progressSteps} />}
      <Styled.ActionMenu>
        <PlatformSelectorItem />
        {(!isLoggedIn || !isUserConfirmed) && (
          <LanguageSelectorNavigationItem active={activeLocale} onLocaleChange={switchLocale} />
        )}
        {isLoggedIn && isUserConfirmed && (
          <ProfileAccess
            activeLocale={activeLocale}
            showInvestorOverview={showInvestorOverview}
            registerOnlyFlow={registerOnlyFlow}
            onLocaleChange={switchLocale}
            onLogout={() => onLogout()}
          />
        )}
        {!isLoggedIn && (
          <Styled.LoginRouterLink route={AUTH_ROUTES.login} className="nav-link" fluid>
            <Translate name="common.login">Login</Translate>
          </Styled.LoginRouterLink>
        )}
      </Styled.ActionMenu>
    </Styled.NavBarDesktop>
  );
};

export default WizardNavBarDesktop;
