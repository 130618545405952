import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { AddIdentificationFields } from '../..';
import Section from 'ui/atoms/section';
import Date from 'ui/atoms/date';
import DocumentModal from 'src/libraries/document-modal';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import Grid, { Col } from 'ui/atoms/grid';

export interface ConfirmIdentificationDocumentDataProps {
  identification: AddIdentificationFields;
}

const ConfirmIdentificationDocumentData: FunctionComponent<ConfirmIdentificationDocumentDataProps> = (props) => {
  const { identification } = props;

  return (
    <Section spacing="medium">
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitleIdentification" />
        </strong>
      </p>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.identification.legitimationProtocol" />
        </Col>
        <Col width={6} spacing="small">
          <Translate
            name="dashboardInvestorSettings.identification.viewDocument"
            args={[
              (text: string, key) => {
                if (!identification?.legitimationProtocol) return;
                return (
                  <DocumentModal key={key} file={URL.createObjectURL(identification?.legitimationProtocol)}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.identification.documentId" />
        </Col>
        <Col width={6} spacing="small">
          {identification.documentId}
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.identification.documentType" />
        </Col>
        <Col width={6} spacing="small">
          <Translate
            name={`identificationDocumentType.${snakeToCamelCaseWithNumbers(
              identification.documentType.toLowerCase(),
            )}`}
          />
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.identification.validationInterval" />
        </Col>
        <Col width={6} spacing="small">
          <>
            <Date>{identification.documentValidFrom}</Date>-<Date>{identification.documentValidTo}</Date>
          </>
        </Col>
      </Grid>
      <Grid>
        <Col width={6} spacing="small">
          <Translate name="dashboardInvestorSettings.identification.issuingOffice" />
        </Col>
        <Col width={6} spacing="small">
          {identification.issuingOffice}
        </Col>
      </Grid>
    </Section>
  );
};

export default ConfirmIdentificationDocumentData;
