import { query } from 'ui/styles/queries';
import styled from 'styled-components';
import { ReactComponent as RegisterImageSvg } from 'ui/assets/images/files/register_side_content.svg';

export const SideContent = styled.div`
  ${query.largeDesktops`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  ${query.desktops`
    width: 100%;
    max-width: 620px;
    margin: auto;
  `};
`;

export const RegisterImage = styled(RegisterImageSvg)`
  fill: ${({ theme }) => theme.colorVariants.primary};
`;
