import React from 'react';
import type { PlatformContextProps } from 'ui/platform/types';
import renderApp from 'render';
import DefaultApp from 'app.default';

export default function renderDefaultApp({
  initialTheme,
  initialPlatform,
  initialLocales,
  initialImages,
}: PlatformContextProps) {
  return renderApp({
    App: (
      <DefaultApp
        initialPlatform={initialPlatform}
        initialTheme={initialTheme}
        initialLocales={initialLocales}
        initialImages={initialImages}
      />
    ),
  });
}
