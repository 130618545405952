import { useApiError } from './use-api-error';

import { useCallback, useRef, useState } from 'react';
import { useStore } from 'store/hooks';
import { makeAuthenticatedApi as coreMakeAuthenticatedApi } from 'core/api';
import { BaseAPI, Configuration } from 'api';
import useIsMounted from 'hooks/use-is-mounted';

type TakeLatestFunction = (latestFunction: () => void) => void;

export const useApiCall = (defaultLoading = false) => {
  const isMounted = useIsMounted();
  const requestNumber = useRef(0);
  const [loading, setLoading] = useState(defaultLoading);
  const { error, setError, setException } = useApiError();
  const store = useStore();
  const makeAuthenticatedApi = useCallback(
    <T extends BaseAPI>(Api: new (config: Configuration) => T) => coreMakeAuthenticatedApi(Api, store.getState),
    [store.getState],
  );

  const reset = useCallback(() => {
    setError(undefined);
    setLoading(defaultLoading);
  }, [setError, setLoading, defaultLoading]);

  const withApi = useCallback(
    async <T>(f: (utilities: { takeLatest: TakeLatestFunction }) => Promise<T>): Promise<T | undefined> => {
      requestNumber.current += 1;
      const latestRequestNumber = requestNumber.current;

      const takeLatest = (latestFunction: () => void) => {
        if (latestRequestNumber === requestNumber.current) latestFunction();
      };

      setError(undefined);
      setLoading(true);

      try {
        return await f({ takeLatest });
      } catch (e) {
        takeLatest(() => {
          setException(e);
        });
      } finally {
        if (isMounted()) setLoading(false);
      }
      return undefined;
    },
    [setException, setError, setLoading, requestNumber],
  );

  return { loading, reset, error, withApi, makeAuthenticatedApi };
};

export default useApiCall;
