import styled from 'styled-components';

export const Graphic = styled.img`
  width: 100px;
  display: block;
  margin: 10px auto 20px;
`;

export const CompletedGraphic = styled(Graphic)`
  width: 150px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const ConfirmationCancelText = styled.p`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  margin-top: 5rem;
`;
