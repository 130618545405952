import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { toNumber, toMoney } from 'ui/helper/money';
import Currency from 'ui/atoms/currency';
import Header from 'ui/atoms/header';
import { StyleLinkButton } from './styled';
import Hint from 'ui/atoms/hint';
import Button from 'ui/atoms/button';
import { shorten } from 'helper/shorten';
import Spacer from 'ui/atoms/spacer';
import { Product, Wallet } from 'apps/issuer/pages/investor-details/create-redemption';
import Grid, { Col } from 'ui/atoms/grid';

export interface ConfirmRedemptionProps {
  onHideConfirmRedemption: () => void;
  isRedemptionCreatedSuccesfully: boolean;
  product: Product;
  wallet: Wallet | undefined;
  units: number;
}

const ConfirmRedemption: FunctionComponent<ConfirmRedemptionProps> = (props) => {
  const { onHideConfirmRedemption, isRedemptionCreatedSuccesfully, product, wallet, units } = props;

  const translate = useTranslateWithStringArgs();

  return (
    <>
      {isRedemptionCreatedSuccesfully && (
        <Hint variant="success">
          <Translate name="dashboardIssuerInvestorDetails.confirmRedemptionForm.success" />
        </Hint>
      )}
      <Header size="tiny" truncate={true}>
        <Translate name="dashboardIssuerInvestorDetails.confirmRedemptionForm.subtitle" />
      </Header>
      <Grid spacing="medium">
        <Col width={5}>
          <Translate name={'dashboardIssuerInvestorDetails.createRedemptionForm.fields.product.label'} />
        </Col>
        <Col width={7}>{product.name}</Col>
      </Grid>
      {wallet?.address && (
        <Grid spacing="medium">
          <Col width={5}>
            <Translate name={'dashboardIssuerInvestorDetails.createRedemptionForm.fields.wallet.label'} />
          </Col>
          <Col width={7}>
            {`${translate(`walletType.${wallet.type.toLowerCase()}`)} (${shorten(wallet.address, 10)})`}
          </Col>
        </Grid>
      )}
      <Grid spacing="medium">
        <Col width={5}>
          <Translate name={'dashboardIssuerInvestorDetails.createRedemptionForm.fields.units.label'} />
        </Col>
        <Col width={7}>
          <Translate name={'dashboardIssuerInvestorDetails.confirmRedemptionForm.units'} args={[units]} />
          <div>
            <small>
              <Translate
                name={'dashboardIssuerInvestorDetails.confirmRedemptionForm.unitsInfo'}
                args={[
                  (_, key) => (
                    <Currency key={key}>
                      {toMoney(units * toNumber(product.tokenPrice), product.tokenPrice.currency)}
                    </Currency>
                  ),
                ]}
              />
            </small>
          </div>
        </Col>
      </Grid>
      {!isRedemptionCreatedSuccesfully && (
        <Section spacing="medium">
          <Spacer x={3} inline>
            <Translate name={'dashboardIssuerInvestorDetails.confirmRedemptionForm.changeData.info'} />
          </Spacer>
          <StyleLinkButton>
            <Button variant="link" onClick={() => onHideConfirmRedemption()}>
              <Translate name={'dashboardIssuerInvestorDetails.confirmRedemptionForm.changeData.button'} />
            </Button>
          </StyleLinkButton>
        </Section>
      )}
      {isRedemptionCreatedSuccesfully ? (
        <Translate name="dashboardIssuerInvestorDetails.confirmRedemptionForm.successInfo" />
      ) : (
        <Hint variant="warning">
          <Translate name="dashboardIssuerInvestorDetails.confirmRedemptionForm.info" />
        </Hint>
      )}
    </>
  );
};

export default ConfirmRedemption;
