import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { ModalContext } from 'ui/molecules/modal';
import LoadingRing from 'ui/atoms/loading-ring';
import * as Styled from './styled';
import Margin from 'ui/types/margin';

interface ModalContentProps {
  loading?: boolean;
  className?: string;
  margin?: Margin;
}

export const ModalContent: FunctionComponent<ModalContentProps> = ({
  children,
  margin,
  loading = false,
  className,
}) => {
  const { bgColor } = useContext(ModalContext);

  const [hasScrollbar, setHasScrollbar] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight;
      const clientHeight = contentRef.current.clientHeight;

      setHasScrollbar(scrollHeight > clientHeight);
    }
  }, []);

  return (
    <Styled.ModalContent
      $bgColor={bgColor}
      $hasScrollbar={hasScrollbar}
      $margin={margin}
      className={className}
      ref={contentRef}
    >
      {loading ? (
        <Styled.ModalContentLoader>
          <LoadingRing />
        </Styled.ModalContentLoader>
      ) : (
        children
      )}
    </Styled.ModalContent>
  );
};

export default ModalContent;
