import React, { FunctionComponent } from 'react';
import Container from 'ui/atoms/container';
import { useLocation } from 'react-router';
import useResetScrollOnChange from 'hooks/use-reset-scroll-on-change';
import * as Styled from './styled';
import type { NavigationItem } from 'core/nav/types';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import DashboardSidebar from 'core/nav/sidebars/sidebar';
import NavBar from 'core/nav/navbar/studio-navbar';

export interface DashboardLayoutProps {
  navigationItems: NavigationItem[];
  showInvestNowButton?: boolean;
  showInviteFriends?: boolean;
}

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  children,
  navigationItems,
  showInvestNowButton,
  showInviteFriends,
}) => {
  const location = useLocation();
  useResetScrollOnChange(location.pathname);
  const isPhoneTablet = useIsMedia(DEVICES.phoneTablet);

  return (
    <Styled.DashboardLayout>
      {!isPhoneTablet && <DashboardSidebar navigationItems={navigationItems} />}
      <Styled.DashboardContent>
        <Styled.Header>
          <NavBar
            showInvestNowButton={showInvestNowButton}
            showInviteFriends={showInviteFriends}
            navigationItems={navigationItems}
            hideLogo={!isPhoneTablet}
          />
        </Styled.Header>
        <Styled.Main>
          <Container fluid={true}>{children}</Container>
        </Styled.Main>
      </Styled.DashboardContent>
    </Styled.DashboardLayout>
  );
};

export default DashboardLayout;
