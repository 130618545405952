import React, { FunctionComponent, useCallback, useContext } from 'react';
import Button from 'src/ui/atoms/button';
import Translate from 'src/ui/atoms/translate';
import WizardContext from '../wizard-context';
import * as Styled from './styled';

export interface WizardContentProps {
  subStep?: number;
  subStepsNumber?: number;
  changeSubStep?: (subStep: number) => void;
}

const WizardHeader: FunctionComponent<WizardContentProps> = (props) => {
  const { subStep, subStepsNumber, changeSubStep } = props;

  const { lastRevisitableStep, changeStep } = useContext(WizardContext);
  const lastRevisitStep = lastRevisitableStep();

  const onGoBack = useCallback(() => {
    if (subStep && subStep !== 1 && changeSubStep) {
      changeSubStep(subStep - 1);
    } else {
      changeStep(lastRevisitStep);
    }
  }, [subStep, lastRevisitStep]);

  if (!lastRevisitStep && (!subStep || !subStepsNumber)) return null;

  return (
    <Styled.WizardHeader>
      {lastRevisitStep && (
        <Button onClick={onGoBack} variant="link" size="large" inline>
          <Translate name="common.back" />
        </Button>
      )}
      {subStep && subStepsNumber && (
        <Styled.SubStep>
          <Translate name="pagination.subStep" args={[subStep, subStepsNumber]} />
        </Styled.SubStep>
      )}
    </Styled.WizardHeader>
  );
};

export default WizardHeader;
