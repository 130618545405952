import { AdminApi, CountryEnum, DocumentsApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';
import Hint from 'ui/atoms/hint';
import Grid, { Col } from 'ui/atoms/grid';
import useCountries from 'src/hooks/use-countries';

export interface ManualReviewCompanyFormFields {
  companyName: string;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
  commercialRegisterStatement: Blob | null;
  listOfShareholders: Blob | null;
  transparencyRegister: Blob | null;
  commercialRegisterStatementId?: string;
  listOfShareholdersId?: string;
  transparencyRegisterId?: string;
}

export interface AddManualReviewModalProps {
  onHide: () => void;
  continueReview: () => void;
  fetchInvestorDetails?: () => void;
  investorId: string;
  values: ManualReviewCompanyFormFields;
}

const ManualReviewCompanyForm = makeModalForm<ManualReviewCompanyFormFields>();

const ManualReviewCompanyFormModal: FunctionComponent<AddManualReviewModalProps> = (props) => {
  const { onHide, continueReview, investorId, fetchInvestorDetails, values } = props;

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const [personValues, setPersonValues] = useState<ManualReviewCompanyFormFields>(values);

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const documentsApi: DocumentsApi = useMemo(() => makeAuthenticatedApi(DocumentsApi), [makeAuthenticatedApi]);

  const onSubmit = (fieldsValues: StateValues<ManualReviewCompanyFormFields>) => {
    withApi(async () => {
      const patchBody = {
        id: investorId,
        adminLegalPersonUpdateRequest: {
          ...fieldsValues,
        },
      };

      if (fieldsValues.commercialRegisterStatement) {
        const commercialRegisterStatementResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.commercialRegisterStatement,
        });
        patchBody.adminLegalPersonUpdateRequest.commercialRegisterStatementId = commercialRegisterStatementResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.commercialRegisterStatementId = values.commercialRegisterStatementId;
      }
      if (fieldsValues.listOfShareholders) {
        const listOfShareholdersResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.listOfShareholders,
        });
        patchBody.adminLegalPersonUpdateRequest.listOfShareholdersId = listOfShareholdersResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.listOfShareholdersId = values.listOfShareholdersId;
      }
      if (fieldsValues.transparencyRegister) {
        const transparencyRegisterResponse = await documentsApi.adminDocumentsCreate({
          file: fieldsValues.transparencyRegister,
        });
        patchBody.adminLegalPersonUpdateRequest.transparencyRegisterId = transparencyRegisterResponse.id;
      } else {
        patchBody.adminLegalPersonUpdateRequest.transparencyRegisterId = values.transparencyRegisterId;
      }

      await adminApi.adminInvestorsLegalPersonCreate(patchBody);

      onHide();
      fetchInvestorDetails!();
      continueReview();
    });
  };

  return (
    <>
      <Modal onClose={onHide}>
        <ManualReviewCompanyForm
          onChange={(values: StateValues<ManualReviewCompanyFormFields>) => {
            setPersonValues(values);
          }}
          onSubmit={(values: StateValues<ManualReviewCompanyFormFields>) => {
            onSubmit(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editCompanyDataForm"
          initial={{
            companyName: personValues.companyName,
            street: personValues.street,
            zip: personValues.zip,
            city: personValues.city,
            country: personValues.country,
          }}
        >
          <ModalHeader>
            <Translate name="kycComplianceDetails.addManualReview.title" />
          </ModalHeader>
          <ModalContent>
            <Hint variant="info">
              <Translate name="kycComplianceDetails.addManualReview.hint" />
            </Hint>
            <ManualReviewCompanyForm.Group name="companyName" required={true}>
              <ManualReviewCompanyForm.Input autoFocus={true} />
            </ManualReviewCompanyForm.Group>
            <ManualReviewCompanyForm.Group name="street" required={true}>
              <ManualReviewCompanyForm.Input />
            </ManualReviewCompanyForm.Group>
            <Grid>
              <Col width={4} spacing="small">
                <ManualReviewCompanyForm.Group name="zip" required={true}>
                  <ManualReviewCompanyForm.Input />
                </ManualReviewCompanyForm.Group>
              </Col>
              <Col width={8} spacing="small">
                <ManualReviewCompanyForm.Group name="city" required={true}>
                  <ManualReviewCompanyForm.Input />
                </ManualReviewCompanyForm.Group>
              </Col>
            </Grid>
            <ManualReviewCompanyForm.Group name="country" required={true}>
              <ManualReviewCompanyForm.Select
                options={useCountries()}
                value={personValues.country}
                onChange={(country: CountryEnum) => {
                  setPersonValues({ ...personValues, country });
                }}
              ></ManualReviewCompanyForm.Select>
            </ManualReviewCompanyForm.Group>
            <ManualReviewCompanyForm.Group name="commercialRegisterStatement" required={true}>
              <ManualReviewCompanyForm.FileUpload />
              {ManualReviewCompanyForm.Validators.FileSize(5000000)}
            </ManualReviewCompanyForm.Group>
            <ManualReviewCompanyForm.Group name="listOfShareholders" required={true}>
              <ManualReviewCompanyForm.FileUpload />
              {ManualReviewCompanyForm.Validators.FileSize(5000000)}
            </ManualReviewCompanyForm.Group>
            <ManualReviewCompanyForm.Group name="transparencyRegister" required={true}>
              <ManualReviewCompanyForm.FileUpload />
              {ManualReviewCompanyForm.Validators.FileSize(5000000)}
            </ManualReviewCompanyForm.Group>
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'send',
                  content: <Translate name="kycComplianceDetails.addManualReview.reviewPersonsButton" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                },
              ]}
            />
          </ModalFooter>
        </ManualReviewCompanyForm>
      </Modal>
    </>
  );
};

export default ManualReviewCompanyFormModal;
