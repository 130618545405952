import React, { useState, useCallback, FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WideContent from 'core/layout/content/wide-content';
import Button from 'ui/atoms/button';
import AddSettlementModal from './add-settlement-modal';
import SettlementsTable from './settlements-table';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterPopup, Search, FilterSingleOption } from 'ui/molecules/filtering';

const IssuerSettlementsPageFilters: FunctionComponent<{}> = () => {
  const translate = useTranslateWithStringArgs();

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.correlationId')} name="search" />
      <FilterPopup>
        <FilterSingleOption
          input="status"
          defaultField="status"
          label={translate('common.status')}
          options={[
            {
              value: 'asset_locked',
              label: translate('studioSettlementsTable.assetLocked'),
              name: 'asset_locked',
            },
            {
              value: 'created',
              label: translate('studioSettlementsTable.created'),
              name: 'created',
            },
            {
              value: 'failed',
              label: translate('studioSettlementsTable.failed'),
              name: 'failed',
            },
            {
              value: 'payment_instruction_prepared',
              label: translate('studioSettlementsTable.paymentInstructionPrepared'),
              name: 'payment_instruction_prepared',
            },
            {
              value: 'success',
              label: translate('studioSettlementsTable.success'),
              name: 'success',
            },
          ]}
        />
      </FilterPopup>
    </>
  );
};

const TriggerSolutionSettlementsPage: FunctionComponent = () => {
  const [isAddSettlementModalOpen, setAddSettlementModalOpen] = useState(false);
  const [forceDataLoad, setForceDataLoad] = useState(false);

  const refreshTableData = useCallback(() => {
    setForceDataLoad(true);
    setAddSettlementModalOpen(false);
    setForceDataLoad(false);
  }, []);

  return (
    <WideContent title={<Translate name="dashboardIssuerTriggerSolution.title" />}>
      <Button
        variant="secondary"
        icon="plus"
        size="xsmall"
        iconSize="small"
        onClick={() => setAddSettlementModalOpen(true)}
      >
        <Translate name="dashboardIssuerTriggerSolution.addSettlement" />
      </Button>

      <SettlementsTable
        filters={<IssuerSettlementsPageFilters />}
        defaultOrdering={{ direction: 'desc', fieldName: 'correlation_id' }}
        defaultLimit={10}
        forceDataLoad={forceDataLoad}
      />

      {isAddSettlementModalOpen && (
        <AddSettlementModal onClose={() => setAddSettlementModalOpen(false)} onAddNewSettlement={refreshTableData} />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(TriggerSolutionSettlementsPage, AppType.ISSUER);
