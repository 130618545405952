export type Spacing = 'none' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';

export type SpacingTheme = {
  [key in Spacing]: number;
};

export default {
  none: 0,
  tiny: 0.2,
  small: 0.5,
  medium: 1,
  large: 1.5,
  xlarge: 2.5,
} as SpacingTheme;
