import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminApi, InvestmentInvitation, Invitation } from 'api';
import Currency from 'ui/atoms/currency';
import { ProductInvitationsStatus } from 'apps/issuer/pages/product-details/invitations/shared/product-invitation-status';
import WithDataRecord from 'src/hoc/WithDataRecord';
import DataRecordOrdering from 'src/ui/types/data-ordering';
import Section from 'src/ui/atoms/section';
import { PaginationProps } from 'ui/molecules/pagination';

export interface ProductInvitationsTableProps {
  productId: string;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
  goToDetails: (invitationId: string) => void;
  defaultLimit?: number;
  defaultOrdering?: DataRecordOrdering;
}

const ProductInvitationsTable: FunctionComponent<ProductInvitationsTableProps> = WithDataRecord<AdminApi, Invitation>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, title, emptyView, loading, goToDetails, paginationProps }) => {
    return (
      <>
        <Section spacing="medium">
          <Translate name={`issuerProductDetails.invitations.${data?.length ? 'subtitle' : 'subtitleNoData'}`} />
        </Section>
        <DataRecordTable
          data={data as InvestmentInvitation[]}
          ordering={ordering}
          onOrderBy={onOrderBy}
          title={title}
          loading={loading}
          paginationProps={paginationProps}
          emptyView={emptyView}
          cells={[
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.reference`} />,
              render: (invitation) => invitation.name,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.distributionPlatform`} />,
              render: (invitation) => invitation.distributionPlatformDetails.platformName || '-',
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.allocation`} />,
              alignContent: 'right',
              render: (invitation) => invitation.maxNumberOfTokens,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.signed`} />,
              alignContent: 'right',
              render: (invitation) => `${invitation.signedPercentage.toFixed()}%`,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.pricePerUnit`} />,
              alignContent: 'right',
              render: (invitation) => <Currency decimals="*">{invitation.pricePerToken}</Currency>,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.status`} />,
              render: (invitation) => <ProductInvitationsStatus invitation={invitation} />,
            },
            {
              title: '',
              render: ({ id }) => (
                <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
              ),
              alignContent: 'right',
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminInvitationsList({
      invitationType: 'investment_invitation',
      tokenId: props.productId,
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default ProductInvitationsTable;
