import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import * as Styled from './styled';
import Spacing from 'ui/types/spacing';
import Size from 'ui/types/size';
import cx from 'ui/helper/prefixed-class-names';
import { Icons } from 'ui/assets/icons/types';
import StepperInput from 'ui/atoms/input/stepper-input';
import { HorizontalAlign } from 'ui/types/align';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Additional classes for input container */
  className?: string;

  /** Additional classes for input */
  inputClassName?: string;

  autoComplete?: string;

  autoFocus?: boolean;

  autoBlur?: boolean;

  /**  Loading attribute **/
  loading?: boolean;

  validating?: boolean;

  /** Valid value */
  valid?: boolean;

  id?: string;

  /** Spacing at the bottom */
  spacing?: Spacing;

  /**  How an `Input` works varies considerably depending on the value of its type attribute **/
  type?: string;

  value?: string;

  pattern?: string;

  placeholder?: string;

  max?: number | string;

  step?: number | string;

  maxLength?: number;

  min?: number | string;

  minLength?: number;

  size?: Size;

  width?: Size;

  label?: string | ReactNode;

  isCurrency?: boolean;

  isDate?: boolean;

  textAlign?: HorizontalAlign;

  height?: Size;

  alignIcon?: string;

  icon?: Icons;

  isStepper?: boolean;

  setInputValue?: (val: string) => void;
}

export const Input = forwardRef<any, InputProps>((props, ref) => {
  const _ref = useRef<HTMLInputElement>(null);
  const {
    className,
    size,
    width,
    children,
    valid,
    autoFocus,
    autoBlur,
    loading,
    validating,
    spacing,
    disabled,
    value,
    type,
    label,
    onClick,
    onKeyDown,
    onBlur,
    onFocus,
    onChange,
    setInputValue,
    inputClassName,
    isCurrency,
    isDate,
    placeholder,
    height = 'large',
    textAlign = 'left',
    alignIcon,
    icon,
    isStepper,
    min,
    max,
    ...restProps
  } = props;

  const [focused, setFocused] = useState(false);
  const [hover, setHover] = useState(false);

  const inputRef = ref || _ref;

  useEffect(() => {
    if (autoFocus && typeof inputRef === 'object' && inputRef.current) {
      inputRef?.current?.focus();
    }
    if (autoBlur && typeof inputRef === 'object' && inputRef.current && !valid) {
      inputRef?.current?.blur();
    }
  }, [inputRef, autoFocus, autoBlur]);

  const onInputFocus = (event: any) => {
    setFocused(true);
    onFocus && onFocus(event);
  };

  const onInputBlur = (event: any) => {
    setFocused(false);
    onBlur && onBlur(event);
  };

  const onInputHover = (isHover: boolean) => {
    setHover(isHover);
  };

  const handleKeyDown = (event: any) => {
    if (type === 'number' && [109, 189, 69].includes(event.keyCode)) {
      event.preventDefault();
    }
    if (onKeyDown) onKeyDown(event);
  };

  return (
    <Styled.InputContainer
      $spacing={spacing}
      $width={width}
      $isInvalid={valid === false}
      $hover={hover}
      disabled={loading ? true : disabled}
      $focused={focused}
      $height={height}
      className={className}
    >
      <Styled.Input
        $isCurrency={isStepper ? false : isCurrency}
        $isDate={isDate}
        $hasLabel={!!label}
        $textAlign={textAlign}
        $alignIcon={alignIcon}
        $isStepper={isStepper}
        disabled={loading ? true : disabled}
        ref={inputRef}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={onInputFocus}
        onMouseOver={() => onInputHover(true)}
        onMouseOut={() => onInputHover(false)}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onBlur={onInputBlur}
        type={type}
        value={value}
        className={cx('input', inputClassName)}
        min={min || 0}
        max={max}
        {...restProps}
      >
        {children}
      </Styled.Input>
      <Styled.Label
        $type={type}
        $isInvalid={valid === false}
        $isCurrency={isStepper ? false : isCurrency}
        $isDate={isDate}
        $focused={focused}
        $inputHasValue={!!value || !!placeholder}
        $alignIcon={alignIcon}
      >
        {label}
      </Styled.Label>
      {isStepper && setInputValue && (
        <StepperInput
          inputHover={hover}
          value={value ? (typeof value === 'string' ? parseFloat(value) : value) : 0}
          setValue={setInputValue}
          min={typeof min === 'string' ? parseFloat(min) : min}
          max={typeof max === 'string' ? parseFloat(max) : max}
        />
      )}
      {isCurrency && (
        <Styled.CurrencySymbol $focused={(focused || !!value) && !!label} $alignIcon={alignIcon}>
          €
        </Styled.CurrencySymbol>
      )}
    </Styled.InputContainer>
  );
});

export default Input;
