import React, { FunctionComponent } from 'react';
import DashboardEmptyView, { EmptyViewType } from 'libraries/dashboard-empty-view';
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import { useFilterContext } from 'ui/molecules/filtering/hooks';
import usePlatformImage from 'ui/hooks/use-platform-image';
import type { ButtonVariants } from 'ui/styles/themes/buttons';
import { PlatformImages } from 'ui/assets/images/types';

export type EmptyViewAssetMap = { [key in EmptyViewType]?: PlatformImages };
export type EmptyViewActionMap = {
  [key in EmptyViewType]?: EmptyViewAction[] | undefined;
};

export interface EmptyViewAction {
  i18nKey: string;
  onAction?: () => void;
  href?: string;
  buttonVariant?: ButtonVariants;
  disabled?: boolean;
}

export interface EmptyViewProps {
  type: EmptyViewType;
  image: string;
  i18nKeyExtension?: string;
  emptyViewActions?: EmptyViewAction[] | undefined;
}

const BaseEmptyView: FunctionComponent<EmptyViewProps> = (props) => {
  const { type, image, i18nKeyExtension, children, emptyViewActions } = props;

  return (
    <DashboardEmptyView type={type} image={image} i18nKeyExtension={i18nKeyExtension}>
      <ActionButtons align="start">
        {(emptyViewActions || []).map((emptyViewAction, index) => (
          <Button
            onClick={emptyViewAction?.onAction ? emptyViewAction?.onAction : () => {}}
            variant={emptyViewAction.buttonVariant || 'primary'}
            key={index}
            disabled={emptyViewAction.disabled}
          >
            <Translate name={`dashboardEmptyView.actions.${emptyViewAction.i18nKey}`} />
          </Button>
        ))}
        {/*custom action buttons passed as children*/}
        {children}
      </ActionButtons>
    </DashboardEmptyView>
  );
};

const EmptyView: FunctionComponent<EmptyViewProps> = (props) => {
  const { filterNumber, resetFilters } = useFilterContext();

  const emptyViewImage = usePlatformImage('emptyFilterResults');

  if (filterNumber) {
    return (
      <BaseEmptyView
        type={EmptyViewType.NO_FILTER_RESULTS}
        image={emptyViewImage}
        emptyViewActions={[{ i18nKey: 'showAllEntries', onAction: resetFilters }]}
      />
    );
  }

  return <BaseEmptyView {...props} />;
};

export default EmptyView;
