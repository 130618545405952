import React, { FunctionComponent } from 'react';
import RoutesRenderer from 'core/routing/routes-renderer';
import DefaultLayout from 'core/layout/default';
import { AUTH_COMPONENT_ROUTING, AUTH_ROUTES } from './routes.config';
import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';

const AuthPages: FunctionComponent = () => {
  const authRoutes = useRoutesResolver(AUTH_ROUTES);

  return (
    <DefaultLayout segment={false} pageTitle={false}>
      <RoutesRenderer routes={authRoutes} componentRouting={AUTH_COMPONENT_ROUTING} />
    </DefaultLayout>
  );
};

export default AuthPages;
