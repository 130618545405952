import { useCallback, useEffect, useMemo, useState } from 'react';
import { TwoFactorAuthApi } from 'api';
import useApiCall from 'hooks/use-api-call';
import { ResetSteps } from '.';

export const useResetWallet = (existingTanganyWallet: boolean, onModalClose: () => void) => {
  const [currentStep, setCurrentStep] = useState(ResetSteps.PRESENT_STEPS);
  const [isWordsConfirmationCorrect, setIsWordsConfirmationCorrect] = useState(false);
  const [isUnpairChecked, setIsUnpairChecked] = useState(false);
  const [isConfirmBtnEnabled, setIsConfirmBtnEnabled] = useState(false);

  const { withApi, makeAuthenticatedApi } = useApiCall();
  const adminApi: TwoFactorAuthApi = useMemo(() => makeAuthenticatedApi(TwoFactorAuthApi), [makeAuthenticatedApi]);

  useEffect(() => {
    if (
      (isWordsConfirmationCorrect && currentStep === ResetSteps.WORDS_CONFIRMATION) ||
      (isUnpairChecked && currentStep === ResetSteps.RESET_CONFIRMATION)
    ) {
      setIsConfirmBtnEnabled(true);
    } else {
      setIsConfirmBtnEnabled(false);
    }
  }, [currentStep, isWordsConfirmationCorrect, isUnpairChecked]);

  const onConfirm = useCallback(async () => {
    if (currentStep === ResetSteps.RESET_CONFIRMATION) {
      await resetWallet();
      setCurrentStep(ResetSteps.SUCCESS);
    } else if (currentStep === ResetSteps.WORDS_CONFIRMATION) {
      setCurrentStep(ResetSteps.RESET_CONFIRMATION);
    }
  }, [currentStep]);

  const onContinue = useCallback(() => {
    setCurrentStep(existingTanganyWallet ? ResetSteps.RESET_CONFIRMATION : ResetSteps.WORDS_CONFIRMATION);
  }, [currentStep, existingTanganyWallet]);

  const onClose = useCallback(() => {
    onModalClose();
  }, [onModalClose, existingTanganyWallet]);

  const resetWallet = useCallback(() => {
    withApi(async () => {
      try {
        await adminApi.twoFactorAuthDeviceUnpairCreate();
      } catch (e) {
        console.error(e);
      }
    });
  }, [withApi, adminApi]);

  const onChangeWordConfirmation = (isCorrect: boolean) => {
    setIsWordsConfirmationCorrect(isCorrect);
  };

  const onChangeUnpairConfirmation = (isUnpairChecked: boolean) => {
    setIsUnpairChecked(isUnpairChecked);
  };

  return {
    onConfirm,
    onClose,
    onContinue,
    currentStep,
    isConfirmBtnEnabled,
    onChangeWordConfirmation,
    onChangeUnpairConfirmation,
  };
};
