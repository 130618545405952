import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Header from 'ui/atoms/header';
import { AdditionalInformationForm } from 'libraries/wizard/components/additional-information/additional-information-form';

export interface TaxInformationProps {
  hasTaxInformation?: boolean;
  showExemptionOrder?: boolean;
}
const TaxInformation: FunctionComponent<TaxInformationProps> = (props) => {
  const { hasTaxInformation, showExemptionOrder } = props;

  return (
    <Section spacing="large">
      {!hasTaxInformation && (
        <>
          <Header as="h3" size="small">
            <Translate name="investmentAdditionalInformation.taxInformation.title" />
          </Header>
          <AdditionalInformationForm.Group
            name="taxIdentificationNumber"
            required={true}
            info={<Translate name="additionalInformationForm.fields.taxIdentificationNumber.info" />}
          >
            <AdditionalInformationForm.Input maxLength={100} />
            {AdditionalInformationForm.Validators.TaxId()}
          </AdditionalInformationForm.Group>
        </>
      )}
      {hasTaxInformation && showExemptionOrder && (
        <Header as="h3" size="small">
          <Translate name="investmentAdditionalInformation.taxInformation.exemptionOrderAmount.title" />
        </Header>
      )}
      {showExemptionOrder && (
        <AdditionalInformationForm.Group
          name="exemptionOrderAmount"
          info={<Translate name="additionalInformationForm.fields.exemptionOrderAmount.info" />}
        >
          <AdditionalInformationForm.Input type="number" isCurrency />
          {AdditionalInformationForm.Validators.Range(0, undefined)}
          {AdditionalInformationForm.Validators.MaxDecimals(2)}
        </AdditionalInformationForm.Group>
      )}
      {!hasTaxInformation && (
        <AdditionalInformationForm.Group
          name="nonAssessmentCertificate"
          required={true}
          info={<Translate name="additionalInformationForm.fields.nonAssessmentCertificate.info" />}
        >
          <AdditionalInformationForm.GroupToggle>
            {['true', 'false'].map((option) => (
              <AdditionalInformationForm.Radio value={option}>
                <Translate name={`commonOptions.${option}`} />
              </AdditionalInformationForm.Radio>
            ))}
          </AdditionalInformationForm.GroupToggle>
        </AdditionalInformationForm.Group>
      )}
    </Section>
  );
};

export default TaxInformation;
