import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query, DEVICES } from 'ui/styles/queries';

export interface StyledGridProps {
  $reverse?: boolean | Array<DEVICES>;
  $spacing?: Spacing;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<StyledGridProps, DefaultTheme>) {
  if (!$spacing) return null;

  return css`
    margin-bottom: ${theme.spacing[$spacing]}rem;
  `;
}

function getReverse({ $reverse }: ThemedStyledProps<StyledGridProps, DefaultTheme>) {
  if (!$reverse) return null;

  if (Array.isArray($reverse)) {
    return $reverse.map(
      (platform) => query[platform]`
      flex-wrap: wrap-reverse;
      flex-direction: row-reverse;
    `,
    );
  }

  return css`
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
  `;
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  align-content: center;
  margin-left: -15px;
  margin-right: -15px;
  ${getSpacing}
  ${getReverse}
`;
