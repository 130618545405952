export interface TablesTheme {
  cellPadding: any;
  footerCellPadding: any;
  headTextColor: any;
  borderRadius: any;
  tableBorder: any;
}

export default {
  cellPadding: '0.2rem 0.75rem 0.2rem 0',
  footerCellPadding: '0.5rem 0.75rem 0.5rem 0',
  borderRadius: '0.5rem',
  headTextColor: ({ theme }: any) => theme.colors.grayDark,
  tableBorder: ({ theme }: any) => `1px solid ${theme.colors.grayHover}`,
};
