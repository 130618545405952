import React, { FunctionComponent, ReactElement, ReactNode, useContext } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import ListPoint from 'ui/atoms/list-point';
import Spacing from 'ui/types/spacing';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import * as Styled from './styled';
import Spacer from 'src/ui/atoms/spacer';

interface StepInfo {
  index: number;
  listPointVariant: ColorVariants;
}

const StepInfoContext = React.createContext<StepInfo>({
  index: 0,
  listPointVariant: 'primary',
});

export interface StepItemProps {
  /** Title component. */
  title?: ReactElement | string;

  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Boolean if it's the last item of the list. */
  isLastItem?: boolean;
}

export const StepItem: FunctionComponent<StepItemProps> = ({
  title,
  isLastItem,
  children,
  className,
  ...restProps
}) => {
  const info: StepInfo = useContext(StepInfoContext);

  let step: string;

  step = (info.index + 1).toString();

  return (
    <Styled.StepItem className={cx('step-item', className)} {...restProps}>
      <ListPoint circular={true}>{step}</ListPoint>
      <Styled.VerticalItemCol>
        {title && <strong>{title}</strong>}
        <Spacer y={2} />
        {children}
      </Styled.VerticalItemCol>
    </Styled.StepItem>
  );
};

export interface StepListProps {
  /** Primary content. (Max amount of children for horizontal step lists: 12) */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  spacing?: Spacing;

  /**
   * The list point can be of different variants
   *
   * @default "primary"
   * */
  listPointVariant?: ColorVariants;
}

const StepList: FunctionComponent<StepListProps> = (props) => {
  const { className, children, spacing, listPointVariant = 'primary', ...restProps } = props;

  const validChildren = React.Children.toArray(children).filter(React.isValidElement);

  return (
    <Styled.StepList fluid={true} className={cx('step-list', className)} spacing={spacing} {...restProps}>
      {validChildren.map((child, i) => (
        <StepInfoContext.Provider
          key={i}
          value={{
            listPointVariant,
            index: i,
          }}
        >
          {child}
        </StepInfoContext.Provider>
      ))}
    </Styled.StepList>
  );
};

export default StepList;
