import { AdminInvestor } from 'api';
import React, { FunctionComponent, useContext, useMemo } from 'react';

import { PlatformContext } from 'ui/platform/provider';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import DeleteConfirm from './delete-confirm';
import DeleteError from './delete-error';
import DeleteSuccess from './delete-success';
import FutureDeletion from './future-deletion';
import { useDeleteAccount } from './hooks';
import Translate from 'ui/atoms/translate';
import type { ActionButton } from 'ui/molecules/action-buttons';
import { ButtonSizes } from 'ui/styles/themes/buttons';

export interface DeleteAccountProps {
  investor: AdminInvestor;
  onModalClose: () => void;
}

const DeleteAccountModal: FunctionComponent<DeleteAccountProps> = (props) => {
  const { investor, onModalClose } = props;

  const {
    error,
    deletionSuccess,
    requestFutureDeletion,
    setIsConfirmed,
    canDeleteInvestor,
    getModalHeader,
    isConfirmed,
    onConfirm,
    onClose,
    onBack,
  } = useDeleteAccount(investor, onModalClose);

  const actionButtons = useMemo(
    () => ({
      Cancel: {
        name: 'cancel',
        content: requestFutureDeletion ? <Translate name="common.cancel" /> : <Translate name="common.close" />,
        size: 'large' as ButtonSizes,
        onClick: onClose,
      },
      Close: {
        name: 'close',
        content: <Translate name="common.close" />,
        size: 'large' as ButtonSizes,
        onClick: onClose,
      },
      Back: {
        name: 'back',
        content: <Translate name="dashboardIssuerInvestorDetails.deleteAccount.back" />,
        size: 'large' as ButtonSizes,
        onClick: onBack,
      },
      Confirm: {
        name: 'confirm',
        content: requestFutureDeletion ? (
          <Translate name="dashboardIssuerInvestorDetails.futureDeletion.markForDeletion" />
        ) : (
          <Translate name="dashboardIssuerInvestorDetails.deleteAccount.delete" />
        ),
        variant: 'primary',
        type: 'submit',
        size: 'large' as ButtonSizes,
        disabled: !isConfirmed,
        onClick: onConfirm,
      },
    }),
    [requestFutureDeletion, isConfirmed],
  );

  const activeActionButtons = useMemo(() => {
    if (error) {
      return [actionButtons.Cancel];
    } else if (deletionSuccess && requestFutureDeletion) {
      return [actionButtons.Close];
    } else if (deletionSuccess) {
      return [actionButtons.Back];
    } else {
      return [actionButtons.Cancel, actionButtons.Confirm] as ActionButton[];
    }
  }, [error, deletionSuccess, requestFutureDeletion, isConfirmed]);

  const { activePlatform } = useContext(PlatformContext);

  return (
    <Modal onClose={onClose}>
      <ModalHeader>{getModalHeader()}</ModalHeader>
      <ModalContent>
        {((error && !deletionSuccess) || !canDeleteInvestor()) && (
          <DeleteError hasWallets={investor.wallets.length > 0} />
        )}

        {!error && deletionSuccess && (
          <DeleteSuccess investor={investor} requestFutureDeletion={requestFutureDeletion} />
        )}

        {!error && !deletionSuccess && canDeleteInvestor() && !requestFutureDeletion && (
          <DeleteConfirm
            activePlatform={activePlatform}
            investor={investor}
            onChangeConfirm={(isChecked) => setIsConfirmed(isChecked)}
          />
        )}

        {!error && !deletionSuccess && canDeleteInvestor() && requestFutureDeletion && (
          <FutureDeletion
            activePlatform={activePlatform}
            investor={investor}
            onChangeConfirm={(isChecked) => setIsConfirmed(isChecked)}
          />
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons actionButtons={activeActionButtons} />
      </ModalFooter>
    </Modal>
  );
};

export default DeleteAccountModal;
