import React, { FunctionComponent } from 'react';
import { push } from 'connected-react-router';
import { camelCase } from 'change-case';
import { useDispatch } from 'store/hooks';
import makeLink from 'helper/make-link';
import Link, { LinkProps } from 'ui/atoms/link';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { PlatformUrl } from 'ui/types/urls';
import { useServerConfigSelector } from 'core/config/hooks';
import { convertPlatformUrl } from 'core/api/conversions';

interface BaseRouterLinkProps extends Pick<LinkProps, Exclude<keyof LinkProps, 'onClick' | 'href'>> {
  params?: object;
  external?: boolean;
}

interface NamedRouterLinkProps extends BaseRouterLinkProps {
  platformUrl: PlatformUrl;
  to?: never;
}

interface ToRouterLinkProps extends BaseRouterLinkProps {
  platformUrl?: never;
  to: string;
}

type RouterLinkProps = ToRouterLinkProps | NamedRouterLinkProps;

const RouterLink: FunctionComponent<RouterLinkProps> = ({
  to,
  params,
  external,
  platformUrl,
  children,
  ...linkProps
}) => {
  const translateWithStringArgs = useTranslateWithStringArgs();
  const dispatch = useDispatch();

  const {
    config: { platformData },
  } = useServerConfigSelector();

  let href: string;

  if (platformUrl) {
    const platformUrlName = convertPlatformUrl(platformUrl);
    const urlFromConfig = platformData && platformData[platformUrlName];
    //fallback if the config object is not loaded or has an empty string
    href = urlFromConfig?.length ? urlFromConfig : translateWithStringArgs(`urls.${camelCase(platformUrl)}`);
  } else if (external && to) href = to;
  else if (to) href = makeLink({ path: to }, params);
  else href = '/not-found'; // theoretically should never hit this, since either "name" or "to" is enforced

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // prevent default navigation to allow for programatic push
    e.preventDefault();
    return external ? window.open(href, '_blank', 'noopener noreferrer') : dispatch(push(href));
  };

  return (
    <Link href={href} external={external} onClick={onClick} {...linkProps}>
      {children}
    </Link>
  );
};

export default RouterLink;
