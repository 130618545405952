import type { FunctionComponent, ReactNode } from 'react';
import React, { createContext } from 'react';
import { TranslateFunc, TranslateOptions, TranslateResult } from 'ui/types/translate';
import { I18N } from '../types';

export interface I18NContextProps {
  translate: TranslateFunc<ReactNode>;
  translateWithStringArgs: (name: string, options?: TranslateOptions<string>) => string;
  changeLocale: (locale: string | undefined, via: { user?: boolean; selection?: boolean }) => void;
  activeLocale: string;
  selectedLocale?: string;
  userLocale?: string;
  addLocaleMessages: (messages: I18N) => void;
  isMessagesLoaded: boolean;
}

export const I18NContext = createContext<I18NContextProps>({
  translate: (stringName: string, options?: TranslateOptions<ReactNode>): TranslateResult<ReactNode> => '',
  translateWithStringArgs: (stringName: string, options?: TranslateOptions<string>): string => '',
  changeLocale: (locale: string | undefined, via: { user?: boolean; selection?: boolean }) => {},
  activeLocale: '',
  addLocaleMessages: (message: I18N) => {},
  isMessagesLoaded: false,
});

export const I18NProvider: FunctionComponent<I18NContextProps> = ({
  isMessagesLoaded,
  activeLocale,
  selectedLocale,
  userLocale,
  changeLocale,
  translateWithStringArgs,
  translate,
  addLocaleMessages,
  children,
}) => {
  return (
    <I18NContext.Provider
      value={{
        selectedLocale,
        userLocale,
        activeLocale,
        translate,
        translateWithStringArgs,
        changeLocale,
        addLocaleMessages,
        isMessagesLoaded,
      }}
    >
      <div
        style={{
          height: '100%',
          visibility: !isMessagesLoaded ? 'hidden' : undefined,
        }}
      >
        {children}
      </div>
    </I18NContext.Provider>
  );
};

export default {
  I18NProvider,
  I18NContext,
};
