import { CountryEnum, SalutationEnum } from 'api';
import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import makeForm from 'ui/forms';
import Grid, { Col } from 'ui/atoms/grid';
import { validateAlphanumericZIP } from 'ui/helper/validators';
import Header from 'ui/atoms/header';
import Section from 'ui/atoms/section';
import { BenefitingPersonEditType } from './hooks/use-edit-benefiting-person';
import useCountries from 'src/hooks/use-countries';

export interface BenefitingPersonDataFormProps {
  benefitingPerson: BenefitingPersonEditType;
  onChange: (values: BenefitingPersonEditType) => void;
}
export interface BenefitingPersonFormFields {
  salutation: SalutationEnum;
  forename: string;
  surname: string;
  street: string;
  zip: string;
  city: string;
  country: CountryEnum;
  birthDate: Date | null;
  birthPlace: string;
}

const BenefitingPersonForm = makeForm<BenefitingPersonFormFields>();

const BenefitingPersonDataForm: FunctionComponent<BenefitingPersonDataFormProps> = (props) => {
  const { benefitingPerson, onChange } = props;

  return (
    <BenefitingPersonForm initial={benefitingPerson} i18nKey="benefitingPersons" onChange={onChange}>
      <Section>
        <Header size="small">
          <Translate name={'kycComplianceDetails.addManualReview.personalDataSubtitle'} />
        </Header>
        <BenefitingPersonForm.Group required={true} name="salutation">
          <BenefitingPersonForm.GroupToggle>
            {Object.values(SalutationEnum).map((salutation, index) => (
              <BenefitingPersonForm.Radio value={salutation} key={index}>
                <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
              </BenefitingPersonForm.Radio>
            ))}
          </BenefitingPersonForm.GroupToggle>
        </BenefitingPersonForm.Group>
        <BenefitingPersonForm.Group required={true} name="forename">
          <BenefitingPersonForm.Input autoFocus={true} value={benefitingPerson.forename} />
        </BenefitingPersonForm.Group>
        <BenefitingPersonForm.Group required={true} name="surname">
          <BenefitingPersonForm.Input />
        </BenefitingPersonForm.Group>
        <BenefitingPersonForm.Group name="birthDate" required={true}>
          <BenefitingPersonForm.DateInput max={new Date()} />
          {BenefitingPersonForm.Validators.Over18()}
        </BenefitingPersonForm.Group>
        <BenefitingPersonForm.Group name="birthPlace" required={true}>
          <BenefitingPersonForm.Input />
        </BenefitingPersonForm.Group>
      </Section>
      <Section>
        <Header size="small">
          <Translate name={'kycComplianceDetails.addManualReview.addressSubtitle'} />
        </Header>
        <BenefitingPersonForm.Group required={true} name="street">
          <BenefitingPersonForm.Input />
        </BenefitingPersonForm.Group>
        <Grid>
          <Col width={4}>
            <BenefitingPersonForm.Group required={true} name="zip">
              <BenefitingPersonForm.Input />
              <BenefitingPersonForm.Validation validate={validateAlphanumericZIP} />
            </BenefitingPersonForm.Group>
          </Col>
          <Col width={8}>
            <BenefitingPersonForm.Group required={true} name="city">
              <BenefitingPersonForm.Input />
            </BenefitingPersonForm.Group>
          </Col>
        </Grid>
        <BenefitingPersonForm.Group required={true} name="country">
          <BenefitingPersonForm.Select
            options={useCountries()}
            value={benefitingPerson.country}
          ></BenefitingPersonForm.Select>
        </BenefitingPersonForm.Group>
        <BenefitingPersonForm.GenericErrorMessages />
      </Section>
    </BenefitingPersonForm>
  );
};

export default BenefitingPersonDataForm;
