import React, { FunctionComponent, ReactNode } from 'react';
import Translate from 'ui/atoms/translate';
import Currency from 'ui/atoms/currency';
import Icon from 'ui/atoms/icon';
import DataRecordTable from 'src/libraries/data-record-table';
import { AdminInvestorList } from 'api';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Number from 'ui/atoms/number';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import Date from 'ui/atoms/date';
import { PaginationProps } from 'ui/molecules/pagination';

export interface StudioInvestorsTableProps {
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  investors?: AdminInvestorList[];
  goToDetails: (investorId: string) => void;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
}

const StudioInvestorsTable: FunctionComponent<StudioInvestorsTableProps> = (props) => {
  const { ordering, onOrderBy, investors, goToDetails, emptyView, loading, paginationProps } = props;

  return (
    <DataRecordTable
      ordering={ordering}
      onOrderBy={onOrderBy}
      data={investors}
      loading={loading}
      emptyView={emptyView}
      paginationProps={paginationProps}
      cells={[
        {
          title: <Translate name={`studioInvestorsTable.name`} />,
          render: ({ name, companyName, email }) => (
            <div>
              <b>{companyName || name}</b>
              <div>{email}</div>
            </div>
          ),
        },
        {
          title: <Translate name={`studioInvestorsTable.registeredOn`} />,
          orderingFieldName: 'registered_at',
          render: ({ registeredAt }) => <Date>{registeredAt}</Date>,
        },
        {
          title: <Translate name={`studioInvestorsTable.investments`} />,
          alignContent: 'right',
          render: ({ activeInvestmentsNo }) => (
            <PlaceholderFallback>
              {activeInvestmentsNo !== undefined && activeInvestmentsNo !== null && (
                <Number>{activeInvestmentsNo}</Number>
              )}
            </PlaceholderFallback>
          ),
        },
        {
          title: <Translate name={`studioInvestorsTable.totalAmount`} args={[<>&shy;</>]} />,
          alignContent: 'right',
          render: ({ activeInvestmentsSum }) => (
            <PlaceholderFallback>
              {activeInvestmentsSum && <Currency>{activeInvestmentsSum}</Currency>}
            </PlaceholderFallback>
          ),
        },
        {
          title: <Translate name={`studioInvestorsTable.products`} />,
          render: ({ productNames }) =>
            productNames?.length ? productNames.join(', ') : <Translate name={`studioInvestorsTable.noProducts`} />,
        },
        {
          title: '',
          render: ({ id }) => <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />,
          alignContent: 'right',
        },
      ]}
    />
  );
};

export default StudioInvestorsTable;
