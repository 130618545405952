import React, { FunctionComponent } from 'react';
import WizardLayout from 'core/layout/wizard';
import DefaultLayout from 'core/layout/default';
import RenderRoute from 'core/routing/routes-renderer/render-route';
import { INVESTMENT_ROUTES, INVESTMENT_COMPONENT_ROUTES } from './routes.config';
import { Route, Switch } from 'react-router';
import useHubspotChatbot from 'hooks/use-hubspot-chatbot';
import EnglishDisclaimer from 'subapps/investment/pages/investment/english-disclaimer';
import PageNotFound from 'core/components/not-found';

export interface InvestmentPagesProps {}

const InvestmentPages: FunctionComponent<InvestmentPagesProps> = () => {
  useHubspotChatbot();

  return (
    <>
      <Switch>
        <Route path={[INVESTMENT_ROUTES.investment.path, INVESTMENT_ROUTES.invitationsContinue.path]}>
          <Switch>
            <Route
              path={INVESTMENT_ROUTES.investment.path}
              exact
              children={(props) => (
                <WizardLayout {...props?.match?.params}>
                  <EnglishDisclaimer />
                  <RenderRoute Component={INVESTMENT_COMPONENT_ROUTES.investment} {...props} />
                </WizardLayout>
              )}
            />
            <Route
              path={INVESTMENT_ROUTES.invitationsContinue.path}
              exact
              children={(props) => (
                <WizardLayout {...props?.match?.params}>
                  <EnglishDisclaimer />
                  <RenderRoute Component={INVESTMENT_COMPONENT_ROUTES.invitationsContinue} {...props} />
                </WizardLayout>
              )}
            />
          </Switch>
        </Route>
        <Route path={[INVESTMENT_ROUTES.register.path]}>
          <Switch>
            <Route
              path={INVESTMENT_ROUTES.register.path}
              exact
              children={(props) => (
                <WizardLayout registerOnlyFlow>
                  <RenderRoute Component={INVESTMENT_COMPONENT_ROUTES.register} {...props} />
                </WizardLayout>
              )}
            />
          </Switch>
        </Route>
        <Route path={INVESTMENT_ROUTES.campaign.path}>
          <DefaultLayout segment={false} pageTitle={false}>
            <Route
              path={INVESTMENT_ROUTES.campaign.path}
              exact
              children={(props) => <RenderRoute Component={INVESTMENT_COMPONENT_ROUTES.campaign} {...props} />}
            />
          </DefaultLayout>
        </Route>
        <Route>
          <DefaultLayout segment={false} pageTitle={false}>
            <PageNotFound />
          </DefaultLayout>
        </Route>
      </Switch>
    </>
  );
};

export default InvestmentPages;
