import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { ButtonIconPositions, ButtonSizes, ButtonVariants } from 'ui/styles/themes/buttons';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import { Colors, getColorsMap } from 'ui/styles/utils/colors';
import IconNew from 'ui/atoms/icon';

export interface ActionProps {
  $variant?: ButtonVariants;
  $size: ButtonSizes;
  $color?: ColorVariants | Colors;
  $active?: boolean;
  $hovering?: boolean;
  $circular?: boolean;
  $iconOnly?: boolean;
  $danger?: boolean;
  $loading?: boolean;
  $iconPosition?: ButtonIconPositions;
  $fixedWidth?: boolean;
  $fluid?: boolean;
  $inline?: boolean;
  $underline?: boolean;
  $stickied?: 'top' | 'right' | 'bottom' | 'left' | 'all';
  disabled?: boolean;
  $wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word' | 'initial' | 'inherit';
}

const actionDefaults = {
  disabledColor: '#d7d7d7',
};

function disabledCheck(
  { disabled }: ThemedStyledProps<ActionProps, DefaultTheme>,
  disabledStyles: ReturnType<typeof css>,
  restOfStyles: ReturnType<typeof css>,
) {
  return disabled ? disabledStyles : restOfStyles;
}

function primaryVariantStyles(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { theme, $color, $danger, $active, $hovering } = props;
  const { main, minor, mainDark, mainLight } = getColorsMap(theme, $color, $danger);

  return css<ActionProps>`
    border: 1px solid transparent;
    text-decoration: none;
    color: ${minor};

    ${disabledCheck(
      props,
      css<ActionProps>`
        cursor: not-allowed;
        background-color: ${actionDefaults.disabledColor};
      `,
      css<ActionProps>`
        background-color: ${main};

        &:hover {
          background-color: ${({ theme, $color }) => ($color ? mainLight : theme.colorVariants.hover)};
        }

        &:active {
          background-color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
        }

        &:focus,
        &:focus-visible {
          outline: 0;
          background-color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
        }

        ${($active || $hovering) &&
        css<ActionProps>`
          background-color: ${main};
        `}
      `,
    )}
  `;
}

export function secondaryVariantStyles(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { theme, $color, $danger, $active } = props;
  const { main, mainLight, mainDark } = getColorsMap(theme, $color, $danger);

  return css<ActionProps>`
    background-color: transparent;
    border: 2px solid;

    ${disabledCheck(
      props,
      css<ActionProps>`
        border-color: ${actionDefaults.disabledColor};
        color: ${actionDefaults.disabledColor};
        cursor: not-allowed;
      `,
      css<ActionProps>`
        border-color: ${main};
        color: ${main};

        &:hover {
          color: ${({ theme, $color }) => ($color ? mainLight : theme.colorVariants.hover)};
          border-color: ${({ theme, $color }) => ($color ? mainLight : theme.colorVariants.hover)};
        }

        &:active,
        &:focus,
        &:focus-visible {
          outline: 0;
          border-color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
          color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
        }

        ${$active &&
        css<ActionProps>`
          outline: 0;
          border-color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
          color: ${({ theme, $color }) => ($color ? mainDark : theme.colors.primaryDark)};
        `}
      `,
    )}
  `;
}

export function linkVariantStyles(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { theme, $color, $danger, $hovering, $active, $iconOnly } = props;
  const { main, mainDark } = getColorsMap(theme, $color, $danger);

  return css<ActionProps>`
    background-color: transparent;
    border: none;

    text-decoration: none;

    ${disabledCheck(
      props,
      css<ActionProps>`
        color: ${actionDefaults.disabledColor};
        cursor: not-allowed;
      `,
      css<ActionProps>`
        color: ${main};

        &:hover {
          color: ${({ theme }) => theme.colorVariants.hover};
        }

        &:active {
          color: ${mainDark};
        }

        &:focus,
        &:focus-visible {
          outline: 0;
          text-decoration: none;
          color: ${mainDark};
        }

        &:focus-visible {
          box-shadow: ${$iconOnly ? `0 0 4px 1px ${main}` : 'none'};
        }

        ${($active || $hovering) &&
        css<ActionProps>`
          text-decoration: none;
          color: ${mainDark};
        `}
      `,
    )}
  `;
}

export function getSize(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { theme, $size, $stickied, $variant, $circular, $inline } = props;

  const { fontSize, lineHeight, radius, paddingX, paddingY } = theme.buttons.size[$size];

  const getPadding = () => {
    if ($inline) return '0';

    if ($variant === 'link') return `${paddingY}rem 0`;

    return `${paddingY}rem ${paddingX}rem`;
  };

  const getRadius = () => {
    if ($variant === 'link') return '0';

    return ((!$stickied && radius) || 4) + 'px';
  };

  if ($circular) {
    return css<ActionProps>`
      font-size: ${fontSize || 1}rem;
      line-height: ${lineHeight || 1};
      border-radius: 100%;
      padding: 0;
    `;
  }

  return css<ActionProps>`
    font-size: ${fontSize || 1}rem;
    line-height: ${lineHeight || 1.5};
    height: fit-content;
    border-radius: ${getRadius()};
    padding: ${getPadding()};
  `;
}

const STICKIED_MAP = {
  top: css`
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  `,
  right: css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `,
  bottom: css`
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `,
  left: css`
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  `,
  all: css`
    border-radius: 0;
  `,
};

function getStickiedBorders({ $stickied }: ThemedStyledProps<ActionProps, DefaultTheme>) {
  if (!$stickied) return null;

  return STICKIED_MAP[$stickied];
}

const VARIANT_MAP = {
  primary: primaryVariantStyles,
  secondary: secondaryVariantStyles,
  link: linkVariantStyles,
};

export function getVariant(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { $color, $variant } = props;

  const variant = $variant || 'primary';

  const color = $color || ($variant === 'link' ? 'link' : 'primary');

  return VARIANT_MAP[variant]({ ...props, $variant: variant, $color: color });
}

export function getIconPositions(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { $iconPosition, $circular, $iconOnly, $variant } = props;

  const spacing = $variant === 'link' ? '4px' : '8px';

  if ($circular || $iconOnly) {
    return css<ActionProps>`
      margin: 0;
    `;
  }

  if ($iconPosition === 'right') {
    return css<ActionProps>`
      margin-left: ${spacing};
    `;
  }

  return css<ActionProps>`
    margin-right: ${spacing};
  `;
}

function getAlignment(props: ThemedStyledProps<ActionProps, DefaultTheme>) {
  const { $circular } = props;

  if ($circular)
    return css<ActionProps>`
      display: flex;
      align-items: center;
      justify-content: center;
    `;

  return null;
}

function getWidth({ theme, $fluid, $fixedWidth }: ThemedStyledProps<ActionProps, DefaultTheme>) {
  if ($fluid) {
    return css`
      width: 100%;
    `;
  }

  if ($fixedWidth) {
    return css`
      width: ${theme.buttons.fixedWidth + 'rem'};
    `;
  }

  return css`
    width: auto;
    max-width: fit-content;
  `;
}

function getTextDecoration({ $variant, $underline, $active, $hovering }: ThemedStyledProps<ActionProps, DefaultTheme>) {
  if ($variant !== 'link') return null;

  return css`
    text-decoration: ${$underline ? 'underline' : 'none'};

    &:focus,
    &:focus-visible {
      text-decoration: underline;
    }

    ${($active || $hovering) &&
    css<ActionProps>`
      text-decoration: underline;
    `}
  `;
}

function getDisplay({ $inline }: ThemedStyledProps<ActionProps, DefaultTheme>) {
  if ($inline)
    return css`
      display: inline;
    `;

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
  `;
}

function getWordBreak({ $wordBreak }: ThemedStyledProps<ActionProps, DefaultTheme>) {
  if (!$wordBreak) return null;

  return css`
    word-break: ${$wordBreak};
  `;
}

export const Action = styled.button<ActionProps>`
  display: ${({ $inline }) => ($inline ? 'inline' : 'inline-flex')};
  justify-content: center;
  align-items: center;
  user-select: none;
  margin: 0;
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  ${getWordBreak};
  ${getVariant};
  ${getSize};
  ${getWidth};
  ${getStickiedBorders};

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

export const Icon = styled(IconNew)<ActionProps>`
  ${getIconPositions};
  ${getAlignment};
`;

export const ActionContentWrapper = styled.span<ActionProps>`
  ${getTextDecoration};
  ${getDisplay};

  ${({ $loading }) =>
    $loading &&
    css`
      visibility: hidden;
    `}
`;

export const ActionText = styled.span<ActionProps>`
  ${getTextDecoration};
`;
