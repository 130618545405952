import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import { LoaderSizes } from './styled';

export interface LoadingRingProps {
  /** Additional classes. */
  className?: string;

  /**
   * A Loading ring can have different sizes.
   *
   * @default "regular"
   * @expandType true
   */
  size?: LoaderSizes;
}

export const LoadingRing: FunctionComponent<LoadingRingProps> = ({ size, className }) => {
  return (
    <Styled.LoaderRing $size={size} className={className}>
      <svg viewBox="-4 -4 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stop-opacity="0" stop-color="currentColor" />
            <stop offset="50%" stop-opacity="0" stop-color="currentColor" />
            <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stop-opacity="1" stop-color="currentColor" />
            <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
          </linearGradient>
        </defs>

        <g x="8" y="8" stroke-width="16">
          <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
          <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />

          <path stroke="currentColor" stroke-linecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
        </g>
      </svg>
    </Styled.LoaderRing>
  );
};

LoadingRing.defaultProps = {
  size: 'regular',
};

export default LoadingRing;
