import React, { FunctionComponent } from 'react';
import Questionnaire from 'libraries/wizard/components/questionnaire';
import { useQuestionnaire } from './use-questionnaire';
import Translate from 'ui/atoms/translate';
import WideContent from 'core/layout/content/wide-content';
import ConfirmationNetWorthModal from 'libraries/wizard/components/questionnaire/confirmation-networth-modal';
import SavedNetworth from 'libraries/wizard/components/questionnaire/saved-networth';
import QuestionnaireLossabilityCalculator from 'libraries/wizard/components/questionnaire/questionnaire-lossability-calculator';
import QuestionnaireSummary from 'libraries/wizard/components/questionnaire/questionnaire-summary';
import LoadingRing from 'ui/atoms/loading-ring';
import WizardHeader from 'libraries/wizard/wizard-header';

const QuestionnaireStep: FunctionComponent = () => {
  const props = useQuestionnaire();
  const {
    investor,
    loading,
    savedNetWorth,
    isSavingDisabled,
    setLosabilityAnswers,
    onSaveLossabilityEntries,
    onEditQuestions,
    isConfirmModalOpen,
    setConfirmModalOpen,
    showSummary,
    questionnairePagesSegments,
    initialEducationOccupation,
    prefilledQuestions,
    surveyAnswers,
    onContinueSummary,
    onGoBackSummary,
    showLossabilityCalculator,
    setShowLossabilityCalculator,
    onContinueWithoutSavingNetworth,
    onSkipNetworth,
    restartNetworthCalculation,
    noAnswers,
    questionnairePage,
    setQuestionnairePage,
    isEcsp,
  } = props;

  if (!prefilledQuestions || !surveyAnswers || !investor || loading) return <LoadingRing />;

  const initialAnswers = prefilledQuestions.map((question) => ({
    ...question,
    selectedOption: surveyAnswers[question.id] ? String(surveyAnswers[question.id]) : undefined,
  }));

  const getQuestionnaireContent = () => {
    if (showSummary) {
      return (
        <WideContent title={<Translate name="questionnaireSummary.title" />}>
          <QuestionnaireSummary
            initialAnswers={initialAnswers}
            questions={prefilledQuestions}
            initialEducationOccupation={initialEducationOccupation}
            questionnairePagesSegments={questionnairePagesSegments}
            netWorth={savedNetWorth}
            onContinue={onContinueSummary}
            onGoBack={onGoBackSummary}
            noAnswers={noAnswers}
          />
        </WideContent>
      );
    }

    if (!investor?.id) return null;

    if (showLossabilityCalculator || questionnairePage === questionnairePagesSegments.length + 1) {
      return (
        <WideContent title={<Translate name="lossAbility.title" />}>
          {savedNetWorth ? (
            <SavedNetworth
              savedNetWorth={savedNetWorth}
              restartCalculation={restartNetworthCalculation}
              onSkipNetworth={onSkipNetworth}
            />
          ) : (
            <QuestionnaireLossabilityCalculator
              setLosabilityAnswers={setLosabilityAnswers}
              setShowLossabilityCalculator={setShowLossabilityCalculator}
              onEditQuestions={onEditQuestions}
              setConfirmModalOpen={setConfirmModalOpen}
              onSaveEntries={onSaveLossabilityEntries}
              isSavingDisabled={isSavingDisabled}
            />
          )}

          {isConfirmModalOpen && (
            <ConfirmationNetWorthModal
              onConfirm={onContinueWithoutSavingNetworth}
              onModalClose={() => setConfirmModalOpen(false)}
            />
          )}
        </WideContent>
      );
    }

    return <Questionnaire initialAnswers={initialAnswers} {...props} />;
  };

  return (
    <>
      <WizardHeader
        subStep={questionnairePage}
        subStepsNumber={isEcsp ? questionnairePagesSegments.length + 1 : questionnairePagesSegments.length}
        changeSubStep={setQuestionnairePage}
      />
      {getQuestionnaireContent()}
    </>
  );
};

export default QuestionnaireStep;
