import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Section from 'ui/atoms/section';
import { PersonType } from 'ui/types/person';
import { LegalPerson, NaturalPerson } from 'api';
import { UserDataSet } from 'ui/types/user-data-set';
import Link from 'ui/atoms/link';
import useTranslate from 'ui/hooks/use-translate';
import Grid, { Col } from 'ui/atoms/grid';

export interface InvestorUserInformationProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  userDataSet: UserDataSet;

  userType: 'investor' | 'issuer';

  naturalPerson?: NaturalPerson;

  legalPerson?: LegalPerson;

  personType: PersonType;
}

const InvestorUserInformation: FunctionComponent<InvestorUserInformationProps> = (props) => {
  const { className, userDataSet } = props;
  const endOfBlock = ['ibanBank'];

  const translate = useTranslate();

  return (
    <div className={cx('user-information', className)}>
      <Section>
        {Object.entries(userDataSet.fields).map((value: any, key: number) => {
          return (
            <Grid spacing={endOfBlock.includes(value[0]) ? 'large' : 'medium'} key={key}>
              <Col spacing={endOfBlock.includes(value[0]) ? 'medium' : undefined}>
                <Translate name={`dashboardInvestorSettings.${userDataSet.key}.${value[0]}`} />
              </Col>
              <Col>
                <PlaceholderFallback>{value[1]}</PlaceholderFallback>
              </Col>
            </Grid>
          );
        })}
      </Section>
      <p>
        <strong>
          <Translate name="dashboardInvestorSettings.changeSettingsSupport.title" />
        </strong>
      </p>
      <Translate
        as="p"
        name="dashboardInvestorSettings.changeSettingsSupport.description"
        args={[
          (_, key) => (
            <Translate key={key} name={`dashboardInvestorSettings.changeSettingsSupport.data.${userDataSet.key}`} />
          ),
        ]}
      />
      <Translate
        as="p"
        name="dashboardInvestorSettings.changeSettingsSupport.contact"
        args={[
          (_, key) => (
            <Link href={`mailto:${translate('platform.supportEmail')}`} key={key} inline>
              <Translate name="platform.supportEmail" />
            </Link>
          ),
        ]}
      />
    </div>
  );
};

export default InvestorUserInformation;
