import type { Wallet } from 'api';

export enum WalletsActions {
  WALLETS_FETCH = 'WALLETS/FETCH',
  WALLETS_FETCH_SUCCESS = 'WALLETS/FETCH_SUCCESS',
  WALLETS_FETCH_FAILURE = 'WALLETS/FETCH_FAILURE',
}

export type WalletsFetch = { type: WalletsActions.WALLETS_FETCH };
export type WalletsFetchSuccess = {
  type: WalletsActions.WALLETS_FETCH_SUCCESS;
  data: Wallet[];
};
export type WalletsFetchFailure = {
  type: WalletsActions.WALLETS_FETCH_FAILURE;
  error: Error;
};

export type WalletsActionTypes = WalletsFetch | WalletsFetchSuccess | WalletsFetchFailure;

export function walletsFetch(): WalletsFetch {
  return {
    type: WalletsActions.WALLETS_FETCH,
  };
}

export function walletsFetchSuccess(data: Wallet[]): WalletsFetchSuccess {
  return {
    type: WalletsActions.WALLETS_FETCH_SUCCESS,
    data,
  };
}

export function walletsFetchFailure(error: Error): WalletsFetchFailure {
  return {
    type: WalletsActions.WALLETS_FETCH_FAILURE,
    error,
  };
}
