import makeForm from 'ui/forms';

import { QuestionnaireFormFields, QuestionnairePagesSegments, QuestionnaireQuestionsGroup } from './types';

export const QuestionnaireForm = makeForm<QuestionnaireFormFields>();

export const DEFAULT_SEGMENT_KEY = 'questionnaire';

export const QUESTIONNAIRE_PAGES_SEGMENTS: QuestionnairePagesSegments = [
  {
    page: 1,
    segments: [
      {
        index: 1,
        values: [QuestionnaireQuestionsGroup.Education],
        title: QuestionnaireQuestionsGroup.Education,
      },
      {
        index: 2,
        values: [QuestionnaireQuestionsGroup.InvestmentExperience],
        title: QuestionnaireQuestionsGroup.InvestmentExperience,
      },
    ],
  },
  {
    page: 2,
    segments: [
      {
        index: 3,
        values: [QuestionnaireQuestionsGroup.ServiceExperience],
        title: QuestionnaireQuestionsGroup.ServiceExperience,
      },
      {
        index: 4,
        values: [QuestionnaireQuestionsGroup.Volume],
        title: QuestionnaireQuestionsGroup.Volume,
      },
    ],
  },
];

export const QUESTIONNAIRE_PAGES_SEGMENTS_ECSP: QuestionnairePagesSegments = [
  {
    page: 1,
    segments: [
      {
        index: 1,
        values: [QuestionnaireQuestionsGroup.Education],
        title: QuestionnaireQuestionsGroup.Education,
      },
      {
        index: 2,
        values: [QuestionnaireQuestionsGroup.InvestmentExperience],
        title: QuestionnaireQuestionsGroup.InvestmentExperience,
      },
    ],
  },
  {
    page: 2,
    segments: [
      {
        index: 3,
        values: [QuestionnaireQuestionsGroup.ServiceExperience],
        title: QuestionnaireQuestionsGroup.ServiceExperience,
      },
      {
        index: 4,
        values: [QuestionnaireQuestionsGroup.Volume],
        title: QuestionnaireQuestionsGroup.Volume,
      },
    ],
  },
  {
    page: 3,
    segments: [
      {
        index: 5,
        values: [QuestionnaireQuestionsGroup.ProfessionalInvestment],
        title: QuestionnaireQuestionsGroup.ProfessionalInvestment,
      },
      {
        index: 6,
        values: [
          QuestionnaireQuestionsGroup.InvestmentDurationObjective,
          QuestionnaireQuestionsGroup.InvestmentSustainabilityObjective,
          QuestionnaireQuestionsGroup.InvestmentObjectivesTarget,
        ],
        title: QuestionnaireQuestionsGroup.InvestmentObjectivesTarget,
      },
    ],
  },
];
