import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import ServerError from 'ui/types/server-error';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import Button from 'ui/atoms/button';

export interface IdNowLandingPageProps {
  /** Click handler for button. */
  onClick?: () => void;

  /** Button loading */
  loading?: boolean;

  /** Error message */
  error?: ServerError;

  /** Is the verification process already started? */
  alreadyStarted?: boolean;

  /** Additional classes. */
  className?: string;
}

const IdNowLandingPage: FunctionComponent<IdNowLandingPageProps> = (props) => {
  const { className, loading = false, error, onClick = () => {}, alreadyStarted = false, ...restProps } = props;

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const errors = getRemainingError();

  return (
    <div className={cx('id-now-landing-page', className)} {...restProps}>
      <p>
        <Translate name="idNow.startText" />
      </p>

      {alreadyStarted && (
        <Hint variant={'warning'}>
          <Translate name="idNow.alreadyStartedHint" />
        </Hint>
      )}
      <p>
        <Button size="large" fluid={true} variant="secondary" loading={loading} onClick={onClick} error={errors}>
          <Translate name="idNow.startButton" />
        </Button>
      </p>

      <p>
        <Translate name="idNow.startTextBottom" />
      </p>
    </div>
  );
};

export default IdNowLandingPage;
