import React from 'react';
import SplitFormSegment from 'libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment/split-form-segment';
import DefaultFormSegment from 'libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment/default-form-segment';
import type { FunctionComponent } from 'react';
import {
  QuestionnaireQuestions,
  QuestionnaireQuestionsGroup,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import ToggleOptionsSegment from './toggle-options-segment';
import SummarySegment from './summary-segment';

export interface QuestionnaireFormSegmentProps {
  segmentIndex: number;
  segmentId: string;
  questionsSegment: Array<QuestionnaireQuestions>;
  isEcsp: boolean;
  isSummary?: boolean;
  className?: string;
}

const QuestionnaireFormSegment: FunctionComponent<QuestionnaireFormSegmentProps> = ({
  segmentId,
  questionsSegment,
  isEcsp,
  isSummary,
}) => {
  if (!questionsSegment) return null;

  // questionnaires which are unified don't have the "investmentknowledge" id, only the "investmentexperience".
  const isSplitQuestionGroup = questionsSegment.some((question) =>
    question.id.includes(QuestionnaireQuestionsGroup.InvestmentKnowledge),
  );
  const isObjectivesTargetGroup = questionsSegment.some(
    (question) => question.groupId === QuestionnaireQuestionsGroup.InvestmentObjectivesTarget,
  );
  const isObjectivesSustainabilityGroup = questionsSegment.some(
    (question) => question.groupId === QuestionnaireQuestionsGroup.InvestmentSustainabilityObjective,
  );

  if (isSummary) {
    return (
      <SummarySegment questions={questionsSegment} segmentId={segmentId} isSplitQuestionGroup={isSplitQuestionGroup} />
    );
  }

  if (isSplitQuestionGroup) {
    return <SplitFormSegment questions={questionsSegment} segmentId={segmentId} isEcsp={isEcsp} />;
  }

  if (isObjectivesTargetGroup) {
    return (
      <ToggleOptionsSegment
        questions={questionsSegment}
        title={'questionnaire.parts.investmentobjectivesTarget.subtitles.objectives'}
      />
    );
  }

  if (isObjectivesSustainabilityGroup) {
    return (
      <ToggleOptionsSegment
        questions={questionsSegment}
        title={'questionnaire.parts.investmentobjectivesTarget.subtitles.sustainability'}
      />
    );
  }

  return <DefaultFormSegment questions={questionsSegment} isEcsp={isEcsp} />;
};

export default QuestionnaireFormSegment;
