import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Container from 'ui/atoms/container';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import PersonName from 'ui/atoms/person-name';
import Date from 'ui/atoms/date';
import Hint from 'ui/atoms/hint';
import { SalutationEnum } from 'api';

export interface ExternalInvestorHintProps {
  externalNaturalPerson: {
    salutation?: SalutationEnum;
    forename: string;
    surname: string;
    birthDate?: Date | null;
    birthPlace?: string;
  };

  /** Additional classes. */
  className?: string;
}

const ExternalInvestorHint: FunctionComponent<ExternalInvestorHintProps> = (props) => {
  const { className, children, externalNaturalPerson, ...restProps } = props;

  return (
    <Hint variant="info" className={cx('external-investor-hint', className)} spacing="large" {...restProps}>
      <Container>
        <Section>
          <Translate as="p" name="createAccount.externalInvestor.dataTransferred" />
        </Section>
        {/*  TODO(mara-cashlink): reduce space between <p> tags below*/}
        <p>
          <Translate as="strong" name="createAccount.externalInvestor.data" />
        </p>
        <p>
          <PersonName person={externalNaturalPerson} withSalutation={!!externalNaturalPerson.salutation} />
        </p>
        {externalNaturalPerson.birthDate && (
          <Translate
            as="p"
            name="createAccount.externalInvestor.birthData"
            args={[<Date key={0}>{externalNaturalPerson.birthDate}</Date>, externalNaturalPerson.birthPlace]}
          />
        )}
      </Container>
    </Hint>
  );
};

export default ExternalInvestorHint;
