import React, { FunctionComponent, useState, useEffect } from 'react';
import Checkbox from 'ui/atoms/checkbox';
import Translate from 'ui/atoms/translate';

interface ResetConfirmationProps {
  onChangeUnpairConfirmation: (isChecked: boolean) => void;
}

const ResetConfirmation: FunctionComponent<ResetConfirmationProps> = (props) => {
  const { onChangeUnpairConfirmation } = props;

  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    onChangeUnpairConfirmation(isChecked);
  }, [isChecked]);

  return (
    <div>
      <p>
        <Translate name="resetWallet.unpair" as="b" />
      </p>
      <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)}>
        <Translate name="resetWallet.unpairConfirm" />
      </Checkbox>
    </div>
  );
};

export default ResetConfirmation;
