export enum InvestmentsActions {
  INVESTOR_HAS_INVESTMENTS_FETCH = 'INVESTOR/HAS_INVESTMENTS_FETCH',
  INVESTOR_HAS_INVESTMENTS_FETCH_FAILURE = 'INVESTOR/HAS_INVESTMENTS_FETCH_FAILURE',
  INVESTOR_HAS_INVESTMENTS_FETCH_SUCCESS = 'INVESTOR/HAS_INVESTMENTS_FETCH_SUCCESS',
  INVESTOR_HAS_ECSP_INVESTMENTS_FETCH = 'INVESTOR/HAS_ECSP_INVESTMENTS_FETCH',
  INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_FAILURE = 'INVESTOR/HAS_ECSP_INVESTMENTS_FETCH_FAILURE',
  INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_SUCCESS = 'INVESTOR/HAS_ECSP_INVESTMENTS_FETCH_SUCCESS',
}

export type InvestorHasInvestmentsFetch = {
  type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH;
};

export type InvestorHasInvestmentsFetchFailure = {
  type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_FAILURE;
  error: Error;
};

export type InvestorHasInvestmentsFetchSuccess = {
  type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_SUCCESS;
  data: boolean;
};

export type InvestorHasEcspInvestmentsFetch = {
  type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH;
};
export type InvestorHasEcspInvestmentsFetchFailure = {
  type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_FAILURE;
  error: Error;
};
export type InvestorHasEcspInvestmentsFetchSuccess = {
  type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_SUCCESS;
  data: boolean;
};

export type InvestmentsActionTypes =
  | InvestorHasInvestmentsFetch
  | InvestorHasInvestmentsFetchSuccess
  | InvestorHasInvestmentsFetchFailure
  | InvestorHasEcspInvestmentsFetch
  | InvestorHasEcspInvestmentsFetchSuccess
  | InvestorHasEcspInvestmentsFetchFailure;

export function investorHasInvestmentsFetch(): InvestorHasInvestmentsFetch {
  return {
    type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH,
  };
}

export function investorHasInvestmentsFetchSuccess(data: boolean): InvestorHasInvestmentsFetchSuccess {
  return {
    type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_SUCCESS,
    data,
  };
}

export function investorHasInvestmentsFetchFailure(error: Error): InvestorHasInvestmentsFetchFailure {
  return {
    type: InvestmentsActions.INVESTOR_HAS_INVESTMENTS_FETCH_FAILURE,
    error,
  };
}

export function investorHasEcspInvestmentsFetch(): InvestorHasEcspInvestmentsFetch {
  return {
    type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH,
  };
}

export function investorHasEcspInvestmentsFetchSuccess(data: boolean): InvestorHasEcspInvestmentsFetchSuccess {
  return {
    type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_SUCCESS,
    data,
  };
}

export function investorHasEcspInvestmentsFetchFailure(error: Error): InvestorHasEcspInvestmentsFetchFailure {
  return {
    type: InvestmentsActions.INVESTOR_HAS_ECSP_INVESTMENTS_FETCH_FAILURE,
    error,
  };
}
