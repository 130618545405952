import React, { FunctionComponent, useMemo, useState } from 'react';
import * as Styled from './styled';
import { ProgressStep } from 'core/layout/wizard/progress-steps';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';

export interface ProgressStepsDropdownProps {
  steps: ProgressStep[];
}

const ProgressStepsDropdown: FunctionComponent<ProgressStepsDropdownProps> = (props) => {
  const { steps } = props;

  const [stepHover, setStepHover] = useState(-1);

  const activeStep = useMemo(() => {
    const activeStepsArr = steps.filter(({ active }) => !!active);
    return activeStepsArr?.length ? activeStepsArr[0] : steps[0];
  }, [steps]);

  const onStepChange = (stepId: number) => {
    const stepObj = steps.filter((step) => step.id === stepId)[0];
    if (stepObj.onClick) stepObj.onClick();
  };

  const isPhone = useIsMedia(DEVICES.phone);

  return (
    <>
      <Styled.ProgressStepsMargin />
      <Styled.ProgressSelect
        className={'select'}
        dark
        isSearchable={false}
        radius={{
          control: '56px',
          menu: '32px',
        }}
        value={activeStep.id}
        onChange={(stepId: string) => onStepChange(parseInt(stepId))}
        customComponents={{
          IndicatorSeparator: () => (
            <Styled.StepCount>
              {activeStep.id + 1}/{steps.length}
            </Styled.StepCount>
          ),
        }}
        styles={{
          menu: {
            marginTop: isPhone ? '0.75rem' : '1rem',
            borderBottomLeftRadius: '32px',
            borderBottomRightRadius: '32px',
          },
        }}
        options={steps.map(({ id, done, active, current, title, onClick }, index) => ({
          value: id,
          label: (
            <Styled.ProgressStepItem
              onMouseEnter={() => setStepHover(id)}
              onMouseLeave={() => setStepHover(-1)}
              key={index}
              $done={!!done}
              $active={!!active}
              $current={current}
              $clickable={!!onClick}
            >
              <Styled.ProgressStepNumber
                $done={!!done}
                $active={!!active}
                $current={current}
                $clickable={!!onClick}
                $hover={stepHover === id}
              >
                {done ? <Styled.CheckIcon name="check" $done={!!done} /> : index + 1}
              </Styled.ProgressStepNumber>
              <Styled.ProgressStepTitle>{title}</Styled.ProgressStepTitle>
            </Styled.ProgressStepItem>
          ),
        }))}
      />
      <Styled.ProgressStepsMargin />
    </>
  );
};

export default ProgressStepsDropdown;
