import React, { FunctionComponent, useContext } from 'react';
import Translate from 'ui/atoms/translate';
import { PlatformList } from 'platforms';
import { PlatformContext } from 'ui/platform/provider';
import config from 'core/config/local';
import Dropdown, { DropdownItem } from 'ui/atoms/dropdown';
import Text from 'ui/polymorphs/text';
import { HorizontalAlign } from 'ui/types/align';

const PlatformSelectorItem: FunctionComponent<{
  textColor?: string;
  openPosition?: HorizontalAlign;
}> = ({ textColor = 'white', openPosition = 'left' }) => {
  const { activePlatform, changePlatform } = useContext(PlatformContext);
  if (!config.enablePlatformSwitch) return null;

  return (
    <>
      <Dropdown
        id="platform-selector"
        title={<Translate name={`platforms.${activePlatform}`} />}
        className="nav-item"
        hasDynamicArrow={true}
        color={textColor}
        openPosition={openPosition}
      >
        {PlatformList.map((platform) => (
          <DropdownItem onClick={() => changePlatform(platform)} active={platform === activePlatform} key={platform}>
            <Text color="textColor">
              <Translate name={`platforms.${platform}`} />
            </Text>
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

export default PlatformSelectorItem;
