import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { push } from 'connected-react-router';
import makeLink from 'helper/make-link';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { useCurrentUserSelector } from 'core/auth/hooks';
import { isInvestor } from 'core/auth/helper';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';

// todo: root component of the apps should be better handled, this is a temporary "patch"
const DashboardPage: FunctionComponent = () => {
  const { currentUser } = useCurrentUserSelector();
  const isInvestorUser = isInvestor(currentUser);

  const dispatch = useDispatch();
  const goToContinueInvestments = useGoTo(INVESTMENT_ROUTES.invitationsContinue);

  useEffect(() => {
    // Redirect to latest invitation process if email is unconfirmed
    if (!currentUser?.confirmed) {
      goToContinueInvestments();
      return;
    }

    const link = isInvestorUser ? INVESTOR_ROUTES.overview : ISSUER_ROUTES.overview;

    dispatch(push(makeLink(link)));
  }, [dispatch, currentUser?.confirmed, isInvestorUser]);

  return null;
};

export default WithAuthenticatedPage(DashboardPage);
