import React, { FunctionComponent } from 'react';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Translate from 'ui/atoms/translate';
import FormToggleOption from 'src/libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment/toggle-option';

export interface ToggleOptionsSegmentProps {
  title?: string;
  questions: (QuestionnaireQuestions & { displayName?: string })[];
  options?: {
    name: string;
    value: string;
  }[];
}

const ToggleOptionsSegment: FunctionComponent<ToggleOptionsSegmentProps> = ({ title, questions, options }) => {
  return (
    <>
      {title && <Translate name={title} as="b" />}
      {questions.map((question) => (
        <FormToggleOption
          name={question.displayName || question.id}
          value={question.id}
          options={options}
          key={question.id}
        />
      ))}
    </>
  );
};

export default ToggleOptionsSegment;
