import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'store/hooks';
import makeLink from 'helper/make-link';
import type { RouteDefinition } from 'core/routing/types';

const useGoTo = (route: RouteDefinition, absolute = false) => {
  const dispatch = useDispatch();

  return useCallback(
    (params: object = {}) => dispatch(push(makeLink(route, params, absolute))),
    [route, absolute, dispatch],
  );
};

export default useGoTo;
