import { Cmd, loop, Loop, LoopReducerWithDefinedState } from 'redux-loop';
import { mergeAll } from 'lodash/fp';
import { InvestorActions, investorFetchSuccess, investorFetchFailure } from './actions';
import { getInvestorEffect } from './effects';
import type { InvestorActionTypes, InvestorFetch, InvestorFetchFailure, InvestorFetchSuccess } from './actions';
import type { Investor } from 'api';
import { RootActions, WithRootActionTypes } from 'store/actions';

export type InvestorStateShape = {
  data: Investor | null;
  loading: boolean;
  error: Error | null;
};

export const initialState: InvestorStateShape = {
  data: null,
  loading: false,
  error: null,
};

type InvestorLoop<A> = (state: InvestorStateShape, action: A) => InvestorStateShape | Loop<InvestorStateShape>;

const handleInvestorFetch: InvestorLoop<InvestorFetch> = (state) => {
  return loop(
    mergeAll([state, { loading: true, error: false }]),
    Cmd.run(getInvestorEffect, {
      args: [Cmd.getState],
      successActionCreator: investorFetchSuccess,
      failActionCreator: investorFetchFailure,
    }),
  );
};

const handleInvestorFetchSuccess: InvestorLoop<InvestorFetchSuccess> = (state, { data }) => {
  return mergeAll([
    state,
    {
      data,
      loading: false,
      error: null,
    },
  ]);
};

const handleInvestorFetchFailure: InvestorLoop<InvestorFetchFailure> = (state, { error }) => {
  return mergeAll([
    state,
    {
      data: null,
      loading: false,
      error: error,
    },
  ]);
};

const reducer: LoopReducerWithDefinedState<InvestorStateShape, WithRootActionTypes<InvestorActionTypes>> = (
  state = initialState,
  action,
): InvestorStateShape | Loop<InvestorStateShape> => {
  switch (action.type) {
    case RootActions.RESET_STATE:
      return initialState;
    case InvestorActions.INVESTOR_FETCH:
      return handleInvestorFetch(state, action);
    case InvestorActions.INVESTOR_FETCH_SUCCESS:
      return handleInvestorFetchSuccess(state, action);
    case InvestorActions.INVESTOR_FETCH_FAILURE:
      return handleInvestorFetchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
