import { Col } from 'ui/atoms/grid';
import styled from 'styled-components';
import Container from 'ui/atoms/container';

export const StatusRow = styled(Container)`
  height: 70px;
  margin: 0;

  &:last-of-type {
    margin-bottom: -30px;
  }
`;

export const IconCol = styled(Col)`
  flex: 0 0 20px;
  padding: 0;
`;

export const Title = styled.div`
  && {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 2px;
  }
`;

export const TransactionDescription = styled(Col)`
  margin-top: 5px;
  font-size: 14px;
`;
