import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { AdminApi, AdminInvestmentsListRequest, InvestmentAcquisitionTypeRecipientEnum, AdminInvestment } from 'api';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import StudioInvestmentsTable from 'src/apps/issuer/shared/studio-investments-table';
import useApiCall from 'hooks/use-api-call';
import makeLink from 'helper/make-link';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';

interface IssuerInvestmentsTableProps extends AdminInvestmentsListRequest {
  investmentId: string | null;
}

const InvitationInvestment: FunctionComponent<IssuerInvestmentsTableProps> = ({ investmentId }) => {
  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);
  const campaignLink = makeLink(INVESTMENT_ROUTES.investment, { investmentId }, true);

  const [investments, setInvestments] = useState<AdminInvestment[]>();

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!investmentId) {
        return;
      }
      withApi(async () => {
        const investmentsData = await api.adminInvestmentsRetrieve({
          id: investmentId,
        });

        if (investmentsData) setInvestments([investmentsData]);
      });
    });
  }, [withApi, api, investmentId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const goTo = useGoTo(ISSUER_ROUTES.investmentDetails);

  return (
    <>
      <StudioInvestmentsTable
        loading={loading}
        emptyView={
          <IssuerEmptyView type={EmptyViewType.ISSUER_INVITATION_DETAILS_INVESTMENTS} campaignLink={campaignLink} />
        }
        goToDetails={(productId: string, investmentId: string) => goTo({ productId, investmentId })}
        investments={(investments || []).map((investment) => ({
          ...investment,
          acquisitionType: investment.acquisitionType || InvestmentAcquisitionTypeRecipientEnum.ISSUANCE,
        }))}
        noProduct
      />
    </>
  );
};

export default InvitationInvestment;
