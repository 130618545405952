import React, { FunctionComponent } from 'react';
import Icon from 'ui/atoms/icon';
import Translate from 'ui/atoms/translate';
import * as Styled from './styled';

const PresentSteps: FunctionComponent = () => {
  return (
    <div>
      <p>
        <Translate name="resetWallet.stepsTitle" as="h2" />
      </p>
      <Styled.StepsContainer>
        <Styled.StepItem>
          <Icon name="unlink" color="primary" size="large" />
          <Styled.Description>
            <Translate name="resetWallet.step1Title" as="h2" />
            <Translate name="resetWallet.step1Description" />
          </Styled.Description>
        </Styled.StepItem>
        <Styled.StepItem>
          <Icon name="mail" color="primary" size="large" />
          <Styled.Description>
            <Translate name="resetWallet.step2Title" as="h2" />
            <Translate name="resetWallet.step2Description" />
          </Styled.Description>
        </Styled.StepItem>
        <Styled.StepItem>
          <Icon name="touch" color="primary" size="large" />
          <Styled.Description>
            <Translate name="resetWallet.step3Title" as="h2" />
            <Translate name="resetWallet.step3Description" />
          </Styled.Description>
        </Styled.StepItem>
      </Styled.StepsContainer>
    </div>
  );
};

export default PresentSteps;
