import React, { FunctionComponent } from 'react';
import { StyledDropdownItem } from './styled';
import cx from 'ui/helper/prefixed-class-names';

export interface DropdownItemProps {
  onClick: () => void;
  /** Active item */
  active?: boolean;
}

export const DropdownItem: FunctionComponent<DropdownItemProps> = (props) => {
  const { onClick, active, children } = props;

  return (
    <StyledDropdownItem href="#" onMouseDown={onClick} className={cx({ active })} role="button">
      {children}
    </StyledDropdownItem>
  );
};

export default DropdownItem;
