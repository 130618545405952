import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Icon from 'ui/atoms/icon';
import { isValidDependingOnTouched } from 'ui/helper/validators';
import castArray from 'lodash/castArray';
import Spacing from 'ui/types/spacing';
import Translate from 'ui/atoms/translate';
import Spacer from 'ui/atoms/spacer';
import * as Styled from './styled';
import Box from 'ui/polymorphs/box';
import Text from 'ui/polymorphs/text';
import { BoxDirectionOptions } from 'ui/polymorphs/box/styled';

export const makeRequirement = (description: ReactNode, valid: boolean, touched?: boolean): Requirement => ({
  description,
  met: isValidDependingOnTouched(valid, touched),
});

export interface Requirement {
  description: ReactNode;
  met?: boolean;
}

export interface FormGroupProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes */
  className?: string;

  /** Form label */
  label?: string | ReactNode;

  /** Occupy label space even though it is hidden */
  occupyHiddenLabelSpace?: boolean;

  /** Help text */
  helpText?: ReactNode;

  /** A form group may have multiple errors to show */
  error?: ReactNode | ReactNode[];

  /** A form group may have multiple requirements to be met */
  requirements?: Requirement[];

  /** A from group may hava an information */
  info?: ReactNode;

  /** Spacing */
  spacing?: Spacing;

  /** Optional */
  optional?: boolean;

  hasLabelOnSameRow?: boolean;

  direction?: BoxDirectionOptions;
}

const LabelOnSameRow: FunctionComponent<FormGroupProps> = (props) => {
  const { children, label, occupyHiddenLabelSpace, helpText, optional } = props;

  return (
    <Styled.SameRow>
      {(label || occupyHiddenLabelSpace) && (
        <Styled.FormGroupLabel>
          <strong>
            {!optional && label}
            {optional && <Translate name="form.group.nonRequired" args={[label]} />}
          </strong>

          {helpText && <Icon name="question" circular={true} size="small" color="info" />}
        </Styled.FormGroupLabel>
      )}
      <Spacer x={6} />
      {children}
    </Styled.SameRow>
  );
};

export const Info: FunctionComponent<FormGroupProps> = (props) => {
  const { children } = props;

  if (!children) return null;

  return (
    <Styled.RequirementsInfo className="info">
      <Text as="small" color="disabled" format={['small']}>
        {children}
      </Text>
    </Styled.RequirementsInfo>
  );
};

export const Error: FunctionComponent<FormGroupProps> = (props) => {
  const { children } = props;

  if (!children) return null;

  return (
    <Styled.RequirementsInfo>
      <Icon name="exclamation-circle" color="danger" size="small" />
      <Spacer x={1} inline />
      <Text as="small" color="danger" format={['small']}>
        {children}
      </Text>
    </Styled.RequirementsInfo>
  );
};

export const FormGroup: FunctionComponent<FormGroupProps> = (props) => {
  const {
    className,
    children,
    label,
    occupyHiddenLabelSpace,
    helpText,
    requirements,
    error,
    spacing = 'medium',
    direction = 'column',
    info,
    optional,
    hasLabelOnSameRow = false,
    ...restProps
  } = props;

  const errors = castArray(error || []);

  return (
    <Box spacing={spacing} direction={direction} className={cx('form-group', className)} {...restProps}>
      {hasLabelOnSameRow && label ? (
        <LabelOnSameRow
          label={label}
          occupyHiddenLabelSpace={occupyHiddenLabelSpace}
          optional={optional}
          helpText={helpText}
          children={children}
        />
      ) : (
        <>
          {(label || occupyHiddenLabelSpace) && (
            <Styled.FormGroupLabel>
              <strong>
                {!optional && label}
                {optional && <Translate name="form.group.nonRequired" args={[label]} />}
              </strong>{' '}
              &nbsp;
              {helpText && <Icon name="question" circular={true} size="small" color="info" />}
            </Styled.FormGroupLabel>
          )}
          {children}
        </>
      )}

      {info && (
        <>
          <Spacer y={1} />
          <Styled.RequirementsInfo className="info">
            <Text as="small" color="disabled" format={['small']}>
              {info}
            </Text>
          </Styled.RequirementsInfo>
        </>
      )}

      <Styled.FormGroupRequirements className="requirements">
        {!!errors.length &&
          errors.map((error, index) => (
            <>
              <Spacer y={1} />
              <Styled.RequirementsInfo key={index}>
                <Styled.ErrorIcon name="exclamation-circle" color="danger" size="small" />
                <Spacer x={1} inline />
                <Text as="small" color="danger" format={['small']}>
                  {error}
                </Text>
              </Styled.RequirementsInfo>
            </>
          ))}
        {requirements &&
          requirements.map((req, index) => (
            <Styled.RequirementsInfo key={index}>
              <Styled.RequirementInfoIcon
                name={req.met || req.met === undefined ? 'check' : 'cross'}
                color={req.met ? 'success' : req.met === undefined ? 'disabled' : 'danger'}
              />
              <Spacer x={1} inline />
              <Text as="small" color="disabled" format={['small']}>
                {req.description}
              </Text>
            </Styled.RequirementsInfo>
          ))}
      </Styled.FormGroupRequirements>
    </Box>
  );
};

export default FormGroup;
