export const Snippets = {
  tags: function ({ id }: { id: string }) {
    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0" width="0" style="display:none;visibility:hidden">
      </iframe>`;

    const script = (window: any, document: any, script: any, dataLayerName: string, id: string) => {
      window[dataLayerName] = window[dataLayerName] || [];
      window[dataLayerName].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      let oldScriptTag = document.getElementsByTagName(script)[0];
      let newScriptTag = document.createElement(script);
      let dataLayer = dataLayerName !== 'dataLayer' ? '&l=' + dataLayerName : '';
      newScriptTag.async = true;
      newScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dataLayer;
      oldScriptTag.parentNode.insertBefore(newScriptTag, oldScriptTag);
    };

    return {
      iframe,
      script,
    };
  },
};

export const TagManager = {
  gtm: function (args: { id: string }) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = (window: any, document: any, script: any, dataLayerName: string, gtmId: string) => {
      snippets.script(window, document, script, dataLayerName, gtmId);
    };

    return {
      noScript,
      script,
    };
  },

  initialize: function (gtmId: string) {
    const gtm = this.gtm({
      id: gtmId,
    });

    gtm.script(window, document, 'script', 'dataLayer', gtmId);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};
