import React, { FunctionComponent, ReactNode } from 'react';
import Icon from 'ui/atoms/icon';
import type Padding from 'ui/types/padding';
import type Size from 'ui/types/size';
import type { Icons } from 'ui/assets/icons/types';
import Spacer from 'ui/atoms/spacer';
import * as Styled from './styled';
import type { Colors } from 'ui/styles/utils/colors';
import cx from 'ui/helper/prefixed-class-names';

export interface TagProps {
  /** Additional classes. */
  className?: string;

  /** Primary content. */
  children?: ReactNode;

  /**
   * Color of the tag.
   *
   * @expandType true
   */
  color?: Colors;

  /**
   * Color of the icon.
   *
   * @expandType true
   */
  iconColor?: Colors;

  /**
   * Color of the text.
   *
   * @expandType true
   */
  textColor?: Colors;

  /**
   * A tag can have different sizes.
   *
   * @expandType true
   **/
  size?: Size;

  /** A tag may be formatted to raise above the page. */
  raised?: boolean;

  /** A tag may take up only as much space as is necessary. */
  compact?: boolean;

  /** A tag may have an icon. */
  iconName?: Icons;

  truncate?: boolean;

  padded?: Padding;
}

export const Tag: FunctionComponent<TagProps> = (props) => {
  const {
    children,
    color = 'primary',
    size,
    raised,
    compact,
    truncate,
    padded = 'tiny',
    iconName,
    iconColor,
    textColor,
    className,
  } = props;

  return (
    <Styled.Tag
      $color={color}
      $truncate={truncate}
      $padding={padded}
      $compact={compact}
      $raised={raised}
      $size={size || 'medium'}
      className={cx('tag', className)}
    >
      {iconName && (
        <>
          <Icon name={iconName} color={iconColor} className="tag-icon" />
          <Spacer x={2} inline />
        </>
      )}
      <Styled.Content $color={color} $textColor={textColor}>
        {children}
      </Styled.Content>
    </Styled.Tag>
  );
};

export default Tag;
