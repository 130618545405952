import React, { FunctionComponent, useState, useCallback } from 'react';
import Icon from 'ui/atoms/icon';
import Text from 'src/ui/polymorphs/text';
import Spacer from 'src/ui/atoms/spacer';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import ReceiveAssetsModal from './receive-assets-modal';

export interface ReceivingTransfersProps {
  investorId: string;
  ethAddress: string | null | undefined;
  receivingEnabled: boolean;
  stellarAddress: string | null | undefined;
  walleId: string;
  onSavingReceiveAssets: () => void;
}

const ReceivingTransfers: FunctionComponent<ReceivingTransfersProps> = ({
  investorId,
  ethAddress,
  receivingEnabled,
  stellarAddress,
  walleId,
  onSavingReceiveAssets,
}) => {
  const [selectedEthAddress, setSelectedEthAddress] = useState<string | null | undefined>(null);
  const [selectedStellarAddress, setSelectedStellarAddress] = useState<string | null | undefined>(null);
  const [selectedWalletId, setWalletId] = useState<string>('');
  const [isReceiveAssetsModalOpen, setReceiveAssetsModalOpen] = useState(false);

  const onClickReceiveAssets = useCallback(
    (ethAddress: string | null | undefined, stellarAddress: string | null | undefined, walletId: string) => {
      setSelectedEthAddress(ethAddress);
      setSelectedStellarAddress(stellarAddress);
      setWalletId(walletId);
      setReceiveAssetsModalOpen(true);
    },
    [],
  );

  const onSavingSuccessfully = useCallback(async () => {
    onSavingReceiveAssets();
    setReceiveAssetsModalOpen(false);
  }, []);

  const onReceiveAssetsModalClose = useCallback(() => {
    setReceiveAssetsModalOpen(false);
  }, []);

  return (
    <>
      {receivingEnabled ? (
        <>
          <Icon name="check" color="gray" />
          <Spacer x={1} inline />
          <Text as="small" color="gray">
            <Translate name={`dashboardInvestorSettings.wallets.receiveAssetsApproved`} />
          </Text>
        </>
      ) : (
        <Button variant="link" onClick={() => onClickReceiveAssets(ethAddress, stellarAddress, walleId)}>
          <Translate name={`dashboardInvestorSettings.wallets.receiveAssets`} />
        </Button>
      )}

      {isReceiveAssetsModalOpen && (
        <ReceiveAssetsModal
          ethAddress={selectedEthAddress}
          stellarAddress={selectedStellarAddress}
          walletId={selectedWalletId}
          onSavingSuccessfully={() => onSavingSuccessfully()}
          onModalClose={() => onReceiveAssetsModalClose()}
          investorId={investorId}
        />
      )}
    </>
  );
};

export default ReceivingTransfers;
