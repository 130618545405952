import React, { FunctionComponent, ReactNode } from 'react';
import Container from 'ui/atoms/container';
import PageTitle from 'core/components/page-title';
import Header from 'ui/atoms/header';
import Spacing from 'ui/types/spacing';
import Grid, { Col } from 'ui/atoms/grid';

const WideContent: FunctionComponent<{
  title?: ReactNode;
  headerPanel?: ReactNode;
  ignorePageTitle?: boolean;
  titleSpacing?: Spacing;
}> = ({ children, title, headerPanel, ignorePageTitle, titleSpacing = 'large' }) => {
  return (
    <Container>
      {/*todo: fix centering here*/}
      <Grid>
        <Col width={12}>
          {headerPanel && <div style={{ float: 'right', marginTop: '-0.5em' }}>{headerPanel}</div>}
          {title && (
            <Header size={ignorePageTitle ? 'small' : 'large'} spacing={titleSpacing}>
              {!ignorePageTitle && <PageTitle renderInPlace={false}>{title}</PageTitle>}
              {title || <>&nbsp;</>}
            </Header>
          )}

          {React.Children.map(children, (element, i) => {
            if (!React.isValidElement(element)) return;
            const { title: childTitle } = element.props;

            return (
              <>
                {!title && childTitle && !i && <PageTitle renderInPlace={false}>{childTitle}</PageTitle>}
                {element}
              </>
            );
          })}
        </Col>
      </Grid>
    </Container>
  );
};

export default WideContent;
