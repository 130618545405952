import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import { compact } from 'lodash';
import Number from 'ui/atoms/number';

export type Mode = 'confirm' | 'running' | 'success' | 'error';

export interface StudioConfirmMultiplesModalProps {
  /** Additional classes. */
  className?: string;

  i18nKey: 'approveOffers' | 'confirmPayments';

  totalNumber: number;

  mode: Mode;

  confirmedNumber: number;

  onConfirm: () => void;

  onClose: () => void;
}

const StudioConfirmMultiplesModal: FunctionComponent<StudioConfirmMultiplesModalProps> = (props) => {
  const { className, totalNumber, confirmedNumber, mode, i18nKey, onConfirm, onClose } = props;

  const getDescription = (mode: Mode) => {
    return (
      <>
        <Translate
          as={['success', 'error'].includes(mode) ? undefined : 'p'}
          name={`studioConfirmMultiples.${mode}.description`}
          args={[
            <Translate
              key={0}
              name={`studioConfirmMultiples.${mode}.descriptionArgs.${i18nKey}`}
              args={compact([mode === 'confirm' && <Number key={0}>{totalNumber}</Number>])}
            />,
          ]}
        />
        {mode === 'running' && (
          <Translate
            as="p"
            name={`studioConfirmMultiples.${mode}.progress`}
            args={[
              <Number key={0}>{confirmedNumber}</Number>,
              <Number key={1}>{totalNumber}</Number>,
              <Translate key={2} name={`studioConfirmMultiples.${mode}.progressArgs.${i18nKey}`} />,
            ]}
          />
        )}
      </>
    );
  };

  return (
    <Modal onClose={onClose} className={cx('studio-confirm-multiples-modal', className)}>
      <ModalHeader>
        <Translate
          name={`studioConfirmMultiples.${mode}.title`}
          args={[<Translate key={0} name={`studioConfirmMultiples.${mode}.titleArgs.${i18nKey}`} />]}
        />
      </ModalHeader>
      <ModalContent>
        {(mode === 'error' || mode === 'success') && (
          <Hint variant={mode === 'success' ? 'success' : 'warning'}>{getDescription(mode)}</Hint>
        )}
        {(mode === 'confirm' || mode === 'running') && getDescription(mode)}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={compact([
            mode === 'confirm' && {
              name: 'confirm',
              variant: 'primary',
              content: <Translate name={`studioConfirmMultiples.action.confirm.${i18nKey}`} />,
              size: 'large',
              onClick: onConfirm,
            },
            (mode === 'error' || mode === 'success') && {
              name: 'backToOverview',
              variant: 'primary',
              content: <Translate name={`studioConfirmMultiples.action.backToOverview`} />,
              size: 'large',
              onClick: onClose,
            },
            mode === 'confirm' && {
              name: 'cancel',
              content: <Translate name={`studioConfirmMultiples.action.cancel`} />,
              size: 'large',
              onClick: onClose,
            },
            mode === 'running' && {
              name: 'cancelProcess',
              content: <Translate name={`studioConfirmMultiples.action.cancelProcess`} />,
              size: 'large',
              onClick: onClose,
            },
          ])}
        />
      </ModalFooter>
    </Modal>
  );
};

export default StudioConfirmMultiplesModal;
