import { useCallback, useMemo, useRef } from 'react';
import { EMPTY_CLIENT_RECT, createContainer, getNewPopoverRect, getNudgedPopoverRect } from './util';
import { PopoverAlign, PopoverPositions } from 'ui/molecules/popover/types';

export function useElementRef(containerClassName?: string, containerStyle?: Record<any, any>) {
  const element = useMemo(
    () => createContainer(containerStyle, containerClassName),
    [containerClassName, containerStyle],
  );

  return useRef(element);
}

export function useMemoizedArray(externalArray: Array<any>) {
  const prevArrayRef = useRef(externalArray);
  return useMemo(() => {
    if (prevArrayRef.current === externalArray) return prevArrayRef.current;

    if (prevArrayRef.current.length !== externalArray.length) {
      prevArrayRef.current = externalArray;
      return externalArray;
    }

    for (let i = 0; i < externalArray.length; i += 1) {
      if (externalArray[i] !== prevArrayRef.current[i]) {
        prevArrayRef.current = externalArray;
        return externalArray;
      }
    }

    return prevArrayRef.current;
  }, [externalArray]);
}

type UsePopoverProps = {
  isOpen?: boolean;
  childRef: any;
  positions: Array<PopoverPositions>;
  parentElement: any;
  contentLocation: unknown;
  align: PopoverAlign;
  padding: number;
  reposition: boolean;
  boundaryInset: number;
  boundaryElement: any;
  onPositionPopover: ({
    childRect,
    popoverRect,
    parentRect,
    boundaryRect,
    padding,
    nudgedTop,
    nudgedLeft,
    align,
    position,
    boundaryInset,
    violations,
    hasViolations,
  }: {
    childRect: DOMRect;
    popoverRect: DOMRect;
    parentRect: DOMRect;
    boundaryRect: DOMRect;
    padding: number;
    nudgedTop: number;
    nudgedLeft: number;
    boundaryInset: number;
    position?: PopoverPositions;
    align?: PopoverAlign;
    violations: {
      top?: number;
      left?: number;
      bottom?: number;
      height?: number;
      right?: number;
      width?: number;
    };
    hasViolations: boolean;
  }) => any;
  containerClassName?: string;
};

export function usePopover({
  isOpen,
  childRef,
  positions,
  containerClassName,
  parentElement,
  contentLocation,
  align,
  padding,
  reposition,
  boundaryInset,
  boundaryElement,
  onPositionPopover,
}: UsePopoverProps) {
  const popoverRef = useElementRef(containerClassName, {
    position: 'fixed',
    overflow: 'visible',
    top: '0px',
    left: '0px',
    zIndex: 8,
  });

  const scoutRef = useElementRef('react-tiny-popover-scout', {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '0px',
    height: '0px',
    visibility: 'hidden',
  });

  // TODO: reposition is broken(it's not though? maybe no todo), this would be useful
  // const isVisible = useIntersectionObserver(popoverRef, 0.5, false);

  const positionPopover = useCallback(
    ({
      positionIndex = 0,
      parentRect = parentElement.getBoundingClientRect(),
      childRect = childRef?.current?.getBoundingClientRect(),
      scoutRect = scoutRef?.current?.getBoundingClientRect(),
      popoverRect = popoverRef.current.getBoundingClientRect(),
      boundaryRect = boundaryElement === parentElement ? parentRect : boundaryElement.getBoundingClientRect(),
    } = {}) => {
      if (!childRect || !parentRect || !isOpen) {
        return;
      }

      if (contentLocation) {
        const { top: inputTop, left: inputLeft } =
          typeof contentLocation === 'function'
            ? contentLocation({
                childRect,
                popoverRect,
                parentRect,
                boundaryRect,
                padding,
                nudgedTop: 0,
                nudgedLeft: 0,
                boundaryInset,
                violations: EMPTY_CLIENT_RECT,
                hasViolations: false,
              })
            : contentLocation;

        const left = parentRect.left + inputLeft;
        const top = parentRect.top + inputTop;

        popoverRef.current.style.top = `${top - scoutRect.top}px`;
        popoverRef.current.style.left = `${left - scoutRect.left}px`;

        onPositionPopover({
          childRect,
          popoverRect,
          parentRect,
          boundaryRect,
          padding,
          nudgedTop: 0,
          nudgedLeft: 0,
          boundaryInset,
          violations: EMPTY_CLIENT_RECT,
          hasViolations: false,
        });

        return;
      }

      const isExhausted = positionIndex === positions.length;
      const position = isExhausted ? positions[0] : positions[positionIndex];

      const { rect, boundaryViolation } = getNewPopoverRect(
        {
          childRect,
          popoverRect,
          boundaryRect,
          position,
          align,
          padding,
          reposition,
        },
        boundaryInset,
      );

      if (boundaryViolation && reposition && !isExhausted) {
        positionPopover({
          positionIndex: positionIndex + 1,
          childRect,
          popoverRect,
          parentRect,
          boundaryRect,
        });
        return;
      }

      const { top, left, width, height } = rect;
      const shouldNudge = reposition && !isExhausted;
      const { left: nudgedLeft, top: nudgedTop } = getNudgedPopoverRect(rect as DOMRect, boundaryRect, boundaryInset);

      let finalTop = top;
      let finalLeft = left;

      if (shouldNudge) {
        finalTop = nudgedTop;
        finalLeft = nudgedLeft;
      }

      popoverRef.current.style.top = `${finalTop - scoutRect.top}px`;
      popoverRef.current.style.left = `${finalLeft - scoutRect.left}px`;

      const potentialViolations = {
        top: boundaryRect.top + boundaryInset - finalTop,
        left: boundaryRect.left + boundaryInset - finalLeft,
        right: finalLeft + width - boundaryRect.right + boundaryInset,
        bottom: finalTop + height - boundaryRect.bottom + boundaryInset,
      };

      onPositionPopover({
        childRect,
        popoverRect: {
          top: finalTop,
          left: finalLeft,
          width,
          height,
          right: finalLeft + width,
          bottom: finalTop + height,
        } as DOMRect,
        parentRect,
        boundaryRect,
        position,
        align,
        padding,
        nudgedTop: nudgedTop - top,
        nudgedLeft: nudgedLeft - left,
        boundaryInset,
        violations: {
          top: potentialViolations.top <= 0 ? 0 : potentialViolations.top,
          left: potentialViolations.left <= 0 ? 0 : potentialViolations.left,
          right: potentialViolations.right <= 0 ? 0 : potentialViolations.right,
          bottom: potentialViolations.bottom <= 0 ? 0 : potentialViolations.bottom,
        },
        hasViolations:
          potentialViolations.top > 0 ||
          potentialViolations.left > 0 ||
          potentialViolations.right > 0 ||
          potentialViolations.bottom > 0,
      });
    },
    [
      parentElement,
      childRef,
      popoverRef,
      boundaryElement,
      contentLocation,
      positions,
      align,
      padding,
      reposition,
      boundaryInset,
      onPositionPopover,
      isOpen,
    ],
  );

  return {
    positionPopover,
    popoverRef,
    scoutRef,
  };
}
