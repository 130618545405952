import { NetworkTypeEnum } from '../api/models';
import {
  getWalletAddressForNetworkAssertExistence as getWalletAddressForNetworkAssertExistenceUi,
  getWalletAddressForNetwork as getWalletAddressForNetworkUi,
} from '../ui/helper/networks';
import { WalletNetworkInfo } from '../ui/types/wallet';

export const getWalletAddressForNetwork = (
  networkType: NetworkTypeEnum,
  wallet: WalletNetworkInfo,
): string | undefined | null => {
  return getWalletAddressForNetworkUi(networkType, wallet);
};

export const getWalletAddressForNetworkAssertExistence = (
  networkType: NetworkTypeEnum,
  wallet: WalletNetworkInfo,
): string => {
  return getWalletAddressForNetworkAssertExistenceUi(networkType, wallet);
};
