import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { StateValues } from 'react-use-form-state';
import { AdminApi, AdminTokensBundesbankSettlementInstructionsCreateRequest } from 'api';
import useApiCall from 'hooks/use-api-call';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import { useProducts, useProductsFilter } from 'src/apps/issuer/hooks';
import { toMoney } from 'src/ui/helper/money';
import { useServerErrorsHandling } from './hooks';

interface AddSettlementModalProps {
  onAddNewSettlement: () => void;
  onClose: () => void;
}

interface AddSettlementFields {
  correlationId: string;
  paymentAmount: string;
  product: string;
  productAmount: number;
  sourceWalletAddress: string;
  targetWalletAddress: string;
  timeoutDelay: number;
}

export const Form = makeModalForm<AddSettlementFields>();

const AddSettlementModal: FunctionComponent<AddSettlementModalProps> = ({ onAddNewSettlement, onClose }) => {
  const { makeAuthenticatedApi, withApi, error, loading } = useApiCall();

  const { getSourceWalletError, getTargetWalletError, getErrorForFieldCorrelationId } = useServerErrorsHandling(error);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  const onSubmit = useCallback((fieldsValues: StateValues<AddSettlementFields>) => {
    const params: AdminTokensBundesbankSettlementInstructionsCreateRequest = {
      id: fieldsValues.product,
      adminBundesbankSettlementInstructionRequest: {
        sourceWalletAddress: fieldsValues.sourceWalletAddress,
        targetWalletAddress: fieldsValues.targetWalletAddress,
        paymentAmount: toMoney(fieldsValues.paymentAmount),
        productAmount: Number(fieldsValues.productAmount),
        correlationId: fieldsValues.correlationId,
        timeoutDelay: Number(fieldsValues.timeoutDelay),
      },
    };
    withApi(async () => {
      await api.adminTokensBundesbankSettlementInstructionsCreate(params);
      onAddNewSettlement();
    });
  }, []);

  return (
    <Modal onClose={onClose}>
      <Form
        i18nKey="createSettlementForm"
        onSubmit={(values: StateValues<AddSettlementFields>) => {
          onSubmit(values);
        }}
        error={error}
      >
        <ModalHeader>
          <Translate name="createSettlementForm.title" />
        </ModalHeader>
        <ModalContent>
          <Form.Group name="product" required={true}>
            <Form.Select options={productsFilter}></Form.Select>
          </Form.Group>
          <Form.Group
            name="sourceWalletAddress"
            fieldError={getSourceWalletError('source_wallet_address')}
            required={true}
          >
            {Form.Validators.EthereumAddress()}
            {Form.Validators.TokenAddressChecksum()}
            <Form.Input />
          </Form.Group>
          <Form.Group
            name="targetWalletAddress"
            fieldError={getTargetWalletError('target_wallet_address')}
            required={true}
          >
            {Form.Validators.EthereumAddress()}
            {Form.Validators.TokenAddressChecksum()}
            <Form.Input />
          </Form.Group>
          <Form.Group name="paymentAmount" required={true}>
            <Form.Input isCurrency />
            {Form.Validators.Range(0, undefined)}
          </Form.Group>
          <Form.Group name="productAmount" required={true}>
            <Form.Input type="number" />
            {Form.Validators.Range(1, undefined)}
          </Form.Group>
          <Form.Group name="correlationId" fieldError={getErrorForFieldCorrelationId('correlation_id')} required={true}>
            <Form.Input maxLength={32} />
          </Form.Group>
          <Form.Group name="timeoutDelay" required={true}>
            <Form.Input type="number" />
            {Form.Validators.Range(1, undefined)}
          </Form.Group>
          <Form.GenericErrorMessages />
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.cancel" />,
                size: 'large',
                onClick: () => onClose(),
              },
              {
                name: 'add',
                content: <Translate name="common.add" />,
                variant: 'primary',
                loading: loading,
                type: 'submit',
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddSettlementModal;
