import React, { FunctionComponent } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import Grid, { Col } from 'ui/atoms/grid';
import StatusTag from 'ui/atoms/status-tag';
import Translate from 'ui/atoms/translate';
import { InfoField } from 'ui/molecules/info-grid';
import * as Styled from './styled';
import CopyButton from 'ui/molecules/copy-button';

export interface FriendReferralDetailsGeneralInfoProps {
  owningInvestorName: string;
  voucherCode: string;
  useTimes: number;
  active: boolean;
}

const FriendReferralDetailsGeneralInfo: FunctionComponent<FriendReferralDetailsGeneralInfoProps> = ({
  owningInvestorName,
  voucherCode,
  useTimes,
  active,
}) => {
  return (
    <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
      <Grid>
        <Col width={3}>
          <InfoField title={<Translate name="issuerFriendReferrals.table.referrer" />}>{owningInvestorName}</InfoField>
        </Col>
        <Col width={3}>
          <InfoField title={<Translate name="issuerFriendReferrals.table.voucherCode" />}>
            <Styled.VoucherCodeContainer>
              <Styled.VoucherCode>{voucherCode}</Styled.VoucherCode>
              <CopyButton valueToCopy={voucherCode} />
            </Styled.VoucherCodeContainer>
          </InfoField>
        </Col>
        <Col width={3}>
          <InfoField title={<Translate name="issuerFriendReferrals.table.useNumber" />}>{useTimes}</InfoField>
        </Col>
        <Col width={3}>
          <InfoField title={<Translate name="issuerFriendReferrals.table.status" />}>
            <StatusTag variant={active ? 'success' : 'danger'} truncate={true}>
              <Translate name={`issuerProductDetails.campaigns.status.${active ? 'active' : 'deactivated'}`} />
            </StatusTag>
          </InfoField>
        </Col>
      </Grid>
    </BoxedContent>
  );
};

export default FriendReferralDetailsGeneralInfo;
