import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './styled';
import Icon from 'ui/atoms/icon';

export interface StepperInputProps {
  inputHover: boolean;
  value: number;
  setValue: (val: string) => void;
  min?: number;
  max?: number;
}

export const StepperInput: FunctionComponent<StepperInputProps> = (props) => {
  const { inputHover, value, min = 0, max = Number.MAX_VALUE, setValue } = props;

  const isIncrementDisabled = useMemo(() => {
    return value + 1 > max;
  }, [value, max]);

  const isDecrementDisabled = useMemo(() => {
    return value - 1 < min;
  }, [value, min]);

  return (
    <Styled.Stepper $inputHover={inputHover}>
      <Styled.StepperIconContainer
        $border
        $disabled={isIncrementDisabled}
        $inputHover={inputHover}
        onClick={!isIncrementDisabled ? () => setValue((value + 1).toString()) : () => setValue('1')}
      >
        <Icon name="plus" color={isIncrementDisabled ? 'grayMain' : '#303539'} />
      </Styled.StepperIconContainer>
      <Styled.StepperIconContainer
        $disabled={isDecrementDisabled}
        onClick={!isDecrementDisabled ? () => setValue((value - 1).toString()) : () => {}}
      >
        <Icon name="minus" color={isDecrementDisabled ? 'grayMain' : '#303539'} />
      </Styled.StepperIconContainer>
    </Styled.Stepper>
  );
};

export default StepperInput;
