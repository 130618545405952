import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Spacer from 'ui/atoms/spacer';
import LossAbilityCalculator from 'libraries/wizard/components/loss-ability-calculator';
import { NetworthAnswerRequest } from 'api';
import Button from 'ui/atoms/button';
import ActionButtons from 'src/ui/molecules/action-buttons';

export interface QuestionnaireLossabilityCalculatorProps {
  setLosabilityAnswers: (val: NetworthAnswerRequest[]) => void;
  setShowLossabilityCalculator: (val: boolean) => void;
  onEditQuestions: () => void;
  setConfirmModalOpen: (val: boolean) => void;
  onSaveEntries: () => void;
  isSavingDisabled: boolean;
}

const QuestionnaireLossabilityCalculator: FunctionComponent<QuestionnaireLossabilityCalculatorProps> = (props) => {
  const { setLosabilityAnswers, setConfirmModalOpen, onSaveEntries, isSavingDisabled } = props;

  return (
    <LossAbilityCalculator
      onUpdateAnswers={(netWorthAnswers: NetworthAnswerRequest[]) => setLosabilityAnswers(netWorthAnswers)}
      actionButtons={
        <ActionButtons>
          <Spacer y={4} />
          <Button variant="secondary" size="large" onClick={() => setConfirmModalOpen(true)}>
            <Translate name="lossAbility.continue" />
          </Button>
          <Button variant="primary" size="large" onClick={onSaveEntries} disabled={isSavingDisabled}>
            <Translate name="lossAbility.saveAndContinue" />
          </Button>
        </ActionButtons>
      }
    />
  );
};

export default QuestionnaireLossabilityCalculator;
