import React, { FunctionComponent, ReactNode } from 'react';
import * as Styled from './styled';

export interface GroupToggleProps {
  /** Primary content. */
  children?: ReactNode;
}

export const GroupToggle: FunctionComponent<GroupToggleProps> = (props) => {
  const { children } = props;
  return (
    <>
      <Styled.GroupToggle>{children}</Styled.GroupToggle>
    </>
  );
};

export default GroupToggle;
