import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import { PlatformUrl } from 'ui/types/urls';
import * as Styled from './styles';
import type { Colors } from 'ui/styles/utils/colors';

const Imprint: FunctionComponent<{
  color?: Colors;
  iconColor?: Colors;
  url?: string;
}> = ({ color, iconColor = 'white', url }) => {
  if (url) {
    return (
      <Styled.Imprint to={url} external size="small" iconColor={iconColor} $color={color}>
        <Translate name="common.imprint" />
      </Styled.Imprint>
    );
  }

  return (
    <Styled.Imprint platformUrl={PlatformUrl.IMPRINT} external iconColor={iconColor} size="small" $color={color}>
      <Translate name="common.imprint" />
    </Styled.Imprint>
  );
};

export default Imprint;
