import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { Modal } from 'ui/molecules/modal';
import { NavigationItem } from 'ui/molecules/navigation';
import Icon from 'ui/atoms/icon';
import Imprint from 'core/components/imprint';
import SidebarNavList from 'core/nav/sidebars/nav-list';
import * as Styled from 'core/nav/sidebars/navbar-menu/styled';
import type { NavigationItem as NavItem } from 'core/nav/types';

interface MenuCollapseProps {
  navigationItems?: NavItem[];
}

const MenuCollapse: FunctionComponent<MenuCollapseProps> = (props) => {
  const { children, navigationItems } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const isPhone = useIsMedia(DEVICES.phoneTablet);
  const location = useLocation();

  useEffect(() => {
    // on location change close menu
    setMenuOpen(false);
  }, [location]);

  return (
    <>
      {!isPhone && children}
      {isPhone && (
        <Styled.NavWrapper>
          <NavigationItem onClick={() => setMenuOpen((prevMenuOpen) => !prevMenuOpen)}>
            <Icon name="bars" color="gray" />
          </NavigationItem>
          {menuOpen && (
            <Modal
              size="fullHeight"
              position="right"
              onClose={() => setMenuOpen(false)}
              hasBorderRadius={false}
              padding="none"
              className="menu-side-overlay"
            >
              <Styled.SideOverlayHeader iconColor="white">{children}</Styled.SideOverlayHeader>
              <Styled.SideOverlayContent margin="none">
                {navigationItems && <SidebarNavList navigationItems={navigationItems} />}
                <Styled.Footer>
                  <Styled.SideOverlayContainer>
                    <Imprint color="white" />
                  </Styled.SideOverlayContainer>
                </Styled.Footer>
              </Styled.SideOverlayContent>
            </Modal>
          )}
        </Styled.NavWrapper>
      )}
    </>
  );
};

export default MenuCollapse;
