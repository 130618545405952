import useRoutesResolver from 'core/routing/hooks/use-routes-resolver';
import { INVESTOR_ROUTES } from './routes.config';
import type { Config, Investor } from 'api';

export function useInvestorNavigationItems({
  investor,
  investorHasManagedWallet,
  hasInvestments,
  hasEcspInvestments,
  config,
}: {
  investor: Investor | null;
  investorHasManagedWallet: boolean;
  hasInvestments: boolean | null;
  hasEcspInvestments: boolean | null;
  config: Config | null;
}) {
  return useRoutesResolver(INVESTOR_ROUTES, {
    investor,
    investorHasManagedWallet,
    hasInvestments,
    hasEcspInvestments,
    config,
  });
}
