import { NaturalPerson, LegalPerson } from 'api';
import { camelCase } from 'change-case';
import Date from 'ui/atoms/date';
import PersonName from 'ui/atoms/person-name';
import { compact } from 'lodash';
import { hideCentralCharacters } from 'ui/helper/hideCharacters';
import Currency from 'ui/atoms/currency';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import DocumentModal from 'src/libraries/document-modal';
import React from 'react';
import Translate from 'ui/atoms/translate';

interface UserDataProps {
  rootPerson?: LegalPerson | NaturalPerson | null;
  naturalPerson?: NaturalPerson | null;
  legalPerson?: LegalPerson | null;
}

const useUserData = (props: UserDataProps) => {
  const { rootPerson, naturalPerson, legalPerson } = props;

  const userData = {
    personalData: {
      key: 'personalData',
      fields: {
        salutation: naturalPerson?.salutation && (
          <Translate name={`personalInformation.salutations.${camelCase(naturalPerson?.salutation)}`} />
        ),
        name: (
          <PersonName
            person={{
              forename: naturalPerson?.forename,
              surname: naturalPerson?.surname,
              companyName: legalPerson?.companyName,
            }}
          />
        ),
        birthDate: naturalPerson?.birthDate && <Date>{naturalPerson?.birthDate}</Date>,
        birthPlace: naturalPerson?.birthPlace,
      },
    },
    registrationAddress: {
      key: 'registrationAddress',
      fields: {
        street: naturalPerson?.street,
        city: compact([naturalPerson?.zip, naturalPerson?.city]).join(' '),
        country: naturalPerson?.country && <Translate name={`countries.${naturalPerson?.country}`} />,
      },
    },
    taxData: {
      key: 'taxData',
      fields: {
        taxIdentificationNumber:
          rootPerson?.taxInformation?.taxIdentificationNumber &&
          hideCentralCharacters(rootPerson.taxInformation.taxIdentificationNumber, 2, -2),
        exemptionOrderSum: (
          <PlaceholderFallback>
            {rootPerson?.taxInformation?.exemptionOrderSum && (
              <Currency>{rootPerson.taxInformation.exemptionOrderSum}</Currency>
            )}
          </PlaceholderFallback>
        ),
        nonAssessmentCertificate: (
          <Translate
            name={`dashboardInvestorSettings.taxData.hasNonAssessmentCertificate.${
              rootPerson?.taxInformation?.nonAssessmentCertificate ? 'available' : 'notAvailable'
            }`}
          />
        ),
      },
    },
    bankData: {
      key: 'bankData',
      fields: {
        bankAccountHolder: rootPerson?.bankAccount?.accountHolder,
        ibanBank: rootPerson?.bankAccount?.iban && hideCentralCharacters(rootPerson.bankAccount.iban, 4, -2),
      },
    },
    depositData: {
      key: 'depositData',
      fields: {
        securitiesAccountHolder: rootPerson?.securitiesDepositAccount?.accountHolder,
        securitiesAccountNumber:
          rootPerson?.securitiesDepositAccount?.accountNumber &&
          hideCentralCharacters(rootPerson?.securitiesDepositAccount?.accountNumber, 2, -2),
        bicSecurities: rootPerson?.securitiesDepositAccount?.bic,
      },
    },
    companyData: {
      key: 'companyData',
      fields: {
        companyName: (
          <PersonName
            person={{
              companyName: legalPerson?.companyName,
            }}
          />
        ),
        legalEntityType: legalPerson?.legalEntityType,
        companyStreet: legalPerson?.street,
        companyCity: legalPerson?.city,
        companyCountry: legalPerson?.country && <Translate name={`countries.${legalPerson?.country}`} />,
        commercialRegister: compact([legalPerson?.commercialRegister, legalPerson?.commercialRegisterNumber]).join(
          ': ',
        ),
        commercialRegisterStatement: legalPerson?.commercialRegisterStatement && (
          <Translate
            name="dashboardInvestorSettings.companyData.viewDocument"
            args={[
              (text: string, key) => {
                if (!legalPerson?.commercialRegisterStatement) return;
                return (
                  <DocumentModal key={key} {...legalPerson.commercialRegisterStatement}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        ),
        listOfShareholders: legalPerson?.listOfShareholders && (
          <Translate
            name="dashboardInvestorSettings.companyData.viewDocument"
            args={[
              (text: string, key) => {
                if (!legalPerson?.listOfShareholders) return;
                return (
                  <DocumentModal key={key} {...legalPerson?.listOfShareholders}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        ),
        transparencyRegister: legalPerson?.transparencyRegister && (
          <Translate
            name="dashboardInvestorSettings.companyData.viewDocument"
            args={[
              (text: string, key) => {
                if (!legalPerson?.transparencyRegister) return;
                return (
                  <DocumentModal key={key} {...legalPerson?.transparencyRegister}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        ),
      },
    },
  };

  const userSections = [
    userData.personalData,
    userData.registrationAddress,
    userData.taxData,
    userData.bankData,
    legalPerson ? userData.companyData : null,
  ];

  return userSections;
};

export default useUserData;
