import React, { ElementType, FunctionComponent, ReactNode } from 'react';
import Logo from 'ui/atoms/logo';
import * as Styled from 'ui/molecules/navigation/styled';
import Link from 'ui/atoms/link';

export interface NavigationBarProps {
  /** Use fluid for a full width navigation */
  fluid?: boolean;

  /** Brand logo */
  logo?: {
    /** Contains a URL or a URL fragment that the logo hyperlink points to. */
    href?: string;

    hide?: boolean;
  };

  progressBar?: ReactNode;
}

const renderLogo: ElementType = (logo: { height?: number; href?: string; hide?: boolean }) => {
  if (logo?.href) {
    return (
      <Link href={logo?.href}>
        <Logo variant="header" />
      </Link>
    );
  }

  return (
    <span>
      <Logo variant="header" hide={logo?.hide} />
    </span>
  );
};

export const NavigationBar: FunctionComponent<NavigationBarProps> = (props) => {
  const { children, logo, progressBar } = props;

  return (
    <Styled.Navbar $progressBar={!!progressBar}>
      <Styled.Container>
        {renderLogo(logo)}
        <Styled.NavContainer>{children}</Styled.NavContainer>
      </Styled.Container>
    </Styled.Navbar>
  );
};

export { default as NavigationItem } from './navigation-item';
export default NavigationBar;
