import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';
import { PopoverPositions } from 'ui/molecules/popover/types';
import { Colors, getColorsMap } from 'ui/styles/utils/colors';

const REVERSE_POSITIONS = {
  top: 'bottom',
  left: 'right',
  right: 'left',
  bottom: 'top',
};

const DEFAULT_ARROW_SIZE = 8;

type ArrowProps = {
  $position: PopoverPositions;
  $childRect: DOMRect;
  $popoverRect: DOMRect;
  $size?: number;
  $color?: Colors;
};

function getColor({ theme, $color }: ThemedStyledProps<Partial<ArrowProps>, DefaultTheme>) {
  const { main } = getColorsMap(theme, $color, false);

  return main;
}

function getArrowOffset({
  theme,
  $childRect,
  $popoverRect,
  $position,
  $color = 'secondary',
  $size = DEFAULT_ARROW_SIZE,
}: ThemedStyledProps<ArrowProps, DefaultTheme>) {
  const arrowWidth = $size * 2;
  let top = $childRect.top - $popoverRect.top + $childRect.height / 2 - arrowWidth / 2;
  let left = $childRect.left - $popoverRect.left + $childRect.width / 2 - arrowWidth / 2;

  const lowerBound = $size;
  const leftUpperBound = $popoverRect.width - $size;
  const topUpperBound = $popoverRect.height - $size;

  left = left < lowerBound ? lowerBound : left;
  left = left + arrowWidth > leftUpperBound ? leftUpperBound - arrowWidth : left;
  top = top < lowerBound ? lowerBound : top;
  top = top + arrowWidth > topUpperBound ? topUpperBound - arrowWidth : top;

  top = Number.isNaN(top) ? 0 : top;
  left = Number.isNaN(left) ? 0 : left;

  switch ($position) {
    case 'right':
      return {
        borderTop: `${$size}px solid transparent`,
        borderBottom: `${$size}px solid transparent`,
        borderRight: `${$size}px solid ${getColor({ theme, $color })}`,
        left: 0,
        top,
      };
    case 'left':
      return {
        borderTop: `${$size}px solid transparent`,
        borderBottom: `${$size}px solid transparent`,
        borderLeft: `${$size}px solid ${getColor({ theme, $color })}`,
        right: 0,
        top,
      };
    case 'bottom':
      return {
        borderLeft: `${$size}px solid transparent`,
        borderRight: `${$size}px solid transparent`,
        borderBottom: `${$size}px solid ${getColor({ theme, $color })}`,
        top: 0,
        left,
      };
    case 'top':
      return {
        borderLeft: `${$size}px solid transparent`,
        borderRight: `${$size}px solid transparent`,
        borderTop: `${$size}px solid ${getColor({ theme, $color })}`,
        bottom: 0,
        left,
      };
    default:
      return {
        display: 'hidden',
      };
  }
}

export const ArrowContainer = styled.div<{ $position: PopoverPositions; $size?: number }>`
  ${({ $position, $size }) => `padding-${REVERSE_POSITIONS[$position]}: ${$size || DEFAULT_ARROW_SIZE}px`}
`;

export const Arrow = styled.div<ArrowProps>`
  position: absolute;
  ${getArrowOffset};
`;
