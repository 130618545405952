import { query } from 'ui/styles/queries';
import { resolveColor } from 'ui/styles/utils/colors';
import styled from 'styled-components';
import Grid, { Col } from 'ui/atoms/grid';

export const WizardContentCol = styled(Col)`
  height: 100%;
  overflow: auto;

  ${query.largeDesktops`
    padding-top: 2.5rem;
  `}
`;

export const WizardContent = styled.div`
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;

  ${query.phone`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `};

  ${query.desktops`
    max-width: 620px;
    width: 100%;
    margin: auto;
  `}

  ${query.lTablet`
    width: 100%;
    max-width: 568px;
    margin: auto;
  `};

  ${query.lDesktop`
    padding-top: 0rem;
  `}
`;

export const WizardGrid = styled(Grid)`
  margin: 0px;

  ${query.largeDesktops`
    height: 100%;
  `}
`;

export const SideContentCol = styled(Col)`
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => resolveColor(theme, 'grayLight')};
  padding: 2.5rem;

  ${query.largeDesktops`
     padding: 2.5rem 4.5rem;
  `}
`;

export const HeaderContentCol = styled(Col)`
  width: 100%;
  overflow: auto;
  background-color: ${({ theme }) => resolveColor(theme, 'grayLight')};
  padding-bottom: 1rem;
  padding-top: 1rem;
`;
