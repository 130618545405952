import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import { CreateAccountForm } from 'libraries/wizard/components/account-setup/create-account/helpers';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import { CommunicationSettingsFields as CommunicationSettingsFieldsCompatibility } from 'libraries/wizard/components/account-setup/create-account/types';
import Header from 'ui/atoms/header';

export type CommunicationSettingsFields = CommunicationSettingsFieldsCompatibility;

export interface CommunicationSettingsProps {
  marketingOptsEnabled?: Array<string>;
}

type CommunicationOptions = 'optinMail' | 'optinEmail' | 'optinPhone';

const CommunicationSettings: FunctionComponent<CommunicationSettingsProps> = (props) => {
  const { marketingOptsEnabled } = props;

  return (
    <Section>
      <Header spacing="small" size="xsmall">
        <Translate name={'createAccount.acceptGroupType.newsletter'} />
      </Header>
      <Translate as="p" name="createAccount.communicationSettings.headline" />
      {marketingOptsEnabled?.map((marketingOption) => (
        // Enforced type casting due to conversion from snake case to camel case
        <CreateAccountForm.Group
          name={snakeToCamelCaseWithNumbers(marketingOption) as CommunicationOptions}
          key={marketingOption}
        >
          <CreateAccountForm.Checkbox>
            <Translate name={`createAccount.communicationSettings.${snakeToCamelCaseWithNumbers(marketingOption)}`} />
          </CreateAccountForm.Checkbox>
        </CreateAccountForm.Group>
      ))}
    </Section>
  );
};

export default CommunicationSettings;
