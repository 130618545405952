import React, { FunctionComponent, ReactNode } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi, AdminBundesbankSettlementInstructionsListRequest, AdminBundesbankSettlementInstruction } from 'api';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import SettlementsTable from './SettlementTable';

interface SettlementsTableProps extends AdminBundesbankSettlementInstructionsListRequest {
  filters?: ReactNode;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
  forceDataLoad?: boolean;
}

const IssuerSettlementsTable: FunctionComponent<SettlementsTableProps> = WithDataRecord<
  AdminApi,
  AdminBundesbankSettlementInstruction
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.triggerSolutionsSettlementsDetails);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <SettlementsTable
          loading={loading}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_TRIGGER_SOLUTION} />}
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(id: string) => goTo({ settlementId: id })}
          settlements={data}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminBundesbankSettlementInstructionsList({
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default IssuerSettlementsTable;
