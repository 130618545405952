export interface SizesTheme {
  small: any;
  medium: any;
  large: any;
}

export default {
  small: 1,
  medium: 2,
  large: 3,
} as SizesTheme;
