import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const DefaultLayout = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndices.header};
`;

export const Main = styled.main`
  padding: 5rem 0 1rem 0;

  ${query.phoneTablet`
    padding-top: 1rem !important;
  `}
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
`;
