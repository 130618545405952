import React, { FunctionComponent } from 'react';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import type { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import Grid, { Col } from 'ui/atoms/grid';
import Input from 'ui/atoms/input';
import useTranslate from 'ui/hooks/use-translate';
import Section from 'ui/atoms/section';
import * as Styled from './styled';

export interface SummaryDefaultSegmentProps {
  questions: QuestionnaireQuestions[];
  groupScore?: boolean;
}

const SummaryDefaultSegment: FunctionComponent<SummaryDefaultSegmentProps> = ({ questions, groupScore }) => {
  const translate = useTranslate();

  return (
    <>
      {questions.map((question) => {
        const questionI18nKey = question.segmentI18nKey ? question.segmentI18nKey : DEFAULT_SEGMENT_KEY;
        const optionI18nKey = question.segmentI18nKey ? question.id : DEFAULT_SEGMENT_KEY;

        return (
          <Section spacing="small" key={question.id}>
            <QuestionnaireForm.ValueProvider>
              {(values) => {
                const answerValue = values[question.id];
                return (
                  <Grid>
                    <Col>
                      {translate(`questionnaireSummary.${snakeToCamelCaseWithNumbers(question.id)}`) ||
                        translate(`${questionI18nKey}.${snakeToCamelCaseWithNumbers(question.id)}`)}
                    </Col>
                    <Col>
                      {['yes', 'no'].includes(answerValue) ? (
                        <Styled.ShortSummaryInput
                          key={answerValue}
                          height="small"
                          textAlign="center"
                          value={translate(`${optionI18nKey}.${snakeToCamelCaseWithNumbers(answerValue)}`) as string}
                          disabled
                        />
                      ) : (
                        <>
                          <Input
                            key={answerValue}
                            height="small"
                            textAlign="center"
                            value={translate(`${optionI18nKey}.${snakeToCamelCaseWithNumbers(answerValue)}`) as string}
                            disabled
                          />
                        </>
                      )}
                    </Col>
                  </Grid>
                );
              }}
            </QuestionnaireForm.ValueProvider>
          </Section>
        );
      })}
    </>
  );
};

export default SummaryDefaultSegment;
