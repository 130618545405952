import { EducationalQualification, Occupation } from 'ui/types/person';
import ServerError from 'ui/types/server-error';

export interface QuestionnaireQuestions {
  id: string;
  options: string[];
  groupId?: string;
  subgroupId?: string;
  productId?: string | null;
  segmentI18nKey?: string;
}

export type QuestionnaireLegacy = Array<QuestionnaireQuestions>;

export type QuestionnaireSegmentsAnswers = {
  [key: string]: Array<QuestionnaireQuestions>;
};

export type QuestionnaireSegment = {
  index: number;
  values: string[];
  title: string;
};

export type QuestionnairePage = {
  page: number;
  segments: QuestionnaireSegment[];
};

export type QuestionnairePagesSegments = QuestionnairePage[];

export interface QuestionnaireAnswers {
  id: string;
  options?: string[];
  selectedOption?: string;
}

export interface EducationOccupationFields {
  educationalQualification?: EducationalQualification;
  occupation?: Occupation;
}

export interface QuestionnaireFormProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (
    values: { answers: QuestionnaireAnswers[]; educationOccupation?: EducationOccupationFields },
    shouldFinalize?: boolean,
  ) => void;

  initialEducationOccupation?: EducationOccupationFields;

  initialAnswers?: QuestionnaireAnswers[];

  /** Indicates a loading state */
  loading?: boolean;

  /** error */
  error?: ServerError;

  questions?: QuestionnaireQuestions[];

  editQuestions?: boolean;

  pageNumber: number;

  setPageNumber: (value: number) => void;

  onEditQuestions: () => void;

  questionnairePagesSegments: QuestionnairePagesSegments;

  investorId?: string;

  isEcsp: boolean;

  goBack: () => void;
}

export type QuestionnaireFormFields = {
  giveNoAnswers: boolean;
  educationalQualification?: EducationalQualification;
  occupation?: Occupation;
} & {
  [key: string]: string;
};

export enum QuestionnaireQuestionsGroup {
  Education = 'education',
  InvestmentExperience = 'investmentexperience',
  InvestmentKnowledge = 'investmentknowledge',
  ProfessionalInvestment = 'professionalinvestment',
  ServiceExperience = 'serviceexperience',
  Volume = 'volume',
  InvestmentDurationObjective = 'investmentdurationobjective',
  InvestmentObjectivesTarget = 'investmentobjectives_target',
  InvestmentSustainabilityObjective = 'investmentsustainabilityobjective',
}
