import React, { createContext, FunctionComponent } from 'react';

interface TableContextValues {
  borderless: boolean;
  compact: boolean;
}

export const TableContext = createContext<TableContextValues>({
  borderless: false,
  compact: false,
});

export const TableProvider: FunctionComponent<{
  borderless: boolean;
  compact: boolean;
}> = ({ children, borderless, compact }) => {
  return <TableContext.Provider value={{ borderless, compact }}>{children}</TableContext.Provider>;
};

export default {
  TableContext,
  TableProvider,
};
