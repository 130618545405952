import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
// todo: UI should never import from anywhere other than UI, this is a bad pattern, flow of components is app > apps > ui, never the other way around
import PageTitle from 'core/components/page-title';
import * as Styled from './styled';
import Header from '../header';

export interface HeaderPanelProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  title?: ReactNode;
}

export const HeaderPanel: FunctionComponent<HeaderPanelProps> = (props) => {
  const { className, children, title, ...restProps } = props;

  return (
    <>
      {children && (
        <Styled.HeaderPanel className={cx('header-panel', className)} {...restProps}>
          {children}
        </Styled.HeaderPanel>
      )}
      {title && (
        <Header size="large" className={className}>
          <PageTitle renderInPlace={false}>{title}</PageTitle>
          {title || <>&nbsp;</>}
        </Header>
      )}
    </>
  );
};

export default HeaderPanel;
