import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

const documentViewDefaults = {
  embedHeight: '19rem',
};

interface DocumentViewProps {
  $borderd?: boolean;
  $height?: 'medium';
  $spacing?: Spacing;
}

function getHeight({ $height }: ThemedStyledProps<DocumentViewProps, DefaultTheme>) {
  return css`
    embed {
      height: ${$height ? documentViewDefaults.embedHeight : '100%'};
    }
  `;
}

function getBorder({ $borderd }: ThemedStyledProps<DocumentViewProps, DefaultTheme>) {
  if (!$borderd) return null;

  return css`
    embed {
      border: 2px solid black;
    }
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<DocumentViewProps, DefaultTheme>) {
  if (!$spacing) return null;

  const spacing = theme.spacing[$spacing];

  return css<DocumentViewProps>`
    &:not(:last-child) {
      margin-bottom: ${spacing}rem;
    }
  `;
}

export const DocumentView = styled.div<DocumentViewProps>`
  ${getBorder};
  ${getHeight};
  ${getSpacing};
`;
