import React, { FunctionComponent } from 'react';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';

export interface DeleteErrorProps {
  hasWallets: boolean;
}

const DeleteError: FunctionComponent<DeleteErrorProps> = ({ hasWallets }) => {
  return (
    <Hint variant="danger">
      {hasWallets ? (
        <p>
          <Translate name="dashboardIssuerInvestorDetails.deleteAccount.errors.wallets" />
        </p>
      ) : (
        <div>
          <p>
            <Translate name="dashboardIssuerInvestorDetails.deleteAccount.errors.info" />
          </p>
          <p>
            <Translate name="dashboardIssuerInvestorDetails.deleteAccount.errors.activeInvestments" />
          </p>
          <p>
            <Translate name="dashboardIssuerInvestorDetails.deleteAccount.errors.cancellation" />
          </p>
        </div>
      )}
    </Hint>
  );
};

export default DeleteError;
