import createActionEnhancerMiddleware from 'redux-action-enhancer';
import { Action, AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import type { StoreCreator } from 'redux-loop';
import { combineReducers, install } from 'redux-loop';
import { routerMiddleware } from 'connected-react-router';

import enhancers from './enhancers';
import history from 'core/routing/history';
import routerReducer from 'core/routing/reducer';
import authReducer from 'core/auth/reducer';
import configReducer from 'core/config/reducer';
import type { AsyncReducers, CashlinkStore, RootAppState } from './types';

const enhancedCreateStore = createStore as StoreCreator;

const staticReducers = {
  router: routerReducer,
  auth: authReducer,
  config: configReducer,
};

export const configureStore = (initialState = {}) => {
  const middlewares = [routerMiddleware(history), createActionEnhancerMiddleware(() => enhancers)];

  if (process.env.mode === 'development') {
    middlewares.push(createLogger());
  }

  const composeEnhancer: typeof compose =
    typeof (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
      ? (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: process.env.NODE_ENV !== 'production',
          shouldHotReload: false,
        })
      : compose;

  const store = enhancedCreateStore(
    combineReducers(staticReducers),
    initialState,
    composeEnhancer(
      install({
        DONT_LOG_ERRORS_ON_HANDLED_FAILURES: true,
        ENABLE_THUNK_MIGRATION: false,
      }),
      applyMiddleware(...middlewares),
    ),
    // todo: there is no good way of typing this. The problem is, redux-loop does not expose a "Store" and the redux store types do not understand "loop"s
  ) as CashlinkStore<any, any>;

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = <S extends RootAppState = RootAppState>(
    key: keyof S,
    asyncReducer: AsyncReducers<S>[keyof S],
  ) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.removeReducer = <S extends RootAppState = RootAppState>(key: keyof S) => {
    delete store.asyncReducers[key];
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  const createReducer =
    <S extends RootAppState = RootAppState, A extends Action = AnyAction>(asyncReducers: AsyncReducers<RootAppState>) =>
    (state: S, action: A) => {
      const appReducers = combineReducers({
        ...staticReducers,
        ...asyncReducers,
      });

      return appReducers(state, action);
    };

  return store;
};

export default configureStore();
