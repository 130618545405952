import { getHasInvestmentsEffect } from 'apps/investor/resources/investments/effects';
import { ConditionFn } from 'core/auth/components/with-conditional-page/conditional-page';
import { isLoggedInSelector } from 'core/auth/selectors';
import { replace } from 'connected-react-router';

export const registerAccessCondition: ConditionFn = async ({ resolveCondition, store, dispatch }) => {
  const isLoggedIn = isLoggedInSelector(store.getState());

  if (!isLoggedIn) {
    resolveCondition(true);
    return;
  }

  // todo: there should be an investments library where this comes from, not redefined here
  // also this is duplicated logic, but due to the current setup, the investor reducers are not accessible from the investment reducers
  const hasInvestments = await getHasInvestmentsEffect(store.getState);

  // if has investments, condition should be false
  resolveCondition(!hasInvestments);

  if (hasInvestments) {
    dispatch(replace('/dashboard'));
  }
};
