import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';
import { Spacing } from 'ui/styles/themes/spacing';

export type ActionButtonsAlign = 'start' | 'center' | 'end' | 'spaceBetween';

const ALIGNMENT_MAP: { [k in ActionButtonsAlign]: string } = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  spaceBetween: 'space-between',
};

export interface ActionButtonsProps {
  $align: ActionButtonsAlign;
  $spacing: Spacing;
  $reverse?: boolean;
  $fullWidth?: boolean;
  $verticalAlign?: boolean;
}

export interface ActionButtonWrapperProps {
  $fullWidth?: boolean;
  $verticalAlign?: boolean;
}

function getDefaultActionButtonWrapperStyle({
  theme,
  $fullWidth,
}: ThemedStyledProps<ActionButtonWrapperProps, DefaultTheme>) {
  return css<ActionButtonWrapperProps>`
    display: flex;
    flex-grow: ${$fullWidth ? 1 : 0};
    width: ${$fullWidth ? '100%' : 'auto'};
    & + & {
      margin-left: ${theme.forms.buttonsSpacing};
    }
  `;
}

function getActionButtonWrapperPhoneStyle() {
  return query.phone`
    margin-left: 0;
    width: 100%;

    & + & {
      margin-left: 0;
      margin-bottom: 0.5rem;
    }

    & > * {
      width: 100%;
    }
  `;
}

function getVerticallyAlignedActionButtonWrappeer({
  $verticalAlign,
}: ThemedStyledProps<ActionButtonWrapperProps, DefaultTheme>) {
  if (!$verticalAlign) return null;

  return css<ActionButtonWrapperProps>`
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  `;
}

function getActionButtonsPhoneStyle() {
  return query.phone`
    flex-direction: column-reverse;
    width: 100%;
  `;
}

function getDefaultActionButtonsStyle({
  theme,
  $spacing,
  $align,
  $reverse,
  $fullWidth,
}: ThemedStyledProps<ActionButtonsProps, DefaultTheme>) {
  return css<ActionButtonsProps>`
    display: flex;
    justify-content: ${$align ? ALIGNMENT_MAP[$align] : ALIGNMENT_MAP.end};
    flex-direction: ${$reverse ? 'row-reverse' : 'row'};
    margin-top: ${2 * theme.spacing[$spacing]}rem;
    width: ${$fullWidth ? '100%' : 'auto'};
    align-items: center;
  `;
}

function getVericallyAlignedActionButtons({ $verticalAlign }: ThemedStyledProps<ActionButtonsProps, DefaultTheme>) {
  if (!$verticalAlign) return null;

  return css<ActionButtonsProps>`
    flex-direction: column;
    align-items: flex-end;
  `;
}

export const ActionButtons = styled.div<ActionButtonsProps>`
  ${getDefaultActionButtonsStyle};
  ${getVericallyAlignedActionButtons};
  // ${getActionButtonsPhoneStyle};
`;

export const ActionButtonWrapper = styled.div<ActionButtonWrapperProps>`
  ${getDefaultActionButtonWrapperStyle};
  ${getVerticallyAlignedActionButtonWrappeer};
  // ${getActionButtonWrapperPhoneStyle};
`;
