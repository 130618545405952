import React, { FunctionComponent } from 'react';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import Currency from 'ui/atoms/currency';
import { AdminTokenGlobalOverview } from 'api';
import * as Styled from './styled';
import Grid, { Col } from 'ui/atoms/grid';

export interface ProductsOverviewProps {
  data: AdminTokenGlobalOverview;
}

const ProductsOverview: FunctionComponent<ProductsOverviewProps> = (props) => {
  const { data } = props;

  return (
    <>
      <Grid spacing="large">
        <Col width={4} phone={12} spacing="medium">
          <Segment raised={true}>
            <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
              <Translate name="dashboardIssuerOverview.products" />
            </Header>
            <Styled.HeaderRow>
              <div>{data.productsAmount}</div>
              &nbsp;
              <div>
                ({data.numberActiveInvestments} <Translate name="dashboardIssuerOverview.investments" />)
              </div>
            </Styled.HeaderRow>
          </Segment>
        </Col>
        <Col width={4} phone={12} spacing="medium">
          <Segment raised={true}>
            <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
              <Translate name="dashboardIssuerOverview.totalAmount" />
            </Header>
            <Styled.HeaderRow>
              <Currency>{data.sumActiveInvestments}</Currency>
            </Styled.HeaderRow>
          </Segment>
        </Col>
        <Col width={4} phone={12} spacing="medium">
          <Segment raised={true}>
            <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
              <Translate name="dashboardIssuerOverview.sumPaymentsReceived" />
            </Header>
            <Styled.HeaderRow>
              <Currency>{data.sumPaymentsReceived}</Currency>
            </Styled.HeaderRow>
          </Segment>
        </Col>
      </Grid>
    </>
  );
};

export default ProductsOverview;
