import { AdminInvestment, BenefitingPerson, CountryEnum, LegalPerson, NaturalPerson, SalutationEnum } from 'api';
import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import BenefitingPersonsModal from 'apps/issuer/shared/kyc-and-compliance-view/add-manual-review/add-manual-review-modals/benefiting-persons-modal';
import AddIdentificationModal from './add-identification-modal';
import EditBankAccountModal from './edit-bank-account-modal';
import EditCompanyDataModal from './edit-company-data-modal';
import EditDepositAccountModal from './edit-deposit-account-modal';
import EditRegistrationAddressModal from './edit-registration-address-modal';
import EditTaxInformationModal from './edit-tax-information-modal';
import SuccessMessageModal from './success-message-modal';

export interface InvestorEditModalsProps {
  id: string;
  isEditRegistrationAddressOpen: boolean;
  isEditCompanyDataOpen: boolean;
  isEditBankAccountOpen: boolean;
  isEditDepositAccountOpen: boolean;
  isEditTaxInformationOpen: boolean;
  isAddIdentificationOpen: boolean;
  isEditBenefitingPersonsOpen: boolean;
  isSuccessEditOpen: boolean;
  setEditRegistrationAddressModal: (val: boolean) => void;
  setEditCompanyDataModal: (val: boolean) => void;
  setEditBankAccountModal: (val: boolean) => void;
  setEditDepositAccountModal: (val: boolean) => void;
  setEditTaxInformationModal: (val: boolean) => void;
  setAddIdentificationModal: (val: boolean) => void;
  setEditBenefitingPersonsModal: (val: boolean) => void;
  fetchInvestorDetails: () => void;
  setSuccessModal: (val: boolean) => void;
  naturalPerson: NaturalPerson;
  legalPerson: LegalPerson;
  rootPerson: NaturalPerson | LegalPerson;
  investments: AdminInvestment[];
  benefitingOwners?: BenefitingPerson[];
  target2Bic?: string | null;
}

const InvestorEditModals: FunctionComponent<InvestorEditModalsProps> = (props) => {
  const {
    isEditRegistrationAddressOpen,
    isEditCompanyDataOpen,
    isEditBankAccountOpen,
    isEditDepositAccountOpen,
    isEditTaxInformationOpen,
    isAddIdentificationOpen,
    isEditBenefitingPersonsOpen,
    isSuccessEditOpen,
    setEditRegistrationAddressModal,
    setEditCompanyDataModal,
    setEditBankAccountModal,
    setEditDepositAccountModal,
    setEditTaxInformationModal,
    setSuccessModal,
    setEditBenefitingPersonsModal,
    setAddIdentificationModal,
    fetchInvestorDetails,
    naturalPerson,
    legalPerson,
    rootPerson,
    id,
    investments,
    benefitingOwners,
    target2Bic,
  } = props;

  return (
    <>
      {isEditRegistrationAddressOpen && (
        <EditRegistrationAddressModal
          onHideEditRegisterAddress={() => setEditRegistrationAddressModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            street: naturalPerson.street || '',
            zip: naturalPerson.zip || '',
            city: naturalPerson.city || '',
            country: naturalPerson.country || CountryEnum.DEU,
          }}
        />
      )}
      {isEditCompanyDataOpen && (
        <EditCompanyDataModal
          onHideEditCompanyData={() => setEditCompanyDataModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            companyName: legalPerson.companyName || '',
            legalEntityType: legalPerson.legalEntityType,
            street: legalPerson.street || '',
            zip: legalPerson.zip || '',
            city: legalPerson.city || '',
            country: legalPerson.country || CountryEnum.DEU,
            commercialRegister: legalPerson.commercialRegister || '',
            commercialRegisterNumber: legalPerson.commercialRegisterNumber || '',
            commercialRegisterStatement: null,
            transparencyRegister: null,
            listOfShareholders: null,
            commercialRegisterStatementId: legalPerson.commercialRegisterStatement?.id,
            listOfShareholdersId: legalPerson.listOfShareholders?.id,
            transparencyRegisterId: legalPerson.transparencyRegister?.id,
          }}
        />
      )}
      {isAddIdentificationOpen && (
        <AddIdentificationModal
          onHideAddIdentification={() => setAddIdentificationModal(false)}
          showSuccessModal={() => setSuccessModal(true)}
          fetchInvestorDetails={fetchInvestorDetails}
          investorId={id}
          registeredAddress={{
            street: naturalPerson?.street || '',
            zip: naturalPerson?.zip || '',
            city: naturalPerson?.city || '',
            country: naturalPerson?.country || '',
          }}
          personalData={{
            salutation: naturalPerson?.salutation || SalutationEnum.MR,
            forename: naturalPerson?.forename,
            surname: naturalPerson?.surname,
            dateOfBirth: naturalPerson?.birthDate || undefined,
            placeOfBirth: naturalPerson?.birthPlace || '',
            nationality: naturalPerson?.nationality || CountryEnum.DEU,
          }}
        />
      )}
      {isEditBankAccountOpen && (
        <EditBankAccountModal
          onHideEditBankAccount={() => setEditBankAccountModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            accountHolder: rootPerson?.bankAccount?.accountHolder || '',
            iban: rootPerson.bankAccount?.iban || '',
            target2Bic: target2Bic || '',
          }}
        />
      )}
      {isEditDepositAccountOpen && (
        <EditDepositAccountModal
          onHideEditDepositAccount={() => setEditDepositAccountModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          showSuccessModal={() => setSuccessModal(true)}
          values={{
            id: id,
            accountHolder: rootPerson.securitiesDepositAccount?.accountHolder || '',
            accountNumber: rootPerson.securitiesDepositAccount?.accountNumber || '',
            bic: rootPerson.securitiesDepositAccount?.bic || '',
          }}
        />
      )}
      {isEditTaxInformationOpen && (
        <EditTaxInformationModal
          onHideEditTaxInformation={() => setEditTaxInformationModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          showSuccessModal={() => setSuccessModal(true)}
          exemptionOrderSum={rootPerson.taxInformation?.exemptionOrderSum}
          investments={investments}
          values={{
            id: id,
            taxIdentificationNumber: rootPerson.taxInformation?.taxIdentificationNumber || '',
            nonAssessmentCertificate: String(rootPerson.taxInformation?.nonAssessmentCertificate) || 'false',
            nonAssessmentCertificateDocId: rootPerson.taxInformation?.nonAssessmentCertificateDoc?.id || '',
            nonAssessmentCertificateDoc: null,
          }}
        />
      )}
      {isEditBenefitingPersonsOpen && (
        <BenefitingPersonsModal
          onHide={() => setEditBenefitingPersonsModal(false)}
          fetchInvestorDetails={fetchInvestorDetails}
          benefitingOwners={benefitingOwners}
          investorId={id}
        />
      )}
      {isSuccessEditOpen && (
        <SuccessMessageModal
          title={<Translate name="dashboardIssuerInvestorDetails.editSuccessModal.title" />}
          onHideSuccessEdit={() => setSuccessModal(false)}
        >
          <Translate name="dashboardIssuerInvestorDetails.editSuccessModal.message" />
        </SuccessMessageModal>
      )}
    </>
  );
};

export default InvestorEditModals;
