import styled from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import Grid from 'ui/atoms/grid';
import Text from 'ui/polymorphs/text';
import Header from 'src/ui/atoms/header';
import { query } from 'ui/styles/queries';
import ActionButtons from 'src/ui/molecules/action-buttons';

export const ProcessOverviewContainer = styled.div`
  padding: 1rem;
  padding-top: 0;
  width: 620px;
  box-sizing: content-box;

  ${query.phone`
    margin: -2rem;
    margin-top: -1.5rem;
  `};

  ${query.phoneTablet`
    width: auto;
  `};
`;

export const InvestmentOverview = styled(Grid)`
  margin: 0 auto;
  display: flex;
  border-radius: 1rem;
  padding: 1rem 0;
  background-color: ${({ theme }) => resolveColor(theme, 'grayLight')};

  ${query.phone`
    border-radius: 0;
    margin-bottom: 1rem;
  `};
`;

export const InvestmentSteps = styled.div`
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => resolveColor(theme, 'grayLight')};
  margin-bottom: 1rem;

  ${query.phone`
    margin: 1rem;
    margin-top: 0;
  `};
`;

export const InvestmentDescription = styled(Text)`
  display: block;
`;

export const AmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InvestmentHeader = styled(Header)`
  ${query.phone`
    font-size: ${({ theme }) => theme.typography.header.small}rem;
  `};
`;

export const ButtonsContainer = styled(ActionButtons)`
  margin-top: 40px;

  ${query.phone`
    margin-top: 0;
  `};
`;
