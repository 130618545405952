import React, { useState, useEffect, useRef, FunctionComponent, ReactNode } from 'react';
import { DEVICES } from 'ui/styles/queries';
import useIsMedia from 'ui/hooks/use-is-media';
import ProgressStepsHorizontal from 'core/layout/wizard/progress-steps/progress-steps-horizontal';
import ProgressStepsDropdown from 'core/layout/wizard/progress-steps/progress-steps-dropdown';

export interface ProgressStep {
  id: number;
  title: ReactNode;
  tooltip?: ReactNode;
  current?: boolean;
  done?: boolean;
  active?: boolean;
  onClick?: () => void;
}

export interface ProgressStepProps {
  steps: ProgressStep[];
}

const ProgressSteps: FunctionComponent<ProgressStepProps> = (props) => {
  const { steps } = props;

  const isPhoneTablet = useIsMedia(DEVICES.phoneTablet);

  const menuRef = useRef<HTMLDivElement>(null);

  const [showStepsDropdown, setShowStepsDropdown] = useState(false);

  const checkSpacerWidth = () => {
    let renderStepsDropdown = false;
    setShowStepsDropdown(renderStepsDropdown);

    if (!menuRef.current) return null;

    const spacers = menuRef.current.querySelectorAll('.spacer');

    spacers.forEach((spacer) => {
      const spacerElement = spacer as HTMLElement;
      const spacerWidth = spacerElement.offsetWidth;

      if (spacerWidth <= 42) {
        renderStepsDropdown = true;
      }
    });

    setShowStepsDropdown(renderStepsDropdown);
  };

  useEffect(() => {
    window.addEventListener('resize', checkSpacerWidth);
    checkSpacerWidth();

    return () => {
      window.removeEventListener('resize', checkSpacerWidth);
    };
  }, []);

  if (!steps?.length) return null;

  return isPhoneTablet || showStepsDropdown ? (
    <ProgressStepsDropdown steps={steps} />
  ) : (
    <ProgressStepsHorizontal ref={menuRef} steps={steps} />
  );
};

export default ProgressSteps;
