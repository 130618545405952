import React, { FunctionComponent } from 'react';
import Spacer from 'src/ui/atoms/spacer';
import * as Styled from './styled';

export interface SearchItemProps {
  name: string;
  email: string;
  onClick: () => void;
}

export const SearchItem: FunctionComponent<SearchItemProps> = (props) => {
  const { name, email, onClick } = props;

  return (
    <Styled.SearchItem href="#" onMouseDown={onClick} role="button">
      <span>{name} </span>
      <Spacer x={1} />
      <Styled.Email>{email}</Styled.Email>
    </Styled.SearchItem>
  );
};

export default SearchItem;
