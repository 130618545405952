import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import Section from 'ui/atoms/section';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import StatusTag from 'ui/atoms/status-tag';
import { camelCase } from 'change-case';
import { InvestorsApi, InvestorStepEnum } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useCurrentUserSelector } from 'core/auth/hooks';
import LoadingRing from 'ui/atoms/loading-ring';
import {
  getInvestorRegistrationStatus,
  getInvestorRegistrationStatusVariant,
  InvestorRegistrationStatusEnum,
} from 'subapps/investment/pages/register/helpers/registration-status';
import ContinueRegistrationButton from 'apps/investor/pages/overview/continue-registration/continue-registration-button';

export interface ContinueRegistrationProps {
  onClick?: () => void;
}

const ContinueRegistration: FunctionComponent<ContinueRegistrationProps> = (props) => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);

  const [step, setStep] = useState<InvestorStepEnum>();

  const investorApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const { currentUser } = useCurrentUserSelector();

  const loadRegisterData = useCallback(() => {
    (async () => {
      await withApi(async () => {
        if (!currentUser?.investor) return;

        const wizard = await investorApi.investorsWizardRetrieve({ id: currentUser.investor });

        setStep(wizard.step);
      });
    })();
  }, [withApi, currentUser?.investor]);

  useEffect(() => {
    loadRegisterData();
  }, [loadRegisterData]);

  if (loading) return <LoadingRing />;

  if (error || !step) return null;

  const status = getInvestorRegistrationStatus({ step });

  return (
    <div>
      <BoxedContent
        title={
          status !== InvestorRegistrationStatusEnum.Finished && (
            <Translate name="dashboardInvestorOverview.continueRegistration.title" />
          )
        }
        releaseSpaceWhenTitleIsEmpty
      >
        <Section>
          <InfoGrid columns={4}>
            <InfoField title={<Translate name="dashboardInvestorOverview.continueRegistration.status" />}>
              <StatusTag variant={getInvestorRegistrationStatusVariant(status)} truncate={true}>
                <Translate name={`registrationStatus.${camelCase(status)}.status`} />
              </StatusTag>
            </InfoField>
          </InfoGrid>
        </Section>
        <Translate
          className="registration-status-description"
          preventRenderingOnNoTranslation={true}
          name={`registrationStatus.${camelCase(status)}.text`}
        />
        <ContinueRegistrationButton status={status} />
      </BoxedContent>
    </div>
  );
};

export default ContinueRegistration;
