import styled from 'styled-components';

export type ModalButtonsAlign = 'start' | 'center' | 'end';

const ALIGNMENT_MAP: { [k in ModalButtonsAlign]: string } = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

export const ModalFooterButtons = styled.div<{ $align?: ModalButtonsAlign; $disableClose?: boolean }>`
  display: inline-flex;
  justify-content: ${({ $align }) => ($align ? ALIGNMENT_MAP[$align] : ALIGNMENT_MAP.end)};
  width: 100%;
`;
