import React, { FunctionComponent, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import { AdminInvestment } from 'api/models';
import Section from 'ui/atoms/section';
import InvestmentTableView from 'apps/issuer/pages/investor-details/investments-view/investment-table-view';
import { hasManageRedemptionsPermission } from 'apps/issuer/helpers/permissions';
import CreateRedemptionView from '../create-redemption';

interface IssuerInvestorDetailsPageProps {
  fetchInvestorDetails: () => void;
  investments: AdminInvestment[];
  investorId: string;
}

const InvestmentsView: FunctionComponent<IssuerInvestorDetailsPageProps> = (props) => {
  const { fetchInvestorDetails, investments, investorId } = props;
  const [forceInvestmentDataLoad, setForceInvestmentDataLoad] = useState(false);

  const onSuccessfulRedemption = () => {
    setForceInvestmentDataLoad(true);
    fetchInvestorDetails();
    setForceInvestmentDataLoad(false);
  };

  return (
    <Section spacing="large">
      <WideContent
        title={<Translate name={'dashboardIssuerInvestorDetails.investments'} />}
        headerPanel={
          !!investments?.length &&
          hasManageRedemptionsPermission(investments?.map((investment) => investment.token)) && (
            <CreateRedemptionView
              investments={investments}
              investorId={investorId}
              onSuccessfulRedemption={onSuccessfulRedemption}
            />
          )
        }
        ignorePageTitle
      >
        <InvestmentTableView forceDataLoad={forceInvestmentDataLoad} investorId={investorId} />
      </WideContent>
    </Section>
  );
};

export default InvestmentsView;
