import {
  CampaignInvitation,
  ExternalCampaignInvitation,
  InvestmentInvitation,
  Invitation,
  LegalPerson,
  NaturalPerson,
  Person,
} from 'api';

export function CastPolymorphic<BaseClass, SubClass extends BaseClass>(
  typeAttrName: string,
  type: string,
  obj?: BaseClass,
): SubClass | null {
  if (!obj) return null;

  if ((obj as any)[typeAttrName] === type) return obj as SubClass;
  else return null;
}

export type InvitationAll = InvestmentInvitation & CampaignInvitation;

export function InvitationToNonPreviewInvitation(invitation?: Invitation) {
  return (
    InvitationToInvestmentInvitation(invitation) ||
    InvitationToCampaignInvitation(invitation) ||
    InvitationToExternalCampaignInvitation(invitation) ||
    InvitationToTransferInvitation(invitation)
  );
}

export function InvitationToInvestmentInvitation(invitation?: Invitation) {
  return CastPolymorphic<Invitation, InvestmentInvitation>('invitationType', 'investment_invitation', invitation);
}

export function InvitationToTransferInvitation(invitation?: Invitation) {
  return CastPolymorphic<Invitation, InvestmentInvitation>('invitationType', 'transfer_invitation', invitation);
}

export function InvitationToCampaignInvitation(invitation?: Invitation) {
  return CastPolymorphic<Invitation, CampaignInvitation>('invitationType', 'campaign_invitation', invitation);
}

export function InvitationToExternalCampaignInvitation(invitation?: Invitation) {
  return CastPolymorphic<Invitation, ExternalCampaignInvitation>(
    'invitationType',
    'external_campaign_invitation',
    invitation,
  );
}

export function PersonToNaturalPerson(person?: Person) {
  return CastPolymorphic<Person, NaturalPerson>('personType', 'natural', person);
}

export function PersonToLegalPerson(person?: Person) {
  return CastPolymorphic<Person, LegalPerson>('personType', 'legal', person);
}
