import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons } from 'ui/molecules/modal';
import CopyInputGroup from 'ui/molecules/copy-input-group';
import FormGroup from 'ui/molecules/form-group';
import * as Styled from './styled';

export interface ReceiveAssetsModalProps {
  /** Open? */
  open?: boolean;

  /** Receiver wallet address */
  walletAddress: string;

  /** Cancel button action */
  onCancel?: () => void;

  /** Continue button action */
  onContinue?: () => void;

  /** Additional classes. */
  className?: string;
}

const ReceiveAssetsModal: FunctionComponent<ReceiveAssetsModalProps> = ({
  open = false,
  className,
  walletAddress,
  onCancel = () => {},
  onContinue = () => {},
}) => {
  if (!open) return null;

  return (
    <Modal onClose={onCancel} className={cx('receive-assets-modal', className)}>
      <ModalHeader>
        <Translate name="portfolio.receiveAssetsModal.title" />
      </ModalHeader>
      <ModalContent>
        <Styled.TopText>
          <Translate name="portfolio.receiveAssetsModal.text" />
        </Styled.TopText>
        <FormGroup label={<Translate name="portfolio.receiveAssetsModal.label" />}>
          <CopyInputGroup valueToCopy={walletAddress} />
        </FormGroup>
        <Hint variant="warning">
          <Translate name="portfolio.receiveAssetsModal.warning" />
        </Hint>
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              size: 'large',
              onClick: onCancel,
            },
            {
              name: 'continue',
              content: <Translate name="portfolio.receiveAssetsModal.continueButton" />,
              variant: 'primary',
              size: 'large',
              onClick: onContinue,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ReceiveAssetsModal;
