import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'store/hooks';
import type { User } from 'api';
import useOnlyOnce from 'hooks/use-only-once';
import { verifyAuth, reLogin, setAuthToken } from 'core/auth/actions';
import { useCurrentUserSelector, useAuthIsVerified, useAuthTokenSelector } from 'core/auth/hooks';
import { getLocalStorageAuth } from 'core/auth/effects';
import { I18NContext } from 'ui/i18n/provider';

export function useValidateAuth() {
  const dispatch = useDispatch();
  const { currentUser, loading, error } = useCurrentUserSelector();
  const authIsVerified = useAuthIsVerified();

  useOnlyOnce(() => {
    dispatch(verifyAuth());
  });

  return { authIsVerified, currentUser, loading, error };
}

/**
 * Ensures that the auth token is synced from local storage to redux, whenever the two are not the same
 * This can happen if a different browser tab updated it, or the user logged out, etc.
 */
export function useAuthTokenSync(): void {
  const dispatch = useDispatch();
  const { authToken: authTokenInStore } = useAuthTokenSelector();

  const syncAuthToken = useCallback(() => {
    const localStorageAuth = getLocalStorageAuth();

    if (!localStorageAuth && authTokenInStore) {
      dispatch(reLogin());
    }

    if (!localStorageAuth) return;

    if (localStorageAuth !== authTokenInStore) {
      dispatch(setAuthToken(localStorageAuth));
    }
  }, [dispatch, reLogin, setAuthToken, authTokenInStore]);

  useEffect(() => {
    window.addEventListener('storage', syncAuthToken);

    return () => {
      window.removeEventListener('storage', syncAuthToken);
    };
  }, [syncAuthToken]);
}

export function useInitUserLanguage(me: User | null) {
  const { changeLocale } = useContext(I18NContext);

  const userLanguage = (me && me.language && me.language.split('-')[0].toLowerCase()) || undefined;

  useEffect(() => {
    changeLocale(userLanguage, { user: true });
  }, [userLanguage, changeLocale]);
}
