import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import Spacing from 'ui/types/spacing';
import cx from 'ui/helper/prefixed-class-names';

export interface SectionProps {
  /** Additional classes. */
  className?: string;

  /** Spacing at the bottom. */
  spacing?: Spacing;
}

export const Section: FunctionComponent<SectionProps> = (props) => {
  const { className, children, spacing = 'medium', ...restProps } = props;

  return (
    <Styled.Section $spacing={spacing} className={cx('section', className)} {...restProps}>
      {children}
    </Styled.Section>
  );
};

export default Section;
