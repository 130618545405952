import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import { PasswordStrength } from 'ui/atoms/strength-indicator';
import makeForm from 'ui/forms';

export interface ChangePasswordFields {
  newPassword: string;
  newPasswordRepeat: string;
  oldPassword: string;
}

export interface ChangePasswordProps {
  /** Additional classes. */
  className?: string;

  passwordChangedSuccessfully?: boolean;

  /** Password strength */
  passwordStrength?: PasswordStrength;
}

export const ChangePasswordForm = makeForm<ChangePasswordFields>();

const ChangePassword: FunctionComponent<ChangePasswordProps> = (props) => {
  const { className, passwordChangedSuccessfully, passwordStrength, ...restProps } = props;

  return (
    <div className={cx('change-password', className)} {...restProps}>
      {passwordChangedSuccessfully && (
        <>
          <Hint variant="success">
            <Translate name="changePassword.success" />
          </Hint>
        </>
      )}
      {!passwordChangedSuccessfully && (
        <>
          <ChangePasswordForm.Group name="oldPassword" required={true} simple={true}>
            <ChangePasswordForm.PasswordInput />
          </ChangePasswordForm.Group>
          <ChangePasswordForm.PasswordGroup
            passwordField={{
              name: 'newPassword',
              label: <Translate name="changePassword.fields.newPassword.label" />,
            }}
            passwordRepeatField={{
              name: 'newPasswordRepeat',
              label: <Translate name="changePassword.fields.newPasswordRepeat.label" />,
            }}
            passwordStrength={passwordStrength}
          />
        </>
      )}
    </div>
  );
};

export default ChangePassword;
