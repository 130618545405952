import { useEffect, useState } from 'react';

const useAsyncFunction = <T>(asyncFunction: () => Promise<T>, condition = true): T | undefined => {
  const [returnToUse, setReturnToUse] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (!condition) return;
    (async () => {
      try {
        const returnToUse = await asyncFunction();

        setReturnToUse(() => returnToUse);
      } catch (e) {
        setReturnToUse(undefined);
      }
    })();
  }, [condition, asyncFunction]);

  return returnToUse;
};

export default useAsyncFunction;
