import React, { FunctionComponent, useContext } from 'react';
import { Colors } from 'ui/styles/utils/colors';
import { ModalContext } from 'ui/molecules/modal/provider';
import * as Styled from './styled';

interface ModalHeaderProps {
  disableClose?: boolean;
  iconColor?: Colors;
  onClose?: () => void;
  overlap?: boolean;
  className?: string;
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  children,
  iconColor = 'grayDark',
  overlap,
  className,
}) => {
  const { disableClose, onClose } = useContext(ModalContext);

  function closeButton() {
    if (disableClose) {
      return null;
    }

    return <Styled.CloseIcon name="close-thin" size="large" color={iconColor} onClick={onClose} />;
  }

  return (
    <Styled.ModalHeader $overlap={overlap} className={className}>
      {children}
      {closeButton()}
    </Styled.ModalHeader>
  );
};

export default ModalHeader;
