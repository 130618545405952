import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import Hint from 'ui/atoms/hint';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';

const TransferInvitationUserLogged: FunctionComponent = () => {
  const goToInvestments = useGoTo(INVESTOR_ROUTES.overview);

  return (
    <NarrowContent>
      <BoxedContent title={<Translate name="transferCampaign.registrationLink" />}>
        <Hint variant="danger" withoutIcon>
          <Translate name="transferCampaign.existingAccountError" />
        </Hint>
        <p>
          <Translate name="transferCampaign.existingAccountInfo" />
        </p>
        <Button variant="primary" size="large" fluid={true} onClick={() => goToInvestments()}>
          <Translate name="errors.actions.toInvestmentOverview" />
        </Button>
      </BoxedContent>
    </NarrowContent>
  );
};

export default TransferInvitationUserLogged;
