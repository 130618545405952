import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import HeaderSize from 'ui/types/header-size';
import type { Colors } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';
import { TextAlignOptions } from 'ui/polymorphs/text/styled';

export interface HeaderProps {
  $spacing: Spacing;
  $size?: HeaderSize;
  $color?: Colors;
  $breakWords?: boolean;
  $inverted?: boolean;
  $uppercase?: boolean;
  $truncate?: boolean;
  $textAlign?: TextAlignOptions;
  as: string;
}

const getSpacingFromTheme = (theme: DefaultTheme, spacing: Spacing) => theme.spacing[spacing];

function getDefaultStyle() {
  return css`
    &.header {
      font-weight: 700;
    }
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  const spacing = getSpacingFromTheme(theme, $spacing);

  return css<HeaderProps>`
    margin-bottom: ${spacing}rem;
  `;
}

function getColor({ theme, $color, $inverted }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  if ($color) {
    const color = resolveColor(theme, $color);

    if ($inverted) {
      return css<HeaderProps>`
        color: ${$color};
        filter: brightness(20%);
      `;
    }

    return css<HeaderProps>`
      color: ${color};
    `;
  }

  return css<HeaderProps>`
    color: ${$color ? resolveColor(theme, $color, 'grayMain') : 'currentColor'};
    fill: ${$color ? resolveColor(theme, $color, 'grayMain') : 'currentColor'};
  `;
}

function getUppercase({ $uppercase }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  if (!$uppercase) return null;

  return css`
    text-transform: uppercase;
  `;
}

function getTextAlign({ $textAlign }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  if (!$textAlign) return null;

  return css`
    text-align: ${$textAlign};
  `;
}

function getTruncation({ $truncate }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  if (!$truncate) return null;

  return css`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  `;
}

function getFontSize({ theme, as, $size }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  const size = $size || as;

  if ($size === 'tiny') {
    return css`
      font-size: ${theme.typography.header.tiny}rem;

      ${query.phone`
        font-size: ${theme.typography.sizes.tiny}rem;
      `};
    `;
  }

  if ($size === 'xsmall') {
    return css`
      font-size: ${theme.typography.header.xsmall}rem;

      ${query.phone`
        font-size: ${theme.typography.header.tiny}rem;
      `};
    `;
  }

  if (size === 'large') {
    return css<HeaderProps>`
      font-size: ${theme.typography.header.large}rem;

      ${query.phone`
        font-size: ${theme.typography.header.medium}rem;
      `};
    `;
  }

  if (size === 'medium') {
    return css<HeaderProps>`
      font-size: ${theme.typography.header.medium}rem;
    `;
  }

  return css<HeaderProps>`
    font-size: ${theme.typography.header.small}rem;

    ${query.phone`
      font-size: ${theme.typography.header.xsmall}rem;
    `};
  `;
}

function getLineHeight({ theme, as, $size }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  const size = $size || as;

  if ($size === 'tiny') {
    return css`
      line-height: 19.6px;
    `;
  }

  if ($size === 'xsmall') {
    return css`
      line-height: 22.4px;

      ${query.phone`
        line-height: 19.6px;
      `};
    `;
  }

  if (size === 'large') {
    return css<HeaderProps>`
      line-height: 44.8px;

      ${query.phone`
        line-height: 33.6px;
      `};
    `;
  }

  if (size === 'medium') {
    return css<HeaderProps>`
      line-height: 33.6px;
    `;
  }

  return css<HeaderProps>`
    line-height: 28px;

    ${query.phone`
      line-height: 22.4px;
    `};
  `;
}

function getBreakWords({ $breakWords }: ThemedStyledProps<HeaderProps, DefaultTheme>) {
  if (!$breakWords) return null;

  return css`
    word-break: break-all;
  `;
}

export const Header = styled.div<HeaderProps>`
  ${getDefaultStyle};
  ${getSpacing};
  ${getColor};
  ${getUppercase};
  ${getTextAlign};
  ${getFontSize};
  ${getLineHeight};
  ${getTruncation};
  ${getBreakWords};
`;
