import Multiselect from 'multiselect-react-dropdown';
import { resolveColor } from 'src/ui/styles/utils/colors';
import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const StyledMultiSelect = styled(Multiselect)`
  .searchWrapper {
    min-height: 60px;
    padding: 5px 16px;
    border-radius: 8px;
    border: none;
    outline: 1px solid ${({ theme }) => resolveColor(theme, 'grayHover')};

    &:hover {
      outline: 2px solid ${({ theme }) => resolveColor(theme, 'primary')};
    }

    img {
      right: 16px;
    }
  }

  .searchBox {
    width: 100%;
    margin-top: 12px;

    ${query.phone`
      margin-top: 15px;
    `};
  }

  .optionContainer li:hover,
  .highlightOption,
  .chip {
    background-color: ${({ theme }) => theme.colorVariants.primary};
  }
`;
