import { LocalStorageInvitation } from 'subapps/investment/types';

export const getInvitationIdLocal = (invitationId: string): string => {
  try {
    return localStorage.getItem(`invitation-${invitationId}`) || '';
  } catch (e) {
    return '';
  }
};

export const getInvitationIdsLocal = (): LocalStorageInvitation[] => {
  try {
    return Object.keys(localStorage)
      .filter((key) => key.startsWith('invitation'))
      .map((invitationKey) => {
        return JSON.parse(localStorage.getItem(invitationKey) || '');
      });
  } catch (e) {
    return [];
  }
};

export const setInvitationIdLocal = (invitationId: string, value: string) => {
  try {
    return localStorage.setItem(`invitation-${invitationId}`, value);
  } catch (e) {
    return;
  }
};

export const removeInvitationIdLocal = (invitationId: string) => {
  try {
    return localStorage.removeItem(`invitation-${invitationId}`);
  } catch (e) {
    return;
  }
};

export const getRegistrationLocalStorage = () => {
  try {
    return localStorage.getItem(`registration-ongoing`) === 'true';
  } catch (e) {
    return false;
  }
};

export const setRegistrationLocalStorage = (value: boolean) => {
  try {
    return localStorage.setItem(`registration-ongoing`, String(value));
  } catch (e) {
    return;
  }
};

export const removeRegistrationLocalStorage = () => {
  try {
    return localStorage.removeItem('registration-ongoing');
  } catch (e) {
    return;
  }
};
