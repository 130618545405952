import { AdminApi, PatchedAdminInvestorUpdateRequest } from 'api';
import useApiCall from 'hooks/use-api-call';
import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import { Modal, makeModalForm, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import { StateValues } from 'react-use-form-state';

export interface EditBankAccountFields {
  id: string;
  accountHolder: string;
  iban: string;
  target2Bic: string;
}

export interface EditBankAccountModalProps {
  onHideEditBankAccount: () => void;
  fetchInvestorDetails: () => void;
  showSuccessModal: () => void;
  className?: string;
  values: EditBankAccountFields;
}

const EditBankAccountForm = makeModalForm<EditBankAccountFields>();

const EditBankAccountModal: FunctionComponent<EditBankAccountModalProps> = (props) => {
  const { onHideEditBankAccount, fetchInvestorDetails, showSuccessModal = () => {}, values } = props;

  const [investorData, setInvestorData] = useState<EditBankAccountFields>(values || {});

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const editBankAccount = (fieldsValues: StateValues<EditBankAccountFields>) => {
    let params: PatchedAdminInvestorUpdateRequest = {};

    if (fieldsValues.accountHolder.length > 0 && fieldsValues.iban.length > 0) {
      params.bankAccount = {
        accountHolder: fieldsValues.accountHolder,
        iban: fieldsValues.iban,
      };
    }

    if (fieldsValues.target2Bic.length > 0) {
      params.target2Bic = fieldsValues.target2Bic;
    }

    withApi(async () => {
      const patchBody = {
        id: values.id,
        patchedAdminInvestorUpdateRequest: {
          ...params,
        },
      };
      await investorsApi.adminInvestorsPartialUpdate(patchBody);
      showSuccessModal();
      fetchInvestorDetails();
      onHideEditBankAccount();
    });
  };

  return (
    <>
      <Modal onClose={onHideEditBankAccount}>
        <EditBankAccountForm
          initial={{
            accountHolder: investorData.accountHolder,
            iban: investorData.iban,
            target2Bic: investorData.target2Bic,
          }}
          onChange={(values: StateValues<EditBankAccountFields>) => {
            setInvestorData(values);
          }}
          onSubmit={(values: StateValues<EditBankAccountFields>) => {
            editBankAccount(values);
          }}
          i18nKey="dashboardIssuerInvestorDetails.editBankAccountForm"
        >
          <ModalHeader>
            <Translate name="dashboardIssuerInvestorDetails.editBankAccountForm.title" />
          </ModalHeader>
          <ModalContent>
            <EditBankAccountForm.Group name="accountHolder" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="iban" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
              {EditBankAccountForm.Validators.IBAN()}
            </EditBankAccountForm.Group>
            <EditBankAccountForm.Group name="target2Bic" required={false}>
              <EditBankAccountForm.Input placeholder="-" />
            </EditBankAccountForm.Group>
            <EditBankAccountForm.GenericErrorMessages />
          </ModalContent>
          <ModalFooter>
            <ModalFooterButtons
              actionButtons={[
                {
                  name: 'cancel',
                  content: <Translate name="common.close" />,
                  size: 'large',
                  onClick: onHideEditBankAccount,
                },
                {
                  name: 'send',
                  content: <Translate name="dashboardIssuerInvestorDetails.editBankAccountForm.button" />,
                  variant: 'primary',
                  size: 'large',
                  type: 'submit',
                },
              ]}
            />
          </ModalFooter>
        </EditBankAccountForm>
      </Modal>
    </>
  );
};

export default EditBankAccountModal;
