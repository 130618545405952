import React, { FunctionComponent } from 'react';
import { StateValues } from 'react-use-form-state';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalFooterButtons, makeModalForm } from 'ui/molecules/modal';
import Grid, { Col } from 'ui/atoms/grid';
import Search from 'src/ui/atoms/search';
import Section from 'src/ui/atoms/section';
import SearchItem from './search-item';
import { FileToUpload } from 'src/ui/atoms/file-upload/multiple-file-upload';
import Hint from 'src/ui/atoms/hint';
import { useAddInvestment } from './hooks';

interface AddInvestmentModalProps {
  campaignId: string;
  onAddNewInvestment: () => void;
  onClose: () => void;
}

export interface AddInvestmentFields {
  units: string;
  disagio?: string;
  exemptionOrderAmount?: string;
  signedAt: Date;
  paidAt: Date;
  acceptedAt: Date;
  wallet: string;
  documents?: FileToUpload[];
}

export const Form = makeModalForm<AddInvestmentFields>();

const AddInvestmentModal: FunctionComponent<AddInvestmentModalProps> = ({
  campaignId,
  onAddNewInvestment,
  onClose,
}) => {
  const { investors, selectedInvestor, error, setSelectedInvestor, getDocumentTypes, onTypeInvestor, onSubmit } =
    useAddInvestment(campaignId, onAddNewInvestment);

  return (
    <Modal onClose={onClose}>
      <Form
        i18nKey="addInvestment"
        onSubmit={(values: StateValues<AddInvestmentFields>) => {
          onSubmit(values);
        }}
      >
        <ModalHeader>
          <Translate name="addInvestment.title" />
        </ModalHeader>
        <ModalContent>
          <>
            <Header>
              <Translate name="addInvestment.info" />
            </Header>
            <Section spacing="small">
              <strong>
                <Translate name="addInvestment.fields.investor.label" />
              </strong>
              <Search
                id="search-investor"
                selectedItem={selectedInvestor ? `${selectedInvestor.name} ${selectedInvestor.email}` : null}
                onChange={(investorName) => onTypeInvestor(investorName)}
                onRemoveSelection={() => setSelectedInvestor(null)}
              >
                {investors?.map((investor) => (
                  <SearchItem
                    name={investor.name}
                    email={investor.email}
                    onClick={() => setSelectedInvestor(investor)}
                    key={investor.name}
                  ></SearchItem>
                ))}
              </Search>
            </Section>
            <Grid>
              <Col width={6} tablet={12} phone={12}>
                <Form.Group name="units" required={true}>
                  <Form.Input placeholder="9999999" />
                </Form.Group>
              </Col>
              <Col width={6} tablet={12} phone={12}>
                <Form.Group name="disagio">
                  <Form.Input placeholder="9999999 €" />
                </Form.Group>
              </Col>
            </Grid>
            <Form.Group name="exemptionOrderAmount">
              <Form.Input placeholder="999999 €" />
            </Form.Group>
            <Form.Group name="signedAt" required={true}>
              <Form.DateInput max={new Date()} hasTime={true} />
            </Form.Group>
            <Form.Group name="paidAt" required={true}>
              <Form.DateInput max={new Date()} hasTime={true} />
            </Form.Group>
            <Form.Group name="acceptedAt" required={true}>
              <Form.DateInput max={new Date()} hasTime={true} />
            </Form.Group>
            {selectedInvestor && (
              <Form.Group name="wallet" required={true}>
                <Form.Select
                  options={
                    selectedInvestor.wallets.map((wallet) => ({
                      value: wallet.id,
                      label: wallet.ethInfo?.address,
                    })) || []
                  }
                ></Form.Select>
              </Form.Group>
            )}
            <Form.Group name="documents">
              <Form.MultipleFilesUpload documentTypes={getDocumentTypes()} />
              {Form.Validators.FileSize(5000000)}
            </Form.Group>
            {error && (
              <Hint variant="danger">
                <Translate name={error} />
              </Hint>
            )}
            <Form.GenericErrorMessages />
          </>
        </ModalContent>
        <ModalFooter>
          <ModalFooterButtons
            actionButtons={[
              {
                name: 'cancel',
                content: <Translate name="common.close" />,
                size: 'large',
                onClick: () => onClose(),
              },
              {
                name: 'send',
                content: <Translate name="addInvestor.submit" />,
                variant: 'primary',
                type: 'submit',
                size: 'large',
              },
            ]}
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddInvestmentModal;
