import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import * as Styled from './styled';
import { v4 as uuid } from 'uuid';
import Spacing from 'ui/types/spacing';

export interface RadioProps {
  id?: string;

  /** Primary content. */
  children?: ReactNode;

  /** Primary content when children is not set */
  content?: ReactNode;

  /** Additional classes. */
  className?: string;

  /** Radio input is checked. */
  checked?: boolean;

  /** Radio input is required. */
  required?: boolean;

  onChange?: React.FormEventHandler<this>;

  onBlur?: React.FormEventHandler<this>;

  readOnly?: boolean;

  /** Radio input can be disabled. */
  disabled?: boolean;

  value?: string;

  /** Spacing at the bottom. */
  spacing?: Spacing;

  /** Validity of radios. */
  valid?: boolean;
}

export const Radio: FunctionComponent<RadioProps> = (props) => {
  const { className, children, id, content, value, spacing = 'medium', valid, disabled, checked, ...restProps } = props;

  const inputID = useMemo(() => id || uuid(), [id]);

  return (
    <Styled.RadioContainer $spacing={spacing} className="radio-container">
      <Styled.Label htmlFor={inputID} $disabled={disabled} $checked={checked}>
        <Styled.Radio $checked={checked} $disabled={disabled} $isInvalid={valid === false} className="radio" />
        <Styled.RadioCheckmark $checked={checked} $disabled={disabled} />
        <Styled.RadioInput
          type="radio"
          id={inputID}
          value={value}
          disabled={disabled}
          checked={checked}
          className={className}
          {...restProps}
        />
      </Styled.Label>
      <span>{children || content}</span>
    </Styled.RadioContainer>
  );
};

export default Radio;
