import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import { InvestorInvestmentStatusEnum } from 'ui/types/investment';
import Translate from 'ui/atoms/translate';
import { camelCase } from 'change-case';
import Date from 'ui/atoms/date';
import { Money } from 'api';
import Currency from 'ui/atoms/currency';
import { useServerConfigSelector } from 'core/config/hooks';
import Link from 'ui/atoms/link';

export interface InvestorInvestmentStatusDescriptionProps {
  /** Primary content. */
  children?: ReactNode;
  /** Additional classes. */
  className?: string;
  status: InvestorInvestmentStatusEnum;
  walletAddress?: string | null;
  signedDate?: Date | null;
  investmentTotalFulfilled?: Money | null;
  areAllTokensUnavailable?: boolean;
  areTokensPartiallyAvailable?: boolean;
  productName?: string;
  isEcsp: boolean | undefined;
  hoursLeft: number;
}

const InvestorInvestmentStatusDescription: FunctionComponent<InvestorInvestmentStatusDescriptionProps> = (props) => {
  const {
    className,
    children,
    status,
    walletAddress,
    signedDate,
    investmentTotalFulfilled,
    areAllTokensUnavailable,
    areTokensPartiallyAvailable,
    productName,
    isEcsp,
    hoursLeft,
    ...restProps
  } = props;

  const {
    config: { investNowUrl },
  } = useServerConfigSelector();

  const getAllUnavailableTokensDescription = useCallback(() => {
    return (
      <>
        <Translate
          className={cx('investor-investment-status-description', className)}
          preventRenderingOnNoTranslation={true}
          name={'investorInvestmentStatus.tokensAllUnavailable.description'}
          args={[productName]}
          {...restProps}
        />
        {investNowUrl && (
          <Translate
            className={cx('investor-investment-status-description', className)}
            preventRenderingOnNoTranslation={true}
            name={'investorInvestmentStatus.tokensAllUnavailableInvestNow.description'}
            args={[
              (text, key) => (
                <Link href={investNowUrl} key={key} inline external>
                  {text}
                </Link>
              ),
            ]}
            {...restProps}
          />
        )}
      </>
    );
  }, [productName, investNowUrl]);

  const getPartiallyUnavailableTokensDescription = useCallback(() => {
    return (
      <>
        <Translate
          className={cx('investor-investment-status-description', className)}
          preventRenderingOnNoTranslation={true}
          name={'investorInvestmentStatus.tokensPartiallyUnavailable.description'}
          args={[productName]}
          {...restProps}
        />
        {investNowUrl && (
          <Translate
            className={cx('investor-investment-status-description', className)}
            preventRenderingOnNoTranslation={true}
            name={'investorInvestmentStatus.tokensPartiallyUnavailableInvestNow.description'}
            args={[
              (text, key) => (
                <Link href={investNowUrl} key={key} inline external>
                  {text}
                </Link>
              ),
            ]}
            {...restProps}
          />
        )}
      </>
    );
  }, [productName, investNowUrl]);

  const getInvestmentDescription = useCallback(() => {
    return (
      <>
        <Translate
          className={cx('investor-investment-status-description', className)}
          preventRenderingOnNoTranslation={true}
          name={`investorInvestmentStatus.${camelCase(status)}.description`}
          args={[
            investmentTotalFulfilled ? (
              <Currency>{investmentTotalFulfilled}</Currency>
            ) : (
              (_, key) => <strong key={key}>{walletAddress}</strong>
            ),
          ]}
          {...restProps}
        />
      </>
    );
  }, [status, investmentTotalFulfilled, walletAddress]);

  const considerationTimeText = useMemo(() => {
    if (hoursLeft > 1) {
      return 'considerationTime';
    }
    if (hoursLeft === 1) {
      return 'considerationTimeOneHour';
    }
    if (hoursLeft === 0) {
      return 'considerationTimeSoon';
    }
  }, [hoursLeft]);

  if (areAllTokensUnavailable) return getAllUnavailableTokensDescription();
  if (areTokensPartiallyAvailable) return getPartiallyUnavailableTokensDescription();

  return (
    <>
      {isEcsp && hoursLeft >= 0 && status === InvestorInvestmentStatusEnum.WaitingAcceptance && (
        <Translate
          className={cx('investor-investment-status-description', className)}
          name={`investorInvestmentStatus.${considerationTimeText}.description`}
          args={[hoursLeft]}
        />
      )}
      {isEcsp && hoursLeft < 0 && getInvestmentDescription()}
      {status !== InvestorInvestmentStatusEnum.Handover && !isEcsp && getInvestmentDescription()}
      {signedDate && (
        <Translate
          as="p"
          name={'investorInvestmentStatus.handover.description'}
          args={[(_, key) => <Date key={key}>{signedDate}</Date>]}
        />
      )}
    </>
  );
};

export default InvestorInvestmentStatusDescription;
