export interface CommonTheme {
  commonBodyBg: any;
  bodyColor: any;
  disabledOpacity: any;
  btnDisabledOpacity: any;
  borderRadius: any;
  colorYigThreshold: number;
  colorDarkenValue: number;
}

export default {
  commonBodyBg: ({ theme }: any) => theme.colors.grayLight,
  bodyColor: ({ theme }: any) => theme.colorVariants.background,
  disabledOpacity: 0.3,
  btnDisabledOpacity: 0.3,
  borderRadius: '0.5em',
  colorYigThreshold: 180,
  colorDarkenValue: 10,
} as CommonTheme;
