import React, { FunctionComponent, ReactNode } from 'react';
import Translate from '../translate';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';

export enum PasswordStrength {
  STRONG = 'strong',
  MEDIUM = 'medium',
  WEAK = 'weak',
}

export interface StrengthIndicatorProps {
  /** Primary content. */
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  passwordStrength?: PasswordStrength;

  /**
   * Weak validation can be hidden (e.g. if input field is empty and was not touched yet).
   *
   * @default true
   * */
  hideWeakValidation?: boolean;
}

export const StrengthIndicator: FunctionComponent<StrengthIndicatorProps> = (props) => {
  const { className, children, passwordStrength, hideWeakValidation, ...restProps } = props;

  const COLORS = {
    RED: '#FF2D2D',
    YELLOW: '#F5C518',
    GREEN: '#52A31D',
  };

  return (
    <>
      {passwordStrength && (
        <Styled.StrengthIndicatorContainer>
          <Styled.StrengthIndicator className={cx('strength-indicator', className)} {...restProps}>
            <Styled.StrengthBar
              $color={
                passwordStrength && passwordStrength === PasswordStrength.STRONG
                  ? COLORS.GREEN
                  : passwordStrength === PasswordStrength.MEDIUM
                  ? COLORS.YELLOW
                  : passwordStrength === PasswordStrength.WEAK && !hideWeakValidation
                  ? COLORS.RED
                  : undefined
              }
              className={'strength-bar'}
            />
            <Styled.StrengthBar
              $color={
                passwordStrength === PasswordStrength.STRONG
                  ? COLORS.GREEN
                  : passwordStrength === PasswordStrength.MEDIUM
                  ? COLORS.YELLOW
                  : undefined
              }
              className={'strength-bar'}
            />
            <Styled.StrengthBar
              $color={passwordStrength === PasswordStrength.STRONG ? COLORS.GREEN : undefined}
              className={'strength-bar'}
            />
          </Styled.StrengthIndicator>
          {!(hideWeakValidation && passwordStrength === PasswordStrength.WEAK) && (
            <>
              <Styled.StrengthDescriptionTitle className="strength-description">
                <Translate as="p" name={`setPassword.${passwordStrength}.title`} />
              </Styled.StrengthDescriptionTitle>
              <Styled.StrengthDescriptionContent className="strength-description">
                <Translate as="p" name={`setPassword.${passwordStrength}.content`} />
              </Styled.StrengthDescriptionContent>
            </>
          )}
        </Styled.StrengthIndicatorContainer>
      )}
    </>
  );
};

export default StrengthIndicator;
