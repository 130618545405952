import React, { FunctionComponent, useEffect } from 'react';
import PageTitle from 'core/components/page-title';
import Translate from 'ui/atoms/translate';
import PageNotFoundElement from './page-not-found';
import useGoTo from 'hooks/use-go-to';
import { reLogin } from 'core/auth/actions';
import { useIsLoggedInSelector } from 'core/auth/hooks';
import { useDispatch } from 'store/hooks';
import { useLocation } from 'react-router';
import { INVESTOR_ROUTES } from 'src/apps/investor/pages/routes.config';

interface PageNotFoundProps {}

const PageNotFound: FunctionComponent<PageNotFoundProps> = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();
  const goToOverview = useGoTo(INVESTOR_ROUTES.overview);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(reLogin());
    }
  }, [isLoggedIn, dispatch, reLogin, pathname]);

  return (
    <>
      <PageTitle>
        <Translate name="pageNotFound.title" />
      </PageTitle>
      <PageNotFoundElement onClick={() => goToOverview()} />
    </>
  );
};

export default PageNotFound;
