import { WalletNetworkInfo } from '../types/wallet';
import { NetworkTypeEnum } from 'api';

export const getWalletAddressForNetwork = (
  networkType: NetworkTypeEnum,
  wallet: WalletNetworkInfo,
): string | undefined | null => {
  switch (networkType) {
    case NetworkTypeEnum.ETHEREUM:
      return wallet.ethInfo?.address;
    case NetworkTypeEnum.STELLAR:
      return wallet.stellarInfo?.accountId;
  }
};

export const getNetworkTypeName = (networkType: NetworkTypeEnum) => {
  switch (networkType) {
    case NetworkTypeEnum.ETHEREUM:
      return 'Ethereum';
    case NetworkTypeEnum.STELLAR:
      return 'Stellar';
    default:
      return '';
  }
};

export const getWalletAddressForNetworkAssertExistence = (
  networkType: NetworkTypeEnum,
  wallet: WalletNetworkInfo,
): string => {
  switch (networkType) {
    case NetworkTypeEnum.ETHEREUM:
      if (!wallet.ethInfo?.address) {
        throw new Error('no wallet address for NetworkType.ETHEREUM');
      }
      return wallet.ethInfo?.address;
    case NetworkTypeEnum.STELLAR:
      if (!wallet.stellarInfo?.accountId) {
        throw new Error('no wallet accountId for NetworkType.STELLAR');
      }
      return wallet.stellarInfo?.accountId;
  }
};
