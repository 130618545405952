import styled from 'styled-components';

export const Pagination = styled.div`
  display: block;
  width: 100%;
`;

export const PaginationContainer = styled.div`
  white-space: nowrap;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LimitContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`;

export const LimitSelectionContainer = styled.div`
  width: 292px;
  height: 100%;
`;

export const PageSelectionContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputContainer = styled.div`
  height: 36px;
  max-width: calc(100% - 32px);
`;

export const ValueContainer = styled.div`
  padding-left: 6px;
  margin-top: 8px;
`;
