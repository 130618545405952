import { Col } from 'ui/atoms/grid';
import styled from 'styled-components';

const questionnaireSplitFormSegment = `
  margin: 18px 0;
`;

const centerText = `
  display: flex;
  align-items: center;
`;

export const QuestionnaireSplitFormDiv = styled.div`
  ${questionnaireSplitFormSegment}
`;

export const QuestionnaireCenteredText = styled(Col)`
  ${centerText}
`;
