import React, { ReactNode, FunctionComponent } from 'react';
import moment from 'moment';
import cx from 'ui/helper/prefixed-class-names';
import Icon from 'ui/atoms/icon';
import Translate from 'ui/atoms/translate';

import { UITransfer } from 'ui/types/transfer';
import Grid from 'ui/atoms/grid';
import * as Styled from './styled';

export interface TransferDetailsCardProps {
  /** Transfer details */
  transfer: UITransfer;

  /** Additional classes. */
  className?: string;
}

const StatusIcon: FunctionComponent<{ success?: boolean }> = ({ success }) => {
  if (success === undefined) {
    return <Icon className="status-icon" color="grayLight" size={2} name="question-dark" />;
  } else if (success) {
    return <Icon className="status-icon" color="primary" size={2} name="check-circle" />;
  } else {
    return <Icon className="status-icon" color="red" size={2} name="close-inverted" />;
  }
};

const TransferDetailsRow: FunctionComponent<{
  success?: boolean;
  title: ReactNode;
  children: ReactNode;
}> = ({ success, title, children }) => {
  return (
    <Styled.StatusRow fluid={true} className="status-row">
      <Grid>
        <Styled.IconCol className="icon-col">
          <StatusIcon success={success} />
        </Styled.IconCol>
        <Styled.TransactionDescription>
          <Styled.Title>{title}</Styled.Title>
          <p>{children}</p>
        </Styled.TransactionDescription>
      </Grid>
    </Styled.StatusRow>
  );
};

const formatTime = (time: Date): string => moment(time).format('YYYY-MM-DD - HH:mm');

const makeFirstRow = (transfer: UITransfer) => (
  <TransferDetailsRow success={true} title={formatTime(transfer.time)}>
    <Translate name={'portfolio.transfer.initiated' + (transfer.fromWallet ? 'ByYou' : '')} />
  </TransferDetailsRow>
);

const makePhaseRow = (phase: string, networkName: string, time?: Date, success?: boolean) => (
  <TransferDetailsRow
    success={time && success}
    title={time ? formatTime(time) : <Translate name={`portfolio.${phase}OutstandingTitle`} />}
  >
    {time ? (
      success ? (
        <Translate name={`portfolio.${phase}Success`} />
      ) : (
        <Translate name={`portfolio.${phase}Fail`} />
      )
    ) : (
      <Translate name={`portfolio.${phase}Outstanding`} args={[networkName]} />
    )}
  </TransferDetailsRow>
);

const TransferDetailsCard: FunctionComponent<TransferDetailsCardProps> = (props) => {
  const { transfer, className, ...restProps } = props;
  const {
    history: {
      tfaTime,
      tfaSuccess,
      approverTime,
      approverSuccess,
      completionTime,
      completionSuccess,
      hideTfa,
      hideApproval,
    },
    networkName,
  } = transfer;

  const showTfa = !(
    hideTfa ||
    // If transaction is already completed, no need to show pending TFA
    (tfaSuccess === undefined && completionSuccess !== undefined) ||
    // If transaction was approver-rejected, no need to show pending TFA
    approverSuccess === false
  );
  const showApproval = !(
    hideApproval ||
    // No need to show if TFA did not even work
    tfaSuccess === false ||
    // If transaction is already completed, no need to show pending approval
    (approverSuccess === undefined && completionSuccess !== undefined)
  );
  const showCompletion = !(tfaSuccess === false || approverSuccess === false);

  return (
    <div className={cx('transfer-details-card', className)} {...restProps}>
      {makeFirstRow(transfer)}
      {showTfa ? makePhaseRow('tfa', networkName, tfaTime, tfaSuccess) : ''}
      {showApproval ? makePhaseRow('approval', networkName, approverTime, approverSuccess) : ''}
      {showCompletion ? makePhaseRow('completion', networkName, completionTime, completionSuccess) : ''}
    </div>
  );
};

export default TransferDetailsCard;
