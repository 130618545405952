import React, { FunctionComponent, useCallback, useState } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import FormGroup from 'ui/molecules/form-group';
import Input from 'ui/atoms/input';
import Button from 'ui/atoms/button';
import Hint from 'ui/atoms/hint';
import { ApiServerError, UnknownServerError } from 'core/api/errors'; //TODO(geforcefan): this is a client import
import { handleError } from 'ui/helper/error-handling';
import useTranslate from 'ui/hooks/use-translate';
import * as Styled from './styled';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';
import ActionButtons from 'ui/molecules/action-buttons';

export interface CreateMobileSafekeepingWalletProps {
  /** Additional classes. */
  className?: string;

  email: string;

  onResendEmail: () => void;

  error?: ApiServerError | UnknownServerError;

  resetWalletOption?: () => void;
}

const CreateMobileSafekeepingWallet: FunctionComponent<CreateMobileSafekeepingWalletProps> = (props) => {
  const { className, email, onResendEmail, error, resetWalletOption, ...restProps } = props;

  const [isMailResent, setIsMailResent] = useState(false);

  const { getRemainingError } = handleError({
    error,
    translate: useTranslate(),
  });

  const errors = getRemainingError();

  const onMailResend = useCallback(() => {
    try {
      onResendEmail();
      setIsMailResent(true);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onSelectWallet = useCallback(() => {
    if (resetWalletOption) resetWalletOption!();
  }, []);

  return (
    <div className={cx('create-mobile-safekeeping-wallet', className)} {...restProps}>
      <Translate as="p" name="createMobileSafekeepingWallet.description" />
      <FormGroup>
        <Input label={<Translate name="common.email" />} value={email} readOnly={true} />
      </FormGroup>
      <div style={{ fontSize: '0.9rem' }}>
        <Translate name="createMobileSafekeepingWallet.openEmail" />
      </div>
      <Spacer y={2} />
      <Button variant="link" onClick={onSelectWallet}>
        <Translate name="createMobileSafekeepingWallet.anotherOption" />
      </Button>
      <Spacer y={8} />
      <ActionButtons>
        <Styled.SubmitButton variant="primary" onClick={onMailResend} disabled={isMailResent} size="large">
          {isMailResent ? (
            <>
              <Translate name="createMobileSafekeepingWallet.mailSent" /> <Icon name="check" />
            </>
          ) : (
            <Translate name="createMobileSafekeepingWallet.resendButton" />
          )}
        </Styled.SubmitButton>
      </ActionButtons>

      {errors && <Hint variant="danger">{errors}</Hint>}
    </div>
  );
};

export default CreateMobileSafekeepingWallet;
