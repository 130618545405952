import { rootStateSelector } from 'store/selectors';
import { RootAppState } from 'store/types';

export function configRootSelector(state: RootAppState) {
  return rootStateSelector(state).config;
}

export function serverConfigSelector(state: RootAppState) {
  const { data, loading, loaded, error } = configRootSelector(state);

  return { config: data || {}, loading, loaded, error };
}
