import styled from 'styled-components';

interface FileUploadContainerProps {
  $dragOver?: boolean;
  $error?: boolean;
}

export const FileUploadContainer = styled.div<FileUploadContainerProps>`
  height: 200px;
  border: 2px dotted
    ${({ theme, $dragOver, $error }) =>
      $dragOver ? theme.colorVariants.primary : $error ? theme.colorVariants.danger : '#CBD1D5'};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $dragOver }) => ($dragOver ? '#E0F1F9' : 'none')};
`;

export const SideTextContent = styled.div`
  color: ${({ theme }) => theme.colors.grayMain};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 78px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.grayLight};
  padding: 0px 1rem;
`;

export const DocumentContent = styled.div`
  display: flex;
  width: 100%;
`;

export const DocumentTextContainer = styled.div`
  width: 100%;
`;

export const DocumentNameRemove = styled.div`
  display: flex;
  justify-content: space-between;
`;
