import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { query } from 'ui/styles/queries';

export interface ContainerProps {
  $spacing: Spacing;
  $padding?: boolean;
  $fluid?: boolean;
}

function getDefaultStyle({ theme }: ThemedStyledProps<ContainerProps, DefaultTheme>) {
  return css`
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
    }
    h1 {
      font-size: ${theme.typography.header.large}rem;

      ${query.phone`
        font-size: ${theme.typography.header.medium}rem;
      `};
    }
    h2 {
      font-size: ${theme.typography.header.medium}rem;
    }
    h3 {
      font-size: ${theme.typography.header.small}rem;

      ${query.phone`
        font-size: ${theme.typography.header.xsmall}rem;
      `};
    }
    h4,
    h5,
    h6 {
      font-size: ${theme.typography.header.tiny}rem;

      ${query.phone`
        font-size: ${theme.typography.sizes.tiny}rem;
      `};
    }
  `;
}

function getMaxWidth({ $fluid }: ThemedStyledProps<ContainerProps, DefaultTheme>) {
  if ($fluid) return null;

  return css`
    ${query.phone`
      max-width: 540px;
    `}
    ${query.tablet`
      max-width: 720px;
    `}
    ${query.desktops`
      max-width: 1140px;
    `}
  `;
}

function getSpacing({ theme, $spacing }: ThemedStyledProps<ContainerProps, DefaultTheme>) {
  const spacing = theme.spacing[$spacing];

  return css<ContainerProps>`
    &:not(:last-child) {
      margin-bottom: ${spacing}rem;
    }
  `;
}

function getPadding({ $padding }: ThemedStyledProps<ContainerProps, DefaultTheme>) {
  if ($padding) return null;

  return css`
    padding-left: 0;
    padding-right: 0;
  `;
}

export const Container = styled.div<ContainerProps>`
  ${getDefaultStyle};
  ${getMaxWidth};
  ${getSpacing};
  ${getPadding};
`;
