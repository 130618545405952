import React, { ReactNode, useEffect } from 'react';
import Portal from 'ui/atoms/portal';
import { ModalProvider } from 'ui/molecules/modal/provider';
import * as Styled from './styled';
import type { ModalSizes, ModalPositions } from './styled';
import type { Colors } from 'ui/styles/utils/colors';
import Padding from 'ui/types/padding';

const ESC_KEY = 'Escape';

export interface ModalProps {
  children?: ReactNode | undefined;
  /**
   *   disableEscape
   */
  disableEscape?: boolean;
  /**
   *   bgColor
   */
  bgColor?: Colors;
  /**
   *   disableClose
   */
  disableClose?: boolean;
  /**
   *   disableClickOutside
   */
  disableClickOutside?: boolean;
  /**
   *   size
   */
  size?: ModalSizes;
  onClose?: () => void;
  /**
   *   maxWidth escape
   */
  maxWidth?: string;
  /**
   *   maxHeight
   */
  maxHeight?: string;
  /**
   *   padding
   */
  padding?: Padding;
  /**
   *   border radius
   */
  hasBorderRadius?: boolean;
  /**
   *   position
   */
  position?: ModalPositions;
  /**
   *   canAnimate
   */
  canAnimate?: boolean;
  /**
   *   custom class
   */
  className?: string;
}

export const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  maxWidth,
  maxHeight,
  bgColor = 'white',
  size = 'regular',
  hasBorderRadius = true,
  padding,
  position = 'top',
  disableClose = false,
  disableEscape = false,
  canAnimate = false,
  disableClickOutside = false,
  onClose,
  className,
}) => {
  useEffect(() => {
    if (!disableEscape) addKeydownListener();
    else removeKeydownListener();

    return () => removeKeydownListener();
  }, [disableEscape]);

  const addKeydownListener = () => {
    document.addEventListener('keydown', onKeyDown, false);
  };

  const removeKeydownListener = () => {
    document.removeEventListener('keydown', onKeyDown, false);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code === ESC_KEY) {
      handleClose();
    }
  };

  const handleClickOutside = () => {
    if (!disableClickOutside) {
      handleClose();
    }
  };

  const handleClose = () => {
    if (!disableClose && onClose) {
      onClose();
    }
  };

  return (
    <Portal>
      <Styled.ModalBase className={className} onClick={(e) => e.stopPropagation()} data-qa="modal">
        <Styled.ModalBackground $canAnimate={canAnimate} />
        <Styled.ModalPosition $position={position} $canAnimate={canAnimate} onClick={handleClickOutside}>
          <Styled.ModalBody
            $maxWidth={maxWidth}
            $maxHeight={maxHeight}
            $size={size}
            $bgColor={bgColor}
            $hasBorderRadius={hasBorderRadius}
            $padding={padding}
            className={className}
            onClick={(e) => e.stopPropagation()}
          >
            <ModalProvider disableClose={disableClose} onClose={handleClose} bgColor={bgColor}>
              {children}
            </ModalProvider>
          </Styled.ModalBody>
        </Styled.ModalPosition>
      </Styled.ModalBase>
    </Portal>
  );
};

Modal.displayName = 'Modal';

export default Modal;
