import React, { FunctionComponent, ReactNode } from 'react';
import { variantToNotificationColor, variantToIconName, variantToIconColor } from 'ui/helper/variant';
import Padding from 'ui/types/padding';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import Tag from '../tag';
export interface StatusTagProps {
  children?: ReactNode;

  /** Additional classes. */
  className?: string;

  /**
   * A status tag may be one of a variety of visual variants.
   *
   * @expandType true
   */
  variant?: ColorVariants;

  truncate?: boolean;

  padded?: Padding;
}

export const StatusTag: FunctionComponent<StatusTagProps> = (props) => {
  const { className, children, variant = 'primary', ...restProps } = props;

  return (
    <>
      <Tag
        iconName={variantToIconName(variant)}
        color={variantToNotificationColor(variant)}
        iconColor={variantToIconColor(variant)}
        compact={true}
        className={className}
        {...restProps}
      >
        {children}
      </Tag>
    </>
  );
};

export default StatusTag;
