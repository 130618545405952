import { Col } from 'ui/atoms/grid';
import styled from 'styled-components';
import Input from 'ui/atoms/input';

const questionnaireSplitFormSegment = `
  margin: 18px 0;
`;

const centerText = `
  display: flex;
  align-items: center;
`;

export const QuestionnaireSplitFormDiv = styled.div`
  ${questionnaireSplitFormSegment}
`;

export const QuestionnaireCenteredText = styled(Col)`
  ${centerText}
`;

export const ShortSummaryInput = styled(Input)`
  width: fit-content;
  flex-grow: 0;

  input {
    width: 3.8rem;
    text-align: center;
  }
`;
