import React, { FunctionComponent } from 'react';
import { camelCase } from 'change-case';
import Link from 'core/routing/link';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import { assertUnreachable } from 'ui/helper/unreachable';
import { InvestorRegistrationStatusEnum } from 'subapps/investment/pages/register/helpers/registration-status';

const hasOpenRegistrationProcessAction = (status: InvestorRegistrationStatusEnum): boolean => {
  switch (status) {
    case InvestorRegistrationStatusEnum.AccountSetup:
    case InvestorRegistrationStatusEnum.InvestmentAmountMissing:
    case InvestorRegistrationStatusEnum.QuestionnaireMissing:
    case InvestorRegistrationStatusEnum.BenefitingPersonsMissing:
    case InvestorRegistrationStatusEnum.IdentificationMissing:
    case InvestorRegistrationStatusEnum.IdentificationRejected:
    case InvestorRegistrationStatusEnum.AdditionalInformationMissing:
    case InvestorRegistrationStatusEnum.WalletSetupMissing:
    case InvestorRegistrationStatusEnum.WalletSetupPending:
      return true;
    case InvestorRegistrationStatusEnum.IdentificationPending:
    case InvestorRegistrationStatusEnum.IdentificationFailed:
    case InvestorRegistrationStatusEnum.Finished:
      return false;
  }
  return assertUnreachable(status);
};

export interface InvestorStatusActionButtonProps {
  status: InvestorRegistrationStatusEnum;
}

const ContinueRegistrationButton: FunctionComponent<InvestorStatusActionButtonProps> = ({ status }) => {
  return (
    <ActionButtons>
      {hasOpenRegistrationProcessAction(status) && (
        <Link variant="primary" to="/register">
          <Translate name={`registrationStatus.${camelCase(status)}.button`} />
        </Link>
      )}
    </ActionButtons>
  );
};

export default ContinueRegistrationButton;
