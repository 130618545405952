import React, { FunctionComponent } from 'react';
import { compact } from 'lodash';
import useTranslate from 'ui/hooks/use-translate';
import { camelCase } from 'change-case';
import { SalutationEnum } from 'api';
import * as Styled from './styled';

export interface PersonNameProps {
  /** Additional classes. */
  className?: string;

  withSalutation?: boolean;

  /** Person data */
  person: {
    companyName?: string;
    forename?: string;
    surname?: string;
    salutation?: string;
  };
}

export const PersonName: FunctionComponent<PersonNameProps> = (props) => {
  const { className, person, withSalutation, ...restProps } = props;

  const translate = useTranslate();

  const naturalPersonName = compact([
    withSalutation &&
      person.salutation &&
      person.salutation !== SalutationEnum.D &&
      translate(`personalInformation.salutations.${camelCase(person.salutation)}`),
    person.forename,
    person.surname,
  ]).join(' ');

  const legalPersonName = person.companyName;

  const fullName =
    naturalPersonName && legalPersonName
      ? `${naturalPersonName} (${legalPersonName})`
      : naturalPersonName || legalPersonName;

  return (
    <Styled.PersonName lang="de" {...restProps}>
      {fullName}
    </Styled.PersonName>
  );
};

export default PersonName;
