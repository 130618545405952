import React, { FunctionComponent, useRef, useState } from 'react';
// import useClickOutside from "hooks/use-click-outside";
import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import { useFilterContext } from 'ui/molecules/filtering/hooks';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import * as Styled from './styled';

export const FilterPopup: FunctionComponent = ({ children }) => {
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);
  const { resetFilters, filterNumber, entries, setApplyFilters } = useFilterContext();
  const [showFilters, setShowFilters] = useState(false);

  const isPhone = useIsMedia(DEVICES.phone);

  const reset = () => {
    setApplyFilters(true);
    resetFilters();
    setShowFilters(false);
  };

  const applyFilters = () => {
    setApplyFilters(true);
    setShowFilters(false);
  };

  // useClickOutside([pickerRef, buttonRef], () => setShowFilters(false));

  return (
    <Styled.Filters>
      <Styled.FilterBar>
        <Button
          onClick={() => setShowFilters(!showFilters)}
          active={showFilters}
          icon="filter"
          iconSize="regular"
          variant="secondary"
          buttonRef={buttonRef}
        >
          {!isPhone && (
            <>
              <Translate name="filters.buttons.filter" />
              {filterNumber && (
                <Styled.PopupTag size="small" padded="none" compact>
                  {filterNumber}
                </Styled.PopupTag>
              )}
            </>
          )}
        </Button>
      </Styled.FilterBar>
      {showFilters && (
        <Styled.FiltersPopover segmentRef={pickerRef} raised={true}>
          {children}
          <ActionButtons>
            <Button onClick={reset} variant="link">
              <Translate name="filters.buttons.resetFilter" />
            </Button>
            <Button variant="primary" onClick={applyFilters}>
              <Translate name={'filters.buttons.showResults'} args={[entries]} />
            </Button>
          </ActionButtons>
        </Styled.FiltersPopover>
      )}
    </Styled.Filters>
  );
};

export default FilterPopup;
