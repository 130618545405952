import { Product, Wallet } from 'apps/issuer/pages/investor-details/create-redemption';

export const getUniqueProductValues = (arr: Product[]) => {
  const result = [];
  const map = new Map();
  for (const item of arr) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      result.push(item);
    }
  }
  return result;
};

const getUniqueWalletValues = (arr: (Wallet | undefined)[]) => {
  const result = [];
  const map = new Map();
  for (const item of arr) {
    if (!map.has(item?.id) && item?.id.length) {
      map.set(item.id, true);
      result.push(item);
    }
  }
  return result;
};

export const getWalletsByProductId = (productId: string, products: Product[]) => {
  const prods = products.filter((prod) => prod.id === productId && !!prod.wallet?.id?.length);
  if (prods.length) {
    const wallets = prods.map((prod) => prod.wallet);
    if (wallets.length) {
      return getUniqueWalletValues(wallets);
    }
  }
  return [];
};
