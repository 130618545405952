import React, { FunctionComponent, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import Button from 'ui/atoms/button';
import Section from 'ui/atoms/section';
import Hint from 'ui/atoms/hint';
import useApiCall from 'hooks/use-api-call';
import { UsersApi } from 'api';

export interface ConfirmEmailProps {
  receiverEmail: string;

  /** On wrong email address callback */
  onWrongEmailAddress?: () => void;
}

const ConfirmEmail: FunctionComponent<ConfirmEmailProps> = (props) => {
  const { receiverEmail, onWrongEmailAddress = () => {} } = props;

  const [emailSuccessfullyResend, setEmailSuccessfullyResend] = useState(false);

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  const resendEmail = () => {
    withApi(async () => {
      await usersApi.usersEmailResendCreate();
      setEmailSuccessfullyResend(true);
    });
  };

  return (
    <>
      <Section>
        <p>
          <strong>
            <Translate name="investmentConfirmEmail.subtitle" />
          </strong>
        </p>
        <p>
          <Translate name="investmentConfirmEmail.body1" />
        </p>
        <p>
          <Translate name="investmentConfirmEmail.body2" />
        </p>
        <p>
          <Translate name="investmentConfirmEmail.body3" />
        </p>
        <Segment textAlign="right" basic padded="none" margin="none">
          <small>
            <Button variant="link" onClick={onWrongEmailAddress}>
              <Translate name="investmentConfirmEmail.isEmailAddressWrong" />
            </Button>
          </small>
        </Segment>
        <Segment padded="tiny" inverted={true} margin="none">
          <span>{receiverEmail}</span>
        </Segment>
      </Section>

      <span>
        <Translate name="investmentConfirmEmail.didNotGetEmail" />
      </span>
      <Button variant="link" onClick={resendEmail}>
        <Translate name="investmentConfirmEmail.resendEmail" />
      </Button>
      {emailSuccessfullyResend && (
        <Hint variant="success">
          <Translate name="investmentConfirmEmail.emailSuccessfullyResend" />
        </Hint>
      )}
    </>
  );
};

export default ConfirmEmail;
