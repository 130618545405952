import { StepEnum } from 'api/models';

export enum WizardSidebarStep {
  ACCOUNT_DATA = 'accountData',
  INVESTMENT_DATA = 'investmentData',
  QUESTIONNAIRE = 'questionnaire',
  COMMITMENT = 'commitment',
  KYC = 'kyc',
  PAYMENT = 'payment',
  DEPOT = 'depotSelection',
  ADDITIONAL_INFORMATION = 'additionalInformation',
}

export interface sidebarMappingInterface {
  sidebarStep?: WizardSidebarStep;
  wizardSteps?: StepEnum[];
}

const sidebarMapping = [
  {
    sidebarStep: WizardSidebarStep.ACCOUNT_DATA,
    wizardSteps: [StepEnum.ACCOUNT_SETUP, StepEnum.UNCONFIRMED],
  },
  {
    sidebarStep: WizardSidebarStep.INVESTMENT_DATA,
    wizardSteps: [StepEnum.INVESTMENT_AMOUNT],
  },
  {
    sidebarStep: WizardSidebarStep.QUESTIONNAIRE,
    wizardSteps: [StepEnum.QUESTIONNAIRE],
  },
  {
    sidebarStep: WizardSidebarStep.COMMITMENT,
    wizardSteps: [StepEnum.VIB, StepEnum.OFFER_CREATION],
  },
  {
    sidebarStep: WizardSidebarStep.KYC,
    wizardSteps: [
      StepEnum.BENEFICIARY,
      StepEnum.IDENTIFICATION,
      StepEnum.RESUME_IDENTIFICATION,
      StepEnum.IDENTIFICATION_REJECTED,
      StepEnum.IDENTIFICATION_FAILED,
    ],
  },
  {
    sidebarStep: WizardSidebarStep.PAYMENT,
    wizardSteps: [StepEnum.PAYMENT, StepEnum.WAITING_ACCEPTANCE, StepEnum.WAITING_KYC],
  },
  {
    sidebarStep: WizardSidebarStep.DEPOT,
    wizardSteps: [
      StepEnum.SELECT_WALLET,
      StepEnum.SETUP_MOBILE_WALLET,
      StepEnum.PREPARE_ASSET,
      StepEnum.CONFIRM_WALLET,
    ],
  },
  {
    sidebarStep: WizardSidebarStep.ADDITIONAL_INFORMATION,
    wizardSteps: [StepEnum.ADDITIONAL_INFORMATION],
  },
];

export const transferSidebarMapping = [
  {
    sidebarStep: WizardSidebarStep.ACCOUNT_DATA,
    wizardSteps: [StepEnum.ACCOUNT_SETUP, StepEnum.UNCONFIRMED],
  },
  {
    sidebarStep: WizardSidebarStep.KYC,
    wizardSteps: [
      StepEnum.BENEFICIARY,
      StepEnum.IDENTIFICATION,
      StepEnum.RESUME_IDENTIFICATION,
      StepEnum.IDENTIFICATION_REJECTED,
      StepEnum.WAITING_KYC,
    ],
  },
  {
    sidebarStep: WizardSidebarStep.DEPOT,
    wizardSteps: [
      StepEnum.SELECT_WALLET,
      StepEnum.SETUP_MOBILE_WALLET,
      StepEnum.PREPARE_ASSET,
      StepEnum.CONFIRM_WALLET,
    ],
  },
];

export default sidebarMapping;
