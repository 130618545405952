import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import Button from 'ui/atoms/button';
import cx from 'ui/helper/prefixed-class-names';
import type { Colors } from 'ui/styles/utils/colors';

export interface PopupProps {
  /** Additional classes. */
  header: React.ReactElement;

  open: boolean;

  setOpen: (v: boolean) => void;

  /** Color of the popup. */
  color?: Colors;

  /** Additional classes. */
  className?: string;
}

export const Popup: FunctionComponent<PopupProps> = ({
  children,
  header,
  open,
  setOpen,
  color = 'secondary',
  className,
}) => {
  if (!open) return null;

  return (
    <Styled.Popup $color={color} className={cx('popup', className)}>
      <Styled.Header>
        {header}
        <Button
          icon="close-thin"
          iconColor="white"
          variant="link"
          inline
          className="popup-button"
          onClick={() => setOpen(false)}
        />
      </Styled.Header>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Popup>
  );
};

export default Popup;
