import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { AdminApi, AdminInvestor } from 'api';
import useApiCall from 'hooks/use-api-call';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';

export const useReactivationModal = (investor: AdminInvestor, onModalClose: () => void) => {
  const { withApi, makeAuthenticatedApi } = useApiCall();
  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const translate = useTranslateWithStringArgs();

  const [isActivationSuccessful, setIsActivationSuccessful] = useState(false);

  const formatDate = (date: Date) => moment(date).format(`${translate('date.dateFormat')}`);

  const onConfirm = useCallback(async () => {
    reactivateAccount();
    setIsActivationSuccessful(true);
  }, [onModalClose]);

  const onClose = useCallback(() => onModalClose(), [onModalClose]);

  const reactivateAccount = useCallback(() => {
    withApi(async () => {
      try {
        await adminApi.adminInvestorsReactivateCreate({ id: investor.id });
      } catch (e) {
        console.error(e);
      }
    });
  }, [withApi, investor.id]);

  return {
    formatDate,
    onClose,
    onConfirm,
    isActivationSuccessful,
  };
};
