import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import * as Styled from './styled';

export interface FormToggleOptionProps {
  options?: {
    name: string;
    value: string;
  }[];
  name: string;
  value: string;
}

const FormToggleOption: FunctionComponent<FormToggleOptionProps> = ({ options, name, value }) => {
  const toggleOptions = options || [
    { name: 'true', value: 'yes' },
    { name: 'false', value: 'no' },
  ];

  return (
    <Styled.QuestionnaireFormRow key={name}>
      <Styled.QuestionnaireDesktopCol>
        <Translate as="h4" name={`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(name)}`} />
      </Styled.QuestionnaireDesktopCol>
      <Styled.QuestionnaireDesktopColEnd>
        <QuestionnaireForm.Group name={value}>
          <QuestionnaireForm.GroupToggle>
            {toggleOptions.map((option) => (
              <QuestionnaireForm.Radio value={option.value} key={option.value} spacing="small">
                <Translate name={`commonOptions.${option.name}`} />
              </QuestionnaireForm.Radio>
            ))}
          </QuestionnaireForm.GroupToggle>
        </QuestionnaireForm.Group>
      </Styled.QuestionnaireDesktopColEnd>
    </Styled.QuestionnaireFormRow>
  );
};

export default FormToggleOption;
