import React, { FunctionComponent, useCallback, useState } from 'react';
import FileUpload from 'ui/atoms/file-upload';

export interface FileToUpload {
  file: File;
  documentType?: string;
}

export const MultipleFileUpload: FunctionComponent<{
  onChange: (arg: FileToUpload[]) => void;
  onFocus?: (arg: any) => void;
  required?: boolean;
  showRequiredError?: boolean;
  showValidatedForm?: boolean;
  documentTypes?: Array<any>;
  valid?: boolean;
  value?: any;
}> = ({ onChange = () => {}, showRequiredError, showValidatedForm, documentTypes = [] }) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileToUpload[]>([]);

  const handleFileInput = useCallback(
    (event: any) => {
      event.preventDefault();

      const files = event.target?.files?.length ? event.target?.files : event.dataTransfer.files;

      for (let i = 0; i < files.length; i++) {
        uploadedFiles.push({ file: files[i] });
      }

      if (files[0]) {
        setUploadedFiles(uploadedFiles);
        onChange(uploadedFiles);
      }
    },
    [uploadedFiles],
  );

  const onSelectDocumentType = useCallback(
    (documentType: string, fileName: string) => {
      const fileToUpload = uploadedFiles.find((document) => document.file.name === fileName);
      if (!fileToUpload) return;
      fileToUpload.documentType = documentType;

      setUploadedFiles([...uploadedFiles]);
      onChange([...uploadedFiles]);
    },
    [uploadedFiles],
  );

  const onRemoveDocument = useCallback(
    (fileName: string) => {
      const filesToUpload = uploadedFiles.filter((document) => document.file.name !== fileName);

      setUploadedFiles([...filesToUpload]);
      onChange([...filesToUpload]);
    },
    [uploadedFiles],
  );

  return (
    <FileUpload
      handleFileInput={handleFileInput}
      uploadedFiles={uploadedFiles}
      onSelectDocumentType={onSelectDocumentType}
      onRemoveDocument={onRemoveDocument}
      documentTypes={documentTypes}
      showRequiredError={showRequiredError}
      showValidatedForm={showValidatedForm}
    />
  );
};

export default MultipleFileUpload;
