export interface ImagesTheme {
  border: any;
  paddingYy: any;
  paddingXx: any;
  radius: any;
  size: {
    small: number;
    medium: number;
    large: number;
    xlarge: number;
  };
}

export default {
  border: '0.1em solid rgba(0, 0, 0, 0.1)',
  paddingYy: 0.5,
  paddingXx: 0.5,
  radius: 0.2,
  size: {
    small: 80,
    medium: 100,
    large: 160,
    xlarge: 228,
  },
} as ImagesTheme;
