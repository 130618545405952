import React, { FunctionComponent, Fragment, useContext, useMemo } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import get from 'lodash/get';
import BigNumber from 'bignumber.js';

import getElementType, { As } from 'ui/helper/get-element-type';

import considerGrammaticalNumber from 'ui/helper/consider-grammatical-number';
import { makeBigNumber } from 'ui/helper/big-number';
import { I18NContext } from 'ui/i18n/provider';

type NumberTypes = 'currency' | 'percent' | 'decimal' | 'token' | 'shares' | 'pages' | 'entries';

export interface NumberProps extends As {
  /** Number to be formatted. */
  // TODO(mara-cashlink): remove number | string from types to use Number component with Bigs only
  children: number | string | BigNumber;

  type?: NumberTypes;

  /** Additional classes. */
  className?: string;

  currency?: string;

  currencyDisplay?: string;

  minimumIntegerDigits?: number;

  minimumFractionDigits?: number;

  maximumFractionDigits?: number;

  minimumSignificantDigits?: number;

  maximumSignificantDigits?: number;

  truncateDecimals?: number;

  prefix?: string;

  suffix?: string;

  showSign?: boolean;
}

export interface NumberFormatPart {
  type: string;
  value: string;
}

export const Number: FunctionComponent<NumberProps> = (props) => {
  const {
    className,
    children,
    type = 'decimal',
    currency,
    currencyDisplay,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    truncateDecimals,
    showSign,
    ...restProps
  } = props;

  const { activeLocale, translate } = useContext(I18NContext);

  const bigNum: BigNumber = children instanceof BigNumber ? children : makeBigNumber(children);

  const isNumberPositive = bigNum.gt(0);

  const ElementType = getElementType(Number, props, className ? 'span' : Fragment);

  const types = useMemo(
    () => ({
      token: {
        suffix: translate('number.token.suffix'),
        prefix: undefined,
        style: 'decimal',
      },
      shares: {
        suffix: translate('number.shares.suffix'),
        prefix: undefined,
        style: 'decimal',
      },
      pages: {
        suffix: translate('number.pages.suffix'),
        prefix: undefined,
        style: 'decimal',
      },
      entries: {
        suffix: translate('number.entries.suffix'),
        prefix: undefined,
        style: 'decimal',
      },
    }),
    [translate],
  );

  const { suffix, prefix, style } = get(types, type) || {
    suffix: undefined,
    prefix: undefined,
    style: type,
  };

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(activeLocale, {
        style,
        currency,
        currencyDisplay,
        minimumIntegerDigits,
        minimumFractionDigits,
        maximumFractionDigits,
        minimumSignificantDigits,
        maximumSignificantDigits,
      }),
    [
      activeLocale,
      style,
      currency,
      currencyDisplay,
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
    ],
  );

  const trauncateFractionAndFormat = (parts: NumberFormatPart[], digits: number) => {
    return parts
      .map(({ type, value }) => {
        if (type !== 'fraction' || !value || value.length < digits) {
          return value;
        }

        let retVal = '';
        for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
          if (value[idx] !== '0') {
            counter++;
          }
          retVal += value[idx];
        }
        return retVal;
      })
      .reduce((string, part) => string + part);
  };

  const formattedNumber = useMemo(() => {
    return (
      (showSign && isNumberPositive ? '+' : '') +
      (prefix ? ` ${considerGrammaticalNumber(bigNum, prefix)}` : '') +
      (truncateDecimals
        ? //  TODO(mara-cashlink): replace parseFloat of Big
          // @ts-ignore
          trauncateFractionAndFormat(formatter.formatToParts(parseFloat(bigNum)), truncateDecimals)
        : // @ts-ignore
          formatter.format(parseFloat(bigNum))) +
      (suffix ? ` ${considerGrammaticalNumber(bigNum, suffix as string)}` : '')
    );
  }, [prefix, suffix, formatter, bigNum]);

  if (ElementType === Fragment) return <>{formattedNumber}</>;

  return (
    <ElementType className={cx(className, { style })} {...restProps}>
      {formattedNumber}
    </ElementType>
  );
};

export default Number;
