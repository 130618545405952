import React, { FunctionComponent, useState, useCallback } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import Checkbox from 'src/ui/atoms/checkbox';
import Spacer from 'src/ui/atoms/spacer';

export interface ReactivationProps {
  onConfirm: () => void;
  onModalClose: () => void;
}

const ConfirmationNetWorthModal: FunctionComponent<ReactivationProps> = (props) => {
  const { onConfirm, onModalClose } = props;

  const [isChecked, setChecked] = useState(false);

  const onClose = useCallback(() => onModalClose(), [onModalClose]);

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <Translate name="netWorthConfirmationModal.header" />
      </ModalHeader>
      <ModalContent>
        <Translate name="netWorthConfirmationModal.content" />
        <Spacer y={6} />
        <Checkbox checked={isChecked} onChange={() => setChecked(!isChecked)}>
          <Translate name="netWorthConfirmationModal.confirm" />
        </Checkbox>
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'confirm',
              content: <Translate name="common.confirm" />,
              variant: 'primary',
              type: 'submit',
              size: 'large',
              disabled: !isChecked,
              onClick: onConfirm,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationNetWorthModal;
