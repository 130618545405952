import Button from 'src/ui/atoms/button';
import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export const NumberOfInvestments = styled.p`
  text-transform: lowercase;
`;

export const RedirectButton = styled(Button)`
  color: #303539;
  border-color: #303539;

  ${query.desktops`
    margin-left: 0.5rem;
  `};

  &:hover {
    & * {
      color: ${({ theme }) => theme.colors.grayDark};
    }
    border-color: ${({ theme }) => theme.colors.grayDark};
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.black};
    box-shadow: none;
  }
`;
