import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentUserSelector } from 'core/auth/hooks';
import SignDocumentsModal from 'apps/investor/shared/sign-documents-modal';
import { InvestorsApi, InvestorTask } from 'api';
import useApiCall from 'hooks/use-api-call';

const InvestorTasks: FunctionComponent = () => {
  const [signDocumentsModalOpen, setSignDocumentsModalOpen] = useState(false);
  const [tasks, setTasks] = useState<InvestorTask[]>([]);

  const { currentUser } = useCurrentUserSelector();
  const investorId = currentUser?.investor;

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  const fetchTasks = useCallback(() => {
    withApi(async () => {
      if (!investorId) return;
      const result = await investorsApi.investorsTasksList({ id: investorId });
      setTasks(result);
      if (result.length) {
        setSignDocumentsModalOpen(true);
      }
    });
  }, [investorId, withApi, investorsApi]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    if (!tasks.length && !signDocumentsModalOpen) {
      setSignDocumentsModalOpen(false);
    }
  }, [JSON.stringify(tasks)]);

  const onModalClose = () => {
    setSignDocumentsModalOpen(false);
    fetchTasks();
  };

  if (!currentUser?.investor || !tasks.length) return null;

  return (
    <>
      {signDocumentsModalOpen && (
        <SignDocumentsModal onModalClose={onModalClose} taskObject={tasks[0]} investorId={currentUser?.investor} />
      )}
    </>
  );
};

export default InvestorTasks;
