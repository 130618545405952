import React, { FunctionComponent } from 'react';
import { As } from 'ui/helper/get-element-type';
import * as Styled from './styled';

export interface ImageProps extends As {
  /** Additional classes. */
  className?: string;

  src?: string;

  alt?: string;

  /** Formatted to appear bordered. */
  bordered?: boolean;

  /** Formatted to appear circular. */
  circular?: boolean;

  /** Size of the image. */
  size?: Styled.ImageSize;
}

export const Image: FunctionComponent<ImageProps> = (props) => {
  const { className, src, alt, circular, bordered, children, size = 'large', ...restProps } = props;

  return <Styled.Image $bordered={bordered} $circular={circular} $size={size} src={src} alt={alt} {...restProps} />;
};

export default Image;
