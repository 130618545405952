import React, { FunctionComponent } from 'react';
import {
  QuestionnaireLegacy,
  QuestionnaireSegment,
  QuestionnaireSegmentsAnswers,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Section from 'ui/atoms/section';
import FormSegmentHeader from 'libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment/form-segment-header';
import QuestionnaireFormSegment from 'libraries/wizard/components/questionnaire/questionnaire-form/questionnaire-form-segment';
import { GroupScore } from 'api';
import Hint from 'ui/atoms/hint';
import Translate from 'ui/atoms/translate';
import useTranslate from 'ui/hooks/use-translate';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';

export interface QuestionnaireSummaryQuestionsProps {
  pageSegments: QuestionnaireSegment[];
  questionsSegments: QuestionnaireSegmentsAnswers | QuestionnaireLegacy;
  noAnswers?: boolean;
  scoreList?: GroupScore[];
}

const QuestionnaireSummaryQuestions: FunctionComponent<QuestionnaireSummaryQuestionsProps> = (props) => {
  const { pageSegments, questionsSegments, noAnswers, scoreList } = props;
  const translate = useTranslate();

  const getGroupScoreHintMessage = (segmentValues: string[]) => {
    if (!scoreList?.some((groupScore) => groupScore.groupId === segmentValues[0])) return null;
    const groupScore = !segmentValues.some((segment) =>
      scoreList?.some((scoreGroup) => scoreGroup.groupId === segment && !scoreGroup.passed),
    );
    if (groupScore !== undefined) {
      const segmentName = translate(`questionnaire.parts.${snakeToCamelCaseWithNumbers(segmentValues[0])}.title`);
      return (
        <Hint variant={groupScore ? 'success' : 'warning'}>
          <Translate
            name={`questionnaireSummary.hints.${groupScore ? 'questionnaireSuitable' : 'questionnaireUnsuitable'}`}
            args={[segmentName]}
          />
        </Hint>
      );
    }
  };

  if (noAnswers) {
    return (
      <Hint variant="info">
        <Translate name={'questionnaireSummary.hints.noAnswersQuestionnaire'} />
      </Hint>
    );
  }

  return (
    <>
      {pageSegments.map(({ index, values: segmentValues, title }) => {
        return (
          <Section spacing="large" key={index}>
            <FormSegmentHeader segmentIndex={index} segmentId={title} />
            {segmentValues.map((questionSegment) => {
              // TS fix for legacy questionnaire
              if (Array.isArray(questionsSegments)) {
                return null;
              }
              return (
                <QuestionnaireFormSegment
                  key={questionSegment}
                  segmentIndex={index}
                  segmentId={questionSegment}
                  questionsSegment={questionsSegments[questionSegment]}
                  isSummary={true}
                  isEcsp={true}
                />
              );
            })}
            {getGroupScoreHintMessage(segmentValues)}
          </Section>
        );
      })}
    </>
  );
};

export default QuestionnaireSummaryQuestions;
