import React, { useEffect, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestmentsApi, InvitationsApi } from 'api/apis';
import { Invitation, AdminInvestmentDetails } from 'api/models';
import Translate from 'ui/atoms/translate';
import LoadingRing from 'ui/atoms/loading-ring';
import WideContent from 'core/layout/content/wide-content';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import BoxedInvestorOpenInvestment, { InvestmentInvitation } from './open-investment';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import InvestorEmptyView from 'core/components/empty-views/investor';
import { InvestmentsListStatusEnum } from 'api';
import CancelInvestment from './cancel-investment';

const ContinueInvestmentsPage = () => {
  const [openInvestments, setOpenInvestments] = useState<AdminInvestmentDetails[]>();
  const [openInvitations, setOpenInvitations] = useState<Invitation[]>();

  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(true);

  const investmentsApi: InvestmentsApi = useMemo(() => makeAuthenticatedApi(InvestmentsApi), [makeAuthenticatedApi]);
  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);

  const [investmentToCancel, setInvestmentToCancel] = useState<AdminInvestmentDetails | Invitation>();

  useEffect(() => {
    withApi(async () => {
      const openInvestments = await investmentsApi.investmentsList({
        status: InvestmentsListStatusEnum.OPEN,
        limit: 100,
      });
      setOpenInvestments(openInvestments.results);

      const openInvitations = await invitationsApi.invitationsList({
        status: InvestmentsListStatusEnum.OPEN,
        limit: 100,
      });
      setOpenInvitations(openInvitations.results);
    });
  }, [withApi, investmentsApi, invitationsApi]);

  if (loading) {
    return <LoadingRing />;
  }

  const openConfirmCancelModal = async (investment: AdminInvestmentDetails | Invitation) => {
    setConfirmCancelOpen(true);
    setInvestmentToCancel(investment);
  };

  const isInvitationInvestment = investmentToCancel ? 'invitationType' in investmentToCancel : false;

  const setInvestmentsAfterCancel = (investmentId: string) => {
    if (isInvitationInvestment) {
      setOpenInvitations(
        (openInvitations || []).filter((openInvitation) => {
          return openInvitation.id !== investmentId;
        }),
      );
    } else {
      setOpenInvestments(
        (openInvestments || []).filter((openInvestment) => {
          return openInvestment.id !== investmentId;
        }),
      );
    }
  };

  return (
    <WideContent title={<Translate name="dashboardInvestorContinueInvestments.title" />}>
      {openInvitations &&
        openInvitations.map((invitation, index) => (
          <BoxedInvestorOpenInvestment
            investment={invitation as InvestmentInvitation}
            key={index}
            onCancelInvestment={() => openConfirmCancelModal(invitation)}
          />
        ))}
      {openInvestments &&
        openInvestments.map((investment, index) => (
          <BoxedInvestorOpenInvestment
            investment={investment}
            key={index}
            onCancelInvestment={() => openConfirmCancelModal(investment)}
          />
        ))}
      {confirmCancelOpen && investmentToCancel && (
        <CancelInvestment
          investmentId={investmentToCancel.id}
          isInvitation={isInvitationInvestment}
          setConfirmCancelOpen={setConfirmCancelOpen}
          setInvestmentsAfterCancel={setInvestmentsAfterCancel}
        />
      )}
      {openInvitations && openInvitations.length === 0 && openInvestments && openInvestments.length === 0 && (
        <InvestorEmptyView type={EmptyViewType.INVESTOR_OPEN_INVESTMENTS} />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(ContinueInvestmentsPage, AppType.INVESTOR);
