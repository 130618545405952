import Link from 'src/ui/atoms/link';
import styled from 'styled-components';

export const PageNotFound = styled.div`
  max-width: 500px;
  margin-left: 40px;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const SubHeadline = styled.h4`
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export const ContactLink = styled(Link)`
  padding: 0.375rem 0.75rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: 1.5;
`;
