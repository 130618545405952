import { resolveColor } from 'src/ui/styles/utils/colors';
import styled from 'styled-components';
import { query } from 'ui/styles/queries';

export interface StyledSidebarProps {
  $wide?: boolean;
}

export const Sidebar = styled.div<StyledSidebarProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => resolveColor(theme, 'background')};
  flex: 0 0 ${({ theme }) => theme.sidebar.small}px;

  ${query.desktops<StyledSidebarProps>`
    ${({ theme, $wide }) => ($wide ? `flex: 0 0 ${theme.sidebar.width}px;` : `flex: 0 0 ${theme.sidebar.small}px`)};
  `}

  ${query.desktops<StyledSidebarProps>`
    ${({ theme, $wide }) => ($wide ? `flex: 0 0 ${theme.sidebar.width}px;` : `flex: 0 0 ${theme.sidebar.small}px`)};
  `}
`;

export const NavContainer = styled.div`
  position: sticky;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
`;
