import styled from 'styled-components';

export const StyledDropdownItem = styled.a`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colorVariants.primary};
    text-decoration: none;
  }
`;
