import React, { FunctionComponent } from 'react';
import { useValidateAuth, useInitUserLanguage, useAuthTokenSync } from './hooks';

export interface AuthLayerProps {}

const AuthLayer: FunctionComponent<AuthLayerProps> = ({ children }) => {
  const { authIsVerified, currentUser } = useValidateAuth();
  useInitUserLanguage(currentUser);

  useAuthTokenSync();

  // todo: initial loading page
  if (!authIsVerified) return null;

  return <>{children}</>;
};

export default AuthLayer;
