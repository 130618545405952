import { InvestorStepEnum } from 'api/models';

export enum InvestorSidebarStep {
  ACCOUNT_DATA = 'accountData',
  QUESTIONNAIRE = 'questionnaire',
  KYC = 'kyc',
  WAITINGKYC = 'waitingKyc',
  DEPOT = 'depotSelection',
  ADDITIONAL_INFORMATION = 'registerAdditionalInformation',
  REGISTRATION_DONE = 'registrationDone',
}

export interface sidebarMappingInterface {
  sidebarStep?: InvestorSidebarStep;
  wizardSteps?: InvestorStepEnum[];
}

const getSidebarMapping = (isWalletStepEnabled?: boolean) => {
  return [
    {
      sidebarStep: InvestorSidebarStep.ACCOUNT_DATA,
      wizardSteps: [InvestorStepEnum.ACCOUNT_SETUP, InvestorStepEnum.UNCONFIRMED],
    },
    {
      sidebarStep: InvestorSidebarStep.QUESTIONNAIRE,
      wizardSteps: [InvestorStepEnum.QUESTIONNAIRE],
    },
    {
      sidebarStep: InvestorSidebarStep.KYC,
      wizardSteps: [
        InvestorStepEnum.BENEFICIARY,
        InvestorStepEnum.IDENTIFICATION,
        InvestorStepEnum.RESUME_IDENTIFICATION,
        InvestorStepEnum.IDENTIFICATION_REJECTED,
        InvestorStepEnum.IDENTIFICATION_FAILED,
      ],
    },
    ...(isWalletStepEnabled
      ? [
          {
            sidebarStep: InvestorSidebarStep.DEPOT,
            wizardSteps: [
              InvestorStepEnum.SELECT_WALLET,
              InvestorStepEnum.SETUP_MOBILE_WALLET,
              InvestorStepEnum.CONFIRM_WALLET,
            ],
          },
        ]
      : []),
    {
      sidebarStep: InvestorSidebarStep.WAITINGKYC,
      wizardSteps: [InvestorStepEnum.WAITING_KYC],
    },
    {
      sidebarStep: InvestorSidebarStep.ADDITIONAL_INFORMATION,
      wizardSteps: [InvestorStepEnum.ADDITIONAL_INFORMATION, InvestorStepEnum.REGISTRATION_DONE],
    },
  ];
};

export default getSidebarMapping;
