import { AdminVoucherStep, CurrencyEnum, Money } from 'api';
import React, { FunctionComponent } from 'react';
import Spacer from 'ui/atoms/spacer';
import { toNumber } from 'ui/helper/money';
import Button from 'ui/atoms/button';
import Grid from 'ui/atoms/grid';
import Header from 'ui/atoms/header';
import Input from 'ui/atoms/input';
import Section from 'ui/atoms/section';
import Translate from 'ui/atoms/translate';
import * as Styled from '../styled';
import { useVoucherSteps } from './hooks';
import FormGroup from 'ui/molecules/form-group';
import Select from 'ui/atoms/select';

interface VoucherStepsProps {
  createdSteps?: AdminVoucherStep[];
  onChangeSteps: (steps: Step[]) => void;
}

export interface Step {
  id: number;
  minInvestment: string | Money | null;
  discount: string | null;
  discountSelection: typeof CurrencyEnum.EUR | '%';
}

export interface ValidatedStep extends Step {
  minInvestment: string | Money;
  discount: string;
}

const MAX_STEPS_ALLOWED = 10;

const VoucherSteps: FunctionComponent<VoucherStepsProps> = ({ createdSteps, onChangeSteps }) => {
  const {
    steps,
    onClickAddStep,
    onClickRemoveStep,
    onChangeInvestmentValue,
    onChangeDiscountValue,
    changeDiscountSelection,
    getActiveDiscountSelection,
    getMaxDiscountValue,
  } = useVoucherSteps(createdSteps, onChangeSteps, MAX_STEPS_ALLOWED);

  const getMinInvestmentValue = (minInvestment: Money | string) => {
    if (typeof minInvestment === 'string') return minInvestment;
    return toNumber(minInvestment).toString();
  };

  return (
    <Section spacing="medium">
      <Header size="small" spacing="medium">
        <Translate name="createVoucherForm.voucherSteps" />
      </Header>
      {steps.length > 0 &&
        steps.map((step) => {
          const activeDiscountSelection = getActiveDiscountSelection(step.id);
          return (
            <Grid>
              <Styled.LeftColumn width={6} tablet={12} phone={12}>
                <FormGroup>
                  <Input
                    type="number"
                    min={0}
                    value={getMinInvestmentValue(step.minInvestment || '')}
                    onChange={(evt) => onChangeInvestmentValue((evt.target as HTMLInputElement).value, step.id)}
                    isCurrency
                    label={<Translate name={`createVoucherForm.minInvestment`} />}
                  />
                </FormGroup>
              </Styled.LeftColumn>
              <Styled.RightColumn width={6} tablet={12} phone={12}>
                <FormGroup direction="row">
                  <Input
                    type="number"
                    step="any"
                    min={1}
                    max={getMaxDiscountValue(step.id)}
                    value={step.discount || ''}
                    onChange={(evt) => onChangeDiscountValue((evt.target as HTMLInputElement).value, step.id)}
                    label={<Translate name={`createVoucherForm.discount`} />}
                  />
                  <Spacer x={2} />
                  <Select
                    options={[
                      {
                        value: CurrencyEnum.EUR,
                        label: '€',
                      },
                      {
                        value: '%',
                        label: '%',
                      },
                    ]}
                    value={activeDiscountSelection}
                    onChange={(val: typeof CurrencyEnum.EUR | '%') => {
                      changeDiscountSelection(step.id, val);
                    }}
                  />
                  {step.id !== 0 && (
                    <Styled.DeleteIcon onClick={() => onClickRemoveStep(step.id)} name="trash" color="primary" />
                  )}
                </FormGroup>
              </Styled.RightColumn>
            </Grid>
          );
        })}
      {steps.length < MAX_STEPS_ALLOWED && (
        <Button variant="link" onClick={onClickAddStep} inline>
          <span>+ </span>
          <Translate name={`createVoucherForm.addStep`} />
        </Button>
      )}
    </Section>
  );
};

export default VoucherSteps;
