import { FunctionComponent, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FunctionComponent = ({ children }) => {
  useEffect(() => {
    document.body.style.setProperty('overflow-y', 'hidden');

    return () => {
      document.body.style.setProperty('overflow-y', '');
    };
  }, []);

  return createPortal(children, document.body);
};

export default Portal;
