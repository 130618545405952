import { Col } from 'ui/atoms/grid';
import styled from 'styled-components';

export const TruncateContractInfo = styled.span`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Column = styled(Col)`
  display: flex;
`;

export const Network = styled.p`
  text-transform: lowercase;
  display: inline-block;
  margin: 0;
  margin-right: 0.2rem;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const HeaderRow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  line-height: 140%;
`;
