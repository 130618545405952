import React, { FunctionComponent } from 'react';

import Button from 'ui/atoms/button';
import Translate from 'ui/atoms/translate';

import PageTitle from 'core/components/page-title';
import { useApiCall } from 'hooks/use-api-call';
import { DemoApi } from 'api';
import { makeApi } from 'core/api';

interface IssuerRegistrationSuccessProps {
  encodedEmail: string;
}

const IssuerRegistrationSuccess: FunctionComponent<IssuerRegistrationSuccessProps> = ({ encodedEmail }) => {
  const email = decodeURIComponent(encodedEmail);

  const { loading, withApi } = useApiCall();

  const onClick = async () => {
    const api: DemoApi = await makeApi(DemoApi);
    await withApi(async () => {
      await api.demoActivationResendCreate({
        demoIssuerActivationResendRequest: { email },
      });
    });
  };

  return (
    <>
      <PageTitle>
        <Translate name="demo.issuerReg.emailConfirmation.title" />
      </PageTitle>
      <p>
        <Translate name="demo.issuerReg.emailConfirmation.text" />
      </p>
      <p>
        <Translate name="demo.issuerReg.emailConfirmation.text2" />
      </p>
      <p>
        <Button loading={loading} fluid={true} variant="primary" onClick={onClick}>
          <Translate name="demo.issuerReg.emailConfirmation.resendButton" />
        </Button>
      </p>
    </>
  );
};

export default IssuerRegistrationSuccess;
