import { useCurrentUserSelector, useIsLoggedInSelector } from 'core/auth/hooks';

import { useEffect, useMemo, useState } from 'react';

import useApiCall from './use-api-call';

import { InvestorsApi, Investor } from 'api';

// todo: this belongs in redux, but investment app migration to redux is a tad bit tricky
const useInvestorMe = () => {
  const [investor, setInvestor] = useState<Investor | null>();

  const { currentUser } = useCurrentUserSelector();
  const isLoggedIn = useIsLoggedInSelector();
  const investorId = currentUser?.investor;

  const { error, loading, makeAuthenticatedApi, withApi } = useApiCall();

  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  useEffect(() => {
    if (!investorId) return;

    withApi(async () => {
      setInvestor(await investorsApi.investorsRetrieve({ id: investorId }));
    });
  }, [investorId, withApi, investorsApi]);

  return { investor, error, loading, isLoggedIn };
};

export default useInvestorMe;
