import React, { FunctionComponent, useMemo, useState } from 'react';
import useApiCall from 'hooks/use-api-call';
import { UsersApi } from 'api';
import ConfirmEmail from './confirm-email';
import { useCurrentUserSelector } from 'core/auth/hooks';
import Translate from 'ui/atoms/translate';
import EnterNewEmail from './enter-new-email';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const UserConfirmationStep: FunctionComponent<{}> = () => {
  const { currentUser } = useCurrentUserSelector();

  const [changeEmailMode, setChangeMailMode] = useState<boolean>();

  const { makeAuthenticatedApi, withApi } = useApiCall();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  // todo: this goes into redux, otherwise inconsistent state can arise
  const [email, setEmail] = useState(currentUser?.email || '');

  // TODO(geforcefan): InvestmentConfirmEmail and InvestmentEnterNewEmail to one component, in fact
  //  refactor this whole step
  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        <Translate name="investmentConfirmEmail.title" />
      </Header>
      {!changeEmailMode && email && (
        <ConfirmEmail receiverEmail={email} onWrongEmailAddress={() => setChangeMailMode(true)} />
      )}
      {changeEmailMode && (
        <EnterNewEmail
          receiverEmail={email}
          onSubmit={({ email }) => {
            withApi(async () => {
              await usersApi.usersEmailResetCreate({
                emailResetRequest: { email },
              });
              setEmail(email);
              setChangeMailMode(false);
            });
          }}
        />
      )}
    </>
  );
};

export default UserConfirmationStep;
