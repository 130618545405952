import React, { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const PopoverPortal: React.FunctionComponent<{ container: any; element: any; scoutElement: any }> = ({
  container,
  element,
  scoutElement,
  children,
}) => {
  useLayoutEffect(() => {
    container.appendChild(element);
    container.appendChild(scoutElement);
    return () => {
      container.removeChild(element);
      container.removeChild(scoutElement);
    };
  }, [container, element]);

  return createPortal(children, element);
};

export { PopoverPortal };
