import React, { FunctionComponent } from 'react';
import groupBy from 'lodash/groupBy';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import Translate from 'ui/atoms/translate';
import {
  DEFAULT_SEGMENT_KEY,
  QuestionnaireForm,
} from 'libraries/wizard/components/questionnaire/questionnaire-form/common';
import { snakeToCamelCaseWithNumbers } from 'ui/helper/case-transforms';
import * as Styled from './styled';
import Input from 'ui/atoms/input';
import useTranslate from 'ui/hooks/use-translate';

export interface SummarySplitSegmentDesktopProps {
  questions: QuestionnaireQuestions[];
  segmentId?: string;
}

const SummarySplitSegmentDesktop: FunctionComponent<SummarySplitSegmentDesktopProps> = ({ questions }) => {
  const questionRows = groupBy(questions, (question) => question.productId);
  const translate = useTranslate();

  const getExperienceSubgroupDesktop = (subgroup?: string) => {
    return (
      <QuestionnaireForm.ValueProvider>
        {(values) => {
          return (
            <>
              <Styled.QuestionnaireSplitFormRow>
                <Styled.QuestionnaireDesktopCol width={5}>
                  <Translate name={`questionnaire.investmentExperienceCategories.${subgroup || 'product'}`} as="b" />
                </Styled.QuestionnaireDesktopCol>
                <Styled.QuestionnaireDesktopCol width={3}>
                  <Translate name="questionnaire.investmentExperienceCategories.knowledge" as="b" />
                </Styled.QuestionnaireDesktopCol>
                <Styled.QuestionnaireDesktopCol width={4}>
                  <Translate name="questionnaire.investmentExperienceCategories.experience" as="b" />
                </Styled.QuestionnaireDesktopCol>
              </Styled.QuestionnaireSplitFormRow>
              {Object.entries(questionRows).map(([productId, questionRow]) => {
                const [knowledge, experience] = questionRow;
                const knowledgeValue = values[knowledge.id];
                const experienceValue = values[experience.id];
                if ((subgroup && knowledge.subgroupId !== subgroup) || (!subgroup && knowledge.subgroupId)) return null;

                return (
                  <Styled.QuestionnaireSplitFormRow key={productId}>
                    <Styled.QuestionnaireDesktopCol width={5}>
                      <Translate as="h4" name={`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(productId)}`} />
                    </Styled.QuestionnaireDesktopCol>
                    <Styled.QuestionnaireDesktopCol width={3}>
                      <Styled.ShortSummaryInput
                        key={knowledge.id}
                        height="small"
                        textAlign="center"
                        value={
                          translate(`${DEFAULT_SEGMENT_KEY}.${knowledgeValue === 'knowledge' ? 'yes' : 'no'}`) as string
                        }
                        disabled
                      />
                    </Styled.QuestionnaireDesktopCol>
                    <Styled.QuestionnaireDesktopCol width={4}>
                      <Input
                        key={experience.id}
                        height="small"
                        textAlign="center"
                        value={
                          translate(`${DEFAULT_SEGMENT_KEY}.${snakeToCamelCaseWithNumbers(experienceValue)}`) as string
                        }
                        disabled
                      />
                    </Styled.QuestionnaireDesktopCol>
                  </Styled.QuestionnaireSplitFormRow>
                );
              })}
            </>
          );
        }}
      </QuestionnaireForm.ValueProvider>
    );
  };

  return (
    <>
      {getExperienceSubgroupDesktop()}
      {getExperienceSubgroupDesktop('crowdfunding')}
    </>
  );
};

export default SummarySplitSegmentDesktop;
