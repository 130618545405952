import React, { FunctionComponent, ReactNode } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import {
  AdminApi,
  InvestmentAcquisitionTypeRecipientEnum,
  AdminVouchersInvestmentsListRequest,
  InvestmentDetails,
} from 'api';
import useGoTo from 'hooks/use-go-to';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import StudioConnectedInvestmentsTable from './StudioInvestmentsTable';
import { PaginationProps } from 'ui/molecules/pagination';

interface ConnectedInvestmentsTableProps extends AdminVouchersInvestmentsListRequest {
  id: string;
  filters?: ReactNode;
  forceDataLoad?: boolean;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
  paginationProps?: PaginationProps;
}

const ConnectedInvestmentsTable: FunctionComponent<ConnectedInvestmentsTableProps> = WithDataRecord<
  AdminApi,
  InvestmentDetails
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.investmentDetails);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <StudioConnectedInvestmentsTable
          loading={loading}
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(productId: string, investmentId: string) => goTo({ productId, investmentId })}
          investments={(data || []).map((investment) => ({
            ...investment,
            acquisitionType: investment.acquisitionType || InvestmentAcquisitionTypeRecipientEnum.ISSUANCE,
          }))}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminVouchersInvestmentsList({
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default ConnectedInvestmentsTable;
