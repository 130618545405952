import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import StudioConfirmMultiplesModal from './studio-confirm-multiples-modal';
import Translate from 'ui/atoms/translate';
import Number from 'ui/atoms/number';
import Button from 'ui/atoms/button';
import { useInvestmentsFilteredByConfirmPermissions } from 'apps/issuer/shared/confirm-investments-table-view/batch-confirmation/hooks';
import { AdminInvestment } from 'api/models';
import { BatchConfirmTypes } from 'apps/issuer/shared/confirm-investments-table-view/types';
import { useApiCall } from 'hooks/use-api-call';
import { AdminApi } from 'api';
import type { Mode as ModalMode } from './studio-confirm-multiples-modal';

type Mode = 'closed' | ModalMode;

const IssuerBatchConfirmation: FunctionComponent<{
  investments: AdminInvestment[];
  type: BatchConfirmTypes;
  onReloadData: () => void;
}> = ({ investments, onReloadData, type }) => {
  const [mode, setMode] = useState<Mode>('closed');
  const [startConfirmation, setStartConfirmation] = useState(false);

  const modeRef = useRef<Mode>();
  modeRef.current = mode;

  const [progress, setProgress] = useState({
    idx: 0,
    total: investments?.length || 0,
  });

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const processInvestment = useCallback(
    async (id: string) => {
      if (type === 'confirmPayments') {
        await api.adminInvestmentsPaymentConfirmationCreate({ id });
      } else if (type === 'approveOffers') {
        await api.adminInvestmentsOfferApprovalCreate({ id });
      } else {
        throw new Error('invalid type');
      }
    },
    [api, type],
  );

  const onClose = useCallback(() => {
    setMode('closed');
    // In confirm case, no reload is needed as nothing was done yet.
    // In running case, effect below will do the reload after cancellation.
    // In error/success case, we need to reload.
    if (mode === 'success' || mode === 'error') {
      onReloadData();
    }
  }, [mode, onReloadData]);

  useEffect(() => {
    if (!startConfirmation) return;
    setStartConfirmation(false);

    (async () => {
      for (let i = 0; i < investments.length; ++i) {
        setProgress({ idx: i, total: investments.length });

        await processInvestment(investments[i].id);

        if (modeRef.current !== 'running') {
          // We got cancelled. modal is no longer shown. make sure to reload parent.
          // This can cause this component to get unmounted, so make sure not to
          // do anything afterwards.
          onReloadData();
          return;
        }
      }
      setMode('success');
      setProgress({ idx: investments.length, total: investments.length });
    })();
  }, [withApi, api, mode, investments, startConfirmation, onReloadData, processInvestment]);

  useEffect(() => {
    if (error) setMode('error');
  }, [error]);

  return (
    <>
      <Button variant="primary" onClick={() => setMode('confirm')}>
        <Translate
          name={`issuerBatchConfirmation.${type}.confirmAll`}
          args={[<Number key={0}>{investments.length}</Number>]}
        />
      </Button>
      {mode !== 'closed' && (
        <StudioConfirmMultiplesModal
          totalNumber={investments.length}
          confirmedNumber={progress.idx}
          mode={mode}
          onConfirm={() => {
            setMode('running');
            setStartConfirmation(true);
          }}
          onClose={onClose}
          i18nKey={type}
        />
      )}
    </>
  );
};

const IssuerBatchConfirmationWrapper: FunctionComponent<{
  investments: AdminInvestment[] | undefined;
  type: BatchConfirmTypes;
  onReloadData: () => void;
}> = ({ investments, type, onReloadData }) => {
  const filteredInvestments = useInvestmentsFilteredByConfirmPermissions(investments, type);

  if (!filteredInvestments || filteredInvestments.length < 2) return null;

  return <IssuerBatchConfirmation investments={filteredInvestments} type={type} onReloadData={onReloadData} />;
};

export default IssuerBatchConfirmationWrapper;
