import { getColorsMap, resolveColor } from 'src/ui/styles/utils/colors';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

function getDropdownItemStyle({ theme }: ThemedStyledProps<null, DefaultTheme>) {
  const { main, minor } = getColorsMap(theme);

  return css`
    display: flex;
    padding: 0.25rem;
    color: ${resolveColor(theme, 'dark')};
    white-space: nowrap;
    overflow: auto;

    &:hover,
    &.active {
      color: ${minor};
      text-decoration: none;
      background-color: ${main};

      ${Email} {
        color: ${minor};
      }
    }
  `;
}

export const SearchItem = styled.a`
  ${getDropdownItemStyle}
`;

export const Email = styled.span`
  color: ${({ theme }) => resolveColor(theme, 'grayMain')};
`;
