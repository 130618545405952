import styled from 'styled-components';

export const StepsContainer = styled.ul`
  list-style-type: none;
  margin: 2rem 0 0 0;
  padding: 0;
`;

export const StepItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }
`;

export const Description = styled.div`
  margin-left: 1rem;
`;
