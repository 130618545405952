import jwtDecode, { JwtPayload } from 'jwt-decode';

export function isAuthExpired(authToken: string): boolean {
  if (!authToken) return true;

  const { exp } = jwtDecode<JwtPayload>(authToken);

  // if no exp on token, we assume auth is not expired, otherwise we may get into a situation where the user can't log in at all
  return !!(exp && exp < Date.now() / 1000);
}
