import React, { FunctionComponent } from 'react';
import { camelCase } from 'change-case';
import cx from 'ui/helper/prefixed-class-names';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import Translate from 'ui/atoms/translate';
import Section from 'ui/atoms/section';
import Image from 'ui/atoms/image';
import Button from 'ui/atoms/button';
import Link from 'ui/atoms/link';
import { AssetPreparationStatus } from 'ui/types/asset-preparation';

export interface PrepareAssetTransactionProps {
  /** Additional classes. */
  className?: string;

  image?: string;

  assetPreparationStatus: AssetPreparationStatus;

  onCancel?: () => void;
}

const PrepareAssetTransaction: FunctionComponent<PrepareAssetTransactionProps> = (props) => {
  const { className, children, image, assetPreparationStatus, onCancel, ...restProps } = props;

  const translate = useTranslateWithStringArgs();

  const canCancel = assetPreparationStatus === AssetPreparationStatus.STARTED;

  return (
    <div className={cx('prepare-asset-transaction', className)} {...restProps}>
      {image && (
        <Section>
          <Image size="large" alt="" src={image} />
        </Section>
      )}
      <Section>
        <p>
          <Translate
            as="b"
            preventRenderingOnNoTranslation={true}
            name={`prepareAssetTransaction.${camelCase(assetPreparationStatus)}.title`}
          />
        </p>
        <Translate
          as="p"
          preventRenderingOnNoTranslation={true}
          name={`prepareAssetTransaction.${camelCase(assetPreparationStatus)}.description`}
          args={[
            (text, key) => (
              <Link key={key} href={`mailto:${translate('platform.supportEmail')}`} inline>
                {text}
              </Link>
            ),
          ]}
        />
      </Section>

      {canCancel && onCancel && (
        <>
          <Translate as="p" name={`prepareAssetTransaction.${camelCase(assetPreparationStatus)}.cancel`} />
          <Button
            variant="link"
            onClick={() => {
              onCancel();
            }}
          >
            <Translate name="prepareAssetTransaction.cancelAction" />
          </Button>
        </>
      )}
    </div>
  );
};

export default PrepareAssetTransaction;
