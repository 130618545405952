import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

export interface ColProps {
  showSidebar?: boolean;
  wide?: boolean;
  className?: string;
}

export const Sidebar: FunctionComponent<ColProps> = ({ children, showSidebar, wide, className }) => {
  if (!showSidebar) return null;

  return (
    <Styled.Sidebar $wide={wide} className={className}>
      <Styled.NavContainer>{children}</Styled.NavContainer>
    </Styled.Sidebar>
  );
};

export default Sidebar;
