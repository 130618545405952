import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { getColorsMap } from 'ui/styles/utils/colors';
import { ArrowContainer } from 'ui/molecules/popover';
import type { Colors } from 'ui/styles/utils/colors';

type InfoProps = {
  $color?: Colors;
};

function getColor({ theme, $color }: ThemedStyledProps<InfoProps, DefaultTheme>) {
  const { main, minor } = getColorsMap(theme, $color, false);

  return css`
    background-color: ${main};
    color: ${minor};
  `;
}

export const Info = styled.div`
  z-index: 1000;
  max-width: 450px;
  min-width: 120px;
  min-height: 40px;
  opacity: ${({ theme }) => theme.popups.opacity};
  padding: ${({ theme }) => theme.popups.padding}px;
  border-radius: ${({ theme }) => theme.popups.borderRadius}px;
  ${getColor};
`;

export const Arrow = styled(ArrowContainer)`
  opacity: ${({ theme }) => theme.popups.opacity};
`;

export const InfoIcon = styled.div`
  display: inline-flex;
  cursor: pointer;
`;
