import { useCallback, useMemo } from 'react';
import useApiCall from 'hooks/use-api-call';
import { InvestorsApi } from 'api/apis';
import useInvestorMe from 'hooks/use-investor-me';
import { Investor } from 'api';

const useBeneficiary = (): {
  benefitingPersonConfirmationCreate: () => void;
  showLoading: boolean;
  investor?: Investor | null;
} => {
  //hooks
  const { withApi, makeAuthenticatedApi } = useApiCall();
  const { investor } = useInvestorMe();

  //apis
  const investorsApi: InvestorsApi = useMemo(() => makeAuthenticatedApi(InvestorsApi), [makeAuthenticatedApi]);

  //functions
  const benefitingPersonConfirmationCreate = useCallback(() => {
    return withApi(async () => {
      if (!investor?.id) return null;
      await investorsApi.investorsBenefitingPersonConfirmationCreate({ id: investor.id });
    });
  }, [investorsApi, investor?.id, withApi]);

  return useMemo(
    () => ({
      benefitingPersonConfirmationCreate,
      showLoading: !investor,
      investor,
    }),
    [benefitingPersonConfirmationCreate, investor?.id],
  );
};

export default useBeneficiary;
