import styled, { css } from 'styled-components';
import Container from 'ui/atoms/container';
import { resolveColor } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';

function getWizardFrameSize() {
  return css`
    ${query.phone`
      padding: 0.5rem 0rem 1rem 0rem;
    `};

    ${query.tablet`
      padding: 1.25rem 1rem 2rem 1rem;
    `};

    ${query.lTablet`
      padding: 1.25rem 1.5rem 1.5rem 1.5rem;
    `};

    ${query.desktops`
      padding: 1rem 2rem 2rem 2rem;
    `};
  `;
}

export const WizardLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => resolveColor(theme, 'background')}};
  overflow: hidden;
  ${getWizardFrameSize};
`;
export const WizardContent = styled.div`
  flex-grow: 1;
  height: 100%;
  height: -webkit-fill-available;
  overflow: auto;
  background-color: ${({ theme }) => resolveColor(theme, 'white')};
  border-radius: 1rem;

  ${query.phone`
    border-radius: 0;
  `};
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndices.header};
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Main = styled.main`
  position: relative;
  height: 100%;
`;
export const Footer = styled.footer`
  height: 20px;
  line-height: normal;
  margin-top: 1rem;

  ${query.phone`
    margin-left: 1rem;
  `};
`;

export const SidebarElements = styled(Container)`
  position: relative;
`;
