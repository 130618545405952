import cx from 'classnames';

type ClassValue = string | number | ClassDictionary | ClassArray | undefined | null | boolean;

interface ClassDictionary {
  [id: string]: any;
}

type ClassArray = Array<ClassValue>;

const prefixedClassNames = (...classes: ClassValue[]): string => cx('cashlink-ui', ...classes);

export default prefixedClassNames;
