import React, { FunctionComponent } from 'react';

import Translate from 'ui/atoms/translate';

import PageTitle from 'core/components/page-title';

const InvestorRegistrationSuccess: FunctionComponent<{}> = (_) => {
  return (
    <>
      <PageTitle>
        <Translate name="demo.investorReg.emailConfirmation.title" />
      </PageTitle>
      <p>
        <Translate name="demo.investorReg.emailConfirmation.text" />
      </p>
    </>
  );
};

export default InvestorRegistrationSuccess;
