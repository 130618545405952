import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import Translate from 'ui/atoms/translate';
import FormGroup, { Error, Info } from 'ui/molecules/form-group';
import Input from 'ui/atoms/input';
import useForm, { ChangeWithValidationState } from 'ui/hooks/use-form';
import NumberAtom from 'ui/atoms/number';
import { compact } from 'lodash';
import findIndex from 'lodash/findIndex';
import { makeRequirement } from 'ui/molecules/form-group';
import Currency from 'ui/atoms/currency';
import Percent from 'ui/atoms/percent';
import { MoneyValue } from 'ui/types/money-value';
import BigNumber from 'bignumber.js';
import { makeBigNumber } from 'ui/helper/big-number';
import PredefinedInputOptions from 'ui/molecules/predefined-input-options';
import { Document } from 'ui/types/document';
import Section from 'ui/atoms/section';
import CommitmentMessage from '../commitment-message';
import Header from 'ui/atoms/header';
import Button from 'ui/atoms/button';

export interface TokenAmountInput {
  nrOfTokens: BigNumber;
}

export interface InvestmentAmountNumbers {
  maxNumberOfTokens?: BigNumber;
  minNumberOfTokens?: BigNumber;
  investmentTotal?: MoneyValue;
  pricePerToken: MoneyValue;
  exitProceedsPercent?: number;
  stepSize: BigNumber;
}

export interface InvestmentAmountInputProps extends InvestmentAmountNumbers {
  onChangeWithValidation: (state: ChangeWithValidationState<TokenAmountInput>) => void;
  customCommitmentText?: string | null;
  customCommitmentDocument?: Document | null;
  companyName?: ReactNode;
  showShareInfo: boolean;
  unitSteps?: BigNumber[];
  /** Investors can enter units (true) or investment amount (false) in the commitment step */
  useUnits: boolean;
  initial: {
    numberOfTokens?: BigNumber;
  };
}

const InvestmentAmountInput: FunctionComponent<InvestmentAmountInputProps> = (props) => {
  const {
    onChangeWithValidation = () => {},
    maxNumberOfTokens,
    minNumberOfTokens,
    investmentTotal,
    pricePerToken,
    exitProceedsPercent,
    companyName,
    showShareInfo,
    useUnits,
    unitSteps,
    stepSize,
    customCommitmentText,
    customCommitmentDocument,
    initial: { numberOfTokens: initialNumberOfTokens },
  } = props;

  const initialInvestmentAmountInput =
    initialNumberOfTokens &&
    (useUnits ? initialNumberOfTokens.toString() : pricePerToken.mul(initialNumberOfTokens).bigNumber.toString());

  const initial = initialInvestmentAmountInput ? { investmentAmount: initialInvestmentAmountInput } : {};

  const [{ values, touched, isValid, setField, setFieldError, validity }, { number }] = useForm<{
    investmentAmount: string;
  }>(
    {
      isEditMode: !!initialNumberOfTokens,
      validateFields: ['investmentAmount'],
    },
    { ...initial },
  );

  // TODO(geforcefan): for some reason, validate function of form state lib is
  //  not detecting changes in props, maybe a caching problem in lib?
  const propsRef = useRef<InvestmentAmountInputProps>(props);
  propsRef.current = props;

  const investmentAmountBN = new BigNumber(values.investmentAmount);
  const maxValue =
    maxNumberOfTokens && (useUnits ? maxNumberOfTokens : maxNumberOfTokens.multipliedBy(pricePerToken.bigNumber));
  const minValue =
    minNumberOfTokens && (useUnits ? minNumberOfTokens : minNumberOfTokens.multipliedBy(pricePerToken.bigNumber));

  const isMaxTokenValid = maxValue ? investmentAmountBN.lte(maxValue) : true;
  const isMinTokenValid = minValue ? investmentAmountBN.gte(minValue) : true;

  const isAmountDivisibleByStep = useMemo(() => {
    if (!values.investmentAmount || !values.investmentAmount.length) return true;

    return useUnits
      ? !!values.investmentAmount && makeBigNumber(values.investmentAmount).mod(stepSize).eq(0)
      : !!values.investmentAmount &&
          new MoneyValue(makeBigNumber(values.investmentAmount), pricePerToken.currency)
            .mod(pricePerToken.mul(stepSize))
            .eq(0);
  }, [useUnits, values.investmentAmount, stepSize, pricePerToken.currency]);

  // filter unitSteps to only contain valid values
  const validUnitSteps = useMemo(() => {
    if (!unitSteps) return undefined;
    return unitSteps.filter((unitStep) => {
      return (
        (!minNumberOfTokens || unitStep.gte(minNumberOfTokens)) &&
        (!maxNumberOfTokens || unitStep.lte(maxNumberOfTokens)) &&
        unitStep.mod(stepSize).eq(0)
      );
    });
  }, [unitSteps, maxNumberOfTokens, minNumberOfTokens]);

  const validateUnits = useCallback((units: BigNumber) => {
    return (
      (!propsRef.current.maxNumberOfTokens || units.lte(propsRef.current.maxNumberOfTokens)) &&
      (!propsRef.current.minNumberOfTokens || units.gte(propsRef.current.minNumberOfTokens)) &&
      units.decimalPlaces() === 0 &&
      units.mod(stepSize).eq(0)
    );
  }, []);

  const validateUnitInput = (value: string) => {
    if (!value) return false;
    const units = makeBigNumber(value);
    return validateUnits(units);
  };

  const validateAmountInput = (value: string) => {
    if (!value) return false;
    const moneyValue = new MoneyValue(makeBigNumber(value), pricePerToken.currency);
    // divisible by token price
    if (!moneyValue.mod(pricePerToken).eq(0)) return false;
    const units = moneyValue.div(pricePerToken);
    return validateUnits(units);
  };

  const roundValue = () => {
    const value = makeBigNumber(values.investmentAmount);
    const divisor = pricePerToken.mul(stepSize).bigNumber;
    let roundedValue = value.div(divisor).integerValue(BigNumber.ROUND_HALF_UP).multipliedBy(divisor);
    roundedValue =
      minValue && roundedValue.lt(minValue || 0)
        ? minValue
        : maxValue && roundedValue.gt(maxValue || Number.MAX_VALUE)
        ? maxValue
        : roundedValue;
    setField('investmentAmount', roundedValue.toString());
  };

  const manuallySetInput = (value: string) => {
    setField('investmentAmount', value);
    if (useUnits && !validateUnitInput(value)) {
      setFieldError('investmentAmount', true);
    }
    if (!useUnits && !validateAmountInput(value)) {
      setFieldError('investmentAmount', true);
    }
  };

  useEffect(() => {
    onChangeWithValidation({
      isValid,
      values: {
        nrOfTokens: useUnits
          ? makeBigNumber(values.investmentAmount || 0)
          : new MoneyValue(makeBigNumber(values.investmentAmount || 0), pricePerToken.currency).div(pricePerToken),
      },
    });
  }, [values.investmentAmount, isValid, onChangeWithValidation, useUnits, pricePerToken]);

  return (
    <>
      <Header size="xsmall" spacing="small">
        <Translate name="investmentAmount.investmentAmountInput.header" />
      </Header>
      <CommitmentMessage
        customCommitmentText={customCommitmentText}
        customCommitmentDocument={customCommitmentDocument}
        companyName={companyName}
      />
      <FormGroup
        requirements={compact([
          useUnits &&
            stepSize.gt(1) &&
            makeRequirement(
              <Translate
                name={`investmentAmount.investmentAmountInput.requirements.${
                  isAmountDivisibleByStep ? 'divisibleByStepSize' : 'divisibleByStepSizeRoundOffer'
                }`}
                args={[
                  <NumberAtom key={0}>{stepSize}</NumberAtom>,
                  (text) => {
                    return (
                      <Button variant="link" inline onClick={roundValue}>
                        {text}
                      </Button>
                    );
                  },
                ]}
              />,
              isAmountDivisibleByStep,
              touched.investmentAmount,
            ),
          !useUnits &&
            makeRequirement(
              <Translate
                name={`investmentAmount.investmentAmountInput.requirements.${
                  isAmountDivisibleByStep ? 'divisibleByStepSizeUnitPrice' : 'divisibleByStepSizeUnitPriceRoundOffer'
                }`}
                args={[
                  <Currency key={0} decimals={pricePerToken.mul(stepSize).decimals}>
                    {pricePerToken.mul(stepSize)}
                  </Currency>,
                  (text) => {
                    return (
                      <Button variant="link" inline onClick={roundValue}>
                        {text}
                      </Button>
                    );
                  },
                ]}
              />,
              isAmountDivisibleByStep,
              touched.investmentAmount,
            ),
          minNumberOfTokens &&
            minNumberOfTokens.gt(1) &&
            makeRequirement(
              <Translate
                name="investmentAmount.investmentAmountInput.requirements.min"
                args={[
                  useUnits
                    ? (_, key) => (
                        <NumberAtom key={key} type="shares">
                          {minNumberOfTokens}
                        </NumberAtom>
                      )
                    : () => (
                        <Currency key={0} decimals={pricePerToken.mul(minNumberOfTokens).decimals}>
                          {pricePerToken.mul(minNumberOfTokens)}
                        </Currency>
                      ),
                ]}
              />,

              useUnits
                ? values.investmentAmount !== undefined && makeBigNumber(values.investmentAmount).gte(minNumberOfTokens)
                : values.investmentAmount !== undefined &&
                    new MoneyValue(makeBigNumber(values.investmentAmount), pricePerToken.currency)
                      .div(pricePerToken)
                      .gte(minNumberOfTokens),
              touched.investmentAmount,
            ),
          maxNumberOfTokens &&
            makeRequirement(
              <Translate
                name="investmentAmount.investmentAmountInput.requirements.max"
                args={[
                  useUnits
                    ? (_, key) => (
                        <NumberAtom key={key} type="shares">
                          {maxNumberOfTokens}
                        </NumberAtom>
                      )
                    : (_, key) => (
                        <Currency key={key} decimals={pricePerToken.mul(maxNumberOfTokens).decimals}>
                          {pricePerToken.mul(maxNumberOfTokens)}
                        </Currency>
                      ),
                ]}
              />,
              useUnits
                ? values.investmentAmount !== undefined && makeBigNumber(values.investmentAmount).lte(maxNumberOfTokens)
                : values.investmentAmount !== undefined &&
                    new MoneyValue(makeBigNumber(values.investmentAmount), pricePerToken.currency)
                      .div(pricePerToken)
                      .lte(maxNumberOfTokens),
              touched.investmentAmount,
            ),
        ])}
      >
        <Section spacing="tiny">
          <Input
            autoFocus={true}
            autoBlur={!!initialInvestmentAmountInput}
            max={maxValue?.toString()}
            min={minValue?.toString()}
            step={useUnits ? stepSize.toString() : pricePerToken.mul(stepSize).bigNumber.toString()}
            required={true}
            isStepper
            setInputValue={(value: string) => manuallySetInput(value)}
            label={<Translate name={`investmentAmount.investmentAmountInput.label.${useUnits ? 'units' : 'amount'}`} />}
            {...number('investmentAmount', {
              validate: useUnits ? validateUnitInput : validateAmountInput,
            })}
          />
          <Info>
            {investmentTotal !== undefined &&
              (useUnits || showShareInfo) &&
              exitProceedsPercent !== undefined &&
              (validity.investmentAmount || initialNumberOfTokens) && (
                <Translate
                  name="investmentAmount.investmentAmountInput.info.description"
                  args={[
                    (_, key) => (
                      <>
                        {useUnits && (
                          <Translate
                            name="investmentAmount.investmentAmountInput.info.investmentTotal"
                            args={[(_, key) => <Currency key={key}>{investmentTotal}</Currency>]}
                          />
                        )}
                        {showShareInfo && useUnits && (
                          <Translate name="investmentAmount.investmentAmountInput.info.and" />
                        )}
                        {showShareInfo && (
                          <Translate
                            name="investmentAmount.investmentAmountInput.info.shares"
                            args={[
                              (_, key) => (
                                <Percent decimals={3} key={key}>
                                  {exitProceedsPercent / 100}
                                </Percent>
                              ),
                            ]}
                          />
                        )}
                      </>
                    ),
                  ]}
                />
              )}
          </Info>
          <Error>
            {touched.investmentAmount &&
              ((!isMinTokenValid && (
                <Translate
                  name="form.errors.min"
                  args={[
                    minValue &&
                      (useUnits ? (
                        <NumberAtom key={0}>{minValue.toNumber()}</NumberAtom>
                      ) : (
                        <Currency decimals={new MoneyValue(minValue, pricePerToken.currency).decimals}>
                          {new MoneyValue(minValue, pricePerToken.currency)}
                        </Currency>
                      )),
                  ]}
                />
              )) ||
                (!isMaxTokenValid && (
                  <Translate
                    name="form.errors.max"
                    args={[maxValue && <NumberAtom key={0}>{maxValue.toNumber()}</NumberAtom>]}
                  />
                )))}
          </Error>
        </Section>
        {useUnits && validUnitSteps && (
          <Section spacing="tiny">
            <PredefinedInputOptions
              size="small"
              activeOptionIndex={findIndex(validUnitSteps, (unitStep) => unitStep.eq(investmentAmountBN))}
              options={validUnitSteps}
              optionLabel={(option: BigNumber) => <NumberAtom>{option}</NumberAtom>}
              onOptionSelected={(value: BigNumber) => manuallySetInput(value.toString())}
            />
          </Section>
        )}
        {!useUnits && validUnitSteps && (
          <Section spacing="tiny">
            <PredefinedInputOptions
              size="small"
              activeOptionIndex={findIndex(validUnitSteps, (unitStep) =>
                pricePerToken.mul(unitStep).bigNumber.eq(investmentAmountBN),
              )}
              options={validUnitSteps.map((option) => pricePerToken.mul(option))}
              optionLabel={(option: MoneyValue) => (
                <>
                  <Currency decimals={option.decimals} truncateDecimals={2}>
                    {option}
                  </Currency>
                  <span>{option.decimals > 2 && '...'}</span>
                </>
              )}
              onOptionSelected={(option: MoneyValue) =>
                setField('investmentAmount', option.bigNumber.toFixed(option.decimals || 0))
              }
            />
          </Section>
        )}
      </FormGroup>
    </>
  );
};

export default InvestmentAmountInput;
