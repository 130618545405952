import React, { FunctionComponent, ReactNode } from 'react';
import Translate from 'ui/atoms/translate';
import { camelCase } from 'change-case';
import DocumentModal from 'libraries/document-modal';
import { Document } from 'ui/types/document';

export interface CommitmentMessageProps {
  customCommitmentText?: string | null;
  customCommitmentDocument?: Document | null;
  companyName?: ReactNode;
}

const CommitmentMessage: FunctionComponent<CommitmentMessageProps> = (props) => {
  const { customCommitmentText, customCommitmentDocument, companyName } = props;

  if (!customCommitmentText) return null;

  return (
    <>
      <Translate
        as="p"
        name={`investmentAmount.customCommitmentText.${camelCase(customCommitmentText)}`}
        args={[
          companyName,
          ...(customCommitmentDocument
            ? [
                (text: string, key: number) => {
                  return (
                    <DocumentModal key={key} {...customCommitmentDocument}>
                      {text}
                    </DocumentModal>
                  );
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default CommitmentMessage;
