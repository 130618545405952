import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { CashlinkStore } from 'store/types';
import { push } from 'connected-react-router';
import { useDispatch, useStore } from 'store/hooks';
import { useCurrentUserSelector, useIsLoggedInSelector } from 'core/auth/hooks';
import { isInvestor, isIssuer } from 'core/auth/helper';
import LoadingRing from 'ui/atoms/loading-ring';
import { AppType } from 'core/auth/types';

export type ConditionFn = ({
  resolveCondition,
  store,
  dispatch,
}: {
  resolveCondition: (condition: boolean) => void;
  store: CashlinkStore<any, any>;
  dispatch: Dispatch<any>;
}) => Promise<void> | void;

export interface ConditionalPageProps {
  condition: ConditionFn;
  appType?: AppType;
}

const ConditionalPage: FunctionComponent<ConditionalPageProps> = ({ children, condition, appType }) => {
  const { currentUser } = useCurrentUserSelector();
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();
  const [conditionResolved, setConditionResolved] = useState(false);
  const store = useStore();
  // todo: error handling
  // const [conditionErrored, setConditionErrored] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      const isIssuerInWrongApp =
        isIssuer(currentUser) && (appType === AppType.INVESTMENT || appType === AppType.INVESTOR);

      const isInvestorInWrongApp = isInvestor(currentUser) && appType === AppType.ISSUER;

      if (isIssuerInWrongApp || isInvestorInWrongApp) {
        dispatch(push('/dashboard'));
      }
    }

    condition({ resolveCondition: setConditionResolved, store: store, dispatch });
  }, [isLoggedIn]);

  if (conditionResolved) {
    return <>{children}</>;
  }

  return <LoadingRing />;
};

export default ConditionalPage;
