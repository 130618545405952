export interface InputTheme {
  labelColor: any;
  borderColor: any;
  borderFocusedColor: any;
  labelFocusedColor: any;
  disabledColor: any;
}

export default {
  labelColor: ({ theme }: any) => theme.colors.grayMain,
  borderColor: ({ theme }: any) => theme.colors.grayHover,
  borderFocusedColor: ({ theme }: any) => theme.colorVariants.primary,
  labelFocusedColor: ({ theme }: any) => theme.colorVariants.primary,
  disabledColor: ({ theme }: any) => theme.colors.grayLight,
} as InputTheme;
