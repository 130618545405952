import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import useTranslate from 'src/ui/hooks/use-translate';
import { CountryEnum } from 'src/api';
import { intersection } from 'lodash';

const useCountries = (countries: CountryEnum[] | string[] = Object.values(CountryEnum)) => {
  const translate = useTranslate();

  const mostUsedCountries = useMemo(() => {
    const mostUsed = ['DEU', 'AUT', 'CHE'];
    const filteredCountries = intersection(countries, mostUsed);

    return filteredCountries.map((country) => ({
      value: country,
      label: translate(`countries.${country}`),
    }));
  }, [translate, countries]);

  const sorted = useMemo(
    () =>
      sortBy(
        countries.map((country) => ({
          value: country,
          label: translate(`countries.${country}`),
        })),
        ['trans'],
      ),
    [translate, JSON.stringify(countries)],
  );

  return [
    {
      value: translate('countrySelect.oftenUsed').toString().toUpperCase(),
      label: translate('countrySelect.oftenUsed').toString().toUpperCase(),
      disabled: true,
    },
    ...mostUsedCountries,
    {
      value: translate('countrySelect.all').toString().toUpperCase(),
      label: translate('countrySelect.all').toString().toUpperCase(),
      disabled: true,
    },
    ...sorted,
  ];
};

export default useCountries;
