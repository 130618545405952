import React, { FunctionComponent } from 'react';
import { CountryEnum } from 'src/api';
import useCountries from 'src/hooks/use-countries';
import Translate from 'ui/atoms/translate';
import { Salutation } from 'ui/types/person';

export interface AddIdentificationPersonalDataProps {
  AddIdentificationForm: any;
  nationality: CountryEnum | undefined;
  onChangeNationality: (country: string) => void;
}

const AddIdentificationPersonalData: FunctionComponent<AddIdentificationPersonalDataProps> = (props) => {
  const { AddIdentificationForm, nationality, onChangeNationality } = props;

  return (
    <>
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitlePersonalData" />
        </strong>
      </p>
      <AddIdentificationForm.Group name="salutation" required={true}>
        <AddIdentificationForm.GroupToggle>
          {Object.values(Salutation).map((salutation, index) => (
            <AddIdentificationForm.Radio value={salutation} key={index}>
              <Translate name={`personalInformation.salutations.${salutation.toLowerCase()}`} />
            </AddIdentificationForm.Radio>
          ))}
        </AddIdentificationForm.GroupToggle>
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="forename" required={true}>
        <AddIdentificationForm.Input />
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="surname" required={true}>
        <AddIdentificationForm.Input />
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="dateOfBirth" required={true}>
        <AddIdentificationForm.DateInput max={new Date()} />
        {AddIdentificationForm.Validators.PastDate()}
        {AddIdentificationForm.Validators.Over18()}
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="placeOfBirth" required={true}>
        <AddIdentificationForm.Input />
      </AddIdentificationForm.Group>
      <AddIdentificationForm.Group name="nationality" required={true}>
        <AddIdentificationForm.Select
          options={useCountries()}
          value={nationality}
          onChange={(nationality: CountryEnum) => {
            onChangeNationality(nationality);
          }}
        ></AddIdentificationForm.Select>
      </AddIdentificationForm.Group>
    </>
  );
};

export default AddIdentificationPersonalData;
