// Set up a message event handler on page load, so that we can attach event handlers
// later without losing messages

type MessageHandler = (evt: MessageEvent) => void;

const bufferedMessages: MessageEvent[] = [];
const handlers: { [key: string]: MessageHandler } = {};

export const setUpMessageBuffer = () => {
  window.addEventListener('message', (evt: MessageEvent) => {
    bufferedMessages.push(evt);
    for (const handler of Object.values(handlers)) {
      handler(evt);
    }
  });
};

export const registerHandler = (key: string, handler: MessageHandler) => {
  const isNew = !handlers.hasOwnProperty(key);
  handlers[key] = handler;
  if (!isNew) {
    return;
  }
  for (const message of bufferedMessages) {
    setTimeout(() => handler(message), 0);
  }
};
