import React, { createContext, FunctionComponent } from 'react';
import { CustomImages } from 'ui/assets/images/types';

import 'ui/assets/icons'; // icons are taken care of by the webpack loader, no need to do anything extra here
import defaultImages from 'ui/assets/images';

export const AssetsContext = createContext<CustomImages>(defaultImages);

export const AssetsProvider: FunctionComponent<{
  images: CustomImages;
  imagesLoaded: boolean;
}> = ({ children, imagesLoaded, images }) => {
  if (!imagesLoaded) return null;

  return <AssetsContext.Provider value={images}>{children}</AssetsContext.Provider>;
};

export default {
  AssetsContext,
  AssetsProvider,
};
