import { resolveColor } from 'ui/styles/utils/colors';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Size from 'ui/types/size';
import { query } from 'ui/styles/queries';

interface PredefinedInputOptionProps {
  $active: boolean;
  $size?: Size;
}

function getPredefinedInputButtonStyle({
  theme,
  $active,
}: ThemedStyledProps<PredefinedInputOptionProps, DefaultTheme>) {
  return css`
    font-size: 0.75rem;
    background-color: ${$active ? theme.predefinedInputOption.activeBackgroundColor : resolveColor(theme, 'white')};
    color: ${$active ? theme.predefinedInputOption.activeColor : theme.predefinedInputOption.color};
    border: 1px solid
      ${$active ? theme.predefinedInputOption.activeBorderColor : theme.predefinedInputOption.borderColor};
    border-radius: 0.5rem;
    margin-right: 0.7rem;
    margin-bottom: 0.7rem;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  `;
}

function getPredefinedInputButtonHoverStyle({ theme }: ThemedStyledProps<PredefinedInputOptionProps, DefaultTheme>) {
  return css`
    ${query.desktops`
      &:hover {
        border: 1px solid ${theme.predefinedInputOption.activeBorderColor};
        background-color: ${theme.predefinedInputOption.activeBackgroundColor};
        color: ${theme.predefinedInputOption.activeColor};
      }
    `};
  `;
}

function getPredefinedInputButtonSize({ $size }: ThemedStyledProps<PredefinedInputOptionProps, DefaultTheme>) {
  if ($size === 'small') {
    return css`
      padding: 0.25rem 0.5rem;
    `;
  }

  return css`
    padding: 0.75rem 1rem;
  `;
}

export const PredefinedInputOption = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PredefinedInputButton = styled.div<PredefinedInputOptionProps>`
  ${getPredefinedInputButtonStyle};
  ${getPredefinedInputButtonHoverStyle};
  ${getPredefinedInputButtonSize};
`;
