export enum RootActions {
  RESET_STATE = 'RESET_STATE',
}

export type ResetAppState = { type: RootActions.RESET_STATE };

export type RootActionTypes = ResetAppState;

export type WithRootActionTypes<A> = RootActionTypes | A;

export function resetAppState(): ResetAppState {
  return {
    type: RootActions.RESET_STATE,
  };
}
