export interface PopupsTheme {
  opacity: number;
  padding: number; // px
  borderRadius: number; // px
}

export default {
  opacity: 0.9,
  padding: 8,
  borderRadius: 4,
  very: 2,
} as PopupsTheme;
