export interface TagTheme {
  paddingX: any;
  paddingY: any;
  raisedBoxShadow: any;
}

export default {
  paddingX: 0.5,
  paddingY: 0.5,
  raisedBoxShadow: ({ theme }: any) => theme.accents.floatingShadow,
} as TagTheme;
