import React, { FunctionComponent } from 'react';
import { LOSS_ABILITY } from 'apps/investor/pages/routes.config';
import Header from 'ui/atoms/header';
import Spacer from 'ui/atoms/spacer';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import cx from 'ui/helper/prefixed-class-names';

const LossAbilityCard: FunctionComponent = () => {
  return (
    <BoxedContent releaseSpaceWhenTitleIsEmpty>
      <div className={cx('loss-ability-card')}>
        <Header size="small" spacing="none" breakWords={true}>
          <Translate name="lossAbilityCard.title" />
        </Header>
        <Spacer y={4} />
        <Translate
          name={`lossAbilityCard.content1`}
          args={[
            (_, key) => (
              <a href={LOSS_ABILITY.path} key={key}>
                <Translate name="lossAbilityCard.here" />
              </a>
            ),
          ]}
        />
        <br />
        <Translate name="lossAbilityCard.content2" />
      </div>
    </BoxedContent>
  );
};

export default LossAbilityCard;
