import { resolveColor } from 'src/ui/styles/utils/colors';
import styled, { css } from 'styled-components';

export const LanguagesContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  ${({ $active, theme }) =>
    $active &&
    css`
      color: ${theme.colors.primaryMain};
    `};

  &:hover {
    color: ${({ theme }) => resolveColor(theme, 'primary')};
  }
`;
