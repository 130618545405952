import { Colors, getColorYig } from 'ui/styles/utils/colors';
import styled, { css, DefaultTheme, keyframes, ThemedStyledProps } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';

const loaderSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const loaderDefaults = {
  speed: 0.6, // in s
};

interface LoadingSpinnerProps {
  $color?: Colors;
  $backgroundColor: Colors;
}

function getColor({ theme, $color, $backgroundColor }: ThemedStyledProps<LoadingSpinnerProps, DefaultTheme>) {
  const color = $color ? resolveColor(theme, $color) : getColorYig(theme, resolveColor(theme, $backgroundColor));

  return css`
    color: ${color};
  `;
}

export const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  ${getColor};
  position: absolute;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  pointer-events: auto;

  animation: ${loaderSpin} ${loaderDefaults.speed}s linear;
  animation-iteration-count: infinite;
`;
