import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Hint from 'ui/atoms/hint';
import makeForm from 'ui/forms';

export interface ForgotPasswordFields {
  email: string;
}

export interface ForgotPasswordProps {
  /** Additional classes. */
  className?: string;

  passwordResetEmailSent: boolean;

  email?: string;
}

export const ForgotPasswordForm = makeForm<{ email: string }>();

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = (props) => {
  const { className, passwordResetEmailSent, email: userEmail, ...restProps } = props;

  return (
    <div className={cx('forgot-password', className)} {...restProps}>
      {passwordResetEmailSent && (
        <>
          <Hint variant="success">
            <Translate name="forgotPassword.success" args={[userEmail]} />
          </Hint>
        </>
      )}
      {!passwordResetEmailSent && (
        <>
          <Translate as="p" name="forgotPassword.description" />
          <ForgotPasswordForm.Group required={true} name="email" simple={true}>
            <ForgotPasswordForm.Input autoFocus={true} />
            {ForgotPasswordForm.Validators.Email()}
          </ForgotPasswordForm.Group>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
