import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Button from 'ui/atoms/button';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';

export interface InvestmentAccessDeniedProps {
  /** Additional classes. */
  className?: string;

  investmentInvitationEmail?: string;

  logout: () => void;

  title?: ReactNode;
}

const InvestmentAccessDenied: FunctionComponent<InvestmentAccessDeniedProps> = (props) => {
  const { className, investmentInvitationEmail, logout = () => {}, title, ...restProps } = props;

  return (
    <NarrowContent>
      <BoxedContent title={title}>
        <div className={cx('investment-access-denied', className)} {...restProps}>
          <p>
            <Translate
              name="investmentInvitation.accessDeniedForUser"
              args={[(fallbackUser, key) => <strong key={key}>{investmentInvitationEmail || fallbackUser}</strong>]}
            />
          </p>
          <Button fluid={true} size="large" onClick={logout} variant="primary">
            <Translate name="common.logoutAndContinue" />
          </Button>
        </div>
      </BoxedContent>
    </NarrowContent>
  );
};

export default InvestmentAccessDenied;
