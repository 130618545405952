import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import Icon from 'ui/atoms/icon';
import Translate from 'ui/atoms/translate';
import TransferIdentifier from 'ui/atoms/transfer-identifier';
import StatusTag from 'ui/atoms/status-tag';

import { UITransfer } from 'ui/types/transfer';
import { transferStatusToVariant } from 'ui/helper/variant';
import Date from 'ui/atoms/date';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import DataRecordTable from 'libraries/data-record-table';
import Number from 'ui/atoms/number';
import { PaginationProps } from 'ui/molecules/pagination';

export interface TransferTableProps {
  className?: string;
  ordering?: DataRecordOrdering;
  onOrderBy?: (fieldName: string) => void;
  transfers?: UITransfer[];
  goToDetails: (transferId: string) => void;
  paginationProps?: PaginationProps;
}

const TransferTable: FunctionComponent<TransferTableProps> = (props) => {
  const { className, ordering, onOrderBy, transfers, goToDetails, paginationProps } = props;

  return (
    <div className={cx('transfer-table', className)}>
      <DataRecordTable
        onOrderBy={onOrderBy}
        ordering={ordering}
        data={transfers}
        paginationProps={paginationProps}
        cells={[
          {
            title: <Translate name="portfolio.time" />,
            render: ({ time }) => <Date showTime={true}>{time}</Date>,
            orderingFieldName: 'created_at',
          },
          {
            title: <Translate name="portfolio.asset" />,
            render: (transfer) => transfer.productName,
          },
          {
            title: <Translate name="portfolio.transaction" />,
            render: ({ isIssuance, fromWallet, fromAddress, toAddress, toWallet }) => (
              <TransferIdentifier
                isIssuance={isIssuance}
                fromAddress={fromAddress}
                fromWallet={fromWallet}
                toAddress={toAddress}
                toWallet={toWallet}
                short={true}
              />
            ),
          },
          {
            title: <Translate name="portfolio.amount" />,
            alignContent: 'right',
            render: (transfer) => <Number>{transfer.amount}</Number>,
            orderingFieldName: 'amount',
          },
          {
            title: <Translate name="common.status" />,
            render: ({ status }) => (
              <StatusTag variant={transferStatusToVariant(status)}>
                <Translate name={`portfolio.transferStatus.${status}`}>{status}</Translate>
              </StatusTag>
            ),
          },
          {
            title: '',
            render: ({ id }) => (
              <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
            ),
            alignContent: 'right',
          },
        ]}
      />
    </div>
  );
};

export default TransferTable;
