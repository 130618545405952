import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent, ModalFooterButtons } from 'ui/molecules/modal';
import Translate from 'ui/atoms/translate';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, AdminInvestmentDetails } from 'api';
import DatePicker from 'ui/atoms/date-picker';
import FormGroup from 'ui/molecules/form-group';
import Spacer from 'ui/atoms/spacer';
import { format } from 'date-fns';
import Hint from 'ui/atoms/hint';

export interface EditPaymentDateModalProps {
  /** Additional classes. */
  className?: string;

  paymentDate: Date | null | undefined;

  acceptedDate: Date | null | undefined;

  investmentId: string;

  setInvestment: (investment: AdminInvestmentDetails) => void;

  onHideModal: () => void;
}

const MS_TO_DAYS_MULTIPLIER = 86400000;

const msToDays = (date: Date) => Math.floor(date.getTime() / MS_TO_DAYS_MULTIPLIER);

const EditPaymentDateModal: FunctionComponent<EditPaymentDateModalProps> = ({
  paymentDate,
  acceptedDate,
  investmentId,
  onHideModal,
  setInvestment,
  className,
  ...restProps
}) => {
  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const [isValid, setIsValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [value, setValue] = useState<Date | null | undefined>(paymentDate);

  const editPaymentDate = useCallback(() => {
    withApi(async () => {
      try {
        const investment = await investmentsApi.adminInvestmentsPartialUpdate({
          id: investmentId,
          patchedAdminInvestmentDetailsRequest: {
            paymentDate: value,
          },
        });

        setSubmitted(true);
        setInvestment(investment);
      } catch (e) {
        console.error(e);
        setIsValid(false);
      }
    });
  }, [value, setSubmitted]);

  const handleChange = useCallback(
    (v: Date | null | undefined) => {
      setValue(v);
      if (v instanceof Date && acceptedDate instanceof Date) {
        setIsValid(!(msToDays(v) < msToDays(acceptedDate)));
      } else if (v instanceof Date && !(acceptedDate instanceof Date)) setIsValid(true);
      else setIsValid(false);
    },
    [acceptedDate, setValue, setIsValid],
  );

  useEffect(() => {
    handleChange(paymentDate);
  }, [handleChange, paymentDate]);

  return (
    <Modal onClose={onHideModal}>
      <ModalHeader>
        <Translate name="generalInvestmentDetails.editPaymentDate.title" />
      </ModalHeader>
      <ModalContent>
        {!submitted ? (
          <>
            <Translate name="generalInvestmentDetails.editPaymentDate.text" />
            <Spacer y={4} />
            <FormGroup
              label={<Translate name="generalInvestmentDetails.editPaymentDate.name" />}
              error={
                !isValid && acceptedDate ? (
                  <Translate
                    name="generalInvestmentDetails.editPaymentDate.error"
                    args={[format(acceptedDate, 'dd/MM/yyyy')]}
                  />
                ) : null
              }
            >
              <DatePicker initial={paymentDate} onChange={handleChange} />
            </FormGroup>
          </>
        ) : (
          <Hint variant="success">
            <Translate name="generalInvestmentDetails.editPaymentDate.success" />
          </Hint>
        )}
      </ModalContent>
      <ModalFooter>
        <ModalFooterButtons
          actionButtons={[
            {
              name: 'cancel',
              content: <Translate name="common.close" />,
              size: 'large',
              onClick: () => onHideModal(),
            },
            !submitted && {
              name: 'confirm',
              content: <Translate name="generalInvestmentDetails.editPaymentDate.confirm" />,
              variant: 'primary',
              type: 'submit',
              size: 'large',
              onClick: () => editPaymentDate(),
              disabled: !isValid,
              loading,
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default EditPaymentDateModal;
