import React, { FunctionComponent } from 'react';
import * as Styled from './styled';
import Translate from 'ui/atoms/translate';
import Text from 'ui/polymorphs/text';
import { useServerConfigSelector } from 'core/config/hooks';
import LossAbilityCard from 'subapps/investment/pages/investment/side-content/loss-ability-card';
import useRegister from 'subapps/investment/pages/register/hooks/use-register';
import { InvestorStepEnum } from 'api';
import Spacer from 'ui/atoms/spacer';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';

const SideContent: FunctionComponent = () => {
  const { config } = useServerConfigSelector();
  const { step } = useRegister();

  const isLargeDesktops = useIsMedia([DEVICES.largeDesktops]);

  return (
    <Styled.SideContent>
      <Text as="b" wordBreak="break-word">
        <Translate name="register.summary.header" />
      </Text>
      {isLargeDesktops && (
        <>
          <Spacer y={10} />
          <Styled.RegisterImage />
          <Spacer y={8} />
        </>
      )}
      <Spacer y={2} />
      <Text wordBreak="break-word">
        <Translate name="register.summary.content" />
      </Text>
      {config.features.ecsp && step === InvestorStepEnum.QUESTIONNAIRE && <LossAbilityCard />}
    </Styled.SideContent>
  );
};

export default SideContent;
