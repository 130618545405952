import { ColorVariants } from 'ui/styles/themes/color-variants';
import { getColorsMap, resolveColor } from 'ui/styles/utils/colors';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import type { Colors } from 'ui/styles/utils/colors';

export interface ListPointProps {
  $color?: Colors;
  $variant?: ColorVariants;
  $inverted?: boolean;
  $fitted?: boolean;
  $circular?: boolean;
}

function getDefaultStyle({ theme }: ThemedStyledProps<ListPointProps, DefaultTheme>) {
  return css<ListPointProps>`
    text-align: center;
    margin: 0 ${theme.lists.pointDistanceFromText}rem 0 0;
    border-radius: ${theme.common.borderRadius};
    line-height: 1;
    display: inline-block;
    font-weight: bold;
    padding: ${theme.lists.pointPadding};
    width: ${theme.lists.pointSize}em;
    height: ${theme.lists.pointSize}em;

    &:last-child {
      margin: 0;
    }
  `;
}

function getColor({ $color, theme }: ThemedStyledProps<ListPointProps, DefaultTheme>) {
  const { minor } = getColorsMap(theme, 'marker');
  const color = resolveColor(theme, $color);

  return css<ListPointProps>`
    color: ${minor};
    background-color: ${color};
  `;
}

function getFitted({ $fitted }: ThemedStyledProps<ListPointProps, DefaultTheme>) {
  if (!$fitted) return null;

  return css`
    margin: 0;
  `;
}

function getCircular({ $circular }: ThemedStyledProps<ListPointProps, DefaultTheme>) {
  if (!$circular) return null;

  return css`
    border-radius: 50%;
  `;
}

function getInverted({ theme, $inverted }: ThemedStyledProps<ListPointProps, DefaultTheme>) {
  if (!$inverted) return null;

  return css<ListPointProps>`
    box-shadow: ${theme.lists.pointShadow};
  `;
}

export const ListPoint = styled.img<ListPointProps>`
  ${getDefaultStyle};
  ${getColor};
  ${getFitted};
  ${getInverted};
  ${getCircular};
`;
