import rawConfig from 'core/config/local/config.json';
import extraConfig from 'core/config/local/extra-config';
import { PlatformType } from 'platforms';

const config: {
  platform?: PlatformType;
  sentry?: any;
  production?: boolean;
  externalInfoOrigins?: string[];
  passwordRequirements?: {
    zxcvbnWeak: number;
    zxcvbnStrong: number;
  };
  hubspotChatbotOnMobile?: boolean;
  keepGlobalUrlQueryKeys?: string[];
  enablePlatformSwitch?: boolean;
} = rawConfig;

declare global {
  interface Window {
    extraConfig: any;
  }
}

window.extraConfig = window.extraConfig || {};

Object.assign(config, window.extraConfig, extraConfig);

export const applicationConfig = {
  platform: config?.platform || 'cashlink',
  enablePlatformSwitch: config?.enablePlatformSwitch || false,
  keepGlobalUrlQueryKeys: ['tracking_id'],

  ...config,

  auth: {
    defaultRedirectRoute: '/',
  },
  passwordRequirements: {
    // amount of guesses
    // threshold below which password is too weak (also set in backend):
    zxcvbnWeak: config?.passwordRequirements?.zxcvbnWeak || Math.pow(10, 8),
    zxcvbnStrong: config?.passwordRequirements?.zxcvbnStrong || Math.pow(10, 12),
  },
  hubspotChatbotOnMobile: config?.hubspotChatbotOnMobile || false,
};

export default applicationConfig;
