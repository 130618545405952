import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import makeForm from 'ui/forms';

export interface EnterNewEmailProps {
  receiverEmail: string;

  onSubmit: (values: EnterNewEmailFields) => void;
}

export interface EnterNewEmailFields {
  email: string;
}

const EnterNewEmailForm = makeForm<EnterNewEmailFields>();

const EnterNewEmail: FunctionComponent<EnterNewEmailProps> = (props) => {
  const { receiverEmail, onSubmit = () => {} } = props;

  return (
    <>
      <p>
        <Translate name="investmentEnterNewEmail.enterNewEmailAddress" />
      </p>
      <EnterNewEmailForm onSubmit={onSubmit} initial={{ email: receiverEmail }} i18nKey="investmentEnterNewEmail">
        <EnterNewEmailForm.Group required={true} name="email">
          <EnterNewEmailForm.Input />
          {EnterNewEmailForm.Validators.Email()}
        </EnterNewEmailForm.Group>
        <EnterNewEmailForm.Submit size="large" fluid={true} variant="primary">
          <Translate name="investmentConfirmEmail.resendEmail" />
        </EnterNewEmailForm.Submit>
      </EnterNewEmailForm>
    </>
  );
};

export default EnterNewEmail;
