import Icon from 'ui/atoms/icon';
import { resolveColor, getColorsMap } from 'ui/styles/utils/colors';
import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';
import Select from 'ui/atoms/select';

export interface ProgressItemProps {
  $clickable?: boolean;
  $active?: boolean;
  $current?: boolean;
  $done?: boolean;
  $hover?: boolean;
}

export const ProgressSelect = styled(Select)`
  font-size: 14px;
  background-color: ${({ theme }) => resolveColor(theme, 'background')};
  outline: none;
  width: 100%;
`;

function getProgressStepsBackground({ theme, $active, $current }: ThemedStyledProps<ProgressItemProps, DefaultTheme>) {
  return css<ProgressItemProps>`
    &::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      z-index: -1;
      background-color: ${$active ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
      opacity: ${$active || $current ? '100%' : '15%'};
    }
  `;
}

function getProgressStepsBackgroundHover({
  theme,
  $done,
  $active,
  $hover,
  $clickable,
}: ThemedStyledProps<ProgressItemProps, DefaultTheme>) {
  if (!$hover || !$clickable) return null;

  return css<ProgressItemProps>`
    &::before {
      background-color: ${$clickable ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
      opacity: ${$done || $active || $clickable ? '100%' : '35%'};
    }
  `;
}

export const ProgressStepNumber = styled.span<ProgressItemProps>`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
  z-index: 1;
  cursor: pointer;

  ${({ theme, $active, $current, $hover }) => {
    const { minor } = getColorsMap(theme, 'marker');

    return css`
      color: ${$active || $hover ? minor : $current ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
    `;
  }}

  ${getProgressStepsBackground};
  ${getProgressStepsBackgroundHover};
`;

export const ProgressStepItem = styled.span<ProgressItemProps>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  color: ${({ theme, $active, $hover, $clickable }) =>
    $active || ($hover && $clickable) ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'not-allowed')};
  text-decoration: ${({ $clickable, $active, $current, $hover }) =>
    $clickable && ((!$active && !$current) || $hover) ? 'underline' : 'initial'};
  opacity: ${({ $clickable, $current, $active }) => ($clickable || ($current && !$active) ? '100%' : '50%')};
`;

export const ProgressStepTitle = styled.span`
  word-break: break-word;
  font-weight: bold;
`;

export const StepCount = styled.span`
  color: ${({ theme }) => resolveColor(theme, 'marker')};
  margin-right: 0.5rem;
`;

export const ProgressStepsMargin = styled.div`
  min-width: 72px;
`;

export const CheckIcon = styled(Icon)<ProgressItemProps>`
  ${({ theme, $done }) => {
    const { minor } = getColorsMap(theme, 'marker');

    return css`
      color: ${$done ? resolveColor(theme, 'white') : minor};
      fill: ${$done ? resolveColor(theme, 'white') : minor};
    `;
  }}
`;
