import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';
import Translate from 'ui/atoms/translate';
import Link from 'ui/atoms/link';
import * as Styled from './styled';

export interface DemoStickyMessageProps {
  /** Additional classes. */
  className?: string;
}

export const DemoStickyMessage: FunctionComponent<DemoStickyMessageProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <Styled.DemoStickyMessage variant="warning" className={cx('demo-sticky-message', className)} {...restProps}>
      <p>
        <Translate
          name="demo.banner"
          args={[
            (txt: string, key) => (
              <Link key={key} href={`https://mailchi.mp/cashlink/personal-consultation`} inline underline external>
                {txt}
              </Link>
            ),
          ]}
        />
      </p>
    </Styled.DemoStickyMessage>
  );
};

export default DemoStickyMessage;
