import { find } from 'lodash';

import { TokenTransfer, Wallet } from 'api';
import { UITransfer, UITransferStatusHistory, TransferStatus } from 'ui/types/transfer';
import { assertUnreachable } from 'ui/helper/unreachable';
import { convertTransferStatusFromApi, convertWalletTypeFromApi } from 'core/api/conversions';
import { getNetworkTypeName } from 'ui/helper/networks';

const ZERO_ADDRESS = '0x' + '0'.repeat(40);

const makeStatusHistory = (
  { fromNetworkAddress, createdAt, statusUpdates }: TokenTransfer,
  fromWallet?: Wallet,
): UITransferStatusHistory => {
  let tfaTime = undefined,
    tfaSuccess = undefined;
  let approverTime = undefined,
    approverSuccess = undefined;
  let completionTime = undefined,
    completionSuccess = undefined;

  for (const update of statusUpdates) {
    const status = convertTransferStatusFromApi(update.status);
    switch (status) {
      case TransferStatus.TFA_CONFIRMED: {
        tfaTime = update.createdAt;
        tfaSuccess = true;
        break;
      }
      case TransferStatus.TFA_REJECTED: {
        tfaTime = update.createdAt;
        tfaSuccess = false;
        break;
      }
      case TransferStatus.APPROVER_REJECTED: {
        approverTime = update.createdAt;
        approverSuccess = false;
        break;
      }
      case TransferStatus.PROCESSING: {
        approverTime = update.createdAt;
        approverSuccess = true;
        break;
      }
      case TransferStatus.COMPLETED: {
        if (!approverTime) {
          approverTime = update.createdAt;
          approverSuccess = true;
        }
        completionTime = update.createdAt;
        completionSuccess = true;
        break;
      }
      case TransferStatus.FAILED: {
        completionTime = update.createdAt;
        completionSuccess = false;
        break;
      }
      case TransferStatus.PENDING:
      case TransferStatus.TFA_REQUESTED:
        break;
      default:
        assertUnreachable(status);
    }
  }

  let hideTfa = !fromWallet?.managed;

  // TODO(mara-cashlink): multi-coin-wallets support -> equivalent for ZERO_ADDRESS for Stellar
  let hideApproval = fromNetworkAddress === ZERO_ADDRESS;

  // Never hide the reason why a TX failed
  hideTfa = hideTfa && tfaSuccess !== false;
  hideApproval = hideApproval && approverSuccess !== false;

  return {
    createdAt,
    tfaTime,
    tfaSuccess,
    approverTime,
    approverSuccess,
    completionTime,
    completionSuccess,
    hideTfa,
    hideApproval,
  };
};

const getWallet = (walletId: string | null, wallets: Wallet[]): Wallet | undefined => {
  if (walletId === null) return undefined;
  return find(wallets, { id: walletId });
};

export const transferToUI = (transfer: TokenTransfer, wallets: Wallet[]): UITransfer => {
  const { id, createdAt, amount, fromNetworkAddress, toNetworkAddress, status, productName, fromWalletId, toWalletId } =
    transfer;

  const fromWallet = getWallet(fromWalletId, wallets);
  const toWallet = getWallet(toWalletId, wallets);

  return {
    id,
    time: createdAt,
    productName,
    fromAddress: fromNetworkAddress,
    toAddress: toNetworkAddress,
    fromWallet: fromWallet && {
      ...fromWallet,
      type: convertWalletTypeFromApi(fromWallet.type),
    },
    toWallet: toWallet && {
      ...toWallet,
      type: convertWalletTypeFromApi(toWallet.type),
    },
    isIssuance: fromNetworkAddress === ZERO_ADDRESS,
    amount,
    status: convertTransferStatusFromApi(status),
    history: makeStatusHistory(transfer, fromWallet),
    networkName: getNetworkTypeName(transfer.tokenizationInfo.networkType),
  };
};
