import React, { FunctionComponent } from 'react';
import cx from 'ui/helper/prefixed-class-names';

import useForm from 'ui/hooks/use-form';
import { validateEmailAddress } from 'ui/helper/validators';

import Translate from 'ui/atoms/translate';
import Input from 'ui/atoms/input';
import Button from 'ui/atoms/button';
import Checkbox from 'ui/atoms/checkbox';
import FormGroup from 'ui/molecules/form-group';

import ServerError from 'ui/types/server-error';
import useTranslate from 'ui/hooks/use-translate';
import { handleError } from 'ui/helper/error-handling';
import { PlatformUrl } from 'ui/types/urls';
import Link from 'ui/atoms/link';
import * as Styled from './styled';

export interface InvestorRegistrationFields {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  consentPrivacy: boolean;
}

export interface DemoInvestorRegistrationFormProps {
  /** Additional classes. */
  className?: string;

  /** On submit callback */
  onSubmit?: (values: InvestorRegistrationFields) => void;

  /** Is the form being submitted? */
  loading?: boolean;

  /** error */
  error?: ServerError;
}

const DemoInvestorRegistrationForm: FunctionComponent<DemoInvestorRegistrationFormProps> = (props) => {
  const { className, error, onSubmit = () => {}, loading = false, ...restProps } = props;

  const [formState, { checkbox, text, submit }] = useForm<InvestorRegistrationFields>({
    validateFields: ['email', 'firstName', 'lastName', 'consentPrivacy'],
  });

  const { getErrorForField, getRemainingError } = handleError({
    error,
    translate: useTranslate(),
    specialCodes: {
      user_is_not_investor: {
        field: 'email',
        translateKey: 'errors.userIsNotInvestor',
      },
    },
  });

  return (
    <div className={cx('demo-issuer-registration-form', className)} {...restProps}>
      <p>
        <Translate name="demo.investorReg.text" args={[(txt, key) => <strong key={key}>{txt}</strong>]} />
      </p>

      <form noValidate autoComplete="off" {...submit(onSubmit)}>
        <FormGroup error={getErrorForField('forename')}>
          <Input label={<Translate name="demo.registration.labelFirstName" />} required={true} {...text('firstName')} />
        </FormGroup>

        <FormGroup error={getErrorForField('surname')}>
          <Input label={<Translate name="demo.registration.labelLastName" />} required={true} {...text('lastName')} />
        </FormGroup>

        <FormGroup error={getErrorForField('companyName')}>
          <Input label={<Translate name="demo.investorReg.labelCompanyName" />} {...text('companyName')} />
        </FormGroup>

        <FormGroup error={getErrorForField('email')}>
          <Input
            label={<Translate name="demo.investorReg.labelEmail" />}
            required={true}
            {...text('email', { validate: validateEmailAddress })}
          />
        </FormGroup>

        <FormGroup>
          <Checkbox {...checkbox('consentPrivacy')}>
            <Translate
              name="demo.registration.labelConsentPrivacy"
              args={[
                (txt, key) => (
                  <Link key={key} platformUrl={PlatformUrl.PRIVACY_POLICY} external>
                    {txt}
                  </Link>
                ),
              ]}
            />
          </Checkbox>
        </FormGroup>

        {((msg) => msg && <Styled.Error>{msg}</Styled.Error>)(getRemainingError())}

        <Button
          type="submit"
          size="large"
          variant="primary"
          fluid={true}
          disabled={!formState.isValid}
          loading={loading}
        >
          <Translate name="demo.registration.button" />
        </Button>
      </form>
    </div>
  );
};

export default DemoInvestorRegistrationForm;
