import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import NarrowContent from 'core/layout/content/narrow-content';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import PublicPage from 'core/auth/components/public-page';
import usePasswordStrength from 'hooks/use-password-strength';
import { PasswordStrength } from 'ui/atoms/strength-indicator';
import { UsersApi } from 'api/apis';
import { useApiCall } from 'hooks/use-api-call';
import useGoTo from 'hooks/use-go-to';
import makeForm from 'ui/forms';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import { AUTH_ROUTES } from 'core/auth/pages/routes.config';

interface ResetPasswordPageProps {
  token: string;
  userId: string;
}

interface ResetPasswordFields {
  newPassword: string;
  newPasswordRepeat: string;
}

const ResetPasswordForm = makeForm<ResetPasswordFields>();

const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = ({ token, userId }) => {
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>();

  const goToLogin = useGoTo(AUTH_ROUTES.login);

  const { error, loading, makeAuthenticatedApi, withApi } = useApiCall();

  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  const getPasswordStrength = usePasswordStrength();
  const getStrengthRef = useRef(getPasswordStrength);
  getStrengthRef.current = getPasswordStrength;

  const getPasswordStrengthValidation = useCallback(({ newPassword }: ResetPasswordFields) => {
    if (!newPassword) {
      setPasswordStrength(undefined);
      return;
    }
    const passwordStrength = getStrengthRef.current(newPassword);
    setPasswordStrength(passwordStrength || undefined);
  }, []);

  const onSubmit = useCallback(
    ({ newPassword }: ResetPasswordFields) => {
      withApi(async () => {
        await usersApi.usersPasswordResetConfirmationCreate({
          resetPasswordConfirmationRequest: {
            newPassword,
            token: token,
            id: userId,
          },
        });
        goToLogin();
      });
    },
    [withApi, usersApi, token, userId, goToLogin],
  );

  return (
    <PublicPage>
      <NarrowContent>
        <BoxedContent title={<Translate name="resetPassword.title" />}>
          <ResetPasswordForm onChange={getPasswordStrengthValidation} onSubmit={onSubmit} error={error}>
            <p>
              <Translate name={'resetPassword.description'} />
            </p>
            <ResetPasswordForm.PasswordGroup
              passwordStrength={passwordStrength}
              passwordField={{
                name: 'newPassword',
                label: <Translate name="resetPassword.setNewPassword" />,
              }}
              passwordRepeatField={{
                name: 'newPasswordRepeat',
                label: <Translate name="resetPassword.passwordRepeat" />,
              }}
            />
            <ResetPasswordForm.GenericErrorMessages />
            <ActionButtons>
              <Button variant="primary" size="large" loading={loading} type="submit">
                <Translate name="resetPassword.send" />
              </Button>
            </ActionButtons>
          </ResetPasswordForm>
        </BoxedContent>
      </NarrowContent>
    </PublicPage>
  );
};

export default ResetPasswordPage;
