import React from 'react';
import UIContextProvider from 'ui/platform';
import { PlatformContextProps } from 'ui/platform/types';
import App from './app';

const DefaultApp = ({ initialPlatform, initialTheme, initialLocales, initialImages }: PlatformContextProps) => (
  <UIContextProvider
    initialTheme={initialTheme}
    initialPlatform={initialPlatform}
    initialLocales={initialLocales}
    initialImages={initialImages}
  >
    <App />
  </UIContextProvider>
);

export default DefaultApp;
