export interface DropdownTheme {
  borderColor: any;
  borderFocusedColor: any;
  labelColor: any;
}

export default {
  labelColor: ({ theme }: any) => theme.colors.grayMain,
  borderColor: ({ theme }: any) => theme.colors.grayHover,
  borderFocusedColor: ({ theme }: any) => theme.colorVariants.primary,
} as DropdownTheme;
