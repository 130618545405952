import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';

export interface AddIdentificationUploadLegitimationProps {
  AddIdentificationForm: any;
}

const AddIdentificationUploadLegitimation: FunctionComponent<AddIdentificationUploadLegitimationProps> = (props) => {
  const { AddIdentificationForm } = props;

  return (
    <>
      <p>
        <strong>
          <Translate name="dashboardIssuerInvestorDetails.addIdentificationForm.subtitleUploadDocument" />
        </strong>
      </p>
      <AddIdentificationForm.Group name="legitimationProtocol" required={true}>
        <AddIdentificationForm.FileUpload />
        {AddIdentificationForm.Validators.FileSize(5000000)}
      </AddIdentificationForm.Group>
    </>
  );
};

export default AddIdentificationUploadLegitimation;
