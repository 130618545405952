import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

interface ModalFooterProps {
  onClose?: () => {};
  disableClose?: boolean;
  className?: string;
}

export const ModalFooter: FunctionComponent<ModalFooterProps> = ({ children, className }) => {
  return (
    <Styled.ModalFooter className={className} data-qa="modal-footer">
      {children}
    </Styled.ModalFooter>
  );
};

export default ModalFooter;
