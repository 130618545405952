import React, { FunctionComponent, ReactNode } from 'react';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi, AdminInvestmentsListRequest, InvestmentAcquisitionTypeRecipientEnum, AdminInvestment } from 'api';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'libraries/dashboard-empty-view';
import { FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import StudioInvestmentsTable from 'apps/issuer/shared/studio-investments-table';
import makeLink from 'helper/make-link';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { PaginationProps } from 'ui/molecules/pagination';

interface IssuerInvestmentsTableProps extends AdminInvestmentsListRequest {
  filters?: ReactNode;
  campaignId: string;
  forceDataLoad?: boolean;
  defaultOrdering: DataRecordOrdering;
  defaultLimit?: number;
  paginationProps?: PaginationProps;
}

const IssuerInvestmentsTable: FunctionComponent<IssuerInvestmentsTableProps> = WithDataRecord<
  AdminApi,
  AdminInvestment
>(AdminApi)(
  ({ data, ordering, onOrderBy, loading, campaignId, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.investmentDetails);
    const campaignLink = makeLink(INVESTMENT_ROUTES.campaign, { campaignId }, true);

    return (
      <>
        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
        <StudioInvestmentsTable
          loading={loading}
          emptyView={
            <IssuerEmptyView type={EmptyViewType.ISSUER_CAMPAIGN_DETAILS_INVESTMENTS} campaignLink={campaignLink} />
          }
          onOrderBy={onOrderBy}
          ordering={ordering}
          goToDetails={(productId: string, investmentId: string) => goTo({ productId, investmentId })}
          investments={(data || []).map((investment) => ({
            ...investment,
            acquisitionType: investment.acquisitionType || InvestmentAcquisitionTypeRecipientEnum.ISSUANCE,
          }))}
          paginationProps={paginationProps}
          noProduct
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminInvestmentsList({
      campaign: props.campaignId,
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default IssuerInvestmentsTable;
