import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';
import { resolveColor } from 'ui/styles/utils/colors';
import { query } from 'ui/styles/queries';

interface ControlProps {
  $isOpen?: boolean;
  $hasValue?: boolean;
  $hasError?: boolean;
  $hasLabelOutside?: boolean;
  $dark?: boolean;
  $radiusOpen?: string;
  $radiusClosed?: string;
}

interface MenuListProps {
  $dark?: boolean;
  $radius?: string;
}

function getControlOutline({ theme, $dark, $isOpen, $hasError }: ThemedStyledProps<ControlProps, DefaultTheme>) {
  return css<ControlProps>`
    outline: ${
      $dark
        ? `2px solid ${resolveColor(theme, 'marker')} !important;`
        : `1px solid ${resolveColor(theme, 'grayHover')} !important;`
    }}

    ${
      $isOpen &&
      css`
        outline: 2px solid ${resolveColor(theme, $dark ? 'marker' : 'primary')} !important;
      `
    }

    ${
      $hasError &&
      !$isOpen &&
      css`
        outline: 2px solid ${resolveColor(theme, 'redMain')} !important;
      `
    }

    &:hover {
      outline: 2px solid ${resolveColor(theme, $dark ? 'marker' : 'primary')} !important;
    }
  `;
}

function getBorderRadius({ $radiusOpen, $radiusClosed, $isOpen }: ThemedStyledProps<ControlProps, DefaultTheme>) {
  if ($isOpen) {
    return css<ControlProps>`
      border-top-right-radius: ${$radiusOpen || '8px'};
      border-top-left-radius: ${$radiusOpen || '8px'};
    `;
  }

  return css<ControlProps>`
    border-radius: ${$radiusClosed || '8px'};
  `;
}

export const Control = styled.div<ControlProps>`
  ${getBorderRadius};
  ${getControlOutline};
`;

export const MenuList = styled.div<MenuListProps>`
  margin-top: -1rem;
  border: 2px solid ${({ theme, $dark }) => resolveColor(theme, $dark ? 'marker' : 'primary')};
  border-bottom-right-radius: ${({ $radius }) => $radius || '8px'};
  border-bottom-left-radius: ${({ $radius }) => $radius || '8px'};
  border-top: none;
  background-color: ${({ theme, $dark }) => resolveColor(theme, $dark ? 'background' : 'white')};
  width: calc(100% + 4px);
  margin-left: -2px;
`;

export const Separator = styled.div<{ $dark?: boolean }>`
  background-color: ${({ theme, $dark }) => resolveColor(theme, $dark ? 'marker' : 'grayHover')};
  margin: 0 1rem;
  width: calc(100% - 2rem);
  height: 1px;
`;

export const InputContainer = styled.div`
  height: 60px;
  max-width: calc(100% - 32px);
`;

export const ValueContainer = styled.div<{ $hasLabel?: boolean }>`
  padding-left: 6px;

  ${({ $hasLabel }) =>
    !$hasLabel &&
    css`
      margin-top: 20px;
    `};
`;

export const Label = styled.div<ControlProps>`
  color: ${({ theme }) => resolveColor(theme, 'grayMain')};
  font-size: 0.75rem;
  padding: 10px 16px 0px;

  ${query.phone`
    font-size: 11px;
  `};

  ${(props) =>
    props.$isOpen &&
    css`
      color: ${({ theme }) => resolveColor(theme, 'primary')};
    `}

  ${(props) =>
    !props.$isOpen &&
    !props.$hasValue &&
    css`
      font-size: 1rem;
      transform: translate(0, 9px);

      ${query.phone`
        font-size: 0.875rem;
        transform: translate(0, 11px);
      `};
    `}

    ${(props) =>
    props.$hasError &&
    css`
      color: ${({ theme }) => resolveColor(theme, 'redMain')};
    `}

    ${(props) =>
    props.$hasLabelOutside &&
    css`
      color: ${({ theme }) => resolveColor(theme, 'black')};
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0;
      margin-bottom: 4px;
    `}
`;

export const Option = styled.div`
  [role='option'] {
    background: none;
    padding-left: 16px;
    color: ${({ theme }) => resolveColor(theme, 'black')};
  }

  [aria-disabled='true'] {
    color: ${({ theme }) => resolveColor(theme, 'grayMain')};
    pointer-events: none;
  }

  &:focus,
  &:active,
  &:hover {
    color: ${({ theme }) => resolveColor(theme, 'primary')};

    [role='option'] {
      background: none;
      color: ${({ theme }) => resolveColor(theme, 'primary')};
    }

    [aria-disabled='true'] {
      color: ${({ theme }) => resolveColor(theme, 'grayMain')};
    }
  }
`;
