import React, { FunctionComponent } from 'react';
import useRegisterIdentification from 'subapps/investment/pages/register/steps/identification-step/use-register-identification';
import IdentificationStep from 'libraries/wizard/components/identification-step';

const Identification: FunctionComponent = () => {
  const props = useRegisterIdentification();

  return <IdentificationStep {...props} />;
};

export default Identification;
