import React, { ComponentType, ElementType } from 'react';

export interface As<C extends React.ElementType = ElementType> {
  as?: C;
}

export type PropsToOmit<C extends React.ElementType, P> = keyof (As<C> & P);

/**
 * Utility for defining polymorphic component props
 * @param C: a generic that will infer the underlying DOM element/React component and enforce it's props
 * @param A: the specific elements that the component can morph into, this is required, since we don't want any element to be used as any element,
 *           for example, a button element as an h1, that would be just wrong. Polymorphic components should be very limited
 * @param Props: the components internal props, without the underlying DOM element/React component's props
 */
export type PolymorphicComponentProp<C extends React.ElementType, Props = {}> = React.PropsWithChildren<Props & As<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

export type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

export type PolymorphicComponentPropWithRef<C extends React.ElementType, Props = {}> = PolymorphicComponentProp<
  C,
  Props
> & { ref?: PolymorphicRef<C> };

export type PolymorphicChildComponentProps<C extends ElementType, P = {}> = React.PropsWithChildren<P> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, P>>;

/**
 * DEPRECATED: do not use this anymore, it's not typesafe
 * Returns a createElement() type based on the props of the Component.
 * Useful for calculating what type a component should render as.
 *
 * @param {function} Component A function or ReactClass.
 * @param {object} props A ReactElement props object
 * @param {ElementType} defaultAs the default prop
 * @returns {string|function} A ReactElement type
 */
const getElementType = <P extends As>(
  Component: ComponentType<P>,
  props: P,
  defaultAs: ElementType = 'div',
): ElementType => {
  if ((props as any).href) return 'a';
  return props.as || defaultAs;
};

export default getElementType;
