import React, { FunctionComponent } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import CopyButton from 'ui/molecules/copy-button';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { AdminBundesbankSettlementInstruction } from 'src/api';
import Header from 'src/ui/atoms/header';
import BoxedContent from 'src/ui/molecules/boxed-content';
import Grid, { Col } from 'src/ui/atoms/grid';
import PlaceholderFallback from 'src/ui/atoms/placeholder-fallback';
import Spacer from 'src/ui/atoms/spacer';

export interface CustomersProps {
  settlement: AdminBundesbankSettlementInstruction;
}

const Customers: FunctionComponent<CustomersProps> = (props) => {
  const { settlement } = props;

  const isPhone = useIsMedia(DEVICES.phone);

  return (
    <>
      <Grid>
        <Col width={6}>
          <Header size="small" spacing="medium">
            <Translate name="triggerSolutionsCustomers.titleBeneficiary" />
          </Header>
        </Col>
        <Col width={6}>
          <Header size="small" spacing="medium">
            <Translate name="triggerSolutionsCustomers.titleOrdering" />
          </Header>
        </Col>
      </Grid>
      <Grid spacing="large">
        <Col width={6}>
          <BoxedContent releaseSpaceWhenTitleIsEmpty={true} fullHeight>
            <InfoField title={<Translate name="triggerSolutionsCustomers.sourceWalletAddress" />}>
              <PlaceholderFallback>
                {settlement.sourceWallet?.ethInfo?.address && (
                  <>
                    <span>{settlement.sourceWallet.ethInfo.address} </span>
                    <CopyButton valueToCopy={settlement.sourceWallet.ethInfo.address} />
                  </>
                )}
              </PlaceholderFallback>
            </InfoField>
            <Spacer y={8} />
            <InfoGrid columns={isPhone ? 1 : 2} truncate={true}>
              <InfoField title={<Translate name="triggerSolutionsCustomers.receiverBankBic" />}>
                <PlaceholderFallback>
                  {settlement.sourceInvestor.target2Bic && <span>{settlement.sourceInvestor.target2Bic} </span>}
                </PlaceholderFallback>
              </InfoField>
              <InfoField title={<Translate name="triggerSolutionsCustomers.beneficiaryCustomerId" />}>
                <PlaceholderFallback>
                  {settlement.sourceInvestor.id && (
                    <>
                      <span>{settlement.sourceInvestor.id}</span>
                      <CopyButton valueToCopy={settlement.sourceInvestor.id} />
                    </>
                  )}
                </PlaceholderFallback>
              </InfoField>
            </InfoGrid>
          </BoxedContent>
        </Col>
        <Col width={6}>
          <BoxedContent releaseSpaceWhenTitleIsEmpty={true} fullHeight>
            <InfoField title={<Translate name="triggerSolutionsCustomers.targetWalletAddress" />}>
              <PlaceholderFallback>
                {settlement.targetWallet?.ethInfo?.address && (
                  <>
                    <span>{settlement.targetWallet.ethInfo.address} </span>
                    <CopyButton valueToCopy={settlement.targetWallet.ethInfo.address} />
                  </>
                )}
              </PlaceholderFallback>
            </InfoField>
            <Spacer y={8} />
            <InfoGrid columns={isPhone ? 1 : 2} truncate={true}>
              <InfoField title={<Translate name="triggerSolutionsCustomers.payerBankBic" />}>
                <PlaceholderFallback>
                  {settlement.targetInvestor.target2Bic && <span>{settlement.targetInvestor.target2Bic} </span>}
                </PlaceholderFallback>
              </InfoField>
              <InfoField title={<Translate name="triggerSolutionsCustomers.orderingCustomerId" />}>
                <PlaceholderFallback>
                  {settlement.targetInvestor.id && (
                    <>
                      <span>{settlement.targetInvestor.id}</span>
                      <CopyButton valueToCopy={settlement.targetInvestor.id} />
                    </>
                  )}
                </PlaceholderFallback>
              </InfoField>
            </InfoGrid>
          </BoxedContent>
        </Col>
      </Grid>
    </>
  );
};

export default Customers;
