import IssuerInvestmentsTable from 'apps/issuer/shared/investments-table';
import IssuerInvestmentsFilters from 'apps/issuer/shared/investments-table/investments-filters';
import React, { FunctionComponent } from 'react';
export interface InvestmentTableViewProps {
  forceDataLoad?: boolean;
  investorId: string;
}

const InvestmentTableView: FunctionComponent<InvestmentTableViewProps> = (props) => {
  const { forceDataLoad, investorId } = props;

  return (
    <IssuerInvestmentsTable
      investor={investorId}
      filters={<IssuerInvestmentsFilters />}
      defaultOrdering={{ direction: 'desc', fieldName: 'signed_date' }}
      forceDataLoad={forceDataLoad}
    />
  );
};

// TODO Try avoiding the repeated rerenders in the parent component (IssuerInvestorDetailsPage), rather than
// using React.memo to avoid the unnecessary calls to the server that the table is making
const MemoizedInvestmentTableView = React.memo(InvestmentTableView);
export default MemoizedInvestmentTableView;
