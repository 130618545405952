// foo_bar_baz_123_asd => fooBarBaz123Asd
import { camelCase } from 'change-case';

export const snakeToCamelCaseWithNumbers = (x: string) => camelCase(x).replace(/_/g, '');

export const camelCaseToSnake = (x: string) => {
  return x.replace(/([A-Z])/g, function (match) {
    return '_' + match.toLowerCase();
  });
};
