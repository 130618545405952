import React, { FunctionComponent, ReactNode, useState } from 'react';
import * as Styled from './styled';
import cx from 'ui/helper/prefixed-class-names';
import Icon from 'ui/atoms/icon';
import Spacer from 'ui/atoms/spacer';
import { Colors } from 'ui/styles/utils/colors';
import Text from 'ui/polymorphs/text';
import { HorizontalAlign } from 'ui/types/align';

export interface DropdownProps {
  id: string;
  /** Title */
  title: ReactNode | string;
  /** Additional classes */
  className?: string;
  /** Dynamic arrow is pointed down when the dropdown is close and up when it's open */
  hasDynamicArrow?: boolean;
  /** Padding of title */
  padding?: string;
  /** Color of icon and label */
  color?: Colors;
  /** Position where the dropdown menu should appear */
  openPosition?: HorizontalAlign;
}

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const { children, id, title, padding, hasDynamicArrow, color = 'white', openPosition = 'left', className } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styled.Dropdown className={cx('dropdown', className)}>
      <Styled.Title
        aria-haspopup="true"
        aria-expanded={isOpen}
        id={id}
        $padding={padding || '0.5'}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
      >
        <Text color={color}>{title}</Text>
        <Spacer x={2} />
        {hasDynamicArrow ? (
          isOpen ? (
            <Icon name="angle-up" color={color} />
          ) : (
            <Icon name="angle-down" color={color} />
          )
        ) : (
          <Styled.Arrow />
        )}
      </Styled.Title>

      {isOpen && (
        <Styled.ItemsContainer aria-labelledby={id} $openPosition={openPosition} onClick={() => setIsOpen(false)}>
          {children}
        </Styled.ItemsContainer>
      )}
    </Styled.Dropdown>
  );
};

export default Dropdown;
