import React, { FunctionComponent, useCallback } from 'react';
import FormGroup from 'ui/molecules/form-group';
import { useFilterOption } from 'ui/molecules/filtering/hooks';
import type { FilterOptionSelectionProps } from 'ui/molecules/filtering/types';
import PredefinedInputOptions from 'ui/molecules/predefined-input-options';
import { compact } from 'lodash';

export const FilterSingleOption: FunctionComponent<FilterOptionSelectionProps> = (props) => {
  const { value: selection, setValue } = useFilterOption(props, []);

  const selectedIndices = compact(
    props.options.map(({ value }, index) => (selection.includes(value) ? index + 1 : undefined)),
  ).map((index) => index - 1);

  const onOptionSelected = useCallback(
    (value) => {
      const isSelected = selection.includes(value);
      setValue(isSelected ? [] : [value]);
    },
    [selection],
  );

  return (
    <FormGroup label={props.label}>
      <PredefinedInputOptions
        options={props.options}
        activeOptionIndices={selectedIndices}
        optionLabel={({ label }) => label}
        onOptionSelected={({ value }) => onOptionSelected(value)}
      />
    </FormGroup>
  );
};

export default FilterSingleOption;
