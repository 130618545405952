import React, { FunctionComponent } from 'react';
import { Investor } from 'api/models';
import BoxedContent from 'ui/molecules/boxed-content';
import { extractNaturalAndLegalFromPerson } from 'core/api/conversions';
import { PersonType } from 'ui/types/person';
import useUserData from './use-user-data';
import InvestorUserInformation from './user-information';
import { UserDataSet } from 'ui/types/user-data-set';
import Translate from 'src/ui/atoms/translate';

interface UserDataViewProps {
  investor: Investor;
}

const UserDataView: FunctionComponent<UserDataViewProps> = (props) => {
  const { investor } = props;

  const { naturalPerson, legalPerson } = extractNaturalAndLegalFromPerson(investor?.person);

  const rootPerson = investor?.person.personType === PersonType.Legal ? legalPerson : naturalPerson;

  const userSections = useUserData({ rootPerson, naturalPerson, legalPerson });

  return (
    <>
      {userSections.map((userDataSet, index) => {
        if (!userDataSet) {
          return null;
        }
        return (
          <BoxedContent
            title={<Translate name={`dashboardInvestorSettings.${userDataSet.key}.title`} />}
            releaseSpaceWhenTitleIsEmpty={true}
            key={index}
          >
            <InvestorUserInformation
              userDataSet={userDataSet as UserDataSet}
              userType={'investor'}
              personType={investor?.person.personType as any} // TODO(mara-cashlink): replace any
              naturalPerson={naturalPerson as any} // TODO(mara-cashlink): replace any
              legalPerson={legalPerson as any} // TODO(mara-cashlink): replace any
            />
          </BoxedContent>
        );
      })}
    </>
  );
};

export default UserDataView;
