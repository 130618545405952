import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useApiCall } from '../hooks/use-api-call';
import FilterProvider from 'ui/molecules/filtering';
import type { FilterValues } from 'ui/molecules/filtering/types';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { BaseAPI } from 'api';

type Class<T> = new (...args: any[]) => T;

export interface WithDataNoPaginationProps<Data> {
  data?: Data[];
  ordering?: DataRecordOrdering;
  loadData: () => void;
  loading: boolean;
}

const WithDataNoPagination =
  <Api extends BaseAPI, Data>(api: Class<Api>) =>
  <P extends object>(
    Component: ComponentType<P & WithDataNoPaginationProps<Data>>,
    apiFetch: (api: Api, props: P) => Promise<Array<Data>>,
  ) => {
    const WithDataNoPaginationComponent: FunctionComponent<P> = (props) => {
      const [filters, setFilters] = useState({
        userFilters: {} as FilterValues,
      });

      const [responseData, setResponseData] = useState<Array<Data>>();

      const [loading, setLoading] = useState(true);

      const { makeAuthenticatedApi, withApi } = useApiCall(true);

      const authenticatedApi: Api = useMemo(() => makeAuthenticatedApi(api), [makeAuthenticatedApi]);

      const filter = useCallback((props: FilterValues) => {
        setFilters((prev) => ({ ...prev, offset: 0, userFilters: props }));
      }, []);

      const load = useCallback(() => {
        withApi(async ({ takeLatest }) => {
          setLoading(true);

          const responseData = await apiFetch(authenticatedApi, {
            ...(filters.userFilters as P),
          });

          takeLatest(() => {
            setResponseData(responseData);
            setLoading(false);
          });
        });
      }, [filters, authenticatedApi, withApi]);

      useEffect(load, [load]);

      return (
        <>
          <FilterProvider
            filter={filter}
            entries={responseData?.length}
            activeFilters={filters.userFilters}
            loading={loading}
          >
            <Component {...props} data={responseData} loadData={load} loading={loading} />
          </FilterProvider>
        </>
      );
    };

    return WithDataNoPaginationComponent;
  };

export default WithDataNoPagination;
