import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 -6px 8px 0 rgba(#dedede, 0.5);
`;
