import React, { FunctionComponent } from 'react';
import { QuestionnaireQuestions } from 'libraries/wizard/components/questionnaire/questionnaire-form/types';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import SplitFormSegmentDesktop from './split-form-segment-desktop';
import SplitFormSegmentMobile from './split-form-segment-mobile';

export interface SplitFormSegmentProps {
  questions: QuestionnaireQuestions[];
  segmentId?: string;
  isEcsp: boolean;
}

const SplitFormSegment: FunctionComponent<SplitFormSegmentProps> = ({ questions, segmentId, isEcsp }) => {
  const isDesktop = useIsMedia(DEVICES.desktops);

  if (isDesktop) {
    return <SplitFormSegmentDesktop questions={questions} segmentId={segmentId} isEcsp={isEcsp} />;
  }

  return <SplitFormSegmentMobile questions={questions} segmentId={segmentId} isEcsp={isEcsp} />;
};

export default SplitFormSegment;
