import { useCallback, useEffect, useState } from 'react';

import useApiCall from './use-api-call';

import { TokenTransfersApi, TokenTransfer, TokenTransferStatusEnum } from '../api';
import { AnyServerError } from './use-api-error';

interface Filter {
  tokenId?: string;
  status?: TokenTransferStatusEnum[];
  walletIds?: string[];
}

export const useTransfers = (
  filter?: Filter,
): {
  transfers: TokenTransfer[];
  loading: boolean;
  error?: AnyServerError;
} => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);

  const [transfers, setTransfers] = useState<TokenTransfer[] | undefined>(undefined);

  const load = useCallback(() => {
    (async () => {
      const transfersApi: TokenTransfersApi = makeAuthenticatedApi(TokenTransfersApi);

      await withApi(async ({ takeLatest }) => {
        // TODO(mara-cashlink): tokenTransferList delivers paginated response - cut off without ui around it
        let { results: transfers = [] } = await transfersApi.tokenTransfersList({ limit: 100 });

        // apply filters
        if (filter?.tokenId) {
          transfers = transfers.filter((transfer) => transfer.tokenId === filter?.tokenId);
        }
        const statusFilter = filter?.status;
        if (statusFilter) {
          transfers = transfers.filter((transfer) => statusFilter.includes(transfer.status));
        }
        const walletFilter = filter?.walletIds;
        if (walletFilter) {
          transfers = transfers.filter(
            (transfer) =>
              (transfer.fromWalletId && walletFilter.includes(transfer.fromWalletId)) ||
              (transfer.toWalletId && walletFilter.includes(transfer.toWalletId)),
          );
        }
        takeLatest(() => setTransfers(transfers));
      });
    })();
  }, [filter, makeAuthenticatedApi, withApi]);

  // TODO(mara-cashlink): was deleting finishedWithoutLoad correct?
  useEffect(() => {
    load();
  }, [load, filter]);

  return {
    transfers: transfers || [],
    loading: loading,
    error,
  };
};

export const useTransfer = (id: string) => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);
  const [transfer, setTransfer] = useState<TokenTransfer | undefined>(undefined);

  const load = useCallback(() => {
    (async () => {
      const transfersApi: TokenTransfersApi = makeAuthenticatedApi(TokenTransfersApi);

      await withApi(async () => {
        const transfer = await transfersApi.tokenTransfersRetrieve({ id });
        setTransfer(transfer);
      });
    })();
  }, [id, makeAuthenticatedApi, withApi]);

  useEffect(() => load(), [load]);

  return {
    transfer,
    error,
    loading,
  };
};
