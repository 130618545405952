import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'ui/atoms/button';
import makeLink from 'helper/make-link';
import useApiCall from 'hooks/use-api-call';
import { InvestmentCalculation, Invitation, IssuerInvestmentRules } from 'api/models';
import { AdminApi } from 'api/apis';
import { toMoney, toNumber } from 'ui/helper/money';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import CreateInvestmentInvitationModal, {
  CreateInvestmentInvitationFields,
} from './create-investment-invitation-modal';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { useActiveProduct } from 'apps/issuer/hooks';
import Tooltip from 'src/ui/atoms/tooltip';
import Translate from 'src/ui/atoms/translate';

interface IssuerCreateInvestmentInvitationProps {
  onCloseAfterSuccess?: () => void;
  productId?: string;
}

const IssuerCreateInvestmentInvitation: FunctionComponent<IssuerCreateInvestmentInvitationProps> = (props) => {
  const { onCloseAfterSuccess = () => {}, children, productId } = props;

  const { loading, token, reloadProducts } = useActiveProduct(productId);

  const [isCreateInvitationOpen, setIsCreateInvitationOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const {
    withApi: withCalcApi,
    loading: loadingCalcApi,
    makeAuthenticatedApi: makeAuthenticatedCalcApi,
    error: calculationError,
  } = useApiCall(false);

  const {
    withApi: withTokensInvitationApi,
    loading: loadingTokensInvitationApi,
    makeAuthenticatedApi: makeAuthenticatedTokensInvitationApi,
    error: createTokensInvitationApiError,
  } = useApiCall(false);

  const [values, setValues] = useState<CreateInvestmentInvitationFields>();

  const [investmentInvitation, setInvestmentInvitation] = useState<Invitation>();

  const [calculations, setCalculations] = useState<InvestmentCalculation>();
  const [ruleSets, setRuleSets] = useState<IssuerInvestmentRules[]>();

  const tokensCalcApi: AdminApi = useMemo(() => makeAuthenticatedCalcApi(AdminApi), [makeAuthenticatedCalcApi]);

  const invitationRulesApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const tokensInvitationApi: AdminApi = useMemo(
    () => makeAuthenticatedTokensInvitationApi(AdminApi),
    [makeAuthenticatedTokensInvitationApi],
  );

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const openInvestmentInvitationCreation = async () => {
    setInvestmentInvitation(undefined);
    setValues(undefined);
    setIsCreateInvitationOpen(true);
    loadData();
  };

  const createInvestmentInvitation = useCallback(
    (values) => {
      if (!token) return;
      withTokensInvitationApi(async () => {
        const investmentInvitation = await tokensInvitationApi.adminTokensInvitationCreate({
          id: token.id,
          investmentInvitationCreationRequest: {
            distributionPlatformId: values.distributionPlatform,
            email: values.email,
            name: values.fullName,
            maxNumberOfTokens: values.maxNumberOfTokens,
            pricePerToken: toMoney(values.pricePerToken),
            ruleType: values.ruleType,
            country: values.country,
            currency: values.currency,
          },
        });
        setInvestmentInvitation(investmentInvitation);
        reloadProducts();
      });
    },
    [withTokensInvitationApi, token],
  );

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!token?.id) return null;

      const rules = await invitationRulesApi.adminTokensInvitationRulesList({
        id: token.id,
      });

      setRuleSets(rules);
    });
  }, [withApi, token, invitationRulesApi, setRuleSets]);

  const getCalculations = useCallback(() => {
    withCalcApi(async (utilities) => {
      if (!token) return;

      const { takeLatest } = utilities;

      const calculations = await invitationRulesApi.adminTokensCalculationsRetrieve({
        id: token.id,
        requestedTokens: values?.maxNumberOfTokens ? toNumber(values?.maxNumberOfTokens) : undefined,
        pricePerToken: values?.pricePerToken ? toNumber(values?.pricePerToken) : undefined,
      });

      takeLatest(() => {
        setCalculations(calculations);
      });
    });
  }, [withCalcApi, tokensCalcApi, token, values]);

  const onCreateInvitationFormChanged = (values: CreateInvestmentInvitationFields) => {
    setValues(values);
  };

  useEffect(() => {
    if (!token?.id) return;
    getCalculations();
  }, [token, values, getCalculations]);

  useEffect(() => {
    if (answerErrors) setIsLoading(false);
  }, [answerErrors]);

  useEffect(() => {
    setIsLoading(loading || (isCreateInvitationOpen && (!token?.id || !calculations)));
  }, [token, calculations, loading, isCreateInvitationOpen]);

  if (!token?.id) return null;

  return (
    <>
      <>
        {isCreateInvitationOpen && token && (
          <CreateInvestmentInvitationModal
            loading={loadingCalcApi || loadingTokensInvitationApi}
            onCloseAfterSuccess={() => {
              onCloseAfterSuccess();
              setIsCreateInvitationOpen(false);
            }}
            open={true}
            onSubmit={createInvestmentInvitation}
            onCancelTriggered={() => setIsCreateInvitationOpen(false)}
            onChange={onCreateInvitationFormChanged}
            currencies={token.currencies}
            maxNumberOfTokensUpperLimit={calculations && calculations.requestedTokens + calculations.remainingTokens}
            distributionPlatforms={token.distributionPlatforms}
            showShareInfo={token.showShareInfo}
            backToBackMinPrice={token.backToBackMinPrice}
            valuation={(calculations && calculations.preMoneyValuation) || undefined}
            investmentTotal={calculations && calculations.investmentTotal}
            ruleSets={ruleSets}
            calculationError={!!calculationError}
            createInvitationError={createTokensInvitationApiError}
            investmentInvitationLink={
              investmentInvitation &&
              makeLink(
                INVESTMENT_ROUTES.investment,
                {
                  invitationId: investmentInvitation.id,
                },
                true,
              )
            }
          />
        )}

        {!token ||
          !token.id ||
          (token.remainingAmount > 0 && (
            <Button
              variant="primary"
              loading={isLoading}
              disabled={!token || !token.id}
              onClick={openInvestmentInvitationCreation}
              error={answerErrors}
            >
              {children}
            </Button>
          ))}
        {token && token.remainingAmount <= 0 && (
          <Tooltip maxWidth={188} content={<Translate name="campaignInvitation.noUnitsAllocation" />}>
            <Button variant="primary" disabled={true}>
              {children}
            </Button>
          </Tooltip>
        )}
      </>
    </>
  );
};

export default IssuerCreateInvestmentInvitation;
