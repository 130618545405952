import React, { createContext } from 'react';
import { StepEnum, InvestorStepEnum } from 'api';

export interface WizardContextProps {
  changeStep: (step: StepEnum | StepEnum[] | InvestorStepEnum | InvestorStepEnum[] | undefined) => void;
  finalize: (access?: string) => void;
  nextStep: () => void;
  lastRevisitableStep: () => StepEnum | InvestorStepEnum | undefined;
  DefaultPageTitle: () => JSX.Element;
  isTransferInvitation: boolean;
  loading: boolean;
  isStepDone: boolean;
  isStepRevisitable: boolean;
  resourceId: string;
  investmentId: string;
  isRegisterOnlyFlow: boolean;
  onCloseProcessOverviewOverlay: (invitationId: string) => void;
  showProcessOverview: boolean;
}

const WizardContext = createContext<WizardContextProps>({
  changeStep: (step: StepEnum | StepEnum[] | InvestorStepEnum | InvestorStepEnum[] | undefined) => {},
  finalize: (access?: string) => {},
  nextStep: () => {},
  lastRevisitableStep: () => undefined,
  DefaultPageTitle: () => <p></p>,
  isTransferInvitation: false,
  loading: false,
  isStepDone: false,
  isStepRevisitable: false,
  resourceId: '',
  investmentId: '',
  isRegisterOnlyFlow: false,
  onCloseProcessOverviewOverlay: (invitationId: string) => {},
  showProcessOverview: false,
});

export default WizardContext;
