import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import usePlatformImage from 'ui/hooks/use-platform-image';
import PrepareAssetHint from 'subapps/investment/pages/investment/wizard-steps/wallet/prepare-asset/prepare-asset-hint';
import { camelCase } from 'change-case';
import PrepareAssetTransaction from 'subapps/investment/pages/investment/wizard-steps/wallet/prepare-asset/prepare-asset-transaction';
import useApiCall from 'hooks/use-api-call';
import { InvitationsApi, TwoFactorAuthApi } from 'api/apis';
import { AssetPreparationStatus } from 'ui/types/asset-preparation';
import { converAssetPreparationStatusFromApi } from 'core/api/conversions';
import LoadingRing from 'ui/atoms/loading-ring';
import useInvestment from 'hooks/use-investment';
import useInterval from 'ui/hooks/use-interval';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import { InvitationTypeEnum } from 'ui/types/invitation-type';
import WizardContext from 'libraries/wizard/wizard-context';
import WizardHeader from 'libraries/wizard/wizard-header';
import Header from 'ui/atoms/header';

const PrepareAssetStep: FunctionComponent<{}> = () => {
  const { investmentId, resourceId: invitationId, loading, nextStep } = useContext(WizardContext);

  const [assetPreparationStatus, setAssetPreparationStatus] = useState<AssetPreparationStatus | null>(null);

  const { investment, loading: investmentLoading } = useInvestment(investmentId);
  const productName = investment?.productName;

  const { invitation } = useInvestmentInvitation(invitationId);
  const isTransferInvitation: boolean = invitation?.invitationType === InvitationTypeEnum.TRANSFER_INVITATION;

  const [twoFactorRequestId, setTwoFactorRequestId] = useState<string | null>(null);
  const imgTfaDone = usePlatformImage('tfaDone');
  const imgTfaPending = usePlatformImage('tfaPending');

  const { withApi, makeAuthenticatedApi, loading: apiLoading } = useApiCall();

  const {
    withApi: withStatusRetrieveApi,
    makeAuthenticatedApi: makeAuthenticatedStatusRetrieveApi,
    loading: statusRetrieveApiLoading,
  } = useApiCall();

  const invitationsApi: InvitationsApi = useMemo(() => makeAuthenticatedApi(InvitationsApi), [makeAuthenticatedApi]);
  const invitationsStatusRetrieveApi: InvitationsApi = useMemo(
    () => makeAuthenticatedStatusRetrieveApi(InvitationsApi),
    [makeAuthenticatedStatusRetrieveApi],
  );

  const twoFactorAuthApi: TwoFactorAuthApi = useMemo(
    () => makeAuthenticatedApi(TwoFactorAuthApi),
    [makeAuthenticatedApi],
  );

  const transactionStateImage: string | undefined = useMemo(() => {
    switch (assetPreparationStatus) {
      case AssetPreparationStatus.STARTED:
        return imgTfaPending;
      case AssetPreparationStatus.FINISHED:
        return imgTfaDone;
      case AssetPreparationStatus.REJECTED:
        return undefined;
      // TODO(mara-cashlink): delete ERROR case?
      // case AssetPreparationStatus.ERROR:
    }
  }, [assetPreparationStatus, imgTfaDone, imgTfaPending]);

  const onRestartTransaction = useCallback(() => {
    withApi(async () => {
      await invitationsApi.invitationsAssetPreparationDestroy({
        id: invitationId,
      });
      const { status, twoFactorRequestId } = await invitationsApi.invitationsAssetPreparationUpdate({
        id: invitationId,
      });
      setAssetPreparationStatus(status && converAssetPreparationStatusFromApi(status));
      setTwoFactorRequestId(twoFactorRequestId);
    });
  }, [withApi, invitationsApi, invitationId]);

  const onCancelTransaction = useCallback(
    (twoFactorRequestId: string) => {
      withApi(async () => {
        await twoFactorAuthApi.twoFactorAuthRequestsCancelCreate({
          id: twoFactorRequestId,
        });
        setAssetPreparationStatus(AssetPreparationStatus.REJECTED);
      });
    },
    [withApi, twoFactorAuthApi, invitationId],
  );

  const onContinue = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const onReceiveAssets = useCallback(() => {
    withApi(async () => {
      const { status, twoFactorRequestId } = await invitationsApi.invitationsAssetPreparationUpdate({
        id: invitationId,
      });
      setAssetPreparationStatus(status && converAssetPreparationStatusFromApi(status));
      setTwoFactorRequestId(twoFactorRequestId);
    });
  }, [withApi, invitationsApi, invitationId]);

  const loadStatus = useCallback(() => {
    withStatusRetrieveApi(async () => {
      const { status, twoFactorRequestId } = await invitationsStatusRetrieveApi.invitationsAssetPreparationRetrieve({
        id: invitationId,
      });
      setAssetPreparationStatus(status && converAssetPreparationStatusFromApi(status));
      setTwoFactorRequestId(twoFactorRequestId);
    });
  }, [withStatusRetrieveApi, invitationsStatusRetrieveApi, converAssetPreparationStatusFromApi]);

  useEffect(() => {
    loadStatus();
  }, [loadStatus]);

  useInterval({
    onInterval: loadStatus,
    shouldStart: assetPreparationStatus === AssetPreparationStatus.STARTED,
    shouldClear: assetPreparationStatus !== AssetPreparationStatus.STARTED,
  });

  const transactionStateAction:
    | {
        name: string;
        onAction: () => void;
      }
    | undefined = useMemo(() => {
    switch (assetPreparationStatus) {
      case AssetPreparationStatus.STARTED:
        return undefined;
      case AssetPreparationStatus.FINISHED:
        return {
          name: 'continue',
          onAction: onContinue,
        };
      case AssetPreparationStatus.REJECTED:
        return {
          name: 'restart',
          onAction: onRestartTransaction,
        };
      // TODO(mara-cashlink): delete ERROR case?
      // case AssetPreparationStatus.ERROR:
    }
    return undefined;
  }, [assetPreparationStatus, onRestartTransaction, onContinue]);

  if (
    loading ||
    investmentLoading ||
    (!productName && !isTransferInvitation) ||
    (statusRetrieveApiLoading && !assetPreparationStatus)
  )
    return <LoadingRing />;

  return (
    <>
      <WizardHeader />
      <Header spacing="xlarge">
        {assetPreparationStatus ? (
          <Translate name={`prepareAssetStep.title.${camelCase(assetPreparationStatus)}`} args={[productName]} />
        ) : (
          <Translate name={`prepareAssetStep.title.notStarted`} />
        )}
      </Header>
      {!assetPreparationStatus && (
        <>
          <PrepareAssetHint />
          <ActionButtons>
            <Button variant="primary" fluid={true} onClick={onReceiveAssets} loading={apiLoading}>
              <Translate name="prepareAssetStep.action.receiveAssets" />
            </Button>
          </ActionButtons>
        </>
      )}
      {assetPreparationStatus && (
        <>
          <PrepareAssetTransaction
            assetPreparationStatus={assetPreparationStatus}
            image={transactionStateImage}
            onCancel={twoFactorRequestId ? () => onCancelTransaction(twoFactorRequestId) : undefined}
          />
          {transactionStateAction && (
            <ActionButtons>
              <Button
                variant="primary"
                loading={apiLoading}
                fluid={true}
                onClick={() => transactionStateAction.onAction()}
              >
                <Translate name={`prepareAssetStep.action.${transactionStateAction.name}`} />
              </Button>
            </ActionButtons>
          )}
        </>
      )}
    </>
  );
};

export default PrepareAssetStep;
