import React, { FunctionComponent, useCallback, useContext } from 'react';
import InvestmentQuestionnaireForm from './questionnaire-form';
import Translate from 'ui/atoms/translate';
import { Investor, Money, QuestionnaireQuestion, SurveyWaiverTypeEnum } from 'api';
import {
  EducationOccupationFields,
  QuestionnaireAnswers,
  QuestionnairePagesSegments,
} from './questionnaire-form/types';
import { AnyServerError } from 'hooks/use-api-error';
import WizardContext from 'libraries/wizard/wizard-context';
import Header from 'ui/atoms/header';

interface QuestionnaireProps {
  loading: boolean;
  onEditQuestions: () => Promise<void> | void;
  initialAnswers: QuestionnaireAnswers[];
  prefilledQuestions: QuestionnaireQuestion[] | null | undefined;
  surveyAnswers: { [key: string]: Object } | undefined;
  investor: Investor | null | undefined;
  error: AnyServerError | undefined;
  onSubmitAnswers: (
    values: {
      answers: QuestionnaireAnswers[];
      educationOccupation: EducationOccupationFields;
    },
    shouldFinalize: boolean,
  ) => Promise<void>;
  initialEducationOccupation: EducationOccupationFields | undefined;
  editQuestions: boolean;
  questionnairePagesSegments: QuestionnairePagesSegments;
  setEditQuestions: (v: boolean) => void;
  isEcsp: boolean;
  questionnairePage: number;
  setQuestionnairePage: (v: number) => void;
  onGoBackQuestionnaire: () => void;
  noAnswers?: boolean;
  savedNetWorth?: Money | null;
  onGoBackSummary?: () => void;
  surveyWaiverType?: SurveyWaiverTypeEnum;
  isNetWorthSuitable?: boolean;
}

const QuestionnaireStep: FunctionComponent<QuestionnaireProps> = ({
  loading,
  onEditQuestions,
  initialAnswers,
  prefilledQuestions,
  investor,
  error,
  onSubmitAnswers,
  initialEducationOccupation,
  questionnairePagesSegments,
  questionnairePage,
  setQuestionnairePage,
  editQuestions,
  onGoBackQuestionnaire,
  isEcsp,
}) => {
  const title = <Translate name="questionnaire.title" />;

  const { resourceId } = useContext(WizardContext);

  const onSubmit = useCallback(
    (
      values: { answers: QuestionnaireAnswers[]; educationOccupation: EducationOccupationFields },
      shouldFinalize: boolean = false,
    ) => {
      onSubmitAnswers(values, shouldFinalize);
    },
    [questionnairePage, resourceId, investor?.id, isEcsp],
  );

  if (!prefilledQuestions) return null;

  return (
    <>
      <Header size="large" spacing="xlarge">
        {title}
      </Header>
      <InvestmentQuestionnaireForm
        loading={loading}
        error={error}
        onSubmit={onSubmit}
        questions={prefilledQuestions}
        initialAnswers={initialAnswers}
        initialEducationOccupation={initialEducationOccupation}
        editQuestions={editQuestions}
        onEditQuestions={onEditQuestions}
        pageNumber={questionnairePage}
        setPageNumber={setQuestionnairePage}
        questionnairePagesSegments={questionnairePagesSegments}
        investorId={investor?.id}
        goBack={onGoBackQuestionnaire}
        isEcsp={isEcsp}
      />
    </>
  );
};

export default QuestionnaireStep;
