import { ThemeColor } from 'ui/styles/types';

export interface ModalTheme {
  headerBackgroundColor: ThemeColor;
  shadow: string;
  backdropOpacity: number;
}

export default {
  headerBackgroundColor: '#ffffff',
  shadow:
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14)',
  backdropOpacity: 0.3,
} as ModalTheme;
