import React, { ReactNode } from 'react';
import ServerError from '../types/server-error';
import Translate from '../atoms/translate';

type SpecialCodes = {
  [code: string]: {
    field: string;
    translateKey?: string;
    custom?: (code: any) => ReactNode;
  };
};
type Options = {
  error?: ServerError | null;
  specialCodes?: SpecialCodes;
  translate: any;
};

export const handleError = (opts: Options) => {
  const { error, specialCodes, translate } = opts;

  const nop = {
    getErrorForField: () => undefined,
    getRemainingError: () => undefined,
  };

  if (!error) {
    return nop;
  }
  let handled = false;
  const errorsByField: { [field: string]: ReactNode } = {};

  for (const field of error.errorFields) {
    // TODO use field error message or code to get better message here
    errorsByField[field] = <Translate name="errors.invalidValue" />;
  }
  let obj: any;
  for (obj of error.errorCodes) {
    const code = typeof obj === 'string' ? obj : obj.code;
    if (!specialCodes || !specialCodes[code]) continue;
    const { field, translateKey, custom } = specialCodes[code];
    if (translateKey) {
      errorsByField[field] = <Translate name={translateKey} />;
    } else if (custom) {
      errorsByField[field] = custom(obj);
    }
  }

  const getErrorForField = (field: string): ReactNode | undefined => {
    const res = errorsByField[field];
    if (res) handled = true;
    return res;
  };

  const getRemainingError = (): ReactNode | undefined => {
    if (!handled) {
      console.error(error);
      return error.getMessage(translate);
    }
    return undefined;
  };

  return { getRemainingError, getErrorForField };
};
