import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import Translate from 'ui/atoms/translate';
import { toNumber } from 'ui/helper/money';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Date from 'ui/atoms/date';
import DateAtom from 'ui/atoms/date';
import {
  IssuerInvestmentStatusDescription,
  IssuerInvestmentStatusIndicator,
} from 'src/apps/issuer/shared/issuer-investment-status';
import Section from 'ui/atoms/section';
import Currency from 'ui/atoms/currency';
import {
  AdminApi,
  AdminInvestmentDetails,
  CancellationImpedimentsEnum,
  CountryEnum,
  FrontendStatusEnum,
  InvestmentAcquisitionTypeRecipientEnum,
  InvestmentCancellationEnum,
  Money,
} from 'api';
import Button from 'ui/atoms/button';
import EditPaymentDateModal from 'apps/issuer/pages/investment-details/general-information/edit-payment-date-modal';
import CopyButton from 'ui/molecules/copy-button';
import Hint from 'ui/atoms/hint';
import ActionButtons from 'ui/molecules/action-buttons';
import InvestmentCancellationModal, { CancellationModalType } from 'apps/issuer/shared/investment-cancellation-modal';
import useApiCall from 'hooks/use-api-call';
import Spacer from 'ui/atoms/spacer';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import Tooltip from 'ui/atoms/tooltip';
import { pascalCase } from 'change-case';

export interface GeneralInvestmentDetailsProps {
  /** Primary content. */
  investorName?: string;
  investmentId: string;
  signedDate: Date;
  acceptedDate?: Date | null;
  paymentDate?: Date | null;
  investorCountry?: CountryEnum;
  status?: FrontendStatusEnum;
  entryType?: string;
  transferReference?: string;
  transactionUrl?: string;
  /** Additional classes. */
  className?: string;
  investmentTotal: Money;
  investmentTotalFulfilled?: Money;
  tokenPrice?: Money;
  remainingAmount: number;
  acquisitionType?: InvestmentAcquisitionTypeRecipientEnum;
  setInvestment: (investment: AdminInvestmentDetails) => void;
  canCancelReasons: InvestmentCancellationEnum[];
  cancellationImpediments: CancellationImpedimentsEnum[];
  cancellationDeadline: Date | null;
  cancellationDate: Date | null;
  loadData: () => void;
  hasManageDataPermission: boolean;
  hasManageApprovalsPermission: boolean;
}

// TODO(geforcefan): remove minimumTicketSizeAlternative translation, params needed for rule types
const GeneralInvestmentDetails: FunctionComponent<GeneralInvestmentDetailsProps> = (props) => {
  const {
    investorName,
    investmentId,
    acceptedDate,
    signedDate,
    paymentDate,
    status,
    entryType,
    transactionUrl,
    investmentTotal,
    investmentTotalFulfilled,
    tokenPrice,
    remainingAmount,
    acquisitionType,
    setInvestment,
    canCancelReasons,
    cancellationImpediments,
    cancellationDeadline,
    cancellationDate,
    loadData,
    hasManageDataPermission,
    hasManageApprovalsPermission,
  } = props;

  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);

  const [editPaymentDetailsOpen, setEditPaymentDetailsOpen] = useState<boolean>(false);

  const [errorCancellation, setErrorCancellation] = useState(false);
  const [successCancellation, setSuccessCancellation] = useState(false);

  const isPhone = useIsMedia(DEVICES.phone);

  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const hasInvestmentAmountChanged = (): boolean => {
    return toNumber(investmentTotal) !== toNumber(investmentTotalFulfilled);
  };

  const canCancelInvestment: boolean = useMemo(
    () =>
      (!!canCancelReasons?.length &&
        canCancelReasons.includes(InvestmentCancellationEnum.REJECTION) &&
        hasManageApprovalsPermission) ||
      (canCancelReasons.includes(InvestmentCancellationEnum.CANCELLATION) && hasManageDataPermission),
    [JSON.stringify(canCancelReasons), hasManageDataPermission, hasManageApprovalsPermission],
  );

  const hasCancellationImpediments: boolean = useMemo(
    () => !!cancellationImpediments.length && hasManageDataPermission,
    [JSON.stringify(cancellationImpediments), hasManageDataPermission],
  );

  const onCancelInvestment = useCallback(() => {
    if (!canCancelReasons?.length) return;
    const cancelReason = canCancelReasons.includes(InvestmentCancellationEnum.REJECTION)
      ? InvestmentCancellationEnum.REJECTION
      : InvestmentCancellationEnum.CANCELLATION;

    withApi(async () => {
      try {
        await adminApi.adminInvestmentsCancellationCreate({
          id: investmentId,
          investmentCancellationRequest: {
            reason: cancelReason,
          },
        });
        setSuccessCancellation(true);
        loadData();
      } catch (e) {
        setErrorCancellation(true);
        console.error(e);
      }
    });
  }, [investmentId, JSON.stringify(canCancelReasons)]);

  useEffect(() => {
    if (cancellationModalOpen) {
      setSuccessCancellation(false);
      setErrorCancellation(false);
    }
  }, [cancellationModalOpen]);

  return (
    <>
      <Section>
        <InfoField title={<Translate name="generalInvestmentDetails.title" />}>
          <Section>
            <Translate name="generalInvestmentDetails.investmentId" />
            <span>: {investmentId} </span>
            <CopyButton valueToCopy={investmentId} />
          </Section>
        </InfoField>
        <InfoGrid columns={isPhone ? 1 : 3} truncate={true}>
          {investorName && (
            <InfoField title={<Translate name="generalInvestmentDetails.investorName" truncate={true} />}>
              {investorName}
            </InfoField>
          )}
          <InfoField title={<Translate name="generalInvestmentDetails.investmentTotal" truncate={true} />}>
            {investmentTotal && <Currency>{investmentTotal}</Currency>}
            {investmentTotalFulfilled && hasInvestmentAmountChanged() && (
              <>
                {' ('}
                <Currency>{investmentTotalFulfilled}</Currency>
                {')'}
              </>
            )}
          </InfoField>
          {tokenPrice && (
            <InfoField title={<Translate name="generalInvestmentDetails.unitPrice" truncate={true} />}>
              <Currency decimals={'*'}>{tokenPrice}</Currency>
            </InfoField>
          )}
          <InfoField title={<Translate name="generalInvestmentDetails.unitNumber" />} truncate={true}>
            {remainingAmount}
          </InfoField>
          <InfoField title={<Translate name="entryType.label" />} truncate={true}>
            <Translate name={`entryType.${entryType}`} />
          </InfoField>
          <InfoField title={<Translate name="generalInvestmentDetails.signedDate" truncate={true} />}>
            <Date>{signedDate}</Date>
          </InfoField>
          <InfoField title={<Translate name="generalInvestmentDetails.acceptedDate" truncate={true} />}>
            <PlaceholderFallback>{acceptedDate && <Date>{acceptedDate}</Date>}</PlaceholderFallback>
          </InfoField>
          <InfoField title={<Translate name="generalInvestmentDetails.paymentDate" truncate={true} />}>
            <PlaceholderFallback>
              {paymentDate && (
                <>
                  <Date>{paymentDate}</Date>
                  <Spacer x={2} />
                  {hasManageDataPermission && !cancellationDate && (
                    <Button
                      icon="edit"
                      color="primary"
                      variant="link"
                      onClick={() => setEditPaymentDetailsOpen(!editPaymentDetailsOpen)}
                      inline
                    />
                  )}
                </>
              )}
            </PlaceholderFallback>
          </InfoField>
          {status && (
            <InfoField title={<Translate name="generalInvestmentDetails.status" truncate={true} />}>
              <IssuerInvestmentStatusIndicator status={status} />
            </InfoField>
          )}
        </InfoGrid>
      </Section>
      {status && (
        <IssuerInvestmentStatusDescription
          status={status}
          transactionUrl={transactionUrl}
          signedDate={acquisitionType === InvestmentAcquisitionTypeRecipientEnum.HANDOVER ? signedDate : null}
          investmentTotalFulfilled={
            status === FrontendStatusEnum.SUCCESS_PARTIALLY_TRANSFERED ? investmentTotalFulfilled : null
          }
        />
      )}
      {canCancelInvestment && cancellationDeadline && (
        <Hint variant="warning">
          <Translate
            name="generalInvestmentDetails.cancellationDate"
            args={[<DateAtom>{cancellationDeadline}</DateAtom>]}
          />
        </Hint>
      )}
      {canCancelInvestment && !hasCancellationImpediments && (
        <ActionButtons>
          <Button variant="link" onClick={() => setCancellationModalOpen(true)}>
            <Translate
              name={
                canCancelReasons.includes(InvestmentCancellationEnum.REJECTION)
                  ? 'generalInvestmentDetails.rejectInvestmentButton'
                  : 'generalInvestmentDetails.cancelInvestmentButton'
              }
            />
          </Button>
        </ActionButtons>
      )}
      {hasCancellationImpediments && (
        <ActionButtons>
          <Tooltip
            maxWidth={188}
            content={
              <Translate
                name={`generalInvestmentDetails.cancellationImpediment${pascalCase(cancellationImpediments[0])}`}
              />
            }
          >
            <Button variant="primary" disabled>
              <Translate name={'generalInvestmentDetails.cancelInvestmentButton'} />
            </Button>
          </Tooltip>
        </ActionButtons>
      )}
      {cancellationModalOpen && (
        <InvestmentCancellationModal
          onCancelInvestment={onCancelInvestment}
          loading={loading}
          onClose={() => setCancellationModalOpen(false)}
          successCancellation={successCancellation}
          errorCancellation={errorCancellation}
          type={
            canCancelReasons.includes(InvestmentCancellationEnum.REJECTION)
              ? CancellationModalType.REJECTION
              : CancellationModalType.CANCELLATION
          }
        />
      )}
      {editPaymentDetailsOpen && (
        <EditPaymentDateModal
          investmentId={investmentId}
          acceptedDate={acceptedDate}
          paymentDate={paymentDate}
          setInvestment={setInvestment}
          onHideModal={() => setEditPaymentDetailsOpen(false)}
        />
      )}
    </>
  );
};

export default GeneralInvestmentDetails;
