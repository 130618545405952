import React, { useCallback, FunctionComponent, ReactNode } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import Translate from 'ui/atoms/translate';
import * as Styled from './styled';
import YearlyIncome from './yearly-income';
import LiquidAssets from './liquid-assets';
import YearlyLiabilities from './yearly-liabilities';
import { NetworthAnswerRequest } from 'api';
import { LosabilityQuestions, useLossAbilityCalculator } from './hooks';

export interface LossAbilityCalculatorProps {
  actionButtons: ReactNode;
  onUpdateAnswers: (netWorthAnswers: NetworthAnswerRequest[]) => void;
  shouldResetValues?: boolean;
}

export const MAX_VALUE = 100000000000;

const LossAbilityCalculator: FunctionComponent<LossAbilityCalculatorProps> = ({
  actionButtons,
  onUpdateAnswers,
  shouldResetValues,
}) => {
  const {
    lossabilityAnswers,
    setLosabilityAnswers,
    setYearlyIncome,
    setLiquidAssets,
    setYearlyLiabilities,
    updateAnswer,
    getCalculatedNetWorth,
  } = useLossAbilityCalculator(onUpdateAnswers);

  const updateYearlyIncomeAnswers = useCallback(
    (netLaborIncome: number, interestIncome: number, dividentsIncome: number, rentIcome: number) => {
      updateAnswer(LosabilityQuestions.YearlyNetLaborIncome, netLaborIncome);
      updateAnswer(LosabilityQuestions.YearlyInterestIncome, interestIncome);
      updateAnswer(LosabilityQuestions.YearlyDividendsAndCapitalIncome, dividentsIncome);
      updateAnswer(LosabilityQuestions.YearlyRentAndLeaseIncome, rentIcome);

      setLosabilityAnswers(lossabilityAnswers);
    },
    [lossabilityAnswers],
  );

  const updateLiquidAssetsAnswers = useCallback(
    (savings: number, exchangeSecurities: number, fundAssets: number) => {
      updateAnswer(LosabilityQuestions.GiroAndSavings, savings);
      updateAnswer(LosabilityQuestions.ExchangeTradedSecurities, exchangeSecurities);
      updateAnswer(LosabilityQuestions.FundAssets, fundAssets);

      setLosabilityAnswers(lossabilityAnswers);
    },
    [lossabilityAnswers],
  );

  const updateYearlyLiabilitiesAnswers = useCallback(
    (
      housekeeping: number,
      rentAndMortgage: number,
      redemption: number,
      insurance: number,
      supplyService: number,
      subscriptions: number,
      taxes: number,
    ) => {
      updateAnswer(LosabilityQuestions.YearlyHousekeepingCosts, housekeeping);
      updateAnswer(LosabilityQuestions.YearlyRentAndMortgagePayments, rentAndMortgage);
      updateAnswer(LosabilityQuestions.YearlyRedemptionCostsForCredits, redemption);
      updateAnswer(LosabilityQuestions.YearlyInsuranceContributions, insurance);
      updateAnswer(LosabilityQuestions.YearlyCostsForSupplyServices, supplyService);
      updateAnswer(LosabilityQuestions.YearlySubscriptionPayments, subscriptions);
      updateAnswer(LosabilityQuestions.Taxes, taxes);

      setLosabilityAnswers(lossabilityAnswers);
    },
    [lossabilityAnswers],
  );

  return (
    <>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <Translate name="lossAbility.heading" />
        <Styled.Hr />
        <YearlyIncome
          onChangeYearlyIncome={(netLaborIncome, interestIncome, dividentsIncome, rentIcome) => {
            updateYearlyIncomeAnswers(netLaborIncome, interestIncome, dividentsIncome, rentIcome);
          }}
          onChangeTotalSum={(sum) => setYearlyIncome(sum)}
          shouldResetValues={shouldResetValues}
        />
        <Styled.Hr />
        <LiquidAssets
          onChangeLiquidAssets={(savings, exchangeSecurities, fundAssets) => {
            updateLiquidAssetsAnswers(savings, exchangeSecurities, fundAssets);
          }}
          onChangeTotalSum={(sum) => setLiquidAssets(sum)}
          shouldResetValues={shouldResetValues}
        />
        <Styled.Hr />
        <YearlyLiabilities
          onChangeYearlyLiabilities={(
            housekeeping,
            rentAndMortgage,
            redemption,
            insurance,
            supplyService,
            subscriptions,
            taxes,
          ) => {
            updateYearlyLiabilitiesAnswers(
              housekeeping,
              rentAndMortgage,
              redemption,
              insurance,
              supplyService,
              subscriptions,
              taxes,
            );
          }}
          onChangeTotalSum={(sum) => setYearlyLiabilities(sum)}
          shouldResetValues={shouldResetValues}
        />
        <Styled.Hr />
        <Styled.NetWorthContainer>
          <Translate name="lossAbility.netWorth" />
          <Styled.NetWorthInput
            type="number"
            textAlign="right"
            height="small"
            value={getCalculatedNetWorth()}
            isCurrency
            disabled
          />
        </Styled.NetWorthContainer>
        {actionButtons}
      </BoxedContent>
    </>
  );
};

export default LossAbilityCalculator;
