import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { getColorYig, resolveColor } from 'ui/styles/utils/colors';
import Padding from 'ui/types/padding';
import Margin from 'ui/types/margin';
import Spacing from 'ui/types/spacing';
import Size from 'ui/types/size';
import TextAlign from 'ui/types/text-align';
import { ColorVariants } from 'ui/styles/themes/color-variants';
import type { Colors } from 'ui/styles/utils/colors';

interface SegmentProps {
  $color?: Colors;
  $variant?: ColorVariants;
  $unrounded?: boolean;
  $breakWords?: boolean;
  $padded?: Padding;
  $margin?: Margin;
  $spacing?: Spacing;
  $fullHeight?: boolean;
  $compact?: boolean;
  $inverted?: boolean;
  $basic?: boolean;
  $raised?: boolean;
  $fontSize?: Size;
  $textAlign?: TextAlign;
}

const segmentDefaults = {
  lineWidth: 0.2, // in em
  borderWidth: '1px',
  borderColor: '#dee2e6',
};

function getPaddings({ theme, $padded }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if ($padded) {
    const paddingX = `${theme.padding[$padded] * theme.segment.paddingY}rem`;
    const paddingY = `${theme.padding[$padded] * theme.segment.paddingX}rem`;
    return css<SegmentProps>`
      padding: ${$padded === 'none' ? '0' : `${paddingY} ${paddingX}`};
    `;
  }
  return css`
    padding: ${theme.segment.paddingY}rem ${theme.segment.paddingX}rem;
  `;
}

function getMargins({ theme, $margin, $spacing }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  return css<SegmentProps>`
    margin: ${theme.segment.marginY}rem ${theme.segment.marginX}rem;

    ${$margin &&
    css<SegmentProps>`
      margin: ${$margin === 'none'
        ? '0'
        : `${theme.margin[$margin] * theme.segment.marginY}rem ${theme.margin[$margin] * theme.segment.marginX}rem`};
    `}

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    ${$spacing &&
    css<SegmentProps>`
      &:not(:last-child):not(.compact) {
        margin-bottom: ${theme.spacing[$spacing]}rem;
      }
    `}
  `;
}

function getBorder({ theme, $unrounded }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  return css<SegmentProps>`
    border: ${segmentDefaults.borderWidth} solid ${segmentDefaults.borderColor};
    border-radius: ${$unrounded ? '0' : theme.common.borderRadius};
  `;
}

function getBreakWords({ $breakWords }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$breakWords) return null;

  return css`
    word-break: break-all;

    .table {
      word-break: normal;
    }
  `;
}

function getFullHeight({ $fullHeight }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$fullHeight) return null;

  return css`
    height: 100%;
  `;
}

function getBasic({ $basic }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$basic) return null;

  return css`
    border: none !important;
    background: transparent !important;
    color: inherit !important;
    box-shadow: none !important;
  `;
}

function getCompact({ theme, $compact, $spacing }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$compact) return null;

  return css<SegmentProps>`
    display: inline-block;
    ${$spacing &&
    `
        &:not(:last-child) {
          margin-right: ${theme.spacing[$spacing]}rem;
        }
        &:not(:first-child) {
          margin-left: ${theme.spacing[$spacing]}rem;
        }
      `}
  `;
}

function getRaised({ theme, $raised }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$raised) return null;

  return css`
    @include box;
    border: 0;
    box-shadow: ${theme.segment.raisedBoxShadow};
  `;
}

function getInverted({ theme, $inverted }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$inverted) return null;

  const borderColor = resolveColor(theme, segmentDefaults.borderColor);
  const colorYig = getColorYig(theme, borderColor);

  return css`
    background-color: ${borderColor};
    &,
    & * {
      color: ${colorYig};
    }
  `;
}

function getFontSize({ theme, $fontSize }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  if (!$fontSize) return null;

  return css<SegmentProps>`
    font-size: ${theme.typography.sizes[$fontSize]};
  `;
}

function getColor({ theme, $color, $variant, $inverted }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  const color = $color || $variant;

  if (!color) return null;

  const themeColor = resolveColor(theme, color);
  const colorYig = getColorYig(theme, themeColor);

  return css`
    border-color: ${themeColor};
      ${
        $inverted &&
        css<SegmentProps>`
          background-color: ${themeColor};
          &,
          & * {
            color: ${colorYig};
          }
        `
      }
    }
  `;
}

function getTextAlign({ $textAlign }: ThemedStyledProps<SegmentProps, DefaultTheme>) {
  return css`
    text-align: ${$textAlign};
  `;
}

export const Segment = styled.div<SegmentProps>`
  background: #fff;

  ${getPaddings};
  ${getMargins};
  ${getBorder};
  ${getBreakWords};
  ${getFullHeight};
  ${getBasic};
  ${getCompact};
  ${getRaised};
  ${getInverted};
  ${getFontSize};
  ${getColor};
  ${getTextAlign};
`;
