import React, { ReactElement, ReactNode, useCallback } from 'react';
import * as Styled from './styled';
import Size from 'ui/types/size';

export interface PredefinedInputOptionsProps<T> {
  options: T[];
  optionLabel: (option: T) => ReactNode;

  /** Additional classes. */
  className?: string;

  onOptionSelected: (selectedInput: T) => void;

  activeOptionIndex?: number;

  activeOptionIndices?: number[];

  size?: Size;
}

const PredefinedInputOptions: <T>(props: PredefinedInputOptionsProps<T>) => ReactElement | null = (props) => {
  const {
    className,
    options,
    onOptionSelected,
    optionLabel,
    activeOptionIndex,
    activeOptionIndices = [],
    size = 'large',
    ...restProps
  } = props;

  const onSelectOption = useCallback(
    (optionIndex: number) => {
      //setActiveOptionIndex(optionIndex);
      onOptionSelected(options[optionIndex]);
    },
    [onOptionSelected, options],
  );

  return (
    <Styled.PredefinedInputOption {...restProps}>
      {options.map((option, index) => (
        <Styled.PredefinedInputButton
          $active={index === activeOptionIndex || activeOptionIndices.includes(index)}
          $size={size}
          key={index}
          onMouseDown={() => onSelectOption(index)}
        >
          {optionLabel(option)}
        </Styled.PredefinedInputButton>
      ))}
    </Styled.PredefinedInputOption>
  );
};

export default PredefinedInputOptions;
